import { Button, Icon } from 'design-react-kit';
import { useFormik } from 'formik';
import { useEffect, useState, type ReactNode } from 'react';
import { FormContext } from '../../hooks/useCompositeForm';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import validationSchema from 'components/forms/validationSchema';
import useUploadFiles from 'hooks/services/useUploadFiles';
import useRegistraDocumenti from 'hooks/services/useRegistraDocumenti';
import { useNavigate } from 'react-router-dom';
import BackButton from 'components/back-button/BackButton';
import { AnagraficaUnicaDto } from 'services/ms-anagrafica-unica';
import { OnboardingConcorsoStato, DocumentoUploaded } from './types';
import { creaConcorso } from 'pages/gestione-candidati/CreaConcorso';
import validationSchemaForBozza from 'components/forms/validationSchemaForBozza';

const cleanFormData = (data: Record<string, any>) => {
  return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== ''));
};

export interface IFormCandidatoConcorsi {
  // Dati anagrafici
  codiceFiscale: string;
  cognome: string;
  nome: string;
  genere: 'FEMMINILE' | 'MASCHILE';
  statoCivile: string;
  cittadinanza: string;
  dataNascita: string;
  nazioneNascita: string;
  provNascita: string;
  cittaNascita: string;
  // Estremi identificazione
  tipoDocumento: string;
  numeroCartaIdentita: string;
  enteEmittente: string;
  dataRilascio: string;
  dataScadenza: string;
  // Recapiti
  indirizzoPec?: string;
  email?: string;
  telefonoAbitazione?: string;
  telefonoCellulare?: string;
  // Dati residenza
  statoResidenza: string;
  provinciaResidenza?: string;
  cittaResidenza?: string;
  indirizzoResidenza: string;
  capResidenza: string;
  // Dati domicilio
  domicilioDifferente: boolean;
  statoDomicilio?: string;
  provinciaDomicilio?: string;
  cittaDomicilio?: string;
  indirizzoDomicilio?: string;
  capDomicilio?: string;
  // Disabilità
  disabile: boolean;
  percentualeDisabilita?: number;
  collocamentoMirato?: boolean;
  //fileCollocamento?: File;
  //documentiDisabilita?: Array<File>;
  // Titolo di studio
  titoloStudio?: string | null;
  titoloDichiarato?: boolean;
  noteTitolo?: string;
  // Concorsi
  onboardingConcorsi: Array<IFormOnBoardingConcorso>;
}

export interface IFormOnBoardingConcorso {
  idConcorso: number;
  stato: OnboardingConcorsoStato;
  statoOnb: OnboardingConcorsoStatoOnb;
  areaProfessionale: string | null;
  tipologiaContrattoId: string | number;
  //modalitaSelezione
  //scadenzaInvioDoc
  attiOnboarding?: Array<AttoOnboardingConcorsoDto>;
  nomeConcorso?: string;
  livello?: string | null;
  posizioneGraduatoria?: string;
  documenti?: Array<DocumentoUploaded>;
  vincitore?: boolean;
  idoneo?: boolean;
}

export enum OnboardingConcorsoStatoOnb {
  BOZZA = 'BOZZA',
  INSERITO = 'INSERITO',
  MODIFICATO_RECLUTAMENTO = 'MODIFICATO_RECLUTAMENTO',
  RICHIESTA_RETTIFICA = 'RICHIESTA_RETTIFICA',
  RICHIESTA_INTEGRAZIONE = 'RICHIESTA_INTEGRAZIONE',
  CONVALIDA_CANDIDATO = 'CONVALIDA_CANDIDATO',
  RINUNCIATARIO = 'RINUNCIATARIO',
  DA_CONVOCARE = 'DA_CONVOCARE',
  CONVOCATO = 'CONVOCATO',
  NON_CONTRATTUALIZZABILE = 'NON_CONTRATTUALIZZABILE',
  CONTRATTUALIZZATO = 'CONTRATTUALIZZATO',
}

interface IDocumentoDisabilita {
  tipoDisabilita: string | number;
  previsioneRevisione: 'Si' | 'No';
  dataRevisione?: string;
  documentoRevisione?: File;
  documentoDisabilita: File;
}

export interface AttoOnboardingConcorsoDto {
  tipoAtto: string;
  oggettoAtto: string;
  dataAtto: string;
  numeroAtto: string;
}

export interface IFormCreaCandidatoForm {
  // Dati anagrafici
  codiceFiscale: string;
  cognome: string;
  nome: string;
  sesso: 'F' | 'M';
  statoCivile: string;
  cittadinanza: string;
  dataNascita: string;
  nazioneNascita: string;
  provNascita: string;
  cittaNascita: string;

  // Estremi identificazione
  tipoDocumento: string;
  numeroCartaIdentita: string;
  enteEmittente: string;
  dataRilascio: string;
  dataScadenza: string;
  filesIdentificazione: Array<File>;

  // Recapiti
  indirizzoPec?: string;
  email?: string;
  telefonoAbitazione?: string;
  telefonoCellulare?: string;

  // Dati residenza
  statoResidenza: string;
  provinciaResidenza?: string;
  cittaResidenza?: string;
  indirizzoResidenza: string;
  capResidenza: string;

  // Dati domicilio
  domicilioDifferente: 'No' | 'Si';
  statoDomicilio?: string;
  provinciaDomicilio?: string;
  cittaDomicilio?: string;
  indirizzoDomicilio?: string;
  capDomicilio?: string;

  // Form dati concorso: atti
  attiOnboarding: Array<{
    tipoAtto: string;
    oggettoAtto: string;
    dataAtto: string;
    numeroAtto: string;
    file: File;
  }>;

  // Disabilità
  disabile: 'No' | 'Si';
  percentualeDisabilita?: number;
  collocamentoMirato?: 'No' | 'Si';
  fileCollocamento?: File;
  documentiDisabilita?: Array<IDocumentoDisabilita>;

  // Titolo di studio
  titoloStudio?: string | null;
  titoloDichiarato?: boolean;
  noteTitolo?: string;

  // Concorso
  idConcorso?: number;
  nomeNuovoConcorso?: string;
  areaProfessionale: string;
  livelli: string;
  vincitore?: boolean;
  idoneo?: boolean;
  posizioneGraduatoria?: string;
  note?: string;
}

export const initialValues: IFormCreaCandidatoForm = {
  // Dati anagrafici
  codiceFiscale: '',
  cognome: '',
  nome: '',
  sesso: 'F',
  statoCivile: '',
  cittadinanza: '',
  dataNascita: '',
  nazioneNascita: '',
  provNascita: '',
  cittaNascita: '',

  // Estremi identificazione
  tipoDocumento: '',
  numeroCartaIdentita: '',
  enteEmittente: '',
  dataRilascio: '',
  dataScadenza: '',
  filesIdentificazione: [] as File[],

  // Recapiti
  indirizzoPec: '',
  email: '',
  telefonoAbitazione: undefined,
  telefonoCellulare: undefined,

  //Dati di residenza
  statoResidenza: 'Italia',
  provinciaResidenza: '',
  cittaResidenza: '',
  indirizzoResidenza: '',
  capResidenza: '',

  //Dati domicilio
  domicilioDifferente: 'No',
  statoDomicilio: undefined,
  provinciaDomicilio: undefined,
  cittaDomicilio: undefined,
  indirizzoDomicilio: undefined,
  capDomicilio: undefined,

  // Form dati concorso: atti
  attiOnboarding: [],

  // Dati disabilità
  disabile: 'No',
  percentualeDisabilita: undefined,
  collocamentoMirato: 'No',
  fileCollocamento: undefined,
  documentiDisabilita: [],

  // Titolo di studio
  titoloStudio: undefined,
  titoloDichiarato: undefined,
  noteTitolo: undefined,

  //Dati concorso
  areaProfessionale: '',
  livelli: '',
  vincitore: false,
  idoneo: false,
};

export const CreaCandidatoForm = ({
  children,
  initialData,
}: {
  children: ReactNode;
  initialData?: AnagraficaUnicaDto | null;
}) => {
  const [enableValidation, setEnableValidation] = useState(true);
  const dispatch = useAppDispatch();
  const uploadFiles = useUploadFiles();
  const registraDocumenti = useRegistraDocumenti();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: enableValidation
      ? toFormikValidationSchema(validationSchema)
      : toFormikValidationSchema(validationSchemaForBozza),
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (!enableValidation) {
      formik.handleSubmit();
    }
  }, [enableValidation]);

  console.log('formik values', formik.values);
  console.log('formik errors', formik.errors);

  async function handleSubmit({
    // Dati anagrafici
    codiceFiscale,
    cognome,
    nome,
    sesso,
    statoCivile,
    cittadinanza,
    dataNascita,
    nazioneNascita,
    provNascita,
    cittaNascita,
    // Estremi identificazione
    tipoDocumento,
    numeroCartaIdentita,
    enteEmittente,
    dataRilascio,
    dataScadenza,
    filesIdentificazione,
    // Recapiti
    indirizzoPec,
    email,
    telefonoAbitazione,
    telefonoCellulare,
    // Dati residenza
    statoResidenza,
    provinciaResidenza,
    cittaResidenza,
    indirizzoResidenza,
    capResidenza,
    // Dati domicilio
    domicilioDifferente,
    statoDomicilio,
    provinciaDomicilio,
    cittaDomicilio,
    indirizzoDomicilio,
    capDomicilio,
    // Disabilità
    disabile,
    percentualeDisabilita,
    collocamentoMirato,
    fileCollocamento,
    documentiDisabilita,
    // Titolo di studio
    titoloStudio,
    titoloDichiarato,
    noteTitolo,
    // Concorsi
    livelli,
    idConcorso,
    nomeNuovoConcorso,
    areaProfessionale,
    vincitore,
    idoneo,
    posizioneGraduatoria,
    attiOnboarding,
  }: IFormCreaCandidatoForm) {
    // File handling
    const documentiIdentificazione = await uploadFiles({
      files: filesIdentificazione,
      tipoDocumento:
        tipoDocumento === 'CARTA_IDENTITA' ? '15' : tipoDocumento === 'PASSAPORTO' ? '17' : '18',
      nomeFiles: 'Identificazione',
    });

    const filesAttiDetermina = attiOnboarding
      ?.filter((doc) => doc.tipoAtto === 'DET')
      .map(({ file }) => file);
    const documentiDetermina =
      filesAttiDetermina.length > 0
        ? await uploadFiles({
            files: filesAttiDetermina,
            tipoDocumento: '28',
            nomeFiles: 'Atti',
          })
        : undefined;

    const filesAttiDelibera = attiOnboarding
      ?.filter((doc) => doc.tipoAtto === 'DEL')
      .map(({ file }) => file);
    const documentiDelibera =
      filesAttiDelibera.length > 0
        ? await uploadFiles({
            files: filesAttiDelibera,
            tipoDocumento: '29',
            nomeFiles: 'Atti',
          })
        : undefined;

    const filesAttiDecreto = attiOnboarding
      ?.filter((doc) => doc.tipoAtto === 'DEC')
      .map(({ file }) => file);
    const documentiDecreto =
      filesAttiDecreto.length > 0
        ? await uploadFiles({
            files: filesAttiDecreto,
            tipoDocumento: '21',
            nomeFiles: 'Atti',
          })
        : undefined;

    const filesSchedaFunzionale = documentiDisabilita
      ?.filter((doc) => doc.tipoDisabilita === 19)
      .map((doc) => doc.documentoDisabilita);
    console.log('filesSchedaFunzionale', filesSchedaFunzionale);

    const documentiSchedaFunzionaleUploaded =
      filesSchedaFunzionale &&
      (await uploadFiles({
        files: filesSchedaFunzionale,
        tipoDocumento: '19',
        nomeFiles: 'Richiesta Scheda Funzionale',
      }));

    const filesVerbaleInvalidita = documentiDisabilita
      ?.filter((doc) => doc.tipoDisabilita === 20)
      .map((doc) => doc.documentoDisabilita);
    console.log('filesVerbaleInvalidita', filesVerbaleInvalidita);
    const documentiVerbaleInvaliditaUploaded =
      filesVerbaleInvalidita &&
      (await uploadFiles({
        files: filesVerbaleInvalidita,
        tipoDocumento: '20',
        nomeFiles: 'Richiesta Verbale Invalidità',
      }));

    const documentoCollocamento =
      fileCollocamento &&
      (await uploadFiles({
        files: [fileCollocamento],
        tipoDocumento: '31',
        nomeFiles: 'Nulla Osta',
      }));

    const filesDataRevisione = documentiDisabilita
      ?.map((doc) => doc.documentoRevisione)
      .filter((file): file is File => file !== undefined);
    const documentiDataRevisione =
      fileCollocamento &&
      (await uploadFiles({
        files: filesDataRevisione,
        tipoDocumento: '2',
        nomeFiles: 'Disabilita',
      }));

    let nuovoIdConcorso: undefined | string = undefined;
    if (nomeNuovoConcorso) {
      const concorso = await creaConcorso({
        nome: nomeNuovoConcorso,
        areaProfessionale,
        livelli,
        posizioneGraduatoria,
        vincitore,
        idoneo,
      });
      if (typeof concorso !== 'string') nuovoIdConcorso = concorso.id;
    }

    const onboarding: IFormOnBoardingConcorso = {
      stato: OnboardingConcorsoStato.COM,
      statoOnb: OnboardingConcorsoStatoOnb.INSERITO,
      idConcorso: idConcorso ?? Number(nuovoIdConcorso),
      areaProfessionale:
        areaProfessionale && areaProfessionale.trim() !== '' ? areaProfessionale : null,
      tipologiaContrattoId: 1,
      livello: livelli && livelli.trim() !== '' ? livelli : null,
      vincitore,
      idoneo,
      posizioneGraduatoria,
      attiOnboarding: attiOnboarding.map(({ file, ...rest }) => rest),
    };

    const payload: IFormCandidatoConcorsi = {
      codiceFiscale,
      cognome,
      nome,
      genere: sesso === 'F' ? 'FEMMINILE' : 'MASCHILE',
      statoCivile,
      cittadinanza,
      dataNascita,
      nazioneNascita,
      provNascita,
      cittaNascita,
      tipoDocumento,
      numeroCartaIdentita,
      enteEmittente,
      dataRilascio,
      dataScadenza,
      indirizzoPec,
      email,
      telefonoAbitazione,
      telefonoCellulare,
      statoResidenza,
      provinciaResidenza,
      cittaResidenza,
      indirizzoResidenza,
      capResidenza,
      domicilioDifferente: domicilioDifferente !== 'No',
      statoDomicilio,
      provinciaDomicilio,
      cittaDomicilio,
      indirizzoDomicilio,
      capDomicilio,
      disabile: disabile !== 'No',
      percentualeDisabilita,
      collocamentoMirato: collocamentoMirato !== 'No',
      ...(titoloStudio && { titoloStudio }),
      ...(titoloDichiarato && { titoloDichiarato }),
      ...(noteTitolo && { noteTitolo }),
      onboardingConcorsi: [onboarding],
    };

    if (!enableValidation) {
      const cleanedPayload = cleanFormData(payload);

      const payloadBozza = {
        ...cleanedPayload,
        stato: OnboardingConcorsoStato.INC,
        statoOnb: OnboardingConcorsoStatoOnb.BOZZA,
      };
      try {
        axios.post(
          `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/bozza/onboarding`,
          payloadBozza
        );
        navigate('/elenco-candidati');
      } catch (error) {
        dispatch(
          showNotifica({
            titolo: 'Candidato salvataggio come bozza non riuscito',
            stato: 'error',
            messaggio: 'Il salvataggio come bozza non è riuscito',
            tipo: NOTIFICA_STATO.error,
          })
        );
      }
      return;
    }

    try {
      const candidatoResponse = await axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/`,
        payload
      );

      registraDocumenti(
        [
          ...(documentiIdentificazione ?? []),
          ...(documentiDetermina ?? []),
          ...(documentiDecreto ?? []),
          ...(documentiDelibera ?? []),
          ...(documentiSchedaFunzionaleUploaded ?? []),
          ...(documentiVerbaleInvaliditaUploaded ?? []),
          ...(documentoCollocamento ?? []),
          ...(documentiDataRevisione ?? []),
        ],
        candidatoResponse.data.onboardingConcorsi[0].id
      );

      navigate('/elenco-candidati');
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Candidato non inserito',
          stato: 'error',
          messaggio: 'Il candidato non è stato inserito',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  }

  return (
    <FormContext.Provider value={formik}>
      <div>
        {children}
        <div className="d-flex col-12 mt-4">
          <BackButton />
          <div className="d-flex ml-auto">
            <Button className="mr-2" color="danger">
              Elimina
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => {
                setEnableValidation(false);
                formik.handleSubmit();
              }}>
              Salva in bozza
            </Button>
            <Button
              className="ml-2"
              color="primary"
              onClick={() => {
                setEnableValidation(true);
                formik.handleSubmit();
              }}>
              Conferma
            </Button>
          </div>
        </div>
      </div>
    </FormContext.Provider>
  );
};