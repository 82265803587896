import { useTranslation } from 'react-i18next';
import {
  loadConfigurazioniNotifiche,
  deleteConfigurazione,
  getContestoNotifiche,
} from '../../store/configurazioniNotificheSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  Icon,
  Table,
  Input,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  LinkList,
  LinkListItem,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'design-react-kit';
import Pagination from '../../components/common/pagination/Pagination';
import { useFormik } from 'formik';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import {
  ConfigurazioneNotificheDto,
  ConfigurazioneNotificheDtoStatoEnum,
  GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest,
  OptionDtoStatoConfNotificheValoreEnum,
  RuoloUtenteAutenticatoDto,
} from '../../services/ms-anagrafica-unica';
import { useNavigate } from 'react-router-dom';
import {
  HREF_MODIFICA_CONFIGURAZIONE_NOTIFICA,
  HREF_NUOVA_CONFIGURAZIONE_NOTIFICA,
} from '../../components/layout/sidemenu/sidemenuConstants';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import PageHeader from '../../components/common/page-header/PageHeader';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

type Direction = 'ASC' | 'DESC';

const schema = z.object({
  ruolo: z.string().max(50, 'lenghtnomeruolo').optional(),
});

interface SortingState {
  direction: Direction;
}

const initialValues: GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest =
  {
    pageNum: 0,
    pageSize: 10,
    stato: undefined,
    ruolo: undefined,
    funzionalita: undefined,
    sort: '',
    direction: undefined,
  };

function GestioneNotifiche(props: { operations: RuoloUtenteAutenticatoDto }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { searchConfigurazioniStatus, searchConfigurazioniResult, deleteStatus, contestoResult } =
    useAppSelector((state) => state.configurazioniNotifiche);

  const [isOpen, toggleModal] = useState(false);
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
  const [formCollapseOpen, setFormCollapseOpen] = useState(false);
  const [isOpenListDetails, toggleModalListDetails] = useState(false);
  const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);
  const [confingurazioneSelezionata, setConfingurazioneSelezionata] = useState<
    ConfigurazioneNotificheDto | undefined
  >(undefined);

  const hasResults =
    searchConfigurazioniStatus && searchConfigurazioniResult.totalElements
      ? searchConfigurazioniResult.totalElements > 0
      : false;

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const searchForm =
    useFormik<GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest>({
      initialValues: initialValues,
      validationSchema: toFormikValidationSchema(schema),
      onSubmit: () => {
        console.log('ConfigurazioneNotificheRequest', searchForm.values);
        doSearch();
      },
    });

  const handlePageChange = (pageNum: number) => {
    console.log('Handle page change', pageNum - 1);
    searchForm.setFieldValue('pageNum', pageNum - 1);
    searchForm.handleSubmit();
  };

  const doSearch = () => {
    dispatch(loadConfigurazioniNotifiche(searchForm.values));
  };

  const handleChange = (name: string, selectedOption: any) => {
    searchForm.setFieldValue(name, selectedOption);
  };

  const confermaDisattivazione = (configurazione: ConfigurazioneNotificheDto) => {
    setConfingurazioneSelezionata(configurazione);
    toggleModal(!isOpen);
  };

  const disattivaConfigurazione = () => {
    if (!!confingurazioneSelezionata && confingurazioneSelezionata.funzionalita) {
      const azione =
        confingurazioneSelezionata.stato === 'ATTIVA'
          ? ConfigurazioneNotificheDtoStatoEnum.Disattiva
          : ConfigurazioneNotificheDtoStatoEnum.Attiva;
      dispatch(
        deleteConfigurazione({
          idConfigurazioneNotifiche: confingurazioneSelezionata?.funzionalita,
          configNotifichePatchRequestDto: {
            statoConfNotifiche: azione,
          },
        })
      )
        .unwrap()
        .then(() => dispatch(loadConfigurazioniNotifiche({})));
    }
  };

  let statoOptions: CustomSelectItem[] = [
    {
      value: '',
      label: 'TUTTE',
    },
    {
      value: 'ATTIVE',
      label: 'ATTIVE',
    },
    {
      value: 'DISATTIVE',
      label: 'DISATTIVE',
    },
  ];

  let funzionalitaOptions: CustomSelectItem[] = [];
  contestoResult?.funzionalita?.forEach((s) => {
    funzionalitaOptions.push({ label: s.label, value: s.valore });
  });

  const modalViewDetails = (list: any) => {
    if (list.length > 0) {
      toggleModalListDetails(!isOpenListDetails);
      setElencoDetails(list);
    }
  };

  useEffect(() => {
    dispatch(getContestoNotifiche())
      .unwrap()
      .then((resp) => {
        if (resp) {
          dispatch(loadConfigurazioniNotifiche({}));
          handleChange('stato', '');
        }
      });
  }, [dispatch]);

  const nuovaNotifica = () => {
    navigate(HREF_NUOVA_CONFIGURAZIONE_NOTIFICA);
  };

  const NewConfigButton = {
    buttonProperties: { outline: true },
    title: t('nuova notifica'),
    buttonColor: 'primary',
    showButtonIcon: true,
    icon: 'it-plus',
    iconColor: 'primary',
    action: nuovaNotifica,
  };

  const expandButton = {
    buttonProperties: { outline: true },
    title: 'Ricerca',
    buttonColor: 'primary',
    showButtonIcon: true,
    icon: 'it-search',
    iconColor: 'primary',
    action: () => {
      formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true);
    },
  };

  const showModificaConfigurazioneOperations = (
    configStatus: OptionDtoStatoConfNotificheValoreEnum
  ) =>
    props.operations?.elencoFunzionalita?.includes('CONFIGNOTIFICA_DETTAGLIO') &&
    configStatus === OptionDtoStatoConfNotificheValoreEnum.Attiva;

  const showDisattivaConfigurazioneOperations = (
    configStatus: OptionDtoStatoConfNotificheValoreEnum
  ) =>
    props.operations?.elencoFunzionalita?.includes('CONFIGNOTIFICA_PATCH') &&
    configStatus === OptionDtoStatoConfNotificheValoreEnum.Attiva;

  const showRiattivaConfigurazioneOperations = (
    configStatus: OptionDtoStatoConfNotificheValoreEnum
  ) =>
    props.operations?.elencoFunzionalita?.includes('CONFIGNOTIFICA_PATCH') &&
    configStatus === OptionDtoStatoConfNotificheValoreEnum.Disattiva;

  const showInserisciConfigurazioneOperations = () =>
    props.operations?.elencoFunzionalita?.includes('CONFIGNOTIFICA_INSERT');

  return (
    <div>
      <PageHeader
        showIcon={false}
        title={t('gestione notifiche')!}
        buttons={
          showInserisciConfigurazioneOperations() ? [NewConfigButton, expandButton] : [expandButton]
        }
      />

      <Accordion className="form-custom form-bg filtri-ricerca" background="active">
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="form-row mt-2">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />
            <Input
              type="text"
              id="input-ruolo-1"
              placeholder="Inserisci una ruolo"
              wrapperClassName="col col-md-6"
              name="ruolo"
              invalid={!!searchForm.errors.ruolo}
              infoText={t(searchForm.errors.ruolo || '') || ''}
              value={searchForm.values.ruolo}
              onChange={searchForm.handleChange}
            />
            <Col md="6">
              <CustomSelect
                placeholder="Seleziona funzionalità"
                options={funzionalitaOptions}
                name="funzionalita"
                invalid={!!searchForm.errors.funzionalita}
                infoText={t(searchForm.errors.funzionalita || '') || ''}
                value={searchForm.values.funzionalita}
                onChange={(e) => handleChange('funzionalita', e)}
              />
            </Col>
            <Col md="6">
              <CustomSelect
                name="stato"
                placeholder="Seleziona stato"
                options={statoOptions}
                value={searchForm.values.stato}
                onChange={(e) => handleChange('stato', e)}
              />
            </Col>
            <div className="d-flex flex-row-reverse col-6">
              <Button
                color="primary"
                className="mt-2"
                onClick={() => {
                  searchForm.handleSubmit();
                }}
              >
                Applica
              </Button>
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                onClick={searchForm.handleReset}
              >
                Pulisci
              </Button>
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-4 position-relative">
        {searchConfigurazioniStatus === STATUS_FULLFILLED &&
          searchConfigurazioniResult.totalElements === 0 && (
            <Alert color="info">Nessun risultato disponibile</Alert>
          )}
        {searchConfigurazioniStatus === STATUS_FULLFILLED && hasResults && (
          <>
            <Table striped responsive>
              <TableTheadCustom
                colonne={[
                  { titolo: 'Stato' },
                  { titolo: 'ruoli', style: { width: '25%' } },
                  { titolo: 'funzionalita' },
                  { titolo: 'data inserimento', nomeColonna: 'data_inserimento' },
                  { titolo: 'data modifica', nomeColonna: 'data_modifica' },
                  { titolo: 'Azioni' },
                ]}
                datiOrdinamento={searchForm.values}
                eseguiOrdinamento={ordinamento}
              />

              <tbody>
                {(searchConfigurazioniResult.data || []).map((x, i) => (
                  <tr key={i}>
                    <td
                      title={
                        x.stato === ConfigurazioneNotificheDtoStatoEnum.Attiva
                          ? 'Attivo'
                          : 'Cancellato'
                      }
                    >
                      {x.stato === ConfigurazioneNotificheDtoStatoEnum.Attiva ? (
                        <div className="cicle-status-active"></div>
                      ) : (
                        <div className="cicle-status-deactive"></div>
                      )}
                    </td>
                    <td>
                      <Badge
                        color="primary"
                        className=" col-sm-12"
                        style={
                          x.ruoli?.length! > 0
                            ? { cursor: 'pointer', padding: '10px', fontSize: '15px' }
                            : { padding: '10px', fontSize: '15px' }
                        }
                        onClick={() => modalViewDetails(x.ruoli)}
                      >
                        {x.ruoli?.length! > 1 ? x.ruoli?.length! + ' Ruoli' : '1 Ruolo'}
                      </Badge>
                    </td>
                    <td>{x.descrizioneFunzionalita}</td>
                    <td>
                      <>{new Date(x.dataInserimento!).toLocaleDateString()} </>
                    </td>
                    <td>
                      <>{new Date(x.dataModifica!).toLocaleDateString()} </>
                    </td>
                    <td>
                      {(showModificaConfigurazioneOperations(x.stato!) ||
                        showDisattivaConfigurazioneOperations(x.stato!) ||
                        showRiattivaConfigurazioneOperations(x.stato!)) && (
                        <UncontrolledDropdown direction="left">
                          <DropdownToggle nav>
                            <Icon icon="it-settings" color="primary" size="sm" />
                          </DropdownToggle>
                          <DropdownMenu className="no-arrow">
                            <LinkList>
                              {showModificaConfigurazioneOperations(x.stato!) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(
                                      `${HREF_MODIFICA_CONFIGURAZIONE_NOTIFICA}/${x.funzionalita}`
                                    )
                                  }
                                >
                                  <span>Modifica</span>
                                </LinkListItem>
                              )}

                              {(showDisattivaConfigurazioneOperations(x.stato!) ||
                                showRiattivaConfigurazioneOperations(x.stato!)) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() => confermaDisattivazione(x)}
                                >
                                  <span>
                                    {x.stato === ConfigurazioneNotificheDtoStatoEnum.Attiva
                                      ? 'Disattiva'
                                      : 'Attiva'}
                                  </span>
                                </LinkListItem>
                              )}
                            </LinkList>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              <Modal
                isOpen={isOpen}
                toggle={() => toggleModal(!isOpen)}
                labelledBy="esempio1"
                centered
              >
                <ModalHeader
                  toggle={() => {
                    toggleModal(!isOpen);
                    setConfingurazioneSelezionata(undefined);
                  }}
                  id="esempio1"
                >
                  Disattiva configurazione
                </ModalHeader>
                <ModalBody>
                  {confingurazioneSelezionata &&
                  confingurazioneSelezionata.stato === ConfigurazioneNotificheDtoStatoEnum.Attiva
                    ? t('conferma disattivazione')
                    : t('conferma attivazione')}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      toggleModal(!isOpen);
                      setConfingurazioneSelezionata(undefined);
                    }}
                  >
                    Annulla
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      toggleModal(!isOpen);
                      disattivaConfigurazione();
                    }}
                  >
                    Conferma
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={isOpenListDetails}
                toggle={() => toggleModalListDetails(!isOpenListDetails)}
                labelledBy="listDetails"
                centered
              >
                <ModalHeader
                  toggle={() => {
                    toggleModalListDetails(!isOpenListDetails);
                    setElencoDetails(undefined);
                  }}
                  id="listDetails"
                >
                  Elenco ruoli
                </ModalHeader>
                <ModalBody>
                  <ul>{elencoDetails?.map((value, index) => <li key={index}>{value}</li>)}</ul>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      toggleModalListDetails(!isOpenListDetails);
                      setElencoDetails(undefined);
                    }}
                  >
                    Annulla
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <Pagination
              totalCount={searchConfigurazioniResult.totalElements || 0}
              siblingCount={1}
              currentPage={
                searchForm && searchForm.values && searchForm.values.pageNum
                  ? searchForm.values.pageNum + 1
                  : 1
              }
              pageSize={searchConfigurazioniResult.pageSize || 10}
              onPageChange={(page: number) => {
                handlePageChange(page);
              }}
              className="justify-content-center"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default GestioneNotifiche;
