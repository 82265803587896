import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { DATI_AMMINISTRAZIONE_REGIONALE, DATI_ENTE_SOTTOSCRITTORE } from './convenzioniConstants';
import { useTranslation } from 'react-i18next';
import Pagination from '../../components/common/pagination/Pagination';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  deleteConvenzioni,
  fetchEntiSottoscrittori,
  fetchStatiConvenzioni,
  searchConvenzioni,
} from '../../store/ConvenzioniSlice';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import { STATUS_FULLFILLED, STATUS_REJECTED } from '../../store/store-constants';
import { useNavigate } from 'react-router-dom';
import {
  HREF_MODIFICA_CONVENZIONE,
  HREF_VISUALIZZA_CONVENZIONE,
} from '../../components/layout/sidemenu/sidemenuConstants';
import { ConvenzioniControllerApiConvenzioniSearchRequest } from '../../services/ms-anagrafica-unica';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

type Direction = 'ASC' | 'DESC';

interface SortingState {
  direction: Direction;
}

const initialValues: ConvenzioniControllerApiConvenzioniSearchRequest = {
  pageNum: 0,
  pageSize: 10,
};

function RicercaConvenzioni() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchResult, searchStatus, searchInput, listaStati, listaEnti, deleteStatus } =
    useAppSelector((state) => state.convenzioni);
  const { userRoleActive } = useAppSelector((state) => state.user);

  const [formCollapseOpen, setFormCollapseOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [convenzioneSelezionata, setConvenzioneSelezionata] = useState<number>();
  let hasResults = searchResult?.totalElements ? searchResult.totalElements > 0 : false;
  let statoOptions: CustomSelectItem[] = [];
  let entiSelect: CustomSelectItem[] = [];

  if (listaStati) {
    listaStati.forEach((stato) => {
      statoOptions.push({ label: stato.descrizione!, value: stato.codice! });
    });
  }
  if (listaEnti) {
    listaEnti.forEach((ente) => {
      entiSelect.push({ label: ente.nomeEnte!, value: ente.id! });
    });
  }
  const searchForm = useFormik<ConvenzioniControllerApiConvenzioniSearchRequest>({
    initialValues: initialValues,
    onSubmit: () => {
      handlePageChange(1);
      setFormCollapseOpen(false);
      doSearch();
    },
  });

  const doSearch = () => {
    dispatch(searchConvenzioni(searchForm.values));
  };

  const doFetchSelects = () => {
    dispatch(fetchStatiConvenzioni());
    dispatch(fetchEntiSottoscrittori());
  };
  const cancella = (id: number) => {
    setConvenzioneSelezionata(id);
    setIsOpen(!isOpen);
  };
  const doEliminaConvenzione = () => {
    if (convenzioneSelezionata) {
      dispatch(deleteConvenzioni(convenzioneSelezionata));
    }
  };

  const handlePageChange = (page: number) => {
    searchForm.setFieldValue('pageNum', page - 1);
  };

  const resetForm = () => {
    searchForm.setFieldValue('stato', undefined);
    searchForm.setFieldValue('enteSottoscrittore', undefined);
    searchForm.setFieldValue('nomeConvenzione', '');
    searchForm.setFieldValue('scopoConvenzione', '');
    searchForm.setFieldValue('responsabile', '');
    searchForm.setFieldValue('strutturaReferente', '');
    searchForm.setFieldValue('contattoTecnico', '');
    searchForm.setFieldValue('contattoAmministrativo', '');
  };

  const handleStatoChange = (selectedOption: any) => {
    searchForm.setFieldValue('stato', selectedOption);
    searchForm.handleChange(selectedOption);
  };

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  useEffect(() => {
    doFetchSelects();
  }, []);

  useEffect(() => {
    if (deleteStatus === STATUS_FULLFILLED) {
      doSearch();
    }
  }, [deleteStatus]);

  useEffect(() => {
    doSearch();
  }, [searchForm.values.pageNum]);

  useEffect(() => {
    searchForm.setValues({
      pageNum: searchInput?.pageNum ?? 0,
      pageSize: searchInput?.pageSize ?? 10,
      nomeConvenzione: searchInput?.nomeConvenzione ?? '',
      scopoConvenzione: searchInput?.scopoConvenzione ?? '',
      enteSottoscrittore: searchInput?.enteSottoscrittore ?? undefined,
      responsabile: searchInput?.responsabile ?? '',
      strutturaReferente: searchInput?.strutturaReferente ?? '',
      contattoTecnico: searchInput?.contattoTecnico ?? '',
      contattoAmministrativo: searchInput?.contattoAmministrativo ?? '',
      stato: (searchInput?.stato ?? '') || undefined,
      // dataDecorrenza: searchInput?.dataDecorrenza || '',
      // dataScadenza: searchInput?.dataScadenza || '',
      sort: searchInput?.sort ?? '',
      direction: (searchInput?.direction ?? '') || undefined,
    });
  }, [searchInput]);

  const handleEnteSottoscrittoreSelect = (value: string) => {
    searchForm.setFieldValue('enteSottoscrittore', value);
  };

  return (
    <div>
      <h2 className="capitalize">{t('elenco convenzioni')}</h2>

      <Accordion className="form-custom form-bg filtri-ricerca" background={'active'}>
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="mt-2">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />

            <div className={'col-12 mb-4 border-bottom border-left'}>
              <h5 className={'mb-0'}>{DATI_ENTE_SOTTOSCRITTORE}</h5>
            </div>
            <div className={'form-row mt-4'}>
              <Input
                type="text"
                id="input-nome-convenzione"
                placeholder="Inserisci un nome per la convenzione"
                wrapperClassName="col col-md-6"
                name="nomeConvenzione"
                value={searchForm.values.nomeConvenzione}
                onChange={searchForm.handleChange}
              />
              <Input
                type="text"
                id="input-scopo-convenzione"
                key="input-scopo-convenzione"
                placeholder="Inserisci lo scopo della convenzione"
                wrapperClassName="col col-md-6"
                name="scopoConvenzione"
                value={searchForm.values.scopoConvenzione}
                onChange={searchForm.handleChange}
              />
              <Col md="6">
                <CustomSelect
                  placeholder="Seleziona un ente sottoscrittore"
                  options={entiSelect}
                  value={searchForm.values.enteSottoscrittore}
                  onChange={handleEnteSottoscrittoreSelect}
                />
              </Col>
            </div>

            <div className={'col-12 mb-4 border-bottom border-left'}>
              <h5 className={'mb-0'}>{DATI_AMMINISTRAZIONE_REGIONALE}</h5>
            </div>
            <div className={'form-row mt-4'}>
              <Input
                type="text"
                id="input-responsabile"
                placeholder="Inserisci il nominativo del responsabile"
                wrapperClassName="col col-md-6"
                name="responsabile"
                value={searchForm.values.responsabile}
                onChange={searchForm.handleChange}
              />
              <Input
                type="text"
                id="input-referente"
                placeholder="Inserisci la struttura referente"
                wrapperClassName="col col-md-6"
                name="strutturaReferente"
                value={searchForm.values.strutturaReferente}
                onChange={searchForm.handleChange}
              />
              <Input
                type="text"
                id="input-contatto-tecnico"
                placeholder="Inserisci un contatto tecnico"
                wrapperClassName="col col-md-6"
                name="contattoTecnico"
                value={searchForm.values.contattoTecnico}
                onChange={searchForm.handleChange}
              />
              <Input
                type="text"
                id="input-contatto-amministrativo"
                placeholder="Inserisci un contatto amministrativo"
                wrapperClassName="col col-md-6"
                name="contattoAmministrativo"
                value={searchForm.values.contattoAmministrativo}
                onChange={searchForm.handleChange}
              />
            </div>

            <div className={'col-12 mb-4 border-bottom border-left'}>
              <h5 className={'mb-0'}>Altro</h5>
            </div>
            <div className={'form-row mt-4'}>
              <Col md="6">
                <CustomSelect
                  name="stato"
                  placeholder="Seleziona uno stato"
                  options={statoOptions}
                  value={searchForm.values.stato}
                  onChange={handleStatoChange}
                />
              </Col>
            </div>
          </div>

          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                searchForm.handleSubmit();
              }}
            >
              Applica
            </Button>
            <Button outline color="primary" className="mt-2 mr-2" onClick={resetForm}>
              Pulisci
            </Button>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-4 position-relative">
        {/*{searchStatus === STATUS_PENDING &&*/}
        {/*    <PageLoader loadingMessage="Ricerca in corso.."/>*/}
        {/*}*/}

        {searchStatus === STATUS_REJECTED && (
          <Alert color="danger">
            Siamo spiacenti, si &egrave; verificato un errore durante la richiesta
          </Alert>
        )}

        {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && (
          <Alert color="info">Nessun risultato disponibile</Alert>
        )}

        {searchStatus === STATUS_FULLFILLED && hasResults && (
          <>
            <Table striped responsive>
              <TableTheadCustom
                colonne={[
                  { titolo: 'Nome Convenzione', nomeColonna: 'nomeConvenzione' },
                  { titolo: 'Scopo Convenzione', nomeColonna: 'scopoConvenzione' },
                  { titolo: 'Data Decorrenza', nomeColonna: 'dataDecorrenza' },
                  { titolo: 'Data Scadenza', nomeColonna: 'dataScadenza' },
                  { titolo: 'Responsabile', nomeColonna: 'responsabile' },
                  { titolo: 'Contatto Tecnico', nomeColonna: 'contattoTecnico' },
                  { titolo: 'Contatto Amministrativo', nomeColonna: 'contattoAmministrativo' },
                  { titolo: 'Struttura Referente', nomeColonna: 'strutturaReferente' },
                  { titolo: 'Stato', nomeColonna: 'stato' },
                  { titolo: 'azioni' },
                ]}
                datiOrdinamento={searchForm.values}
                eseguiOrdinamento={ordinamento}
              />

              <tbody>
                {(searchResult.data ?? []).map((x) => (
                  <tr key={x.id}>
                    <td>{x.nomeConvenzione}</td>
                    <td>{x.scopoConvenzione}</td>
                    <td>{new Date(x.dataDecorrenza!).toLocaleDateString()}</td>
                    <td>{new Date(x.dataScadenza!).toLocaleDateString()}</td>
                    <td>{x.responsabile}</td>
                    <td>{x.contattoTecnico}</td>
                    <td>{x.contattoAmministrativo}</td>
                    <td>{x.strutturaReferente}</td>
                    <td>{x.statoConvenzione?.descrizione}</td>
                    <td>
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle nav>
                          <Icon icon="it-settings" color="primary" size="sm" />
                        </DropdownToggle>
                        <DropdownMenu className="no-arrow">
                          <LinkList>
                            <LinkListItem
                              size="medium"
                              onClick={() => navigate(`${HREF_VISUALIZZA_CONVENZIONE}/${x.id}`)}
                            >
                              Visualizza
                            </LinkListItem>
                            {userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_PATCH') &&
                              !['SCADUTA', 'ELIMINATA'].includes(x?.statoConvenzione?.codice!) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() => navigate(`${HREF_MODIFICA_CONVENZIONE}/${x.id}`)}
                                >
                                  Modifica
                                </LinkListItem>
                              )}
                            {userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_DELETE') &&
                              !['SCADUTA', 'ELIMINATA'].includes(x?.statoConvenzione?.codice!) && (
                                <LinkListItem size="medium" onClick={() => cancella(x.id!)}>
                                  Elimina
                                </LinkListItem>
                              )}
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              <Modal
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                labelledBy="esempio1"
                centered
              >
                <ModalHeader
                  toggle={() => {
                    setIsOpen(!isOpen);
                    setConvenzioneSelezionata(undefined);
                  }}
                  id="esempio1"
                >
                  Cancella convenzione
                </ModalHeader>
                <ModalBody>
                  <p>Sei sicuro di voler cancellare questa convenzione?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setConvenzioneSelezionata(undefined);
                    }}
                  >
                    Annulla
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      doEliminaConvenzione();
                    }}
                  >
                    Conferma
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <Pagination
              totalCount={searchResult.totalElements ?? 0}
              siblingCount={1}
              currentPage={searchForm?.values?.pageNum ? searchForm.values.pageNum + 1 : 1}
              pageSize={searchResult.pageSize ?? 10}
              onPageChange={(page: number) => {
                handlePageChange(page);
              }}
              className="justify-content-center"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default RicercaConvenzioni;
