import { Button, Icon } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { createContext, useContext, type ReactNode } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import useUploadFiles from 'hooks/services/useUploadFiles';
import {
  OnboardingConcorsoStatoOnb,
  type IFormCreaCandidatoForm,
} from 'components/crea-candidato-form/CreaCandidatoForm';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useRegistraDocumenti from 'hooks/services/useRegistraDocumenti';
import useAsyncInitialValues from 'hooks/useAsyncInitialValues';
import { z } from 'zod';
import BackButton from 'components/back-button/BackButton';

export interface IFormConferimentoDocumentiForm {
  trattamentoDatiPersonali: boolean;
  codiceComportamentoArpal: boolean;
  rinunciaPerseo: boolean;
  visitaMedica: boolean;
  dichiarazioneAttivitaLavorativa?: string; //"TFR" | "TFS" | "CE5" | "CEN";
  accreditamentoCompetenze?: AccreditamentoCompetenzeDto;
}

/* export interface AccettazioneAssunzioneConcorsoDto {
  id?: number;
  rinuncia: boolean;
  motivazioneRinuncia?: string;
  documentiDisabilita?: DocumentoUploaded;
  trattamentoDatiPersonali?: boolean;
  codiceComportamentoArpal?: boolean;
  rinunciaPerseo?: boolean;
  dichiarazioneAttivitaLavorativa?: string;
  accreditamentoCompetenze?: AccreditamentoCompetenzeDto;
} */

export interface AccreditamentoCompetenzeDto {
  tipoAccreditamento: string;
  iban: string;
}

export const initialValues: IFormConferimentoDocumentiForm = {
  trattamentoDatiPersonali: false,
  codiceComportamentoArpal: false,
  rinunciaPerseo: false,
  visitaMedica: false,
};

// Create a properly typed context
export const FormContext = createContext<FormikProps<IFormConferimentoDocumentiForm> | null>(null);

// Hook to use the context safely
export const useCompositeConferimentoDocumentiForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useCompositeForm must be used within a FormContext.Provider');
  }
  return context;
};

/* const validationSchema = z.object({
  //Dati anagrafici
  codiceFiscale: z
    .string({
      required_error: "Obbligatorio",
    })
    .min(16, "Il codice fiscale deve essere di 16 caratteri")
    .max(16, "Il codice fiscale deve essere di 16 caratteri"),
  cognome: z
    .string({
      required_error: "Obbligatorio",
    })
    .min(1, "Inserisci il cognome")
    .max(200),
  nome: z
    .string({
      required_error: "Obbligatorio",
    })
    .min(1, "Inserisci il nome")
    .max(50),
}); */

export const ConferimentoDocumentiForm = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  const uploadFiles = useUploadFiles();
  const registraDocumenti = useRegistraDocumenti();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    //validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  console.log('formik values', formik.values);
  console.log('formik errors', formik.errors);

  async function handleSubmit(values: IFormConferimentoDocumentiForm) {
    console.log('SUBMIT conferimento documenti', values);

    // File handling: check if new ones
    /*    const documentiIdentificazione = await uploadFiles({
      files: filesIdentificazione,
      tipoDocumento:
        tipoDocumento === "CARTA_IDENTITA"
          ? "15"
          : tipoDocumento === "PASSAPORTO"
          ? "17"
          : "18",
      nomeFiles: "Identificazione",
    });
    const documentiAttiOnBoarding = await uploadFiles({
      files: attiOnboarding.map(({ file }) => file),
      tipoDocumento: "21",
      nomeFiles: "Atti",
    }); */

    try {
      /*  await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato?.id}`,
        payload
      ); */
      /*   const salvataggioIncompletoResponse = await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/salva/${candidato?.id}/onboarding/${onboarding?.id}`,
        payload
      );

      await axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/onboarding/${onboarding?.id}/valida`,
        onboardingUpdated
      ); */
      /* 
      if (documentiAttiOnBoarding && documentiIdentificazione)
        registraDocumenti(
          [...documentiAttiOnBoarding, ...documentiIdentificazione],
          onboarding?.id
        ); */
      /*       navigate("/elenco-candidati");
       */
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Candidato non inserito',
          stato: 'error',
          messaggio: 'Il candidato non è stato inserito',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  }

  return (
    <FormContext.Provider value={formik}>
      <div>
        {children}
        <div className="d-flex col-12 mt-4">
          <BackButton />
          <div className="d-flex ml-auto">
            <Button className="ml-2" color="primary" onClick={() => formik.handleSubmit()}>
              Conferma
            </Button>
          </div>
        </div>
      </div>
    </FormContext.Provider>
  );
};
