import { useEffect, useRef, useState } from 'react';
import PageHeader from '../../components/common/page-header/PageHeader';
import { GestioneCvForm } from '../../components/gestione-cv-form/GestioneCvForm';
import { useNavigate, useParams } from 'react-router-dom';
import VisualizzaCV from '../../components/gestione-cv-form/VisualizzazioneCV/VisualizzaCV';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { cvDownload } from '../../store/curriculumVitaeSlice';
import { Alert, Badge, Button } from 'design-react-kit';

const GestioneCv = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [badge, setBadge] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const scrollDiv = useRef<null | HTMLDivElement>(null);
  const executeScroll = () => scrollDiv.current!.scrollIntoView();
  const { userInfoResult } = useAppSelector((state) => state.user);
  const { detailResult } = useAppSelector((state) => state.curriculumVitae);

  const callCvDownloadGestioneCV = (id: number, nomeDipendente: string) => {
    dispatch(
      cvDownload({
        idCid: Number(id),
      })
    )
      .unwrap()
      .then((resp) => {
        if (!!resp) {
          const blob = new Blob([resp], { type: 'application/pdf' });
          let url;
          const link = document.createElement('a');
          url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `CV_${nomeDipendente.replace(' ', '_').replace("'", '')}`;
          link.click();
        }
      });
  };

  const scaricaCV = {
    buttonProperties: { outline: true },
    title: 'Download CV',
    buttonColor: 'primary',
    action: () =>
      callCvDownloadGestioneCV(
        userInfoResult?.entityId!,
        userInfoResult?.nome! + '_' + userInfoResult?.cognome!
      ),
    showButtonIcon: true,
    icon: 'it-download',
    iconColor: 'primary',
  };

  const bottoni = !!detailResult && detailResult.stato === 'PUBBLICATO' ? [scaricaCV] : [];

  useEffect(() => {
    if (!!detailResult && detailResult?.stato === 'PUBBLICATO') {
      setBadge(<Badge color="success">Completato</Badge>);
    } else if (!!detailResult && detailResult.stato?.indexOf('BOZZA') !== -1) {
      setBadge(<Badge color="warning">Bozza</Badge>);
    } else if (!detailResult && !userInfoResult?.isCvCreato) {
      setBadge(<Badge color="primary">Da compilare</Badge>);
    }
  }, [detailResult?.stato]);

  return (
    <div ref={scrollDiv}>
      <PageHeader
        showIcon={false}
        title="Gestione Curriculum Vitae"
        buttons={bottoni}
        info={badge}
      />
      <div>
        {id && Number(id) === userInfoResult?.entityId! && (
          <VisualizzaCV idDipendente={Number(id)}></VisualizzaCV>
        )}
        {id && Number(id) !== userInfoResult?.entityId! && (
          <>
            <Alert color="  danger">Non hai il permesso di visualizzare questa pagina.</Alert>
            <Button color="primary" onClick={() => navigate('/')}>
              Torna indietro
            </Button>
          </>
        )}
        {!id && <GestioneCvForm disabled={false} scrollAction={() => executeScroll()} />}
      </div>
    </div>
  );
};

export default GestioneCv;
