/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { CandidaturaBandoPoVintaDto } from './candidatura-bando-po-vinta-dto';
// May contain unused imports in some cases
// @ts-ignore
import { DipendenteRegionaleEssenzialeConSoDto } from './dipendente-regionale-essenziale-con-so-dto';
// May contain unused imports in some cases
// @ts-ignore
import { DocumentoDto } from './documento-dto';

/**
 *
 * @export
 * @interface CandidaturaBandoPoDto
 */
export interface CandidaturaBandoPoDto {
  /**
   *
   * @type {number}
   * @memberof CandidaturaBandoPoDto
   */
  id?: number;
  /**
   *
   * @type {DocumentoDto}
   * @memberof CandidaturaBandoPoDto
   */
  idRiepilogoFirmato?: DocumentoDto;
  /**
   *
   * @type {DocumentoDto}
   * @memberof CandidaturaBandoPoDto
   */
  idRiepilogoDoc?: DocumentoDto;
  /**
   *
   * @type {DipendenteRegionaleEssenzialeConSoDto}
   * @memberof CandidaturaBandoPoDto
   */
  dipendenteRegionale?: DipendenteRegionaleEssenzialeConSoDto;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  dataInserimento?: string;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  note?: string;
  /**
   *
   * @type {boolean}
   * @memberof CandidaturaBandoPoDto
   */
  vincitore?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CandidaturaBandoPoDto
   */
  flagForzaVincitore?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CandidaturaBandoPoDto
   */
  conteso?: boolean;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  stato?: CandidaturaBandoPoDtoStatoEnum;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  specificareComprovataEsperienza?: string;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  breveEsposizione?: string;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  procedDisciplinari?: string;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  dataConferma?: string;
  /**
   *
   * @type {string}
   * @memberof CandidaturaBandoPoDto
   */
  denominazioneAltraEq?: string;
  /**
   *
   * @type {Array<CandidaturaBandoPoVintaDto>}
   * @memberof CandidaturaBandoPoDto
   */
  elencoCandidatureVinte?: Array<CandidaturaBandoPoVintaDto>;
}

export const CandidaturaBandoPoDtoStatoEnum = {
  InComp: 'IN_COMP',
  Confermata: 'CONFERMATA',
  Opzionato: 'OPZIONATO',
  Conteso: 'CONTESO',
} as const;

export type CandidaturaBandoPoDtoStatoEnum =
  (typeof CandidaturaBandoPoDtoStatoEnum)[keyof typeof CandidaturaBandoPoDtoStatoEnum];
