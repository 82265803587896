import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GestioneDocumentaleControllerApi,
  GestioneDocumentaleControllerApiDownloadDocumentRequest,
  GestioneDocumentaleControllerApiUploadDocumentRequest,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';
import { AxiosError } from 'axios';

export interface DocumentsState {
  getDocumentStatus: string;
  downloadedStatus: string;
  uploadDocumentoStatus: string;
}

const initialState: DocumentsState = {
  getDocumentStatus: '',
  downloadedStatus: '',
  uploadDocumentoStatus: '',
};

export const cancellaDocumento = createAsyncThunk(
  'gestioneDocumentale/cancellaDocumento',
  async (idDocumento: number) => {
    console.log('cancellaDocumento');
    const api = new GestioneDocumentaleControllerApi(MS_AU_CONFIG);
    const response = await api.deleteDocument({
      idDocumento,
    });
    return response.data;
  }
);

export const downloadDocument = createAsyncThunk(
  'gestioneDocumentale/downloadDocumento',
  async (param: GestioneDocumentaleControllerApiDownloadDocumentRequest) => {
    const api = new GestioneDocumentaleControllerApi(MS_AU_CONFIG);
    const response = await api.downloadDocument(param, { responseType: 'arraybuffer' });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const dettaglioDocumento = createAsyncThunk(
  'gestioneDocumentale/dettaglioDocumento',
  async (idDocumento: number) => {
    const api = new GestioneDocumentaleControllerApi(MS_AU_CONFIG);
    const response = await api.getDocument({
      idDocumento,
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const uploadDocumento = createAsyncThunk(
  'gestioneDocumentale/uploadDocumento',
  async (args: GestioneDocumentaleControllerApiUploadDocumentRequest) => {
    const api = new GestioneDocumentaleControllerApi(MS_AU_CONFIG);
    const response = await api.uploadDocument(args);
    return response instanceof AxiosError ? null : response.data;
  }
);

export const documentiSlice = createSlice({
  name: 'gestioneDocumentale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadDocument.pending, (state: any, action: any) => {
      state.downloadedStatus = STATUS_PENDING;
    });
    builder.addCase(downloadDocument.rejected, (state: any, action: any) => {
      state.downloadedStatus = STATUS_REJECTED;
    });
    builder.addCase(downloadDocument.fulfilled, (state: any, action: any) => {
      state.downloadedStatus = STATUS_FULLFILLED;
    });

    builder.addCase(dettaglioDocumento.pending, (state: any, action: any) => {
      state.getDocumentStatus = STATUS_PENDING;
    });
    builder.addCase(dettaglioDocumento.rejected, (state: any, action: any) => {
      state.getDocumentStatus = STATUS_REJECTED;
    });
    builder.addCase(dettaglioDocumento.fulfilled, (state: any, action: any) => {
      state.getDocumentStatus = STATUS_FULLFILLED;
    });

    builder.addCase(uploadDocumento.pending, (state: any, action: any) => {
      state.uploadDocumentoStatus = STATUS_PENDING;
    });
    builder.addCase(uploadDocumento.rejected, (state: any, action: any) => {
      state.uploadDocumentoStatus = STATUS_REJECTED;
    });
    builder.addCase(uploadDocumento.fulfilled, (state: any, action: any) => {
      state.uploadDocumentoStatus = STATUS_FULLFILLED;
    });
  },
});

export default documentiSlice.reducer;
