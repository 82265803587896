/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RegistroImpreseExportRequest } from '../model';
// @ts-ignore
import { RegistroImpreseResultDto } from '../model';
/**
 * RegistroImpreseControllerApi - axios parameter creator
 * @export
 */
export const RegistroImpreseControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {RegistroImpreseExportRequest} registroImpreseExportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _export: async (
      registroImpreseExportRequest: RegistroImpreseExportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'registroImpreseExportRequest' is not null or undefined
      assertParamExists('_export', 'registroImpreseExportRequest', registroImpreseExportRequest);
      const localVarPath = `/v1/registro-imprese/export/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registroImpreseExportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca delle imprese
     * @summary Ricerca impresa
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    multiSearch: async (
      requestBody: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('multiSearch', 'requestBody', requestBody);
      const localVarPath = `/v1/registro-imprese/ricerca-multipla`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca delle imprese
     * @summary Ricerca impresa
     * @param {string} cf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchRegistroImprese: async (
      cf: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cf' is not null or undefined
      assertParamExists('searchRegistroImprese', 'cf', cf);
      const localVarPath = `/v1/registro-imprese/ricerca-singola/{cf}`.replace(
        `{${'cf'}}`,
        encodeURIComponent(String(cf))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RegistroImpreseControllerApi - functional programming interface
 * @export
 */
export const RegistroImpreseControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RegistroImpreseControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {RegistroImpreseExportRequest} registroImpreseExportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _export(
      registroImpreseExportRequest: RegistroImpreseExportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._export(
        registroImpreseExportRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca delle imprese
     * @summary Ricerca impresa
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async multiSearch(
      requestBody: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.multiSearch(requestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca delle imprese
     * @summary Ricerca impresa
     * @param {string} cf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchRegistroImprese(
      cf: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistroImpreseResultDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchRegistroImprese(cf, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RegistroImpreseControllerApi - factory interface
 * @export
 */
export const RegistroImpreseControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RegistroImpreseControllerApiFp(configuration);
  return {
    /**
     *
     * @param {RegistroImpreseControllerApiExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _export(
      requestParameters: RegistroImpreseControllerApiExportRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<string>> {
      return localVarFp
        ._export(requestParameters.registroImpreseExportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca delle imprese
     * @summary Ricerca impresa
     * @param {RegistroImpreseControllerApiMultiSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    multiSearch(
      requestParameters: RegistroImpreseControllerApiMultiSearchRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .multiSearch(requestParameters.requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca delle imprese
     * @summary Ricerca impresa
     * @param {RegistroImpreseControllerApiSearchRegistroImpreseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchRegistroImprese(
      requestParameters: RegistroImpreseControllerApiSearchRegistroImpreseRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<RegistroImpreseResultDto> {
      return localVarFp
        .searchRegistroImprese(requestParameters.cf, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for _export operation in RegistroImpreseControllerApi.
 * @export
 * @interface RegistroImpreseControllerApiExportRequest
 */
export interface RegistroImpreseControllerApiExportRequest {
  /**
   *
   * @type {RegistroImpreseExportRequest}
   * @memberof RegistroImpreseControllerApiExport
   */
  readonly registroImpreseExportRequest: RegistroImpreseExportRequest;
}

/**
 * Request parameters for multiSearch operation in RegistroImpreseControllerApi.
 * @export
 * @interface RegistroImpreseControllerApiMultiSearchRequest
 */
export interface RegistroImpreseControllerApiMultiSearchRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof RegistroImpreseControllerApiMultiSearch
   */
  readonly requestBody: Array<string>;
}

/**
 * Request parameters for searchRegistroImprese operation in RegistroImpreseControllerApi.
 * @export
 * @interface RegistroImpreseControllerApiSearchRegistroImpreseRequest
 */
export interface RegistroImpreseControllerApiSearchRegistroImpreseRequest {
  /**
   *
   * @type {string}
   * @memberof RegistroImpreseControllerApiSearchRegistroImprese
   */
  readonly cf: string;
}

/**
 * RegistroImpreseControllerApi - object-oriented interface
 * @export
 * @class RegistroImpreseControllerApi
 * @extends {BaseAPI}
 */
export class RegistroImpreseControllerApi extends BaseAPI {
  /**
   *
   * @param {RegistroImpreseControllerApiExportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistroImpreseControllerApi
   */
  public _export(
    requestParameters: RegistroImpreseControllerApiExportRequest,
    options?: AxiosRequestConfig
  ) {
    return RegistroImpreseControllerApiFp(this.configuration)
      ._export(requestParameters.registroImpreseExportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca delle imprese
   * @summary Ricerca impresa
   * @param {RegistroImpreseControllerApiMultiSearchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistroImpreseControllerApi
   */
  public multiSearch(
    requestParameters: RegistroImpreseControllerApiMultiSearchRequest,
    options?: AxiosRequestConfig
  ) {
    return RegistroImpreseControllerApiFp(this.configuration)
      .multiSearch(requestParameters.requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca delle imprese
   * @summary Ricerca impresa
   * @param {RegistroImpreseControllerApiSearchRegistroImpreseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistroImpreseControllerApi
   */
  public searchRegistroImprese(
    requestParameters: RegistroImpreseControllerApiSearchRegistroImpreseRequest,
    options?: AxiosRequestConfig
  ) {
    return RegistroImpreseControllerApiFp(this.configuration)
      .searchRegistroImprese(requestParameters.cf, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
