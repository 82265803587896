import { Button, Col, Form, FormGroup, Input, Row, TextArea, Toggle } from 'design-react-kit';
import { useFormik } from 'formik';
import CampoForm from '../campo-form/CampoForm';
import CustomSelect from 'components/custom-select/CustomSelect';
import useConcorsiSelectOptions from 'hooks/useConcorsiSelectOptions';
//import validationSchema from "./validationSchema";
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import { useParams } from 'react-router-dom';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';

export interface IFormInserimentoDatiAssunzioneForm {
  tipologiaContrattoId: string;
  dataAssunzione: string;
  tempoDeterminato: 'Si' | 'No'; //boolean;
  durataMesi?: string;
  determinatoDa?: string;
  determinatoA?: string;
  sedeContruattale?: string;
  notePersonali?: string;
  nominativoAffariGenerali?: string;
  numeroPostiConcorso?: number;
  stipendioTabellare?: string;
  IVCTrienno?: string;
  IndennitaComparto?: string;
  partTime: boolean;
  tipoPartime?: 'Misto' | 'Verticale' | 'Orizzontale';
  percentualePartTime?: string;
  numOre?: string;
  notePartTime?: string;
  articolazione?: string;
  privacy: 'Si' | 'No'; //boolean;
  codiceComportamentale: 'Si' | 'No'; //boolean;
  fondoPerseoSirio: 'Si' | 'No'; //boolean;
  iban: 'Si' | 'No'; //boolean;
  visitaMedica: 'Si' | 'No'; //boolean;
  idoneitaLavorativa: 'Si' | 'No'; //boolean;
}

const initialValues: IFormInserimentoDatiAssunzioneForm = {
  tipologiaContrattoId: '',
  dataAssunzione: '',
  tempoDeterminato: 'No',
  durataMesi: undefined,
  partTime: false,
  tipoPartime: undefined,
  percentualePartTime: undefined,
  numOre: undefined,
  notePartTime: undefined,
  articolazione: undefined,
  privacy: 'No',
  codiceComportamentale: 'No',
  fondoPerseoSirio: 'No',
  iban: 'No',
  visitaMedica: 'No',
  idoneitaLavorativa: 'No',
};

const validationSchema = z.object({
  tipologiaContrattoId: z
    .number({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona una tipologia di contratto'),
  dataAssunzione: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona una data'),
  tempoDeterminato: z.string().optional(),
  durataMesi: z.string().optional(),
  determinatoDa: z.string().optional(),
  determinatoA: z.string().optional(),
  sedeContruattale: z.string().optional(),
  notePersonali: z.string().optional(),
  nominativoAffariGenerali: z.string().optional(),
  numeroPostiConcorso: z.string().optional(),
  stipendioTabellare: z.string().optional(),
  IVCTrienno: z.string().optional(),
  IndennitaComparto: z.string().optional(),
  partTime: z.boolean({
    required_error: 'Obbligatorio',
  }),
  tipoPartime: z.string().optional(),
  percentualePartTime: z.string().optional(),
  numOre: z.string().optional(),
  notePartTime: z.string().optional(),
  articolazione: z.string().optional(),
  privacy: z.enum(['Si', 'No'], {
    errorMap: (issue, ctx) => ({
      message: 'Seleziona Si o No',
    }),
  }),
  codiceComportamentale: z.enum(['Si', 'No'], {
    errorMap: (issue, ctx) => ({
      message: 'Seleziona Si o No',
    }),
  }),
  fondoPerseoSirio: z.enum(['Si', 'No'], {
    errorMap: (issue, ctx) => ({
      message: 'Seleziona Si o No',
    }),
  }),
  iban: z.enum(['Si', 'No'], {
    errorMap: (issue, ctx) => ({
      message: 'Seleziona Si o No',
    }),
  }),
  visitaMedica: z.enum(['Si', 'No'], {
    errorMap: (issue, ctx) => ({
      message: 'Seleziona Si o No',
    }),
  }),
  idoneitaLavorativa: z.enum(['Si', 'No'], {
    errorMap: (issue, ctx) => ({
      message: 'Seleziona Si o No',
    }),
  }),
});

const InserimentoDatiAssunzioneForm = ({
  onSubmit,
  onBackSubmit,
  submitButtonTitle = 'Conferma',
}: {
  onSubmit?: (values: IFormInserimentoDatiAssunzioneForm) => void | Promise<any>;
  onBackSubmit: () => void;
  submitButtonTitle?: string;
}) => {
  const concorsiOptions = useConcorsiSelectOptions();
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);

  const form = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: onSubmit
      ? onSubmit
      : async (values) => {
          if (!onboarding?.id || !candidato?.id) {
            dispatch(
              showNotifica({
                titolo: 'Inserimento dati assunzione non è andato a buon fine',
                stato: 'error',
                messaggio: 'Inserimento dati assunzione non è andato a buon fine',
                tipo: NOTIFICA_STATO.error,
              })
            );
            return;
          }

          //const {onboarding,}

          const updatedOnboarding = {
            ...onboarding,
            tipologiaContrattoId: values.tipologiaContrattoId,
            partTime: values.partTime,
            tipoPartTime: values.tipoPartime,
            notePartTime: values.notePartTime,
            dataAssunzione: values.dataAssunzione,
            note: values.notePersonali,
            tempoIndeterminato: !values.tempoDeterminato,
            durataMesi: values.durataMesi,
            determinatoDa: values.determinatoDa,
            determinatoA: values.determinatoA,
            stipendioTabellare: values.stipendioTabellare,
            numOre: values.numOre,
          };

          try {
            axios.post(
              `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/${onboarding.id}/accettazione/assunzione`,
              {
                ...candidato,
                onboardingConcorsi: [
                  {
                    ...onboarding,
                    ...updatedOnboarding,
                    statoOnb: OnboardingConcorsoStatoOnb.DA_CONVOCARE,
                  },
                ],
              }
            );
            /*  axios.post(
              //`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato.id}/assunzione`,
              `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/${onboarding.id}/accettazione/assunzione`,
              {
                ...candidato,
                onboardingConcorsi: [
                  {
                    ...onboarding,
                    ...updatedOnboarding,
                    statoOnb: OnboardingConcorsoStatoOnb.DA_CONVOCARE,
                  },
                ],
              }
            ); */
            /*    axios.put(
              //`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato.id}/assunzione`,
              `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/rettifica/${candidato?.id}/onboarding/${onboarding.id}`,
              {
                ...candidato,
                onboardingConcorsi: [
                  {
                    ...onboarding,
                    ...updatedOnboarding,
                    statoOnb: OnboardingConcorsoStatoOnb.DA_CONVOCARE,
                  },
                ],
              }
            ); */
            /*   axios.put(
              //`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato.id}/assunzione`,
              `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/${candidato?.id}/onboarding/${onboarding.id}`,
              {
                ...onboarding,
                ...updatedOnboarding,
                statoOnb: OnboardingConcorsoStatoOnb.DA_CONVOCARE,
              }
            ); */
          } catch (error) {
            dispatch(
              showNotifica({
                titolo: 'Inserimento dati assunzione non è andato a buon fine',
                stato: 'error',
                messaggio: 'Inserimento dati assunzione non è andato a buon fine',
                tipo: NOTIFICA_STATO.error,
              })
            );
          }
        },
  });

  return (
    <>
      <CampoForm
        titolo="Dati assunzione"
        style={{
          width: '100%',
          border: '1px solid #06c',
          borderRadius: '12px',
        }}
      >
        <Form>
          <Row className="mt-5">
            <Col md={6}>
              <CustomSelect
                label="Tipologia contratto"
                placeholder="Seleziona il tipo di contratto"
                options={concorsiOptions.tipologieContratto}
                onChange={(option) => {
                  form.setFieldValue('tipologiaContrattoId', option);
                }}
                name="tipologiaContrattoId"
                wrapperClass="col-md required"
                value={form.values.tipologiaContrattoId}
                invalid={!!form.errors.tipologiaContrattoId}
                infoText={form.errors.tipologiaContrattoId}
              />
            </Col>
            <Col md={6}>
              <Input
                id="dataAssunzione"
                name="dataAssunzione"
                type="date"
                label="Data assunzione"
                className="active"
                placeholder="gg/mm/aaaa"
                value={form.values.dataAssunzione}
                onChange={(e) => form.setFieldValue('dataAssunzione', e.target.value)}
                wrapperClass="required"
                invalid={!!form.errors.dataAssunzione}
                infoText={form.errors.dataAssunzione}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <CustomSelect
                  label="Tempo Determinato"
                  placeholder="Seleziona tempo determinato"
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Si', label: 'Si' },
                  ]}
                  onChange={(option) => {
                    form.setFieldValue('tempoDeterminato', option);
                  }}
                  name="tempoDeterminato"
                  wrapperClass="col-md required"
                  value={form.values.tempoDeterminato}
                  aria-describedby="tempoDeterminatoError"
                  invalid={!!form.errors.tempoDeterminato}
                  infoText={form.errors.tempoDeterminato}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Input
                type="text"
                id="durataMesi"
                name="durataMesi"
                label="Durata in mesi"
                maxLength={5}
                value={form.values.durataMesi}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Durata in mesi"
                invalid={!!form.errors.durataMesi}
                infoText={form.errors.durataMesi}
              />
            </Col>
            <Col md={4}>
              <Input
                id="determinatoDa"
                name="determinatoDa"
                type="date"
                label="Data dal"
                className="active"
                placeholder="gg/mm/aaaa"
                value={form.values.determinatoDa}
                onChange={(e) => form.setFieldValue('determinatoDa', e.target.value)}
                invalid={!!form.errors.determinatoDa}
                infoText={form.errors.determinatoDa}
              />
            </Col>
            <Col md={4}>
              <Input
                id="determinatoA"
                name="determinatoA"
                type="date"
                label="Data al"
                className="active"
                placeholder="gg/mm/aaaa"
                value={form.values.determinatoA}
                onChange={(e) => form.setFieldValue('determinatoA', e.target.value)}
                invalid={!!form.errors.determinatoA}
                infoText={form.errors.determinatoA}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <CustomSelect
                label="Sede Contrattuale"
                placeholder="Seleziona sede"
                options={[
                  { value: 'identityCard', label: "Carta d'identità" },
                  { value: 'passport', label: 'Passaporto' },
                  { value: 'driverLicense', label: 'Patente di guida' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('sedeContruattale', option);
                }}
                name="sedeContruattale"
                wrapperClass="col-md"
                value={form.values.sedeContruattale}
                invalid={!!form.errors.sedeContruattale}
                infoText={form.errors.sedeContruattale}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextArea
                label="Note Personale"
                placeholder="Inserisci note personale"
                id="notePersonali"
                value={form.values.notePersonali ?? ''}
                onChange={form.handleChange}
                wrapperClassName="form-group col-md-12"
                name="notePersonali"
                invalid={!!form.errors.notePersonali}
                infoText={form.errors.notePersonali}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomSelect
                label="Nominativo U.O. Affari Generali"
                placeholder="Seleziona nominativo"
                options={[
                  { value: 'identityCard', label: "Carta d'identità" },
                  { value: 'passport', label: 'Passaporto' },
                  { value: 'driverLicense', label: 'Patente di guida' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('nominativoAffariGenerali', option);
                }}
                name="nominativoAffariGenerali"
                wrapperClass="col-md"
                value={form.values.nominativoAffariGenerali}
                invalid={!!form.errors.nominativoAffariGenerali}
                infoText={form.errors.nominativoAffariGenerali}
              />
            </Col>
            <Col md={6}>
              <Input
                type="text"
                id="numeroPostiConcorso"
                name="numeroPostiConcorso"
                label="Numero posti messi a concorso"
                maxLength={5}
                value={form.values.numeroPostiConcorso}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Numero posti messi a concorso"
                invalid={!!form.errors.numeroPostiConcorso}
                infoText={form.errors.numeroPostiConcorso}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Input
                type="text"
                id="stipendioTabellare"
                name="stipendioTabellare"
                label="Stipendio Tabellare"
                maxLength={5}
                value={form.values.stipendioTabellare}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Inserisci stipendio tabellare"
                invalid={!!form.errors.stipendioTabellare}
                infoText={form.errors.stipendioTabellare}
              />
            </Col>
            <Col md={4}>
              <Input
                type="text"
                id="IVCTrienno"
                name="IVCTrienno"
                label="IVC Trienno"
                maxLength={5}
                value={form.values.IVCTrienno}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Inserisci IVC Trienno"
                invalid={!!form.errors.IVCTrienno}
                infoText={form.errors.IVCTrienno}
              />
            </Col>
            <Col md={4}>
              <Input
                type="text"
                id="IndennitaComparto"
                name="IndennitaComparto"
                label="Indennità comparto"
                maxLength={5}
                value={form.values.IndennitaComparto}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Inserisci indennità comparto"
                invalid={!!form.errors.IndennitaComparto}
                infoText={form.errors.IndennitaComparto}
              />
            </Col>
          </Row>
          <Row>
            <Col md={1.5}>
              <Toggle
                label="Part-time"
                id="partTime"
                name="partTime"
                checked={form.values.partTime}
                onChange={form.handleChange}
                invalid={!!form.errors.partTime}
                infoText={form.errors.partTime}
              />
            </Col>
            <Col md={10}>
              <CustomSelect
                label="Tipologia di part time"
                placeholder="Seleziona tipologia part time"
                options={[
                  { value: 'VERTICALE', label: 'Verticale' },
                  { value: 'ORIZZONTALE', label: 'Orizzontale' },
                  { value: 'MISTO', label: 'Misto' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('tipoPartime', option);
                }}
                name="tipoPartime"
                wrapperClass="col-md"
                value={form.values.tipoPartime}
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Input
                type="text"
                id="percentualePartTime"
                name="percentualePartTime"
                label="Percentuale del part time"
                maxLength={5}
                value={form.values.percentualePartTime}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Inserisci percentuale part time"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
            <Col md={6}>
              <Input
                type="text"
                id="numOre"
                name="numOre"
                label="Numero ore"
                maxLength={5}
                value={form.values.numOre}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Numero ore"
                invalid={!!form.errors.numOre}
                infoText={form.errors.numOre}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextArea
                label="Note part time"
                placeholder="Inserisci note part time"
                id="notePartTime"
                value={form.values.notePartTime ?? ''}
                onChange={form.handleChange}
                wrapperClassName="form-group col-md-12"
                name="notePartTime"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextArea
                label="Articolazione"
                placeholder="Inserisci articolazione per part time"
                id="articolazione"
                value={form.values.articolazione ?? ''}
                onChange={form.handleChange}
                wrapperClassName="form-group col-md-12"
                name="articolazione"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row>
          {/*         <Row>
            <Col md={12}>
              <CustomSelect
                label="Scelta documenti da inviare"
                placeholder="Seleziona documenti da inviare"
                options={[
                  { value: "VERTICALE", label: "Verticale" },
                  { value: "ORIZZONTALE", label: "Orizzontale" },
                  { value: "MISTO", label: "Misto" },
                ]}
                onChange={(option) => {
                  form.setFieldValue("documenti", option);
                }}
                name="tipoPartime"
                wrapperClass="col-md"
                value={form.values.documenti[0]?.name ?? ""}
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row> */}
          <Row className="mb-5 ml-2">
            <h6>Scelta documenti da visionare o compilare</h6>
          </Row>
          <Row>
            <Col md={6}>
              <CustomSelect
                label="Richiesta di presa visione Privacy"
                placeholder="Richiesta di presa visione Privacy"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('privacy', option);
                }}
                name="privacy"
                wrapperClass="col-md required"
                value={form.values.privacy}
                aria-describedby="privacyError"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                label="Richiesta di presa visione Codice comportamentale"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('codiceComportamentale', option);
                }}
                name="codiceComportamentale"
                wrapperClass="col-md required"
                value={form.values.codiceComportamentale}
                aria-describedby="codiceComportamentaleError"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomSelect
                label="Scelta fondo Perseo-Sirio"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('fondoPerseoSirio', option);
                }}
                name="fondoPerseoSirio"
                wrapperClass="col-md required"
                value={form.values.fondoPerseoSirio}
                aria-describedby="fondoPerseoSirioError"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                label="Scelta fondo Perseo-Sirio"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('fondoPerseoSirio', option);
                }}
                name="fondoPerseoSirio"
                wrapperClass="col-md required"
                value={form.values.fondoPerseoSirio}
                aria-describedby="fondoPerseoSirioError"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomSelect
                label="Inserimento IBAN accredito stipendio"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('iban', option);
                }}
                name="iban"
                wrapperClass="col-md required"
                value={form.values.iban}
                aria-describedby="ibanError"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
            <Col md={6}>
              <CustomSelect
                label="Sottoscrizione impegno a ad effettuare la visita medica"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  form.setFieldValue('visitaMedica', option);
                }}
                name="visitaMedica"
                wrapperClass="col-md required"
                value={form.values.visitaMedica}
                aria-describedby="visitaMedicaError"
                invalid={!!form.errors.tipoPartime}
                infoText={form.errors.tipoPartime}
              />
            </Col>
          </Row>
        </Form>
      </CampoForm>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '10px',
          paddingBottom: '15px',
        }}
      >
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            form.submitForm();
          }}
        >
          {submitButtonTitle}
        </Button>
        <Button outline color="primary" size="sm" onClick={onBackSubmit}>
          Indietro
        </Button>
      </div>
    </>
  );
};

export default InserimentoDatiAssunzioneForm;
