import { AssegnazioneBandiPoDto } from '../../services/ms-anagrafica-unica';
import { formatDate } from '../../utility/formatDate';

const BandoDetail = (props: { bando: AssegnazioneBandiPoDto }) => {
  const { bando } = props;
  return (
    <div className="col-sm-12">
      <div className="card-div row">
        <div className="col-sm-12">
          <div className="info-box-light bg-light-gray">
            <h5 className="pb-2 border-bottom font-weight-bold">Riepilogo Bando</h5>
            <dl className="border-bottom py-2">
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <dt>Denominazione bando</dt>
                  <dd>{bando.nomeBando ? bando.nomeBando : ' - '}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Identificativo</dt>
                  <dd>{bando.idBando ? bando.idBando : ' - '}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Apertura candidature</dt>
                  <dd>{formatDate(bando.dataApertura!, false, ' - ')}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Chiusura candidature</dt>
                  <dd>{formatDate(bando.dataChiusura!, false, ' - ')}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Identificativo EQ</dt>
                  <dd>
                    {bando.posizioneOrganizzativa?.codiceUnivoco
                      ? bando.posizioneOrganizzativa.codiceUnivoco
                      : ' - '}
                  </dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Tipo EQ</dt>
                  <dd>
                    {bando.posizioneOrganizzativa?.tipoLabel
                      ? bando.posizioneOrganizzativa.tipoLabel
                      : ' - '}
                  </dd>
                </div>
              </div>
            </dl>

            <dl className="border-bottom py-2">
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <dt>Requisiti oggettivi</dt>
                  <dd>{bando.requisitiOggettivi ? bando.requisitiOggettivi : ' - '}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Requisiti soggettivi</dt>
                  <dd>{bando.requisitiSoggettivi ? bando.requisitiSoggettivi : ' - '}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Tipologia</dt>
                  <dd>{bando.tipologiaBandoPoLabel ? bando.tipologiaBandoPoLabel : ' - '}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Durata dell'incarico</dt>
                  <dd>{bando.durataIncarico ? `${bando.durataIncarico} mesi` : ' - '}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Sedi</dt>
                  <dd>{bando.sedi && bando.sedi.length > 0 ? bando.sedi.join(', ') : '-'}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Codice Cifra</dt>
                  <dd>{bando.codiceCifra ? bando.codiceCifra : ' - '}</dd>
                </div>
                {bando.posizioneOrganizzativa?.denominazione && (
                  <div className="col-sm-12">
                    <dt>Denominazione EQ</dt>
                    <dd>
                      {bando.posizioneOrganizzativa?.denominazione
                        ? bando.posizioneOrganizzativa?.denominazione
                        : '-'}
                      <br />
                      {bando.posizioneOrganizzativa?.descrizione}
                    </dd>
                  </div>
                )}
              </div>
            </dl>

            <dl className="py-2">
              <div className="row">
                {bando.posizioneOrganizzativa?.declaratoriaFunzioni && (
                  <div className="col-sm-12">
                    <dt>Descrizione funzioni</dt>
                    <dd>
                      {bando.posizioneOrganizzativa?.declaratoriaFunzioni
                        ? bando.posizioneOrganizzativa?.declaratoriaFunzioni
                        : '-'}
                    </dd>
                  </div>
                )}
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandoDetail;
