import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  LinkList,
  LinkListItem,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { FormikProvider, useFormik } from 'formik';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useExcelExportWithFilters } from '../../hooks/useExcelExport';
import useConcorsiSelectOptions from '../../hooks/useConcorsiSelectOptions';
import CustomSelect from 'components/common/custom-select/CustomSelect';
import PageHeader from 'components/common/page-header/PageHeader';
import Pagination from 'components/common/pagination/Pagination';
import {
  HREF_INSERIMENTO_CANDIDATO,
  HREF_VISUALIZZA_CANDIDATO,
  HREF_CONVALIDA_DATI,
  HREF_VERIFICA_DATI,
  HREF_VERIFICA_INTEGRAZIONE_DATI,
  HREF_INSERIMENTO_DATI_ASSUNZIONE,
  HREF_GEST_ACCETTAZIONE_CONTRATTO,
  HREF_CONVOCAZIONE_SINGOLA,
  HREF_INSERIMENTO_CONCORSO,
  HREF_VALIDAZIONE_DATI_DOCUMENTI,
  HREF_GESTIONE_CONTRATTUALIZZAZIONE,
  HREF_MODIFICA_CANDIDATO,
  HREF_CONFERIMENTO_DOCUMENTI,
  HREF_TROVA_CANDIDATO,
} from 'components/layout/sidemenu/sidemenuConstants';
import { ICandidatoConcorso } from 'hooks/services/useGetCandidatoByCodiceFiscale';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';
import {
  IFormInvioConvocazioneForm,
  InvioConvocazioneModal,
  RiepilogoModal,
} from './InvioConvocazioneSingola';
import { RuoloUtenteAutenticatoDto } from 'services/ms-anagrafica-unica';
import { OnBoardingConcorsoDto } from 'components/crea-candidato-form/types';
import { useAppSelector } from 'hooks';
import deleteCandidato from 'components/forms/utils/deleteCandidato';

export const MAP_RUOLI = {
  UFFICIO_RECLUTAMENTO: 'UFFICIO_RECLUTAMENTO',
  UFFICIO_CONCORSI: 'UFFICIO_CONCORSI',
  CANDIDATO: 'CANDIDATO',
  SYS_ADMIN: 'SYS_ADMIN',
} as const;

const MultiState: React.FC<{ onboarding: OnBoardingConcorsoDto[] }> = ({
  onboarding,
}: {
  onboarding: OnBoardingConcorsoDto[];
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const targetRef = useRef(null);

  const togglePopover = () => {
    setPopoverOpen((prevState) => !prevState);
  };

  return (
    <div className="d-flex">
      <span className="mr-2">Stati</span>{' '}
      <div
        style={{ width: 'fit-content' }}
        ref={targetRef}
        onMouseLeave={() => setPopoverOpen(false)}
        onMouseEnter={() => setPopoverOpen(true)}
      >
        <Icon icon="it-info-circle" />
      </div>
      <Popover placement="right" target={targetRef} isOpen={popoverOpen} toggle={togglePopover}>
        <PopoverHeader>
          <Icon color="primary" icon="it-info-circle" />
          <b>Stato concorsi</b>
        </PopoverHeader>
        <PopoverBody>
          {onboarding.map((concorso) => (
            <div key={concorso.id}>
              {concorso.nomeConcorso} - {concorso.stato}
            </div>
          ))}
        </PopoverBody>
      </Popover>
    </div>
  );
};

const schema = z.object({
  cognome: z.string().max(200, { message: 'Massimo 200 caratteri' }).optional(),
  nome: z.string().max(500, { message: 'Massimo 500 caratteri' }).optional(),
  codiceFiscale: z.string().max(16, { message: 'Massimo 16 caratteri' }).optional(),
});

// Define a type for the response from the API
interface CandidatiResponse {
  content: ICandidatoConcorso[];
  size: number;
  number: number;
  empty: boolean;
  totalElements: number;
  totalPages: number;
  first: boolean;
  last: boolean;
}

export const OnboardingConcorsoStatoLabels: Record<OnboardingConcorsoStatoOnb, string> = {
  [OnboardingConcorsoStatoOnb.BOZZA]: 'Bozza',
  [OnboardingConcorsoStatoOnb.INSERITO]: 'Inserito',
  [OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO]: 'Modificato Reclutamento',
  [OnboardingConcorsoStatoOnb.RICHIESTA_RETTIFICA]: 'Richiesta Rettifica',
  [OnboardingConcorsoStatoOnb.RICHIESTA_INTEGRAZIONE]: 'Richiesta Integrazione',
  [OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO]: 'Convalida Candidato',
  [OnboardingConcorsoStatoOnb.RINUNCIATARIO]: 'Rinunciatario',
  [OnboardingConcorsoStatoOnb.DA_CONVOCARE]: 'Da Convocare',
  [OnboardingConcorsoStatoOnb.CONVOCATO]: 'Convocato',
  [OnboardingConcorsoStatoOnb.NON_CONTRATTUALIZZABILE]: 'Non Contrattualizzabile',
  [OnboardingConcorsoStatoOnb.CONTRATTUALIZZATO]: 'Contrattualizzato',
};

const ElencoCandidati = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const { t } = useTranslation();
  const { userInfoResult } = useAppSelector((state) => state.user);
  const [formCollapseOpenElencoCandidati, setFormCollapseOpenElencoCandidati] = useState(false);
  const navigate = useNavigate();
  const [nome, setNome] = useState<string>('');
  const [cognome, setCognome] = useState<string>('');
  const [cf, setCf] = useState<string>('');
  const [concorsoId, setConcorsoId] = useState<string | undefined>(undefined);
  const [stato, setStato] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(0); // Mandatory integer
  const [size] = useState<number>(10); // Mandatory integer
  const [response, setResponse] = useState<CandidatiResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const endpoint = `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidati`;

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const concorsiOptions = useConcorsiSelectOptions();
  const { loading, error, exportToExcel } = useExcelExportWithFilters(
    endpoint,
    { nome, cognome, cf },
    size,
    'CandidatiExport'
  );

  useEffect(() => {
    const fetchCandidati = async () => {
      setIsLoading(true);
      try {
        const params: Record<string, string | number> = {
          page: page + 1,
          size,
        };

        if (nome) params.nome = nome;
        if (cognome) params.cognome = cognome;
        if (cf) params.cf = cf;
        if (concorsoId) params.concorsoId = concorsoId;
        if (stato) params.stato = stato;

        const response = await axios.get<CandidatiResponse>(
          `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidati`,
          {
            params,
          }
        );

        // @ts-ignore
        setResponse(response.data);
      } catch (error) {
        console.error('Error fetching candidati:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCandidati();
  }, [page, size, nome, cognome, cf, concorsoId, stato]);

  const searchFormElencoCandidati = useFormik({
    initialValues: {
      nome: '',
      cognome: '',
      codiceFiscale: '',
      concorsoId: undefined,
      stato: undefined,
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      setNome(values.nome);
      setCognome(values.cognome);
      setCf(values.codiceFiscale);
      setConcorsoId(values.concorsoId);
      setStato(values.stato);
    },
  });

  const searchStartKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      searchFormElencoCandidati.handleSubmit();
    }
  };

  const handlePageChange = (page: number) => {
    console.log('Handle page change', page);
    setPage(page - 1);
  };

  const nuovoCandidato = () => {
    navigate(HREF_TROVA_CANDIDATO);
  };

  const nuovoConcorso = () => {
    navigate(HREF_INSERIMENTO_CONCORSO);
  };

  const [invioConvocazioneValues, setInvioConvocazioneValues] = useState<
    undefined | IFormInvioConvocazioneForm
  >();
  const [openModalInvioConvocazione, setOpenModalInvioConvocazione] = useState(false);
  const handleOpenModalInvioConvocazione = () => {
    setOpenModalInvioConvocazione(true);
  };
  const handleCloseModalInvioConvocazione = () => {
    setOpenModalInvioConvocazione(false);
  };

  const [openModalRiepilogo, setOpenModalRiepilogo] = useState(false);
  const handleOpenModalRiepilogo = () => {
    setOpenModalRiepilogo(true);
  };
  const handleCloseModalRiepilogo = () => {
    setOpenModalRiepilogo(false);
  };

  const handleInvioConvocazioneSubmit = (values: IFormInvioConvocazioneForm) => {
    console.log('values IFormInvioConvocazioneForm', values);
    setInvioConvocazioneValues(values);
    handleCloseModalInvioConvocazione();
    handleOpenModalRiepilogo();
  };

  const handleRiepilogoSubmit = () => {
    console.log('values handleRiepilogoSubmit');
    handleCloseModalRiepilogo();
  };

  // Handle selecting/deselecting a single row
  const handleSelectRow = (id: number) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle selecting/deselecting all rows
  const handleSelectAll = () => {
    if (selectedRows.length === response?.content?.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(response?.content?.map((item) => item.id) || []); // Select all
    }
  };

  const handleInviaConvocazione = ({
    codiceFiscale,
    onboardingId,
  }: {
    codiceFiscale: string;
    onboardingId: number;
  }) => {
    // Invia convocazione massiva
    if (selectedRows.length > 1) {
      handleOpenModalInvioConvocazione();
    }
    // Invia convocazione singola
    else {
      navigate(
        `${HREF_CONVOCAZIONE_SINGOLA}/codiceFiscale/${codiceFiscale}/onboarding/${onboardingId}`
      );
    }
  };

  return (
    <div>
      <PageHeader
        showIcon={false}
        title={t('elenco candidati')!}
        buttons={[
          {
            buttonProperties: { outline: true },
            title: 'Nuovo candidato',
            buttonColor: 'primary',
            action: nuovoCandidato,
            showButtonIcon: true,
            icon: 'it-plus',
            iconColor: 'primary',
          },
          {
            buttonProperties: { outline: true },
            title: 'Nuovo Concorso',
            buttonColor: 'primary',
            action: nuovoConcorso,
            showButtonIcon: true,
            icon: 'it-plus',
            iconColor: 'primary',
          },
        ]}
      />

      <Accordion background="active">
        <AccordionHeader
          active={formCollapseOpenElencoCandidati}
          onToggle={() => setFormCollapseOpenElencoCandidati(!formCollapseOpenElencoCandidati)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpenElencoCandidati}>
          <FormikProvider value={searchFormElencoCandidati}>
            <div className="form-row mt-5">
              <Input
                type="text"
                label="Nome"
                id="input-nome"
                placeholder="Inserisci un nome"
                wrapperClassName="col col-md-6"
                name="nome"
                invalid={!!searchFormElencoCandidati.errors.nome}
                infoText={t(searchFormElencoCandidati.errors.nome || '') || ''}
                value={searchFormElencoCandidati.values.nome}
                onChange={searchFormElencoCandidati.handleChange}
                onKeyUp={searchStartKeyPress}
              />
              <Input
                type="text"
                label="Cognome"
                id="input-cognome"
                placeholder="Inserisci un cognome"
                wrapperClassName="col col-md-6"
                name="cognome"
                invalid={!!searchFormElencoCandidati.errors.cognome}
                infoText={t(searchFormElencoCandidati.errors.cognome || '') || ''}
                value={searchFormElencoCandidati.values.cognome}
                onChange={searchFormElencoCandidati.handleChange}
                onKeyUp={searchStartKeyPress}
              />

              <Input
                type="text"
                label="Codice fiscale"
                id="input-codiceFiscale"
                placeholder="Inserisci un codice fiscale"
                wrapperClassName="col col-md-6"
                name="codiceFiscale"
                max={16}
                maxLength={16}
                invalid={!!searchFormElencoCandidati.errors.codiceFiscale}
                infoText={t(searchFormElencoCandidati.errors.codiceFiscale || '') || ''}
                value={searchFormElencoCandidati.values.codiceFiscale}
                onChange={searchFormElencoCandidati.handleChange}
                onKeyUp={searchStartKeyPress}
              />
              {concorsiOptions?.concorsi && (
                <div className="col-md-6">
                  <CustomSelect
                    label="Nome Concorso"
                    placeholder="Nome Concorso"
                    options={
                      concorsiOptions?.concorsi.map(({ value, label }) => ({
                        value,
                        label,
                      })) || []
                    }
                    onChange={(e) => setConcorsoId(e)}
                    name="idConcorso"
                    wrapperClass="col-md required"
                    value={concorsoId}
                  />
                </div>
              )}
            </div>
            <Row>
              <div className="col-md-6">
                <CustomSelect
                  label="Stato Concorso"
                  placeholder="Stato Concorso"
                  options={[
                    {
                      value: 'INC',
                      label: 'In Compilazione',
                    },
                    {
                      value: 'COM',
                      label: 'Compilato',
                    },
                  ]}
                  onChange={(e) => setStato(e)}
                  name="stato"
                  wrapperClass="col-md required"
                  value={stato}
                />
              </div>
            </Row>
          </FormikProvider>

          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                setPage(0);
                searchFormElencoCandidati.handleSubmit();
              }}
            >
              Applica
            </Button>
            <Button
              outline
              color="primary"
              className="mt-2 mr-2"
              onClick={searchFormElencoCandidati.handleReset}
            >
              Pulisci
            </Button>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-2">
        <div>
          {/* Show loading indicator */}
          {isLoading && <p>Loading...</p>}
          {response?.content?.length === 0 && (
            <Alert color="info">Nessun risultato disponibile</Alert>
          )}

          {response?.content && response.content?.length > 0 && (
            <div className={response?.totalPages === 1 ? 'mb-5' : ''}>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        checked={selectedRows.length === response.content.length}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th scope="col" style={{ width: '8%' }}>
                      #
                    </th>
                    <th scope="col">
                      <div className="table-order">Codice fiscale</div>
                    </th>
                    <th scope="col">
                      <div className="table-order">Cognome</div>
                    </th>
                    <th scope="col">
                      <div className="table-order">Nome</div>
                    </th>
                    <th scope="col">
                      <div className="table-order">Concorso</div>
                    </th>
                    <th scope="col">
                      <div className="table-order">Stato</div>
                    </th>
                    <th scope="col">Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {response?.content?.flatMap((x, index) =>
                    x.onboardingConcorsi?.map((concorso, concorsoIndex) => {
                      if (
                        /* userInfoResult?.ruoloAttivo?.codice === MAP_RUOLI.UFFICIO_CONCORSI &&
                        (concorso.statoOnb === OnboardingConcorsoStatoOnb.BOZZA ||
                          concorso.statoOnb === OnboardingConcorsoStatoOnb.INSERITO) */
                        true
                      )
                        return (
                          <tr key={x.id}>
                            <td>
                              <input
                                id={`${x.id}`}
                                name={`input-${x.id}`}
                                type="checkbox"
                                checked={selectedRows.includes(x.id)}
                                onChange={() => handleSelectRow(x.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{x?.codiceFiscale}</td>
                            <td>{x?.cognome}</td>
                            <td>{x?.nome}</td>
                            <td>
                              {x.onboardingConcorsi &&
                                x.onboardingConcorsi?.length > 0 &&
                                x.onboardingConcorsi.map((c) => (
                                  <span key={c.id}>Concorso {c.idConcorso ?? '---'}</span>
                                ))}
                            </td>
                            <td>{OnboardingConcorsoStatoLabels[concorso.statoOnb]}</td>
                            <td>
                              <UncontrolledDropdown direction="left" className="full-width">
                                <DropdownToggle nav>
                                  <Icon icon="it-more-items" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="no-arrow"
                                  style={{
                                    zIndex: '999 !important',
                                  }}
                                >
                                  <LinkList>
                                    <LinkListItem
                                      size="medium"
                                      onClick={() =>
                                        navigate(
                                          `${HREF_VISUALIZZA_CANDIDATO}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '5px',
                                        }}
                                      >
                                        <Icon icon="it-pencil" size="xs" />
                                        <span>Visualizza</span>
                                      </div>
                                    </LinkListItem>
                                    {(concorso.statoOnb === OnboardingConcorsoStatoOnb.BOZZA ||
                                      concorso.statoOnb === OnboardingConcorsoStatoOnb.INSERITO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.DA_CONVOCARE ||
                                      concorso.statoOnb === OnboardingConcorsoStatoOnb.CONVOCATO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.CONTRATTUALIZZATO) && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_MODIFICA_CANDIDATO}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Modifica</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {concorso.statoOnb === OnboardingConcorsoStatoOnb.BOZZA && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() => {
                                          deleteCandidato(x.id);
                                          setResponse((prevCandidati) =>
                                            prevCandidati
                                              ? {
                                                  ...prevCandidati,
                                                  content: prevCandidati.content?.filter(
                                                    (c) => c.id !== x.id
                                                  ),
                                                }
                                              : prevCandidati
                                          );
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Elimina</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {concorso.statoOnb === OnboardingConcorsoStatoOnb.INSERITO && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_CONVALIDA_DATI}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Convalida dati</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                  </LinkList>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );

                      if (
                        userInfoResult?.ruoloAttivo?.codice === MAP_RUOLI.UFFICIO_RECLUTAMENTO &&
                        concorso.statoOnb !== OnboardingConcorsoStatoOnb.BOZZA
                      )
                        return (
                          <tr key={x.id}>
                            <td>
                              <input
                                id={`${x.id}`}
                                name={`input-${x.id}`}
                                type="checkbox"
                                checked={selectedRows.includes(x.id)}
                                onChange={() => handleSelectRow(x.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{x?.codiceFiscale}</td>
                            <td>{x?.cognome}</td>
                            <td>{x?.nome}</td>
                            <td>
                              {x.onboardingConcorsi &&
                                x.onboardingConcorsi?.length > 0 &&
                                x.onboardingConcorsi.map((c) => (
                                  <span key={c.id}>Concorso {c.idConcorso ?? '---'}</span>
                                ))}
                            </td>
                            <td>{OnboardingConcorsoStatoLabels[concorso.statoOnb]}</td>
                            <td>
                              <UncontrolledDropdown direction="left" className="full-width">
                                <DropdownToggle nav>
                                  <Icon icon="it-more-items" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="no-arrow"
                                  style={{
                                    zIndex: '999 !important',
                                  }}
                                >
                                  <LinkList>
                                    <LinkListItem
                                      size="medium"
                                      onClick={() =>
                                        navigate(
                                          `${HREF_VISUALIZZA_CANDIDATO}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '5px',
                                        }}
                                      >
                                        <Icon icon="it-pencil" size="xs" />
                                        <span>Visualizza</span>
                                      </div>
                                    </LinkListItem>
                                    {
                                      //concorso.statoOnb === OnboardingConcorsoStatoOnb.BOZZA ||
                                      (concorso.statoOnb === OnboardingConcorsoStatoOnb.INSERITO ||
                                        concorso.statoOnb ===
                                          OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO ||
                                        concorso.statoOnb ===
                                          OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO ||
                                        concorso.statoOnb ===
                                          OnboardingConcorsoStatoOnb.DA_CONVOCARE ||
                                        concorso.statoOnb ===
                                          OnboardingConcorsoStatoOnb.CONVOCATO ||
                                        concorso.statoOnb ===
                                          OnboardingConcorsoStatoOnb.CONTRATTUALIZZATO) && (
                                        <LinkListItem
                                          size="medium"
                                          onClick={() =>
                                            navigate(
                                              `${HREF_MODIFICA_CANDIDATO}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '5px',
                                            }}
                                          >
                                            <Icon icon="it-pencil" size="xs" />
                                            <span>Modifica</span>
                                          </div>
                                        </LinkListItem>
                                      )
                                    }
                                    {(concorso.statoOnb === OnboardingConcorsoStatoOnb.INSERITO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO) && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_CONVALIDA_DATI}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Convalida dati</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {concorso.statoOnb ===
                                      OnboardingConcorsoStatoOnb.RICHIESTA_INTEGRAZIONE && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_VERIFICA_INTEGRAZIONE_DATI}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Verifica ed integrazione dati (candidato)</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {(concorso.statoOnb ===
                                      OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO) && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_VALIDAZIONE_DATI_DOCUMENTI}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Validazione dati e documenti</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {concorso.statoOnb ===
                                      OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO ||
                                      (concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO && (
                                        <LinkListItem
                                          size="medium"
                                          onClick={() =>
                                            navigate(
                                              `${HREF_VERIFICA_DATI}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '5px',
                                            }}
                                          >
                                            <Icon icon="it-pencil" size="xs" />
                                            <span>Verifica dati</span>
                                          </div>
                                        </LinkListItem>
                                      ))}
                                    {(concorso.statoOnb ===
                                      OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO) && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_INSERIMENTO_DATI_ASSUNZIONE}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Inserisci dati assunzione</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {(concorso.statoOnb ===
                                      OnboardingConcorsoStatoOnb.DA_CONVOCARE ||
                                      concorso.statoOnb ===
                                        OnboardingConcorsoStatoOnb.CONVOCATO) && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          handleInviaConvocazione({
                                            codiceFiscale: x.codiceFiscale,
                                            onboardingId: concorso.id,
                                          })
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Invia convocazione</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {concorso.statoOnb === OnboardingConcorsoStatoOnb.CONVOCATO && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_CONFERIMENTO_DOCUMENTI}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Conferimento documenti (candidato)</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {concorso.statoOnb === OnboardingConcorsoStatoOnb.CONVOCATO && (
                                      <LinkListItem
                                        size="medium"
                                        onClick={() =>
                                          navigate(
                                            `${HREF_GESTIONE_CONTRATTUALIZZAZIONE}/codiceFiscale/${x.codiceFiscale}/onboarding/${concorso.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                          }}
                                        >
                                          <Icon icon="it-pencil" size="xs" />
                                          <span>Gestione contrattualizzazione</span>
                                        </div>
                                      </LinkListItem>
                                    )}
                                    {/*   
                                
                                <div className="mb-2" />
                                <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(
                                      `${HREF_GESTIONE_CONTRATTUALIZZAZIONE}/${x.codiceFiscale}`
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Icon icon="it-pencil" size="xs" />
                                    <span>Gestione contrattualizzazione</span>
                                  </div>
                                </LinkListItem> */}
                                    {/*        <div className="mb-2" />
                                <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(
                                      `${HREF_GESTIONE_RINUNCIA}/${x.codiceFiscale}`
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Icon icon="it-pencil" size="xs" />
                                    <span>Gestione rinuncia</span>
                                  </div>
                                </LinkListItem>
                               */}
                                    {/* <LinkListItem
                                size="medium"
                                onClick={() =>
                                  navigate(
                                    `${HREF_VERIFICA_DOC_CANDIDATO}/${x.codiceFiscale}`
                                  )
                                }
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Icon icon="it-pencil" size="xs" />
                                  <span>Verifica dati e documenti</span>
                                </div>
                              </LinkListItem>
                              <div className="mb-2" /> */}
                                    {/*  <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(
                                      `${HREF_VERIFICA_DOC_CANDIDATO}/${
                                        x.codiceFiscale
                                      }/${
                                        x.onboardingConcorsi?.at(0)?.idConcorso
                                      }`
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Icon icon="it-pencil" size="xs" />
                                    <span>Verifica dati e documenti</span>
                                  </div>
                                </LinkListItem>
                                */}

                                    {/*     <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(
                                      `${HREF_VISUALIZZA_CANDIDATO}/${
                                        x.codiceFiscale
                                      }/${
                                        x.onboardingConcorsi?.at(0)?.idConcorso
                                      }`
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <Icon icon="it-zoom-in" size="xs" />
                                    <span>Visualizza</span>
                                  </div>
                                </LinkListItem> */}
                                  </LinkList>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                    })
                  )}
                </tbody>
              </Table>
              <Pagination
                totalCount={response.totalElements || 0}
                siblingCount={1}
                currentPage={page + 1}
                pageSize={response.size || 10}
                onPageChange={(page: number) => {
                  handlePageChange(page);
                }}
                className="justify-content-center"
              />
              <div>
                <Button color="primary" className="mt-2" onClick={exportToExcel} disabled={loading}>
                  {loading ? 'Esportando...' : 'Esporta tabella in excel'}
                </Button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>
            </div>
          )}
        </div>
      </div>
      <InvioConvocazioneModal
        isOpen={openModalInvioConvocazione}
        closeModal={handleCloseModalInvioConvocazione}
        onSubmit={handleInvioConvocazioneSubmit}
      />
      <RiepilogoModal
        values={invioConvocazioneValues}
        isOpen={openModalRiepilogo}
        closeModal={handleCloseModalRiepilogo}
        onSubmit={handleRiepilogoSubmit}
      />
    </div>
  );
};

export default ElencoCandidati;