import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RIDatiIdentificativiComponent from '../ri-dati-identificativi-component/RIDatiIdentificativiComponent';
import RIInformazioniAttivitaComponent from '../ri-informazioni-attivita-component/RIInformazioniAttivitaComponent';
import RIInformazioniStatutoComponent from '../ri-informazioni-statuto-component/RIInformazioniStatutoComponent';
import RIInformazioniPatrimonialiComponent from '../ri-informazioni-patrimoniali-component/RIInformazioniPatrimonialiComponent';
import RIAmministrazioneControlloComponent from '../ri-amministrazione-controllo-component/RIAmministrazioneControlloComponent';
import {
  AMMINISTRAZIONE_CONTROLLO,
  DATI_IDENTIFICATIVI,
  INFORMAZIONI_ATTIVITA,
  INFORMAZIONI_PATRIMONIALI,
  INFORMAZIONI_STATUTO,
  LICENZE,
  PERSONE,
  SEDI,
  SOCI,
} from '../../../pages/registro-imprese/registroImpreseConstants';
import RISediComponent from '../ri-sedi-component/RISediComponent';
import { Button } from 'design-react-kit';
import {
  RegistroImpreseExportRequest,
  RegistroImpreseResultDto,
} from '../../../services/ms-anagrafica-unica';
import RIPersoneComponent from '../ri-persone-component/RIPersoneComponent';
import RISociComponent from '../ri-soci-component/RISociComponent';
import RIAlbiComponent from '../ri-albi-component/RIAlbiComponent';
import { useAppDispatch } from '../../../hooks';
import { esportaByCodiceFiscale } from '../../../store/RegistroImpreseSlice';
import { saveAs } from 'file-saver';

export interface RegistroImpreseDetailInput {
  value: RegistroImpreseResultDto;
  input: string;
}

const RegistroImpreseDetailComponent = (ri: RegistroImpreseDetailInput) => {
  const dispatch = useAppDispatch();

  const hasAlbiLicenze =
    ri.value['albi-ruoli-licenze-ridotti']?.['ruoli-ridotti']?.['ruolo-ridotto'] &&
    ri.value['albi-ruoli-licenze-ridotti']?.['ruoli-ridotti']?.['ruolo-ridotto'].length > 0;
  const hasDatiIdentificativi = !!ri.value['dati-identificativi'];
  const hasInformazioniAttivita = !!ri.value['info-attivita'];
  const hasSedi = !!ri.value.localizzazioni;
  const hasPersone = !!ri.value['persone-sede'];
  const hasSoci = !!ri.value['elenco-soci'];
  const hasInformazioniStatuto = !!ri.value['info-statuto'];
  const hasAmministrazioneControllo = !!ri.value['amministrazione-controllo'];
  const hasInformazioniPatrimoniali = !!ri.value['info-patrimoniali-finanziarie'];

  const handleEsportaPDFClick = () => {
    const newVar: RegistroImpreseExportRequest = { cf: ri.input, formato: 'PDF' };
    dispatch(esportaByCodiceFiscale(newVar))
      .unwrap()
      .then((res) => {
        const filename = 'registro_imprese_export_'.concat(ri.input || '', '.pdf');
        const blob = new Blob([res as any], { type: 'application/pdf' });
        saveAs(blob, filename);
      });
  };

  const handleEsportaRTFClick = () => {
    const newVar: RegistroImpreseExportRequest = { cf: ri.input, formato: 'RTF' };
    dispatch(esportaByCodiceFiscale(newVar))
      .unwrap()
      .then((res) => {
        const filename = 'registro_imprese_export_'.concat(ri.input || '', '.rtf');
        const blob = new Blob([res as any], { type: 'application/rtf' });
        saveAs(blob, filename);
      });
  };

  return (
    <div>
      <div className="row justify-content-end mb-4">
        <div>
          <Button color="primary" className="mt-2" onClick={handleEsportaPDFClick}>
            Esporta PDF
          </Button>
          <Button color="primary" className="mt-2 mx-2" onClick={handleEsportaRTFClick}>
            Esporta RTF
          </Button>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab hidden={!hasDatiIdentificativi}>{DATI_IDENTIFICATIVI}</Tab>
          <Tab hidden={!hasInformazioniAttivita}>{INFORMAZIONI_ATTIVITA}</Tab>
          <Tab hidden={!hasSedi}>{SEDI}</Tab>
          <Tab hidden={!hasPersone}>{PERSONE}</Tab>
          <Tab hidden={!hasSoci}>{SOCI}</Tab>
          <Tab hidden={!hasAlbiLicenze}>{LICENZE}</Tab>
          <Tab hidden={!hasInformazioniStatuto}>{INFORMAZIONI_STATUTO}</Tab>
          <Tab hidden={!hasAmministrazioneControllo}>{AMMINISTRAZIONE_CONTROLLO}</Tab>
          <Tab hidden={!hasInformazioniPatrimoniali}>{INFORMAZIONI_PATRIMONIALI}</Tab>
        </TabList>

        <TabPanel>
          <RIDatiIdentificativiComponent value={ri.value?.['dati-identificativi'] ?? {}} />
        </TabPanel>
        <TabPanel>
          <RIInformazioniAttivitaComponent value={ri.value?.['info-attivita'] ?? {}} />
        </TabPanel>
        <TabPanel>
          <RISediComponent value={ri.value?.['localizzazioni'] ?? []} />
        </TabPanel>
        <TabPanel>
          <RIPersoneComponent value={ri.value?.['persone-sede'] ?? {}} />
        </TabPanel>
        <TabPanel>
          <RISociComponent value={ri.value?.['elenco-soci'] ?? {}} />
        </TabPanel>
        <TabPanel>
          <RIAlbiComponent value={ri.value?.['albi-ruoli-licenze-ridotti'] ?? {}} />
        </TabPanel>
        <TabPanel>
          <RIInformazioniStatutoComponent value={ri.value?.['info-statuto'] ?? {}} />
        </TabPanel>
        <TabPanel>
          <RIAmministrazioneControlloComponent
            value={ri.value?.['amministrazione-controllo'] ?? {}}
          />
        </TabPanel>
        <TabPanel>
          <RIInformazioniPatrimonialiComponent
            value={ri.value?.['info-patrimoniali-finanziarie'] ?? {}}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default RegistroImpreseDetailComponent;
