import { useNavigate, useParams } from 'react-router-dom';
import {
  FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum,
  ModificaFunzionalitaRichiesteDto,
  ModificaFunzionalitaRichiesteDtoAllOf,
  RichiestaAbilitazioneDtoStatoEnum,
  RuoloUtenteAutenticatoDto,
} from '../../services/ms-anagrafica-unica';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import {
  getDettaglioRichiesta,
  modificaRichiestaAbilitazione,
} from '../../store/funzionalitaApplicativeSlice';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ABILITAZIONI_ELENCO_RICHIESTE } from '../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import PageLoader from '../../components/common/page-loader/PageLoader';
import NotificationManagerComponent from '../../components/notification/NotificationManagerComponent';
import AbilitazioneDetailsComponent from '../../components/abilitazione-detail/AbilitazioneDetailsComponent';
import { Button, FormGroup, Input, Label, Table } from 'design-react-kit';
import { z } from 'zod';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const ModificaRichiestaAbilitazione = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    dettaglioRichiestaResult,
    dettaglioRichiestaStatus,
    valutazioneRichiestaAttivazioneStatus,
    modificaRichiestaStatus,
  } = useAppSelector((state) => state.abilitazioni);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [arrFunz, setArrayFunz] = useState<Array<any>>([]);
  const [btnSalvataggio, setBtnSalvataggio] = useState<'SALVA' | 'CONFERMA'>('SALVA');
  const initialValues: ModificaFunzionalitaRichiesteDtoAllOf = {
    elencoFunzionalita: [],
  };

  useEffect(() => {
    dispatch(getDettaglioRichiesta(Number(id)))
      .unwrap()
      .then((resp) => {
        if (!!resp) {
          const arrFunz: Array<any> = [];
          resp.elencoFunzionalitaRichieste &&
            resp.elencoFunzionalitaRichieste.map((f) =>
              arrFunz.push({
                idFunzionalita: f.funzionalita?.codiceUnivoco,
                tipoAbilitazione: f.tipoAbilitazione,
                noteDirigente: f.noteDirigente,
                descrizione: f.funzionalita?.descrizione,
              })
            );
          setArrayFunz(arrFunz);
        }
      });
  }, []);

  const schema = z.object({
    elencoFunzionalita: z.array(
      z.object({
        idFunzionalita: z.number(),
        tipoAbilitazione: z.string(),
        noteDirigente: z.string().optional(),
      })
    ),
  });

  const searchForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      console.log('values >>> ', values);

      const req: ModificaFunzionalitaRichiesteDto = {
        ...values,
        requestType: btnSalvataggio,
        tipoGestione: 'GestioneFunzionalita',
      };
      // console.log('Invio form inserimento >>> ', req);
      dispatch(
        modificaRichiestaAbilitazione({
          id: Number(id),
          patchAssegnazioneFunzionalitaApplicativeRequest: req,
        })
      )
        .unwrap()
        .then((resp) => !!resp && navigate(HREF_ABILITAZIONI_ELENCO_RICHIESTE));
    },
  });

  const changeAbilitazione = (e: any) => {
    const newArrFunz = arrFunz.map((f) => {
      if (f.idFunzionalita === e) {
        if (f.tipoAbilitazione === FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.Off) {
          f.tipoAbilitazione = FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.On;
        } else {
          f.tipoAbilitazione = FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.Off;
        }
      }
      return f;
    });
    setArrayFunz(newArrFunz);
  };

  const changeNoteDirigente = (e: string, funzionalita: number) => {
    const newArrFunz = arrFunz.map((f) => {
      if (f.idFunzionalita === funzionalita) {
        f.noteDirigente = e;
      }
      return f;
    });
    setArrayFunz(newArrFunz);
  };

  const submitConfirm = () => {
    setBtnSalvataggio('CONFERMA');
    searchForm.setFieldValue('elencoFunzionalita', arrFunz);
    setTimeout(() => {
      searchForm.submitForm();
    }, 500);
  };

  const submitSave = () => {
    setBtnSalvataggio('SALVA');
    searchForm.setFieldValue('elencoFunzionalita', arrFunz);
    setTimeout(() => {
      searchForm.submitForm();
    }, 500);
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ABILITAZIONI_ELENCO_RICHIESTE}
        title={t('Modifica richiesta di attivazione')!}
      />
      {(dettaglioRichiestaStatus === STATUS_REJECTED ||
        valutazioneRichiestaAttivazioneStatus === STATUS_REJECTED) && (
        <NotificationManagerComponent
          state="error"
          title="Errore"
          content="Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio"
        />
      )}
      {dettaglioRichiestaStatus === STATUS_FULLFILLED && !!dettaglioRichiestaResult && (
        <div className="mt-5 position-relative">
          <AbilitazioneDetailsComponent abilitazione={dettaglioRichiestaResult} except={[]} />
          <div className="form-row form-custom mt-3 p-3">
            <Table md={12}>
              <thead>
                <tr>
                  <th>Funzionalità</th>
                  <th>Attiva</th>
                  <th>Note Dirigente</th>
                </tr>
              </thead>
              <tbody>
                {arrFunz.map((funz, key) => (
                  <tr>
                    <td>{funz.descrizione}</td>
                    <td>
                      <FormGroup check>
                        <Input
                          id={`funz${funz.idFunzionalita}`}
                          type="checkbox"
                          checked={
                            funz.tipoAbilitazione ===
                            FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.On
                          }
                          onChange={() => changeAbilitazione(funz.idFunzionalita)}
                        />
                        <Label for={`funz${funz.idFunzionalita}`} check></Label>
                      </FormGroup>
                    </td>
                    <td>
                      <Input
                        type="text"
                        id="note"
                        name="note"
                        value={funz.noteDirigente || ''}
                        onChange={(e) => changeNoteDirigente(e.target.value, funz.idFunzionalita)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex flex-row-reverse">
            <Button color="primary" className="mt-2 mr-2" onClick={() => submitConfirm()}>
              Conferma
            </Button>
            {dettaglioRichiestaResult.stato === RichiestaAbilitazioneDtoStatoEnum.Bozza && (
              <Button color="primary" className="mt-2 mr-2" onClick={() => submitSave()}>
                Salva
              </Button>
            )}
            <Button
              color="secondary"
              outline
              className="mt-2 mr-2"
              onClick={() => {
                navigate(`${HREF_ABILITAZIONI_ELENCO_RICHIESTE}`);
              }}
            >
              {t('annulla')}{' '}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModificaRichiestaAbilitazione;
