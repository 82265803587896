import {
  StepperContainer,
  StepperContent,
  StepperHeader,
  StepperHeaderElement,
} from 'design-react-kit';
import React, { ReactNode } from 'react';

interface StepperProps {
  label: string;
  children: ReactNode;
}

export const Stepper: React.FC<StepperProps> = ({ children }) => {
  return <>{children}</>;
};

interface SteppersProps {
  currentTab: number;
  children: ReactNode;
}

export const SteppersCustom: React.FC<SteppersProps> = ({ currentTab, children }) => {
  return (
    <StepperContainer>
      <StepperHeader className="row col-12">
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <StepperHeaderElement
                className={
                  'col-12 col-lg-6 col-xl-4 ' +
                  (index !== currentTab ? 'deactive' : '') +
                  ' ' +
                  (index < currentTab ? 'confermato' : '')
                }
                appendIcon={index < currentTab ? 'it-check-circle' : undefined}
                variant={index === currentTab ? 'active' : undefined}
              >
                {child.props.label}
              </StepperHeaderElement>
            );
          }
        })}
      </StepperHeader>
      <StepperContent>
        {React.Children.map(children, (child, index) => {
          if (index === currentTab) {
            return <>{child}</>;
          }
        })}
      </StepperContent>
    </StepperContainer>
  );
};
