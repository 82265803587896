import { useEffect, useRef, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Button, Col, Input, TextArea, Toggle } from 'design-react-kit';
import { useNavigate, useParams } from 'react-router-dom';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import DocumentUpload, {
  DocumentUploadRef,
} from '../../components/common/document-upload/DocumentUpload';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  associazioneStrutturaDipendente,
  resetUpdateForm,
  detailsStruttureOrganizzative,
} from '../../store/strutturaOrganizzativaSlice';
import { FormsControllerApi, SOAssociaRequestDto } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants';
import CustomAutocomplete, {
  CustomAutocompleteRef,
} from '../../components/common/custom-autocomplete/CustomAutocomplete';
import { debounce } from 'lodash';
import PageHeader from '../../components/common/page-header/PageHeader';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail/StruttureOrganizzativeDetails';
import {
  CAP,
  CITTA,
  CODICE,
  DATA_INIZIO_VALIDITA,
  DECLARATORIA,
  DENOMINAZIONE,
  DIPARTIMENTO,
  EMAIL,
  EMAIL_2,
  FAX,
  INDIRIZZO,
  PEC,
  PEC_2,
  TELEFONO,
  TELEFONO_2,
  TIPOLOGIA_STRUTTURA,
} from './struttureOrganizzativeConstants';
import { zodCifra } from '../../utility/formUtils';

const AssegnazioneStrutturaOrganizzativa = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const ref = useRef<DocumentUploadRef>(null);
  const refAutoComplete = useRef<CustomAutocompleteRef>(null);
  const fieldUsed: Array<string> = [
    CODICE,
    DENOMINAZIONE,
    DECLARATORIA,
    DATA_INIZIO_VALIDITA,
    TIPOLOGIA_STRUTTURA,
    DIPARTIMENTO,
    EMAIL,
    EMAIL_2,
    PEC,
    PEC_2,
    TELEFONO,
    TELEFONO_2,
    FAX,
    INDIRIZZO,
    CITTA,
    CAP,
  ];
  const strutturaOrganizzativaResult = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsStatus
  );
  const strutturaOrganizzativa = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsResult
  );
  const updateStatus = useAppSelector((state) => state.strutturaOrganizzativa.updateStatus);
  const [isPrimaAssegnazione, setIsPrimaAssegnazione] = useState<boolean | undefined>(undefined);
  const [optDipendenti, setOptDipendenti] = useState<any[] | undefined>([]);

  const initialValues: SOAssociaRequestDto = {
    idDipendente: undefined,
    dataInizioValidita: '',
    dataFineValidita: '',
    // attoAutorizzativo: undefined,
    idFile: undefined,
    isPrimaAssegnazione: undefined,
    requestType: 'Associa',
  };

  var schema = z
    .object({
      idDipendente: z.number({ required_error: 'required' }),
      dataInizioValidita: z.coerce.date().max(new Date('9999-12-31'), { message: 'Invalid' }),
      dataFineValidita: z.coerce.date().max(new Date('9999-12-31'), { message: 'Invalid' })
        .refine((data) => data >= new Date(formik.values.dataInizioValidita!), {
          message: 'La data di fine validità non può essere minore della data di inizio',
        }).optional(),
      
      note: z.string().max(1000, 'Le note possono contenere massimo 1000 caratteri').optional(),
      isPrimaAssegnazione: z.boolean({ required_error: 'required' }),
      idFile: z.number().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.isPrimaAssegnazione === false && data.idFile === undefined) {
        ctx.addIssue({
          code: 'custom',
          path: ['idFile'],
        });
      }
    });

  useEffect(() => {
    dispatch(resetUpdateForm());
    getDetailsFromApi(id!);
    autocompleteDipendenti.bind(this);
  }, []);

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  };

  const formik: FormikProps<SOAssociaRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      associaSoDipendenti();
    },
  });

  const submitConfirm = () => {
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  const getFieldError = (
    form: FormikProps<SOAssociaRequestDto>,
    fieldName: keyof SOAssociaRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const handleIdChange = (id: number | undefined) => {
    if (id && id !== -1) {
      formik.setFieldValue('idFile', id);
    } else {
      formik.setFieldValue('idFile', undefined);
    }
  };
  const handleChange = (name: string, selectedOption: any) => {
    formik.setFieldValue(name, selectedOption);
    setTimeout(() => formik.validateForm(), 0);
  };

  const associaSoDipendenti = () => {
    const body = {
      idStrutturaOrganizzativa: id,
      bodyRequest: formik.values,
    };
    dispatch(associazioneStrutturaDipendente(body));
  };

  useEffect(() => {
    if (updateStatus === STATUS_FULLFILLED) {
      formik.resetForm();
      handleIdChange(undefined);
      setIsPrimaAssegnazione(undefined);
      ref.current?.resetUploadFile();
      // formik.setFieldValue('attoAutorizzativo', '')
      refAutoComplete.current?.resetAutocompleteForm();
    }
  }, [updateStatus]);

  const autocompleteDipendenti = debounce(async (inputValue: string, callback: any) => {
    let options: { value: number | undefined; label: string | undefined; data: any | undefined }[] =
      [];

    // if (inputValue == '' && formik.values.idDipendente!) callback(options);
    // else {
      const api = new FormsControllerApi(MS_AU_CONFIG);
      const response = await api.getAssociaIncarichiContestoForm({
        idIncarico: id!,
        chiave: inputValue,
      });

      if (response.data.dipendenti) {
        response.data.dipendenti?.data?.forEach((value) => {
          options.push({
            value: value.id,
            label: `${value.nomeCompleto} - ${value.codiceFiscale}`,
            data: value,
          });
        });
      }

      setOptDipendenti(options);
      callback(options);
    //}
  }, 500);

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}
        state={{ form: true }}
        title={'Assegnazione struttura organizzativa'}
      ></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && (
        <div>
          <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
          <div className="row form-custom form-inserimento mt-3">
            <Col md="12">
              <CustomAutocomplete
                required={true}
                label="Dipendente"
                placeholder="Cerca un dipendente"
                id="select-dipendente"
                loadOptionsFn={autocompleteDipendenti}
                ref={refAutoComplete}
                handleOptionSelect={(id: string) => {
                  handleChange('idDipendente', id);
                  const isPrimoIncarico =
                    optDipendenti?.find((dip) => dip.value === id)?.data?.isPrimoIncarico ??
                    undefined;
                  setIsPrimaAssegnazione(isPrimoIncarico!!);
                  handleChange('isPrimaAssegnazione', isPrimoIncarico!!);
                }}
                invalid={!!getFieldError(formik, 'idDipendente')}
                infoText={t(getFieldError(formik, 'idDipendente')) || ''}
              />
            </Col>
            <Input
              type="date"
              label="Data inizio validità"
              placeholder="Inserisci la data di inizio validità"
              id="input-dataInizioValidita"
              name="dataInizioValidita"
              value={formik.values.dataInizioValidita}
              onChange={formik.handleChange}
              max={formik.values.dataFineValidita}
              invalid={!!getFieldError(formik, 'dataInizioValidita')}
              infoText={t(getFieldError(formik, 'dataInizioValidita')) || ''}
              wrapperClassName="col-xl-6 required"
            />

            <Input
              type="date"
              label="Data fine validità"
              placeholder="Inserisci la data di fine validità"
              id="input-dataFineValidita"
              name="dataFineValidita"
              value={formik.values.dataFineValidita}
              onChange={formik.handleChange}
              min={formik.values.dataInizioValidita}
              invalid={!!getFieldError(formik, 'dataFineValidita')}
              infoText={t(getFieldError(formik, 'dataFineValidita')) || ''}
              wrapperClassName="col-md-12"
            />

            {/* <Input
                        type="text"
                        label="Atto Autorizzativo (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
                        placeholder="Inserisci l'atto autorizzativo"
                        id="input-attoAutorizzativo"
                        wrapperClassName="required col-md-12"
                        name="attoAutorizzativo"
                        value={formik.values.attoAutorizzativo}
                        onChange={formik.handleChange}
                        invalid={!!getFieldError(formik, "attoAutorizzativo")}
                        infoText={t(getFieldError(formik, "attoAutorizzativo")) || ""} /> */}

            <Col md="12">
              <TextArea
                label="Note"
                placeholder="Note"
                id="input-note"
                name="note"
                className="mb-1"
                value={formik.values.note}
                onChange={formik.handleChange}
                invalid={!!getFieldError(formik, 'note')}
                infoText={t(getFieldError(formik, 'note')) || ''}
              />
            </Col>

            {isPrimaAssegnazione !== undefined && (
              <>
                <Col md={4} sm={12}>
                  <Toggle
                    className="mt-2"
                    label="Prima assegnazione"
                    name="isPrimaAssegnazione"
                    defaultChecked={isPrimaAssegnazione}
                    checked={formik.values.isPrimaAssegnazione}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsPrimaAssegnazione(e?.target.checked);
                    }}
                  />
                </Col>
                <DocumentUpload
                  ref={ref}
                  invalid={!!formik.errors.idFile}
                  infoText={t(getFieldError(formik, 'idFile')) || ''}
                  setDocumentId={handleIdChange}
                  documentName="Autorizzazione assegnazione del dipendente alla struttura"
                  className={`col-12 mt-2 ${!formik.values.isPrimaAssegnazione ? 'required' : ''}`}
                />
              </>
            )}
          </div>

          <div className="py-4">
            <div className="d-flex flex-row-reverse">
              <Button
                color="primary"
                className="mt-2"
                onClick={() => {
                  submitConfirm();
                }}
              >
                {t('conferma')}
              </Button>
              <Button
                color="secondary"
                outline
                className="mt-2 mr-2"
                onClick={() =>
                  navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`, { state: { form: true } })
                }
              >
                {t('annulla')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssegnazioneStrutturaOrganizzativa;
