import { useEffect, useState } from 'react';
import { Button, Col, Icon, Table } from 'design-react-kit';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { HREF_ELENCO_RUOLI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  deleteFunzionalita,
  detailsRuolo,
  disassociaFunzionalita,
  insertFunzionalitaRuolo,
  listFunzionalita,
  resetAssociazioneStatus,
  selectFunzionalitaInsert,
} from '../../store/ruoloSlice';
import {
  AssegnaRuoloConFunzionalitaRequestDto,
  RuoloUtenteAutenticatoDto,
} from '../../services/ms-anagrafica-unica';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import './style.scss';
import PageHeader from '../../components/common/page-header/PageHeader';
import RuoloDetailsComponent from '../../components/ruolo-details/RuoloDetailsComponent';

const schema = z.object({
  funzionalita: z.string({ required_error: 'required' }),
});

const initialValues = {
  funzionalita: '',
};

const AssociazioneRuoliFunzionalita = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    funzionalitaRuoloListResult,
    selectFunzionalitaResult,
    funzionalitaRuoloListStatus,
    insertFunzionalitaRuoloStauts,
    detailsResult,
  } = useAppSelector((state) => state.ruolo);
  const [tableVisual, setVisualTable] = useState(false);
  const [funzionalitaArray, setFunzionalitaArray] = useState<any[]>([]);
  const [funzionalita, setFunzionalita] = useState<AssegnaRuoloConFunzionalitaRequestDto>();
  const [funzionalitaSelect, setFunzionalitaSelect] = useState<CustomSelectItem[]>([]);
  useEffect(() => {
    getList(id!);
    dispatch(selectFunzionalitaInsert(Number.parseInt(id!)));
    dispatch(detailsRuolo(Number.parseInt(id!)));
    dispatch(resetAssociazioneStatus());
  }, []);

  const getList = (id: string) => {
    dispatch(listFunzionalita(Number.parseInt(id!)));
  };

  useEffect(() => {
    getList(id!);
    dispatch(selectFunzionalitaInsert(Number.parseInt(id!)));
  }, [insertFunzionalitaRuoloStauts]);

  useEffect(() => {
    if (funzionalitaRuoloListResult?.data?.length || funzionalitaArray.length) {
      setVisualTable(true);
    } else {
      setVisualTable(false);
    }
  }, [funzionalitaRuoloListResult, funzionalitaArray]);

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      const newFunzionalitaArray = [...funzionalitaArray, values.funzionalita];
      const newFunzionalitaSelect = funzionalitaSelect.filter(
        (f, i) => f.value !== values.funzionalita
      );
      setFunzionalitaSelect(newFunzionalitaSelect);
      setFunzionalitaArray(newFunzionalitaArray);
      setFunzionalita({
        idRuolo: Number.parseInt(id!),
        elencoPermessoFunzionalita: newFunzionalitaArray,
      });
      form.handleReset(values);
      if (funzionalitaArray.length > 0) {
        setVisualTable(true);
      }
    },
  });

  useEffect(() => {
    let funzionalitaSelect: CustomSelectItem[] = [];
    selectFunzionalitaResult?.funzionalita
      ?.filter((v) => !funzionalitaArray.includes(v.valore))
      .forEach((s) => {
        funzionalitaSelect.push({ label: s.label, value: s.valore });
      });
    setFunzionalitaSelect(funzionalitaSelect);
  }, [selectFunzionalitaResult]);

  const getFieldError = (form: any, fieldName: any): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const handleChange = (name: string, selectedOption: any) => {
    console.log('selected options', selectedOption);
    form.setFieldValue(name, selectedOption);
  };

  let funzionalitaSelectLocal: CustomSelectItem[] = [];
  selectFunzionalitaResult?.funzionalita?.forEach((s) => {
    funzionalitaSelectLocal.push({ label: s.label, value: s.valore });
  });

  const eliminaFunzionalita = (id: string, funzionalita: string) => {
    const body: deleteFunzionalita = {
      idRuolo: Number.parseInt(id),
      funzionalita: funzionalita,
    };
    dispatch(disassociaFunzionalita(body));
  };

  const rimuoviFunzionalitaArray = (index: number, selectValue: string) => {
    let select: CustomSelectItem[] = funzionalitaSelectLocal.filter(
      (value) => value.value == selectValue
    );
    const newFunzionalitaSelect = [...funzionalitaSelect, select[0]];
    setFunzionalitaSelect(newFunzionalitaSelect);

    const newFunzionalitaArray = funzionalitaArray.filter((f, i) => f !== selectValue);
    setFunzionalitaArray(newFunzionalitaArray);
    setFunzionalita({ elencoPermessoFunzionalita: newFunzionalitaArray });
    if (funzionalitaArray.length <= 0 && funzionalitaRuoloListResult?.data?.length! <= 0) {
      setVisualTable(false);
    }
  };

  const submitForm = () => {
    form.submitForm();
  };

  const insert = () => {
    if (funzionalitaArray.length > 0) {
      dispatch(insertFunzionalitaRuolo(funzionalita!));
      setFunzionalitaArray([]);
    } else {
      form.submitForm();
    }
  };

  const showFunzionalitaDeleteOperations = () =>
    props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLOFUNZ_DELETE');

  const showFunzionalitaInsertOperations = () =>
    props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLOFUNZ_INSERT');

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_RUOLI}
        title={`${detailsResult?.nome}: Gestione ruolo funzionalità`}
      ></PageHeader>
      {funzionalitaRuoloListStatus === STATUS_FULLFILLED && (
        <div>
          <div className="row">
            <RuoloDetailsComponent detailsResult={detailsResult!} />
            <div className="col-md-12">
              <div className="mt-5 position-relative">
                <div className="row">
                  <Col md="10">
                    <CustomSelect
                      label="Funzionalita"
                      options={funzionalitaSelect}
                      value={form.values.funzionalita}
                      placeholder="Seleziona funzionalità"
                      onChange={(e) => handleChange('funzionalita', e)}
                      invalid={!!getFieldError(form, 'funzionalita')}
                      infoText={t(getFieldError(form, 'funzionalita')) || ''}
                      name="funzionalita"
                      disabled={!showFunzionalitaInsertOperations()}
                    />
                  </Col>
                  <div className="col-2">
                    <Button
                      outline
                      color="primary"
                      disabled={!showFunzionalitaInsertOperations()}
                      onClick={() => submitForm()}
                    >
                      {' '}
                      <Icon icon="it-plus-circle" aria-hidden size="sm" color="primary" />
                      Aggiungi
                    </Button>
                  </div>
                </div>
              </div>
              {tableVisual && (
                <Table striped size="sm" className="col-md-11">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="col-md-10">Funzionalità</th>
                      <th className="col-md-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(funzionalitaRuoloListResult?.data || []).map((value, index) => (
                      <tr key={index}>
                        <td></td>
                        <td> {value.decodificaFunzionalita?.label}</td>
                        <td>
                          {' '}
                          <Button
                            size="xs"
                            color="danger"
                            disabled={!showFunzionalitaDeleteOperations()}
                            outline
                            onClick={() =>
                              eliminaFunzionalita(id!, value.decodificaFunzionalita?.name!)
                            }
                          >
                            {' '}
                            <Icon
                              className="bg-grey"
                              color="danger"
                              icon="it-delete"
                              size="xs"
                            />{' '}
                            Rimuovi{' '}
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {funzionalitaArray?.map((value, index) => (
                      <tr key={index}>
                        <td className="notSaveElement">*</td>
                        <td>
                          {' '}
                          {selectFunzionalitaResult?.funzionalita?.map((value1) =>
                            value1.valore == value ? value1.label : ''
                          )}
                        </td>
                        <td>
                          {' '}
                          <Button
                            size="xs"
                            color="danger"
                            disabled={!showFunzionalitaDeleteOperations()}
                            outline
                            onClick={() => rimuoviFunzionalitaArray(index, value)}
                          >
                            {' '}
                            <Icon
                              className="bg-grey"
                              color="danger"
                              icon="it-delete"
                              size="xs"
                            />{' '}
                            Rimuovi{' '}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {funzionalitaArray.length > 0 && (
                <span>I campi contrassegnati da * non sono ancora stati salvati</span>
              )}
            </div>
          </div>

          <div className=" position-relative py-5">
            <div className="d-flex flex-row-reverse">
              <Button
                color="primary"
                className="mt-2 mr-2"
                onClick={() => {
                  insert();
                }}
              >
                Salva
              </Button>
              <Button
                color="secondary"
                outline
                className="mt-2 mr-2"
                onClick={() => {
                  navigate(`${HREF_ELENCO_RUOLI}`);
                }}
              >
                {t('annulla')}{' '}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssociazioneRuoliFunzionalita;
