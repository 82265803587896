import React, { useEffect, useState } from 'react';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import { FormikProps, useFormik } from 'formik';
import PageHeader from '../../components/common/page-header/PageHeader';
import {
  Alert,
  Button,
  Col,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea,
} from 'design-react-kit';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  convocazioneSottoscrizioneCandidato,
  detailsCandidato,
  formSrachPopulate,
  pathDecisioneConvocazione,
  resetUpdateForm,
} from '../../store/candidatoSlice';
import { DecisioneConvocazioneRequestDto } from '../../services/ms-anagrafica-unica';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useTranslation } from 'react-i18next';
import {
  HREF_LOGOUT,
  HREF_SCRIVANIA_VIRTUALE,
} from '../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../store/store-constants';

const initialValues: DecisioneConvocazioneRequestDto = {
  note: '',
  decisioneConvocazione: undefined,
  idDocumentoDecisione: undefined,
  idFoto: undefined,
};

const validationSchema = z
  .object({
    note: z.string({ required_error: 'required' }).max(3000, 'lenghtNoteCandidato').optional(),
    decisioneConvocazione: z.string({ required_error: 'required' }),
    idDocumentoDecisione: z.number().optional(),
    idFoto: z.number().optional(),
  })
  .refine(
    (data) => {
      if (data.decisioneConvocazione !== 'ACCETTO') {
        if (!data.note) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'required',
      path: ['note'], // path of error
    }
  )
  .refine(
    (data) => {
      if (data.decisioneConvocazione === 'ACCETTO') {
        if (!data.idFoto) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'required',
      path: ['idFoto'], // path of error
    }
  );

const GestioneConvocazioneSottoscrizioneContratto = () => {
  const { formSearchResult, detailsResult, detailsStatus, updateStatus } = useAppSelector(
    (state) => state.candidato
  );
  const dispatch = useAppDispatch();
  const { userInfoResult } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenModal, toggleModal] = useState(false);

  useEffect(() => {
    if (!!userInfoResult) {
      dispatch(resetUpdateForm());
      dispatch(formSrachPopulate());
      dispatch(detailsCandidato(userInfoResult?.entityId!));
    }
  }, [userInfoResult]);

  let convocazioniOptions: CustomSelectItem[] = [];
  formSearchResult?.decisioniConvocazioni?.forEach((s) => {
    convocazioniOptions.push({ label: s.label, value: s.valore });
  });

  const form: FormikProps<DecisioneConvocazioneRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: (values) => {
      doInsert();
    },
  });

  const doInsert = () => {
    const body: convocazioneSottoscrizioneCandidato = {
      id: userInfoResult?.entityId!,
      decisione: form.values.decisioneConvocazione!,
      idDocumentoDecisione: form.values.idDocumentoDecisione!,
      idFoto: form.values.idFoto!,
      note: form.values.note!,
    };
    dispatch(pathDecisioneConvocazione(body))
      .unwrap()
      .then((resp) => {
        if (resp && (body.decisione === 'ACCETTO' || body.decisione === 'NON_ACCETTO')) {
          toggleModal(true);
        }
      });
  };

  const handleChange = (name: string, selectedOption: any) => {
    form.setFieldValue(name, selectedOption);
  };

  const getFieldError = (
    form: FormikProps<DecisioneConvocazioneRequestDto>,
    fieldName: keyof DecisioneConvocazioneRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const handleIdChangeFoto = (id: number | undefined) => {
    if (id && id !== -1) {
      form.setFieldValue('idFoto', id);
    } else {
      form.setFieldValue('idFoto', undefined);
    }
  };

  const handleIdChangeDocumentoGenerico = (id: number | undefined) => {
    if (id && id !== -1) {
      form.setFieldValue('idDocumentoDecisione', id);
    } else {
      form.setFieldValue('idDocumentoDecisione', undefined);
    }
  };

  return (
    <div>
      <PageHeader showIcon={false} title={'Gestione convocazione sottoscrizione contratto'} />
      {detailsStatus === STATUS_FULLFILLED && (
        <div>
          {detailsResult?.hasConvocazioni ? (
            <>
              <div>
                <div className="position-relative">
                  {detailsResult?.elencoDocumenti != null &&
                    detailsResult.elencoDocumenti.length > 0 && (
                      <div>
                        <hr />
                        <h5>Riepilogo file sottoscrizione</h5>
                        <hr />
                        {detailsResult?.elencoDocumenti!.map(
                          (item, index) =>
                            item.tipo === 'CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO' && (
                              <div key={index} className="p-3">
                                <DocumentUpload
                                  hiddenDeleteButton={true}
                                  documentName="Convocazione sottoscrizione contratto "
                                  documento={item.documentoDto}
                                />
                              </div>
                            )
                        )}
                      </div>
                    )}
                </div>
                <hr />
                <h5>Inserisci dati</h5>
                <hr />
                <div className="form-custom form-inserimento">
                  <div className="row">
                    <Col xs="12">
                      <CustomSelect
                        label="Convocazione"
                        placeholder="Seleziona l'azione da effettuare con questa convocazione"
                        options={convocazioniOptions}
                        name="decisioneConvocazione"
                        invalid={!!getFieldError(form, 'decisioneConvocazione')}
                        infoText={t(getFieldError(form, 'decisioneConvocazione')) || ''}
                        value={form.values.decisioneConvocazione}
                        onChange={(e) => {
                          handleChange('decisioneConvocazione', e);
                        }}
                        wrapperClass="required"
                      />
                    </Col>
                    <TextArea
                      label="Note"
                      placeholder="Inserisci le note "
                      id="input-note"
                      wrapperClassName="form-group  mt-4 col-md-12"
                      name="note"
                      value={form.values.note}
                      onChange={form.handleChange}
                      invalid={!!getFieldError(form, 'note')}
                      infoText={t(getFieldError(form, 'note')) || ''}
                    />

                    <Col md="6">
                      <DocumentUpload
                        idDocumento={form.values.idFoto}
                        disabled={form.values.decisioneConvocazione != 'ACCETTO'}
                        documentName="Upload foto"
                        setDocumentId={handleIdChangeFoto}
                        invalid={!!getFieldError(form, 'idFoto')}
                        infoText={t(getFieldError(form, 'idFoto')) || ''}
                      ></DocumentUpload>
                    </Col>
                    <Col md="6">
                      <DocumentUpload
                        idDocumento={form.values.idDocumentoDecisione}
                        disabled={
                          form.values.decisioneConvocazione?.trim() == '' ||
                          form.values.decisioneConvocazione == null ||
                          form.values.decisioneConvocazione == undefined ||
                          form.values.decisioneConvocazione == 'ACCETTO'
                        }
                        documentName="Upload documento generico"
                        setDocumentId={handleIdChangeDocumentoGenerico}
                      ></DocumentUpload>
                    </Col>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <Button
                      color="primary"
                      className="mt-2"
                      onClick={() => {
                        form.handleSubmit();
                      }}
                    >
                      Conferma
                    </Button>
                    <Button
                      color="secondary"
                      outline
                      className="mt-2 mr-2"
                      onClick={() => {
                        navigate(HREF_SCRIVANIA_VIRTUALE);
                      }}
                    >
                      Indietro
                    </Button>
                  </div>
                </div>
              </div>

              <Modal
                isOpen={isOpenModal}
                toggle={() => toggleModal(!isOpenModal)}
                centered
                labelledBy="modal-decisione-convocazione"
                withIcon
                onClosed={() => navigate(HREF_LOGOUT)}
              >
                <ModalHeader
                  toggle={() => toggleModal(!isOpenModal)}
                  id="modal-decisione-convocazione"
                >
                  {(form.values.decisioneConvocazione === 'ACCETTO'
                    ? 'Accettazione'
                    : 'Cessazione') + ' candidatura'}
                </ModalHeader>
                <ModalBody>
                  <span className="d-flex">
                    <Icon icon="it-check-circle" className="mr-2" color="primary" size="xl"></Icon>
                    <span>
                      Il processo di candidatura termina e sarà eseguito il logoff dal sistema di
                      Anagrafica Unica
                    </span>
                  </span>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => navigate(HREF_LOGOUT)}>
                    Chiudi
                  </Button>
                </ModalFooter>
              </Modal>
            </>
          ) : (
            <Alert color="info">Convocazione non ancora presente.</Alert>
          )}
        </div>
      )}
    </div>
  );
};

export default GestioneConvocazioneSottoscrizioneContratto;
