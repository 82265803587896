import PageHeader from '../../components/common/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { HREF_RUBRICA_ELENCO_DIPENDENTI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import DipendenteDetailsComponent from '../../components/dipendente-detail-form/DipendenteDetailsComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  detailsDipendente,
  generaEmail,
  inserisciTelefonoCredenzialiEmail,
} from '../../store/rubricaSlice';
import { GenerazioneEmailDto, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { Button, Col, Input } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';

let initialValues: GenerazioneEmailDto = {
  indirizzoEmail: '',
};

const validationSchemaConfirm = z.object({
  indirizzoEmail: z
    .string({ required_error: 'required' })
    .max(320, 'email troppo lunga, max 320 caratteri'),
});

const InserisciEmailDipendente = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    dipendenteResult,
    dipendenteStatus,
    generaEmailStatus,
    inserisciTelefonoCredenzialiEmailStatus,
  } = useAppSelector((state) => state.rubrica);

  useEffect(() => {
    if (id != '' && id != undefined && id != null) {
      dispatch(detailsDipendente(Number.parseInt(id!)));
    }
  }, []);

  const insertForm: FormikProps<any> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(validationSchemaConfirm),
    onSubmit: (values) => {
      dispatch(
        inserisciTelefonoCredenzialiEmail({
          id: Number(id),
          patchDatiRubricaDipendenteRequest: {
            ...values,
            requestType: 'Email',
          },
        })
      )
        .unwrap()
        .then((resp) => {
          if (resp) {
            navigate(HREF_RUBRICA_ELENCO_DIPENDENTI);
          }
        })
        .catch((error) => console.error('rejected', error));
    },
  });

  const getFieldError = (
    form: FormikProps<GenerazioneEmailDto>,
    fieldName: keyof GenerazioneEmailDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const handleChange = (name: string, selectedOption: any) => {
    console.log('selected options', selectedOption);
    if (selectedOption != '') {
      insertForm.setFieldValue(name, selectedOption, false);
    } else {
      insertForm.setFieldValue(name, undefined, false);
    }
  };

  const submitConfirm = () => {
    setTimeout(() => {
      insertForm.submitForm();
    }, 500);
  };

  const generaEmailBtn = () => {
    dispatch(generaEmail({ id: Number.parseInt(id!) }))
      .unwrap()
      .then((resp) => resp && insertForm.setFieldValue('indirizzoEmail', resp.indirizzoEmail));
  };

  const abilitaGenerazioneEmail = () =>
    props.operations.elencoFunzionalita?.includes('RUBRICA_GENERA_EMAIL_DIPENDENTE');

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_RUBRICA_ELENCO_DIPENDENTI}
        title={t('Inserimento email dipendente')!}
      />
      {dipendenteStatus === STATUS_FULLFILLED && (
        <div>
          <DipendenteDetailsComponent
            dipendente={dipendenteResult!}
            except={['assegnazioneNumeroTelefono', 'credenzialiDominio', 'passwordDominio']}
          />
          <div className="position-relative py-5">
            <div className="row">
              <Col>
                <Input
                  label="Email"
                  type="text"
                  placeholder="Inserisci indirizzo email"
                  id="input-indirizzoEmail"
                  wrapperClassName="form-group required col-md-12 "
                  name="indirizzoEmail"
                  disabled={!!initialValues.indirizzoEmail}
                  value={insertForm.values.indirizzoEmail}
                  onChange={(e) => handleChange('indirizzoEmail', e.target.value)}
                  invalid={!!getFieldError(insertForm, 'indirizzoEmail')}
                  infoText={t(getFieldError(insertForm, 'indirizzoEmail')) || ''}
                />
              </Col>
            </div>
          </div>
          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2 mr-2"
              onClick={() => {
                submitConfirm();
              }}
            >
              Conferma
            </Button>
            <Button
              color="primary-outline"
              className="mt-2 mr-2"
              disabled={!abilitaGenerazioneEmail()}
              onClick={() => {
                generaEmailBtn();
              }}
            >
              Genera email
            </Button>
            <Button
              color="secondary"
              outline
              className="mt-2 mr-2"
              onClick={() => {
                navigate(`${HREF_RUBRICA_ELENCO_DIPENDENTI}`);
              }}
            >
              {t('annulla')}{' '}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InserisciEmailDipendente;
