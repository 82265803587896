import { showFieldIfNotNull } from 'utility/utility';
import { RiepilogoBandoDetailInterface } from '../../models/RiepilogoBandoDetailInterface';
import { formatDate } from '../../utility/formatDate';

const RiepilogoBandoDetail = (props: { bando: RiepilogoBandoDetailInterface }) => {
  const { bando } = props;
  return (
    <div className="col-sm-12">
      <div className="card-div row">
        <div className="col-sm-12">
          <div className="info-box-light bg-light-gray">
            <h5 className="pb-2 border-bottom font-weight-bold">Riepilogo Bando</h5>
            <dl>
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <dt>Denominazione bando</dt>
                  <dd>{showFieldIfNotNull(bando.nomeBando)}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Identificativo</dt>
                  <dd>{showFieldIfNotNull(bando.idBando)}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Struttura organizzativa</dt>
                  <dd>{showFieldIfNotNull(bando.strutturaOrganizzativa)}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Apertura candidature</dt>
                  <dd>{formatDate(bando.dataApertura!, false, ' - ')}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Chiusura candidature</dt>
                  <dd>{formatDate(bando.dataChiusura!, false, ' - ')}</dd>
                </div>
                <div className="col-md-6 col-xl-4">
                  <dt>Codice Cifra</dt>
                  <dd>{showFieldIfNotNull(bando.codiceCifraDeliberaDetermina!)}</dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiepilogoBandoDetail;
