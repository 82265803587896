import axios from 'axios';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';
import { DocumentoUploaded, OnboardingConcorsoStato } from 'components/crea-candidato-form/types';
import { useEffect, useState } from 'react';

export interface IAttoOnboardingConcorso {
  id: number;
  tipoAtto: string;
  oggettoAtto: string;
  dataAtto: string;
  numeroAtto: string;
}

export interface IOnboardingConcorso {
  id: number;
  idConcorso: number;
  stato: OnboardingConcorsoStato;
  statoOnb: OnboardingConcorsoStatoOnb;
  areaProfessionale: string;
  tipologiaContrattoId: string | number;
  elencoDocumenti: Array<DocumentoUploaded>;
  attiOnboarding: Array<IAttoOnboardingConcorso>;
  nomeConcorso?: string;
  livello?: string;
  posizioneGraduatoria?: string;
  vincitore?: boolean;
  idoneo?: boolean;
  note?: string;
}

const useGetOnboardingById = (onboardingId?: number | string) => {
  useEffect(() => {
    if (!onboardingId) return;
    axios
      .get(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/onboarding/${onboardingId}`)
      .then((response) => {
        setOnboarding(response.data);
      })
      .catch((error) => {
        console.error('Error fetching concorsi:', error);
      });
  }, [onboardingId]);

  const [onboarding, setOnboarding] = useState<IOnboardingConcorso | undefined>(undefined);

  return onboarding;
};

export default useGetOnboardingById;
