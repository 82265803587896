import { Accordion, AccordionBody, AccordionHeader, Alert } from 'design-react-kit';
import { useEffect, useState } from 'react';
import InformazioniPersonaliDetail from './InformazioniPersonaliDetail';
import EsperienzeProfessionaliDetail from './EsperienzeProfessionaliDetail';
import IstruzioneFormazioneDetail from './IstruzioneFormazioneDetail';
import CapacitaCompetenzaDetail from './CapacitaCompetenzaDetail';
import ElencoAllegatiDetail from './ElencoAllegatiDetail';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCvDetail } from '../../../store/curriculumVitaeSlice';
import { detailsDipendente } from '../../../store/rubricaSlice';
import { formatDate } from '../../../utility/formatDate';
import EsperienzeProfessionaliInRegioneDetail from './EsperienzeProfessionaliInRegioneDetail';

const VisualizzaCV = (props: { idDipendente: number }) => {
  const dispatch = useAppDispatch();
  const { detailResult } = useAppSelector((state) => state.curriculumVitae);
  const { dipendenteResult } = useAppSelector((state) => state.rubrica);
  const [collapseElementOpen, setCollapseElement] = useState<Array<number>>([]);

  useEffect(() => {
    dispatch(detailsDipendente(Number(props.idDipendente)))
      .unwrap()
      .then((resp) => {
        if (resp) {
          dispatch(getCvDetail(props.idDipendente!))
            .unwrap()
            .then((resp) => {
              setCollapseElement([1]);
            });
        }
      });
  }, []);

  const toggleAccordion = (i: number) => {
    if (collapseElementOpen.includes(i)) {
      const updated = collapseElementOpen.filter((e) => e !== i);
      setCollapseElement(updated);
    } else {
      const updated = [...collapseElementOpen, i];
      setCollapseElement(updated);
    }
  };

  return (
    detailResult &&
    dipendenteResult && (
      <div>
        <Accordion>
          <>
            <AccordionHeader
              active={collapseElementOpen.includes(1)}
              onToggle={() => toggleAccordion(1)}
            >
              Informazioni curriculum vitae
            </AccordionHeader>
            <AccordionBody active={collapseElementOpen.includes(1)} listClassName={'custom-class'}>
              <div className="row">
                {detailResult.dataInserimento && detailResult.stato !== 'PUBBLICATO' && (
                  <dl className="col-sm-4">
                    <dt className="col-sm-12">Data inserimento</dt>
                    <dd className="col-sm-12">{formatDate(detailResult?.dataInserimento)}</dd>
                  </dl>
                )}
                {detailResult.dataPubblicazione && (
                  <dl className="col-sm-4">
                    <dt className="col-sm-12">Data pubblicazione</dt>
                    <dd className="col-sm-12">{formatDate(detailResult?.dataPubblicazione)}</dd>
                  </dl>
                )}
                {detailResult.dataModifica && (
                  <dl className="col-sm-4">
                    <dt className="col-sm-12">Data ultima modifica</dt>
                    <dd className="col-sm-12">{formatDate(detailResult?.dataModifica)}</dd>
                  </dl>
                )}
              </div>
            </AccordionBody>
          </>
          {detailResult.informazioniPersonali && (
            <>
              <AccordionHeader
                active={collapseElementOpen.includes(2)}
                onToggle={() => toggleAccordion(2)}
              >
                Informazioni personali
              </AccordionHeader>
              <AccordionBody
                active={collapseElementOpen.includes(2)}
                listClassName={'custom-class'}
              >
                <InformazioniPersonaliDetail
                  dati={detailResult.informazioniPersonali}
                  datiAnagrafici={dipendenteResult!}
                ></InformazioniPersonaliDetail>
              </AccordionBody>
            </>
          )}
          <AccordionHeader
            active={collapseElementOpen.includes(3)}
            onToggle={() => toggleAccordion(3)}
          >
            Esperienze professionali in ARPAL Puglia
          </AccordionHeader>
          <AccordionBody active={collapseElementOpen.includes(3)} listClassName={'custom-class'}>
            {detailResult.elencoEsperienzeProfessionaliInRegione &&
            detailResult.elencoEsperienzeProfessionaliInRegione.length > 0 ? (
              <EsperienzeProfessionaliInRegioneDetail
                dati={detailResult.elencoEsperienzeProfessionaliInRegione}
              ></EsperienzeProfessionaliInRegioneDetail>
            ) : (
              <Alert color="info">La sezione non è ancora stata compilata</Alert>
            )}
          </AccordionBody>
          <AccordionHeader
            active={collapseElementOpen.includes(4)}
            onToggle={() => toggleAccordion(4)}
          >
            Esperienze professionali
          </AccordionHeader>
          <AccordionBody active={collapseElementOpen.includes(4)} listClassName={'custom-class'}>
            {detailResult.elencoEsperienzeProfessionali &&
            detailResult.elencoEsperienzeProfessionali.length > 0 ? (
              <EsperienzeProfessionaliDetail
                dati={detailResult.elencoEsperienzeProfessionali}
              ></EsperienzeProfessionaliDetail>
            ) : (
              <Alert color="info">La sezione non è ancora stata compilata</Alert>
            )}
          </AccordionBody>
          <AccordionHeader
            active={collapseElementOpen.includes(5)}
            onToggle={() => toggleAccordion(5)}
          >
            Istruzione e formazione
          </AccordionHeader>
          <AccordionBody active={collapseElementOpen.includes(5)} listClassName={'custom-class'}>
            {detailResult.elencoIstruzioneFormazione &&
            detailResult.elencoIstruzioneFormazione.length > 0 ? (
              <IstruzioneFormazioneDetail
                dati={detailResult.elencoIstruzioneFormazione}
              ></IstruzioneFormazioneDetail>
            ) : (
              <Alert color="info">La sezione non è ancora stata compilata</Alert>
            )}
          </AccordionBody>
          <AccordionHeader
            active={collapseElementOpen.includes(6)}
            onToggle={() => toggleAccordion(6)}
          >
            Capacità e competenze personali
          </AccordionHeader>
          <AccordionBody active={collapseElementOpen.includes(6)} listClassName={'custom-class'}>
            {detailResult.capacitaCompetenzePersonali ? (
              <CapacitaCompetenzaDetail
                dati={detailResult.capacitaCompetenzePersonali}
              ></CapacitaCompetenzaDetail>
            ) : (
              <Alert color="info">La sezione non è ancora stata compilata</Alert>
            )}
          </AccordionBody>
          {/* <AccordionHeader
                    active={collapseElementOpen.includes(7)}
                    onToggle={() => toggleAccordion(7)}
                    >
                    Allegati
                </AccordionHeader>
                <AccordionBody active={collapseElementOpen.includes(7)} listClassName={'custom-class'}>
                {detailResult.elencoAllegati ? 
                    <ElencoAllegatiDetail dati={detailResult.elencoAllegati}></ElencoAllegatiDetail>
                    : <Alert color='info'>La sezione non è ancora stata compilata</Alert>}
                </AccordionBody> */}
        </Accordion>
      </div>
    )
  );
};

export default VisualizzaCV;
