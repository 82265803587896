import { Input, Col, TextArea, Toggle, Row } from 'design-react-kit';
import AccordionElement from '../../accordion-element/AccordionElement';
import { useCompositeForm } from '../../../hooks/useCompositeForm';

export default function FormTitoloDiStudio({ readingOnly }: { readingOnly?: boolean }) {
  const { values, handleChange, handleBlur, setFieldValue, errors, touched } = useCompositeForm();

  return (
    <AccordionElement title="Titolo di Studio">
      <>
        <Row>
          <Col md={6} className="form-row mt-4">
            <Input
              id="titoloStudio"
              name="titoloStudio"
              type="text"
              label="Titolo di studio dichiarato"
              placeholder="Inserisci titolo di studio"
              wrapperClassName="form-group col col-md-11"
              // @ts-ignore
              value={values.titoloStudio}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={!!errors.titoloStudio}
              infoText={errors.titoloStudio && errors.titoloStudio}
              disabled={readingOnly}
            />
          </Col>
          <Col md={6} className="px-1 mt-4">
            <Toggle
              label="Titolo di studio dichiarato prodotto come titolo dichiarato o superiore"
              name="titoloDichiarato"
              // @ts-ignore
              value={values.titoloDichiarato}
              invalid={!!errors.titoloDichiarato}
              infoText={errors.titoloDichiarato}
              onChange={(event: any) => {
                const isChecked = event.target.checked;
                setFieldValue('titoloDichiarato', isChecked);

                if (!isChecked) {
                  setFieldValue('noteTitolo', '');
                }
              }}
              disabled={readingOnly}
            />
          </Col>
        </Row>

        <Row>
          {values.titoloDichiarato && (
            <Col md={12}>
              <TextArea
                label="Note titolo di studio"
                placeholder="Inserisci delle note"
                id="input-noteTitolo"
                value={values.noteTitolo}
                onChange={handleChange}
                wrapperClassName="form-group col-md-12"
                name="noteTitolo"
                onBlur={handleBlur}
                invalid={touched.noteTitolo && !!errors.noteTitolo}
                infoText={errors.noteTitolo && errors.noteTitolo}
                disabled={readingOnly}
              />
            </Col>
          )}
        </Row>
      </>
    </AccordionElement>
  );
}
