import { Callout, CalloutTitle } from 'design-react-kit';
import { CapacitaCompetenzePersonaliDto } from '../../../services/ms-anagrafica-unica';

const CapacitaCompetenzaDetail = (props: { dati: CapacitaCompetenzePersonaliDto }) => {
  const { dati } = props;
  return (
    <div className="wrap-text row">
      <dl className="col-sm-12">
        {!!dati.madrelingua && (
          <div className="row">
            <div className="col-sm-4">
              <dt className="col-sm-12">Madrelingua</dt>
              <dd className="col-sm-12">{dati.madrelingua.label}</dd>
            </div>
          </div>
        )}
        {dati.elencoCompetenzeLingue &&
          dati.elencoCompetenzeLingue.length > 0 &&
          dati.elencoCompetenzeLingue.map((lingua) => (
            <Callout className="au-callout">
              <CalloutTitle>{`${lingua.lingua?.label}`}</CalloutTitle>
              <div className="row">
                {lingua.comprensioneAscolto && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Comprensione ascolto</dt>
                    <dd className="col-sm-12">{lingua.comprensioneAscoltoLabel}</dd>
                  </div>
                )}
                {lingua.comprensioneLettura && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Comprensione lettura</dt>
                    <dd className="col-sm-12">{lingua.comprensioneLetturaLabel}</dd>
                  </div>
                )}
                {lingua.parlatoInterazioneOrale && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Parlato interazione orale</dt>
                    <dd className="col-sm-12">{lingua.parlatoInterazioneOraleLabel}</dd>
                  </div>
                )}
                {lingua.parlatoProduzioneOrale && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Parlato - Produzione orale</dt>
                    <dd className="col-sm-12">{lingua.parlatoProduzioneOraleLabel}</dd>
                  </div>
                )}
                {lingua.scritto && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Scritto</dt>
                    <dd className="col-sm-12">{lingua.scrittoLabel}</dd>
                  </div>
                )}
              </div>
            </Callout>
          ))}
        {!!dati.capacitaCompetenzeSociali && (
          <div className="row">
            <div className="col-sm-12">
              <dt className="col-sm-12">Capacità e compentenze sociali</dt>
              <dd className="col-sm-12">{dati.capacitaCompetenzeSociali}</dd>
            </div>
          </div>
        )}
        {!!dati.capacitaCompetenzeOrganizzativeLabel &&
          dati.capacitaCompetenzeOrganizzativeLabel.length > 0 && (
            <div className="col-sm-12">
              <dt className="col-sm-12">Capacità e competenze organizzative</dt>
              <dd className="col-sm-12">{dati.capacitaCompetenzeOrganizzativeLabel.join(', ')}</dd>
            </div>
          )}
        {!!dati.capacitaCompetenzeTecniche && (
          <div className="col-sm-12">
            <dt className="col-sm-12">Capacità e competenze tecniche</dt>
            <dd className="col-sm-12">{dati.capacitaCompetenzeTecniche}</dd>
          </div>
        )}
        {!!dati.capacitaCompetenzeInformatiche && (
          <div className="col-sm-12">
            <dt className="col-sm-12">Capacità e competenze informatiche</dt>
            <dd className="col-sm-12">{dati.capacitaCompetenzeInformatiche}</dd>
          </div>
        )}
        {!!dati.altreCapacitaCompetenze && (
          <div className="col-sm-12">
            <dt className="col-sm-12">Altre capacità e competenze</dt>
            <dd className="col-sm-12">{dati.altreCapacitaCompetenze}</dd>
          </div>
        )}
        {!!dati.certificazioni && (
          <div className="col-sm-12">
            <dt className="col-sm-12">Certificazioni</dt>
            <dd className="col-sm-12">{dati.certificazioni}</dd>
          </div>
        )}
        {!!dati.patente && (
          <div className="col-sm-12">
            <dt className="col-sm-12">Patente</dt>
            <dd className="col-sm-12">{dati.patente}</dd>
          </div>
        )}
        {!!dati.ulterioriInformazioni && (
          <div className="col-sm-12">
            <dt className="col-sm-12">Ulteriori informazioni</dt>
            <dd className="col-sm-12">{dati.ulterioriInformazioni}</dd>
          </div>
        )}
      </dl>
    </div>
  );
};

export default CapacitaCompetenzaDetail;
