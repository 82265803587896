import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AnagraficaUnicaDto,
  AreaDocumentiCandidatoRequestDto,
  ComunicazioneConvocazioneRequestDto,
  ConfigurazioneDocumentiNuoveAssunzioniDto,
  ConvocazioneNuoveAssunzioniDto,
  DecisioneConvocazioneRequestDtoDecisioneConvocazioneEnum,
  DocumentoConvocazioneSottoscrizioneRequestDto,
  DocumentoPrimaConvocazioneRequestDto,
  ElencoNuoveAssunzioniForm,
  FormsControllerApi,
  GestioneAssunzioniControllerApi,
  GestioneAssunzioniControllerApiRettificaCandidatoRequest,
  GestioneAssunzioniControllerApiRicercaInAnprRequest,
  NuoveAssunzioniDto,
  NuoveAssunzioniInsertUpdateRequestDto,
  NuoveAssunzioniPatchRequestDto,
  PagedDtoElencoNuoveAssunzioniDto,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';
import { AxiosError } from 'axios';

export interface CandidatoState {
  searchResult: PagedDtoElencoNuoveAssunzioniDto;
  searchStatus: string;
  formSearchResult: ElencoNuoveAssunzioniForm | null;
  formSearchStatus: string;
  searchInput: SearchCandidatiRequest | null;
  inputResult: NuoveAssunzioniDto | null;
  inputStatus: string;
  detailsResult: NuoveAssunzioniDto | null;
  detailsStatus: string;
  generaDocumentoResult: string | null;
  generaDocumentoStatus: string;
  updateResult: NuoveAssunzioniDto | null;
  updateStatus: string;
  rettificaStatus: string;
  verificaDatiDocumentiStatus: string;
  elencoConvocazioniResult: Array<ConvocazioneNuoveAssunzioniDto> | null;
  elencoConvocazioniStatus: string;
  elencoDocumentiResult: Array<ConfigurazioneDocumentiNuoveAssunzioniDto> | null;
  elencoDocumentiStatus: string;
  modificaDocumentoResult: ConfigurazioneDocumentiNuoveAssunzioniDto | null;
  modificaDocumentoStatus: string;
  detailsDocumentoResult: ConfigurazioneDocumentiNuoveAssunzioniDto | null;
  detailsDocumentoStatus: string;
  ricercaCandidatoResult: AnagraficaUnicaDto | null;
  ricercaCandidatoStatus: string;
}

const initialState: CandidatoState = {
  searchResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  searchStatus: '',
  searchInput: null,
  formSearchResult: null,
  formSearchStatus: '',
  inputResult: null,
  inputStatus: '',
  detailsResult: null,
  detailsStatus: '',
  generaDocumentoResult: null,
  generaDocumentoStatus: '',
  updateResult: null,
  updateStatus: '',
  rettificaStatus: '',
  verificaDatiDocumentiStatus: '',
  elencoConvocazioniResult: null,
  elencoConvocazioniStatus: '',
  elencoDocumentiResult: null,
  elencoDocumentiStatus: '',
  modificaDocumentoResult: null,
  modificaDocumentoStatus: '',
  detailsDocumentoResult: null,
  detailsDocumentoStatus: '',
  ricercaCandidatoResult: null,
  ricercaCandidatoStatus: '',
};

export interface SearchCandidatiRequest {
  pageSize?: number;
  pageNum?: number;
  nome?: string;
  cognome?: string;
  codiceFiscale?: string;
  stato?: any;
  sort: any;
  direction: any;
}

export interface updateCandidato {
  id: number;
  bodyRequest: NuoveAssunzioniInsertUpdateRequestDto;
}

export interface generaDocumentoRequest {
  bodyRequest: DocumentoPrimaConvocazioneRequestDto;
}

export interface patchValidazioneIntegrazioneDati {
  id: number;
  bodyRequest: NuoveAssunzioniPatchRequestDto;
}

export interface convocazioneComunicazione {
  id: number;
  bodyRequest: ComunicazioneConvocazioneRequestDto;
}

export interface convocazioneSottoscrizioneCandidato {
  id: number;
  decisione: DecisioneConvocazioneRequestDtoDecisioneConvocazioneEnum;
  idDocumentoDecisione: number;
  idFoto: number;
  note: string;
}

export interface patchAccettazioneContratto {
  id: number;
  idDocumento: number;
}

export interface patchDocumento {
  id: number;
  bodyRequest: AreaDocumentiCandidatoRequestDto;
}

export const searchElencoCandidati = createAsyncThunk(
  'candidato/searchCandidati',
  async (args: SearchCandidatiRequest) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.searchCandidati({
      pageNum: args.pageNum,
      pageSize: args.pageSize,
      codiceFiscale: args.codiceFiscale,
      cognome: args.cognome,
      direction: args.direction,
      nome: args.nome,
      sort: args.sort,
      statoCandidato: args.stato,
    });
    return response.data;
  }
);

export const formSrachPopulate = createAsyncThunk('candidato/formSearchPopulate', async () => {
  const api = new FormsControllerApi(MS_AU_CONFIG);
  const response = await api.getElencoNuoveAssunzioniForm();
  return response.data;
});

export const insertCandidato = createAsyncThunk(
  'candidato/insert',
  async (body: NuoveAssunzioniInsertUpdateRequestDto) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.insertCandidato({
      nuoveAssunzioniInsertUpdateRequestDto: body,
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const ricercaCandidato = createAsyncThunk(
  'candidato/ricercaInAnpr',
  async (args: GestioneAssunzioniControllerApiRicercaInAnprRequest) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.ricercaInAnpr(args);
    return response.data;
  }
);

export const updateCandidato = createAsyncThunk(
  'candidato/update',
  async (body: updateCandidato) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.updateCandidato({
      id: body.id,
      nuoveAssunzioniInsertUpdateRequestDto: body.bodyRequest,
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const detailsCandidato = createAsyncThunk('candidato/details', async (id: number) => {
  const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
  const response = await api.dettaglioCandidato({
    id: id,
  });
  return response.data;
});

export const generaDocumentoPrimaConvocazione = createAsyncThunk(
  'candidato/generaDocumentoPrimaConvocazione',
  async (body: generaDocumentoRequest) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.documentoPrimaConvocazione(
      {
        documentoPrimaConvocazioneRequestDto: body.bodyRequest,
      },
      { responseType: 'arraybuffer' }
    );
    return response.data;
  }
);

export const validazioneIntegrazioneDati = createAsyncThunk(
  'candidato/validazioneIntegrazioneDati',
  async (body: patchValidazioneIntegrazioneDati) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.patchCandidato({
      id: body.id,
      nuoveAssunzioniPatchRequestDto: body.bodyRequest,
    });
    return response.data;
  }
);

export const rettificaCandidato = createAsyncThunk(
  'candidato/rettificaCandidato',
  async (rettifica: GestioneAssunzioniControllerApiRettificaCandidatoRequest) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.rettificaCandidato({
      id: rettifica.id,
      rettificaCandidatoDto: rettifica.rettificaCandidatoDto,
    });
    return response.data;
  }
);

export const generaDocumentoConvocazioneSottoscrizione = createAsyncThunk(
  'candidato/generaDocumentoConvocazioneSottoscrizione',
  async (body: DocumentoConvocazioneSottoscrizioneRequestDto) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.documentoConvocazioneSottoscrizione(
      {
        documentoConvocazioneSottoscrizioneRequestDto: body,
      },
      { responseType: 'arraybuffer' }
    );
    return response.data;
  }
);

export const comunicazioneConvocazioneSottoscrizione = createAsyncThunk(
  'candidato/comunicazioneConvocazioneSottoscrizione',
  async (body: convocazioneComunicazione) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.comunicazioneConvocazione({
      id: body.id,
      comunicazioneConvocazioneRequestDto: body.bodyRequest,
    });
    return response.data;
  }
);

export const getConvocazioniSottoscrizione = createAsyncThunk(
  'candidato/getConvocazioniSottoscrizione',
  async (id: number) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.elencoConvocazioni({
      id: id,
    });
    return response.data;
  }
);

export const pathDecisioneConvocazione = createAsyncThunk(
  'candidato/getConvocazioneSottoscrizioneCandidato',
  async (body: convocazioneSottoscrizioneCandidato) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.patchDecisione({
      id: body.id,
      decisioneConvocazioneRequestDto: {
        decisioneConvocazione: body.decisione,
        idDocumentoDecisione: body.idDocumentoDecisione,
        idFoto: body.idFoto,
        note: body.note,
      },
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const accettazioneContratto = createAsyncThunk(
  'candidato/accettazioneCandidato',
  async (body: patchAccettazioneContratto) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.patchAccettazione({
      id: body.id,
      accettazioneContrattoRequestDto: {
        idDocumento: body.idDocumento,
      },
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const cessazioneContratto = createAsyncThunk(
  'candidato/cessazioneCandidato',
  async (id: number) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.patchCessazione({
      id: id,
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const getListaDocumenti = createAsyncThunk('candidato/getListaDocumenti', async () => {
  const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
  const response = await api.elencoDocumentiCandidato();
  return response.data;
});

export const modificaDocumento = createAsyncThunk(
  'candidato/modificaDocumento',
  async (body: patchDocumento) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.patchDocumento({
      id: body.id,
      areaDocumentiCandidatoRequestDto: body.bodyRequest,
    });
    return response.data;
  }
);

export const dettaglioConfigDocumento = createAsyncThunk(
  'candidato/detailsCandidato',
  async (id: number) => {
    const api = new GestioneAssunzioniControllerApi(MS_AU_CONFIG);
    const response = await api.dettaglioDocumentoCandidato({
      id,
    });
    return response.data;
  }
);

export const candidatoSilce = createSlice({
  name: 'candidato',
  initialState,
  reducers: {
    resetInputForm: (state) => {
      state.inputStatus = '';
    },
    resetUpdateForm: (state) => {
      state.updateStatus = '';
      state.detailsResult = null;
      state.detailsStatus = '';
    },
    resetGeneraDocumento: (state) => {
      state.generaDocumentoResult = null;
    },
    resetRettificaStatus: (state) => {
      state.rettificaStatus = '';
    },
    resetAll: (state) => {
      state.generaDocumentoResult = null;
      state.updateResult = null;
      state.updateStatus = '';
      state.rettificaStatus = '';
      state.verificaDatiDocumentiStatus = '';
      state.elencoConvocazioniResult = null;
      state.elencoConvocazioniStatus = '';
      state.elencoDocumentiResult = null;
      state.elencoDocumentiStatus = '';
      state.modificaDocumentoResult = null;
      state.modificaDocumentoStatus = '';
      state.detailsDocumentoResult = null;
      state.detailsDocumentoStatus = '';
      state.detailsStatus = '';
      state.detailsResult = null;
      state.generaDocumentoStatus = '';
    },
  },
  extraReducers: (builder) => {
    //search
    builder.addCase(searchElencoCandidati.pending, (state: CandidatoState, action: any) => {
      state.searchStatus = STATUS_PENDING;
    });
    builder.addCase(searchElencoCandidati.fulfilled, (state: CandidatoState, action: any) => {
      state.searchResult = action.payload;
      state.searchInput = action.meta.arg;
      state.searchStatus = STATUS_FULLFILLED;
    });
    builder.addCase(searchElencoCandidati.rejected, (state: CandidatoState, action: any) => {
      state.searchStatus = STATUS_REJECTED;
    });

    //populate form stato search
    builder.addCase(formSrachPopulate.pending, (state: CandidatoState, action: any) => {
      state.formSearchStatus = STATUS_PENDING;
    });
    builder.addCase(formSrachPopulate.fulfilled, (state: CandidatoState, action: any) => {
      state.formSearchResult = action.payload;
      state.formSearchStatus = STATUS_FULLFILLED;
    });
    builder.addCase(formSrachPopulate.rejected, (state: CandidatoState, action: any) => {
      state.formSearchStatus = STATUS_REJECTED;
    });

    //inserimento candidato
    builder.addCase(insertCandidato.pending, (state: CandidatoState, action: any) => {
      state.inputStatus = STATUS_PENDING;
    });
    builder.addCase(insertCandidato.fulfilled, (state: CandidatoState, action: any) => {
      state.inputResult = action.payload;
      state.inputStatus = STATUS_FULLFILLED;
    });
    builder.addCase(insertCandidato.rejected, (state: CandidatoState, action: any) => {
      state.inputStatus = STATUS_REJECTED;
    });

    //update candidato
    builder.addCase(updateCandidato.pending, (state: CandidatoState, action: any) => {
      state.inputStatus = STATUS_PENDING;
    });
    builder.addCase(updateCandidato.fulfilled, (state: CandidatoState, action: any) => {
      state.inputResult = action.payload;
      state.inputStatus = STATUS_FULLFILLED;
    });
    builder.addCase(updateCandidato.rejected, (state: CandidatoState, action: any) => {
      state.inputStatus = STATUS_REJECTED;
    });

    //details candidato
    builder.addCase(detailsCandidato.pending, (state: CandidatoState, action: any) => {
      state.detailsStatus = STATUS_PENDING;
    });
    builder.addCase(detailsCandidato.fulfilled, (state: CandidatoState, action: any) => {
      state.detailsResult = action.payload;
      state.detailsStatus = STATUS_FULLFILLED;
    });
    builder.addCase(detailsCandidato.rejected, (state: CandidatoState, action: any) => {
      state.detailsStatus = STATUS_REJECTED;
    });

    //genera documento prima convocazione
    builder.addCase(
      generaDocumentoPrimaConvocazione.pending,
      (state: CandidatoState, action: any) => {
        state.generaDocumentoStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      generaDocumentoPrimaConvocazione.fulfilled,
      (state: CandidatoState, action: any) => {
        state.generaDocumentoResult = action.payload;
        state.generaDocumentoStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      generaDocumentoPrimaConvocazione.rejected,
      (state: CandidatoState, action: any) => {
        state.generaDocumentoStatus = STATUS_REJECTED;
      }
    );

    //genera documento convocazione sottoscrizione
    builder.addCase(
      generaDocumentoConvocazioneSottoscrizione.pending,
      (state: CandidatoState, action: any) => {
        state.generaDocumentoStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      generaDocumentoConvocazioneSottoscrizione.fulfilled,
      (state: CandidatoState, action: any) => {
        state.generaDocumentoResult = action.payload;
        state.generaDocumentoStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      generaDocumentoConvocazioneSottoscrizione.rejected,
      (state: CandidatoState, action: any) => {
        state.generaDocumentoStatus = STATUS_REJECTED;
      }
    );

    //validazione integrazione dati candidato
    builder.addCase(validazioneIntegrazioneDati.pending, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_PENDING;
    });
    builder.addCase(validazioneIntegrazioneDati.fulfilled, (state: CandidatoState, action: any) => {
      state.updateResult = action.payload;
      state.updateStatus = STATUS_FULLFILLED;
    });
    builder.addCase(validazioneIntegrazioneDati.rejected, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_REJECTED;
    });

    //rettifica dati candidato
    builder.addCase(rettificaCandidato.pending, (state: CandidatoState, action: any) => {
      state.rettificaStatus = STATUS_PENDING;
    });
    builder.addCase(rettificaCandidato.fulfilled, (state: CandidatoState, action: any) => {
      state.rettificaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(rettificaCandidato.rejected, (state: CandidatoState, action: any) => {
      state.rettificaStatus = STATUS_REJECTED;
    });

    //convocazioneComunicazione
    builder.addCase(
      comunicazioneConvocazioneSottoscrizione.pending,
      (state: CandidatoState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      comunicazioneConvocazioneSottoscrizione.fulfilled,
      (state: CandidatoState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      comunicazioneConvocazioneSottoscrizione.rejected,
      (state: CandidatoState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //recupero elenco sottoscrizioni by id candidato
    builder.addCase(getConvocazioniSottoscrizione.pending, (state: CandidatoState, action: any) => {
      state.elencoConvocazioniStatus = STATUS_PENDING;
    });
    builder.addCase(
      getConvocazioniSottoscrizione.fulfilled,
      (state: CandidatoState, action: any) => {
        state.elencoConvocazioniResult = action.payload;
        state.elencoConvocazioniStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      getConvocazioniSottoscrizione.rejected,
      (state: CandidatoState, action: any) => {
        state.elencoConvocazioniStatus = STATUS_REJECTED;
      }
    );

    //patch accettazione contratto candidato
    builder.addCase(accettazioneContratto.pending, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_PENDING;
    });
    builder.addCase(accettazioneContratto.fulfilled, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_FULLFILLED;
    });
    builder.addCase(accettazioneContratto.rejected, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_REJECTED;
    });

    //patch cessazione contratto candidato
    builder.addCase(cessazioneContratto.pending, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_PENDING;
    });
    builder.addCase(cessazioneContratto.fulfilled, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_FULLFILLED;
    });
    builder.addCase(cessazioneContratto.rejected, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_REJECTED;
    });

    //recupero elenco dei documenti da configurare
    builder.addCase(getListaDocumenti.pending, (state: CandidatoState, action: any) => {
      state.elencoDocumentiStatus = STATUS_PENDING;
    });
    builder.addCase(getListaDocumenti.fulfilled, (state: CandidatoState, action: any) => {
      state.elencoDocumentiResult = action.payload;
      state.elencoDocumentiStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getListaDocumenti.rejected, (state: CandidatoState, action: any) => {
      state.elencoDocumentiStatus = STATUS_REJECTED;
    });

    //modifica configurazione documenti
    builder.addCase(modificaDocumento.pending, (state: CandidatoState, action: any) => {
      state.modificaDocumentoStatus = STATUS_PENDING;
    });
    builder.addCase(modificaDocumento.fulfilled, (state: CandidatoState, action: any) => {
      state.modificaDocumentoResult = action.payload;
      state.modificaDocumentoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(modificaDocumento.rejected, (state: CandidatoState, action: any) => {
      state.modificaDocumentoStatus = STATUS_REJECTED;
    });

    //dettaglio documento
    builder.addCase(dettaglioConfigDocumento.pending, (state: CandidatoState, action: any) => {
      state.detailsDocumentoStatus = STATUS_PENDING;
    });
    builder.addCase(dettaglioConfigDocumento.fulfilled, (state: CandidatoState, action: any) => {
      state.detailsDocumentoResult = action.payload;
      state.detailsDocumentoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(dettaglioConfigDocumento.rejected, (state: CandidatoState, action: any) => {
      state.detailsDocumentoStatus = STATUS_REJECTED;
    });

    //convocazione sottoscrizione candidato
    builder.addCase(pathDecisioneConvocazione.pending, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_PENDING;
    });
    builder.addCase(pathDecisioneConvocazione.fulfilled, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_FULLFILLED;
    });
    builder.addCase(pathDecisioneConvocazione.rejected, (state: CandidatoState, action: any) => {
      state.updateStatus = STATUS_REJECTED;
    });

    //ricerca candidato
    builder.addCase(ricercaCandidato.pending, (state: CandidatoState, action: any) => {
      state.ricercaCandidatoStatus = STATUS_PENDING;
    });
    builder.addCase(ricercaCandidato.fulfilled, (state: CandidatoState, action: any) => {
      console.log('action.payload', action.payload);
      state.ricercaCandidatoResult = action.payload;
      state.ricercaCandidatoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(ricercaCandidato.rejected, (state: CandidatoState, action: any) => {
      state.ricercaCandidatoStatus = STATUS_REJECTED;
    });
  },
});

export const {
  resetInputForm,
  resetUpdateForm,
  resetGeneraDocumento,
  resetRettificaStatus,
  resetAll,
} = candidatoSilce.actions;

export default candidatoSilce.reducer;
