import {
  Button,
  Col,
  Form,
  FormGroup,
  Icon,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Select,
  TextArea,
} from 'design-react-kit';
import { useFormik } from 'formik';
import { useState, type ReactNode } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import useUploadFiles from 'hooks/services/useUploadFiles';
import {
  initialValues,
  OnboardingConcorsoStatoOnb,
  type IFormCreaCandidatoForm,
} from 'components/crea-candidato-form/CreaCandidatoForm';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useRegistraDocumenti from 'hooks/services/useRegistraDocumenti';
import useAsyncInitialValues from 'hooks/useAsyncInitialValues';
import validationSchemaWithAddedFields from 'components/forms/validationSchemaWithAddedFields';
import { z } from 'zod';
import { FormContext } from 'hooks/useCompositeForm';
import CampoForm from 'components/campo-form/CampoForm';
import { ErrorMessage } from 'components/error-message/ErrorMessage';
import { errors } from 'jose';
import { values } from 'lodash';

export interface IFormCandidatoWithAddedFields extends IFormCreaCandidatoForm {
  fileCv?: File;
  note?: string;
}

export const initialValuesWithAddedFields = {
  ...initialValues,
};

export interface IFormRettifica {
  descrizione: string;
}

interface IFormVerificaAltriDati {
  verificaCasellari: 'No' | 'Si' | null;
  noteCasellari: string;
  verificaTitoloDiStudio: 'No' | 'Si' | null;
  noteTitoloDiStudio: string;
  verificaDisabilita: 'No' | 'Si' | null;
  noteDisabilita: string;
}

const initialValuesVerificaAltriDati: IFormVerificaAltriDati = {
  verificaCasellari: null,
  noteCasellari: '',
  verificaTitoloDiStudio: null,
  noteTitoloDiStudio: '',
  verificaDisabilita: null,
  noteDisabilita: '',
};

const validationSchemaVerificaAltriDati = toFormikValidationSchema(
  z.object({
    verificaCasellari: z.enum(['Si', 'No'], {
      errorMap: (issue, ctx) => ({
        message: 'Seleziona Si o No',
      }),
    }),
    verificaTitoloDiStudio: z.enum(['Si', 'No'], {
      errorMap: (issue, ctx) => ({
        message: 'Seleziona Si o No',
      }),
    }),
    verificaDisabilita: z.enum(['Si', 'No'], {
      errorMap: (issue, ctx) => ({
        message: 'Seleziona Si o No',
      }),
    }),
    noteCasellari: z
      .string({
        required_error: 'Le note di verifica casellari sono obbligatorie',
      })
      .max(5000, { message: 'Must be 5000 or fewer characters long' }),
    noteTitoloDiStudio: z
      .string({
        required_error: 'Le note di verifica titolo di studio sono obbligatorie',
      })
      .max(5000, { message: 'Must be 5000 or fewer characters long' }),
    noteDisabilita: z
      .string()
      .max(5000, { message: 'Must be 5000 or fewer characters long' })
      .optional(),
  })
);

export const FormVerificaAltriDati = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValuesWithAddedFields,
    validationSchema: toFormikValidationSchema(validationSchemaWithAddedFields),
    onSubmit: (values) => {},
    enableReinitialize: true,
  });
  const isAsyncValuesLoading = useAsyncInitialValues(formik, candidato, onboarding);

  const formVerificaAltriDati = useFormik({
    initialValues: initialValuesVerificaAltriDati,
    validationSchema: validationSchemaVerificaAltriDati,
    onSubmit: (values) => {
      if (!candidato?.id) {
        dispatch(
          showNotifica({
            titolo: 'Invio verifica dati non è andato a buon fine',
            stato: 'error',
            messaggio: 'Invio verifica dati non è andato a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
        return;
      }

      const onboardingPayload = {
        ...onboarding,
        elencoDocumenti: [],
      };

      // chiudere processo con non contrattualizzabile
      if (values.verificaCasellari === 'No' || values.noteTitoloDiStudio === 'No') {
        try {
          axios.put(
            `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/ko/${candidato.id}/onboarding/${onboarding?.id}`,
            {
              ...candidato,
              onboardingConcorsi: [onboardingPayload],
            }
          );
          navigate('/elenco-candidati');
        } catch (error) {
          dispatch(
            showNotifica({
              titolo: 'Invio verifica dati non è andato a buon fine',
              stato: 'error',
              messaggio: 'Invio verifica dati non è andato a buon fine',
              tipo: NOTIFICA_STATO.error,
            })
          );
        }
      }
    },
  });

  const getDescrizioneFromNotes = (...notes: string[]) => notes.join(' ');

  const [isRettificaModalOpen, setIsRettificaModalOpen] = useState(false);
  const handleOpenRettifica = () => {
    setIsRettificaModalOpen(true);
  };

  const rettificaForm = useFormik({
    initialValues: {
      descrizione: getDescrizioneFromNotes(
        /*         formVerificaAltriDati.values.noteCasellari,
        formVerificaAltriDati.values.noteTitoloDiStudio, */
        formVerificaAltriDati.values.noteDisabilita
      ),
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        descrizione: z
          .string({
            required_error: 'Obbligatorio',
          })
          .min(1, 'Inserisci la descrizione')
          .max(5000, { message: 'Massimo 5000 caratteri' }),
      })
    ),
    enableReinitialize: true,
    onSubmit: async ({ descrizione }) => {
      if (!candidato?.id) {
        dispatch(
          showNotifica({
            titolo: 'Invio notifica rettifica non è andato a buon fine',
            stato: 'error',
            messaggio: 'Invio notifica rettifica non è andato a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
        return;
      }
      try {
        axios.post(
          `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato.id}/rettifica`,
          { descrizione }
        );
        navigate('/elenco-candidati');
      } catch (error) {
        dispatch(
          showNotifica({
            titolo: 'Invio notifica rettifica non è andato a buon fine',
            stato: 'error',
            messaggio: 'Invio notifica rettifica non è andato a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
      }
    },
  });

  if (isAsyncValuesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormContext.Provider value={formik}>
      <div>
        {children}
        <Form className="mt-4">
          <CampoForm
            titolo="Verifica casellari"
            style={{
              height: '280px',
              width: '100%',
              border: '1px solid #06c',
              borderRadius: '12px',
            }}
          >
            <Row>
              <Col md={4} className="px-1">
                <Select
                  id="verificaCasellari"
                  name="verificaCasellari"
                  value={{
                    value: formVerificaAltriDati.values.verificaCasellari,
                    label: formVerificaAltriDati.values.verificaCasellari,
                  }}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Si', label: 'Si' },
                  ]}
                  onChange={(option) => {
                    if (option) {
                      formVerificaAltriDati.setFieldValue('verificaCasellari', option.value);
                    }
                  }}
                  aria-describedby="verificaCasellariError"
                  placeholder="Seleziona"
                />
                {formVerificaAltriDati.touched.verificaCasellari &&
                  formVerificaAltriDati.errors.verificaCasellari && (
                    <ErrorMessage
                      touched={formVerificaAltriDati.touched.verificaCasellari}
                      error={formVerificaAltriDati.errors.verificaCasellari}
                    />
                  )}
              </Col>
            </Row>

            <Row
              style={{
                marginTop: '40px',
              }}
            >
              <Col md={12}>
                <TextArea
                  label="Note casellari"
                  placeholder="Inserisci delle note"
                  id="noteCasellari"
                  value={formVerificaAltriDati.values.noteCasellari}
                  onChange={formVerificaAltriDati.handleChange}
                  wrapperClassName="required formVerificaAltriDati-group col-md-12"
                  name="noteCasellari"
                  invalid={
                    formVerificaAltriDati.touched.noteCasellari &&
                    !!formVerificaAltriDati.errors.noteCasellari
                  }
                  infoText={
                    formVerificaAltriDati.errors.noteCasellari &&
                    formVerificaAltriDati.errors.noteCasellari
                  }
                />
              </Col>
            </Row>
          </CampoForm>

          <CampoForm
            titolo="Verifica Titolo di studio"
            style={{
              height: '280px',
              width: '100%',
              border: '1px solid #06c',
              borderRadius: '12px',
            }}
          >
            <Row>
              <Col md={4} className="px-1">
                <Select
                  id="verificaTitoloDiStudio"
                  name="verificaTitoloDiStudio"
                  value={{
                    value: formVerificaAltriDati.values.verificaTitoloDiStudio,
                    label: formVerificaAltriDati.values.verificaTitoloDiStudio,
                  }}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Si', label: 'Si' },
                  ]}
                  onChange={(option) => {
                    if (option) {
                      formVerificaAltriDati.setFieldValue('verificaTitoloDiStudio', option.value);
                    }
                  }}
                  aria-describedby="verificaTitoloDiStudioError"
                  placeholder="Seleziona"
                />
                {formVerificaAltriDati.touched.verificaTitoloDiStudio &&
                  formVerificaAltriDati.errors.verificaTitoloDiStudio && (
                    <ErrorMessage
                      touched={formVerificaAltriDati.touched.verificaTitoloDiStudio}
                      error={formVerificaAltriDati.errors.verificaTitoloDiStudio}
                    />
                  )}
              </Col>
            </Row>
            <Row
              style={{
                marginTop: '40px',
              }}
            >
              <Col md={12}>
                <TextArea
                  label="Note titoli di studio"
                  placeholder="Inserisci delle note"
                  id="noteTitoloDiStudio"
                  value={formVerificaAltriDati.values.noteTitoloDiStudio ?? ''}
                  onChange={formVerificaAltriDati.handleChange}
                  wrapperClassName="required formVerificaAltriDati-group col-md-12"
                  name="noteTitoloDiStudio"
                  invalid={
                    formVerificaAltriDati.touched.noteTitoloDiStudio &&
                    !!formVerificaAltriDati.errors.noteTitoloDiStudio
                  }
                  infoText={
                    formVerificaAltriDati.errors.noteTitoloDiStudio &&
                    formVerificaAltriDati.errors.noteTitoloDiStudio
                  }
                />
              </Col>
            </Row>
          </CampoForm>

          <CampoForm
            titolo="Verifica Disabilità"
            style={{
              height: '280px',
              width: '100%',
              border: '1px solid #06c',
              borderRadius: '12px',
            }}
          >
            <Row>
              <Col md={4} className="px-1">
                <Select
                  id="verificaDisabilita"
                  name="verificaDisabilita"
                  value={{
                    value: formVerificaAltriDati.values.verificaDisabilita,
                    label: formVerificaAltriDati.values.verificaDisabilita,
                  }}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Si', label: 'Si' },
                  ]}
                  onChange={(option) => {
                    if (option) {
                      formVerificaAltriDati.setFieldValue('verificaDisabilita', option.value);
                    }
                  }}
                  aria-describedby="verificaDisabilitaError"
                  placeholder="Seleziona"
                />
                {formVerificaAltriDati.touched.verificaDisabilita &&
                  formVerificaAltriDati.errors.verificaDisabilita && (
                    <ErrorMessage
                      touched={formVerificaAltriDati.touched.verificaDisabilita}
                      error={formVerificaAltriDati.errors.verificaDisabilita}
                    />
                  )}
              </Col>
            </Row>

            <Row
              style={{
                marginTop: '40px',
              }}
            >
              <Col md={12}>
                <TextArea
                  label="Note disabilità"
                  placeholder="Inserisci delle note"
                  id="noteDisabilita"
                  value={formVerificaAltriDati.values.noteDisabilita ?? ''}
                  onChange={formVerificaAltriDati.handleChange}
                  name="noteDisabilita"
                  wrapperClassName="formVerificaAltriDati-group col-md-12"
                  invalid={
                    formVerificaAltriDati.touched.noteDisabilita &&
                    !!formVerificaAltriDati.errors.noteDisabilita
                  }
                  infoText={
                    formVerificaAltriDati.errors.noteDisabilita &&
                    formVerificaAltriDati.errors.noteDisabilita
                  }
                />
              </Col>
            </Row>
          </CampoForm>

          <div className="d-flex col-12 mt-4">
            <Button color="primary" outline>
              <Icon color="primary" size="sm" className="mr-1" icon="it-arrow-left" />
              Annulla
            </Button>
            <div className="d-flex ml-auto">
              <Button
                className="mr-2"
                color="primary"
                outline
                onClick={handleOpenRettifica}
                disabled={
                  formVerificaAltriDati.values.verificaCasellari === 'No' ||
                  formVerificaAltriDati.values.verificaTitoloDiStudio === 'No'
                }
              >
                Richiedi Rettifica
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  formVerificaAltriDati.submitForm();
                }}
                disabled={
                  formVerificaAltriDati.values.verificaCasellari === 'Si' &&
                  formVerificaAltriDati.values.verificaTitoloDiStudio === 'Si'
                }
              >
                Conferma
              </Button>
            </div>
          </div>
        </Form>

        <Modal
          isOpen={isRettificaModalOpen}
          toggle={() => setIsRettificaModalOpen(false)}
          labelledBy="elimina-attivita"
          centered
        >
          <ModalHeader
            toggle={() => {
              setIsRettificaModalOpen(false);
            }}
            id="elimina-attivita"
          >
            Invia Notifica Rettifica
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '50px',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <Input
                label="Descrizione"
                type="text"
                placeholder=""
                id="descrizione"
                wrapperClassName="form-group required col-md-12"
                name="descrizione"
                value={rettificaForm.values.descrizione}
                onChange={rettificaForm.handleChange}
                onBlur={rettificaForm.handleBlur}
                invalid={rettificaForm.touched.descrizione && !!rettificaForm.errors.descrizione}
                infoText={rettificaForm.errors.descrizione && rettificaForm.errors.descrizione}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: '20px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  outline
                  size="sm"
                  color="primary"
                  onClick={() => {
                    setIsRettificaModalOpen(false);
                  }}
                >
                  Annulla
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    rettificaForm.submitForm();
                  }}
                >
                  Invia notifica
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </FormContext.Provider>
  );
};
