import { UncontrolledTooltip } from 'design-react-kit';

export interface TruncateProps {
  maxLength?: number;
  text?: string;
  idTooltip?: string;
}

const TruncateText = (props: TruncateProps) => {
  const truncate = (str: string) => {
    if (props.text) {
      if (props.maxLength) {
        return str.length > props.maxLength! ? str.substring(0, props.maxLength) + '...' : str;
      } else {
        return str.length > 30 ? str.substring(0, 30) + '...' : str;
      }
    } else {
      return '';
    }
  };

  return (
    <div>
      <span id={'tooltip_' + props.idTooltip}>{truncate(props.text!)}</span>
      <UncontrolledTooltip placement="top" target={'tooltip_' + props.idTooltip}>
        {props.text}
      </UncontrolledTooltip>
    </div>
  );
};

export default TruncateText;
