import { useEffect, useState } from 'react';
import { Button, Col, Icon, Input, Row, Table, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import CustomSelect, { CustomSelectItem } from '../common/custom-select/CustomSelect';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  AssegnazioneBandoPoRequestDto,
  OptionDtoTipoPosizioneOrganizzativaValueEnum,
  PosizioneOrganizzativaDto,
} from '../../services/ms-anagrafica-unica';

interface PosizioniOrganizzativeFormProps {
  po: PosizioneOrganizzativaDto;
  durataIncaricoOptions: CustomSelectItem[];
  tipologieBandoOptions: CustomSelectItem[];
  btnConferma: Function;
  btnAnnulla: Function;
}

function PosizioniOrganizzativeForm(props: PosizioniOrganizzativeFormProps) {
  const initialValues: AssegnazioneBandoPoRequestDto = {
    requisitiOggettivi: undefined,
    requisitiSoggettivi: undefined,
    durataIncarico: undefined,
    sedi: undefined,
    tipo: undefined,
    idPosizioneOrganizzativa: undefined,
    progetto: undefined,
  };

  const { t } = useTranslation();
  const [denominazioniSede, setDenominazioniSede] = useState<string[]>([]);
  const [nuovaDenominazione, setNuovaDenominazione] = useState('');
  const [isAltroSelezionato, setIsAltroSelezionato] = useState(false);
  const [durataIncarico, setDurataIncarico] = useState('');

  useEffect(() => {
    addForm.setFieldValue('idPosizioneOrganizzativa', props.po.codiceUnivoco);
    addForm.setFieldValue('denominazione', props.po.denominazione);
  }, []);

  var objSchemaPO = {
    requisitiOggettivi: z
      .string()
      .min(1, { message: 'Requisiti oggettivi è un campo obbligatorio' }),
    requisitiSoggettivi: z
      .string()
      .min(1, { message: 'Requisiti soggettivi è un campo obbligatorio' }),
    durataIncarico: z
      .number({
        required_error: 'Durata incarico è un campo obbligatorio',
        invalid_type_error: 'Inserire un numero',
      })
      .min(1, 'Inserire un numero maggiore di 0')
      .max(12000),
    sedi: z.array(z.string()).refine((data) => data.length > 0, {
      message: 'Almeno una sede è obbligatoria',
    }),
    tipo: z.string({ required_error: 'La tipologia del bando è un campo obbligatorio' }),
    idPosizioneOrganizzativa: z.string(),
  };

  const schemaPosizioneOrganizzativa = z.object(
    props.po.tipo === OptionDtoTipoPosizioneOrganizzativaValueEnum.PoEquiparata
      ? {
          ...objSchemaPO,
          progetto: z.string({ required_error: 'Campo obbligatorio' }),
        }
      : { ...objSchemaPO }
  );

  const addForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schemaPosizioneOrganizzativa),
    onSubmit: (values) => {
      props.btnConferma(values);
    },
  });

  const getFieldError = (
    form: FormikProps<AssegnazioneBandoPoRequestDto>,
    fieldName: string
  ): string => {
    if (form.getFieldMeta(fieldName as keyof AssegnazioneBandoPoRequestDto).touched) {
      return form.errors[fieldName as keyof AssegnazioneBandoPoRequestDto] || '';
    } else {
      return '';
    }
  };

  const handleAggiungiDenominazione = () => {
    if (nuovaDenominazione) {
      setDenominazioniSede((prevDenominazioni) => {
        const updatedDenominazioni = [...prevDenominazioni, nuovaDenominazione];
        addForm.setFieldValue('sedi', updatedDenominazioni, false);
        setNuovaDenominazione('');
        return updatedDenominazioni;
      });
    }
  };

  const handleRimuoviDenominazione = (denominazione: string) => {
    const nuoveDenominazioni = denominazioniSede.filter((d) => d !== denominazione);
    setDenominazioniSede(nuoveDenominazioni);
    addForm.setFieldValue('sedi', nuoveDenominazioni);
  };

  const handleDurataIncarico = (value: string) => {
    setDurataIncarico(value);

    if (value != '9999') {
      setIsAltroSelezionato(false);
      addForm.setFieldValue('durataIncarico', value);
    } else {
      addForm.setFieldValue('durataIncarico', '');
      setIsAltroSelezionato(true);
    }
  };

  const handleDurataIncaricoAltro = (value: any) => {
    if (!Number.isNaN(Number(value))) {
      addForm.setFieldValue('durataIncarico', Number(value));
    } else {
      addForm.setFieldValue('durataIncarico', value);
    }
  };

  return (
    props.po && (
      <div className="form-custom form-bg form-modal">
        {/*  <PageHeader title={t(`Inserisci dati per ${props.po.denominazione || ''}`) || ''} /> */}
        <h5 className="font-bold mb-3">
          {t(`Inserisci dati per ${props.po.denominazione || ''}`) || ''}{' '}
        </h5>
        <Row>
          <Col md={6} className="d-flex flex-column">
            <label className="mb-0">Tipo EQ</label>
            <Input type="text" value={props.po.tipoLabel || 'MANCANTE'} readOnly />
          </Col>
          <Col md={3} className="d-flex flex-column">
            <label className="mb-0 mb-0">Codice Struttura</label>
            <Input type="text" value={props.po.strutturaOrganizzativa || ''} readOnly />
          </Col>
          <Col md={3} className="d-flex flex-column">
            <label className="mb-0 mb-0">Codice Univoco</label>
            <Input type="text" value={props.po.codiceUnivoco || ''} readOnly />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <CustomSelect
              label="Tipologia Bando EQ"
              options={props.tipologieBandoOptions}
              value={addForm.values.tipo}
              placeholder="Seleziona tipologia EQ"
              invalid={!!getFieldError(addForm, 'tipo')}
              infoText={t(getFieldError(addForm, 'tipo')) || ''}
              onChange={(e) => addForm.setFieldValue('tipo', e)}
              name="tipo"
              wrapperClass="required"
            />
          </Col>

          <Col md={6}>
            <CustomSelect
              label="Durata incarico (mesi)"
              options={props.durataIncaricoOptions}
              name="durataIncarico"
              placeholder="Seleziona la durata dell'incarico"
              value={durataIncarico}
              onChange={(e) => handleDurataIncarico(e)}
              invalid={!!getFieldError(addForm, 'durataIncarico')}
              infoText={t(getFieldError(addForm, 'durataIncarico')) || ''}
              wrapperClass="required"
            />
          </Col>
        </Row>

        {isAltroSelezionato && (
          <Row className="mt-1">
            <Col md={12}>
              <Input
                type="text"
                label="Durata incarico (mesi)"
                name="durataIncaricoAltro"
                placeholder="Inserisci la durata dell'incarico"
                value={addForm.values.durataIncarico}
                onChange={(e) => handleDurataIncaricoAltro(e.target.value)}
                invalid={!!getFieldError(addForm, 'durataIncarico')}
                infoText={t(getFieldError(addForm, 'durataIncarico')) || ''}
                wrapperClassName="required"
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={12}>
            <TextArea
              label="Requisiti oggettivi"
              placeholder="Inserisci i requisiti oggettivi"
              id="input-requisiti-oggettivi"
              wrapperClassName="required"
              name="requisitiOggettivi"
              value={addForm.values.requisitiOggettivi}
              onChange={addForm.handleChange}
              invalid={!!getFieldError(addForm, 'requisitiOggettivi')}
              infoText={t(getFieldError(addForm, 'requisitiOggettivi')) || ''}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <TextArea
              label="Requisiti soggettivi"
              placeholder="Inserisci i requisiti soggettivi"
              wrapperClassName="required"
              name="requisitiSoggettivi"
              id="input-requisiti-soggettivi"
              value={addForm.values.requisitiSoggettivi}
              onChange={addForm.handleChange}
              invalid={!!getFieldError(addForm, 'requisitiSoggettivi')}
              infoText={t(getFieldError(addForm, 'requisitiSoggettivi')) || ''}
            />
          </Col>
        </Row>

        {props.po.tipo === OptionDtoTipoPosizioneOrganizzativaValueEnum.PoEquiparata && (
          <Row>
            <Col md={12}>
              <Input
                type="text"
                label="Progetto"
                placeholder="Progetto"
                id="progetto"
                wrapperClassName="required"
                name="progetto"
                value={addForm.values.progetto}
                onChange={addForm.handleChange}
                invalid={!!getFieldError(addForm, 'progetto')}
                infoText={t(getFieldError(addForm, 'progetto')) || ''}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Input
            label="Aggiungi Sede"
            type="text"
            id="input-sedi"
            name="sedi"
            wrapperClassName="mb-0 col-9 required"
            placeholder="Inserisci una sede"
            invalid={!!getFieldError(addForm, 'sedi')}
            infoText={t(getFieldError(addForm, 'sedi')) || ''}
            value={nuovaDenominazione || ''}
            onChange={(e) => setNuovaDenominazione(e.target.value)}
          />

          <Button
            color="primary"
            size="sm"
            className="col-md-3"
            onClick={handleAggiungiDenominazione}
          >
            Aggiungi sede
          </Button>
        </Row>

        {denominazioniSede.length > 0 && (
          <div className="mt-3">
            <Table striped className="table table-bordered">
              <thead>
                <tr>
                  <th>Denominazione Sede</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {denominazioniSede.map((denominazione, index) => (
                  <tr key={index}>
                    <td>{denominazione}</td>
                    <td>
                      <Icon
                        icon="it-delete"
                        onClick={() => handleRimuoviDenominazione(denominazione)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        <div className="modal-footer" style={{ padding: '24px 0px 0px 0px' }}>
          <Button color="secondary" onClick={() => props.btnAnnulla()}>
            Annulla
          </Button>
          <Button className="btn btn-primary" onClick={addForm.submitForm}>
            Inserisci a bando
          </Button>
        </div>
      </div>
    )
  );
}

export default PosizioniOrganizzativeForm;
