import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
// import { CustomSelectItem } from '../custom-select/CustomSelect';
import { Button, Callout, CalloutTitle, Icon, Input } from 'design-react-kit';
import { t } from 'i18next';
import {
  CurriculumVitaeRequestDtoSezioneEnum,
  InformazioniPersonaliRequestDto,
} from '../../../services/ms-anagrafica-unica';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  creaNuovoCv,
  getCvDetail /*, getGestioneCvOpts*/,
} from '../../../store/curriculumVitaeSlice';
import { FormikProps, useFormik } from 'formik';
import { detailsDipendente } from '../../../store/rubricaSlice';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { GestioneCvPageRef } from '../GestioneCvForm';

export interface InfoPersonaliProps {
  disabled: boolean;
  continuaAction: Function;
}

const InformazioniPersonaliTab = forwardRef(
  (props: InfoPersonaliProps, ref: Ref<GestioneCvPageRef>) => {
    const dispatch = useAppDispatch();
    const { detailResult, detailStatus } = useAppSelector((state) => state.curriculumVitae);
    const { dipendenteResult, dipendenteStatus } = useAppSelector((state) => state.rubrica);
    const { userInfoResult } = useAppSelector((state) => state.user);
    const [alertDatiMancanti, setAlertDatiMancanti] = useState<boolean>(false);

    useEffect(() => {
      dispatch(detailsDipendente(Number(userInfoResult?.entityId!)))
        .unwrap()
        .then((resp) => {
          if (!!resp) {
            resp.cvPresente && dispatch(getCvDetail(userInfoResult?.entityId!));
          }
        });
    }, [userInfoResult]);

    useEffect(() => {
      const numeroEsiste =
        dipendenteResult?.assegnazioneNumeroTelefono?.numeroTelefono ||
        detailResult?.dipendenteRegionale?.assegnazioneNumeroTelefono;
      const emailEsiste = dipendenteResult?.email || detailResult?.dipendenteRegionale?.email;

      if (!numeroEsiste || !emailEsiste) {
        setAlertDatiMancanti(true);
      } else {
        setAlertDatiMancanti(false);
      }
    }, [detailResult, dipendenteResult]);

    const form: FormikProps<InformazioniPersonaliRequestDto> = useFormik({
      initialValues: {},
      onSubmit: () => {
        dispatch(
          creaNuovoCv({
            sezione: CurriculumVitaeRequestDtoSezioneEnum.InformazioniPersonali,
          })
        )
          .unwrap()
          .then((resp) => resp != null && props.continuaAction());
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm() {
          form.submitForm();
        },
      }),
      []
    );

    return (
      <div className="informazioni-personali">
        <Callout className="au-callout au-callout-info col-md-12 mb-5">
          <CalloutTitle>
            <Icon icon="it-info-circle" color="primary" size="sm" /> Nota
          </CalloutTitle>
          Si invitano i dipendenti a porre particolare attenzione alla compilazione dei CV omettendo
          l'inserimento di dati eccedenti rispetto a quelli necessari per adempiere agli obblighi di
          legge quali, ad esempio, l’indirizzo di residenza, il numero di cellulare e gli indirizzi
          di posta elettronica privati oltre a categorie particolari di dati.
        </Callout>

        {alertDatiMancanti && (
          <Callout className="au-callout au-callout-warning col-md-12 mb-5">
            <CalloutTitle>
              <Icon icon="it-help-circle" color="warning" size="sm" /> Attenzione
            </CalloutTitle>
            Il numero di telefono e/o la mail regionale non risultano presenti nella base dati ARPAL
            Puglia. Se in possesso comunicarli all’indirizzo st.anagrafica@arpal.regione.puglia.it
          </Callout>
        )}
        <div className="font-italic font-weight-500 mb-3">{t('campiObbligatoriAsterisco')}</div>
        {dipendenteStatus === STATUS_FULLFILLED || detailStatus === STATUS_FULLFILLED ? (
          <div className="form-custom">
            <div className="form-row">
              <Input
                type="text"
                label="Cognome/Nome"
                id="cognomeNome"
                placeholder="Cognome e nome"
                wrapperClassName="col-md-12 mt-5 required"
                name="cognomeNome"
                value={dipendenteResult?.nomeCompleto}
                disabled={true}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);

export default InformazioniPersonaliTab;
