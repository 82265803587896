import arpalLogoWhite from 'assets/images/Arpal_puglia_white.png';
import pugliafesr from 'assets/images/puglia-fesr.png';
import { Col, Container, Row } from 'design-react-kit';
import './Footer.scss';

function Footer() {
  return (
    <footer className="it-footer">
      <div className="it-footer-main">
        <Container>
          <section className="py-4 footer-block">
            {' '}
            {
              <Row className="it-brand-wrapper d-flex justify-content-between">
                <Col className="item-section" lg="auto" md={6}>
                  <h4 className="footer-title">
                    <strong>Contatti e indirizzi</strong>
                  </h4>

                  <p>Sede legale: Via Niceforo, 1-3 70124 - Bari</p>
                  <p>C.F. 93497660725</p>
                  <p>Cod.Univoco arpal-UF2VRV (fatture)</p>

                  <p>
                    <a
                      href="mailto:arpalpuglia@pec.rupar.puglia.it"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PEC: arpalpuglia@pec.rupar.puglia.it
                    </a>
                  </p>
                  <p>
                    <a
                      href="mailto:protocollo@arpal.regione.puglia.it"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email: protocollo@arpal.regione.puglia.it
                    </a>
                  </p>
                </Col>

                <Col className="item-section" lg="auto" md={6}>
                  <h4 className="footer-title">
                    <strong>URP</strong>
                  </h4>
                  <p>
                    <a
                      href="https://arpal.regione.puglia.it/capgrptv/zf/index.php/servizi-aggiuntivi/index/index/idtesto/138"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      Ufficio Relazioni col pubblico
                    </a>
                  </p>
                </Col>

                <Col className="item-section" lg="auto" md={6}>
                  <h4 className="footer-title">
                    <strong>Progetti Europei</strong>
                  </h4>
                  <p>
                    <a
                      href="https://arpal.regione.puglia.it/capgrptv/zf/index.php/servizi-aggiuntivi/index/index/idtesto/50"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Piano di Rafforzamento CPI
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://arpal.regione.puglia.it/capgrptv/zf/index.php/servizi-aggiuntivi/index/index/idtesto/158"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Piano di Potenziamento CPI
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://arpal.regione.puglia.it/capgrptv/zf/index.php/servizi-aggiuntivi/index/index/idtesto/144"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Punti di facilitazione digitale
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://arpal.regione.puglia.it/capgrptv/zf/index.php/servizi-aggiuntivi/index/index/idtesto/159"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Il camper del Lavoro
                    </a>
                  </p>
                </Col>

                <Col className="item-section" lg="auto" md={6}>
                  <div className="d-flex flex-column">
                    <div>
                      <h4 className="footer-title">
                        <strong>Privacy</strong>
                      </h4>

                      <p>
                        Ufficio Privacy{' '}
                        <a
                          href="mailto:privacy@arpal.regione.puglia.it"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacy@arpal.regione.puglia.it
                        </a>
                      </p>
                      <p>
                        {' '}
                        <a
                          href="https://arpal.regione.puglia.it/zf/index.php/privacy/index/cookie"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          Informativa Cookie
                        </a>
                      </p>
                      <p>
                        {' '}
                        <a
                          href="http://arpal.regione.puglia.it/capgrptv/zf/index.php/trasparenza/index/index/categoria/159"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Accesso agli atti
                        </a>
                      </p>
                      <p>
                        {' '}
                        <a
                          href="https://arpal.regione.puglia.it/capgrptv/zf/index.php/trasparenza/index/index/categoria/149"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Whistleblowing
                        </a>
                      </p>
                    </div>

                    <div className="mt-5">
                      <h4 className="footer-title">
                        <strong>Accessibilità</strong>
                      </h4>

                      <p>
                        {' '}
                        <a
                          href="https://form.agid.gov.it/view/a2ab7680-ea85-11ee-b8af-55211d195233"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Obiettivi
                        </a>
                      </p>
                      <p>
                        {' '}
                        <a
                          href="https://form.agid.gov.it/view/ce107160-75b1-11ef-b5a3-539ec2ca60a0"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dichiarazione
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </section>
        </Container>
      </div>

      <div className="it-footer-small-prints clearfix it-footer-bottom">
        <Container>
          <div className="float-end">
            <div className="py-2">
              <a
                href="https://arpal.regione.puglia.it/hh/index.php"
                title="Arpal Puglia"
                style={{ textDecoration: 'none' }}
              >
                {' '}
                © Arpal Puglia
              </a>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
