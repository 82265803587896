import './style/App.scss';
import ElencoStruttureOrganizzative from './pages/strutture-organizzative/ElencoStruttureOrganizzative';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ScrivaniaVirtuale from './pages/scrivania-virtuale/ScrivaniaVirtuale';
import Login from './pages/authentication/Login';
import Layout from './components/layout/Layout';
import NuovaStrutturaOrganizzativa from './pages/strutture-organizzative/NuovaStrutturaOrganizzativa';
import ElencoPosizioniOrganizzative from './pages/posizioni-organizzative/ElencoPosizioniOrganizzative';
import NuovaPosizioneOrganizzativa from './pages/posizioni-organizzative/NuovaPosizioneOrganizzativa';
import {
  HREF_ABILITAZIONI_ELENCO_RICHIESTE,
  HREF_ABILITAZIONI_NUOVA_RICHIESTA,
  HREF_ABILITAZIONI_APPROVAZIONE_ATTIVAZIONE,
  HREF_ABILITAZIONI_MODIFICA_RICHIESTA,
  HREF_ABILITAZIONI_DETTAGLIO_RICHIESTA,
  HREF_ASSEGNAZIONE_CODICE_BILANCIO,
  HREF_ASSEGNAZIONE_CODICE_CIFRA,
  HREF_ASSEGNAZIONE_CODICE_IPA,
  HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA,
  HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA,
  HREF_ASSEGNAZIONE_UTENZA_CIFRA,
  HREF_CESSAZIONE_DIPENDENTE,
  HREF_COM_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO,
  HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA,
  HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA,
  HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA,
  HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA,
  HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA,
  HREF_DOWNLOAD_NULLA_OSTA,
  HREF_ELENCO_CANDIDATI,
  HREF_ELENCO_DIPENDENTI_REG,
  HREF_ELENCO_INCARICHI_DIRIGENZIALI,
  HREF_ELENCO_POSIZIONI_ORGANIZZATIVE,
  HREF_ELENCO_RUOLI,
  HREF_ELENCO_STRUTTURE_ORGANIZZATIVE,
  HREF_FEEDBACK_NOTIFICA,
  HREF_GESTIONE_NOTIFICHE,
  HREF_GESTIONE_RUOLO_FUNZIONALITA,
  HREF_GESTIONE_RUOLO_UTENTI,
  HREF_GEST_ACCETTAZIONE_CONTRATTO,
  HREF_GEST_CESSAZIONE_CONTRATTO,
  HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO,
  HREF_INSERIMENTO_CANDIDATO,
  HREF_LOGIN,
  HREF_MODIFICA_CANDIDATO,
  HREF_MODIFICA_CONFIGURAZIONE_NOTIFICA,
  HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA,
  HREF_MODIFICA_RUOLO,
  HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA,
  HREF_NUOVA_CONFIGURAZIONE_NOTIFICA,
  HREF_NUOVA_POSIZIONE_ORGANIZZATIVA,
  HREF_NUOVA_PROPOSTA_INCARICO,
  HREF_NUOVA_STRUTTURA_ORGANIZZATIVA,
  HREF_NUOVO_RUOLO,
  HREF_ORGANIGRAMMA,
  HREF_SCRIVANIA_VIRTUALE,
  HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO,
  HREF_UPLOAD_NOMINA,
  HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO,
  HREF_VERIFICA_DATI,
  HREF_VISUALIZZA_CESSAZIONE_DIPENDENTE,
  HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA,
  HREF_VALUTA_PROPOSTA_INCARICO,
  HREF_MODIFICA_PROPOSTA_INCARICO,
  HREF_CONF_AREA_DOCUMENTI,
  HREF_MOD_DOCUMENTI_CANDIDATO,
  HREF_INSERIMENTO_PARERE_GIUNTA,
  HREF_ASSEGNA_INCARICO,
  HREF_MODIFICA_INCARICO,
  HREF_VISUALIZZA_INCARICO,
  HREF_LOGOUT,
  HREF_NOT_AUTHORIZED,
  HREF_RUBRICA_ELENCO_DIPENDENTI,
  HREF_RUBRICA_INSERISCI_TEL_CRED,
  HREF_RUBRICA_INSERISCI_EMAIL_DIPENDENTE,
  HREF_RUBRICA_MODIFICA_UTENZE,
  HREF_RUBRICA_VISUALIZZA_UTENZE,
  HREF_CONSULENTE_ESTERNO_ELENCO,
  HREF_CONSULENTE_ESTERNO_MODIFICA,
  HREF_CONSULENTE_ESTERNO_DETTAGLIO,
  HREF_CONSULENTE_ESTERNO_INSERISCI,
  HREF_PRIMO_ACCESSO_CITTADINO,
  HREF_SH_AREA_PERSONALE,
  HREF_SH_MODIFICA_INFO,
  HREF_SH_DOCUMENTI,
  HREF_SH_PRATICHE,
  HREF_SH_BANDI_CONCORSI,
  HREF_ELENCO_CONVENZIONI,
  HREF_VISUALIZZA_CONVENZIONE,
  HREF_NUOVA_CONVENZIONE,
  HREF_MODIFICA_CONVENZIONE,
  HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA,
  HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA,
  HREF_VISUALIZZA_CANDIDATO,
  HREF_ELENCO_BANDI,
  HREF_NUOVO_BANDO,
  HREF_ELENCO_RICHIESTE_NULLA_OSTA,
  HREF_CANDIDATURA_ELENCO_BANDI,
  HREF_CANDIDATURA_VISUALIZZA_BANDO,
  HREF_VISUALIZZA_CV,
  HREF_CANDIDATURA_DETTAGLIO_NULLA_OSTA,
  HREF_ELENCO_CANDIDATURE,
  HREF_CANDIDATURA_ELENCO_CANDIDATURE,
  HREF_ELENCO_ASSEGNAZIONI,
  HREF_CANDIDATURA_NULLA_OSTA,
  HREF_MODIFICA_BANDO,
  HREF_GESTIONE_CV,
  HREF_UPLOAD_GESTIONE_MASSIVA,
  HREF_BOZZA_STRUTTURA_ORGANIZZATIVA,
  HREF_INSERIMENTO_DATI_ASSUNZIONE,
  HREF_CONVOCAZIONE_SINGOLA,
  HREF_INSERIMENTO_CONCORSO,
  HREF_CONVALIDA_DATI,
  HREF_VERIFICA_INTEGRAZIONE_DATI,
  HREF_VALIDAZIONE_DATI_DOCUMENTI,
  HREF_CONFERIMENTO_DOCUMENTI,
  HREF_GESTIONE_CONTRATTUALIZZAZIONE,
  HREF_GESTIONE_RINUNCIA,
  HREF_INSERIMENTO_MASSIVO_CANDIDATI,
  HREF_TROVA_CANDIDATO,
} from './components/layout/sidemenu/sidemenuConstants';
import ElencoRuoli from './pages/gestione-ruoli-funzionalita/ElencoRuoli';
import ModificaStruttureOrganizzative from './pages/strutture-organizzative/ModificaStruttureOrganizzative';
import AssegnazioneCodiceCifra from './pages/strutture-organizzative/AssegnazioneCodiceCifra';
import AssegnazioneCodiceIpa from './pages/strutture-organizzative/AssegnazioneCodiceIpa';
import AssegnazioneCodiceBilancio from './pages/strutture-organizzative/AssegnazioneCodiceBilancio';
import DisattivazioneStrutturaOrganizzativa from './pages/strutture-organizzative/DisattivazioneStrutturaOrganizzativa';
import AssegnazioneUtenzaCifra from './pages/strutture-organizzative/AssegnazioneUtenzaCifra';
import UploadDeterminaIstituzioneAvviso from './pages/posizioni-organizzative/UploadDeterminaIstituzioneAvviso';
import DownloadNullaOsta from './pages/posizioni-organizzative/DownloadNullaOsta';
import ModificaPosizioneOrganizzativa from './pages/posizioni-organizzative/ModificaPosizioneOrganizzativa';
import VisualizzaPosizioneOrganizzativa from './pages/posizioni-organizzative/VisualizzaPosizioneOrganizzativa';
import DisattivaPostazioneOrganizzativa from './pages/posizioni-organizzative/DisattivaPostazioneOrganizzativa';
import AssegnazioneStrutturaOrganizzativa from './pages/strutture-organizzative/AssegnazioneStrutturaOrganizzativa';
import DisassociazioneStrutturaOrganizzativa from './pages/strutture-organizzative/DisassociazioneStrutturaOrganizzativa';
import UploadNomina from './pages/posizioni-organizzative/UploadNomina';
import AssociazionePosizioneOrganizzativa from './pages/posizioni-organizzative/AssociazionePosizioneOrganizzativa';
import DisassociazionePosizioneOrganizzativa from './pages/posizioni-organizzative/DisassociazionePosizioneOrganizzativa';
import Organigramma from './pages/organigramma/Organigramma';
import NuovoRuolo from './pages/gestione-ruoli-funzionalita/NuovoRuolo';
import ModificaRuolo from './pages/gestione-ruoli-funzionalita/ModificaRuolo';
import AssociazioneRuoliFunzionalita from './pages/gestione-ruoli-funzionalita/AssociazioneRuoliFunzionalita';
import AssociazioneRuoliUtenti from './pages/gestione-ruoli-funzionalita/AssociazioneRuoliUtenti';
import DettaglioStrutturaOrganizzativa from './pages/organigramma/DettaglioStrutturaOrganizzativa';
import GestioneNotifiche from './pages/gestione-ruoli-funzionalita/GestioneNotifiche';
import NuovaConfigurazioneNotifica from './pages/gestione-ruoli-funzionalita/NuovaConfigurazioneNotifica';
import ElencoCandidati from './pages/gestione-candidati/ElencoCandidati';
import InserimentoCandidato from './pages/gestione-candidati/InserimentoCandidato';
import ValidazioneIntegrazioneDatiCandidato from './pages/gestione-candidati/ValidazioneIntegrazioneDatiCandidato';
import VerificaDatiDocumentiCandidato from './pages/gestione-candidati/VerificaDatiDocumentiCandidato';
import ModificaConfigurazioneNotifica from './pages/gestione-ruoli-funzionalita/ModificaConfigurazioneNotifica';
import FeedbackNotifica from './pages/scrivania-virtuale/FeedbackNotifica';
import ComunicazioneConvocazioneSottoscrizioneContratto from './pages/gestione-candidati/ComunicazioneConvocazioneSottoscrizioneContratto';
import GestioneConvocazioneSottoscrizioneContratto from './pages/gestione-candidati/GestioneConvocazioneSottoscrizioneContratto';
import GestioneCessazioneContratto from './pages/gestione-candidati/GestioneCessazioneContratto';
import ElencoDipendentiRegionali from './pages/gestione-dipendenti-regionali/ElencoDipendentiRegionali';
import VisualizzaCessazioneDipendente from './pages/gestione-dipendenti-regionali/VisualizzaCessazioneDipendente';
import ElencoIncarichiDirigenziali from './pages/gestione-incarichi-dirigenziali/ElencoIncarichiDirigenziali';
import CessazioneDipendente from './pages/gestione-dipendenti-regionali/CessazioneDipendente';
import PageNotFound from './pages/404/pageNotFound';
import NuovaPropostaIncarico from './pages/gestione-incarichi-dirigenziali/NuovaPropostaIncarico';
import { useAppSelector } from './hooks';
import ProtectedRoute from './components/auth/ProtectedRoute';
import ModificaPropostaIncarico from './pages/gestione-incarichi-dirigenziali/ModificaPropostaIncarico';
import ConfigurazioneAreaDocumenti from './pages/gestione-candidati/ConfigurazioneAreaDocumenti';
import ModificaAreaDocumenti from './pages/gestione-candidati/ModificaAreaDocumenti';
import ValutazioneProposta from './pages/gestione-incarichi-dirigenziali/ValutazioneProposta';
import InserimentoParere from './pages/gestione-incarichi-dirigenziali/InserimentoParere';
import AssegnaIncarico from './pages/gestione-incarichi-dirigenziali/AssegnaIncarico';
import ModificaAssegnazioneIncarico from './pages/gestione-incarichi-dirigenziali/ModificaAssegnazione';
import VisualizzaIncarico from './pages/gestione-incarichi-dirigenziali/VisualizzaIncarico';
import Logout from './pages/authentication/Logout';
import RicercaConvenzioni from './pages/convenzioni/RicercaConvenzioni';
import NuovaConvenzione from './pages/convenzioni/NuovaConvenzione';
import VisualizzaConvenzione from './pages/convenzioni/VisualizzaConvenzione';
import ModificaConvenzione from './pages/convenzioni/ModificaConvenzione';
import Rubrica from './pages/rubrica/Rubrica';
import NotAuthorized from './pages/401/notAuthorized';
import InserisciTelefonoCredenziali from './pages/rubrica/InserisciTelefonoCredenziali';
import InserisciEmailDipendente from './pages/rubrica/InserisciEmailDipendente';
import ModificaUtenze from './pages/rubrica/ModificaUtenze';
import VisualizzaUtenze from './pages/rubrica/VisualizzaUtenze';
import ElencoConsulentiEsterni from './pages/gestione-consulenti-esterni/ElencoConsulentiEsterni';
import ModificaConsulenteEsterno from './pages/gestione-consulenti-esterni/ModificaConsulenteEsterno';
import DettaglioConsulenteEsterno from './pages/gestione-consulenti-esterni/DettaglioConsulenteEsterno';
import InserisciConsulenteEsterno from './pages/gestione-consulenti-esterni/InserisciConsulenteEsterno';
import ElencoRichieste from './pages/gestione-abilitazioni/ElencoRichieste';
import NuovaRichiestaAbilitazione from './pages/gestione-abilitazioni/NuovaRichiestaAbilitazione';
import ApprovaRichiestaAttivazione from './pages/gestione-abilitazioni/ApprovaRichiestaAttivazione';
import ModificaRichiestaAbilitazione from './pages/gestione-abilitazioni/ModificaRichiestaAbilitazione';
import DettaglioRichiestaAbilitazione from './pages/gestione-abilitazioni/DettaglioRichiestaAbilitazione';
import PrimoAccessoCittadino from './pages/authentication/PrimoAccessoCittadino';
import AreaPersonale from './pages/stackholder-esterni/AreaPersonale';
import ModificaInformazioni from './pages/stackholder-esterni/ModificaInformazioni';
import DocumentiCittadino from './pages/stackholder-esterni/DocumentiCittadino';
import PraticheCittadino from './pages/stackholder-esterni/PraticheCittadino';
import BandiConcorsi from './pages/stackholder-esterni/BandiConcorsi';
import LayoutCourtesy from './components/layout/Layout_Courtesy';
import NotificaComponent from './components/notification/NotificaComponent';
import PageLoader from './components/common/page-loader/PageLoader';
import RegistroImprese from './pages/registro-imprese/RegistroImprese';
import RegistroImpreseRicercaMultipla from './pages/registro-imprese/RegistroImpreseRicercaMultipla';
import React, { useEffect, useState } from 'react';
import VisualizzaCandidato from './pages/gestione-candidati/VisualizzaCandidato';
import ElencoBandi from './pages/posizioni-organizzative/candidatura/ElencoBandi';
import NuovoBando from './pages/posizioni-organizzative/candidatura/NuovoBando';
import NullaOsta from './pages/posizioni-organizzative/candidatura/NullaOsta';
import ElencoRichiestaNullaOsta from './pages/posizioni-organizzative/candidatura/ElencoRichiesteNullaOsta';
import ElencoBandiEQ from './pages/posizioni-organizzative/candidatura/ElencoBandiEQ';
import VisualizzaBandoEQ from './pages/posizioni-organizzative/candidatura/VisualizzaBando';
import GestioneCv from './pages/gestione-cv/GestioneCv';
import ElencoCandidature from './pages/posizioni-organizzative/candidatura/ElencoCandidature';
import ElencoAssegnazioni from './pages/posizioni-organizzative/candidatura/ElencoAssegnazioni';
import ModificaBando from './pages/posizioni-organizzative/candidatura/ModificaBando';
import { Icon } from 'design-react-kit';
import UploadGestioneMassiva from './pages/posizioni-organizzative/candidatura/UploadGestioneMassive';
import InserimentoDatiAssunzione from 'pages/gestione-candidati/InserimentoDatiAssunzione';
import InvioConvocazioneSingola from 'pages/gestione-candidati/InvioConvocazioneSingola';
import CreaCandidato from 'pages/gestione-candidati/CreaCandidato';
import ModificaCandidato from 'pages/gestione-candidati/2.9/modifica-candidato/ModificaCandidato';
import InserimentoMassivoCandidati from 'pages/gestione-candidati/InserimentoMassivoCandidati';
import CreaConcorso from 'pages/gestione-candidati/CreaConcorso';
import ConvalidaDati from 'pages/convalida-dati/ConvalidaDati';
import VerificatIntegrazioneDati from 'pages/verifica-integrazione/VerificaIntegrazioneDati';
import ValidazioneDatiDocumenti from 'pages/validazione-dati-documenti/ValidazioneDatiDocumenti';
import ConferimentoDocumenti from 'pages/conferimento-documenti/ConferimentoDocumenti';
import GestioneContrattualizzazione from 'pages/gestione-contrattualizzazione/GestioneContrattualizzazione';
import GestioneRinuncia from 'pages/rinuncia/Rinuncia';
import VerificaAltriDati from 'pages/verifica-dati/VerificaAltriDati';

function App() {
  const { userRoleActive } = useAppSelector((state) => state.user);
  const [mostraTornaSu, setMostraTornaSu] = useState(false);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (window.scrollY > 500) {
        setMostraTornaSu(true);
      } else {
        setMostraTornaSu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path={HREF_LOGIN} element={<Login />} />
        <Route element={<LayoutCourtesy />}>
          <Route path={HREF_PRIMO_ACCESSO_CITTADINO} element={<PrimoAccessoCittadino />} />
          <Route path={HREF_NOT_AUTHORIZED} element={<NotAuthorized />} />
          {/* pagina non trovata */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path={HREF_LOGOUT}
            element={
              <ProtectedRoute perCittadino perCandidato perCessato toMatch={[]}>
                <Logout />
              </ProtectedRoute>
            }
          />
          {/* scrivania virtuale */}
          <Route
            path={HREF_SCRIVANIA_VIRTUALE}
            element={
              <ProtectedRoute perCittadino perCandidato perCessato toMatch={['NOTIFICHE_ELENCO']}>
                <ScrivaniaVirtuale operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_FEEDBACK_NOTIFICA}/:id`}
            element={
              <ProtectedRoute toMatch={['NOTIFICHE_INSERT_FEEDBACK']}>
                <FeedbackNotifica />
              </ProtectedRoute>
            }
          />

          {/* organigramma */}
          <Route
            path={HREF_ORGANIGRAMMA}
            element={
              <ProtectedRoute toMatch={['ORGANIGRAMMA_ELENCO']}>
                <Organigramma operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA}/:idStrutturaOrganizzativa`}
            element={
              <ProtectedRoute toMatch={['ORGANIGRAMMA_DETTAGLIO', 'SO_DETTAGLIO']}>
                <DettaglioStrutturaOrganizzativa />
              </ProtectedRoute>
            }
          />

          {/* strutture organizzative */}
          <Route
            path={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}
            element={
              <ProtectedRoute toMatch={['SO_ELENCO']}>
                <ElencoStruttureOrganizzative operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'SO_MOD_DIPA',
                  'SO_MOD_SEZ',
                  'SO_MOD_SERV',
                  'SO_DISA_SERV',
                  'SO_DISA_DIPA',
                  'SO_DISA_SEZ',
                  'SO_ASS_CODCIFRA',
                  'SO_ASS_CODIPA',
                  'SO_ASS_CODBILA',
                  'SO_INOLTRA',
                  'SO_ASSOCIA_SERV',
                  'SO_ASSOCIA_DIP',
                  'SO_ASSOCIA_SEZ',
                  'SO_CENSISCI',
                  'SO_DISASSOCIAZIONE_SERV',
                  'SO_DISASSOCIAZIONE_DIPA',
                  'SO_DISASSOCIAZIONE_SEZ',
                ]}
              >
                <ModificaStruttureOrganizzative operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNAZIONE_CODICE_CIFRA}/:id`}
            element={
              <ProtectedRoute toMatch={['SO_ASS_CODCIFRA']}>
                <AssegnazioneCodiceCifra />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNAZIONE_CODICE_IPA}/:id`}
            element={
              <ProtectedRoute toMatch={['SO_ASS_CODIPA']}>
                <AssegnazioneCodiceIpa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNAZIONE_CODICE_BILANCIO}/:id`}
            element={
              <ProtectedRoute toMatch={['SO_ASS_CODBILA']}>
                <AssegnazioneCodiceBilancio />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA}/:id`}
            element={
              <ProtectedRoute toMatch={['SO_DISA_SERV', 'SO_DISA_DIPA', 'SO_DISA_SEZ']}>
                <DisattivazioneStrutturaOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNAZIONE_UTENZA_CIFRA}/:id`}
            element={
              <ProtectedRoute
                toMatch={['SO_INSERIMENTO_CIFRA', 'SO_ELENCO_CIFRA', 'SO_DELETE_CIFRA']}
              >
                <AssegnazioneUtenzaCifra />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_NUOVA_STRUTTURA_ORGANIZZATIVA}`}
            element={
              <ProtectedRoute toMatch={['SO_INS_DIPA', 'SO_INS_SEZ', 'SO_INS_SERV']}>
                <NuovaStrutturaOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_BOZZA_STRUTTURA_ORGANIZZATIVA}/:id`}
            element={
              <ProtectedRoute toMatch={['SO_INS_DIPA', 'SO_INS_SEZ', 'SO_INS_SERV']}>
                <NuovaStrutturaOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA}/:id`}
            element={
              <ProtectedRoute toMatch={['SO_ASSOCIA_SERV', 'SO_ASSOCIA_DIP', 'SO_ASSOCIA_SEZ']}>
                <AssegnazioneStrutturaOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'SO_DISASSOCIAZIONE_SERV',
                  'SO_DISASSOCIAZIONE_DIPA',
                  'SO_DISASSOCIAZIONE_SEZ',
                ]}
              >
                <DisassociazioneStrutturaOrganizzativa />
              </ProtectedRoute>
            }
          />

          {/* posizioni organizzative */}
          <Route
            path={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}
            element={
              <ProtectedRoute toMatch={['PO_ELENCO', 'PO_ELENCO_COMPLETO']}>
                <ElencoPosizioniOrganizzative operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_NUOVA_POSIZIONE_ORGANIZZATIVA}
            element={
              <ProtectedRoute toMatch={['PO_INSERIMENTO']}>
                <NuovaPosizioneOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_UPLOAD_DETERMINA_AVVISO', 'PO_DETTAGLIO']}>
                <UploadDeterminaIstituzioneAvviso />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_DOWNLOAD_NULLA_OSTA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_DETTAGLIO']}>
                <DownloadNullaOsta />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_MODIFICA']}>
                <ModificaPosizioneOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_DETTAGLIO']}>
                <VisualizzaPosizioneOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_DISATTIVA']}>
                <DisattivaPostazioneOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_UPLOAD_NOMINA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_UPLOAD_DETERMINA_DI_NOMINA', 'PO_DETTAGLIO']}>
                <UploadNomina />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_ASSOCIA']}>
                <AssociazionePosizioneOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA}/:idCodiceIncarico`}
            element={
              <ProtectedRoute toMatch={['PO_DISASSOCIA']}>
                <DisassociazionePosizioneOrganizzativa />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_ELENCO_BANDI}
            element={
              <ProtectedRoute toMatch={['BANDO_PO_ELENCO', 'BANDO_PO_ACCESSO_COMPLETO']}>
                <ElencoBandi operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_NUOVO_BANDO}
            element={
              <ProtectedRoute toMatch={['BANDO_PO_INSERT', 'BANDO_PO_ACCESSO_COMPLETO']}>
                <NuovoBando />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_ELENCO_RICHIESTE_NULLA_OSTA}
            element={
              <ProtectedRoute toMatch={['NULLA_OSTA_ELENCO']}>
                <ElencoRichiestaNullaOsta />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ELENCO_ASSEGNAZIONI}/:idBando`}
            element={
              <ProtectedRoute
                toMatch={['BANDO_PO_ELENCO_ASSEGNAZIONI', 'BANDO_PO_ACCESSO_COMPLETO']}
              >
                <ElencoAssegnazioni operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ELENCO_CANDIDATURE}/:codiceUnivocoEQ`}
            element={
              <ProtectedRoute
                toMatch={['BANDO_PO_DETTAGLIO_ASSEGNAZIONE', 'BANDO_PO_ACCESSO_COMPLETO']}
              >
                <ElencoCandidature operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          {/* posizioni organizzative - CANDIDATURE */}
          {/* <Route path={HREF_CANDIDATURA_ELENCO_BANDI} element={<ProtectedRoute toMatch={['BANDO_PO_ELENCO_ASSEGNAZIONI']}><ElencoBandiEQ operations={userRoleActive!}/></ProtectedRoute>} /> */}
          <Route
            path={HREF_CANDIDATURA_NULLA_OSTA}
            element={
              <ProtectedRoute toMatch={['NULLA_OSTA_INSERIMENTO']}>
                <NullaOsta />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_CANDIDATURA_ELENCO_BANDI}
            element={
              <ProtectedRoute
                toMatch={['BANDO_PO_CANDIDATURA']}
                controlloPartecipazioneBandiARF={true}
              >
                <ElencoBandiEQ operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_CANDIDATURA_VISUALIZZA_BANDO}/:idBando`}
            element={
              <ProtectedRoute toMatch={['BANDO_PO_DETTAGLIO', 'BANDO_PO_CANDIDATURA']}>
                <VisualizzaBandoEQ />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_UPLOAD_GESTIONE_MASSIVA}
            element={
              <ProtectedRoute toMatch={['UPLOAD_MASSIVO_BANDI_EQ_PREVIEW']}>
                <UploadGestioneMassiva />
              </ProtectedRoute>
            }
          />
          {/* 
          // nascosta come da issue: https://tools.linksmt.it/jira/browse/DXCAUF2-21
          <Route path={`${HREF_CANDIDATURA_DETTAGLIO_NULLA_OSTA}/:idRichiesta`} element={<ProtectedRoute toMatch={['NULLA_OSTA_DETTAGLIO']}><ValutaRichiesteNullaOsta operations={userRoleActive!}/></ProtectedRoute>} /> */}
          <Route
            path={`${HREF_CANDIDATURA_ELENCO_CANDIDATURE}/:idBando`}
            element={
              <ProtectedRoute toMatch={['BANDO_PO_DETTAGLIO_ASSEGNAZIONE']}>
                <ElencoCandidature operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_BANDO}/:idBando`}
            element={
              <ProtectedRoute toMatch={['BANDO_PO_PATCH', 'BANDO_PO_ACCESSO_COMPLETO']}>
                <ModificaBando />
              </ProtectedRoute>
            }
          />

          {/* ruoli e funzionalità */}
          <Route
            path={HREF_ELENCO_RUOLI}
            element={
              <ProtectedRoute toMatch={['RUOLO_ELENCO']}>
                <ElencoRuoli operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_NUOVO_RUOLO}
            element={
              <ProtectedRoute toMatch={['RUOLO_INSERT']}>
                <NuovoRuolo />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_RUOLO}/:id`}
            element={
              <ProtectedRoute toMatch={['RUOLO_DETTAGLIO']}>
                <ModificaRuolo operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_GESTIONE_RUOLO_FUNZIONALITA}/:id`}
            element={
              <ProtectedRoute toMatch={['ASSEGNARUOLOFUNZ_ELENCO']}>
                <AssociazioneRuoliFunzionalita operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_GESTIONE_RUOLO_UTENTI}/:id`}
            element={
              <ProtectedRoute toMatch={['ASSEGNARUOLODIPE_ELENCO']}>
                <AssociazioneRuoliUtenti operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />

          {/* configurazione notifiche */}
          <Route
            path={HREF_GESTIONE_NOTIFICHE}
            element={
              <ProtectedRoute toMatch={['CONFIGNOTIFICA_ELENCO']}>
                <GestioneNotifiche operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_NUOVA_CONFIGURAZIONE_NOTIFICA}
            element={
              <ProtectedRoute toMatch={['CONFIGNOTIFICA_INSERT']}>
                <NuovaConfigurazioneNotifica />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_CONFIGURAZIONE_NOTIFICA}/:funzionalita`}
            element={
              <ProtectedRoute toMatch={['CONFIGNOTIFICA_DETTAGLIO']}>
                <ModificaConfigurazioneNotifica operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />

          {/* elenco candidati */}
          <Route
            path={HREF_ELENCO_CANDIDATI}
            element={
              <ProtectedRoute toMatch={['ASSUNZIONI_ELENCO']}>
                <ElencoCandidati operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_TROVA_CANDIDATO}`}
            element={
              <ProtectedRoute toMatch={['ASSUNZIONI_INSERT']}>
                {/*                 <CreaCandidato />
                 */}
                <InserimentoCandidato />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_INSERIMENTO_CANDIDATO}`}
            element={
              <ProtectedRoute toMatch={['ASSUNZIONI_INSERT']}>
                <CreaCandidato />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_INSERIMENTO_MASSIVO_CANDIDATI}
            element={
              <ProtectedRoute toMatch={['ASSUNZIONI_ELENCO']}>
                <InserimentoMassivoCandidati />
              </ProtectedRoute>
            }
          />
          <Route path={HREF_INSERIMENTO_CONCORSO} element={<CreaConcorso />} />
          <Route
            path={`${HREF_VISUALIZZA_CANDIDATO}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<VisualizzaCandidato />}
          />
          <Route
            path={`${HREF_MODIFICA_CANDIDATO}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<ModificaCandidato />}
          />
          <Route
            path={`${HREF_CONVALIDA_DATI}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<ConvalidaDati />}
          />
          <Route
            path={`${HREF_VERIFICA_INTEGRAZIONE_DATI}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<VerificatIntegrazioneDati />}
          />
          <Route
            path={`${HREF_VALIDAZIONE_DATI_DOCUMENTI}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<ValidazioneDatiDocumenti />}
          />
          <Route
            path={`${HREF_VERIFICA_DATI}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<VerificaAltriDati />}
          />
          <Route
            path={`${HREF_INSERIMENTO_DATI_ASSUNZIONE}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<InserimentoDatiAssunzione />}
          />
          <Route
            path={`${HREF_CONVOCAZIONE_SINGOLA}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<InvioConvocazioneSingola />}
          />
          <Route
            path={`${HREF_CONFERIMENTO_DOCUMENTI}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<ConferimentoDocumenti />}
          />
          <Route
            path={`${HREF_GESTIONE_CONTRATTUALIZZAZIONE}/codiceFiscale/:codiceFiscale/onboarding/:onboardingId`}
            element={<GestioneContrattualizzazione />}
          />
          <Route path={`${HREF_GESTIONE_RINUNCIA}/:id`} element={<GestioneRinuncia />} />

          {/*     <Route
            path={HREF_INSERIMENTO_CANDIDATO}
            element={
              <ProtectedRoute toMatch={["ASSUNZIONI_INSERT"]}>
                <InserimentoCandidato operations={userRoleActive!} />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path={HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO}
            element={
              <ProtectedRoute perCandidato>
                <ValidazioneIntegrazioneDatiCandidato />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_COM_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'ASSUNZIONI_DETTAGLIO',
                  'ASSUNZIONI_ELENCO_CONVOCAZIONI',
                  'ASSUNZIONI_COMUNICA_CONVOCAZIONE',
                ]}
                mandatory
              >
                <ComunicazioneConvocazioneSottoscrizioneContratto operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO}`}
            element={
              <ProtectedRoute perCandidato>
                <GestioneConvocazioneSottoscrizioneContratto />
              </ProtectedRoute>
            }
          />
          {/*           <Route
            path={`${HREF_GEST_ACCETTAZIONE_CONTRATTO}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'ASSUNZIONI_DETTAGLIO',
                  'ASSUNZIONI_CONVOCAZIONE_ACCETTAZIONE',
                  'ASSUNZIONI_PATCH_DECISIONE',
                ]}
                mandatory
              >
                <GestioneAccettazioneContratto />
              </ProtectedRoute>
            }
          /> */}
          {/*     <Route
            path={`${HREF_GEST_CESSAZIONE_CONTRATTO}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  "ASSUNZIONI_DETTAGLIO",
                  "ASSUNZIONI_CONVOCAZIONE_CESSAZIONE",
                  "ASSUNZIONI_PATCH_DECISIONE",
                ]}
                mandatory
              >
                <GestioneCessazioneContratto />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path={`${HREF_CONF_AREA_DOCUMENTI}`}
            element={
              <ProtectedRoute toMatch={['ASSUNZIONI_ELENCO_DOCUMENTI']}>
                <ConfigurazioneAreaDocumenti operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MOD_DOCUMENTI_CANDIDATO}/:id`}
            element={
              <ProtectedRoute
                toMatch={['ASSUNZIONI_DETTAGLIO_DOCUMENTI', 'ASSUNZIONI_PATCH_DOCUMENTOCANDIDATO']}
                mandatory
              >
                <ModificaAreaDocumenti />
              </ProtectedRoute>
            }
          />

          {/* dipendenti regionali */}
          <Route
            path={`${HREF_ELENCO_DIPENDENTI_REG}`}
            element={
              <ProtectedRoute toMatch={['DIPENDENTE_ELENCO']}>
                <ElencoDipendentiRegionali />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_VISUALIZZA_CESSAZIONE_DIPENDENTE}/:id`}
            element={
              <ProtectedRoute
                mandatory
                toMatch={['DIPENDENTE_DETTAGLIO', 'DIPENDENTE_INFO_DECESSO']}
              >
                <VisualizzaCessazioneDipendente />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_CESSAZIONE_DIPENDENTE}/:id`}
            element={
              <ProtectedRoute mandatory toMatch={['DIPENDENTE_DETTAGLIO', 'DIPENDENTE_PATCH']}>
                <CessazioneDipendente operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />

          {/* rubrica */}
          <Route
            path={HREF_RUBRICA_ELENCO_DIPENDENTI}
            element={
              <ProtectedRoute toMatch={['RUBRICA_ELENCO_DIPENDENTI']}>
                <Rubrica operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_RUBRICA_INSERISCI_TEL_CRED}/:id`}
            element={
              <ProtectedRoute
                toMatch={['RUBRICA_DETTAGLIO_DIPENDENTE', 'RUBRICA_PATCH_DIPENDENTE']}
                mandatory
              >
                <InserisciTelefonoCredenziali />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_RUBRICA_INSERISCI_EMAIL_DIPENDENTE}/:id`}
            element={
              <ProtectedRoute
                toMatch={['RUBRICA_DETTAGLIO_DIPENDENTE', 'RUBRICA_PATCH_DIPENDENTE']}
                mandatory
              >
                <InserisciEmailDipendente operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_RUBRICA_MODIFICA_UTENZE}/:id`}
            element={
              <ProtectedRoute
                toMatch={['RUBRICA_DETTAGLIO_DIPENDENTE', 'RUBRICA_PATCH_DIPENDENTE']}
                mandatory
              >
                <ModificaUtenze />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_RUBRICA_VISUALIZZA_UTENZE}/:id`}
            element={
              <ProtectedRoute toMatch={['RUBRICA_DETTAGLIO_DIPENDENTE']}>
                <VisualizzaUtenze />
              </ProtectedRoute>
            }
          />

          {/* consulenti esterni */}
          <Route
            path={HREF_CONSULENTE_ESTERNO_ELENCO}
            element={
              <ProtectedRoute toMatch={['CONSULENTEESTERNO_ELENCO']}>
                <ElencoConsulentiEsterni operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_CONSULENTE_ESTERNO_MODIFICA}/:id`}
            element={
              <ProtectedRoute toMatch={['CONSULENTEESTERNO_PATCH']}>
                <ModificaConsulenteEsterno operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_CONSULENTE_ESTERNO_DETTAGLIO}/:id`}
            element={
              <ProtectedRoute toMatch={['CONSULENTEESTERNO_DETTAGLIO']}>
                <DettaglioConsulenteEsterno operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_CONSULENTE_ESTERNO_INSERISCI}
            element={
              <ProtectedRoute toMatch={['CONSULENTEESTERNO_INSERT']}>
                <InserisciConsulenteEsterno operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />

          {/* incarichi dirigenziali */}
          <Route
            path={HREF_ELENCO_INCARICHI_DIRIGENZIALI}
            element={
              <ProtectedRoute
                toMatch={[
                  'INCDIRIGENZIALE_ELENCO',
                  'INCDIRIGENZIALE_SELECT_DIP',
                  'INCDIRIGENZIALE_SELECT_SEZ',
                  'INCDIRIGENZIALE_SELECT_SER',
                ]}
              >
                <ElencoIncarichiDirigenziali operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_NUOVA_PROPOSTA_INCARICO}
            element={
              <ProtectedRoute
                toMatch={[
                  'INCDIRIGENZIALE_INSERT_DIP',
                  'INCDIRIGENZIALE_INSERT_SER',
                  'INCDIRIGENZIALE_INSERT_SEZ',
                  'INCDIRIGENZIALE_SELECT_SO_APPARTENENZA',
                ]}
              >
                <NuovaPropostaIncarico operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_PROPOSTA_INCARICO}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'INCDIRIGENZIALE_MOD_DIP',
                  'INCDIRIGENZIALE_MOD_SEZ',
                  'INCDIRIGENZIALE_MOD_SER',
                ]}
              >
                <ModificaPropostaIncarico />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <PageNotFound />
              </ProtectedRoute>
            }
          />

          {/* convenzioni */}
          <Route
            path={HREF_ELENCO_CONVENZIONI}
            element={
              <ProtectedRoute toMatch={['CONVENZIONI_RICERCA']}>
                <RicercaConvenzioni />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_VISUALIZZA_CONVENZIONE}/:id`}
            element={
              <ProtectedRoute toMatch={['CONVENZIONI_DETTAGLIO']}>
                <VisualizzaConvenzione />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_NUOVA_CONVENZIONE}
            element={
              <ProtectedRoute toMatch={['CONVENZIONI_INSERT']}>
                <NuovaConvenzione />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_CONVENZIONE}/:id`}
            element={
              <ProtectedRoute toMatch={['CONVENZIONI_PATCH']}>
                <ModificaConvenzione />
              </ProtectedRoute>
            }
          />

          {/* registro imprese */}
          <Route
            path={HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA}
            element={
              <ProtectedRoute toMatch={['REGISTRO_IMPRESE_RICERCA_SINGOLA']}>
                <RegistroImprese />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA}
            element={
              <ProtectedRoute toMatch={['REGISTRO_IMPRESE_RICERCA_MULTIPLA']}>
                <RegistroImpreseRicercaMultipla />
              </ProtectedRoute>
            }
          />

          <Route
            path={`${HREF_VALUTA_PROPOSTA_INCARICO}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'INCDIRIGENZIALE_DETTAGLIO_VALUTAZIONE',
                  'INCDIRIGENZIALE_INSERT_VALPERSONALE',
                  'INCDIRIGENZIALE_PATCH_VALPERSONALE',
                ]}
              >
                <ValutazioneProposta operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_INSERIMENTO_PARERE_GIUNTA}/:id`}
            element={
              <ProtectedRoute
                toMatch={[
                  'INCDIRIGENZIALE_PATCH_VALGIUNTA',
                  'INCDIRIGENZIALE_DETTAGLIO_VALUTAZIONE',
                ]}
              >
                <InserimentoParere />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ASSEGNA_INCARICO}/:id`}
            element={
              <ProtectedRoute toMatch={['INCDIRIGENZIALE_INSERT_ASSEGNAZIONE']}>
                <AssegnaIncarico />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_MODIFICA_INCARICO}/:id`}
            element={
              <ProtectedRoute toMatch={['INCDIRIGENZIALE_MODIFICA_ASSEGNAZIONE']}>
                <ModificaAssegnazioneIncarico />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_VISUALIZZA_INCARICO}/:id`}
            element={
              <ProtectedRoute toMatch={['INCDIRIGENZIALE_ELENCO']}>
                <VisualizzaIncarico />
              </ProtectedRoute>
            }
          />

          {/* abilitazioni funzionalità applicative */}
          <Route
            path={HREF_ABILITAZIONI_ELENCO_RICHIESTE}
            element={
              <ProtectedRoute toMatch={['RICHIESTEFUNZAPP_ELENCO']}>
                <ElencoRichieste operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_ABILITAZIONI_NUOVA_RICHIESTA}
            element={
              <ProtectedRoute
                toMatch={['RICHIESTEFUNZAPP_INSERT', 'RICHIESTEFUNZAPP_PATCH']}
                mandatory
              >
                <NuovaRichiestaAbilitazione operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ABILITAZIONI_MODIFICA_RICHIESTA}/:id`}
            element={
              <ProtectedRoute
                toMatch={['RICHIESTEFUNZAPP_DETTAGLIO', 'RICHIESTEFUNZAPP_PATCH']}
                mandatory
              >
                <ModificaRichiestaAbilitazione operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ABILITAZIONI_DETTAGLIO_RICHIESTA}/:id`}
            element={
              <ProtectedRoute toMatch={['RICHIESTEFUNZAPP_DETTAGLIO']}>
                <DettaglioRichiestaAbilitazione operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_ABILITAZIONI_APPROVAZIONE_ATTIVAZIONE}/:id`}
            element={
              <ProtectedRoute
                toMatch={['RICHIESTEFUNZAPP_DETTAGLIO', 'RICHIESTEFUNZAPP_PATCH_VALUTAZIONE']}
                mandatory
              >
                <ApprovaRichiestaAttivazione operations={userRoleActive!} />
              </ProtectedRoute>
            }
          />

          {/* stackholder esterni */}
          <Route
            path={HREF_SH_AREA_PERSONALE}
            element={
              <ProtectedRoute perCandidato perCittadino perCessato>
                <AreaPersonale />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_SH_MODIFICA_INFO}
            element={
              <ProtectedRoute perCandidato perCittadino perCessato>
                <ModificaInformazioni />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_SH_DOCUMENTI}
            element={
              <ProtectedRoute perCandidato perCittadino perCessato>
                <DocumentiCittadino />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_SH_PRATICHE}
            element={
              <ProtectedRoute perCandidato perCittadino perCessato>
                <PraticheCittadino />
              </ProtectedRoute>
            }
          />
          <Route
            path={HREF_SH_BANDI_CONCORSI}
            element={
              <ProtectedRoute perCandidato perCittadino perCessato>
                <BandiConcorsi />
              </ProtectedRoute>
            }
          />

          {/*Gestione CV*/}
          <Route
            path={HREF_GESTIONE_CV}
            element={
              <ProtectedRoute toMatch={['CV_INSERIMENTO', 'CV_DETTAGLIO']}>
                <GestioneCv />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HREF_VISUALIZZA_CV}/:id`}
            element={
              <ProtectedRoute toMatch={['CV_DETTAGLIO']}>
                <GestioneCv />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      <PageLoader />
      <NotificaComponent />

      {mostraTornaSu && (
        <div className="scroll-up" onClick={() => window.scrollTo(0, 0)} title="Torna su">
          <Icon icon="it-arrow-up-circle" color="white" />{' '}
        </div>
      )}
    </div>
  );
}

/** */

export default App;