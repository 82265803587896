import { useRef, useState } from 'react';

export const useMandatoryViewModal = ({ callbackFn }: { callbackFn: Function | null }) => {
  const [isPdfModalOpen, setIsPdfModalOpen] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDivElement>(null);
  const [nextBtnActive, setNextBtnActive] = useState<boolean>(false);

  function toggleModal() {
    setIsPdfModalOpen((prevState) => !prevState);
  }

  function handleScroll() {
    //console.log('Handle scroll triggered!');
    const div = dialogRef.current;
    if (div) {
      //console.log('Reference div present');
      // Check if scroll bar has reached the end (- 10 is a little bit of tolerance )
      //console.log(`Scroll height: ${div.scrollHeight} - Scroll top: ${div.scrollTop} = Client height: ${div.clientHeight}`);
      if (div.scrollHeight - div.scrollTop - 10 <= div.clientHeight) {
        setNextBtnActive(true);
      }
    }
  }

  function handleAcceptClick() {
    if (callbackFn) callbackFn();

    toggleModal();
  }

  return {
    toggleModal,
    handleAcceptClick,
    handleScroll,
    dialogRef,
    nextBtnActive,
    isPdfModalOpen,
  };
};
