import { useEffect, useState } from 'react';
import { Button } from 'design-react-kit';
import { DatiAmministrazioneControllerApi, DatiAttoDto } from '../../services/ms-anagrafica-unica';
import { formatDate } from '../../utility/formatDate';
import { MS_AU_CONFIG } from '../../store/store-constants';
import { Alert } from 'design-react-kit';
import { Spinner } from 'reactstrap';
import { showFieldIfNotNull } from 'utility/utility';

export interface DettaglioAttoInterface {
  codiceCifra?: string;
  titolo?: string;
  datiAtto: DatiAttoDto;
  showBtnRecuperaDati?: boolean;
  showLoader?: boolean;
  setDatiAtto?: (datiAtto: DatiAttoDto) => void;
  notFoundDatiAtto?: () => void;
  resetDatiAtto?: () => void;
}

const DettaglioAtto = ({
  codiceCifra,
  datiAtto,
  titolo = 'Dati Atto',
  showBtnRecuperaDati = false,
  showLoader = false,
  setDatiAtto = () => {},
  notFoundDatiAtto = () => {},
  resetDatiAtto = () => {},
}: DettaglioAttoInterface) => {
  const [loadingDati, setLoadingDati] = useState(false);

  useEffect(() => {
    if (!showBtnRecuperaDati && codiceCifra && codiceCifra?.length >= 10 && !datiAttoPresenti())
      caricaDatiAtto();
  }, [codiceCifra]);

  const datiAttoPresenti = () => {
    return !!Object.keys(datiAtto).length;
  };
  const caricaDatiAtto = () => {
    if (codiceCifra) {
      datiAtto = {};
      setLoadingDati(true);
      let arrayValue: string[] = codiceCifra?.split('/');
      const api = new DatiAmministrazioneControllerApi(MS_AU_CONFIG);
      api
        .recuperoAttoCifra({ codiceAoo: arrayValue[0], anno: arrayValue[1], numero: arrayValue[2] })
        .then((response) => {
          setLoadingDati(false);
          if (response.data?.oggetto == null) notFoundDatiAtto();
          else {
            setDatiAtto(response.data);
          }
        });
    }
  };

  return (
    <>
      <div className="mt-1">
        <div className="card-div">
          {datiAttoPresenti() && (
            <div className="info-box-light">
              <h6 className="mb-3">{titolo}</h6>
              <hr />
              <div className="row">
                <dl className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">Oggetto</dt>
                      <dd className="col-sm-12">{showFieldIfNotNull(datiAtto?.oggetto)}</dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">Data Adozione</dt>
                      <dd className="col-sm-12">{formatDate(datiAtto?.dataAdozione!)}</dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">Codice Adozione</dt>
                      <dd className="col-sm-12">{showFieldIfNotNull(datiAtto?.codiceAdozione)}</dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">Codice Proposta</dt>
                      <dd className="col-sm-12">{showFieldIfNotNull(datiAtto?.codiceProposta)}</dd>
                    </div>
                  </div>
                </dl>
              </div>
              {showBtnRecuperaDati && (
                <div className=" postion-relative d-flex flex-row-reverse pb-2">
                  <Button color="primary" className="mt-2 mr-2" onClick={caricaDatiAtto}>
                    Recupera Dati Atto
                  </Button>
                  <Button outline color="primary" className="mt-2 mr-2" onClick={resetDatiAtto}>
                    Reset
                  </Button>
                </div>
              )}
            </div>
          )}

          {!loadingDati && !datiAttoPresenti() && <Alert color="warning">Atto non presente</Alert>}

          {showLoader && loadingDati && (
            <>
              <div className="d-flex align-items-center mb-2">
                <span className="mr-2">
                  <Spinner active="true" color="primary" size="md" />
                </span>
                <span className="text-primary font-weight-bold">Caricamento dati Atto ...</span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DettaglioAtto;
