import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import CustomSelect, { CustomSelectItem } from '../../common/custom-select/CustomSelect';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  Callout,
  CalloutTitle,
  FormGroup,
  Icon,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TextArea,
} from 'design-react-kit';
import { t } from 'i18next';
import {
  CurriculumVitaeDto,
  CurriculumVitaeRequestDtoSezioneEnum,
  ElencoTitoliDiStudioDettaglioCVContestoForm,
  IstruzioneFormazioneRequestDto,
  OptionDtoLong,
  TitoliDiStudioMacroCategorieDto,
  TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum,
} from '../../../services/ms-anagrafica-unica';
import {
  creaNuovoCv,
  getCvDetail,
  getDettaglioTitoloStudioOpts,
  getTitoliStudioOpts,
} from '../../../store/curriculumVitaeSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { FormikProps, useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import TextareaInfoChars from '../../common/textarea-infochars/TextareaInfoChars';
import { GestioneCvPageRef } from '../GestioneCvForm';

export interface EspProfessionaliProps {
  disabled: boolean;
  continuaAction: Function;
  scrollAction: Function;
  disabilitaContinuaBtn: (disabilita: boolean) => void;
}

const IstruzioneFormazioneTab = forwardRef(
  (props: EspProfessionaliProps, ref: Ref<GestioneCvPageRef>) => {
    const dispatch = useAppDispatch();
    const { detailStatus, insertStatus } = useAppSelector((state) => state.curriculumVitae);
    const { userInfoResult } = useAppSelector((state) => state.user);

    const [titoloOptions, setTitoloOptions] = useState<CustomSelectItem[]>([]);
    const [dettaglioTitoloOptions, setDettaglioTitoloOptions] = useState<CustomSelectItem[]>([]);
    const [elencoIstruzioneFormazione, setElencoIstruzioneFormazione] = useState<
      IstruzioneFormazioneRequestDto[]
    >([]);
    const [elencoTitoliDiStudioMacroCategorie, setElencoTitoliDiStudioMacroCategorie] = useState<
      TitoliDiStudioMacroCategorieDto[]
    >([]);
    const [elencoTitoliDettaglioCategorie, setElencoTitoliDettaglioCategorie] = useState<
      OptionDtoLong[]
    >([]);
    const [saveOnClick, setSaveOnClick] = useState<boolean>(false);
    const [formCollapseOpen, setFormCollapseOpen] = useState(true);
    const [selectedElement, setSelectedElement] = useState<number | undefined>();
    const [showFormInserimento, setShowFormInserimento] = useState(false);
    const [canAggiungiEsperienza, setCanAggiungiEsperienza] = useState(false);
    const [inModifica, setInModifica] = useState(false);
    const [altriPercorsiFormativi, setAltriPercorsiFormativi] = useState('');
    const [tipoSalvataggio, setTipoSalvataggio] = useState<'LICENZA_MEDIA' | 'ALTRI_TITOLI'>(
      'ALTRI_TITOLI'
    );
    const [isOpenModalTitolo, toggleModalEliminaTitolo] = useState(false);
    const [titoloDaRimuovere, setTitoloDaRimuovere] = useState<string | number | undefined>(
      undefined
    );

    var initialValues: IstruzioneFormazioneRequestDto = {
      dataConseguimento: '',
      specificare: '',
      idDettaglio: undefined,
      idMacroCategoria: undefined,
      titoloAccessoAreaGiuridica: false,
      esameDiStato: false,
      dataEsecuzioneEsameDiStato: '',
      dataIscrizioneAlbo: '',
    };

    const customDettaglioValidation = (data: any) => {
      if (
        elencoTitoliDiStudioMacroCategorie.find(
          (value) => value.id === form.values.idMacroCategoria
        )?.previstoDominioSelezione &&
        data === undefined
      ) {
        return false;
      }
      return true;
    };

    const isNotRequired = () => {
      let titoloMacro = elencoTitoliDiStudioMacroCategorie.find(
        (value) => value.id === form.values.idMacroCategoria
      );

      if (
        (form.values.specificare === '' || form.values.specificare === undefined) &&
        ((form.values.idDettaglio !== undefined &&
          elencoTitoliDettaglioCategorie.find(
            (value) => value && value.label && value.label.toLocaleLowerCase().includes('altro')
          )?.valore === form.values.idDettaglio) ||
          titoloMacro?.classe === 4 ||
          titoloMacro?.classe === 11 ||
          titoloMacro?.classe === 12 ||
          (titoloMacro?.classe === 6 && titoloMacro?.sub === 1) ||
          (titoloMacro?.classe === 6 && titoloMacro?.sub === 2) ||
          titoloMacro?.classe === 7 ||
          titoloMacro?.classe === 9 ||
          titoloMacro?.classe === 10)
      ) {
        return false;
      }
      return true;
    };

    const schema = z
      .object({
        dataConseguimento: z.coerce
          .date({ required_error: 'Il campo Data conseguimento è obbligatorio' })
          .min(new Date(1900, 0), 'La data non può essere inferiore al 01/01/1900')
          .refine((data) => data < new Date(), {
            message: 'Data conseguimento non può essere maggiore della data odierna',
          })
          .optional(),
        idDettaglio: z.custom((data) => customDettaglioValidation(data), {
          message: 'Il campo è obbligatorio',
        }),
        idMacroCategoria: z.number({ required_error: 'Il campo è obbligatorio' }),
        specificare: z.custom(() => isNotRequired(), { message: 'Il campo è obbligatorio' }),
        titoloAccessoAreaGiuridica: z.boolean().optional(),
        esameDiStato: z.boolean().optional(),
        dataEsecuzioneEsameDiStato: z.coerce
          .date({ required_error: 'Il campo AL è obbligatorio' })
          .min(new Date(1900, 0), 'La data non può essere inferiore al 01/01/1900')
          .refine((data) => data < new Date(), {
            message:
              'Il campo Data esecuzione esame di Stato non può essere maggiore della data odierna',
          })
          .optional(),
        dataIscrizioneAlbo: z.coerce
          .date()
          .min(new Date(1900, 0), 'La data non può essere inferiore al 01/01/1900')
          .refine((data) => data < new Date(), {
            message: 'Data conseguimento non può essere maggiore della data odierna',
          })
          .optional(),
      })
      .superRefine((data, context) => {
        if (data.dataConseguimento === undefined) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Selezionare la data di conseguimento',
            path: ['dataConseguimento'],
          });
        }
        if (data.esameDiStato && !data.dataEsecuzioneEsameDiStato) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "La data di esecuzione dell'esame di stato è obbligatoria",
            path: ['dataEsecuzioneEsameDiStato'],
          });
        }
        if (
          !!data.dataConseguimento &&
          (data.idMacroCategoria === 3 ||
            data.idMacroCategoria === 5 ||
            data.idMacroCategoria === 6 ||
            data.idMacroCategoria === 7 ||
            data.idMacroCategoria === 8 ||
            data.idMacroCategoria === 9 ||
            data.idMacroCategoria === 10 ||
            data.idMacroCategoria === 11 ||
            data.idMacroCategoria === 12 ||
            data.idMacroCategoria === 13 ||
            data.idMacroCategoria === 14)
        ) {
          const islicenzaMediaMaggioreLaurea = elencoIstruzioneFormazione.some(
            (i) =>
              // se è stata inserita la licenza media
              i.idMacroCategoria === 1 &&
              // la data di conseguimento di laurea (1° livello, 2° livello e vecchio ordinamento) deve essere maggiore
              new Date(i.dataConseguimento!).getFullYear() >
                new Date(data.dataConseguimento!).getFullYear()
          );
          if (islicenzaMediaMaggioreLaurea) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'La data di conseguimento deve essere successiva al diploma e/o alla licenza media',
              path: ['dataConseguimento'],
            });
          }
        }
        if (
          !!data.dataConseguimento &&
          (data.idMacroCategoria === 3 ||
            data.idMacroCategoria === 5 ||
            data.idMacroCategoria === 6 ||
            data.idMacroCategoria === 7 ||
            data.idMacroCategoria === 8 ||
            data.idMacroCategoria === 9 ||
            data.idMacroCategoria === 10 ||
            data.idMacroCategoria === 11 ||
            data.idMacroCategoria === 12 ||
            data.idMacroCategoria === 13 ||
            data.idMacroCategoria === 14)
        ) {
          const isDiplomaMaggioreLaurea = elencoIstruzioneFormazione.some(
            (i) =>
              // se è stata inserito il diploma di scuola sencodaria
              i.idMacroCategoria === 2 &&
              // la data di conseguimento di laurea (1° livello, 2° livello e vecchio ordinamento) deve essere maggiore
              new Date(i.dataConseguimento!).getFullYear() >
                new Date(data.dataConseguimento!).getFullYear()
          );
          if (isDiplomaMaggioreLaurea) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'La data di conseguimento deve essere successiva al diploma e/o alla licenza media',
              path: ['dataConseguimento'],
            });
          }
        }
        if (
          data.titoloAccessoAreaGiuridica &&
          elencoIstruzioneFormazione.filter((i) => i.titoloAccessoAreaGiuridica).length > 0
        ) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "E' già stato selezionato un titolo come titolo di accesso all'area giuridica",
            path: ['titoloAccessoAreaGiuridica'],
          });
        }
        return context;
      });

    const schemaDiplomaLicenzaMedia = z
      .object({
        dataConseguimento: z
          .string()
          .transform((year) => parseInt(year))
          .pipe(
            z
              .number({ invalid_type_error: 'Fornire un anno' })
              .min(1900, { message: 'Valore minimo 1900' })
              .max(new Date().getFullYear(), { message: 'Inserire un anno valido' })
          )
          .transform((year) => year.toString()),
        idDettaglio: z.custom((data) => customDettaglioValidation(data), {
          message: 'Il campo è obbligatorio',
        }),
        idMacroCategoria: z.number({ required_error: 'Il campo è obbligatorio' }),
        specificare: z.custom(() => isNotRequired(), { message: 'Il campo è obbligatorio' }),
        titoloAccessoAreaGiuridica: z.boolean().optional(),
      })
      .superRefine((data, context) => {
        if (data.dataConseguimento === undefined) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Selezionare l'anno conseguimento",
            path: ['dataConseguimento'],
          });
        }
        if (
          data.titoloAccessoAreaGiuridica &&
          elencoIstruzioneFormazione.filter((i) => i.titoloAccessoAreaGiuridica).length > 0
        ) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "E' già stato selezionato un titolo come titolo di accesso all'area giuridica",
            path: ['titoloAccessoAreaGiuridica'],
          });
        }
        if (!!data.dataConseguimento && data.idMacroCategoria === 1) {
          const isLaureaMinoreLicenzaMedia = elencoIstruzioneFormazione.some(
            (i) =>
              // se è stata inserita la licenza media
              (i.idMacroCategoria === 3 ||
                i.idMacroCategoria === 5 ||
                i.idMacroCategoria === 6 ||
                i.idMacroCategoria === 7 ||
                i.idMacroCategoria === 8 ||
                i.idMacroCategoria === 9 ||
                i.idMacroCategoria === 10 ||
                i.idMacroCategoria === 11 ||
                i.idMacroCategoria === 12 ||
                i.idMacroCategoria === 13 ||
                i.idMacroCategoria === 14) &&
              // la data di conseguimento di laurea (1° livello, 2° livello e vecchio ordinamento) deve essere maggiore
              new Date(i.dataConseguimento!).getFullYear() <=
                new Date(data.dataConseguimento!).getFullYear()
          );
          if (isLaureaMinoreLicenzaMedia) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'La data di conseguimento della licenza media precedente agli altri titoli inseriti',
              path: ['dataConseguimento'],
            });
          }
        }
        if (!!data.dataConseguimento && data.idMacroCategoria === 2) {
          const isLaureaMinoreDiploma = elencoIstruzioneFormazione.some(
            (i) =>
              // se è stata inserita la licenza media
              (i.idMacroCategoria === 3 ||
                i.idMacroCategoria === 5 ||
                i.idMacroCategoria === 6 ||
                i.idMacroCategoria === 7 ||
                i.idMacroCategoria === 8 ||
                i.idMacroCategoria === 9 ||
                i.idMacroCategoria === 10 ||
                i.idMacroCategoria === 11 ||
                i.idMacroCategoria === 12 ||
                i.idMacroCategoria === 13 ||
                i.idMacroCategoria === 14) &&
              // la data di conseguimento di laurea (1° livello, 2° livello e vecchio ordinamento) deve essere maggiore
              new Date(i.dataConseguimento!).getFullYear() <=
                new Date(data.dataConseguimento!).getFullYear()
          );
          if (isLaureaMinoreDiploma) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'La data di conseguimento del diploma precedente agli altri titoli inseriti',
              path: ['dataConseguimento'],
            });
          }
        }
        if (!!data.dataConseguimento && data.idMacroCategoria === 1) {
          const isDiplomaMinoreLicenzaMedia = elencoIstruzioneFormazione.some(
            (i) =>
              // se è stata inserita la licenza media
              i.idMacroCategoria === 2 &&
              // la data di conseguimento di laurea (1° livello, 2° livello e vecchio ordinamento) deve essere maggiore
              new Date(i.dataConseguimento!).getFullYear() <=
                new Date(data.dataConseguimento!).getFullYear()
          );
          if (isDiplomaMinoreLicenzaMedia) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'La data di conseguimento della licenza media deve essere precedente al diploma',
              path: ['dataConseguimento'],
            });
          }
        }
        if (!!data.dataConseguimento && data.idMacroCategoria === 2) {
          const isLicenzaMediaMaggioreDiploma = elencoIstruzioneFormazione.some(
            (i) =>
              // se è stata inserita la licenza media
              i.idMacroCategoria === 1 &&
              // la data di conseguimento di laurea (1° livello, 2° livello e vecchio ordinamento) deve essere maggiore
              new Date(i.dataConseguimento!).getFullYear() >=
                new Date(data.dataConseguimento!).getFullYear()
          );
          if (isLicenzaMediaMaggioreDiploma) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'La data di conseguimento del diploma deve essere maggiore di quella della licenza media',
              path: ['dataConseguimento'],
            });
          }
        }
        if (!!data.specificare && data.specificare.length > 300) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'length300chars',
            path: ['specificare'],
          });
        }
        return context;
      });

    let validationSchema =
      tipoSalvataggio && tipoSalvataggio === 'ALTRI_TITOLI'
        ? toFormikValidationSchema(schema)
        : toFormikValidationSchema(schemaDiplomaLicenzaMedia);

    const form: FormikProps<IstruzioneFormazioneRequestDto> = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        aggiungiIstruzione(values);
        props.scrollAction();
      },
      enableReinitialize: true,
      validateOnBlur: true,
    });

    const populateDataCV = (dataCv: CurriculumVitaeDto) => {
      if (
        dataCv &&
        dataCv.elencoIstruzioneFormazione &&
        dataCv.elencoIstruzioneFormazione.length > 0
      ) {
        let elencoIstruzione = dataCv.elencoIstruzioneFormazione!.map((value) => {
          if (!value.macroCategoria) {
            setAltriPercorsiFormativi(value.altriPercorsiFormativi || '');
            return {
              altriPercorsiFormativi: value.altriPercorsiFormativi,
            };
          } else {
            let a: IstruzioneFormazioneRequestDto = {};
            a.dataConseguimento = value.dataConseguimento || '';
            a.idDettaglio = value.dettaglio?.id || undefined;
            a.idMacroCategoria = value.macroCategoria?.id || undefined;
            a.specificare = value.specificare || '';
            a.titoloAccessoAreaGiuridica = value.titoloAccessoAreaGiuridica || undefined;
            a.esameDiStato = value.esameDiStato || false;
            a.dataEsecuzioneEsameDiStato = value.dataEsecuzioneEsameDiStato || '';
            a.dataIscrizioneAlbo = value.dataIscrizioneAlbo || '';
            //a.altriPercorsiFormativi = value.altriPercorsiFormativi || ' '
            return a;
          }
        });
        setElencoIstruzioneFormazione(elencoIstruzione);
      }
    };

    useEffect(() => {
      dispatch(getTitoliStudioOpts())
        .unwrap()
        .then((resp) => {
          if (resp) {
            let titoloOpt: Array<CustomSelectItem> = [];
            resp.elencoTitoliDiStudio &&
              resp.elencoTitoliDiStudio.forEach((f: any) => {
                titoloOpt.push({
                  value: f.id,
                  label: f.descrizione,
                });
              });
            setElencoTitoliDiStudioMacroCategorie(resp.elencoTitoliDiStudio!);
            setTitoloOptions(titoloOpt);
            dispatch(getCvDetail(userInfoResult?.entityId!))
              .unwrap()
              .then((resp) => {
                populateDataCV(resp);
              });
          }
        });
    }, []);

    const handleSelectChange = (
      name: string,
      e: string | number,
      forceValidation?: boolean,
      resetDate?: boolean
    ) => {
      form.setFieldValue(name, e, forceValidation);

      if (name === 'idMacroCategoria') {
        form.setFieldValue('idDettaglio', '');

        resetDate && form.setFieldValue('dataConseguimento', '');

        dispatch(getDettaglioTitoloStudioOpts(e as number)).then((res) => {
          var titoloDettaglioOpt: Array<CustomSelectItem> = [];
          var resp = res.payload as ElencoTitoliDiStudioDettaglioCVContestoForm;
          resp &&
            resp.elencoTitoliDiStudio!.forEach((f: any) => {
              titoloDettaglioOpt.push({
                value: f.value,
                label: f.label,
              });
            });
          resp.elencoTitoliDiStudio && setElencoTitoliDettaglioCategorie(resp.elencoTitoliDiStudio);
          setDettaglioTitoloOptions(titoloDettaglioOpt);
        });
      }
    };

    let aggiungiIstruzione = (values: IstruzioneFormazioneRequestDto) => {
      if (form.isValid) {
        if (selectedElement !== undefined) {
          let updatedData = values;
          var array = [...elencoIstruzioneFormazione]; // make a separate copy of the array
          array[selectedElement] = updatedData;
          setElencoIstruzioneFormazione(array);
          setSelectedElement(undefined);
        } else {
          let dataDetail = [...elencoIstruzioneFormazione, values];
          setElencoIstruzioneFormazione(dataDetail);
        }
      }
      setSaveOnClick(true);
      form.resetForm({ values: initialValues });
      setShowFormInserimento(false);
      disabilitaBtnContinua(false);
      setCanAggiungiEsperienza(true);
    };

    const getFieldError = (
      form: FormikProps<IstruzioneFormazioneRequestDto>,
      fieldName: keyof IstruzioneFormazioneRequestDto
    ): string => {
      if (form.getFieldMeta(fieldName).touched) {
        return form.errors[fieldName] || '';
      } else return '';
    };

    const removeItem = (index: number) => {
      setSaveOnClick(true);
      setElencoIstruzioneFormazione([
        ...elencoIstruzioneFormazione.slice(0, index),
        ...elencoIstruzioneFormazione.slice(index + 1),
      ]);
    };

    const disabilitaBtnContinua = (disabilita: boolean) => {
      props.disabilitaContinuaBtn(
        disabilita ||
          !elencoIstruzioneFormazione.some((titolo) => titolo.idMacroCategoria !== undefined)
      );
    };

    const modificaIstruzione = (index: number) => {
      var array = [...elencoIstruzioneFormazione]; // make a separate copy of the array
      var esp = array[index] as IstruzioneFormazioneRequestDto;
      var updatedEsp = esp;

      if (esp != null && esp != undefined) {
        setSelectedElement(index);
        form.resetForm({ values: initialValues });
        if (esp.idMacroCategoria === 1 || esp.idMacroCategoria === 2) {
          updatedEsp = {
            ...esp,
            dataConseguimento: esp.dataConseguimento!.substring(0, 4),
          };
        }
        form.setValues(updatedEsp);
        disabilitaBtnContinua(true);
        setCanAggiungiEsperienza(false);
        setShowFormInserimento(true);
        setInModifica(true);
        if (
          esp.idMacroCategoria != undefined &&
          elencoTitoliDiStudioMacroCategorie.find((value) => value.id === esp.idMacroCategoria)
            ?.classe !== 1
        ) {
          handleSelectChange('idMacroCategoria', esp.idMacroCategoria);
          handleSelectChange('idDettaglio', esp.idDettaglio!);
        }
      }
    };

    useEffect(() => {
      if (elencoIstruzioneFormazione.length > 0) {
        if (saveOnClick === true) {
          const licenzaMediaEDiploma = elencoIstruzioneFormazione.filter(
            (titolo) => titolo.idMacroCategoria === 1 || titolo.idMacroCategoria === 2
          );
          const licenzaMediaEDiplomaDataConseguimento = licenzaMediaEDiploma.map((titolo) => {
            const titoloUpdated = {
              ...titolo,
              dataConseguimento: `${titolo.dataConseguimento}-01-01`,
            };
            return titoloUpdated;
          });
          const altriTitoli = elencoIstruzioneFormazione.filter(
            (titolo) => titolo.idMacroCategoria !== 1 && titolo.idMacroCategoria !== 2
          );

          dispatch(
            creaNuovoCv({
              elencoIstruzioneFormazione: [
                ...licenzaMediaEDiplomaDataConseguimento,
                ...altriTitoli,
              ],
              sezione: CurriculumVitaeRequestDtoSezioneEnum.IstruzioneFormazione,
            })
          )
            .unwrap()
            .then((resp) => {
              if (resp != null) {
                setSaveOnClick(false);
                if (userInfoResult && userInfoResult.entityId)
                  dispatch(getCvDetail(userInfoResult?.entityId!))
                    .unwrap()
                    .then((resp) => populateDataCV(resp));
              }
            });
        } else {
          setShowFormInserimento(false);
          setCanAggiungiEsperienza(true);
          disabilitaBtnContinua(false);
          setInModifica(false);
        }
      } else {
        setShowFormInserimento(true);
        disabilitaBtnContinua(true);
      }
    }, [elencoIstruzioneFormazione]);

    const handleChangeDataConseguimento = (e: any) => {
      setTipoSalvataggio('LICENZA_MEDIA');
      form.setFieldValue('dataConseguimento', e.target.value);
    };

    const printLabelDataConseguimento = () => {
      return form.values.idMacroCategoria === 10
        ? 'Data conseguimento titolo'
        : 'Data conseguimento';
    };

    const salvaTitolo = () => {
      !form.values.esameDiStato && form.setFieldValue('dataEsecuzioneEsameDiStato', '');
      form.values.idMacroCategoria !== 10 && form.setFieldValue('dataIscrizioneAlbo', '');
      setTimeout(() => {
        form.submitForm();
      }, 0);
    };

    const testInvio = () => {
      const licenzaMediaEDiploma = elencoIstruzioneFormazione.filter(
        (titolo) => titolo.idMacroCategoria === 1 || titolo.idMacroCategoria === 2
      );
      const licenzaMediaEDiplomaDataConseguimento = licenzaMediaEDiploma.map((titolo) => {
        const titoloUpdated = {
          ...titolo,
          dataConseguimento: `${titolo.dataConseguimento}-01-01`,
        };
        return titoloUpdated;
      });
      const altriTitoli = elencoIstruzioneFormazione.filter(
        (titolo) =>
          titolo.idMacroCategoria !== 1 &&
          titolo.idMacroCategoria !== 2 &&
          titolo.idMacroCategoria !== undefined
      );
      let nuovoElencoIstruzioneFormazione = [];

      if (altriPercorsiFormativi.length > 0) {
        const percorsiFormativi = elencoIstruzioneFormazione.filter(
          (titolo) => titolo.idMacroCategoria === undefined
        )[0];
        const percorsiFormativiUpdated = {
          ...percorsiFormativi,
          altriPercorsiFormativi: altriPercorsiFormativi,
        };

        nuovoElencoIstruzioneFormazione = [
          ...licenzaMediaEDiplomaDataConseguimento,
          ...altriTitoli,
          percorsiFormativiUpdated,
        ];
      } else {
        nuovoElencoIstruzioneFormazione = [
          ...licenzaMediaEDiplomaDataConseguimento,
          ...altriTitoli,
        ];
      }
      dispatch(
        creaNuovoCv({
          elencoIstruzioneFormazione: nuovoElencoIstruzioneFormazione,
          sezione: CurriculumVitaeRequestDtoSezioneEnum.IstruzioneFormazione,
        })
      )
        .unwrap()
        .then((resp) => {
          resp != null && props.continuaAction();
        });
    };

    const confermaRimuoviTitolo = (stato: boolean) => {
      if (stato) {
        removeItem(Number(titoloDaRimuovere));
      }
      toggleModalEliminaTitolo(!isOpenModalTitolo);
      setTitoloDaRimuovere(undefined);
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm() {
          testInvio();
        },
      }),
      [elencoIstruzioneFormazione, altriPercorsiFormativi]
    );

    return (
      <div className="istruzione-formazione">
        <Callout className="au-callout au-callout-info col-md-12 mb-5">
          <CalloutTitle>
            <Icon icon="it-info-circle" color="primary" size="sm" /> Nota
          </CalloutTitle>
          Nella sezione denominata “Titoli di Studio” è necessario anagrafare esclusivamente i
          titoli di studio riconosciuti ai sensi del vigente ordinamento. Nella sezione denominata
          “Altri Percorsi Formativi” è possibile anagrafare altri percorsi formativi che non
          comportano l’acquisizione di un titolo di studio
        </Callout>
        <div className="font-italic font-weight-500 mb-3">{t('campiObbligatoriAsterisco')}</div>

        <div className="">
          {elencoIstruzioneFormazione.length > 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between my-3">
                <div className="d-flex align-items-center">
                  <h4>{t('Esperienze aggiunte')} </h4>
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    color="primary"
                    className="mt-2"
                    outline
                    disabled={!canAggiungiEsperienza}
                    onClick={() => {
                      form.resetForm();
                      setShowFormInserimento(true);
                      setCanAggiungiEsperienza(false);
                      disabilitaBtnContinua(true);
                    }}
                  >
                    {t('Aggiungi titolo')}
                  </Button>
                </div>
              </div>

              <Table responsive striped>
                <tbody>
                  {insertStatus === STATUS_FULLFILLED &&
                    elencoIstruzioneFormazione.map(
                      (data, index) =>
                        data.idMacroCategoria !== undefined && (
                          <tr key={index}>
                            <td>
                              {
                                elencoTitoliDiStudioMacroCategorie.find(
                                  (value) => value.id === data.idMacroCategoria
                                )?.descrizione
                              }
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-end mr-3">
                                <div
                                  className="btn-table"
                                  onClick={() => {
                                    if (!showFormInserimento) {
                                      toggleModalEliminaTitolo(true);
                                      setTitoloDaRimuovere(index);
                                    }
                                  }}
                                  title="Elimina voce"
                                >
                                  <Icon
                                    color="primary"
                                    icon="it-delete"
                                    className={showFormInserimento ? 'disabled' : ''}
                                  />
                                </div>

                                <div
                                  className="btn-table ml-2"
                                  onClick={() => {
                                    if (!showFormInserimento) modificaIstruzione(index);
                                  }}
                                  title="Modifica voce"
                                >
                                  <Icon
                                    icon="it-pencil"
                                    color="primary"
                                    className={showFormInserimento ? 'disabled' : ''}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </Table>
            </>
          )}
        </div>

        {showFormInserimento &&
          detailStatus === STATUS_FULLFILLED &&
          insertStatus === STATUS_FULLFILLED && (
            <Callout className="au-callout col-md-12 mt-5">
              <CalloutTitle>
                {inModifica
                  ? 'Modifica Titolo #' + (selectedElement! + 1)
                  : 'Nuovo Titolo di studio'}
              </CalloutTitle>

              <div className="form-custom">
                <div className="form-row mt-3">
                  <CustomSelect
                    label="Titolo di studio / Abilitazione"
                    name="idMacroCategoria"
                    placeholder="Indica il titolo di studio e abilitazione conseguita o in corso di conseguimento"
                    wrapperClass="form-group col-md-12 required"
                    options={
                      inModifica
                        ? titoloOptions
                        : elencoIstruzioneFormazione.find((value) => value.idMacroCategoria === 1)
                          ? titoloOptions.filter((f) => {
                              return f.value !== 1;
                            })
                          : titoloOptions
                    }
                    value={form.values.idMacroCategoria}
                    onChange={(e) => {
                      handleSelectChange('idMacroCategoria', e, true, true);
                    }}
                    invalid={!!getFieldError(form, 'idMacroCategoria')}
                    infoText={t(getFieldError(form, 'idMacroCategoria')) || ''}
                  />
                  <CustomSelect
                    label={
                      elencoTitoliDiStudioMacroCategorie.find(
                        (value) => form.values.idMacroCategoria === value.id
                      )?.tipoDominioSelezione ==
                      TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum.Ambito
                        ? 'Ambito'
                        : 'Dettaglio titolo di studio'
                    }
                    name="idDettaglio"
                    placeholder={
                      elencoTitoliDiStudioMacroCategorie.find(
                        (value) => form.values.idMacroCategoria === value.id
                      )?.tipoDominioSelezione ==
                      TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum.Ambito
                        ? 'Indica l’ambito del titolo di studio conseguito'
                        : 'Indica il dettaglio del titolo di studio conseguito'
                    }
                    wrapperClass={
                      'form-group col-md-12 ' +
                      (elencoTitoliDiStudioMacroCategorie.find(
                        (value) => value.id === form.values.idMacroCategoria
                      )?.previstoDominioSelezione && 'required')
                    }
                    disabled={
                      elencoTitoliDiStudioMacroCategorie.find(
                        (value) => form.values.idMacroCategoria === value.id
                      )?.previstoDominioSelezione === false
                    }
                    options={dettaglioTitoloOptions}
                    value={form.values.idDettaglio}
                    onChange={(e) => handleSelectChange('idDettaglio', e)}
                    invalid={!!getFieldError(form, 'idDettaglio')}
                    infoText={t(getFieldError(form, 'idDettaglio')) || ''}
                  />
                </div>
                <div className="form-row display-flex align-items-center">
                  {form.values.idMacroCategoria !== 1 && form.values.idMacroCategoria !== 2 ? (
                    <Input
                      label={printLabelDataConseguimento()}
                      type="date"
                      placeholder="Indica la data di conseguimento del titolo di studio"
                      id="dataConseguimento"
                      wrapperClassName="form-group col-md-6 required"
                      max={new Date().toISOString().slice(0, 10)}
                      name="dataConseguimento"
                      value={form.values.dataConseguimento}
                      disabled={props.disabled}
                      onChange={form.handleChange}
                      invalid={!!getFieldError(form, 'dataConseguimento')}
                      infoText={t(getFieldError(form, 'dataConseguimento')) || ''}
                    />
                  ) : (
                    <Input
                      label="Anno conseguimento"
                      type="text"
                      placeholder="Indica l'anno di conseguimento del titolo di studio"
                      id="dataConseguimento"
                      wrapperClassName="form-group col-md-6 required"
                      max={new Date().toISOString().slice(0, 10)}
                      name="dataConseguimento"
                      value={form.values.dataConseguimento}
                      disabled={props.disabled}
                      onChange={(e) => handleChangeDataConseguimento(e)}
                      invalid={!!getFieldError(form, 'dataConseguimento')}
                      infoText={t(getFieldError(form, 'dataConseguimento')) || ''}
                    />
                  )}
                </div>
                <div className="form-row display-flex align-items-center">
                  {form.values.idMacroCategoria === 10 && (
                    <>
                      <Input
                        label={"Data iscrizione all'albo"}
                        type="date"
                        placeholder="Indica la data di iscrizione all'albo"
                        id="dataIscrizioneAlbo"
                        wrapperClassName="col-md-6"
                        max={new Date().toISOString().slice(0, 10)}
                        name="dataIscrizioneAlbo"
                        value={form.values.dataIscrizioneAlbo}
                        disabled={props.disabled}
                        onChange={form.handleChange}
                        invalid={!!getFieldError(form, 'dataIscrizioneAlbo')}
                        infoText={t(getFieldError(form, 'dataIscrizioneAlbo')) || ''}
                      />
                      <FormGroup check className="form-group col-md-12 check-esame-di-stato">
                        <Input
                          type="checkbox"
                          id="esameDiStato"
                          name="esameDiStato"
                          checked={form.values.esameDiStato}
                          onChange={form.handleChange}
                          invalid={!!getFieldError(form, 'esameDiStato')}
                          infoText={t(getFieldError(form, 'esameDiStato')) || ''}
                        />
                        <Label for={'esameDiStato'} check>
                          Esame di Stato
                        </Label>
                      </FormGroup>
                    </>
                  )}
                  {form.values.idMacroCategoria === 10 && form.values.esameDiStato && (
                    <Input
                      label={'Data esecuzione esame di Stato'}
                      type="date"
                      placeholder="Indica la data di esecuzione dell'esame di Stato"
                      id="dataEsecuzioneEsameDiStato"
                      wrapperClassName={form.values.esameDiStato ? 'col-md-6 required' : 'col-md-6'}
                      max={new Date().toISOString().slice(0, 10)}
                      name="dataEsecuzioneEsameDiStato"
                      value={form.values.dataEsecuzioneEsameDiStato}
                      disabled={props.disabled}
                      onChange={form.handleChange}
                      invalid={!!getFieldError(form, 'dataEsecuzioneEsameDiStato')}
                      infoText={t(getFieldError(form, 'dataEsecuzioneEsameDiStato')) || ''}
                    />
                  )}
                </div>
                <div className="form-row">
                  <TextareaInfoChars
                    className=""
                    label="Specificare"
                    placeholder="Indica la specifica testuale relativa al titolo non presente in lista"
                    id="specificare"
                    wrapperClassName={
                      'form-group col-md-12 ' + (isNotRequired() === false && 'required')
                    }
                    name="specificare"
                    maxLength={300}
                    disabled={
                      elencoTitoliDiStudioMacroCategorie.find(
                        (value) => value.id === form.values.idMacroCategoria
                      )?.classe === 1
                    }
                    value={
                      elencoTitoliDiStudioMacroCategorie.find(
                        (value) => value.id === form.values.idMacroCategoria
                      )?.classe === 1
                        ? ''
                        : form.values.specificare
                    }
                    onChange={form.handleChange}
                    invalid={!!getFieldError(form, 'specificare')}
                    infoText={
                      t(
                        'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                      ) || ''
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mb-2">
                {elencoIstruzioneFormazione && elencoIstruzioneFormazione.length > 0 && (
                  <Button
                    color="secondary"
                    className="mr-3"
                    onClick={() => {
                      setSelectedElement(undefined);
                      setShowFormInserimento(false);
                      setCanAggiungiEsperienza(true);
                      disabilitaBtnContinua(false);
                      if (inModifica) {
                        setInModifica(false);
                      }
                      props.scrollAction();
                      form.resetForm();
                    }}
                  >
                    {t('Annulla')}
                  </Button>
                )}
                <Button color="primary" outline className="" onClick={salvaTitolo}>
                  {t('salva titolo')}
                </Button>
              </div>
            </Callout>
          )}
        <Callout className="au-callout col-md-12 mt-5" data-test="daniele">
          <CalloutTitle>Altri percorsi formativi</CalloutTitle>
          <div className="form-custom form-row mt-3">
            <TextareaInfoChars
              className=""
              label="Altri percorsi formativi"
              placeholder="Indica altri percorsi formativi"
              id="altriPercorsiFormativi"
              wrapperClassName="form-group col-md-12"
              name="altriPercorsiFormativi"
              maxLength={300}
              value={altriPercorsiFormativi}
              onChange={(e: any) => setAltriPercorsiFormativi(e.target.value)}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
          </div>
        </Callout>
        <Modal
          isOpen={isOpenModalTitolo}
          toggle={() => toggleModalEliminaTitolo(!isOpenModalTitolo)}
          labelledBy="elimina-titolo"
          centered
        >
          <ModalHeader
            toggle={() => {
              toggleModalEliminaTitolo(!isOpenModalTitolo);
            }}
            id="elimina-titolo"
          >
            Conferma rimozione
          </ModalHeader>
          <ModalBody>
            <p>Sei sicuro di voler eliminare questo titolo?</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                confermaRimuoviTitolo(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              onClick={() => {
                confermaRimuoviTitolo(true);
              }}
            >
              Si
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);

export default IstruzioneFormazioneTab;
