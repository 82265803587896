/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OptionDtoStatoPosizioneOrganizzativa
 */
export interface OptionDtoStatoPosizioneOrganizzativa {
  /**
   *
   * @type {string}
   * @memberof OptionDtoStatoPosizioneOrganizzativa
   */
  value?: OptionDtoStatoPosizioneOrganizzativaValueEnum;
  /**
   *
   * @type {string}
   * @memberof OptionDtoStatoPosizioneOrganizzativa
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OptionDtoStatoPosizioneOrganizzativa
   */
  valore: OptionDtoStatoPosizioneOrganizzativaValoreEnum;
}

export const OptionDtoStatoPosizioneOrganizzativaValueEnum = {
  Bozza: 'BOZZA',
  Inserita: 'INSERITA',
  InseritaEComunicata: 'INSERITA_E_COMUNICATA',
  Creata: 'CREATA',
  Associabile: 'ASSOCIABILE',
  Associata: 'ASSOCIATA',
  Disattivata: 'DISATTIVATA',
} as const;

export type OptionDtoStatoPosizioneOrganizzativaValueEnum =
  (typeof OptionDtoStatoPosizioneOrganizzativaValueEnum)[keyof typeof OptionDtoStatoPosizioneOrganizzativaValueEnum];
export const OptionDtoStatoPosizioneOrganizzativaValoreEnum = {
  Bozza: 'BOZZA',
  Inserita: 'INSERITA',
  InseritaEComunicata: 'INSERITA_E_COMUNICATA',
  Creata: 'CREATA',
  Associabile: 'ASSOCIABILE',
  Associata: 'ASSOCIATA',
  Disattivata: 'DISATTIVATA',
} as const;

export type OptionDtoStatoPosizioneOrganizzativaValoreEnum =
  (typeof OptionDtoStatoPosizioneOrganizzativaValoreEnum)[keyof typeof OptionDtoStatoPosizioneOrganizzativaValoreEnum];
