import { Button, Input } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { AnagraficaRuoloInsertRequestDto } from '../../services/ms-anagrafica-unica';
import { codiceRuoloEsistente, insertRuolo, resetInputForm } from '../../store/ruoloSlice';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate } from 'react-router-dom';
import { HREF_ELENCO_RUOLI } from '../../components/layout/sidemenu/sidemenuConstants';
import PageHeader from '../../components/common/page-header/PageHeader';

const initialValues: AnagraficaRuoloInsertRequestDto = {
  nome: '',
  descrizione: '',
  codice: '',
};

const NuovoRuolo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { insertStatus, codicePresenteResult } = useAppSelector((state) => state.ruolo);

  useEffect(() => {
    dispatch(resetInputForm());
  }, [0]);

  const validationSchema = z
    .object({
      codice: z.string({ required_error: 'required' }),
      nome: z.string({ required_error: 'required' }).max(50, 'lenghtnomeruolo'),
      descrizione: z.string({ required_error: 'required' }).max(150, 'lenghtdescrizioneruolo'),
    })
    .refine(
      (data) => {
        dispatch(codiceRuoloEsistente(data.codice));

        return !codicePresenteResult;
      },
      {
        message: 'codiceruolopresente',
        path: ['codice'],
      }
    );

  const form: FormikProps<AnagraficaRuoloInsertRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: (values) => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      doInsert();
    },
  });

  const doInsert = () => {
    dispatch(insertRuolo(form.values));
  };

  const getFieldError = (
    form: FormikProps<AnagraficaRuoloInsertRequestDto>,
    fieldName: keyof AnagraficaRuoloInsertRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_RUOLI} title={t('nuovo ruolo')!} />
      <div className="mt-3 form-custom form-inserimento form-bg border">
        <Input
          type="text"
          label="Codice"
          placeholder="Inserisci il codice"
          id="input-codice"
          name="codice"
          value={form.values.codice}
          onChange={form.handleChange}
          wrapperClassName="form-group required col-md-12"
          invalid={!!getFieldError(form, 'codice')}
          infoText={t(getFieldError(form, 'codice')) || ''}
        />

        <Input
          type="text"
          label="Ruolo"
          placeholder="Inserisci il ruolo"
          id="input-nome"
          name="nome"
          value={form.values.nome}
          onChange={form.handleChange}
          wrapperClassName="form-group required col-md-12"
          invalid={!!getFieldError(form, 'nome')}
          infoText={t(getFieldError(form, 'nome')) || ''}
        />

        <Input
          type="text"
          label="Descrizione"
          placeholder="Inserisci la descrizione"
          id="input-descrizione"
          name="descrizione"
          value={form.values.descrizione}
          onChange={form.handleChange}
          wrapperClassName="form-group required col-md-12"
          invalid={!!getFieldError(form, 'descrizione')}
          infoText={t(getFieldError(form, 'descrizione')) || ''}
        />
      </div>
      <div className="d-flex flex-row-reverse mt-2">
        <Button
          color="primary"
          className="mt-2 mr-2"
          onClick={() => {
            form.submitForm();
          }}
        >
          Salva
        </Button>
        <Button
          color="secondary"
          outline
          className="mt-2 mr-2"
          onClick={() => {
            navigate(`${HREF_ELENCO_RUOLI}`);
          }}
        >
          {t('annulla')}{' '}
        </Button>
      </div>
    </div>
  );
};

export default NuovoRuolo;
