import { AlbiRuoliLicenzeRidottiDto, RuoloRidottoDto } from '../../../services/ms-anagrafica-unica';
import React from 'react';
import { Table } from 'design-react-kit';

const RIAlbiComponent = (dati: { value: AlbiRuoliLicenzeRidottiDto }) => {
  return (
    dati.value && (
      <div className={'col-12 pt-5'}>
        <Table striped responsive>
          <thead className={'primary-bg text-white'}>
            <tr>
              <th>Tipo</th>
              <th>Numero</th>
              <th>Ente rilascio</th>
              <th>Data</th>
              <th>Provincia</th>
            </tr>
          </thead>
          <tbody>
            {(dati.value['ruoli-ridotti']?.['ruolo-ridotto'] ?? []).map((x: RuoloRidottoDto) => (
              <tr key={'ruolo-' + x.tipo}>
                <td>{x.tipo ?? '-'}</td>
                <td>{x.n ?? '-'}</td>
                <td>{x['ente-rilascio'] ?? '-'}</td>
                <td>{x.data ?? '-'}</td>
                <td>{x.provincia ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  );
};

export default RIAlbiComponent;
