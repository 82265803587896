import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  CAP,
  CITTA,
  CODICE,
  DATA_INIZIO_VALIDITA,
  DECLARATORIA,
  DENOMINAZIONE,
  DIPARTIMENTO,
  EMAIL,
  EMAIL_2,
  INDIRIZZO,
  PEC,
  PEC_2,
  TELEFONO,
  TELEFONO_2,
  TIPOLOGIA_STRUTTURA,
} from './struttureOrganizzativeConstants';
import {
  detailsStruttureOrganizzative,
  disattivazioneStruttura,
  resetUpdateForm,
} from '../../store/strutturaOrganizzativaSlice';
import { FormikProps, useFormik } from 'formik';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail/StruttureOrganizzativeDetails';
import { Button, Input } from 'design-react-kit';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { SODeactiveRequestDto } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import PageHeader from '../../components/common/page-header/PageHeader';

const initialValues: SODeactiveRequestDto = {
  dataFineValidita: undefined,
  requestType: 'Deactive',
  idFile: undefined,
};

const schema = z.object({
  dataFineValidita: z.coerce.date().max(new Date('9999-12-31'), { message: 'Invalid' }),
  idFile: z.number({ required_error: 'required' }),
});

const DisattivazioneStrutturaOrganizzativa = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const updateStatus = useAppSelector((state) => state.strutturaOrganizzativa.updateStatus);
  const fieldUsed: Array<string> = [
    CODICE,
    DENOMINAZIONE,
    DECLARATORIA,
    DATA_INIZIO_VALIDITA,
    TIPOLOGIA_STRUTTURA,
    DIPARTIMENTO,
    EMAIL,
    EMAIL_2,
    PEC,
    PEC_2,
    TELEFONO,
    TELEFONO_2,
    INDIRIZZO,
    CITTA,
    CAP,
  ];
  const strutturaOrganizzativa = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsResult
  );
  const strutturaOrganizzativaResult = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsStatus
  );

  useEffect(() => {
    dispatch(resetUpdateForm());
    getDetailsFromApi(id!);
  }, [0]);

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  };

  const formData: FormikProps<SODeactiveRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      deactivateStruttura();
    }
  });

  const deactivateStruttura = () => {
    const body = {
      idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
      bodyRequest: formData.values,
    };

    dispatch(disattivazioneStruttura(body));
  };

  const getFieldError = (
    form: FormikProps<SODeactiveRequestDto>,
    fieldName: keyof SODeactiveRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const setDocumentId = (name: string, id: number | undefined) => {
    if (id) {
      formData.setFieldValue(name, id, false);
    } else {
      formData.setFieldValue('idFile', undefined);
    }
  };

  const submit = () => {
    formData.submitForm();
  };

  useEffect(() => {
    console.log(formData.errors);
  }, [formData.errors]);

  useEffect(() => {
    if (updateStatus === STATUS_FULLFILLED) {
      navigate(HREF_ELENCO_STRUTTURE_ORGANIZZATIVE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}
        state={{ form: true }}
        title={t('disattivazione struttura organizzativa')!}
      ></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && (
        <div>
          <div className="postion-relative">
            <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
            <div className="col-sm-12">
              <div className="form-row form-custom form-inserimento">
                <div className="col-12 mb-4">
                  <DocumentUpload
                    invalid={!!getFieldError(formData, 'idFile')}
                    infoText={t(getFieldError(formData, 'idFile')) || ''}
                    setDocumentId={(id) => setDocumentId('idFile', id)}
                    idDocumento={formData.values.idFile}
                    documentName={t('disattivazione struttura documento da allegare')}
                    className="my-3 required"
                  />
                </div>

                <Input
                  type="date"
                  label="Data fine Validità"
                  placeholder="Inserisci la data di fine Validità"
                  id="input-dataFineValidita"
                  name="dataFineValidita"
                  value={formData.values.dataFineValidita}
                  onChange={formData.handleChange}
                  wrapperClassName="form-group required col-md-12"
                  invalid={!!getFieldError(formData, 'dataFineValidita')}
                  infoText={t(getFieldError(formData, 'dataFineValidita')) || ''}
                />
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <Button color="primary" className="mt-2" onClick={() => submit()}>
                {t('conferma')}
              </Button>
              <Button
                color="secondary"
                outline
                className="mt-2 mr-2"
                onClick={() =>
                  navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`, { state: { form: true } })
                }
              >
                {t('annulla')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisattivazioneStrutturaOrganizzativa;
