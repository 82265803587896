import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getBandiConcorsiStakeholder, loadOptions } from '../../store/stakeholderSlice';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  Col,
  Icon,
  Table,
} from 'design-react-kit';
import PageHeader from '../../components/common/page-header/PageHeader';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { AreaPersonaleControllerApiElencoBandiRequest } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import Pagination from '../../components/common/pagination/Pagination';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

const BandiConcorsi = () => {
  type Direction = 'ASC' | 'DESC';

  interface SortingState {
    direction: Direction;
  }

  const initialValues: AreaPersonaleControllerApiElencoBandiRequest = {};

  const schema = z.object({
    categoria: z.number().optional(),
  });

  const [formCollapseOpen, setFormCollapseOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
  const { formsResult, bandiConcorsiStatus, bandiConcorsiResult } = useAppSelector(
    (state) => state.stakeholder
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadOptions());
    dispatch(getBandiConcorsiStakeholder(initialValues));
  }, []);

  let categoriaOptions: CustomSelectItem[] = [];
  formsResult?.categorie?.forEach((s) => {
    categoriaOptions.push({ label: s.label, value: s.valore });
  });

  let hasResults =
    !!bandiConcorsiResult && bandiConcorsiResult.totalElements
      ? bandiConcorsiResult.totalElements > 0
      : false;

  const searchForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      dispatch(getBandiConcorsiStakeholder(values));
    },
  });

  const handleChange = (name: string, selectedOption: any) => {
    searchForm.setFieldValue(name, selectedOption);
  };

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const handlePageChange = (pageNum: number) => {
    // setCurrentPage(pageNum);
    searchForm.setFieldValue('pageNum', pageNum - 1);
    // doSearch();
    searchForm.handleSubmit();
  };

  return (
    <div>
      <PageHeader showIcon={false} title={t('bandi e concorsi')!} buttons={[]} />
      <Accordion background="active" className="form-custom form-bg filtri-ricerca">
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="form-row mt-2">
            <Col md="12">
              <CustomSelect
                label="Categoria"
                options={categoriaOptions}
                value={searchForm.values.categoria}
                placeholder="Seleziona categoria"
                onChange={(e) => handleChange('categoria', e)}
                invalid={!!searchForm.errors.categoria}
                infoText={t(searchForm.errors.categoria || '') || ''}
                name="categoria"
              />
            </Col>
          </div>

          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                searchForm.setFieldValue('pageNum', 0);
                searchForm.handleSubmit();
              }}
            >
              Applica
            </Button>
            <Button outline color="primary" className="mt-2 mr-2" onClick={searchForm.handleReset}>
              Pulisci
            </Button>
          </div>
        </AccordionBody>
      </Accordion>
      <div className="mt-2 position-relative">
        {bandiConcorsiStatus === STATUS_FULLFILLED && !hasResults && (
          <Alert color="info">Nessun risultato disponibile</Alert>
        )}

        {bandiConcorsiStatus === STATUS_FULLFILLED && hasResults && (
          <div className={bandiConcorsiResult?.totalPages === 1 ? 'mb-5' : ''}>
            <Table striped responsive size="sm">
              <TableTheadCustom
                colonne={[
                  { titolo: 'Bando', nomeColonna: 'nome' },
                  { titolo: 'Categoria', nomeColonna: 'categoria' },
                  { titolo: 'Età di riferimento da', nomeColonna: 'etaRiferimentoInizio' },
                  { titolo: 'Età di riferimento a', nomeColonna: 'etaRiferimentoFine' },
                  { titolo: 'Provincia di residenza', nomeColonna: 'provincia' },
                  { titolo: 'Link' },
                ]}
                datiOrdinamento={searchForm.values}
                eseguiOrdinamento={ordinamento}
              />

              <tbody>
                {(bandiConcorsiResult?.data || []).map((x, i) => (
                  <tr key={i}>
                    <td>{x.nome}</td>
                    <td>{x.categoria}</td>
                    <td>{x.etaRiferimentoInizio!}</td>
                    <td>{x.etaRiferimentoFine!}</td>
                    <td>{x.provincia}</td>
                    <td>
                      <a href={x.riferimento!}>{x.riferimento}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              totalCount={bandiConcorsiResult?.totalElements || 0}
              siblingCount={1}
              currentPage={searchForm.values.pageNum! + 1}
              pageSize={bandiConcorsiResult?.pageSize || 10}
              onPageChange={(page: number) => {
                handlePageChange(page);
              }}
              className="justify-content-center"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BandiConcorsi;
