export const ENTE_SOTTOSCRITTORE = 'Ente sottoscrittore';

export const DATI_ENTE_SOTTOSCRITTORE = 'Dati Convenzione';
export const NOME_CONVENZIONE = 'Nome Convenzione';
export const SCOPO_CONVENZIONE = 'Scopo Convenzione';
export const DATA_DECORRENZA = 'Data Decorrenza';
export const DATA_SCADENZA = 'Data Scadenza';
export const ENTI_SOTTOSCRITTORI = 'Enti sottoscrittori';
export const STATO = 'Stato';
export const NOTE = 'Note';
export const DATI_AMMINISTRAZIONE_REGIONALE = 'Riferimenti per la Convenzione';
export const CONTATTO_AMMINISTRATIVO = 'Contatto Amministrativo';
export const CONTATTO_TECNICO = 'Contatto Tecnico';
export const RESPONSABILE = 'Responsabile';
export const STRUTTURA_REFERENTE = 'Struttura referente';

export const APPLICABILITA_CONVENZIONE = 'Applicabilità della Convenzione';
export const STRUTTURE_REGIONALI = 'Strutture';
export const MODALITA_RINNOVO = 'Modalità di rinnovo';
export const DATA_ALERT_1 = 'Data Alert 1';
export const DATA_ALERT_2 = 'Data Alert 2';
export const DATA_ALERT_3 = 'Data Alert 3';
export const CONDIZIONI_DI_RINNOVO = 'Condizioni di rinnovo';
export const EMAIL_CONTATTO = 'E-mail responsabile per il rinnovo';

export const RECESSO = 'Recesso';
export const DATA_RECESSO = 'Data Recesso';
export const MOTIVO_RECESSO = 'Motivo del Recesso';

export const DOCUMENTI_ALLEGATI = 'Documenti allegati';

export const NOTIFICHE = 'Contatti per richiesta informazione';
export const CONTATTI = 'Contatti';
export const COGNOME_NOME = 'Cognome Nome';
export const MAIL = 'Mail';
export const AZIONI = 'Azioni';

export const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$/;
