import { DatiIdentificativiDto } from '../../../services/ms-anagrafica-unica';
import { Input } from 'design-react-kit';
import React from 'react';

const RIDatiIdentificativiComponent = (dati: { value: DatiIdentificativiDto }) => {
  return (
    <div className={'row pt-5'}>
      <Input
        type="text"
        label="Partita IVA"
        disabled={true}
        value={dati.value?.['partita-iva'] ?? '-'}
        wrapperClassName="col-md-2"
      />
      <Input
        type="text"
        label="Denominazione"
        disabled={true}
        value={dati.value?.['denominazione'] ?? '-'}
        wrapperClassName="col-md-4"
      />
      <Input
        type="text"
        label="Forma Giuridica"
        disabled={true}
        value={dati.value?.['forma-giuridica']?.value ?? '-'}
        wrapperClassName="col-md-4"
      />
      <Input
        type="text"
        label="CCIAA"
        disabled={true}
        value={dati.value?.['cciaa'] ?? '-'}
        wrapperClassName="col-md-2"
      />
      <Input
        type="text"
        label="Data Atto Costituzione"
        disabled={true}
        value={dati.value?.['dt-atto-costituzione'] ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Tipo Impresa"
        disabled={true}
        value={dati.value?.['tipo-impresa'] ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Data Iscrizione REA"
        disabled={true}
        value={dati.value?.['dt-iscrizione-rea'] ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Numero REA"
        disabled={true}
        value={dati.value?.['n-rea'] ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Indirizzo"
        disabled={true}
        value={
          dati.value?.['indirizzo-localizzazione']?.toponimo +
            ' ' +
            dati.value?.['indirizzo-localizzazione']?.via +
            ', ' +
            dati.value?.['indirizzo-localizzazione']?.['n-civico'] ?? '-'
        }
        wrapperClassName="col-md-4"
      />
      <Input
        type="text"
        label="Comune"
        disabled={true}
        value={dati.value?.['indirizzo-localizzazione']?.comune ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Provincia"
        disabled={true}
        value={dati.value?.['indirizzo-localizzazione']?.provincia ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="CAP"
        disabled={true}
        value={dati.value?.['indirizzo-localizzazione']?.cap ?? '-'}
        wrapperClassName="col-md-2"
      />
      <Input
        type="text"
        label="PEC"
        disabled={true}
        value={dati.value?.['indirizzo-posta-certificata'] ?? '-'}
        wrapperClassName="col"
      />
    </div>
  );
};

export default RIDatiIdentificativiComponent;
