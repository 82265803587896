import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailsCesszioneDipendente } from '../../store/dipendentiRegionaliSlice';
import { HREF_ELENCO_DIPENDENTI_REG } from '../../components/layout/sidemenu/sidemenuConstants';
import { Button } from 'design-react-kit';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import CessazioneDipendenteDetailsComponent from '../../components/cessazione-dipendente-detail/CessazioneDipendenteDetailsComponent';
import PageHeader from '../../components/common/page-header/PageHeader';

const VisualizzaCessazioneDipendente = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cessazioneDipendenteResult = useAppSelector(
    (state) => state.dipendentiRegionali.detailsResult
  );
  const cessazioneDipendenteStatus = useAppSelector(
    (state) => state.dipendentiRegionali.detailsStatus
  );

  useEffect(() => {
    getDetailsFromApi();
  }, [0]);

  const getId = () => {
    return Number(id!);
  };

  const getDetailsFromApi = () => {
    let idDipendente = getId();
    dispatch(detailsCesszioneDipendente({ idDipendente }));
  };
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_DIPENDENTI_REG}
        title="Visualizza cessazione dipendente"
      ></PageHeader>
      {cessazioneDipendenteStatus === STATUS_FULLFILLED && (
        <div>
          <div className="row">
            <div className="col">
              <CessazioneDipendenteDetailsComponent detailsResult={cessazioneDipendenteResult!} />
            </div>
          </div>
          <div className="postion-relative py-5">
            <div className="d-flex flex-row-reverse">
              <Button
                color="primary"
                outline
                className="mt-2 mr-2"
                onClick={() => navigate(HREF_ELENCO_DIPENDENTI_REG)}
              >
                Indietro
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualizzaCessazioneDipendente;
