import { CSSProperties, ReactNode } from 'react';

const CampoForm = ({
  titolo,
  children,
  style = {
    height: '240px',
    width: '100%',
    border: '1px solid #06c',
    borderRadius: '12px',
  },
}: {
  titolo: string;
  children: ReactNode;
  style?: CSSProperties;
}) => (
  <div className="p-3 mb-4" style={style}>
    <h6
      style={{
        fontSize: '16px',
        color: '#06c',
      }}
    >
      {titolo}
    </h6>
    {children}
  </div>
);

export default CampoForm;
