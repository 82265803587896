import { useEffect, useState } from 'react';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import {
  getPropostaIncarico,
  getValutazioniPropostaIncarico,
  insertAssegnazioneIncarico,
} from '../../store/proposteIncarichiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input } from 'design-react-kit';
import {
  DatiAttoDto,
  InsertAssegnazioneIncarichiDirigenzialiRequestDto,
} from '../../services/ms-anagrafica-unica';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { showNotifica } from '../../store/loaderSlice';
import { NOTIFICA_STATO } from '../../utility/Notifica';
import DettaglioAtto from '../../components/dettaglio-atto-cifra/DettaglioAtto';
import { zodCifra } from '../../utility/formUtils';
import { DettaglioPropostaIncarico } from 'components/incarichi-dirigenziali/DettaglioPropostaIncarico';

const AssegnaIncarico = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { propostaSelezionataResult, propostaSelezionataStatus } = useAppSelector(
    (state) => state.proposteIncarichi
  );
  const [datiAtto, setDatiAtto] = useState<DatiAttoDto>({});
  const [codiceCifra, setCodiceCifra] = useState<string>('');

  useEffect(() => {
    dispatch(getPropostaIncarico({ id: Number(id) }))
      .unwrap()
      .then((resp) => {
        if (resp) {
          getValutazione();
          setDatiAtto(resp.assegnazioneIncarico?.datiAtto || {});
        }
      });
  }, []);

  const initialValuesUtenteRecuperato: InsertAssegnazioneIncarichiDirigenzialiRequestDto = {
    dataInizioValidita: '',
    dataFineValidita: '',
    estremiDiConferimento: '',
  };

  const schemaConferma = z
    .object({
      dataInizioValidita: z.coerce.date(),
      dataFineValidita: z.coerce.date(),
      estremiDiConferimento: zodCifra(z, true),
    })
    .refine((data) => data.dataInizioValidita < data.dataFineValidita, {
      message: 'La data di inizio validità deve essere precedente alla data di fine',
      path: ['dataInizioValidita'],
    })
    .refine((data) => data.dataInizioValidita < data.dataFineValidita, {
      message: 'La data di inizio validità deve essere precedente alla data di fine',
      path: ['dataFineValidita'],
    });

  const insertForm: FormikProps<InsertAssegnazioneIncarichiDirigenzialiRequestDto> = useFormik({
    initialValues: initialValuesUtenteRecuperato,
    validationSchema: toFormikValidationSchema(schemaConferma),
    onSubmit: (values) => {
      if (!datiAtto.oggetto) {
        dispatch(
          showNotifica({
            titolo: 'ATTENZIONE',
            messaggio: 'Non è possibile proseguire: atto non presente. Caricare un atto valido!',
            tipo: NOTIFICA_STATO.warning,
          })
        );
      } else {
        console.log('Submit form', JSON.stringify(values, null, 2));
        values = { ...values, datiAtto: { ...datiAtto, codiceAtto: values.estremiDiConferimento } };
        dispatch(
          insertAssegnazioneIncarico({
            idProposta: Number(id),
            insertAssegnazioneIncarichiDirigenzialiRequestDto: values,
          })
        );
      }
    },
  });

  const getFieldError = (
    form: FormikProps<InsertAssegnazioneIncarichiDirigenzialiRequestDto>,
    fieldName: keyof InsertAssegnazioneIncarichiDirigenzialiRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] ?? '';
    } else return '';
  };

  const getValutazione = () => {
    dispatch(getValutazioniPropostaIncarico({ id: Number(id) }));
  };

  const setDateInput = (field: string, e: any) => {
    insertForm.setFieldValue(field, e.target.value);
  };

  const onChangeCodiceAtto = (el: any) => {
    insertForm.handleChange(el);
    setCodiceCifra(el.target.value);
    setDatiAtto({});
  };

  const notFoundDatiAtto = () => {
    dispatch(
      showNotifica({
        titolo: 'ATTENZIONE',
        messaggio: 'Nessun atto associato al codice inserito!',
        tipo: NOTIFICA_STATO.warning,
      })
    );
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI}
        title={'assegnaIncarico.titolo'}
      />
      {propostaSelezionataStatus === STATUS_FULLFILLED && !!propostaSelezionataResult && (
        <>
          <DettaglioPropostaIncarico propostaIncarico={propostaSelezionataResult} />
          <div className="form-custom form-inserimento mt-3">
            <Input
              label="Data inizio incarico"
              type="date"
              placeholder="Inserisci la data"
              id="input-dataInizioIncarico"
              wrapperClassName="required assegna-incarico-data-inizio"
              name="dataInizioIncarico"
              min={new Date().toISOString().split('T')[0]}
              value={insertForm.values.dataInizioValidita}
              onChange={(e: any) => setDateInput('dataInizioValidita', e)}
              invalid={!!getFieldError(insertForm, 'dataInizioValidita')}
              infoText={t(getFieldError(insertForm, 'dataInizioValidita')) ?? ''}
            />
            <Input
              label="Data fine incarico"
              type="date"
              placeholder="Inserisci la data"
              id="input-dataFineIncarico"
              wrapperClassName="required"
              name="dataFineIncarico"
              min={new Date().toISOString().split('T')[0]}
              value={insertForm.values.dataFineValidita}
              onChange={(e: any) => setDateInput('dataFineValidita', e)}
              invalid={!!getFieldError(insertForm, 'dataFineValidita')}
              infoText={t(getFieldError(insertForm, 'dataFineValidita')) ?? ''}
            />
            <Input
              label="Atto (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
              type="text"
              placeholder="Estremi di conferimento"
              id="input-estremiDiConferimento"
              wrapperClassName="mb-3 required"
              name="estremiDiConferimento"
              value={insertForm.values.estremiDiConferimento}
              onChange={(e) => onChangeCodiceAtto(e)}
              invalid={!!getFieldError(insertForm, 'estremiDiConferimento')}
              infoText={t(getFieldError(insertForm, 'estremiDiConferimento')) ?? ''}
            />
            {insertForm.values.estremiDiConferimento &&
              !insertForm.errors.estremiDiConferimento && (
                <Col md="12 mt-3 mb-4">
                  <DettaglioAtto
                    codiceCifra={codiceCifra}
                    datiAtto={datiAtto}
                    setDatiAtto={(e) => setDatiAtto(e)}
                    notFoundDatiAtto={() => notFoundDatiAtto()}
                  ></DettaglioAtto>
                </Col>
              )}
          </div>
          <div className="d-flex flex-row-reverse">
            <Button color="primary" className="mt-2 mr-2" onClick={insertForm.submitForm}>
              Conferma
            </Button>
            <Button
              color="secondary"
              className="mt-2 mr-2"
              onClick={() => {
                navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`);
              }}
            >
              {t('annulla')}{' '}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AssegnaIncarico;
