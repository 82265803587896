export interface OnBoardingConcorsoDto {
  classeConcorso?: string;
  nomeConcorso?: string;
  stato: OnboardingConcorsoStato;
  id?: number;
  idConcorso?: number;
  tipologiaContratto: string;
  attiOnboarding?: Array<AttoOnboardingConcorsoDto>;
  categoria?: string;
  livello?: string;
  areaProfessionale: string;
  modalitaSelezione: string;
  note?: string;
  sedeLavoro?: string;
  sedeContrattuale?: string;
  sedeEffettiva?: string;
  dataAssunzione?: string;
  dataCessazione?: string;
  posizioneGraduatoria?: string;
  parTime?: boolean;
  tipoPartime?: string;
  partTimeDataDa?: string;
  partTimeDataA?: string;
  numeroGiorni?: string;
  numeroOre?: string;
  militare?: boolean;
  comando?: boolean;
  comandoDataDa?: string;
  comandoDataA?: string;
  disabile?: boolean;
  percentualeDisabilita?: number;
  nullaOstaUc?: boolean;
  documenti?: Array<DocumentoUploaded>;
  accettazioneAssunzioneConcorso?: AccettazioneAssunzioneConcorsoDto;
  vincitore?: boolean;
  idoneo?: boolean;
}

export interface AccettazioneAssunzioneConcorsoDto {
  id?: number;
  rinuncia: boolean;
  motivazioneRinuncia?: string;
  documentiDisabilita?: DocumentoUploaded;
  trattamentoDatiPersonali?: boolean;
  codiceComportamentoArpal?: boolean;
  rinunciaPerseo?: boolean;
  dichiarazioneAttivitaLavorativa?: string;
  accreditamentoCompetenze?: AccreditamentoCompetenzeDto;
}

export interface AccreditamentoCompetenzeDto {
  tipoAccreditamento: string;
  iban: string;
}

export interface DocumentoUploaded {
  documentoDto: DocumentoUploadedDto;
  tipo?: string;
  descrizioneDocumento?: string;
}

export interface DocumentoUploadedDto {
  id: string;
  filename: string;
  path: string;
  nomeDocumento: string | null;
  dataInserimento: string;
  stato: string | null;
  dimensione: string;
  contentType: string;
}

export interface AttoOnboardingConcorsoDto {
  tipoAtto: string;
  oggettoAtto: string;
  dataAtto: string;
  numeroAtto: string;
  file: File;
}

export enum OnboardingConcorsoStato {
  INC = 'INC',
  COM = 'COM',
  REG = 'REG',
  VAL = 'VAL',
  RIF = 'RIF',
  ACC = 'ACC',
  CES = 'CES',
  CEN = 'CEN',
  RET = 'RET',
}

export const OnboardingConcorsoStatoMapper: {
  [key in keyof typeof OnboardingConcorsoStato]: string;
} = {
  INC: 'In Compilazione',
  COM: 'Compilato',
  REG: 'Registrato',
  VAL: 'Validato',
  RIF: 'Rifiutato',
  ACC: 'Accettato',
  CES: 'Cessato',
  CEN: 'Censito',
  RET: 'Rettificato',
};

export const getStatoCandidato = (stato: OnboardingConcorsoStato): string => {
  const onboardingKey = stato as keyof typeof OnboardingConcorsoStatoMapper;
  return OnboardingConcorsoStatoMapper[onboardingKey];
};

// Usage example
const stato = OnboardingConcorsoStato.INC;
console.log(getStatoCandidato(stato));

export const NuoveAssunzioniInsertUpdateRequestDtoCategoriaEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  Aro: 'ARO',
  Are: 'ARE',
  Ari: 'ARI',
  Arf: 'ARF',
  Cd: 'CD',
  Sz: 'SZ',
  Sr: 'SR',
  Altro: 'ALTRO',
} as const;

export type NuoveAssunzioniInsertUpdateRequestDtoCategoriaEnum =
  (typeof NuoveAssunzioniInsertUpdateRequestDtoCategoriaEnum)[keyof typeof NuoveAssunzioniInsertUpdateRequestDtoCategoriaEnum];
export const NuoveAssunzioniInsertUpdateRequestDtoModalitaSelezioneEnum = {
  Con: 'CON',
  Chd: 'CHD',
  Mob: 'MOB',
} as const;

export type NuoveAssunzioniInsertUpdateRequestDtoModalitaSelezioneEnum =
  (typeof NuoveAssunzioniInsertUpdateRequestDtoModalitaSelezioneEnum)[keyof typeof NuoveAssunzioniInsertUpdateRequestDtoModalitaSelezioneEnum];
export const NuoveAssunzioniInsertUpdateRequestDtoTipoContrattoEnum = {
  Pt: 'PT',
  Ind: 'IND',
  Det: 'DET',
  IndPt: 'IND_PT',
  DetDg: 'DET_DG',
} as const;

export type NuoveAssunzioniInsertUpdateRequestDtoTipoContrattoEnum =
  (typeof NuoveAssunzioniInsertUpdateRequestDtoTipoContrattoEnum)[keyof typeof NuoveAssunzioniInsertUpdateRequestDtoTipoContrattoEnum];
export const NuoveAssunzioniInsertUpdateRequestDtoLivelloEnum = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A4: 'A4',
  A5: 'A5',
  A6: 'A6',
  B1: 'B1',
  B2: 'B2',
  B3: 'B3',
  B4: 'B4',
  B5: 'B5',
  B6: 'B6',
  B7: 'B7',
  B8: 'B8',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  C5: 'C5',
  C6: 'C6',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  D4: 'D4',
  D5: 'D5',
  D6: 'D6',
  D7: 'D7',
} as const;

export type NuoveAssunzioniInsertUpdateRequestDtoLivelloEnum =
  (typeof NuoveAssunzioniInsertUpdateRequestDtoLivelloEnum)[keyof typeof NuoveAssunzioniInsertUpdateRequestDtoLivelloEnum];
export const NuoveAssunzioniInsertUpdateRequestDtoAreaProfessionaleEnum = {
  Are: 'ARE',
  Arf: 'ARF',
  Ari: 'ARI',
  Aro: 'ARO',
} as const;

export type NuoveAssunzioniInsertUpdateRequestDtoAreaProfessionaleEnum =
  (typeof NuoveAssunzioniInsertUpdateRequestDtoAreaProfessionaleEnum)[keyof typeof NuoveAssunzioniInsertUpdateRequestDtoAreaProfessionaleEnum];
export const NuoveAssunzioniInsertUpdateRequestDtoRequestTypeEnum = {
  Salva: 'SALVA',
  Conferma: 'CONFERMA',
} as const;

export type NuoveAssunzioniInsertUpdateRequestDtoRequestTypeEnum =
  (typeof NuoveAssunzioniInsertUpdateRequestDtoRequestTypeEnum)[keyof typeof NuoveAssunzioniInsertUpdateRequestDtoRequestTypeEnum];
