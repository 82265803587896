export const CODICE = 'Codice';
export const DENOMINAZIONE = 'Denominazione';
export const DECLARATORIA = 'Declaratoria';
export const DATA_INIZIO_VALIDITA = 'Data Inizio Validità';
export const TIPOLOGIA_STRUTTURA = 'Tipologia struttura';
export const DIPARTIMENTO = 'Unità organizzativa';
export const SEZIONE = 'Area/Cpi/Ufficio';
export const EMAIL = 'E-mail';
export const EMAIL_2 = 'E-mail secondaria';
export const PEC = 'PEC';
export const PEC_2 = 'PEC secondaria';
export const TELEFONO = 'Telefono';
export const TELEFONO_2 = 'Telefono secondario';
export const FAX = 'Fax';
export const INDIRIZZO = 'Indirizzo';
export const CITTA = 'Città';
export const CAP = 'CAP';
export const CODICE_CIFRA_DETERMINA = 'Codice CIFRA Determina';
export const CODICE_CIFRA_DELIBERA = 'Codice CIFRA Delibera';
export const CODICE_IPA = 'Codice IPA';
export const CODICE_DIPA_RAGIONERIA = 'Codice Bilancio Dipartimento';
export const CODICE_SEZI_RAGIONERIA = 'Codice Bilancio Sezione';
export const CODICE_COMUNE_SEDE = 'Codice Comune Sede';
export const NOME_COGNOME_DIRETTORE = 'Nome Cognome Direttore Sede ';
export const EMAIL_DIRETTORE = 'E-mail Direttore Sede';
export const ELENCO_DIPENDENTI = 'Elenco dipendenti';
export const CAP_SEDE = 'CAP Sede';
export const UNITA_ORGANIZZATIVA = 'Unità organizzativa';
export const AREA_CPI_UFFICIO = 'Area/Cpi/Ufficio';
