import { Col, FormGroup, Input, Label, Row, Select } from 'design-react-kit';
import { useEffect, useState } from 'react';
import provincie from '../../assets/json/province.json';
import comuni from '../../assets/json/comuni.json';
import AccordionElement from '../accordion-element/AccordionElement';
import { useCompositeForm } from '../../hooks/useCompositeForm';

export interface IFormDomicilio {
  domicilioDifferente: 'Si' | 'No';
  statoDomicilio?: string;
  provinciaDomicilio?: string;
  cittaDomicilio?: string;
  indirizzoDomicilio?: string;
  capDomicilio?: string;
}

const FormDomicilio = ({ readingOnly }: { readingOnly?: boolean }) => {
  const { values, handleChange, setFieldValue, errors, touched, handleBlur } = useCompositeForm();

  const statiOptions = [
    { value: 'Italia', label: 'Italia' },
    { value: 'Altro', label: 'Altro' },
  ];
  const provinceOptions = provincie.map((item) => {
    return {
      value: item.sigla_automobilistica,
      label: item.nome,
    };
  });
  const [cittaOptions, setCittaOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    let selectedProvinceId = provincie.find(
      (item) => item.sigla_automobilistica === values.provinciaDomicilio
    )?.id;
    setCittaOptions(
      comuni
        .filter((item) => item.id_provincia === selectedProvinceId)
        .map((item) => {
          return {
            value: item.nome,
            label: item.nome,
          };
        })
    );
  }, []);

  function setCitiesByProvince(selectedProvince: { value: string; label: string }) {
    let selectedProvinceId = provincie.find((item) => item.nome === selectedProvince.label)?.id;
    console.log('Selected province id: ', selectedProvince);
    console.log(
      'Comuni: ',
      comuni.filter((item) => item.id_provincia === selectedProvinceId)
    );
    setCittaOptions(
      comuni
        .filter((item) => item.id_provincia === selectedProvinceId)
        .map((item) => {
          return {
            value: item.nome,
            label: item.nome,
          };
        })
    );
  }

  return (
    <AccordionElement title="Dati Domicilio">
      <>
        <Row>
          <Col md={12} className="px-1">
            <Label for="domicilioDifferente">Domicilio Differente</Label>
            <FormGroup>
              <Select
                id="domicilioDifferente"
                name="domicilioDifferente"
                value={{
                  value: values.domicilioDifferente,
                  label: values.domicilioDifferente,
                }}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  if (option) {
                    setFieldValue('domicilioDifferente', option.value);
                    setFieldValue('statoDomicilio', option.value === 'No' ? '' : 'Italia');
                  }
                }}
                aria-describedby="domicilioDifferenteError"
                placeholder="Seleziona"
                isDisabled={readingOnly}
              />
              {touched.domicilioDifferente && errors.domicilioDifferente && (
                <div id="domicilioDifferenteError" className="invalid-feedback">
                  {errors.domicilioDifferente}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        {values.domicilioDifferente === 'Si' && (
          <>
            <Row>
              <Col md={4} className="px-1">
                <Label for="statoDomicilio">Stato Domicilio</Label>
                <FormGroup>
                  <Select
                    id="statoDomicilio"
                    name="statoDomicilio"
                    value={{
                      value: values.statoDomicilio,
                      label: values.statoDomicilio,
                    }}
                    options={statiOptions}
                    onChange={(option) => {
                      if (option) {
                        setFieldValue('statoDomicilio', option.value);
                        setFieldValue('provinciaDomicilio', '');
                        setFieldValue('cittaDomicilio', '');
                      }
                    }}
                    aria-describedby="statoDomicilioError"
                    placeholder="Seleziona Stato"
                    isDisabled={readingOnly}
                  />
                  {touched.statoDomicilio && errors.statoDomicilio && (
                    <div id="statoDomicilioError" className="invalid-feedback">
                      {errors.statoDomicilio}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={4} className="px-1">
                <Label for="provinciaDomicilio">Provincia Domicilio</Label>
                <FormGroup>
                  {values.statoDomicilio === 'Italia' ? (
                    <Select
                      id="provinciaDomicilio"
                      name="provinciaDomicilio"
                      value={{
                        value: values.provinciaDomicilio,
                        label: values.provinciaDomicilio,
                      }}
                      options={provinceOptions}
                      onChange={(option) => {
                        if (option) {
                          setFieldValue('provinciaDomicilio', option.value);
                          /* @ts-ignore */
                          setCitiesByProvince(option);
                        }
                      }}
                      aria-describedby="provinciaDomicilioError"
                      placeholder="Seleziona Provincia"
                      isDisabled={readingOnly}
                    />
                  ) : (
                    <Input
                      type="text"
                      id="provinciaDomicilio"
                      name="provinciaDomicilio"
                      maxLength={50}
                      value={values.provinciaDomicilio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Inserisci Provincia"
                      invalid={!!(touched.provinciaDomicilio && errors.provinciaDomicilio)}
                      disabled={readingOnly}
                    />
                  )}
                  {touched.provinciaDomicilio && errors.provinciaDomicilio && (
                    <div id="provinciaDomicilioError" className="invalid-feedback">
                      {errors.provinciaDomicilio}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={4} className="px-1">
                <Label for="cittaDomicilio">Città Domicilio</Label>
                <FormGroup>
                  {values.statoDomicilio === 'Italia' ? (
                    <Select
                      id="cittaDomicilio"
                      name="cittaDomicilio"
                      value={{
                        value: values.cittaDomicilio,
                        label: values.cittaDomicilio,
                      }}
                      options={cittaOptions}
                      onChange={(option) => {
                        if (option) {
                          setFieldValue('cittaDomicilio', option.value);
                        }
                      }}
                      aria-describedby="cittaDomicilioError"
                      placeholder="Seleziona Città"
                      isDisabled={readingOnly}
                    />
                  ) : (
                    <Input
                      type="text"
                      id="cittaDomicilio"
                      name="cittaDomicilio"
                      maxLength={50}
                      value={values.cittaDomicilio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Inserisci Città"
                      invalid={!!(touched.cittaDomicilio && errors.cittaDomicilio)}
                      disabled={readingOnly}
                    />
                  )}
                  {touched.cittaDomicilio && errors.cittaDomicilio && (
                    <div id="cittaDomicilioError" className="invalid-feedback">
                      {errors.cittaDomicilio}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={8} className="px-1">
                <FormGroup>
                  <Input
                    type="text"
                    id="indirizzoDomicilio"
                    name="indirizzoDomicilio"
                    label="Indirizzo domicilio"
                    maxLength={200}
                    value={values.indirizzoDomicilio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Inserisci Indirizzo"
                    invalid={!!(touched.indirizzoDomicilio && errors.indirizzoDomicilio)}
                    disabled={readingOnly}
                  />
                  {touched.indirizzoDomicilio && errors.indirizzoDomicilio && (
                    <div id="indirizzoDomicilioError" className="invalid-feedback">
                      {errors.indirizzoDomicilio}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={4} className="px-1">
                <FormGroup>
                  <Input
                    type="text"
                    id="capDomicilio"
                    name="capDomicilio"
                    label="CAP domicilio"
                    maxLength={5}
                    value={values.capDomicilio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Inserisci CAP"
                    invalid={!!(touched.capDomicilio && errors.capDomicilio)}
                    disabled={readingOnly}
                  />
                  {touched.capDomicilio && errors.capDomicilio && (
                    <div id="capDomicilioError" className="invalid-feedback">
                      {errors.capDomicilio}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </>
    </AccordionElement>
  );
};

export default FormDomicilio;
