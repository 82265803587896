import axios from 'axios';
import type { IConcorso } from 'pages/gestione-candidati/CreaConcorso';
import { useEffect, useState } from 'react';

const useGetConcorsi = () => {
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/concorsi/lista`)
      .then((response) => {
        setConcorsi(response.data);
      })
      .catch((error) => {
        console.error('Error fetching concorsi:', error);
      });
  }, []);

  const [concorsi, setConcorsi] = useState<Array<IConcorso> | undefined>(undefined);

  return concorsi;
};

export default useGetConcorsi;
