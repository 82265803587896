import { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Select,
} from 'design-react-kit';
import { type FormikProps, useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate, useParams } from 'react-router-dom';
import InserimentoDatiAssunzioneForm, {
  type IFormInserimentoDatiAssunzioneForm,
} from '../../components/inserimento-dati-assunzione-form/InserimentoDatiAssunzioneForm';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import FormCv from 'components/FormCv/FormCv';
import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormNote from 'components/FormNote/FormNote';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import { FormReadingOnly } from 'components/forms/FormReadingOnly/FormReadingOnly';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import axios from 'axios';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { useAppDispatch } from 'hooks';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';

export interface IFormInvioConvocazioneForm {
  dataConvocazione: string;
  oraConvocazione: string;
  luogoConvocazione: string;
  testoConvocazione: string;
  documentiRichiesti: Array<any>;
}

const initialValues: IFormInvioConvocazioneForm = {
  dataConvocazione: '',
  oraConvocazione: '',
  luogoConvocazione: '',
  testoConvocazione: '',
  documentiRichiesti: [],
};

const getFieldError = (
  form: FormikProps<IFormInvioConvocazioneForm>,
  fieldName: string
): string => {
  if (form.getFieldMeta(fieldName).touched) {
    // @ts-ignore
    return form.errors[fieldName] || '';
  } else return '';
};

export const InvioConvocazioneModal = ({
  isOpen,
  closeModal,
  onSubmit,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (values: IFormInvioConvocazioneForm) => void | Promise<any>;
}) => {
  const invioConvocazioneForm = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(
      z.object({
        dataConvocazione: z
          .string({
            required_error: 'Data Convocazione è obbligatoria',
          })
          .min(1, 'Data Convocazione è obbligatoria'),
        oraConvocazione: z
          .string({
            required_error: 'Ora Convocazione è obbligatoria',
          })
          .min(1, 'Ora Convocazione è obbligatoria')
          .max(10, 'Ora Convocazione non può superare 10 caratteri'),
        luogoConvocazione: z
          .string({
            required_error: 'Luogo Convocazione è obbligatorio',
          })
          .max(50),
        testoConvocazione: z
          .string({
            required_error: 'Testo Convocazione è obbligatorio',
          })
          .max(5000),
        documentiRichiesti: z
          .array(z.object({}))
          .min(1, 'Devi selezionare almeno un documento richiesto'),
      })
    ),
    onSubmit,
  });

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Invio Convocazione</ModalHeader>
      <ModalBody>
        <Form>
          <Input
            label="Data convocazione"
            type="date"
            placeholder="Inserisci una data"
            id="input-dataConvocazione"
            wrapperClassName="form-group required col-md-12"
            name="dataConvocazione"
            value={invioConvocazioneForm.values.dataConvocazione}
            onChange={invioConvocazioneForm.handleChange}
            onBlur={invioConvocazioneForm.handleBlur}
            invalid={!!getFieldError(invioConvocazioneForm, 'dataConvocazione')}
            infoText={getFieldError(invioConvocazioneForm, 'dataConvocazione')}
          />
          <Input
            label="Ora Convocazione"
            type="text"
            id="input-oraConvocazione"
            name="oraConvocazione"
            placeholder="Inserisci ora convocazione"
            wrapperClassName="form-group required col-md-12"
            value={invioConvocazioneForm.values.oraConvocazione}
            onChange={invioConvocazioneForm.handleChange}
            onBlur={invioConvocazioneForm.handleBlur}
            invalid={!!getFieldError(invioConvocazioneForm, 'oraConvocazione')}
            infoText={getFieldError(invioConvocazioneForm, 'oraConvocazione')}
          />
          <Input
            label="Luogo Convocazione"
            type="text"
            placeholder="Inserisci luogo convocazione"
            id="input-oggettoLuogoConvocazione"
            wrapperClassName="form-group required col-md-12"
            name="luogoConvocazione"
            value={invioConvocazioneForm.values.luogoConvocazione}
            onChange={invioConvocazioneForm.handleChange}
            onBlur={invioConvocazioneForm.handleBlur}
            invalid={!!getFieldError(invioConvocazioneForm, 'luogoConvocazione')}
            infoText={getFieldError(invioConvocazioneForm, 'luogoConvocazione')}
          />
          <Input
            label="Testo Convocazione"
            type="text"
            placeholder="Inserisci testo convocazione"
            id="input-testoConvocazione"
            wrapperClassName="form-group required col-md-12"
            name="testoConvocazione"
            value={invioConvocazioneForm.values.testoConvocazione}
            onChange={invioConvocazioneForm.handleChange}
            onBlur={invioConvocazioneForm.handleBlur}
            invalid={!!getFieldError(invioConvocazioneForm, 'testoConvocazione')}
            infoText={getFieldError(invioConvocazioneForm, 'testoConvocazione')}
          />
          <Label
            style={{
              fontSize: '14px',
              fontWeight: '600',
              marginLeft: '14px',
            }}
            for="documentiRichiesti"
          >
            Documenti Richiesti
          </Label>
          <FormGroup>
            <Select
              id="documentiRichiesti"
              name="documentiRichiesti"
              placeholder="Seleziona Documenti Richiesti"
              isMulti
              value={invioConvocazioneForm.values.documentiRichiesti}
              options={[
                { value: 'VERTICALE', label: 'Verticale' },
                { value: 'ORIZZONTALE', label: 'Orizzontale' },
                { value: 'MISTO', label: 'Misto' },
              ]}
              onChange={(selectedOptions) => {
                invioConvocazioneForm.setFieldValue('documentiRichiesti', selectedOptions);
              }}
            />
            <small
              className="danger"
              style={{
                color: '#f73e5a',
                fontSize: '14px',
                fontWeight: '600',
                marginLeft: '5px',
              }}
            >
              {getFieldError(invioConvocazioneForm, 'documentiRichiesti')}
            </small>
          </FormGroup>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '10px',
              paddingBottom: '15px',
            }}
          >
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                invioConvocazioneForm.submitForm();
              }}
            >
              Conferma
            </Button>
            <Button outline color="primary" onClick={closeModal} size="sm">
              Annulla
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export const RiepilogoModal = ({
  values,
  isOpen,
  closeModal,
  onSubmit,
}: {
  values?: IFormInvioConvocazioneForm;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void | Promise<any>;
}) => {
  const invioConvocazioneForm = useFormik({
    initialValues: {},
    onSubmit,
  });

  if (!values) return <></>;

  const {
    dataConvocazione,
    oraConvocazione,
    luogoConvocazione,
    testoConvocazione,
    documentiRichiesti,
  } = values;

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Riepilogo Convocazione</ModalHeader>
      <ModalBody>
        <Form>
          <Row className="mt-2">
            <div>Testo Convocazione:</div>
            <div>{testoConvocazione}</div>
          </Row>
          <Row>
            <div>Data:</div>
            <div>{dataConvocazione}</div>
          </Row>
          <Row>
            <div>Ora:</div>
            <div>{oraConvocazione}</div>
          </Row>
          <Row>
            <div>Luogo:</div>
            <div>{luogoConvocazione}</div>
          </Row>
          <Row>
            <div>Documenti richiesti:</div>
          </Row>
          <Row>
            <div></div>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '10px',
              paddingBottom: '15px',
            }}
          >
            <Button
              outline
              color="primary"
              size="sm"
              onClick={() => {
                invioConvocazioneForm.submitForm();
              }}
            >
              Chiudi
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

const InvioConvocazioneSingola = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  const [openModalInvioConvocazione, setOpenModalInvioConvocazione] = useState(false);
  const [invioConvocazioneValues, setInvioConvocazioneValues] = useState<
    undefined | IFormInvioConvocazioneForm
  >();
  const handleOpenModalInvioConvocazione = () => {
    setOpenModalInvioConvocazione(true);
  };
  const handleCloseModalInvioConvocazione = () => {
    setOpenModalInvioConvocazione(false);
  };

  const [openModalRiepilogo, setOpenModalRiepilogo] = useState(false);
  const handleOpenModalRiepilogo = () => {
    setOpenModalRiepilogo(true);
  };
  const handleCloseModalRiepilogo = () => {
    setOpenModalRiepilogo(false);
  };

  const handleDatiAssunzioneSubmit = (values: IFormInserimentoDatiAssunzioneForm) => {
    console.log('values IFormInserimentoDatiAssunzioneForm', values);

    if (!candidato?.id) {
      dispatch(
        showNotifica({
          titolo: 'Inserimento dati assunzione non è andato a buon fine',
          stato: 'error',
          messaggio: 'Inserimento dati assunzione non è andato a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }

    const updatedOnboarding = {
      ...onboarding,
      tipologiaContrattoId: values.tipologiaContrattoId,
      partTime: values.partTime,
      tipoPartTime: values.tipoPartime,
      dataAssunzione: values.dataAssunzione,
      note: values.notePersonali,
    };

    try {
      axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato.id}/assunzione`,
        {
          ...candidato,
          onboardingConcorsi: [
            {
              ...onboarding,
              ...updatedOnboarding,
              statoOnb: OnboardingConcorsoStatoOnb.CONVOCATO,
            },
          ],
        }
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Inserimento dati assunzione non è andato a buon fine',
          stato: 'error',
          messaggio: 'Inserimento dati assunzione non è andato a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }

    handleOpenModalInvioConvocazione();
  };

  const handleBackButtonSubmit = () => navigate(HREF_ELENCO_CANDIDATI);

  const handleInvioConvocazioneSubmit = (values: IFormInvioConvocazioneForm) => {
    console.log('values IFormInvioConvocazioneForm', values);
    setInvioConvocazioneValues(values);
    handleCloseModalInvioConvocazione();
    handleOpenModalRiepilogo();

    if (!candidato?.id) {
      dispatch(
        showNotifica({
          titolo: 'Invio convocazione non è andato a buon fine',
          stato: 'error',
          messaggio: 'Invio convocazione non è andato a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }

    try {
      axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/candidati/${candidato.id}/convocazione-sottoscrizione`,
        {
          dataConvocazione: values.dataConvocazione,
          oraConvocazione: values.oraConvocazione,
          luogoConvocazione: values.luogoConvocazione,
          testoConvocazione: values.testoConvocazione,
          //documentiRichiesti: Array<any>;
        }
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Invio convocazione non è andato a buon fine',
          stato: 'error',
          messaggio: 'Invio convocazione non è andato a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  const handleRiepilogoSubmit = () => {
    console.log('values handleRiepilogoSubmit');
    handleCloseModalRiepilogo();
  };

  return (
    <>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={'Invio Convocazione Singola'}
      />
      <FormReadingOnly>
        <FormDatiAnagrafici readingOnly />
        <FormEstremiIdentificazione readingOnly />
        <FormRecapiti readingOnly />
        <FormDatiResidenza readingOnly />
        <FormDomicilio readingOnly />
        <FormDatiDisabilita readingOnly />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv readingOnly />
        <FormNote readingOnly />
      </FormReadingOnly>
      <InserimentoDatiAssunzioneForm
        onSubmit={handleDatiAssunzioneSubmit}
        submitButtonTitle="Invia Convocazione"
        onBackSubmit={handleBackButtonSubmit}
      />
      <InvioConvocazioneModal
        isOpen={openModalInvioConvocazione}
        closeModal={handleCloseModalInvioConvocazione}
        onSubmit={handleInvioConvocazioneSubmit}
      />
      <RiepilogoModal
        values={invioConvocazioneValues}
        isOpen={openModalRiepilogo}
        closeModal={handleCloseModalRiepilogo}
        onSubmit={handleRiepilogoSubmit}
      />
    </>
  );
};

export default InvioConvocazioneSingola;
