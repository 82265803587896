import { Accordion, AccordionBody, AccordionHeader } from 'design-react-kit';
import { ReactNode, useState } from 'react';

interface IAccordionProps {
  title: string | ReactNode;
  children: ReactNode;
}

const AccordionElement: React.FC<IAccordionProps> = ({ title, children }: IAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Accordion background="active">
      <AccordionHeader active={isOpen} onToggle={() => setIsOpen(!isOpen)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {title}
        </div>
      </AccordionHeader>
      <AccordionBody active={isOpen}>{children}</AccordionBody>
    </Accordion>
  );
};

export default AccordionElement;
