import { Icon, Nav, NavItem, NavLink } from 'design-react-kit';
import './CustomTab.scss';

export interface CustomTabProps {
  tabOptions: ITabOption[];
  selectedTab: string;
  onItemClick: (desc: string) => void;
}

export interface ITabOption {
  icon: string;
  option: ITipologica;
}

export interface ITipologica {
  value: string;
  description: string;
}

const CustomTab = ({ tabOptions, selectedTab, onItemClick }: CustomTabProps) => {
  return (
    <>
      <Nav tabs>
        {tabOptions.map((tabOption) => (
          <NavItem
            key={tabOption.option.description}
            onClick={() => onItemClick(tabOption.option.description)}
            style={{ cursor: 'pointer' }}
          >
            <NavLink active={tabOption.option.description === selectedTab}>
              <Icon
                icon={tabOption.icon}
                aria-label={tabOption.option.description}
                size={'lg'}
              ></Icon>
              <span className={'ml-3'}> {tabOption.option.description} </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </>
  );
};

export default CustomTab;
