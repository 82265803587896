import {
  Input,
  Col,
  Row,
  FormGroup,
  Label,
  Select,
  Button,
  Icon,
  DropdownMenu,
  DropdownToggle,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import AccordionElement from '../../accordion-element/AccordionElement';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import ExplodedPdfViewer from '../../exploded-pdf-viewer/ExplodedPdfViewer';
import useConcorsiSelectOptions from '../../../hooks/useConcorsiSelectOptions';
import { useFormik } from 'formik';
import { useCompositeForm } from '../../../hooks/useCompositeForm';
import { ErrorMessage } from 'components/error-message/ErrorMessage';
import CustomSelect from 'components/custom-select/CustomSelect';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import useDownloadDocumentiDisabilita from 'hooks/services/useDownloadDocumentiDisabilita';

interface ITabellaAllegatiProps {
  documenti: Array<IDocumentoDisabilita>;
  deleteFile: (fileIndex: number) => void;
}
const TabellaAllegati: React.FC<ITabellaAllegatiProps> = ({ documenti, deleteFile }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const modalId = v4();
  const [pdfUrl, setPdfUrl] = useState<string[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);
  const concorsiOptions = useConcorsiSelectOptions();

  function viewFile(fileIndex: number) {
    setSelectedFileIndex(fileIndex);
    toggleModal();
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  useEffect(() => {
    setPdfUrl(documenti.map(({ documentoDisabilita }) => URL.createObjectURL(documentoDisabilita)));

    return () => pdfUrl.forEach((url) => URL.revokeObjectURL(url));
  }, [documenti, setPdfUrl]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Tipo Disabilità</th>
            <th scope="col">Previsione revisione</th>
            <th scope="col">Data revisione</th>
            <th scope="col">Nome file</th>
            <th scope="col">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {documenti?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {concorsiOptions.tipiDocumento.find((doc) => doc.valore === item.tipoDisabilita)
                  ?.label ?? '/'}
              </td>
              <td>{item.previsioneRevisione}</td>
              <td>{item.dataRevisione === '' || !item.dataRevisione ? '/' : item.dataRevisione}</td>
              <td>{item.documentoDisabilita.name}</td>
              <td>
                <UncontrolledDropdown direction="left">
                  <DropdownToggle nav>
                    <Icon size="sm" color="primary" icon="it-more-items" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <LinkList>
                      <LinkListItem onClick={() => viewFile(index)}>Visualizza</LinkListItem>
                      <LinkListItem onClick={() => deleteFile(index)}>Elimina</LinkListItem>
                    </LinkList>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={`modal-${modalId}`}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={`modal-${modalId}`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" icon="it-close" />
              <h2>Anteprima documento</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '70vh',
              overflowY: 'auto',
            }}
          >
            <ExplodedPdfViewer pdfFile={pdfUrl[selectedFileIndex]} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" outline className="link-underline-primary" onClick={toggleModal}>
            Annulla
          </Button>
          <Button color="primary" onClick={toggleModal}>
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

interface IDocumentoDisabilita {
  tipoDisabilita: string;
  previsioneRevisione: 'Si' | 'No';
  dataRevisione?: string;
  documentoRevisione?: File;
  documentoDisabilita: File;
}

interface IFormDocumentoDisabilita {
  tipoDisabilita: string;
  previsioneRevisione?: 'Si' | 'No';
  dataRevisione?: string;
  documentoRevisione?: File | null;
  documentoDisabilita: File | null;
}

export interface IFormDatiDisabilita {
  percentualeDisabilita?: string;
  collocamentoMirato?: 'Si' | 'No';
  fileCollocamento?: File;
  documentiDisabilita?: Array<IDocumentoDisabilita>;
}

const initialValues: IFormDocumentoDisabilita = {
  tipoDisabilita: '',
  documentoDisabilita: null,
};

export default function FormDatiDisabilita({ readingOnly }: { readingOnly?: boolean }) {
  const documentoDisabilitaRef = useRef<HTMLInputElement>(null);
  const fileCollocamentoRef = useRef<HTMLInputElement>(null);
  const documentoRevisioneRef = useRef<HTMLInputElement>(null);

  const concorsiOptions = useConcorsiSelectOptions();
  const [openModalDocumentiDisabilità, setOpenModalDocumentiDisabilità] = useState(false);
  const { values, handleChange, setFieldValue, errors, touched } = useCompositeForm();

  useDownloadDocumentiDisabilita({
    onFilesReady: (files) => {
      setFieldValue('documentiDisabilita', files);
    },
  });

  const documentiDisabilitaForm = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(
      z
        .object({
          tipoDisabilita: z.number({
            required_error: 'Obbligatorio',
          }),
          previsioneRevisione: z
            .string({
              required_error: 'Obbligatorio',
            })
            .min(1, 'Seleziona previsione revisione'),
          dataRevisione: z
            .string()
            .optional()
            .refine(
              (value) => {
                if (!value) return true;
                const date = new Date(value);
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date > today;
              },
              { message: 'La data di revisione deve essere nel futuro' }
            ),
          documentoRevisione: z.instanceof(File, { message: 'tipo di file non valido' }).optional(),
          documentoDisabilita: z
            .instanceof(File, {
              message: 'Obbligatorio',
            })
            .refine(
              (file) =>
                [
                  'application/pdf',
                  'application/vnd.ms-excel',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ].includes(file.type),
              { message: 'tipo di file non valido' }
            ),
        })
        .superRefine((data, ctx) => {
          if (data.previsioneRevisione === 'Si' && !data.dataRevisione) {
            ctx.addIssue({
              path: ['dataRevisione'],
              message: 'Data revisione obbligatoria quando previsione revisione è selezionata',
              code: z.ZodIssueCode.custom,
            });
          }
        })
        .superRefine((data, ctx) => {
          if (data.previsioneRevisione === 'Si' && !data.dataRevisione) {
            ctx.addIssue({
              path: ['dataRevisione'],
              message: 'Data revisione obbligatoria quando previsione revisione è selezionata',
              code: z.ZodIssueCode.custom,
            });
          }
        })
    ),
    onSubmit: ({
      tipoDisabilita,
      previsioneRevisione,
      dataRevisione,
      documentoRevisione,
      documentoDisabilita,
    }) => {
      setFieldValue('documentiDisabilita', [
        ...(values.documentiDisabilita ?? []),
        {
          tipoDisabilita,
          previsioneRevisione,
          dataRevisione,
          documentoRevisione,
          documentoDisabilita,
        },
      ]);
      setOpenModalDocumentiDisabilità(false);
    },
  });

  function deleteFile(fileIndex: number) {
    setFieldValue(
      'documentiDisabilita',
      // @ts-ignore
      values.documentiDisabilita.filter((_, index) => index !== fileIndex)
    );
  }

  const handleOpenModalDocumentiDisabilità = () => {
    setOpenModalDocumentiDisabilità(true);
  };

  return (
    <AccordionElement title="Dati Disabilità">
      <Row>
        <Col md={12} className="px-1">
          <Label for="disabile">Disabile</Label>
          <FormGroup>
            <Select
              id="disabile"
              name="disabile"
              value={{
                value: values.disabile,
                label: values.disabile,
              }}
              options={[
                { value: 'No', label: 'No' },
                { value: 'Si', label: 'Si' },
              ]}
              onChange={(option) => {
                if (option) {
                  setFieldValue('disabile', option.value);
                }
              }}
              aria-describedby="disabileError"
              placeholder="Seleziona"
              isDisabled={readingOnly}
            />
            {touched.disabile && errors.disabile && (
              <ErrorMessage touched={touched.disabile} error={errors.disabile} />
            )}
          </FormGroup>
        </Col>
      </Row>

      {values.disabile === 'Si' && (
        <>
          <Row>
            <Col md={4} className="px-1">
              <Input
                id="percentualeDisabilita"
                name="percentualeDisabilita"
                type="number"
                label="Percentuale Disabilità"
                placeholder="Inserisci percentuale disabilità"
                wrapperClassName="form-group required col col-md-12"
                value={values.percentualeDisabilita}
                onChange={handleChange}
                disabled={readingOnly}
                invalid={!!errors.percentualeDisabilita}
                infoText={errors.percentualeDisabilita}
              />
            </Col>
            <Col md={4} className="px-1">
              <CustomSelect
                name="collocamentoMirato"
                label="Iscrizione al collocamento"
                placeholder="Seleziona"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                value={values.collocamentoMirato}
                invalid={!!errors.collocamentoMirato}
                infoText={errors.collocamentoMirato}
                onChange={(option) => {
                  if (option) {
                    setFieldValue('collocamentoMirato', option);
                  }
                }}
                wrapperClass="col-md required"
                disabled={readingOnly}
              />
            </Col>
            {values.collocamentoMirato === 'Si' && (
              <Col md={4} className="px-1">
                <Button
                  /* @ts-ignore */
                  onClick={() => fileCollocamentoRef.current?.click()}
                  color="primary"
                  outline
                  size="sm"
                  disabled={readingOnly}
                >
                  <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
                  Carica nulla osta
                </Button>

                <input
                  id="fileInput"
                  type="file"
                  accept=".pdf"
                  ref={fileCollocamentoRef}
                  onChange={(event: any) => {
                    const file = event.target.files[0];
                    if (file) setFieldValue('fileCollocamento', file);
                  }}
                  hidden
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col></Col>
          </Row>
        </>
      )}

      {values.disabile === 'Si' && (
        <>
          <Row>
            <Col md={12} className="px-1">
              <h6
                style={{
                  fontSize: '16px',
                  color: '#06c',
                  textTransform: 'uppercase',
                  marginTop: '-20px',
                  marginBottom: '20px',
                }}
              >
                Documentazione Disabilità
              </h6>
            </Col>
          </Row>

          {values.documentiDisabilita && values.documentiDisabilita.length > 0 && (
            <Row>
              <Col md={12}>
                <TabellaAllegati deleteFile={deleteFile} documenti={values.documentiDisabilita} />
              </Col>
            </Row>
          )}
          <Button
            onClick={handleOpenModalDocumentiDisabilità}
            color="primary"
            outline
            size="sm"
            disabled={readingOnly}
          >
            <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
            Seleziona file
          </Button>

          {!readingOnly && (
            <div
              className=" mt-4"
              style={{
                height: '140px',
                width: '100%',
                border: '1px solid #e3e4e6',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Row
                style={{
                  height: '100%',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: '20px',
                    background: '#FF9327',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  }}
                />
                <Col>
                  <Row
                    style={{
                      margin: '10px',
                    }}
                  >
                    <Icon icon="it-warning-circle" color="warning" size="sm" />
                    <h6
                      style={{
                        fontSize: '18px',
                        marginLeft: '10px',
                      }}
                    >
                      Sono obbligatori almeno i seguenti due documenti:
                    </h6>
                  </Row>
                  <Row>
                    <div>
                      <ul>
                        <li>“Allegato Richiesta Scheda funzionale”</li>
                        <li>“Allegato Richiesta Verbale invalidità”</li>
                      </ul>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={openModalDocumentiDisabilità}
        toggle={() => setOpenModalDocumentiDisabilità(!openModalDocumentiDisabilità)}
      >
        <ModalHeader toggle={() => setOpenModalDocumentiDisabilità(!openModalDocumentiDisabilità)}>
          Compila e carica il file
        </ModalHeader>
        <ModalBody>
          <>
            <Col md={12} className="px-1 mt-4">
              <CustomSelect
                name="tipoDisabilita"
                label="Tipologia documenti"
                placeholder="Seleziona tipologia documenti"
                options={concorsiOptions.tipiDocumento.filter(
                  (tipo) =>
                    tipo.label === 'RICHIESTA_SCHEDA_FUNZIONALE' ||
                    tipo.label === 'RICHIESTA_VERBALE_INVALIDITA'
                )}
                value={documentiDisabilitaForm.values.tipoDisabilita}
                invalid={!!documentiDisabilitaForm.errors.tipoDisabilita}
                infoText={documentiDisabilitaForm.errors.tipoDisabilita}
                onChange={(option) => {
                  if (option) {
                    documentiDisabilitaForm.setFieldValue('tipoDisabilita', Number(option));
                  }
                }}
                wrapperClass="col-md required"
              />
            </Col>
            <Col md={12} className="px-1">
              <CustomSelect
                name="previsioneRevisione"
                label="Previsione Revisione"
                placeholder="Seleziona"
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Si', label: 'Si' },
                ]}
                onChange={(option) => {
                  if (option) {
                    documentiDisabilitaForm.setFieldValue('previsioneRevisione', option);
                  }
                }}
                value={documentiDisabilitaForm.values.previsioneRevisione}
                invalid={!!documentiDisabilitaForm.errors.previsioneRevisione}
                infoText={documentiDisabilitaForm.errors.previsioneRevisione}
                wrapperClass="col-md required"
              />
            </Col>

            {documentiDisabilitaForm.values.previsioneRevisione === 'Si' && (
              <Row>
                <Col md={6} className="px-1">
                  <Input
                    label="Data revisione"
                    type="date"
                    placeholder="Inserisci una data"
                    id="input-dataRevisione"
                    wrapperClassName="form-group required col-md-12"
                    name="dataRevisione"
                    value={documentiDisabilitaForm.values.dataRevisione}
                    onChange={documentiDisabilitaForm.handleChange}
                    onBlur={documentiDisabilitaForm.handleBlur}
                    invalid={!!documentiDisabilitaForm.errors.dataRevisione}
                    infoText={documentiDisabilitaForm.errors.dataRevisione}
                  />
                </Col>
                <Col md={6} className="px-1">
                  <Button
                    /* @ts-ignore */
                    onClick={() => documentoRevisioneRef.current?.click()}
                    color="primary"
                    outline
                    size="sm"
                  >
                    <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
                    Carica file data revisione
                  </Button>
                  {documentiDisabilitaForm.errors.documentoRevisione && (
                    <ErrorMessage error={documentiDisabilitaForm.errors.documentoRevisione} />
                  )}

                  <input
                    id="fileInput"
                    type="file"
                    accept=".pdf"
                    ref={documentoRevisioneRef}
                    onChange={(event: any) => {
                      const file = event.target.files[0];
                      if (file) documentiDisabilitaForm.setFieldValue('documentoRevisione', file);
                    }}
                    hidden
                  />
                </Col>
              </Row>
            )}

            <Button
              onClick={() => documentoDisabilitaRef.current?.click()}
              color="primary"
              outline
              size="sm"
            >
              <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
              Seleziona file
            </Button>
            {documentiDisabilitaForm.errors.documentoDisabilita && (
              <ErrorMessage error={documentiDisabilitaForm.errors.documentoDisabilita} />
            )}

            <input
              id="fileInput"
              type="file"
              accept=".pdf"
              ref={documentoDisabilitaRef}
              onChange={(event: any) => {
                const file = event.target.files[0];
                documentiDisabilitaForm.setFieldValue('documentoDisabilita', file);
              }}
              hidden
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '10px',
                paddingBottom: '15px',
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  documentiDisabilitaForm.submitForm();
                }}
              >
                Conferma
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => setOpenModalDocumentiDisabilità(!openModalDocumentiDisabilità)}
                size="sm"
              >
                Annulla
              </Button>
            </div>
          </>
        </ModalBody>
      </Modal>
    </AccordionElement>
  );
}
