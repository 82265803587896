/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccettazioneContrattoRequestDto } from '../model';
// @ts-ignore
import { AnagraficaUnicaDto } from '../model';
// @ts-ignore
import { AreaDocumentiCandidatoRequestDto } from '../model';
// @ts-ignore
import { ComunicazioneConvocazioneRequestDto } from '../model';
// @ts-ignore
import { ConfigurazioneDocumentiNuoveAssunzioniDto } from '../model';
// @ts-ignore
import { ConvocazioneNuoveAssunzioniDto } from '../model';
// @ts-ignore
import { DecisioneConvocazioneRequestDto } from '../model';
// @ts-ignore
import { DocumentoConvocazioneSottoscrizioneRequestDto } from '../model';
// @ts-ignore
import { DocumentoPrimaConvocazioneRequestDto } from '../model';
// @ts-ignore
import { NuoveAssunzioniDto } from '../model';
// @ts-ignore
import { NuoveAssunzioniInsertUpdateRequestDto } from '../model';
// @ts-ignore
import { NuoveAssunzioniPatchRequestDto } from '../model';
// @ts-ignore
import { PagedDtoElencoNuoveAssunzioniDto } from '../model';
// @ts-ignore
import { RettificaCandidatoDto } from '../model';
/**
 * GestioneAssunzioniControllerApi - axios parameter creator
 * @export
 */
export const GestioneAssunzioniControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * API Rest per la comunicazione della convocazione
     * @summary Comunicazione convocazione
     * @param {number} id
     * @param {ComunicazioneConvocazioneRequestDto} comunicazioneConvocazioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comunicazioneConvocazione: async (
      id: number,
      comunicazioneConvocazioneRequestDto: ComunicazioneConvocazioneRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('comunicazioneConvocazione', 'id', id);
      // verify required parameter 'comunicazioneConvocazioneRequestDto' is not null or undefined
      assertParamExists(
        'comunicazioneConvocazione',
        'comunicazioneConvocazioneRequestDto',
        comunicazioneConvocazioneRequestDto
      );
      const localVarPath = `/v1/candidati/{id}/convocazione-sottoscrizione`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        comunicazioneConvocazioneRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca di un candidato
     * @summary Cerca Candidato
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettaglioCandidato: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('dettaglioCandidato', 'id', id);
      const localVarPath = `/v1/candidati/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero di un documento utile per l\'assunzione
     * @summary Ricerca documento da richiedere al candidato
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettaglioDocumentoCandidato: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('dettaglioDocumentoCandidato', 'id', id);
      const localVarPath = `/v1/candidati/area-documenti/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la generazione dei documenti di un candidato
     * @summary Gestione documenti candidato
     * @param {DocumentoConvocazioneSottoscrizioneRequestDto} documentoConvocazioneSottoscrizioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentoConvocazioneSottoscrizione: async (
      documentoConvocazioneSottoscrizioneRequestDto: DocumentoConvocazioneSottoscrizioneRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentoConvocazioneSottoscrizioneRequestDto' is not null or undefined
      assertParamExists(
        'documentoConvocazioneSottoscrizione',
        'documentoConvocazioneSottoscrizioneRequestDto',
        documentoConvocazioneSottoscrizioneRequestDto
      );
      const localVarPath = `/v1/candidati/convocazione-sottoscrizione/anteprima-documento`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        documentoConvocazioneSottoscrizioneRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la generazione dei documenti di un candidato
     * @summary Gestione documenti candidato
     * @param {DocumentoPrimaConvocazioneRequestDto} documentoPrimaConvocazioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentoPrimaConvocazione: async (
      documentoPrimaConvocazioneRequestDto: DocumentoPrimaConvocazioneRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentoPrimaConvocazioneRequestDto' is not null or undefined
      assertParamExists(
        'documentoPrimaConvocazione',
        'documentoPrimaConvocazioneRequestDto',
        documentoPrimaConvocazioneRequestDto
      );
      const localVarPath = `/v1/candidati/prima-convocazione/anteprima-documento`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        documentoPrimaConvocazioneRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'elenco delle convocazioni
     * @summary Elenco convocazioni
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    elencoConvocazioni: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('elencoConvocazioni', 'id', id);
      const localVarPath = `/v1/candidati/{id}/convocazione-sottoscrizione`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero dell\'elenco dei documenti per l\'assunzione
     * @summary Elenco documenti da richiedere al candidato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    elencoDocumentiCandidato: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/candidati/area-documenti`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'inserimento di un nuovo candidato
     * @summary Inserisci Nuovo Candidato
     * @param {NuoveAssunzioniInsertUpdateRequestDto} nuoveAssunzioniInsertUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertCandidato: async (
      nuoveAssunzioniInsertUpdateRequestDto: NuoveAssunzioniInsertUpdateRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'nuoveAssunzioniInsertUpdateRequestDto' is not null or undefined
      assertParamExists(
        'insertCandidato',
        'nuoveAssunzioniInsertUpdateRequestDto',
        nuoveAssunzioniInsertUpdateRequestDto
      );
      const localVarPath = `/v1/candidati`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nuoveAssunzioniInsertUpdateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'accettazione di un candidato
     * @summary Patch per la comunicazione dell\'accettazione del contratto
     * @param {number} id
     * @param {AccettazioneContrattoRequestDto} accettazioneContrattoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAccettazione: async (
      id: number,
      accettazioneContrattoRequestDto: AccettazioneContrattoRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchAccettazione', 'id', id);
      // verify required parameter 'accettazioneContrattoRequestDto' is not null or undefined
      assertParamExists(
        'patchAccettazione',
        'accettazioneContrattoRequestDto',
        accettazioneContrattoRequestDto
      );
      const localVarPath = `/v1/candidati/{id}/convocazione-sottoscrizione/accettazione`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accettazioneContrattoRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la patch di un candidato
     * @summary Patch Candidato
     * @param {number} id
     * @param {NuoveAssunzioniPatchRequestDto} nuoveAssunzioniPatchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCandidato: async (
      id: number,
      nuoveAssunzioniPatchRequestDto: NuoveAssunzioniPatchRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchCandidato', 'id', id);
      // verify required parameter 'nuoveAssunzioniPatchRequestDto' is not null or undefined
      assertParamExists(
        'patchCandidato',
        'nuoveAssunzioniPatchRequestDto',
        nuoveAssunzioniPatchRequestDto
      );
      const localVarPath = `/v1/candidati/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nuoveAssunzioniPatchRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per cessare un contratto
     * @summary Patch per la comunicazione della cessazione del contratto
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCessazione: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchCessazione', 'id', id);
      const localVarPath = `/v1/candidati/{id}/convocazione-sottoscrizione/cessazione`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la decisione di un candidato
     * @summary Patch per la comunicazione della decisione sulla convocazione
     * @param {number} id
     * @param {DecisioneConvocazioneRequestDto} decisioneConvocazioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchDecisione: async (
      id: number,
      decisioneConvocazioneRequestDto: DecisioneConvocazioneRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchDecisione', 'id', id);
      // verify required parameter 'decisioneConvocazioneRequestDto' is not null or undefined
      assertParamExists(
        'patchDecisione',
        'decisioneConvocazioneRequestDto',
        decisioneConvocazioneRequestDto
      );
      const localVarPath = `/v1/candidati/{id}/convocazione-sottoscrizione/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        decisioneConvocazioneRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la modifica di un documento per l\'assunzione
     * @summary Patch su un documento da richiedere al candidato
     * @param {number} id
     * @param {AreaDocumentiCandidatoRequestDto} areaDocumentiCandidatoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchDocumento: async (
      id: number,
      areaDocumentiCandidatoRequestDto: AreaDocumentiCandidatoRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchDocumento', 'id', id);
      // verify required parameter 'areaDocumentiCandidatoRequestDto' is not null or undefined
      assertParamExists(
        'patchDocumento',
        'areaDocumentiCandidatoRequestDto',
        areaDocumentiCandidatoRequestDto
      );
      const localVarPath = `/v1/candidati/area-documenti/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        areaDocumentiCandidatoRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'invio della rettifica a un candidato
     * @summary Invia rettifica Candidato
     * @param {number} id
     * @param {RettificaCandidatoDto} rettificaCandidatoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rettificaCandidato: async (
      id: number,
      rettificaCandidatoDto: RettificaCandidatoDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('rettificaCandidato', 'id', id);
      // verify required parameter 'rettificaCandidatoDto' is not null or undefined
      assertParamExists('rettificaCandidato', 'rettificaCandidatoDto', rettificaCandidatoDto);
      const localVarPath = `/v1/candidati/{id}/rettifica`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rettificaCandidatoDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero dei dati anagrafici da anpr
     * @summary Ricerca in anpr a partire dal codice fiscale
     * @param {string} codiceFiscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ricercaInAnpr: async (
      codiceFiscale: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'codiceFiscale' is not null or undefined
      assertParamExists('ricercaInAnpr', 'codiceFiscale', codiceFiscale);
      const localVarPath = `/v1/candidati/anpr/{codiceFiscale}`.replace(
        `{${'codiceFiscale'}}`,
        encodeURIComponent(String(codiceFiscale))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero dell\'elenco dei candidati
     * @summary Elenco Candidati
     * @param {string} [cognome]
     * @param {string} [nome]
     * @param {string} [codiceFiscale]
     * @param {number} [concorsoId]
     * @param {string} [concorsoNome]
     * @param {'A' | 'B' | 'C'} [concorsoClasse]
     * @param {string} [concorsoPosizioneEconomica]
     * @param {'ARE' | 'ARF' | 'ARI' | 'ARO'} [concorsoAreaProfessionale]
     * @param {string} [concorsoPosizioneGraduatoria]
     * @param {boolean} [concorsoVincitore]
     * @param {boolean} [concorsoIdoneo]
     * @param {string} [concorsoDataInserimento]
     * @param {string} [concorsoDataModifica]
     * @param {string} [concorsoNumeroPosti]
     * @param {string} [concorsoStipendioTabellare]
     * @param {string} [concorsoIvcBiennio]
     * @param {string} [concorsoIdennitaComparto]
     * @param {'INC' | 'COM' | 'REG' | 'VAL' | 'RIF' | 'ACC' | 'CES' | 'CEN' | 'CON'} [statoCandidato]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCandidati: async (
      cognome?: string,
      nome?: string,
      codiceFiscale?: string,
      concorsoId?: number,
      concorsoNome?: string,
      concorsoClasse?: 'A' | 'B' | 'C',
      concorsoPosizioneEconomica?: string,
      concorsoAreaProfessionale?: 'ARE' | 'ARF' | 'ARI' | 'ARO',
      concorsoPosizioneGraduatoria?: string,
      concorsoVincitore?: boolean,
      concorsoIdoneo?: boolean,
      concorsoDataInserimento?: string,
      concorsoDataModifica?: string,
      concorsoNumeroPosti?: string,
      concorsoStipendioTabellare?: string,
      concorsoIvcBiennio?: string,
      concorsoIdennitaComparto?: string,
      statoCandidato?: 'INC' | 'COM' | 'REG' | 'VAL' | 'RIF' | 'ACC' | 'CES' | 'CEN' | 'CON',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/candidati`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (cognome !== undefined) {
        localVarQueryParameter['cognome'] = cognome;
      }

      if (nome !== undefined) {
        localVarQueryParameter['nome'] = nome;
      }

      if (codiceFiscale !== undefined) {
        localVarQueryParameter['codiceFiscale'] = codiceFiscale;
      }

      if (concorsoId !== undefined) {
        localVarQueryParameter['concorso.id'] = concorsoId;
      }

      if (concorsoNome !== undefined) {
        localVarQueryParameter['concorso.nome'] = concorsoNome;
      }

      if (concorsoClasse !== undefined) {
        localVarQueryParameter['concorso.classe'] = concorsoClasse;
      }

      if (concorsoPosizioneEconomica !== undefined) {
        localVarQueryParameter['concorso.posizioneEconomica'] = concorsoPosizioneEconomica;
      }

      if (concorsoAreaProfessionale !== undefined) {
        localVarQueryParameter['concorso.areaProfessionale'] = concorsoAreaProfessionale;
      }

      if (concorsoPosizioneGraduatoria !== undefined) {
        localVarQueryParameter['concorso.posizioneGraduatoria'] = concorsoPosizioneGraduatoria;
      }

      if (concorsoVincitore !== undefined) {
        localVarQueryParameter['concorso.vincitore'] = concorsoVincitore;
      }

      if (concorsoIdoneo !== undefined) {
        localVarQueryParameter['concorso.idoneo'] = concorsoIdoneo;
      }

      if (concorsoDataInserimento !== undefined) {
        localVarQueryParameter['concorso.dataInserimento'] =
          (concorsoDataInserimento as any) instanceof Date
            ? (concorsoDataInserimento as any).toISOString().substr(0, 10)
            : concorsoDataInserimento;
      }

      if (concorsoDataModifica !== undefined) {
        localVarQueryParameter['concorso.dataModifica'] =
          (concorsoDataModifica as any) instanceof Date
            ? (concorsoDataModifica as any).toISOString().substr(0, 10)
            : concorsoDataModifica;
      }

      if (concorsoNumeroPosti !== undefined) {
        localVarQueryParameter['concorso.numeroPosti'] = concorsoNumeroPosti;
      }

      if (concorsoStipendioTabellare !== undefined) {
        localVarQueryParameter['concorso.stipendioTabellare'] = concorsoStipendioTabellare;
      }

      if (concorsoIvcBiennio !== undefined) {
        localVarQueryParameter['concorso.ivcBiennio'] = concorsoIvcBiennio;
      }

      if (concorsoIdennitaComparto !== undefined) {
        localVarQueryParameter['concorso.idennitaComparto'] = concorsoIdennitaComparto;
      }

      if (statoCandidato !== undefined) {
        localVarQueryParameter['statoCandidato'] = statoCandidato;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['pageNum'] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'update di un candidato
     * @summary Update Candidato
     * @param {number} id
     * @param {NuoveAssunzioniInsertUpdateRequestDto} nuoveAssunzioniInsertUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCandidato: async (
      id: number,
      nuoveAssunzioniInsertUpdateRequestDto: NuoveAssunzioniInsertUpdateRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCandidato', 'id', id);
      // verify required parameter 'nuoveAssunzioniInsertUpdateRequestDto' is not null or undefined
      assertParamExists(
        'updateCandidato',
        'nuoveAssunzioniInsertUpdateRequestDto',
        nuoveAssunzioniInsertUpdateRequestDto
      );
      const localVarPath = `/v1/candidati/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nuoveAssunzioniInsertUpdateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GestioneAssunzioniControllerApi - functional programming interface
 * @export
 */
export const GestioneAssunzioniControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GestioneAssunzioniControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per la comunicazione della convocazione
     * @summary Comunicazione convocazione
     * @param {number} id
     * @param {ComunicazioneConvocazioneRequestDto} comunicazioneConvocazioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async comunicazioneConvocazione(
      id: number,
      comunicazioneConvocazioneRequestDto: ComunicazioneConvocazioneRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvocazioneNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.comunicazioneConvocazione(
        id,
        comunicazioneConvocazioneRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca di un candidato
     * @summary Cerca Candidato
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dettaglioCandidato(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NuoveAssunzioniDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioCandidato(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero di un documento utile per l\'assunzione
     * @summary Ricerca documento da richiedere al candidato
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dettaglioDocumentoCandidato(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ConfigurazioneDocumentiNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioDocumentoCandidato(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la generazione dei documenti di un candidato
     * @summary Gestione documenti candidato
     * @param {DocumentoConvocazioneSottoscrizioneRequestDto} documentoConvocazioneSottoscrizioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentoConvocazioneSottoscrizione(
      documentoConvocazioneSottoscrizioneRequestDto: DocumentoConvocazioneSottoscrizioneRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentoConvocazioneSottoscrizione(
        documentoConvocazioneSottoscrizioneRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la generazione dei documenti di un candidato
     * @summary Gestione documenti candidato
     * @param {DocumentoPrimaConvocazioneRequestDto} documentoPrimaConvocazioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentoPrimaConvocazione(
      documentoPrimaConvocazioneRequestDto: DocumentoPrimaConvocazioneRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentoPrimaConvocazione(
        documentoPrimaConvocazioneRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'elenco delle convocazioni
     * @summary Elenco convocazioni
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async elencoConvocazioni(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ConvocazioneNuoveAssunzioniDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.elencoConvocazioni(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero dell\'elenco dei documenti per l\'assunzione
     * @summary Elenco documenti da richiedere al candidato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async elencoDocumentiCandidato(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ConfigurazioneDocumentiNuoveAssunzioniDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.elencoDocumentiCandidato(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'inserimento di un nuovo candidato
     * @summary Inserisci Nuovo Candidato
     * @param {NuoveAssunzioniInsertUpdateRequestDto} nuoveAssunzioniInsertUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertCandidato(
      nuoveAssunzioniInsertUpdateRequestDto: NuoveAssunzioniInsertUpdateRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NuoveAssunzioniDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertCandidato(
        nuoveAssunzioniInsertUpdateRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'accettazione di un candidato
     * @summary Patch per la comunicazione dell\'accettazione del contratto
     * @param {number} id
     * @param {AccettazioneContrattoRequestDto} accettazioneContrattoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchAccettazione(
      id: number,
      accettazioneContrattoRequestDto: AccettazioneContrattoRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvocazioneNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchAccettazione(
        id,
        accettazioneContrattoRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la patch di un candidato
     * @summary Patch Candidato
     * @param {number} id
     * @param {NuoveAssunzioniPatchRequestDto} nuoveAssunzioniPatchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchCandidato(
      id: number,
      nuoveAssunzioniPatchRequestDto: NuoveAssunzioniPatchRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NuoveAssunzioniDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchCandidato(
        id,
        nuoveAssunzioniPatchRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per cessare un contratto
     * @summary Patch per la comunicazione della cessazione del contratto
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchCessazione(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvocazioneNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchCessazione(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la decisione di un candidato
     * @summary Patch per la comunicazione della decisione sulla convocazione
     * @param {number} id
     * @param {DecisioneConvocazioneRequestDto} decisioneConvocazioneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchDecisione(
      id: number,
      decisioneConvocazioneRequestDto: DecisioneConvocazioneRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvocazioneNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchDecisione(
        id,
        decisioneConvocazioneRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la modifica di un documento per l\'assunzione
     * @summary Patch su un documento da richiedere al candidato
     * @param {number} id
     * @param {AreaDocumentiCandidatoRequestDto} areaDocumentiCandidatoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchDocumento(
      id: number,
      areaDocumentiCandidatoRequestDto: AreaDocumentiCandidatoRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ConfigurazioneDocumentiNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchDocumento(
        id,
        areaDocumentiCandidatoRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'invio della rettifica a un candidato
     * @summary Invia rettifica Candidato
     * @param {number} id
     * @param {RettificaCandidatoDto} rettificaCandidatoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rettificaCandidato(
      id: number,
      rettificaCandidatoDto: RettificaCandidatoDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rettificaCandidato(
        id,
        rettificaCandidatoDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero dei dati anagrafici da anpr
     * @summary Ricerca in anpr a partire dal codice fiscale
     * @param {string} codiceFiscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ricercaInAnpr(
      codiceFiscale: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnagraficaUnicaDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ricercaInAnpr(
        codiceFiscale,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero dell\'elenco dei candidati
     * @summary Elenco Candidati
     * @param {string} [cognome]
     * @param {string} [nome]
     * @param {string} [codiceFiscale]
     * @param {number} [concorsoId]
     * @param {string} [concorsoNome]
     * @param {'A' | 'B' | 'C'} [concorsoClasse]
     * @param {string} [concorsoPosizioneEconomica]
     * @param {'ARE' | 'ARF' | 'ARI' | 'ARO'} [concorsoAreaProfessionale]
     * @param {string} [concorsoPosizioneGraduatoria]
     * @param {boolean} [concorsoVincitore]
     * @param {boolean} [concorsoIdoneo]
     * @param {string} [concorsoDataInserimento]
     * @param {string} [concorsoDataModifica]
     * @param {string} [concorsoNumeroPosti]
     * @param {string} [concorsoStipendioTabellare]
     * @param {string} [concorsoIvcBiennio]
     * @param {string} [concorsoIdennitaComparto]
     * @param {'INC' | 'COM' | 'REG' | 'VAL' | 'RIF' | 'ACC' | 'CES' | 'CEN' | 'CON'} [statoCandidato]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchCandidati(
      cognome?: string,
      nome?: string,
      codiceFiscale?: string,
      concorsoId?: number,
      concorsoNome?: string,
      concorsoClasse?: 'A' | 'B' | 'C',
      concorsoPosizioneEconomica?: string,
      concorsoAreaProfessionale?: 'ARE' | 'ARF' | 'ARI' | 'ARO',
      concorsoPosizioneGraduatoria?: string,
      concorsoVincitore?: boolean,
      concorsoIdoneo?: boolean,
      concorsoDataInserimento?: string,
      concorsoDataModifica?: string,
      concorsoNumeroPosti?: string,
      concorsoStipendioTabellare?: string,
      concorsoIvcBiennio?: string,
      concorsoIdennitaComparto?: string,
      statoCandidato?: 'INC' | 'COM' | 'REG' | 'VAL' | 'RIF' | 'ACC' | 'CES' | 'CEN' | 'CON',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoElencoNuoveAssunzioniDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchCandidati(
        cognome,
        nome,
        codiceFiscale,
        concorsoId,
        concorsoNome,
        concorsoClasse,
        concorsoPosizioneEconomica,
        concorsoAreaProfessionale,
        concorsoPosizioneGraduatoria,
        concorsoVincitore,
        concorsoIdoneo,
        concorsoDataInserimento,
        concorsoDataModifica,
        concorsoNumeroPosti,
        concorsoStipendioTabellare,
        concorsoIvcBiennio,
        concorsoIdennitaComparto,
        statoCandidato,
        pageNum,
        pageSize,
        sort,
        direction,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'update di un candidato
     * @summary Update Candidato
     * @param {number} id
     * @param {NuoveAssunzioniInsertUpdateRequestDto} nuoveAssunzioniInsertUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCandidato(
      id: number,
      nuoveAssunzioniInsertUpdateRequestDto: NuoveAssunzioniInsertUpdateRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NuoveAssunzioniDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCandidato(
        id,
        nuoveAssunzioniInsertUpdateRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GestioneAssunzioniControllerApi - factory interface
 * @export
 */
export const GestioneAssunzioniControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GestioneAssunzioniControllerApiFp(configuration);
  return {
    /**
     * API Rest per la comunicazione della convocazione
     * @summary Comunicazione convocazione
     * @param {GestioneAssunzioniControllerApiComunicazioneConvocazioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    comunicazioneConvocazione(
      requestParameters: GestioneAssunzioniControllerApiComunicazioneConvocazioneRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConvocazioneNuoveAssunzioniDto> {
      return localVarFp
        .comunicazioneConvocazione(
          requestParameters.id,
          requestParameters.comunicazioneConvocazioneRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca di un candidato
     * @summary Cerca Candidato
     * @param {GestioneAssunzioniControllerApiDettaglioCandidatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettaglioCandidato(
      requestParameters: GestioneAssunzioniControllerApiDettaglioCandidatoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<NuoveAssunzioniDto> {
      return localVarFp
        .dettaglioCandidato(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero di un documento utile per l\'assunzione
     * @summary Ricerca documento da richiedere al candidato
     * @param {GestioneAssunzioniControllerApiDettaglioDocumentoCandidatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettaglioDocumentoCandidato(
      requestParameters: GestioneAssunzioniControllerApiDettaglioDocumentoCandidatoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConfigurazioneDocumentiNuoveAssunzioniDto> {
      return localVarFp
        .dettaglioDocumentoCandidato(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la generazione dei documenti di un candidato
     * @summary Gestione documenti candidato
     * @param {GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentoConvocazioneSottoscrizione(
      requestParameters: GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizioneRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<File> {
      return localVarFp
        .documentoConvocazioneSottoscrizione(
          requestParameters.documentoConvocazioneSottoscrizioneRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la generazione dei documenti di un candidato
     * @summary Gestione documenti candidato
     * @param {GestioneAssunzioniControllerApiDocumentoPrimaConvocazioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentoPrimaConvocazione(
      requestParameters: GestioneAssunzioniControllerApiDocumentoPrimaConvocazioneRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<File> {
      return localVarFp
        .documentoPrimaConvocazione(requestParameters.documentoPrimaConvocazioneRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'elenco delle convocazioni
     * @summary Elenco convocazioni
     * @param {GestioneAssunzioniControllerApiElencoConvocazioniRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    elencoConvocazioni(
      requestParameters: GestioneAssunzioniControllerApiElencoConvocazioniRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<ConvocazioneNuoveAssunzioniDto>> {
      return localVarFp
        .elencoConvocazioni(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero dell\'elenco dei documenti per l\'assunzione
     * @summary Elenco documenti da richiedere al candidato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    elencoDocumentiCandidato(
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<ConfigurazioneDocumentiNuoveAssunzioniDto>> {
      return localVarFp
        .elencoDocumentiCandidato(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'inserimento di un nuovo candidato
     * @summary Inserisci Nuovo Candidato
     * @param {GestioneAssunzioniControllerApiInsertCandidatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertCandidato(
      requestParameters: GestioneAssunzioniControllerApiInsertCandidatoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<NuoveAssunzioniDto> {
      return localVarFp
        .insertCandidato(requestParameters.nuoveAssunzioniInsertUpdateRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'accettazione di un candidato
     * @summary Patch per la comunicazione dell\'accettazione del contratto
     * @param {GestioneAssunzioniControllerApiPatchAccettazioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAccettazione(
      requestParameters: GestioneAssunzioniControllerApiPatchAccettazioneRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConvocazioneNuoveAssunzioniDto> {
      return localVarFp
        .patchAccettazione(
          requestParameters.id,
          requestParameters.accettazioneContrattoRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la patch di un candidato
     * @summary Patch Candidato
     * @param {GestioneAssunzioniControllerApiPatchCandidatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCandidato(
      requestParameters: GestioneAssunzioniControllerApiPatchCandidatoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<NuoveAssunzioniDto> {
      return localVarFp
        .patchCandidato(
          requestParameters.id,
          requestParameters.nuoveAssunzioniPatchRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per cessare un contratto
     * @summary Patch per la comunicazione della cessazione del contratto
     * @param {GestioneAssunzioniControllerApiPatchCessazioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCessazione(
      requestParameters: GestioneAssunzioniControllerApiPatchCessazioneRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConvocazioneNuoveAssunzioniDto> {
      return localVarFp
        .patchCessazione(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la decisione di un candidato
     * @summary Patch per la comunicazione della decisione sulla convocazione
     * @param {GestioneAssunzioniControllerApiPatchDecisioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchDecisione(
      requestParameters: GestioneAssunzioniControllerApiPatchDecisioneRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConvocazioneNuoveAssunzioniDto> {
      return localVarFp
        .patchDecisione(
          requestParameters.id,
          requestParameters.decisioneConvocazioneRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la modifica di un documento per l\'assunzione
     * @summary Patch su un documento da richiedere al candidato
     * @param {GestioneAssunzioniControllerApiPatchDocumentoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchDocumento(
      requestParameters: GestioneAssunzioniControllerApiPatchDocumentoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConfigurazioneDocumentiNuoveAssunzioniDto> {
      return localVarFp
        .patchDocumento(
          requestParameters.id,
          requestParameters.areaDocumentiCandidatoRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'invio della rettifica a un candidato
     * @summary Invia rettifica Candidato
     * @param {GestioneAssunzioniControllerApiRettificaCandidatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rettificaCandidato(
      requestParameters: GestioneAssunzioniControllerApiRettificaCandidatoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<boolean> {
      return localVarFp
        .rettificaCandidato(requestParameters.id, requestParameters.rettificaCandidatoDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero dei dati anagrafici da anpr
     * @summary Ricerca in anpr a partire dal codice fiscale
     * @param {GestioneAssunzioniControllerApiRicercaInAnprRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ricercaInAnpr(
      requestParameters: GestioneAssunzioniControllerApiRicercaInAnprRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<AnagraficaUnicaDto> {
      return localVarFp
        .ricercaInAnpr(requestParameters.codiceFiscale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero dell\'elenco dei candidati
     * @summary Elenco Candidati
     * @param {GestioneAssunzioniControllerApiSearchCandidatiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCandidati(
      requestParameters: GestioneAssunzioniControllerApiSearchCandidatiRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedDtoElencoNuoveAssunzioniDto> {
      return localVarFp
        .searchCandidati(
          requestParameters.cognome,
          requestParameters.nome,
          requestParameters.codiceFiscale,
          requestParameters.concorsoId,
          requestParameters.concorsoNome,
          requestParameters.concorsoClasse,
          requestParameters.concorsoPosizioneEconomica,
          requestParameters.concorsoAreaProfessionale,
          requestParameters.concorsoPosizioneGraduatoria,
          requestParameters.concorsoVincitore,
          requestParameters.concorsoIdoneo,
          requestParameters.concorsoDataInserimento,
          requestParameters.concorsoDataModifica,
          requestParameters.concorsoNumeroPosti,
          requestParameters.concorsoStipendioTabellare,
          requestParameters.concorsoIvcBiennio,
          requestParameters.concorsoIdennitaComparto,
          requestParameters.statoCandidato,
          requestParameters.pageNum,
          requestParameters.pageSize,
          requestParameters.sort,
          requestParameters.direction,
          requestParameters.q,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'update di un candidato
     * @summary Update Candidato
     * @param {GestioneAssunzioniControllerApiUpdateCandidatoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCandidato(
      requestParameters: GestioneAssunzioniControllerApiUpdateCandidatoRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<NuoveAssunzioniDto> {
      return localVarFp
        .updateCandidato(
          requestParameters.id,
          requestParameters.nuoveAssunzioniInsertUpdateRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for comunicazioneConvocazione operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiComunicazioneConvocazioneRequest
 */
export interface GestioneAssunzioniControllerApiComunicazioneConvocazioneRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiComunicazioneConvocazione
   */
  readonly id: number;

  /**
   *
   * @type {ComunicazioneConvocazioneRequestDto}
   * @memberof GestioneAssunzioniControllerApiComunicazioneConvocazione
   */
  readonly comunicazioneConvocazioneRequestDto: ComunicazioneConvocazioneRequestDto;
}

/**
 * Request parameters for dettaglioCandidato operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiDettaglioCandidatoRequest
 */
export interface GestioneAssunzioniControllerApiDettaglioCandidatoRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiDettaglioCandidato
   */
  readonly id: number;
}

/**
 * Request parameters for dettaglioDocumentoCandidato operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiDettaglioDocumentoCandidatoRequest
 */
export interface GestioneAssunzioniControllerApiDettaglioDocumentoCandidatoRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiDettaglioDocumentoCandidato
   */
  readonly id: number;
}

/**
 * Request parameters for documentoConvocazioneSottoscrizione operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizioneRequest
 */
export interface GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizioneRequest {
  /**
   *
   * @type {DocumentoConvocazioneSottoscrizioneRequestDto}
   * @memberof GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizione
   */
  readonly documentoConvocazioneSottoscrizioneRequestDto: DocumentoConvocazioneSottoscrizioneRequestDto;
}

/**
 * Request parameters for documentoPrimaConvocazione operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiDocumentoPrimaConvocazioneRequest
 */
export interface GestioneAssunzioniControllerApiDocumentoPrimaConvocazioneRequest {
  /**
   *
   * @type {DocumentoPrimaConvocazioneRequestDto}
   * @memberof GestioneAssunzioniControllerApiDocumentoPrimaConvocazione
   */
  readonly documentoPrimaConvocazioneRequestDto: DocumentoPrimaConvocazioneRequestDto;
}

/**
 * Request parameters for elencoConvocazioni operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiElencoConvocazioniRequest
 */
export interface GestioneAssunzioniControllerApiElencoConvocazioniRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiElencoConvocazioni
   */
  readonly id: number;
}

/**
 * Request parameters for insertCandidato operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiInsertCandidatoRequest
 */
export interface GestioneAssunzioniControllerApiInsertCandidatoRequest {
  /**
   *
   * @type {NuoveAssunzioniInsertUpdateRequestDto}
   * @memberof GestioneAssunzioniControllerApiInsertCandidato
   */
  readonly nuoveAssunzioniInsertUpdateRequestDto: NuoveAssunzioniInsertUpdateRequestDto;
}

/**
 * Request parameters for patchAccettazione operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiPatchAccettazioneRequest
 */
export interface GestioneAssunzioniControllerApiPatchAccettazioneRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiPatchAccettazione
   */
  readonly id: number;

  /**
   *
   * @type {AccettazioneContrattoRequestDto}
   * @memberof GestioneAssunzioniControllerApiPatchAccettazione
   */
  readonly accettazioneContrattoRequestDto: AccettazioneContrattoRequestDto;
}

/**
 * Request parameters for patchCandidato operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiPatchCandidatoRequest
 */
export interface GestioneAssunzioniControllerApiPatchCandidatoRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiPatchCandidato
   */
  readonly id: number;

  /**
   *
   * @type {NuoveAssunzioniPatchRequestDto}
   * @memberof GestioneAssunzioniControllerApiPatchCandidato
   */
  readonly nuoveAssunzioniPatchRequestDto: NuoveAssunzioniPatchRequestDto;
}

/**
 * Request parameters for patchCessazione operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiPatchCessazioneRequest
 */
export interface GestioneAssunzioniControllerApiPatchCessazioneRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiPatchCessazione
   */
  readonly id: number;
}

/**
 * Request parameters for patchDecisione operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiPatchDecisioneRequest
 */
export interface GestioneAssunzioniControllerApiPatchDecisioneRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiPatchDecisione
   */
  readonly id: number;

  /**
   *
   * @type {DecisioneConvocazioneRequestDto}
   * @memberof GestioneAssunzioniControllerApiPatchDecisione
   */
  readonly decisioneConvocazioneRequestDto: DecisioneConvocazioneRequestDto;
}

/**
 * Request parameters for patchDocumento operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiPatchDocumentoRequest
 */
export interface GestioneAssunzioniControllerApiPatchDocumentoRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiPatchDocumento
   */
  readonly id: number;

  /**
   *
   * @type {AreaDocumentiCandidatoRequestDto}
   * @memberof GestioneAssunzioniControllerApiPatchDocumento
   */
  readonly areaDocumentiCandidatoRequestDto: AreaDocumentiCandidatoRequestDto;
}

/**
 * Request parameters for rettificaCandidato operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiRettificaCandidatoRequest
 */
export interface GestioneAssunzioniControllerApiRettificaCandidatoRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiRettificaCandidato
   */
  readonly id: number;

  /**
   *
   * @type {RettificaCandidatoDto}
   * @memberof GestioneAssunzioniControllerApiRettificaCandidato
   */
  readonly rettificaCandidatoDto: RettificaCandidatoDto;
}

/**
 * Request parameters for ricercaInAnpr operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiRicercaInAnprRequest
 */
export interface GestioneAssunzioniControllerApiRicercaInAnprRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiRicercaInAnpr
   */
  readonly codiceFiscale: string;
}

/**
 * Request parameters for searchCandidati operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiSearchCandidatiRequest
 */
export interface GestioneAssunzioniControllerApiSearchCandidatiRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly cognome?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly nome?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly codiceFiscale?: string;

  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoId?: number;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoNome?: string;

  /**
   *
   * @type {'A' | 'B' | 'C'}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoClasse?: 'A' | 'B' | 'C';

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoPosizioneEconomica?: string;

  /**
   *
   * @type {'ARE' | 'ARF' | 'ARI' | 'ARO'}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoAreaProfessionale?: 'ARE' | 'ARF' | 'ARI' | 'ARO';

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoPosizioneGraduatoria?: string;

  /**
   *
   * @type {boolean}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoVincitore?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoIdoneo?: boolean;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoDataInserimento?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoDataModifica?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoNumeroPosti?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoStipendioTabellare?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoIvcBiennio?: string;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly concorsoIdennitaComparto?: string;

  /**
   *
   * @type {'INC' | 'COM' | 'REG' | 'VAL' | 'RIF' | 'ACC' | 'CES' | 'CEN' | 'CON'}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly statoCandidato?: 'INC' | 'COM' | 'REG' | 'VAL' | 'RIF' | 'ACC' | 'CES' | 'CEN' | 'CON';

  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly sort?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly direction?: 'ASC' | 'DESC';

  /**
   *
   * @type {string}
   * @memberof GestioneAssunzioniControllerApiSearchCandidati
   */
  readonly q?: string;
}

/**
 * Request parameters for updateCandidato operation in GestioneAssunzioniControllerApi.
 * @export
 * @interface GestioneAssunzioniControllerApiUpdateCandidatoRequest
 */
export interface GestioneAssunzioniControllerApiUpdateCandidatoRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneAssunzioniControllerApiUpdateCandidato
   */
  readonly id: number;

  /**
   *
   * @type {NuoveAssunzioniInsertUpdateRequestDto}
   * @memberof GestioneAssunzioniControllerApiUpdateCandidato
   */
  readonly nuoveAssunzioniInsertUpdateRequestDto: NuoveAssunzioniInsertUpdateRequestDto;
}

/**
 * GestioneAssunzioniControllerApi - object-oriented interface
 * @export
 * @class GestioneAssunzioniControllerApi
 * @extends {BaseAPI}
 */
export class GestioneAssunzioniControllerApi extends BaseAPI {
  /**
   * API Rest per la comunicazione della convocazione
   * @summary Comunicazione convocazione
   * @param {GestioneAssunzioniControllerApiComunicazioneConvocazioneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public comunicazioneConvocazione(
    requestParameters: GestioneAssunzioniControllerApiComunicazioneConvocazioneRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .comunicazioneConvocazione(
        requestParameters.id,
        requestParameters.comunicazioneConvocazioneRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca di un candidato
   * @summary Cerca Candidato
   * @param {GestioneAssunzioniControllerApiDettaglioCandidatoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public dettaglioCandidato(
    requestParameters: GestioneAssunzioniControllerApiDettaglioCandidatoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .dettaglioCandidato(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero di un documento utile per l\'assunzione
   * @summary Ricerca documento da richiedere al candidato
   * @param {GestioneAssunzioniControllerApiDettaglioDocumentoCandidatoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public dettaglioDocumentoCandidato(
    requestParameters: GestioneAssunzioniControllerApiDettaglioDocumentoCandidatoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .dettaglioDocumentoCandidato(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la generazione dei documenti di un candidato
   * @summary Gestione documenti candidato
   * @param {GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizioneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public documentoConvocazioneSottoscrizione(
    requestParameters: GestioneAssunzioniControllerApiDocumentoConvocazioneSottoscrizioneRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .documentoConvocazioneSottoscrizione(
        requestParameters.documentoConvocazioneSottoscrizioneRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la generazione dei documenti di un candidato
   * @summary Gestione documenti candidato
   * @param {GestioneAssunzioniControllerApiDocumentoPrimaConvocazioneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public documentoPrimaConvocazione(
    requestParameters: GestioneAssunzioniControllerApiDocumentoPrimaConvocazioneRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .documentoPrimaConvocazione(requestParameters.documentoPrimaConvocazioneRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'elenco delle convocazioni
   * @summary Elenco convocazioni
   * @param {GestioneAssunzioniControllerApiElencoConvocazioniRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public elencoConvocazioni(
    requestParameters: GestioneAssunzioniControllerApiElencoConvocazioniRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .elencoConvocazioni(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero dell\'elenco dei documenti per l\'assunzione
   * @summary Elenco documenti da richiedere al candidato
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public elencoDocumentiCandidato(options?: AxiosRequestConfig) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .elencoDocumentiCandidato(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'inserimento di un nuovo candidato
   * @summary Inserisci Nuovo Candidato
   * @param {GestioneAssunzioniControllerApiInsertCandidatoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public insertCandidato(
    requestParameters: GestioneAssunzioniControllerApiInsertCandidatoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .insertCandidato(requestParameters.nuoveAssunzioniInsertUpdateRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'accettazione di un candidato
   * @summary Patch per la comunicazione dell\'accettazione del contratto
   * @param {GestioneAssunzioniControllerApiPatchAccettazioneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public patchAccettazione(
    requestParameters: GestioneAssunzioniControllerApiPatchAccettazioneRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .patchAccettazione(
        requestParameters.id,
        requestParameters.accettazioneContrattoRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la patch di un candidato
   * @summary Patch Candidato
   * @param {GestioneAssunzioniControllerApiPatchCandidatoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public patchCandidato(
    requestParameters: GestioneAssunzioniControllerApiPatchCandidatoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .patchCandidato(
        requestParameters.id,
        requestParameters.nuoveAssunzioniPatchRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per cessare un contratto
   * @summary Patch per la comunicazione della cessazione del contratto
   * @param {GestioneAssunzioniControllerApiPatchCessazioneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public patchCessazione(
    requestParameters: GestioneAssunzioniControllerApiPatchCessazioneRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .patchCessazione(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la decisione di un candidato
   * @summary Patch per la comunicazione della decisione sulla convocazione
   * @param {GestioneAssunzioniControllerApiPatchDecisioneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public patchDecisione(
    requestParameters: GestioneAssunzioniControllerApiPatchDecisioneRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .patchDecisione(
        requestParameters.id,
        requestParameters.decisioneConvocazioneRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la modifica di un documento per l\'assunzione
   * @summary Patch su un documento da richiedere al candidato
   * @param {GestioneAssunzioniControllerApiPatchDocumentoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public patchDocumento(
    requestParameters: GestioneAssunzioniControllerApiPatchDocumentoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .patchDocumento(
        requestParameters.id,
        requestParameters.areaDocumentiCandidatoRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'invio della rettifica a un candidato
   * @summary Invia rettifica Candidato
   * @param {GestioneAssunzioniControllerApiRettificaCandidatoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public rettificaCandidato(
    requestParameters: GestioneAssunzioniControllerApiRettificaCandidatoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .rettificaCandidato(requestParameters.id, requestParameters.rettificaCandidatoDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero dei dati anagrafici da anpr
   * @summary Ricerca in anpr a partire dal codice fiscale
   * @param {GestioneAssunzioniControllerApiRicercaInAnprRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public ricercaInAnpr(
    requestParameters: GestioneAssunzioniControllerApiRicercaInAnprRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .ricercaInAnpr(requestParameters.codiceFiscale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero dell\'elenco dei candidati
   * @summary Elenco Candidati
   * @param {GestioneAssunzioniControllerApiSearchCandidatiRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public searchCandidati(
    requestParameters: GestioneAssunzioniControllerApiSearchCandidatiRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .searchCandidati(
        requestParameters.cognome,
        requestParameters.nome,
        requestParameters.codiceFiscale,
        requestParameters.concorsoId,
        requestParameters.concorsoNome,
        requestParameters.concorsoClasse,
        requestParameters.concorsoPosizioneEconomica,
        requestParameters.concorsoAreaProfessionale,
        requestParameters.concorsoPosizioneGraduatoria,
        requestParameters.concorsoVincitore,
        requestParameters.concorsoIdoneo,
        requestParameters.concorsoDataInserimento,
        requestParameters.concorsoDataModifica,
        requestParameters.concorsoNumeroPosti,
        requestParameters.concorsoStipendioTabellare,
        requestParameters.concorsoIvcBiennio,
        requestParameters.concorsoIdennitaComparto,
        requestParameters.statoCandidato,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.sort,
        requestParameters.direction,
        requestParameters.q,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'update di un candidato
   * @summary Update Candidato
   * @param {GestioneAssunzioniControllerApiUpdateCandidatoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneAssunzioniControllerApi
   */
  public updateCandidato(
    requestParameters: GestioneAssunzioniControllerApiUpdateCandidatoRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneAssunzioniControllerApiFp(this.configuration)
      .updateCandidato(
        requestParameters.id,
        requestParameters.nuoveAssunzioniInsertUpdateRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
