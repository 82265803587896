import { Document, Page } from 'react-pdf'; // Assuming you are using react-pdf to render PDF pages
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IExplodedPdfViewerProps {
  pdfFile: string;
}

const ExplodedPdfViewer: React.FC<IExplodedPdfViewerProps> = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const [PDF, setPDF] = useState();

  return (
    <div>
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from({ length: numPages }).map((_, index) => (
          <Page
            key={index}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </div>
  );
};

export default ExplodedPdfViewer;
