import { z } from 'zod';

const validationSchemaForBozza = z
  .object({
    codiceFiscale: z.string().max(16, 'Il codice fiscale deve essere di 16 caratteri').optional(),
    idConcorso: z.number().optional(),
    nomeNuovoConcorso: z.string().max(150).optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.codiceFiscale) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obbligatorio',
        path: ['codiceFiscale'],
      });
    }

    if (!data.idConcorso && !data.nomeNuovoConcorso) {
      console.log('data.idConcorso', data.idConcorso);
      console.log('data.nomeNuovoConcorso', data.nomeNuovoConcorso);
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Seleziona o inserisci un Nome Concorso',
        path: ['idConcorso'],
      });
    }
  });

export default validationSchemaForBozza;
