import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import { ModificaCandidatoForm } from 'components/forms/modifica-candidato-form/ModificaCandidatoForm';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';

const ModificaCandidato = () => {
  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={'Modifica candidato'} />
      <ModificaCandidatoForm>
        <FormDatiAnagrafici />
        <FormEstremiIdentificazione />
        <FormRecapiti />
        <FormDatiResidenza />
        <FormDomicilio />
        <FormDatiDisabilita />
        <FormDatiConcorso />
        <FormTitoloDiStudio />
      </ModificaCandidatoForm>
    </div>
  );
};

export default ModificaCandidato;
