import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ElencoPosizioniOrganizzativeContestoForm,
  FormsControllerApi,
  PagedDtoPosizioneOrganizzativaDto,
  PosizioneOrganizzativaControllerApi,
  PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest,
  PosizioneOrganizzativaDto,
  PosizioneOrganizzativaInsertRequestDto,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';

export interface PosizioneOrganizzativaState {
  searchResult: PagedDtoPosizioneOrganizzativaDto;
  searchStatus: string;
  searchInput: SearchPoRequest | null;
  formSearchComponents: ElencoPosizioniOrganizzativeContestoForm | null;
  detailsResult: PosizioneOrganizzativaDto | null;
  insertStatus: string;
  detailsStatus: string;
  editStatus: string;
  associaStatus: string;
  disassociaStatus: string;
  disattivaStatus: string;
  istituzioneAvvisoStatus: string;
  determinaNominaStatus: string;
  protocollaStatus: string;
  comunicaStatus: string;
}

export interface SearchPoRequest {
  pageSize?: number;
  pageNum?: number;
  denominazione?: string;
  strutturaOrganizzativa?: string;
  stato?:
    | 'BOZZA'
    | 'INSERITA'
    | 'INSERITA_E_COMUNICATA'
    | 'CREATA'
    | 'ASSOCIABILE'
    | 'ASSOCIATA'
    | 'DISATTIVATA';
  tipo?: 'PO' | 'PO_EQUIPARATA';
  sort?: string;
  direction?: 'ASC' | 'DESC';
  q?: string;
}

export interface detailParam {
  idCodiceIncarico: string;
}

export interface posizioneOrganizzativa {
  codiceUnivoco: string;
  denominazione: string;
  declaratoria: string;
  dataInizioValidita: string;
  dataFineValidita: string;
  strutturaOrganizzativa: string;
  tipo: string;
  email: string;
  pec: string;
  telefono: string;
  fax: string;
  indirizzo: string;
  citta: string;
}

const initialState: PosizioneOrganizzativaState = {
  searchResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  searchStatus: '',
  searchInput: null,
  formSearchComponents: null,
  insertStatus: '',
  detailsStatus: '',
  editStatus: '',
  detailsResult: null,
  associaStatus: '',
  disassociaStatus: '',
  disattivaStatus: '',
  istituzioneAvvisoStatus: '',
  determinaNominaStatus: '',
  protocollaStatus: '',
  comunicaStatus: '',
};

export const searchPosizioniOrganizzative = createAsyncThunk(
  'posizioniOrganizzative/search',
  async (args: SearchPoRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.searchPosizioneOrganizzativa(args);
    return response.data;
  }
);

export const loadPOFormSearch = createAsyncThunk(
  'posizioniOrganizzative/loadFormSearch',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoPosizioniOrganizzativeContestoForm();
    return response.data;
  }
);

export const detailsPosizioneOrganizzativa = createAsyncThunk(
  'posizioniOrganizzative/details',
  async (detailsParam: detailParam) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.detailPosizioneOrganizzativa({
      idCodiceIncarico: detailsParam.idCodiceIncarico,
    });
    return response.data;
  }
);

export const insertPosizioneOrganizzativa = createAsyncThunk(
  'posizioniOrganizzative/insert',
  async (posizioneOrganizzativa: PosizioneOrganizzativaInsertRequestDto) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.insertPosizioneOrganizzativa({
      posizioneOrganizzativaInsertRequestDto: posizioneOrganizzativa,
    });
    return response.data;
  }
);

export const editPosizioneOrganizzativa = createAsyncThunk(
  'posizioniOrganizzative/edit',
  async (params: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: params.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: params.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);

export const protocollaPO = createAsyncThunk(
  'posizioniOrganizzative/protocolla',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: param.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: param.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);

//TODO: rinominare in quanto questa è la vera protocollazione
export const comunicaPO = createAsyncThunk(
  'posizioniOrganizzative/comunica',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: param.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: param.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);

export const associaPO = createAsyncThunk(
  'posizioniOrganizzative/associa',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: param.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: param.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);

export const disassociaPO = createAsyncThunk(
  'posizioniOrganizzative/disassocia',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: param.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: param.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);

export const disattivaPO = createAsyncThunk(
  'posizioniOrganizzative/disattiva',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: param.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: param.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);

export const uploadDeterminaIstituzioneAvviso = createAsyncThunk(
  'posizioniOrganizzative/uploadDeterminaIstituzione',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa(param);
    return response.data;
  }
);

export const uploadDeterminaNomina = createAsyncThunk(
  'posizioniOrganizzative/uploadDeterminaNomina',
  async (param: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest) => {
    const api = new PosizioneOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchPosizioneOrganizzativa({
      idCodiceIncarico: param.idCodiceIncarico,
      patchPosizioneOrganizzativaRequest: param.patchPosizioneOrganizzativaRequest,
    });
    return response.data;
  }
);
/**************************************
 *********** TIPOLOGICHE *************
 *************************************/

export const getDipendentiOptsPO = createAsyncThunk(
  'posizioniOrganizzative/tipologicheDipendenti',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getAssociaPosizioniOrganizzativeContestoForm();
    return response.data;
  }
);

export const getTipoByDipendente = createAsyncThunk(
  'posizioniOrganizzative/tipologicheByDipendente',
  async (idDipendente: number) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente({
      idDipendente,
    });
    return response.data;
  }
);

export const getStrutturaOrganizzativaOpts = createAsyncThunk(
  'posizioniOrganizzative/SOtipologiche',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getCreaPosizioniOrganizzativeContestoForm();
    return response.data;
  }
);

export const posizioneOrganizzativaSlice = createSlice({
  name: 'posizioneOrganizzativaSlice',
  initialState,
  reducers: {
    resetDetailResult: (state) => {
      state.detailsResult = null;
    },
    resetStatus: (state) => {
      state.insertStatus = '';
      state.detailsStatus = '';
      state.editStatus = '';
      state.associaStatus = '';
      state.disassociaStatus = '';
      state.disattivaStatus = '';
      state.istituzioneAvvisoStatus = '';
      state.determinaNominaStatus = '';
      state.protocollaStatus = '';
      state.comunicaStatus = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchPosizioniOrganizzative.pending, (state: any, action: any) => {
      state.searchStatus = STATUS_PENDING;
    });
    builder.addCase(
      searchPosizioniOrganizzative.fulfilled,
      (state: PosizioneOrganizzativaState, action: any) => {
        state.searchResult = action.payload;
        state.searchStatus = STATUS_FULLFILLED;
        state.searchInput = action.meta.arg;
      }
    );
    builder.addCase(searchPosizioniOrganizzative.rejected, (state: any, action: any) => {
      state.searchStatus = STATUS_REJECTED;
    });

    builder.addCase(
      loadPOFormSearch.pending,
      (state: PosizioneOrganizzativaState, action: any) => {}
    );
    builder.addCase(
      loadPOFormSearch.fulfilled,
      (state: PosizioneOrganizzativaState, action: any) => {
        state.formSearchComponents = action.payload;
      }
    );
    builder.addCase(
      loadPOFormSearch.rejected,
      (state: PosizioneOrganizzativaState, action: any) => {}
    );

    builder.addCase(
      detailsPosizioneOrganizzativa.pending,
      (state: PosizioneOrganizzativaState, action) => {
        state.detailsStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      detailsPosizioneOrganizzativa.fulfilled,
      (state: PosizioneOrganizzativaState, action) => {
        state.detailsResult = action.payload;
        state.detailsStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      detailsPosizioneOrganizzativa.rejected,
      (state: PosizioneOrganizzativaState, action) => {
        state.detailsStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      insertPosizioneOrganizzativa.pending,
      (state: PosizioneOrganizzativaState, action) => {
        state.insertStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      insertPosizioneOrganizzativa.fulfilled,
      (state: PosizioneOrganizzativaState, action) => {
        state.insertStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      insertPosizioneOrganizzativa.rejected,
      (state: PosizioneOrganizzativaState, action) => {
        state.insertStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      editPosizioneOrganizzativa.pending,
      (state: PosizioneOrganizzativaState, action) => {
        state.editStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      editPosizioneOrganizzativa.fulfilled,
      (state: PosizioneOrganizzativaState, action) => {
        state.editStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      editPosizioneOrganizzativa.rejected,
      (state: PosizioneOrganizzativaState, action) => {
        state.editStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(protocollaPO.pending, (state: PosizioneOrganizzativaState, action) => {
      state.protocollaStatus = STATUS_PENDING;
    });
    builder.addCase(protocollaPO.fulfilled, (state: PosizioneOrganizzativaState, action) => {
      state.protocollaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(protocollaPO.rejected, (state: PosizioneOrganizzativaState, action) => {
      state.protocollaStatus = STATUS_REJECTED;
    });

    builder.addCase(comunicaPO.pending, (state: PosizioneOrganizzativaState, action) => {
      state.comunicaStatus = STATUS_PENDING;
    });
    builder.addCase(comunicaPO.fulfilled, (state: PosizioneOrganizzativaState, action) => {
      state.comunicaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(comunicaPO.rejected, (state: PosizioneOrganizzativaState, action) => {
      state.comunicaStatus = STATUS_REJECTED;
    });

    builder.addCase(associaPO.pending, (state: PosizioneOrganizzativaState, action) => {
      state.associaStatus = STATUS_PENDING;
    });
    builder.addCase(associaPO.fulfilled, (state: PosizioneOrganizzativaState, action) => {
      state.associaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(associaPO.rejected, (state: PosizioneOrganizzativaState, action) => {
      state.associaStatus = STATUS_REJECTED;
    });

    builder.addCase(disassociaPO.pending, (state: PosizioneOrganizzativaState, action) => {
      state.disassociaStatus = STATUS_PENDING;
    });
    builder.addCase(disassociaPO.fulfilled, (state: PosizioneOrganizzativaState, action) => {
      state.disassociaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(disassociaPO.rejected, (state: PosizioneOrganizzativaState, action) => {
      state.disassociaStatus = STATUS_REJECTED;
    });

    builder.addCase(disattivaPO.pending, (state: PosizioneOrganizzativaState, action) => {
      state.disattivaStatus = STATUS_PENDING;
    });
    builder.addCase(disattivaPO.fulfilled, (state: PosizioneOrganizzativaState, action) => {
      state.disattivaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(disattivaPO.rejected, (state: PosizioneOrganizzativaState, action) => {
      state.disattivaStatus = STATUS_REJECTED;
    });

    builder.addCase(
      uploadDeterminaIstituzioneAvviso.pending,
      (state: PosizioneOrganizzativaState, action) => {
        state.istituzioneAvvisoStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      uploadDeterminaIstituzioneAvviso.fulfilled,
      (state: PosizioneOrganizzativaState, action) => {
        state.istituzioneAvvisoStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      uploadDeterminaIstituzioneAvviso.rejected,
      (state: PosizioneOrganizzativaState, action) => {
        state.istituzioneAvvisoStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(uploadDeterminaNomina.pending, (state: PosizioneOrganizzativaState, action) => {
      state.determinaNominaStatus = STATUS_PENDING;
    });
    builder.addCase(
      uploadDeterminaNomina.fulfilled,
      (state: PosizioneOrganizzativaState, action) => {
        state.determinaNominaStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      uploadDeterminaNomina.rejected,
      (state: PosizioneOrganizzativaState, action) => {
        state.determinaNominaStatus = STATUS_REJECTED;
      }
    );
  },
});

export const { resetStatus, resetDetailResult } = posizioneOrganizzativaSlice.actions;

export default posizioneOrganizzativaSlice.reducer;
