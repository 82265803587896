import { Col, FormGroup, Input, Row } from 'design-react-kit';
import AccordionElement from '../accordion-element/AccordionElement';
import { useCompositeForm } from '../../hooks/useCompositeForm';

export interface IFormRecapiti {
  pec?: string;
  email?: string;
  telefonoAbitazione?: string;
  telefonoCellulare?: string;
}

const FormRecapiti: React.FC<{
  initValuesProps?: IFormRecapiti;
  readingOnly?: boolean;
}> = ({ initValuesProps, readingOnly }) => {
  const { values, handleChange, errors, touched, handleBlur } = useCompositeForm();

  return (
    <AccordionElement title="Recapiti">
      <>
        <Row className="mt-4">
          <Col md={6} className="px-1">
            <FormGroup>
              <Input
                id="indirizzoPec"
                name="indirizzoPec"
                type="email"
                label="Pec"
                placeholder="Inserisci indirizzo PEC"
                value={values.indirizzoPec}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.indirizzoPec && !!errors.indirizzoPec}
                aria-describedby="indirizzoPecError"
                infoText={touched.indirizzoPec && errors.indirizzoPec && errors.indirizzoPec}
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>

          <Col md={6} className="px-1">
            <FormGroup>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Inserisci indirizzo email"
                label="E-mail"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-describedby="emailError"
                invalid={touched.email && !!errors.email}
                infoText={touched.email && errors.email && errors.email}
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="px-1">
            <FormGroup>
              <Input
                id="telefonoAbitazione"
                name="telefonoAbitazione"
                type="text"
                label="Tel abitazione"
                placeholder="Telefono abitazione"
                value={values.telefonoAbitazione}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.telefonoAbitazione && !!errors.telefonoAbitazione}
                aria-describedby="telefonoAbitazioneError"
                infoText={
                  touched.telefonoAbitazione &&
                  errors.telefonoAbitazione &&
                  errors.telefonoAbitazione
                }
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>

          <Col md={6} className="px-1">
            <FormGroup>
              <Input
                id="telefonoCellulare"
                name="telefonoCellulare"
                type="text"
                label="Tel cellulare"
                placeholder="Telefono cellulare"
                value={values.telefonoCellulare}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.telefonoCellulare && !!errors.telefonoCellulare}
                aria-describedby="telefonoCellulareError"
                infoText={
                  touched.telefonoCellulare && errors.telefonoCellulare && errors.telefonoCellulare
                }
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    </AccordionElement>
  );
};

export default FormRecapiti;
