import { Button } from 'reactstrap';
import './Login.scss';
import logoArpal from '../../assets/images/Arpal_puglia_blue.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserFromCookieAuUserToken } from '../../utility/cookie';
import Select from 'react-select';
import { AuthPublicControllerApi, Configuration } from '../../services/ms-anagrafica-unica';
import { SelectItem } from '../../store/ConvenzioniSlice';
import { HREF_SCRIVANIA_VIRTUALE } from '../../components/layout/sidemenu/sidemenuConstants';

function Login() {
  const api = new AuthPublicControllerApi(
    new Configuration({ basePath: process.env.REACT_APP_MS_AU_BASE_URL })
  );
  const navigate = useNavigate();
  const [userItems, setUserItems] = useState<SelectItem[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (process.env.REACT_APP_MS_ACTIVATE_AUTH === 'false') {
      const getUserMockedList = async () => {
        try {
          const response = await api.getDipendentiRegionaliConRuolo();

          const newUserItems = response.data.map((item) => ({
            label: item.nomeCompleto!,
            value: item.id!,
          }));

          setUserItems(newUserItems);
        } catch (error) {
          console.error('Error fetching users: ', error);
        }
      };
      getUserMockedList();
    }

    if (getUserFromCookieAuUserToken()) {
      navigate(HREF_SCRIVANIA_VIRTUALE);
    }
  }, []);

  const login = () => {
    console.log('process.env.REACT_APP_MS_AU_LOGIN_URL: ', process.env.REACT_APP_MS_AU_LOGIN_URL);
    let loginRedirectUrl = process.env.REACT_APP_MS_AU_LOGIN_URL || '';

    if (process.env.REACT_APP_MS_ACTIVATE_AUTH === 'false' && !!selectedUser) {
      loginRedirectUrl += `?idDipendente=${selectedUser}`;
    }

    window.location.href = loginRedirectUrl;
    return;
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ minHeight: '75vh' }}
    >
      <div className="login-box">
        <img
          src={logoArpal}
          alt="logo ARPAL Puglia"
          className="img-fluid"
          style={{ width: 300, padding: '20px' }}
        />
        <h1>Anagrafica Unica</h1>
        <p>Accedi all'area privata</p>
        {process.env.REACT_APP_MS_ACTIVATE_AUTH === 'false' && (
          <Select
            key={'users'}
            placeholder="Seleziona un utente"
            name="users"
            options={userItems}
            className="form-group col-12"
            onChange={(e) => setSelectedUser(Number(e?.value))}
          />
        )}
        <Button color="primary" onClick={login}>
          Login
        </Button>
      </div>
    </div>
  );
}

export default Login;
