import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getContestoProposteIncarichi,
  getPropostaIncarico,
  reset,
} from '../../store/proposteIncarichiSlice';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DatiAttoDto, IncaricoDirigenzialeDto } from '../../services/ms-anagrafica-unica';
import { Button, Table } from 'design-react-kit';
import { StrutturaOrganizzativaTipoEnum } from '../../services/ms-anagrafica-unica/model/struttura-organizzativa';
import { format } from 'date-fns';
import DettaglioAtto from '../../components/dettaglio-atto-cifra/DettaglioAtto';
import { showFieldIfNotNull } from 'utility/utility';
import DocumentView from 'components/common/document-view/DocumentView';

const VisualizzaIncarico = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { propostaSelezionataResult, propostaSelezionataStatus, contestoResult } = useAppSelector(
    (state) => state.proposteIncarichi
  );
  const [datiAtto, setDatiAtto] = useState<DatiAttoDto>({});

  useEffect(() => {
    dispatch(reset());
    dispatch(getContestoProposteIncarichi());
    dispatch(getPropostaIncarico({ id: Number(id) }))
      .unwrap()
      .then((proposta) => {
        setDatiAtto(proposta.assegnazioneIncarico?.datiAtto || {});
      });
  }, []);

  const retryButtonCallback = () => {
    dispatch(getPropostaIncarico({ id: Number(id) }));
  };

  const riepilogoPropostaIncaricoVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
    const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(
      (stato) =>
        stato.value === 'INSERITA' ||
        stato.value === 'ACCETTATA' ||
        stato.value === 'RIGETTATA' ||
        stato.value === 'NON_APPROVATA' ||
        stato.value === 'APPROVATA' ||
        stato.value === 'ASSEGNATA' ||
        stato.value === 'TERMINATA'
    );

    return proposta.stato && statiAmmessi?.find((stato) => stato.valore === proposta.stato);
  };

  const valutazionePersonaleVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
    const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(
      (stato) =>
        stato.value === 'ACCETTATA' ||
        stato.value === 'RIGETTATA' ||
        stato.value === 'NON_APPROVATA' ||
        stato.value === 'APPROVATA' ||
        stato.value === 'ASSEGNATA' ||
        stato.value === 'TERMINATA'
    );

    return proposta.stato && statiAmmessi?.find((stato) => stato.valore === proposta.stato);
  };

  const valutazioneGiuntaVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
    const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(
      (stato) =>
        stato.value === 'NON_APPROVATA' ||
        stato.value === 'APPROVATA' ||
        stato.value === 'ASSEGNATA' ||
        stato.value === 'TERMINATA'
    );

    return (
      proposta.stato &&
      statiAmmessi?.find((stato) => stato.valore === proposta.stato) &&
      (proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaTipoEnum.Sezione ||
        proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaTipoEnum.Dipartimento)
    );
  };

  const assegnazioneVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
    const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(
      (stato) => stato.label === 'ASSEGNATA' || stato.label === 'TERMINATA'
    );
    return proposta.stato && statiAmmessi?.find((stato) => stato.valore === proposta.stato);
  };

  const formatDataInizio = (proposta: IncaricoDirigenzialeDto) => {
    return (
      proposta.assegnazioneIncarico &&
      proposta.assegnazioneIncarico.dataInizio &&
      format(new Date(proposta.assegnazioneIncarico.dataInizio), 'dd/MM/yyyy')
    );
  };
  const formatDataFine = (proposta: IncaricoDirigenzialeDto) => {
    return (
      proposta.assegnazioneIncarico &&
      proposta.assegnazioneIncarico.dataFine &&
      format(new Date(proposta.assegnazioneIncarico.dataFine), 'dd/MM/yyyy')
    );
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI}
        title={'visualizzazioneIncarico.titolo'}
      />
      {propostaSelezionataStatus === STATUS_FULLFILLED && !!propostaSelezionataResult && (
        <>
          <div className="col-sm-12">
            <div className="info-box-light-so bg-light-gray mb-4">
              <h5 className="pb-2 border-bottom font-weight-bold">Riepilogo della proposta</h5>
              <div className="row">
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">
                        {t('valutazioneProposta.riepilogo.denominazioneSo')}
                      </dt>
                      <dd className="col-sm-12">
                        {showFieldIfNotNull(propostaSelezionataResult.denominazioneSo)}
                      </dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">
                        {t('valutazioneProposta.riepilogo.codiceStruttura')}
                      </dt>
                      <dd className="col-sm-12">
                        {showFieldIfNotNull(propostaSelezionataResult.codiceIncaricoSo)}
                      </dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">
                        {t('valutazioneProposta.riepilogo.tipoStruttura')}
                      </dt>
                      <dd className="col-sm-12">
                        {showFieldIfNotNull(propostaSelezionataResult.tipoStrutturaOrganizzativa)}
                      </dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">
                        {t('valutazioneProposta.riepilogo.tipoIncarico')}
                      </dt>
                      <dd className="col-sm-12">
                        {showFieldIfNotNull(propostaSelezionataResult.tipoIncarico)}
                      </dd>
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">{t('valutazioneProposta.riepilogo.candidato')}</dt>
                      {propostaSelezionataResult.candidatoInterno ? (
                        <dd className="col-sm-12">
                          {showFieldIfNotNull(
                            propostaSelezionataResult.dipendenteRegionale?.nomeCompleto
                          )}
                        </dd>
                      ) : (
                        <dd className="col-sm-12">{`${propostaSelezionataResult.nomeCognomeEsterno} ${propostaSelezionataResult.codiceFiscaleEsterno ? `(${propostaSelezionataResult.codiceFiscaleEsterno})` : ''}`}</dd>
                      )}
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">
                        {t('valutazioneProposta.riepilogo.candidatoInterno')}
                      </dt>
                      {propostaSelezionataResult.candidatoInterno ? (
                        <dd className="col-sm-12">{t('si')}</dd>
                      ) : (
                        <dd className="col-sm-12">{t('no')}</dd>
                      )}
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">{t('valutazioneProposta.riepilogo.inComando')}</dt>
                      {propostaSelezionataResult.inComando ? (
                        <dd className="col-sm-12">{t('si')}</dd>
                      ) : (
                        <dd className="col-sm-12">{t('no')}</dd>
                      )}
                    </div>
                  </div>
                </dl>
                <dl className="col-sm-3">
                  <div className="row">
                    <div className="col-sm-12">
                      <dt className="col-sm-12">
                        {t('valutazioneProposta.riepilogo.estremiAttoConferimento')}
                      </dt>
                      <dd className="col-sm-12">
                        {showFieldIfNotNull(
                          propostaSelezionataResult?.assegnazioneIncarico?.estremiAttoConferimento
                        )}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
              <div className="row">
                <dl className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-12">
                      {datiAtto?.oggetto && (
                        <DettaglioAtto
                          datiAtto={datiAtto}
                          titolo="Dati Atto Conferimento"
                        ></DettaglioAtto>
                      )}
                    </div>
                  </div>
                </dl>
              </div>
              {riepilogoPropostaIncaricoVisualizzabile(propostaSelezionataResult) && (
                <>
                  <div className="row border-top">
                    <dl className="col-sm-12 mt-2">
                      <div className="row">
                        <div className="col-sm-12">
                          <dt className="col-sm-12">
                            {t('visualizzazioneIncarico.riepilogo.note')}
                          </dt>
                          <dd className="col-sm-12">
                            {propostaSelezionataResult.note || <small>Nessuna nota inserita</small>}
                          </dd>
                        </div>
                      </div>
                    </dl>
                    <dl className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12">
                          <dt className="col-sm-12">
                            {t('visualizzazioneIncarico.riepilogo.documentoProposta')}
                          </dt>
                          <dd className="col-sm-12">
                            <DocumentView
                              documentName={''}
                              documento={propostaSelezionataResult.documenti?.at(0)?.documento}
                              view
                              download
                            />
                          </dd>
                        </div>
                      </div>
                    </dl>
                  </div>
                </>
              )}
            </div>

            {valutazionePersonaleVisualizzabile(propostaSelezionataResult) && (
              <div className="info-box-light-so bg-light-gray mb-4">
                <h5 className="pb-2 border-bottom font-weight-bold">Valutazione del Personale</h5>
                <div className="row">
                  <dl className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">
                          {t('visualizzazioneIncarico.riepilogo.valutazionePersonale')}
                        </dt>
                        <dd className="col-sm-12">
                          {
                            propostaSelezionataResult.valutazionePropostaIncaDirigenziale
                              ?.esitoValutazioneProposta
                          }
                        </dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">{t('visualizzazioneIncarico.riepilogo.note')}</dt>
                        <dd className="col-sm-12">
                          {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.note || (
                            <small>Nessuna nota inserita</small>
                          )}
                        </dd>
                      </div>
                    </div>
                  </dl>
                  {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(
                    0
                  ) && (
                    <dl className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12">
                          <dt className="col-sm-12">
                            {t('visualizzazioneIncarico.riepilogo.documentoValutazione')}
                          </dt>
                          <dd className="col-sm-12">
                            <DocumentView
                              documentName={''}
                              documento={
                                propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(
                                  0
                                )?.documento
                              }
                              view
                              download
                            />
                          </dd>
                        </div>
                      </div>
                    </dl>
                  )}
                </div>
              </div>
            )}
            {valutazioneGiuntaVisualizzabile(propostaSelezionataResult) && (
              <div className="info-box-light-so bg-light-gray mb-4">
                <h5 className="pb-2 border-bottom font-weight-bold">Valutazione della Giunta</h5>
                <div className="row">
                  <dl className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">
                          {t('visualizzazioneIncarico.riepilogo.valutazioneGiunta')}
                        </dt>
                        <dd className="col-sm-12">
                          {
                            propostaSelezionataResult.valutazionePropostaIncaDirigenziale
                              ?.esitoGiuntaValutazioneProposta
                          }
                        </dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">{t('visualizzazioneIncarico.riepilogo.note')}</dt>
                        <dd className="col-sm-12">
                          {propostaSelezionataResult.valutazionePropostaIncaDirigenziale
                            ?.noteGiunta || <small>Nessuna nota inserita</small>}
                        </dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">
                          {t('visualizzazioneIncarico.riepilogo.documentoValutazioneGiunta')}
                        </dt>
                        <dd className="col-sm-12">
                          <DocumentView
                            documentName={''}
                            documento={
                              propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(
                                0
                              )?.documento
                            }
                            view
                            download
                          />
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>
              </div>
            )}
            {assegnazioneVisualizzabile(propostaSelezionataResult) && (
              <div className="info-box-light-so bg-light-gray mb-4">
                <h5 className="pb-2 border-bottom font-weight-bold">Assegnazione</h5>
                <div className="row">
                  <dl className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">
                          {t('visualizzazioneIncarico.riepilogo.dipendenteAssegnato')}
                        </dt>
                        <dd className="col-sm-12">
                          {propostaSelezionataResult.assegnazioneIncarico?.nomeCompletoDipendente}
                        </dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">{t('visualizzazioneIncarico.riepilogo.cid')}</dt>
                        <dd className="col-sm-12">
                          {propostaSelezionataResult.assegnazioneIncarico?.idDipendente}
                        </dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">
                          {t('visualizzazioneIncarico.riepilogo.dataInizioIncarico')}
                        </dt>
                        <dd className="col-sm-12">{formatDataInizio(propostaSelezionataResult)}</dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">
                          {t('visualizzazioneIncarico.riepilogo.dataFineIncarico')}
                        </dt>
                        <dd className="col-sm-12">{formatDataFine(propostaSelezionataResult)}</dd>
                      </div>
                    </div>
                  </dl>
                  <dl className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <dt className="col-sm-12">{t('visualizzazioneIncarico.riepilogo.atto')}</dt>
                        <dd className="col-sm-12">
                          {propostaSelezionataResult.assegnazioneIncarico?.estremiAttoConferimento}
                        </dd>
                      </div>
                    </div>
                  </dl>
                  {propostaSelezionataResult.assegnazioneIncarico &&
                    propostaSelezionataResult.assegnazioneIncarico.elencoModifiche &&
                    propostaSelezionataResult.assegnazioneIncarico.elencoModifiche.length > 0 && (
                      <Table>
                        <tr>
                          <th>N modifica</th>
                          <th>Note</th>
                          <th>File</th>
                        </tr>
                        {propostaSelezionataResult.assegnazioneIncarico.elencoModifiche.map(
                          (proposta) => (
                            <tr key={proposta.codiceUnivoco}>
                              <td>{proposta.nmodifica}</td>
                              <td>{proposta.note}</td>
                              <td>
                                <DocumentView
                                  documentName=""
                                  documento={proposta.documento}
                                  showFileName
                                  view
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </Table>
                    )}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              outline
              className="mt-2 mr-2"
              onClick={() => {
                navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`);
              }}
            >
              {t('annulla')}{' '}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default VisualizzaIncarico;
