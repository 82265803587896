import axios from 'axios';

const getCsrfToken = () => {
  const name = 'XSRF-TOKEN=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    let tokenValue = c.split('=')[1];

    if (tokenValue && tokenValue.trim() !== '') {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};

const xsrfInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      if (config.method !== 'get') {
        // Recupera il token XSRF dai cookie (assumendo che il nome del cookie sia 'XSRF-TOKEN')
        config.headers['X-XSRF-TOKEN'] = getCsrfToken();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default xsrfInterceptor;
