import axios from 'axios';
import { DocumentoUploaded } from 'components/crea-candidato-form/types';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';

const useRegistraDocumenti = () => {
  const dispatch = useAppDispatch();

  const registraDocumenti = async (documenti?: DocumentoUploaded[], onBoardingId?: number) => {
    if (!documenti || documenti.length < 1) return;
    if (!onBoardingId) return;

    const mappedDocumenti = documenti.map((documento) => ({
      idDocumento: documento.documentoDto.id,
      idTipoDocumento: documento.tipo,
    }));

    try {
      const registraDocumenti = await axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/area-documenti/registra/${onBoardingId}`,
        mappedDocumenti
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Documenti non inseriti',
          stato: 'error',
          messaggio: 'I documenti non sono stati inseriti',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };
  return registraDocumenti;
};

export default useRegistraDocumenti;
