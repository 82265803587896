import { useState, useCallback } from 'react';
import { utils, writeFile } from 'xlsx';
import axios from 'axios';

interface PageableResponse<T> {
  content: T[]; // Array of data items on the current page
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean; // Indicates if this is the last page
  totalElements: number; // Total number of elements in the dataset
  totalPages: number; // Total number of pages
  first: boolean; // Indicates if this is the first page
  size: number; // Size of the page
  number: number; // Current page number
  sort: {
    empty: boolean;
    unsorted: boolean;
    sorted: boolean;
  };
  numberOfElements: number; // Number of elements on this page
  empty: boolean; // Indicates if the page is empty
}

interface UseExcelExportReturn<T> {
  loading: boolean;
  error: string | null;
  exportToExcel: () => Promise<void>; // Function to trigger export
}

/**
 * Custom hook for exporting paginated data to Excel with dynamic filters.
 * @param endpoint - API endpoint for data fetching.
 * @param filters - Filters to apply to the request.
 * @param pageSize - Number of items per page (default: 10).
 * @param sheetName - Name of the Excel sheet (default: "Sheet1").
 * @returns Hook state and function for triggering the export.
 */
export const useExcelExportWithFilters = <T,>(
  endpoint: string,
  filters: Record<string, string | number>,
  pageSize: number = 10,
  sheetName: string = 'Sheet1'
): UseExcelExportReturn<T> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Recursive function to fetch all data with filters
  const fetchAllData = useCallback(async (): Promise<T[]> => {
    let allData: T[] = [];
    let page = 1; // API uses 0-based indexing for pages
    let lastPage = false;

    try {
      while (!lastPage) {
        const response = await axios.get<PageableResponse<T>>(endpoint, {
          params: {
            ...filters, // Apply filters
            page,
            size: pageSize,
          },
        });

        const { content, last } = response.data;
        allData = [...allData, ...content];
        lastPage = last; // Check if this is the last page
        page += 1;
      }
    } catch (err) {
      throw new Error('Error fetching data');
    }

    return allData;
  }, [endpoint, filters, pageSize]);

  // Function to export data as an Excel file
  const exportToExcel = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      // Fetch all data
      const allData = await fetchAllData();

      if (allData.length === 0) {
        throw new Error('No data available to export');
      }

      // Convert data to a worksheet
      const worksheet = utils.json_to_sheet(allData);

      // Create a workbook and append the worksheet
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, sheetName);

      // Trigger the download
      writeFile(workbook, `${sheetName}.xlsx`);
    } catch (err: any) {
      setError(err.message || 'An error occurred during export');
    } finally {
      setLoading(false);
    }
  }, [fetchAllData, sheetName]);

  return { loading, error, exportToExcel };
};
