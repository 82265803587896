/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PagedDtoPosizioneOrganizzativaDto } from '../model';
// @ts-ignore
import { PatchPosizioneOrganizzativaRequest } from '../model';
// @ts-ignore
import { PosizioneOrganizzativaDto } from '../model';
// @ts-ignore
import { PosizioneOrganizzativaInsertRequestDto } from '../model';
/**
 * PosizioneOrganizzativaControllerApi - axios parameter creator
 * @export
 */
export const PosizioneOrganizzativaControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * API Rest per il dettaglio di una posizione organizzativa
     * @summary Dettaglio posizione organizzativa
     * @param {string} idCodiceIncarico
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailPosizioneOrganizzativa: async (
      idCodiceIncarico: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idCodiceIncarico' is not null or undefined
      assertParamExists('detailPosizioneOrganizzativa', 'idCodiceIncarico', idCodiceIncarico);
      const localVarPath = `/v1/posizioni-organizzative/{idCodiceIncarico}`.replace(
        `{${'idCodiceIncarico'}}`,
        encodeURIComponent(String(idCodiceIncarico))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'inserimento di una nuova posizione organizzativa
     * @summary Inserimento posizione organizzativa
     * @param {PosizioneOrganizzativaInsertRequestDto} posizioneOrganizzativaInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertPosizioneOrganizzativa: async (
      posizioneOrganizzativaInsertRequestDto: PosizioneOrganizzativaInsertRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'posizioneOrganizzativaInsertRequestDto' is not null or undefined
      assertParamExists(
        'insertPosizioneOrganizzativa',
        'posizioneOrganizzativaInsertRequestDto',
        posizioneOrganizzativaInsertRequestDto
      );
      const localVarPath = `/v1/posizioni-organizzative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        posizioneOrganizzativaInsertRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch posizione organizzativa
     * @param {string} idCodiceIncarico
     * @param {PatchPosizioneOrganizzativaRequest} patchPosizioneOrganizzativaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchPosizioneOrganizzativa: async (
      idCodiceIncarico: string,
      patchPosizioneOrganizzativaRequest: PatchPosizioneOrganizzativaRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idCodiceIncarico' is not null or undefined
      assertParamExists('patchPosizioneOrganizzativa', 'idCodiceIncarico', idCodiceIncarico);
      // verify required parameter 'patchPosizioneOrganizzativaRequest' is not null or undefined
      assertParamExists(
        'patchPosizioneOrganizzativa',
        'patchPosizioneOrganizzativaRequest',
        patchPosizioneOrganizzativaRequest
      );
      const localVarPath = `/v1/posizioni-organizzative/{idCodiceIncarico}`.replace(
        `{${'idCodiceIncarico'}}`,
        encodeURIComponent(String(idCodiceIncarico))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchPosizioneOrganizzativaRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca delle posizioni organizzative
     * @summary Ricerca posizioni organizzative
     * @param {string} [denominazione]
     * @param {string} [strutturaOrganizzativa]
     * @param {'BOZZA' | 'INSERITA' | 'INSERITA_E_COMUNICATA' | 'CREATA' | 'ASSOCIABILE' | 'ASSOCIATA' | 'DISATTIVATA'} [stato]
     * @param {'PO' | 'PO_EQUIPARATA'} [tipo]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPosizioneOrganizzativa: async (
      denominazione?: string,
      strutturaOrganizzativa?: string,
      stato?:
        | 'BOZZA'
        | 'INSERITA'
        | 'INSERITA_E_COMUNICATA'
        | 'CREATA'
        | 'ASSOCIABILE'
        | 'ASSOCIATA'
        | 'DISATTIVATA',
      tipo?: 'PO' | 'PO_EQUIPARATA',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/posizioni-organizzative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (denominazione !== undefined) {
        localVarQueryParameter['denominazione'] = denominazione;
      }

      if (strutturaOrganizzativa !== undefined) {
        localVarQueryParameter['strutturaOrganizzativa'] = strutturaOrganizzativa;
      }

      if (stato !== undefined) {
        localVarQueryParameter['stato'] = stato;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['pageNum'] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PosizioneOrganizzativaControllerApi - functional programming interface
 * @export
 */
export const PosizioneOrganizzativaControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PosizioneOrganizzativaControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per il dettaglio di una posizione organizzativa
     * @summary Dettaglio posizione organizzativa
     * @param {string} idCodiceIncarico
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detailPosizioneOrganizzativa(
      idCodiceIncarico: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosizioneOrganizzativaDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.detailPosizioneOrganizzativa(
        idCodiceIncarico,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'inserimento di una nuova posizione organizzativa
     * @summary Inserimento posizione organizzativa
     * @param {PosizioneOrganizzativaInsertRequestDto} posizioneOrganizzativaInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertPosizioneOrganizzativa(
      posizioneOrganizzativaInsertRequestDto: PosizioneOrganizzativaInsertRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosizioneOrganizzativaDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertPosizioneOrganizzativa(
        posizioneOrganizzativaInsertRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Patch posizione organizzativa
     * @param {string} idCodiceIncarico
     * @param {PatchPosizioneOrganizzativaRequest} patchPosizioneOrganizzativaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchPosizioneOrganizzativa(
      idCodiceIncarico: string,
      patchPosizioneOrganizzativaRequest: PatchPosizioneOrganizzativaRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosizioneOrganizzativaDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchPosizioneOrganizzativa(
        idCodiceIncarico,
        patchPosizioneOrganizzativaRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca delle posizioni organizzative
     * @summary Ricerca posizioni organizzative
     * @param {string} [denominazione]
     * @param {string} [strutturaOrganizzativa]
     * @param {'BOZZA' | 'INSERITA' | 'INSERITA_E_COMUNICATA' | 'CREATA' | 'ASSOCIABILE' | 'ASSOCIATA' | 'DISATTIVATA'} [stato]
     * @param {'PO' | 'PO_EQUIPARATA'} [tipo]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPosizioneOrganizzativa(
      denominazione?: string,
      strutturaOrganizzativa?: string,
      stato?:
        | 'BOZZA'
        | 'INSERITA'
        | 'INSERITA_E_COMUNICATA'
        | 'CREATA'
        | 'ASSOCIABILE'
        | 'ASSOCIATA'
        | 'DISATTIVATA',
      tipo?: 'PO' | 'PO_EQUIPARATA',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoPosizioneOrganizzativaDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPosizioneOrganizzativa(
        denominazione,
        strutturaOrganizzativa,
        stato,
        tipo,
        pageNum,
        pageSize,
        sort,
        direction,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PosizioneOrganizzativaControllerApi - factory interface
 * @export
 */
export const PosizioneOrganizzativaControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PosizioneOrganizzativaControllerApiFp(configuration);
  return {
    /**
     * API Rest per il dettaglio di una posizione organizzativa
     * @summary Dettaglio posizione organizzativa
     * @param {PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailPosizioneOrganizzativa(
      requestParameters: PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PosizioneOrganizzativaDto> {
      return localVarFp
        .detailPosizioneOrganizzativa(requestParameters.idCodiceIncarico, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'inserimento di una nuova posizione organizzativa
     * @summary Inserimento posizione organizzativa
     * @param {PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertPosizioneOrganizzativa(
      requestParameters: PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PosizioneOrganizzativaDto> {
      return localVarFp
        .insertPosizioneOrganizzativa(
          requestParameters.posizioneOrganizzativaInsertRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch posizione organizzativa
     * @param {PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchPosizioneOrganizzativa(
      requestParameters: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PosizioneOrganizzativaDto> {
      return localVarFp
        .patchPosizioneOrganizzativa(
          requestParameters.idCodiceIncarico,
          requestParameters.patchPosizioneOrganizzativaRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca delle posizioni organizzative
     * @summary Ricerca posizioni organizzative
     * @param {PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPosizioneOrganizzativa(
      requestParameters: PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativaRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedDtoPosizioneOrganizzativaDto> {
      return localVarFp
        .searchPosizioneOrganizzativa(
          requestParameters.denominazione,
          requestParameters.strutturaOrganizzativa,
          requestParameters.stato,
          requestParameters.tipo,
          requestParameters.pageNum,
          requestParameters.pageSize,
          requestParameters.sort,
          requestParameters.direction,
          requestParameters.q,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for detailPosizioneOrganizzativa operation in PosizioneOrganizzativaControllerApi.
 * @export
 * @interface PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativaRequest
 */
export interface PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativaRequest {
  /**
   *
   * @type {string}
   * @memberof PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativa
   */
  readonly idCodiceIncarico: string;
}

/**
 * Request parameters for insertPosizioneOrganizzativa operation in PosizioneOrganizzativaControllerApi.
 * @export
 * @interface PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativaRequest
 */
export interface PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativaRequest {
  /**
   *
   * @type {PosizioneOrganizzativaInsertRequestDto}
   * @memberof PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativa
   */
  readonly posizioneOrganizzativaInsertRequestDto: PosizioneOrganizzativaInsertRequestDto;
}

/**
 * Request parameters for patchPosizioneOrganizzativa operation in PosizioneOrganizzativaControllerApi.
 * @export
 * @interface PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest
 */
export interface PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest {
  /**
   *
   * @type {string}
   * @memberof PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativa
   */
  readonly idCodiceIncarico: string;

  /**
   *
   * @type {PatchPosizioneOrganizzativaRequest}
   * @memberof PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativa
   */
  readonly patchPosizioneOrganizzativaRequest: PatchPosizioneOrganizzativaRequest;
}

/**
 * Request parameters for searchPosizioneOrganizzativa operation in PosizioneOrganizzativaControllerApi.
 * @export
 * @interface PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativaRequest
 */
export interface PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativaRequest {
  /**
   *
   * @type {string}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly denominazione?: string;

  /**
   *
   * @type {string}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly strutturaOrganizzativa?: string;

  /**
   *
   * @type {'BOZZA' | 'INSERITA' | 'INSERITA_E_COMUNICATA' | 'CREATA' | 'ASSOCIABILE' | 'ASSOCIATA' | 'DISATTIVATA'}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly stato?:
    | 'BOZZA'
    | 'INSERITA'
    | 'INSERITA_E_COMUNICATA'
    | 'CREATA'
    | 'ASSOCIABILE'
    | 'ASSOCIATA'
    | 'DISATTIVATA';

  /**
   *
   * @type {'PO' | 'PO_EQUIPARATA'}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly tipo?: 'PO' | 'PO_EQUIPARATA';

  /**
   *
   * @type {number}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly sort?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly direction?: 'ASC' | 'DESC';

  /**
   *
   * @type {string}
   * @memberof PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativa
   */
  readonly q?: string;
}

/**
 * PosizioneOrganizzativaControllerApi - object-oriented interface
 * @export
 * @class PosizioneOrganizzativaControllerApi
 * @extends {BaseAPI}
 */
export class PosizioneOrganizzativaControllerApi extends BaseAPI {
  /**
   * API Rest per il dettaglio di una posizione organizzativa
   * @summary Dettaglio posizione organizzativa
   * @param {PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosizioneOrganizzativaControllerApi
   */
  public detailPosizioneOrganizzativa(
    requestParameters: PosizioneOrganizzativaControllerApiDetailPosizioneOrganizzativaRequest,
    options?: AxiosRequestConfig
  ) {
    return PosizioneOrganizzativaControllerApiFp(this.configuration)
      .detailPosizioneOrganizzativa(requestParameters.idCodiceIncarico, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'inserimento di una nuova posizione organizzativa
   * @summary Inserimento posizione organizzativa
   * @param {PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosizioneOrganizzativaControllerApi
   */
  public insertPosizioneOrganizzativa(
    requestParameters: PosizioneOrganizzativaControllerApiInsertPosizioneOrganizzativaRequest,
    options?: AxiosRequestConfig
  ) {
    return PosizioneOrganizzativaControllerApiFp(this.configuration)
      .insertPosizioneOrganizzativa(
        requestParameters.posizioneOrganizzativaInsertRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch posizione organizzativa
   * @param {PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosizioneOrganizzativaControllerApi
   */
  public patchPosizioneOrganizzativa(
    requestParameters: PosizioneOrganizzativaControllerApiPatchPosizioneOrganizzativaRequest,
    options?: AxiosRequestConfig
  ) {
    return PosizioneOrganizzativaControllerApiFp(this.configuration)
      .patchPosizioneOrganizzativa(
        requestParameters.idCodiceIncarico,
        requestParameters.patchPosizioneOrganizzativaRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca delle posizioni organizzative
   * @summary Ricerca posizioni organizzative
   * @param {PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosizioneOrganizzativaControllerApi
   */
  public searchPosizioneOrganizzativa(
    requestParameters: PosizioneOrganizzativaControllerApiSearchPosizioneOrganizzativaRequest = {},
    options?: AxiosRequestConfig
  ) {
    return PosizioneOrganizzativaControllerApiFp(this.configuration)
      .searchPosizioneOrganizzativa(
        requestParameters.denominazione,
        requestParameters.strutturaOrganizzativa,
        requestParameters.stato,
        requestParameters.tipo,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.sort,
        requestParameters.direction,
        requestParameters.q,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
