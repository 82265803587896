import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/common/page-header/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Badge,
  Button,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import CustomSelect, {
  CustomSelectItem,
} from '../../../components/common/custom-select/CustomSelect';
import { getCreaBandoContestoForm, searchNullaOsta } from '../../../store/bandoSlice';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { HREF_CANDIDATURA_DETTAGLIO_NULLA_OSTA } from '../../../components/layout/sidemenu/sidemenuConstants';
import {
  IncaricoEssenzialeDto,
  NullaOstaControllerApiSearchNullaOstaRequest,
} from '../../../services/ms-anagrafica-unica';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utility/formatDate';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

const ElencoRichiestaNullaOsta = () => {
  type Direction = 'ASC' | 'DESC';

  interface SortingState {
    direction: Direction;
  }

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { searchNullaOstaInput, searchNullaOstaResult, searchNullaOstaStatus } = useAppSelector(
    (state) => state.bando
  );
  const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);
  const [formCollapseOpen, setFormCollapseOpen] = useState<boolean>(true);
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
  const [struttureOrganizzative, setStruttureOrganizzative] = useState<CustomSelectItem[]>([]);
  const [isOpenListDetails, toggleModalListDetails] = useState(false);

  const initialValuesSearchForm: NullaOstaControllerApiSearchNullaOstaRequest = {
    idSo: '',
    nomeCognomeDipendente: '',
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: '',
  };

  const schemaSearchForm = z.object({
    idSo: z.string().optional(),
    nomeCognomeDipendente: z.string().optional(),
  });

  const searchForm = useFormik({
    initialValues: initialValuesSearchForm,
    validationSchema: toFormikValidationSchema(schemaSearchForm),
    onSubmit: () => {
      doSearch();
    },
  });

  const doSearch = () => {
    dispatch(searchNullaOsta(searchForm.values));
  };

  const stampaStruttureDiAppartenenza = (struttureOrganizzative: IncaricoEssenzialeDto[]) => {
    return struttureOrganizzative.map((so, index) => so.descrizione);
  };

  const modalViewDetails = (list: any) => {
    if (list.length > 0) {
      setElencoDetails(list);
      toggleModalListDetails(!isOpenListDetails);
    }
  };

  useEffect(() => {
    dispatch(getCreaBandoContestoForm())
      .unwrap()
      .then((resp) => {
        if (resp) {
          const selectItems: Array<CustomSelectItem> = [];
          resp.struttureOrganizzative &&
            resp.struttureOrganizzative.forEach((f: any) => {
              selectItems.push({
                value: f.valore,
                label: f.label,
              });
            });
          setStruttureOrganizzative(selectItems);
        }
      });
    doSearch();
  }, []);

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  return (
    <div>
      <PageHeader showIcon={false} title={t('Elenco Richieste Nulla Osta')!} buttons={[]} />
      <Accordion className="form-custom form-bg filtri-ricerca mt-5" background="active">
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="form-row mt-5">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />
            <CustomSelect
              options={struttureOrganizzative}
              value={searchForm.values.idSo}
              placeholder="Seleziona la struttura organizzativa"
              invalid={!!searchForm.errors.idSo}
              infoText={''}
              onChange={(e) => searchForm.setFieldValue('idSo', e, false)}
              name="idSo"
              wrapperClass="col-md-6"
            />
            <Input
              type="text"
              id="nomeCognomeDipendente"
              placeholder="Nome e Cognome dipendente"
              wrapperClassName="col-md-6"
              name="nomeCognomeDipendente"
              value={searchForm.values.nomeCognomeDipendente}
              onChange={(e) =>
                searchForm.setFieldValue('nomeCognomeDipendente', e.target.value, false)
              }
            />
          </div>
          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                searchForm.setFieldValue('pageNum', 0);
                searchForm.handleSubmit();
              }}
            >
              Applica
            </Button>
            <Button outline color="primary" className="mt-2 mr-2" onClick={searchForm.handleReset}>
              Pulisci
            </Button>
          </div>
        </AccordionBody>
      </Accordion>
      <Modal
        isOpen={isOpenListDetails}
        toggle={() => toggleModalListDetails(!isOpenListDetails)}
        labelledBy="listDetails"
        centered
      >
        <ModalHeader
          toggle={() => {
            toggleModalListDetails(!isOpenListDetails);
            setElencoDetails(undefined);
          }}
          id="listDetails"
        >
          Elenco
        </ModalHeader>
        <ModalBody>
          <ul>{elencoDetails?.map((value, index) => <li key={index}>{value}</li>)}</ul>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggleModalListDetails(!isOpenListDetails);
              setElencoDetails(undefined);
            }}
          >
            Annulla
          </Button>
        </ModalFooter>
      </Modal>
      <div className="mt-4 position-relative">
        {searchNullaOstaStatus === STATUS_FULLFILLED &&
          searchNullaOstaResult?.totalElements === 0 && (
            <Alert color="info">Nessun risultato disponibile</Alert>
          )}
        {searchNullaOstaStatus === STATUS_FULLFILLED &&
          searchNullaOstaResult?.totalElements! > 0 && (
            <div className={searchNullaOstaResult?.totalPages === 1 ? 'mb-5' : ''}>
              <Table responsive striped>
                <TableTheadCustom
                  colonne={[
                    { titolo: 'Nome e Cognome dipendente', nomeColonna: 'nomeCompleto' },
                    { titolo: 'Struttura organizzativa', nomeColonna: 'so' },
                    { titolo: 'Data inoltro richesta', nomeColonna: 'data' },
                    { titolo: 'Esito' },
                    { titolo: 'Azioni' },
                  ]}
                  datiOrdinamento={searchForm.values}
                  eseguiOrdinamento={ordinamento}
                />

                <tbody>
                  {(searchNullaOstaResult?.data || []).map((x, i) => (
                    <tr key={i}>
                      <td>{x.dipendenteRegionale?.nomeCompleto}</td>
                      <td>
                        {x.dipendenteRegionale?.elencoStruttureOrganizzative &&
                        x.dipendenteRegionale?.elencoStruttureOrganizzative.length > 1 ? (
                          <Badge
                            color="primary"
                            className=" col-sm-12"
                            style={{ cursor: 'pointer', padding: '10px', fontSize: '15px' }}
                            onClick={() =>
                              modalViewDetails(
                                stampaStruttureDiAppartenenza(
                                  x.dipendenteRegionale?.elencoStruttureOrganizzative!
                                )
                              )
                            }
                          >
                            {`${x.dipendenteRegionale?.elencoStruttureOrganizzative.length} Strutture organizzative`}
                          </Badge>
                        ) : x.dipendenteRegionale?.elencoStruttureOrganizzative &&
                          x.dipendenteRegionale?.elencoStruttureOrganizzative.length === 1 ? (
                          <Badge
                            color="primary"
                            className=" col-sm-12"
                            style={{ padding: '10px', fontSize: '15px' }}
                          >{`${x.dipendenteRegionale.elencoStruttureOrganizzative[0].descrizione}`}</Badge>
                        ) : (
                          <Badge
                            color="primary"
                            className=" col-sm-12"
                            style={{ padding: '10px', fontSize: '15px' }}
                          >
                            {'Nessuna struttura associata'}
                          </Badge>
                        )}
                      </td>
                      <td>{formatDate(x.dataInserimento!, true)}</td>
                      <td>{x.stato || ' - '}</td>
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle nav>
                          <Icon icon="it-more-actions" />
                        </DropdownToggle>
                        <DropdownMenu className="no-arrow">
                          <LinkList>
                            <LinkListItem
                              size="medium"
                              onClick={() =>
                                navigate(
                                  `${HREF_CANDIDATURA_DETTAGLIO_NULLA_OSTA}/${x.codiceUnivoco}`
                                )
                              }
                            >
                              <span>{t('Visualizza')}</span>
                            </LinkListItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
      </div>
    </div>
  );
};

export default ElencoRichiestaNullaOsta;
