import {
  StrutturaOrganizzativaDto,
  StrutturaOrganizzativaDtoTipoEnum,
  ValutazionePropostaIncaDirigRequestDto,
} from '../../services/ms-anagrafica-unica';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getPropostaIncarico,
  getValutazioniPropostaIncarico,
  updateApprovazionePropostaIncarico,
} from '../../store/proposteIncarichiSlice';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Input, Label, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { DettaglioPropostaIncarico } from 'components/incarichi-dirigenziali/DettaglioPropostaIncarico';

export interface SODetailsForm {
  value: StrutturaOrganizzativaDto;
  fields: Array<string>;
}

const FORCE_PROTOCOLLA = true;

const InserimentoParere = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { searchValutazioniResult, propostaSelezionataResult, propostaSelezionataStatus } =
    useAppSelector((state) => state.proposteIncarichi);
  const [tipoSalvataggio, setTipoSalvataggio] = useState('');
  const [, setStatoValutazione] = useState<string | undefined>(undefined);

  const initialValues: ValutazionePropostaIncaDirigRequestDto = {
    esitoValutazioneProposta: undefined,
    note: undefined,
    idDocumento: undefined,
  };

  const schemaSalva = z.object({
    esitoValutazioneProposta: z.string().optional(),
    note: z.string().max(1500).optional(),
    idDocumento: z.number().optional(),
  });
  const schemaConferma = z
    .object({
      esitoValutazioneProposta: z.enum(['APPROVATA', 'NON_APPROVATA']),
      note: z.string().max(1500).optional(),
      idDocumento: z.number().gt(0),
    })
    .refine(
      (data) => {
        if (data.esitoValutazioneProposta === 'NON_APPROVATA' && !data.note) return false;
        return true;
      },
      {
        message: 'Il campo note è obbligatorio',
        path: ['note'],
      }
    );

  const validationSchema =
    tipoSalvataggio === 'CONFERMA'
      ? toFormikValidationSchema(schemaConferma)
      : toFormikValidationSchema(schemaSalva);

  const formik: FormikProps<ValutazionePropostaIncaDirigRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      if (propostaSelezionataResult?.codiceUnivoco) {
        // UPDATE VALUTAZIONE
        searchValutazioniResult?.codiceUnivoco &&
          dispatch(
            updateApprovazionePropostaIncarico({
              id: propostaSelezionataResult?.codiceUnivoco,
              valutazioneGiuntaPropostaIncaDirigRequestDto: values,
            })
          );
      }
    },
  });

  useEffect(() => {
    dispatch(getPropostaIncarico({ id: Number(id) }))
      .unwrap()
      .then((resp) => {
        if (resp) {
          getValutazioneInserimentoParere();
        }
      });
  }, []);

  useEffect(() => {
    if (!!searchValutazioniResult) {
      formik.setValues({
        esitoValutazioneProposta:
          searchValutazioniResult.esitoGiuntaValutazioneProposta || undefined,
        note: searchValutazioniResult.noteGiunta || undefined,
        idDocumento:
          searchValutazioniResult.documentiDto?.filter((d) => d.tipo === 'DGR_APPROVATA').at(0)
            ?.documento?.id || 0,
      });
    }
  }, [searchValutazioniResult]);

  const getValutazioneInserimentoParere = () => {
    dispatch(getValutazioniPropostaIncarico({ id: Number(id) }));
  };

  const setRadioButtonInserimentoParere = (fieldName: string, value: string | undefined) => {
    console.log(fieldName, value);
    formik.setFieldValue(fieldName, value, true);
    setStatoValutazione(value);
  };

  const getFieldErrorInserimentoParere = (
    form: FormikProps<ValutazionePropostaIncaDirigRequestDto>,
    fieldName: keyof ValutazionePropostaIncaDirigRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const getTitleUploadInserimentoParere = () => {
    return propostaSelezionataResult?.tipoStrutturaOrganizzativa ===
      StrutturaOrganizzativaDtoTipoEnum.Servizio
      ? t('Determina dirigenziale')
      : t('DGR');
  };

  const setDocumentIdInserimentoParere = (name: string, id: number | undefined) => {
    if (id) {
      formik.setFieldValue(name, id, false);
    } else {
      console.error('errore durante la chiamata di caricamento documento');
    }
  };

  const submitConfirmInserimentoParere = () => {
    formik.setFieldValue('requestType', 'CONFERMA');
    !formik.values.esitoValutazioneProposta && formik.setFieldValue('esitoValutazioneProposta', '');
    !formik.values.idDocumento && formik.setFieldValue('idDocumento', 0);
    !formik.values.note && formik.setFieldValue('note', '');
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  const submitSaveInserimentoParere = () => {
    formik.setFieldValue('requestType', 'SALVA');
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI}
        title={'inserimentoParere.titolo'}
      />

      {propostaSelezionataStatus === STATUS_FULLFILLED && !!propostaSelezionataResult && (
        <>
          <DettaglioPropostaIncarico
            propostaIncarico={propostaSelezionataResult}
            documenti={{ showDocumenti: true, download: true, view: true, protocolla: true }}
          />

          {/* {searchValutazioniStatus === STATUS_FULLFILLED && !!propostaSelezionataResult.nprotocolloUscita ? ( */}
          {FORCE_PROTOCOLLA ? (
            <div className="form-custom form-inserimento">
              <Col md="12" className="required">
                <label className="font-weight-500">Approvazione proposta</label>
                <br />
                <FormGroup check inline className="mb-0">
                  <Input
                    name="esitoValutazione"
                    type="radio"
                    id="esitoValutazione"
                    value={'APPROVATA'}
                    checked={formik.values.esitoValutazioneProposta === 'APPROVATA'}
                    onChange={(e) =>
                      setRadioButtonInserimentoParere('esitoValutazioneProposta', e.target.value)
                    }
                  />
                  <Label check htmlFor="esitoValutazione">
                    APPROVATA
                  </Label>
                </FormGroup>
                <FormGroup check inline className="mb-0">
                  <Input
                    name="esitoValutazione"
                    type="radio"
                    id="esitoValutazione1"
                    value={'NON_APPROVATA'}
                    checked={formik.values.esitoValutazioneProposta === 'NON_APPROVATA'}
                    onChange={(e) =>
                      setRadioButtonInserimentoParere('esitoValutazioneProposta', e.target.value)
                    }
                  />
                  <Label check htmlFor="esitoValutazione1">
                    NON APPROVATA
                  </Label>
                </FormGroup>
                {!!getFieldErrorInserimentoParere(formik, 'esitoValutazioneProposta') && (
                  <small className="invalid-feedback form-text text-muted">
                    {t(getFieldErrorInserimentoParere(formik, 'esitoValutazioneProposta'))}
                  </small>
                )}
              </Col>
              <Col className="mt-5">
                <TextArea
                  label="Note personale"
                  placeholder="Note valutazione"
                  id="input-note"
                  wrapperClassName={
                    formik.values.esitoValutazioneProposta === 'NON_APPROVATA'
                      ? 'form-group col-md-12 required'
                      : 'form-group col-md-12'
                  }
                  name="note"
                  disabled={!formik.values.esitoValutazioneProposta}
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  invalid={!!getFieldErrorInserimentoParere(formik, 'note')}
                  infoText={t(getFieldErrorInserimentoParere(formik, 'note')) || ''}
                />
              </Col>
              <DocumentUpload
                documentName={getTitleUploadInserimentoParere()}
                className="required m-2"
                invalid={!!getFieldErrorInserimentoParere(formik, 'idDocumento')}
                infoText={t(getFieldErrorInserimentoParere(formik, 'idDocumento')) || ''}
                idDocumento={formik.values.idDocumento}
                setDocumentId={(id) => setDocumentIdInserimentoParere('idDocumento', id)}
              />
              <div className="d-flex flex-row-reverse">
                <Button
                  color="primary"
                  className="mt-2 mr-2"
                  onClick={() => {
                    submitConfirmInserimentoParere();
                    setTipoSalvataggio('CONFERMA');
                  }}
                >
                  Conferma
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mt-2 mr-2"
                  onClick={() => {
                    submitSaveInserimentoParere();
                    setTipoSalvataggio('SALVA');
                  }}
                >
                  Salva
                </Button>
                <Button
                  color="secondary"
                  className="mt-2 mr-2 annulla-inserimento-parere"
                  onClick={() => {
                    navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`);
                  }}
                >
                  {t('annulla')}{' '}
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row-reverse">
              <Button
                color="secondary"
                className="mt-2 mr-2 annulla-inserimento-parere"
                onClick={() => {
                  navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`);
                }}
              >
                {t('annulla')}{' '}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InserimentoParere;
