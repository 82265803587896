import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { DocumentoUploaded } from 'components/crea-candidato-form/types';

const API_BASE_URL = process.env.REACT_APP_MS_AU_BASE_URL;

// Mapping document type labels to their corresponding ID values
export const documentTypeMap: Record<string, number> = {
  RICHIESTA_DOCUMENTAZIONE: 1,
  DISABILITA: 2,
  PRIVACY: 3,
  COD_COMP_ARPAL: 4,
  RINUNCIA_PERSEO: 5,
  TITOLO_STUDIO: 6,
  EQUIPOL_TITOLO_STUDIO: 7,
  CV: 8,
  CODICE_FISCALE: 9,
  CONVOCAZIONE_SOTTOSCRIZIONE: 10,
  FOTO_TESSERA: 12,
  RINUNCIA_ASSUNZIONE: 13,
  DICHIARAZIONE_DISPONIBILITA_IMP: 14,
  CARTA_IDENTITA: 15,
  PERMESSO_SOGGIORNO: 16,
  PASSAPORTO: 17,
  PATENTE: 18,
  RICHIESTA_VERBALE_INVALIDITA: 20,
  RICHIESTA_SCHEDA_FUNZIONALE: 19,
  ATTI_ONBOARDING: 21,
  ATTI_DETERMINA: 28,
  ATTI_DELIBERA: 29,
  FILE_COLLOCAMENTO: 30,
  NULLA_OSTA: 31,
};

interface ManageFilesProps {
  newFiles: File[]; // Files the user wants to upload
  existingFiles: DocumentoUploaded[]; // Files currently stored in the backend
  tipoDocumento: string;
  nomeFiles: string;
  idOnboarding?: number | string;
}

const useManageFiles = () => {
  const dispatch = useAppDispatch();

  // Function to upload new files
  const uploadFiles = async ({ newFiles, tipoDocumento, nomeFiles }: ManageFilesProps) => {
    if (!newFiles || newFiles.length === 0) return [];

    const uploadedFiles: DocumentoUploaded[] = [];
    try {
      for (const file of newFiles) {
        const formData = new FormData();
        formData.append('file', file);

        const {
          data: {
            id,
            contentType,
            dataInserimento,
            dimensione,
            path,
            filename,
            nomeDocumento,
            stato,
          },
        } = await axios.post(`${API_BASE_URL}/v1/documenti`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        uploadedFiles.push({
          tipo: tipoDocumento,
          descrizioneDocumento: '',
          documentoDto: {
            id,
            dataInserimento,
            dimensione,
            path,
            filename,
            nomeDocumento,
            stato,
            contentType,
          },
        });
      }
      return uploadedFiles;
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: `Errore upload ${nomeFiles}`,
          stato: 'error',
          messaggio: `Impossibile caricare ${nomeFiles}`,
        })
      );
      return [];
    }
  };

  // Function to delete files from backend
  const deleteFiles = async (
    filesToDelete: DocumentoUploaded[],
    idOnboarding?: number | string
  ) => {
    if (!filesToDelete.length || !idOnboarding) return;

    try {
      const mappedDocumenti = filesToDelete
        .map((file) => {
          const idTipoDocumento = file.tipo ? documentTypeMap[file.tipo] : null;

          if (!idTipoDocumento) {
            console.warn(`Tipo documento non trovato per: ${file.tipo}`);
            return null;
          }

          return {
            idDocumento: file.documentoDto.id,
            idTipoDocumento,
          };
        })
        .filter(Boolean); // Removes null values

      if (mappedDocumenti.length > 0) {
        await axios.delete(`${API_BASE_URL}/v1/concorsi/area-documenti/elimina/${idOnboarding}`, {
          data: mappedDocumenti,
        });
      }
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: `Errore eliminazione file`,
          stato: 'error',
          messaggio: `Impossibile eliminare i file.`,
        })
      );
    }
  };

  // Function to sync new files and delete missing ones
  const syncFiles = async ({
    newFiles,
    existingFiles,
    tipoDocumento,
    nomeFiles,
    idOnboarding,
  }: ManageFilesProps) => {
    const newFileNames = newFiles.map((file) => file.name);
    const existingFileNames = existingFiles.map((file) => file.documentoDto.filename);

    // Files to Upload: New files that are NOT in existing files
    const filesToUpload = newFiles.filter((file) => !existingFileNames.includes(file.name));

    // Files to Delete: Existing files that are NOT in new files
    const filesToDelete = existingFiles.filter(
      (file) => !newFileNames.includes(file.documentoDto.filename)
    );

    // Upload new files
    const uploadedFiles = await uploadFiles({
      newFiles: filesToUpload,
      tipoDocumento,
      nomeFiles,
      idOnboarding,
      existingFiles,
    });

    // Delete removed files
    await deleteFiles(filesToDelete, idOnboarding);

    return uploadedFiles;
  };

  return { syncFiles };
};

export default useManageFiles;