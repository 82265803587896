import { NotificationManager, notify } from 'design-react-kit';
import { useEffect } from 'react';
import './style.scss';

export interface NotificationProps {
  title: string;
  content: string;
  state: 'success' | 'error' | 'info' | 'warning';
}

const NotificationManagerComponent = (props: NotificationProps) => {
  useEffect(() => {
    notify(props.title, <p>{props.content}</p>, { state: props.state, dismissable: true });
  }, [0]);

  return <NotificationManager containerId="notification-container" />;
};

export default NotificationManagerComponent;
