import { useState } from 'react';
import './LeggiTutto.scss';

interface LeggiTuttoProps {
  id: string;
  testo: string;
  numeroParole?: number;
}

export const LeggiTutto = ({ id, testo, numeroParole = 10 }: LeggiTuttoProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const splittedText = testo.split(' ');
  const itCanOverflow = splittedText.length > numeroParole;
  const beginText = itCanOverflow ? splittedText.slice(0, numeroParole - 1).join(' ') : testo;
  const endText = ' ' + splittedText.slice(numeroParole - 1).join(' ');

  function replaceUrisInHyperlinks(inputText: string): React.ReactNode[] {
    const uriPattern =
      /\b((?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|])/g;

    const parts = inputText.split(uriPattern);
    const elements: React.ReactNode[] = [];

    parts.forEach((part, index) => {
      if (uriPattern.test(part)) {
        const uri = part.trim();
        elements.push(
          <a key={index} href={uri} target="_blank" rel="noopener noreferrer">
            {uri}
          </a>
        );
      } else elements.push(<span key={index}>{part}</span>);
    });

    return elements;
  }

  return (
    <span id={id}>
      {replaceUrisInHyperlinks(beginText)}
      {itCanOverflow && (
        <>
          {!isExpanded && <span> ... </span>}
          <span className={`${!isExpanded && 'hidden'}`} aria-hidden={!isExpanded}>
            {replaceUrisInHyperlinks(endText)}
          </span>
          <span className="ml-1" role="button" onClick={() => setIsExpanded(!isExpanded)}>
            <span className="leggi-tutto pointer">{isExpanded ? 'leggi meno' : 'leggi tutto'}</span>
          </span>
        </>
      )}
    </span>
  );
};
