import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  DipendenteRegionaleRubricaDto,
  FormsControllerApi,
  GenerazioneEmailDto,
  OptionDtoLong,
  OptionDtoString,
  PagedDtoDipendenteRegionaleRubricaDto,
  RubricaControllerApi,
  RubricaControllerApiGetEmailGenerataRequest,
  RubricaControllerApiPatchDatiRubricaDipendenteRequest,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';

export interface RubricaState {
  searchResult: PagedDtoDipendenteRegionaleRubricaDto;
  searchStatus: string;
  searchInput: SearchDipendentiRequest | null;
  dipendenteResult: DipendenteRegionaleRubricaDto | null;
  dipendenteStatus: string;
  dipendenteInput: RubricaControllerApiPatchDatiRubricaDipendenteRequest | null;
  numeriAssociabiliResult: Array<OptionDtoLong> | null;
  numeriAssociabiliStatus: string;
  inserisciTelefonoCredenzialiEmailResult: DipendenteRegionaleRubricaDto | null;
  inserisciTelefonoCredenzialiEmailStatus: string;
  generaEmailStatus: string;
  generaEmailResult: GenerazioneEmailDto | null;
  decodificaCodTipoResult: Array<OptionDtoString> | null;
  decodificaCodTipoStatus: string;
}

const initialState: RubricaState = {
  searchResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  searchStatus: '',
  searchInput: null,
  dipendenteResult: null,
  dipendenteStatus: '',
  dipendenteInput: null,
  numeriAssociabiliResult: null,
  numeriAssociabiliStatus: '',
  inserisciTelefonoCredenzialiEmailResult: null,
  inserisciTelefonoCredenzialiEmailStatus: '',
  generaEmailStatus: '',
  generaEmailResult: null,
  decodificaCodTipoResult: null,
  decodificaCodTipoStatus: '',
};

export interface SearchDipendentiRequest {
  id?: number | undefined;
  nome?: string;
  cognome?: string;
  codiceFiscale?: string;
  strutturaOrganizzativa?: string;
  pageSize?: number;
  pageNum?: number;
  sort?: string;
  direction?: any;
  q?: string;
  idTipoIncarico?: string | undefined;
  tipoStrutturaOrganizzativa?: any;
}

export interface DetailParam {
  idDipendente: number;
}

export const searchDipendentiRegionali = createAsyncThunk(
  'rubrica/search',
  async (args: SearchDipendentiRequest) => {
    const api = new RubricaControllerApi(MS_AU_CONFIG);
    const response = await api.searchDipendentiInRubrica({
      pageNum: args.pageNum,
      pageSize: args.pageSize,
      direction: args.direction,
      sort: args.sort,
      id: args.id == 0 ? undefined : args.id,
      nome: args.nome || '',
      cognome: args.cognome || '',
      strutturaOrganizzativa: args.strutturaOrganizzativa,
      idTipoIncarico: args.idTipoIncarico,
      tipoStrutturaOrganizzativa: args.tipoStrutturaOrganizzativa,
    });
    return response.data;
  }
);

export const detailsDipendente = createAsyncThunk(
  'rubrica/dettaglioDipendente',
  async (id: number) => {
    const api = new RubricaControllerApi(MS_AU_CONFIG);
    const response = await api.dettaglioDipendenteInRubrica({
      id: id,
    });
    return response.data;
  }
);

export const getNumeriTelefonoAssociabili = createAsyncThunk(
  'rubrica/getNumeriAssociabili',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoNumeriDiTelefonoForm();
    return response.data;
  }
);

export const getDecodificaCodiTipoInca = createAsyncThunk(
  'rubrica/getDecodificaCodiTipoInca',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getRubricaElencoForm();
    return response.data;
  }
);

export const generaEmail = createAsyncThunk(
  'rubrica/generazioneEmailDipendente',
  async (args: RubricaControllerApiGetEmailGenerataRequest) => {
    const api = new RubricaControllerApi(MS_AU_CONFIG);
    const response = await api.getEmailGenerata({
      id: args.id,
    });
    return response.data;
  }
);

export const inserisciTelefonoCredenzialiEmail = createAsyncThunk(
  'rubrica/inserisciTelefonoCredenzialiEmail',
  async (args: RubricaControllerApiPatchDatiRubricaDipendenteRequest) => {
    const api = new RubricaControllerApi(MS_AU_CONFIG);
    const response = await api.patchDatiRubricaDipendente({
      id: args.id,
      patchDatiRubricaDipendenteRequest: args.patchDatiRubricaDipendenteRequest,
    });
    return response.data;
  }
);

export const RubricaSlice = createSlice({
  name: 'dipendentiRegionaliSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchDipendentiRegionali.pending, (state: any, action: any) => {
      state.searchStatus = STATUS_PENDING;
    });
    builder.addCase(searchDipendentiRegionali.fulfilled, (state: RubricaState, action: any) => {
      state.searchResult = action.payload;
      state.searchStatus = STATUS_FULLFILLED;
      state.searchInput = action.meta.arg;
    });
    builder.addCase(searchDipendentiRegionali.rejected, (state: any, action: any) => {
      state.searchStatus = STATUS_REJECTED;
    });

    builder.addCase(detailsDipendente.pending, (state: any, action: any) => {
      state.dipendenteStatus = STATUS_PENDING;
    });
    builder.addCase(detailsDipendente.fulfilled, (state: RubricaState, action: any) => {
      state.dipendenteResult = action.payload;
      state.dipendenteStatus = STATUS_FULLFILLED;
      state.dipendenteInput = action.meta.arg;
    });
    builder.addCase(detailsDipendente.rejected, (state: any, action: any) => {
      state.dipendenteStatus = STATUS_REJECTED;
    });

    builder.addCase(getNumeriTelefonoAssociabili.pending, (state: any, action: any) => {
      state.numeriAssociabiliStatus = STATUS_PENDING;
    });
    builder.addCase(getNumeriTelefonoAssociabili.fulfilled, (state: RubricaState, action: any) => {
      state.numeriAssociabiliResult = action.payload;
      state.numeriAssociabiliStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getNumeriTelefonoAssociabili.rejected, (state: any, action: any) => {
      state.numeriAssociabiliStatus = STATUS_REJECTED;
    });

    builder.addCase(getDecodificaCodiTipoInca.pending, (state: any, action: any) => {
      state.decodificaCodTipoStatus = STATUS_PENDING;
    });
    builder.addCase(getDecodificaCodiTipoInca.fulfilled, (state: RubricaState, action: any) => {
      state.decodificaCodTipoResult = action.payload;
      state.decodificaCodTipoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getDecodificaCodiTipoInca.rejected, (state: any, action: any) => {
      state.decodificaCodTipoStatus = STATUS_REJECTED;
    });

    builder.addCase(inserisciTelefonoCredenzialiEmail.pending, (state: any, action: any) => {
      state.inserisciTelefonoCredenzialiEmailStatus = STATUS_PENDING;
    });
    builder.addCase(
      inserisciTelefonoCredenzialiEmail.fulfilled,
      (state: RubricaState, action: any) => {
        state.inserisciTelefonoCredenzialiEmailResult = action.payload;
        state.inserisciTelefonoCredenzialiEmailStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(inserisciTelefonoCredenzialiEmail.rejected, (state: any, action: any) => {
      state.inserisciTelefonoCredenzialiEmailStatus = STATUS_REJECTED;
    });

    builder.addCase(generaEmail.pending, (state: any, action: any) => {
      state.generaEmailStatus = STATUS_PENDING;
    });
    builder.addCase(generaEmail.fulfilled, (state: RubricaState, action: any) => {
      state.generaEmailResult = action.payload;
      state.generaEmailStatus = STATUS_FULLFILLED;
    });
    builder.addCase(generaEmail.rejected, (state: any, action: any) => {
      state.generaEmailStatus = STATUS_REJECTED;
    });
  },
});

export default RubricaSlice.reducer;
