import { Callout, CalloutTitle } from 'design-react-kit';
import { EsperienzeProfessionaliInRegioneDto } from '../../../services/ms-anagrafica-unica';
import { formatDate } from '../../../utility/formatDate';
import { showFieldIfNotNull } from 'utility/utility';

const EsperienzeProfessionaliInRegioneDetail = (props: {
  dati: EsperienzeProfessionaliInRegioneDto[];
}) => {
  const { dati } = props;
  return (
    <div className="row">
      <dl className="col-sm-12">
        {dati.length > 0 &&
          dati.map((esperienza) => (
            <Callout className="wrap-text au-callout">
              <CalloutTitle>{`${esperienza.tipologiaDipendenteLabel} dal ${formatDate(esperienza.da!, true, '-')} ${esperienza.al ? 'al ' + formatDate(esperienza.al!, true, '-') : ''}`}</CalloutTitle>
              <div className="row">
                {
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Datore di lavoro</dt>
                    <dd className="col-sm-12">ARPAL Puglia</dd>
                  </div>
                }
                {esperienza.areaInquadramento && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Area Inquadramento</dt>
                    <dd className="col-sm-12">{esperienza.areaInquadramento.titoloArea}</dd>
                  </div>
                )}
                {esperienza.profiloProfessionale && (
                  <div className="col-sm-4">
                    <dt className="col-sm-12">Profilo Professionale</dt>
                    <dd className="col-sm-12">
                      {esperienza.profiloProfessionale.profiloProfessionale}
                    </dd>
                  </div>
                )}
                {esperienza.ambitoDiRuolo && (
                  <div className="col-sm-12">
                    <dt className="col-sm-12">Ambito di Ruolo</dt>
                    <dd className="col-sm-12">{esperienza.ambitoDiRuolo?.descrizione}</dd>
                  </div>
                )}
                {esperienza.principaliAttivitaResponsabilita && (
                  <div className="col-sm-12">
                    <dt className="col-sm-12">Principali attività e responsabilità</dt>
                    <dd className="col-sm-12">{esperienza.principaliAttivitaResponsabilita}</dd>
                  </div>
                )}
                {esperienza &&
                  esperienza.elencoAttivita &&
                  esperienza.elencoAttivita.length > 0 && (
                    <div className="col-sm-12">
                      <dt className="col-sm-12">Attività, incarichi e responsabilità</dt>
                    </div>
                  )}
                {esperienza &&
                  esperienza.elencoAttivita &&
                  esperienza.elencoAttivita.length > 0 &&
                  esperienza.elencoAttivita.map((attivita) => (
                    <Callout className="au-callout">
                      <div className="row mt-1">
                        {attivita.dal && (
                          <div className="col-sm-6">
                            <dt className="col-sm-12">Dal</dt>
                            <dd className="col-sm-12">{formatDate(attivita.dal!, true, '-')}</dd>
                          </div>
                        )}
                        {attivita.a ? (
                          <div className="col-sm-6">
                            <dt className="col-sm-12">Al</dt>
                            <dd className="col-sm-12">{formatDate(attivita.a!, true, '-')}</dd>
                          </div>
                        ) : (
                          <div className="col-sm-6"></div>
                        )}
                        <div className="col-sm-12">
                          <dt className="col-sm-12">Descrizione</dt>
                          <dd className="col-sm-12">{showFieldIfNotNull(attivita.descrizione)}</dd>
                        </div>
                      </div>
                    </Callout>
                  ))}
              </div>
            </Callout>
          ))}
      </dl>
    </div>
  );
};

export default EsperienzeProfessionaliInRegioneDetail;
