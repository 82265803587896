import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidemenuState {
  currentCollapse: string;
  currentPage: string;
}

const initialState: SidemenuState = {
  currentCollapse: '',
  currentPage: '',
};

export const sidemenuSlice = createSlice({
  name: 'sidemenu',
  initialState,
  reducers: {
    setCurrentCollapse: (state, action: PayloadAction<string>) => {
      state.currentCollapse = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentCollapse, setCurrentPage } = sidemenuSlice.actions;

export default sidemenuSlice.reducer;
