import { PagedDtoBandoPoDto } from './../services/ms-anagrafica-unica/model/paged-dto-bando-po-dto';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AssegnazioneBandiPoDto,
  BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest,
  BandoPoDto,
  CreaAggiornaBandoPoRequestDto,
  BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest,
  PagedDtoPosizioneOrganizzativaDto,
  BandiPosizioniOrganizzativeControllerApi,
  BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest,
  BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest,
  BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest,
  BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest,
  BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest,
  CreaBandoPoContestoForm,
  ElencoBandiPoContestoForm,
  FormsControllerApi,
  NullaOstaControllerApi,
  NullaOstaControllerApiDettagliNullaOstaRequest,
  NullaOstaControllerApiSearchNullaOstaRequest,
  NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest,
  NullaOstaDto,
  NullaOstaRichiedibileDto,
  PagedDtoNullaOstaDto,
  BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest,
  ElencoAssegnazioniBandiPoContestoForm,
  BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest,
  BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest,
  BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest,
  BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest,
  BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest,
  BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest,
  BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest,
  AssegnazioniBandiPoPagedDtoAssegnazioneBandiPoDto,
  BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest,
  BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest,
  CandidaturaBandoPoDto,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';
import { PagedDtoAssegnazioneBandiPoEssenzialeDto } from '../services/ms-anagrafica-unica/model/paged-dto-assegnazione-bandi-po-essenziale-dto';

export interface BandoState {
  contestoResult: CreaBandoPoContestoForm | null;
  contestoStatus: string;
  insertResult: BandoPoDto | null;
  insertStatus: string;
  updateResult: BandoPoDto | null;
  updateStatus: string;
  searchElencoBandiResult: PagedDtoBandoPoDto | null;
  searchElencoBandiStatus: string;
  searchElencoBandiInput: BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest | null;
  contestoElencoBandiResult: ElencoBandiPoContestoForm | null;
  contestoElencoBandiStatus: string;
  elencoPoIncluseStatus: string;
  elencoPoIncluseResult: Array<AssegnazioneBandiPoDto>;
  eliminaBandoStatus: string;
  riportaInLavorazioneStatus: string;
  insertNullaOstaResult: NullaOstaDto | null;
  insertNullaOstaStatus: string;
  searchNullaOstaResult: PagedDtoNullaOstaDto | null;
  searchNullaOstaStatus: string;
  searchNullaOstaInput: NullaOstaControllerApiSearchNullaOstaRequest | null;
  searchElencoEQResult: AssegnazioniBandiPoPagedDtoAssegnazioneBandiPoDto | null;
  searchElencoEQStatus: string;
  searchElencoEQInput: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest | null;
  assegnazioneBandoStatus: string;
  assegnazioneBandoResult: AssegnazioneBandiPoDto | null;
  elencoPoPerBandoInput: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest | null;
  elencoPoPerBandoStatus: string;
  elencoPoPerBandoResult: PagedDtoPosizioneOrganizzativaDto | null;
  riepilogoBandoCompletoStatus: string;
  riepilogoBandoCompletoResult: BandoPoDto | null;
  insertCandidaturaStatus: string;
  annullaCandidaturaStatus: string;
  generaRiepilogoStatus: string;
  dettaglioRichiestaNullaOstaStatus: string;
  dettaglioRichiestaNullaOstaResult: NullaOstaDto | null;
  patchValutazioneDirigenteStatus: string;
  patchValutazioneDirettoreStatus: string;
  nullaOstaRichiedibileStatus: string;
  nullaOstaRichiedibileResult: NullaOstaRichiedibileDto | null;
  searchElencoAssegnazioniPerBandoResult: PagedDtoAssegnazioneBandiPoEssenzialeDto | null;
  searchElencoAssegnazioniPerBandoStatus: string;
  searchElencoAssegnazioniPerBandoInput: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest | null;
  elencoAssegnazioniBandiContestoFormStatus: string;
  elencoAssegnazioniBandiContestoFormResult: ElencoAssegnazioniBandiPoContestoForm | null;
  insertValutazioneBandoPoStatus: string;
  insertAssociazioneBandoPoStatus: string;
  breveEsposizione: string;
  comprovataEsperienza: string;
  procedDisciplinari: string;
  denominazioneAltraEq: string;
  dettaglioCandidatureAssegnazioneBandoInput: BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest | null;
  dettaglioCandidatureAssegnazioneBandoStatus: string;
  dettaglioCandidatureAssegnazioneBandoResult: CandidaturaBandoPoDto | null;
}

const initialState: BandoState = {
  contestoResult: null,
  contestoStatus: '',
  insertResult: null,
  insertStatus: '',
  updateResult: null,
  updateStatus: '',
  searchElencoBandiResult: null,
  searchElencoBandiStatus: '',
  searchElencoBandiInput: null,
  contestoElencoBandiResult: null,
  contestoElencoBandiStatus: '',
  elencoPoIncluseResult: [],
  elencoPoIncluseStatus: '',
  eliminaBandoStatus: '',
  riportaInLavorazioneStatus: '',
  insertNullaOstaResult: null,
  insertNullaOstaStatus: '',
  searchNullaOstaResult: null,
  searchNullaOstaStatus: '',
  searchNullaOstaInput: null,
  searchElencoEQResult: null,
  searchElencoEQStatus: '',
  searchElencoEQInput: null,
  assegnazioneBandoStatus: '',
  assegnazioneBandoResult: null,
  elencoPoPerBandoInput: null,
  elencoPoPerBandoStatus: '',
  elencoPoPerBandoResult: null,
  riepilogoBandoCompletoStatus: '',
  riepilogoBandoCompletoResult: null,
  insertCandidaturaStatus: '',
  annullaCandidaturaStatus: '',
  generaRiepilogoStatus: '',
  dettaglioRichiestaNullaOstaStatus: '',
  dettaglioRichiestaNullaOstaResult: null,
  patchValutazioneDirigenteStatus: '',
  patchValutazioneDirettoreStatus: '',
  nullaOstaRichiedibileStatus: '',
  nullaOstaRichiedibileResult: null,
  searchElencoAssegnazioniPerBandoResult: null,
  searchElencoAssegnazioniPerBandoStatus: '',
  searchElencoAssegnazioniPerBandoInput: null,
  elencoAssegnazioniBandiContestoFormStatus: '',
  elencoAssegnazioniBandiContestoFormResult: null,
  insertValutazioneBandoPoStatus: '',
  insertAssociazioneBandoPoStatus: '',
  breveEsposizione: '',
  comprovataEsperienza: '',
  procedDisciplinari: '',
  denominazioneAltraEq: '',
  dettaglioCandidatureAssegnazioneBandoInput: null,
  dettaglioCandidatureAssegnazioneBandoStatus: '',
  dettaglioCandidatureAssegnazioneBandoResult: null,
};

export interface ElencoPoPerBando {
  idSo: string;
  dataFineAssociazioneDa?: string;
  dataFineAssociazioneA?: string;
  tipoPosizioneOrganizzativa?: 'PO' | 'PO_EQUIPARATA';
  pageNum?: number;
  pageSize?: number;
  sort?: string;
  direction?: 'ASC' | 'DESC';
  q?: string;
}

export const getCreaBandoContestoForm = createAsyncThunk('bandi/getContesto', async () => {
  const api = new FormsControllerApi(MS_AU_CONFIG);
  const response = await api.getCreaBandoPoContestoForm();
  return response.data;
});

export const getElencoBandiPoContestoForm = createAsyncThunk(
  'bandi/getElencoBandiPoContestoForm',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoBandiPoContestoForm();
    return response.data;
  }
);

export const creaNuovoBando = createAsyncThunk(
  'bandi/creaNuovoBando',
  async (body: CreaAggiornaBandoPoRequestDto) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.nuovoBandoPo({
      creaAggiornaBandoPoRequestDto: body,
    });
    return response.data;
  }
);

export const aggiornaBando = createAsyncThunk(
  'bandi/aggiornaBando',
  async (body: BandiPosizioniOrganizzativeControllerApiAggiornaBandoPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.aggiornaBandoPo({
      id: body.id,
      creaAggiornaBandoPoRequestDto: body.creaAggiornaBandoPoRequestDto,
    });
    return response.data;
  }
);

/**
 export const getElencoFunzionlita = createAsyncThunk(
    'funzionalitaApplicative/elencoFunzionalita',
    async (args: number) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getFunzionalitaApplicativeForm({
            chiave: args
        });
        return response.data;
    }
);
 */
export const deleteBando = createAsyncThunk('bandi/deleteBando', async (args: string) => {
  const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
  const response = await api.deleteBandoPo({
    id: args,
  });
  return response.status;
});

export const riportaInLavorazioneBando = createAsyncThunk(
  'bandi/riportaInLavorazioneBando',
  async (args: string) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.riportaInLavorazione({
      id: args,
    });
    return response.status;
  }
);

export const searchElencoBandiPO = createAsyncThunk(
  'bandi/elencoBandiPO',
  async (args: BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.elencoBandiPo(args);
    return response.data;
  }
);

export const insertRichiestaNullaOsta = createAsyncThunk('bandi/richiestaNullaOsta', async () => {
  const api = new NullaOstaControllerApi(MS_AU_CONFIG);
  const response = await api.insertNullaOsta();
  return response.data;
});

export const searchNullaOsta = createAsyncThunk('bandi/searchNullaOsta', async (args: any) => {
  const api = new NullaOstaControllerApi(MS_AU_CONFIG);
  const response = await api.searchNullaOsta(args);
  return response.data;
});

export const getElencoEQ = createAsyncThunk(
  'bandi/searchBandiEQ',
  async (args: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.elencoAssegnazioniBandiPo(args);
    return response.data;
  }
);

export const getDettaglioAssegnazioneBando = createAsyncThunk(
  'bandi/getDettaglioAssegnazioneBando',
  async (args: BandiPosizioniOrganizzativeControllerApiDettaglioAssegnazioneBandoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.dettaglioAssegnazioneBando(args);
    return response.data;
  }
);

export const elencoPoPerBando = createAsyncThunk(
  'bandi/elencoPoPerBando',
  async (args: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.elencoPoPerBando({
      idSo: args.idSo,
      dataFineAssociazioneDa: args.dataFineAssociazioneDa,
      dataFineAssociazioneA: args.dataFineAssociazioneA,
      tipoPosizioneOrganizzativa: args.tipoPosizioneOrganizzativa,
      codiceEQ: args.codiceEQ,
      descrizioneEQ: args.descrizioneEQ,
      pageNum: args.pageNum,
      pageSize: args.pageSize,
      sort: args.sort,
      direction: args.direction,
      q: args.q,
    });
    return response.data;
  }
);

export const riepilogoBandoCompleto = createAsyncThunk(
  'bandi/riepilogoBandoCompleto',
  async (args: BandiPosizioniOrganizzativeControllerApiDettaglioBandoPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.dettaglioBandoPo(args);
    return response.data;
  }
);

export const insertCandidatura = createAsyncThunk(
  'bandi/insertCandidatura',
  async (args: BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.candidaturaBandoPo(args);
    return response.data;
  }
);

export const annullaCandidatura = createAsyncThunk(
  'bandi/annullaCandidatura',
  async (args: BandiPosizioniOrganizzativeControllerApiAnnullaCandidaturaBandoPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.annullaCandidaturaBandoPo(args);
    return response.status;
  }
);

export const generaRipepilogo = createAsyncThunk(
  'bandi/generaRipepilogo',
  async (args: BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.generaRiepilogo(args);
    return response.data;
  }
);

export const generaRipepilogoFirmato = createAsyncThunk(
  'bandi/generaRipepilogoFirmato',
  async (args: BandiPosizioniOrganizzativeControllerApiGeneraRiepilogoFirmatoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.generaRiepilogoFirmato(args);
    return response.data;
  }
);

export const eliminaDocumentoRiepilogo = createAsyncThunk(
  'bandi/eliminaDocumentoRiepilogo',
  async (args: BandiPosizioniOrganizzativeControllerApiEliminaDocumentoRiepilogoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.eliminaDocumentoRiepilogo(args);
    return response.data;
  }
);

export const eliminaDocumentoFirmato = createAsyncThunk(
  'bandi/eliminaDocumentoRiepilogo',
  async (args: BandiPosizioniOrganizzativeControllerApiEliminaDocumentoFirmatoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.eliminaDocumentoFirmato(args);
    return response.data;
  }
);

export const getNullaOstaRichiedibile = createAsyncThunk(
  'bandi/getNullaOstaRichiedibile',
  async () => {
    const api = new NullaOstaControllerApi(MS_AU_CONFIG);
    const response = await api.nullaOstaRichiedibile();
    return response.data;
  }
);

export const patchValutazioneDirigente = createAsyncThunk(
  'bandi/patchValutazioneDirigente',
  async (args: NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest) => {
    const api = new NullaOstaControllerApi(MS_AU_CONFIG);
    const response = await api.valutazioneDirigenteNullaOsta(args);
    return response.data;
  }
);

export const patchValutazioneDirettore = createAsyncThunk(
  'bandi/patchValutazioneDirettore',
  async (args: NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest) => {
    const api = new NullaOstaControllerApi(MS_AU_CONFIG);
    const response = await api.valutazioneDirettoreNullaOsta(args);
    return response.data;
  }
);

export const getDettaglioRichiestaNullaOsta = createAsyncThunk(
  'bandi/getDettaglioRichiestaNullaOsta',
  async (args: NullaOstaControllerApiDettagliNullaOstaRequest) => {
    const api = new NullaOstaControllerApi(MS_AU_CONFIG);
    const response = await api.dettagliNullaOsta(args);
    return response.data;
  }
);

export const getElencoAssegnazioniPerBando = createAsyncThunk(
  'bandi/getElencoAssegnazioniPerBando',
  async (args: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniPerBandoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.elencoAssegnazioniPerBando(args);
    return response.data;
  }
);

// export const getElencoAssegnazioniBandiPoContestoForm = createAsyncThunk(
//   'bandi/getElencoAssegnazioniBandiPoContestoForm',
//   async () => {
//     const api = new FormsControllerApi(MS_AU_CONFIG);
//     const response = await api.getElencoAssegnazioniBandiPoContestoForm();
//     return response.data;
//   }
// );

export const insertValutazioneBandoPo = createAsyncThunk(
  'bandi/insertValutazioneBandoPo',
  async (args: BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.valutazioneBandoPo(args);
    return response.data;
  }
);

export const insertAssociazioneBandiPo = createAsyncThunk(
  'bandi/insertAssociazioneBandiPo',
  async (args: BandiPosizioniOrganizzativeControllerApiAssociazioneBandiPoRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.associazioneBandiPo(args);
    return response.data;
  }
);

export const getElencoAssegnazioniBandiPoContestoForm = createAsyncThunk(
  'bandi/getElencoAssegnazioniBandiPoContestoForm',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoAssegnazioniBandiPoContestoForm();
    return response.data;
  }
);

export const getDownloadExportBandi = createAsyncThunk(
  'bandi/downloadExportBandi',
  async (args: BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.downloadExportBandi(args, { responseType: 'arraybuffer' });
    return response.data;
  }
);

export const dettaglioCandidatureAssegnazioneBando = createAsyncThunk(
  'bandi/dettaglioCandidatureAssegnazioneBando',
  async (
    args: BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest
  ) => {
    const api = new BandiPosizioniOrganizzativeControllerApi(MS_AU_CONFIG);
    const response = await api.dettaglioCandidatureAssegnazioneBando(args);
    return response.data;
  }
);

const bandSlice = createSlice({
  name: 'bando',
  initialState: initialState,
  reducers: {
    aggiungiNuovaPo: (state, action) => {
      state.elencoPoIncluseResult.push(action.payload);
    },
    rimuoviNuovaPo: (state, action) => {
      const indexToRemove = action.payload;
      state.elencoPoIncluseResult = state.elencoPoIncluseResult.filter(
        (item, index) => index !== indexToRemove
      );
    },
    saveBreveEsposizione: (state, action) => {
      const breveEsposizione = action.payload;
      state.breveEsposizione = breveEsposizione;
    },
    saveComprovataEsperienza: (state, action) => {
      const comprovataEsperienza = action.payload;
      state.comprovataEsperienza = comprovataEsperienza;
    },
    saveProcedDisciplinari: (state, action) => {
      const procedDisciplinari = action.payload;
      state.procedDisciplinari = procedDisciplinari;
    },
    saveDenominazioneAltraEq: (state, action) => {
      const denominazioneAltraEq = action.payload;
      state.denominazioneAltraEq = denominazioneAltraEq;
    },
    resetElencoPoPerBando: (state) => {
      state.searchElencoEQResult = null;
    },
    reset: (state) => {
      state = {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreaBandoContestoForm.pending, (state: any, action: any) => {
      state.contestoStatus = STATUS_PENDING;
    });
    builder.addCase(getCreaBandoContestoForm.fulfilled, (state: BandoState, action: any) => {
      state.contestoResult = action.payload;
      state.contestoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getCreaBandoContestoForm.rejected, (state: any, action: any) => {
      state.contestoStatus = STATUS_REJECTED;
    });

    builder.addCase(creaNuovoBando.pending, (state: any, action: any) => {
      state.insertStatus = STATUS_PENDING;
    });
    builder.addCase(creaNuovoBando.fulfilled, (state: BandoState, action: any) => {
      state.insertResult = action.payload;
      state.insertStatus = STATUS_FULLFILLED;
    });

    builder.addCase(aggiornaBando.pending, (state: any, action: any) => {
      state.updateStatus = STATUS_PENDING;
    });
    builder.addCase(aggiornaBando.fulfilled, (state: BandoState, action: any) => {
      state.updateResult = action.payload;
      state.updateStatus = STATUS_FULLFILLED;
    });
    builder.addCase(aggiornaBando.rejected, (state: any, action: any) => {
      state.updateStatus = STATUS_REJECTED;
    });

    builder.addCase(searchElencoBandiPO.pending, (state: BandoState, action: any) => {
      state.searchElencoBandiStatus = STATUS_PENDING;
      state.searchElencoBandiInput = action.meta.arg;
    });
    builder.addCase(searchElencoBandiPO.fulfilled, (state: BandoState, action: any) => {
      state.searchElencoBandiResult = action.payload;
      state.searchElencoBandiStatus = STATUS_FULLFILLED;
      state.searchElencoBandiInput = action.meta.arg;
    });
    builder.addCase(searchElencoBandiPO.rejected, (state: BandoState, action: any) => {
      state.searchElencoBandiStatus = STATUS_REJECTED;
    });

    builder.addCase(getElencoBandiPoContestoForm.pending, (state: BandoState, action: any) => {
      state.contestoElencoBandiStatus = STATUS_PENDING;
    });
    builder.addCase(getElencoBandiPoContestoForm.fulfilled, (state: BandoState, action: any) => {
      state.contestoElencoBandiResult = action.payload;
      state.contestoElencoBandiStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getElencoBandiPoContestoForm.rejected, (state: BandoState, action: any) => {
      state.contestoElencoBandiStatus = STATUS_REJECTED;
    });

    builder.addCase(deleteBando.pending, (state: BandoState, action: any) => {
      state.eliminaBandoStatus = STATUS_PENDING;
    });
    builder.addCase(deleteBando.fulfilled, (state: BandoState, action: any) => {
      state.eliminaBandoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(deleteBando.rejected, (state: BandoState, action: any) => {
      state.eliminaBandoStatus = STATUS_REJECTED;
    });

    builder.addCase(riportaInLavorazioneBando.pending, (state: BandoState, action: any) => {
      state.riportaInLavorazioneStatus = STATUS_PENDING;
    });
    builder.addCase(riportaInLavorazioneBando.fulfilled, (state: BandoState, action: any) => {
      state.riportaInLavorazioneStatus = STATUS_FULLFILLED;
    });
    builder.addCase(riportaInLavorazioneBando.rejected, (state: BandoState, action: any) => {
      state.riportaInLavorazioneStatus = STATUS_REJECTED;
    });

    builder.addCase(insertRichiestaNullaOsta.pending, (state: BandoState, action: any) => {
      state.insertNullaOstaStatus = STATUS_PENDING;
    });
    builder.addCase(insertRichiestaNullaOsta.fulfilled, (state: BandoState, action: any) => {
      state.insertNullaOstaResult = action.payload;
      state.insertNullaOstaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(insertRichiestaNullaOsta.rejected, (state: BandoState, action: any) => {
      state.insertNullaOstaStatus = STATUS_REJECTED;
    });

    builder.addCase(searchNullaOsta.pending, (state: BandoState, action: any) => {
      state.searchNullaOstaStatus = STATUS_PENDING;
      state.searchNullaOstaInput = action.meta.arg;
    });
    builder.addCase(searchNullaOsta.fulfilled, (state: BandoState, action: any) => {
      state.searchNullaOstaResult = action.payload;
      state.searchNullaOstaStatus = STATUS_FULLFILLED;
      state.searchElencoBandiInput = action.meta.arg;
    });
    builder.addCase(searchNullaOsta.rejected, (state: BandoState, action: any) => {
      state.searchNullaOstaStatus = STATUS_REJECTED;
    });

    builder.addCase(getElencoEQ.pending, (state: BandoState, action: any) => {
      state.searchElencoEQStatus = STATUS_PENDING;
      state.searchElencoEQInput = action.meta.arg;
    });
    builder.addCase(getElencoEQ.fulfilled, (state: BandoState, action: any) => {
      state.searchElencoEQResult = action.payload;
      state.searchElencoEQStatus = STATUS_FULLFILLED;
      state.searchElencoEQInput = action.meta.arg;
    });
    builder.addCase(getElencoEQ.rejected, (state: BandoState, action: any) => {
      state.searchElencoEQStatus = STATUS_REJECTED;
    });

    builder.addCase(getDettaglioAssegnazioneBando.pending, (state: BandoState, action: any) => {
      state.assegnazioneBandoStatus = STATUS_PENDING;
    });
    builder.addCase(getDettaglioAssegnazioneBando.fulfilled, (state: BandoState, action: any) => {
      state.assegnazioneBandoStatus = STATUS_FULLFILLED;
      state.assegnazioneBandoResult = action.payload;
    });
    builder.addCase(getDettaglioAssegnazioneBando.rejected, (state: BandoState, action: any) => {
      state.assegnazioneBandoStatus = STATUS_REJECTED;
    });
    builder.addCase(elencoPoPerBando.pending, (state: BandoState, action: any) => {
      state.elencoPoPerBandoStatus = STATUS_PENDING;
    });
    builder.addCase(elencoPoPerBando.fulfilled, (state: BandoState, action: any) => {
      state.elencoPoPerBandoInput = action.meta.arg;
      state.elencoPoPerBandoStatus = STATUS_FULLFILLED;
      state.elencoPoPerBandoResult = action.payload;
    });
    builder.addCase(elencoPoPerBando.rejected, (state: BandoState, action: any) => {
      state.elencoPoPerBandoStatus = STATUS_REJECTED;
    });
    builder.addCase(riepilogoBandoCompleto.pending, (state: BandoState, action: any) => {
      state.elencoPoPerBandoStatus = STATUS_PENDING;
    });
    builder.addCase(riepilogoBandoCompleto.fulfilled, (state: BandoState, action: any) => {
      state.elencoPoPerBandoStatus = STATUS_FULLFILLED;
      state.elencoPoPerBandoResult = action.payload;
    });
    builder.addCase(riepilogoBandoCompleto.rejected, (state: BandoState, action: any) => {
      state.elencoPoPerBandoStatus = STATUS_REJECTED;
    });
    builder.addCase(insertCandidatura.pending, (state: BandoState, action: any) => {
      state.insertCandidaturaStatus = STATUS_PENDING;
    });
    builder.addCase(insertCandidatura.fulfilled, (state: BandoState, action: any) => {
      state.insertCandidaturaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(insertCandidatura.rejected, (state: BandoState, action: any) => {
      state.insertCandidaturaStatus = STATUS_REJECTED;
    });
    builder.addCase(annullaCandidatura.pending, (state: BandoState, action: any) => {
      state.annullaCandidaturaStatus = STATUS_PENDING;
    });
    builder.addCase(annullaCandidatura.fulfilled, (state: BandoState, action: any) => {
      state.annullaCandidaturaStatus = STATUS_FULLFILLED;
    });
    builder.addCase(annullaCandidatura.rejected, (state: BandoState, action: any) => {
      state.annullaCandidaturaStatus = STATUS_REJECTED;
    });
    builder.addCase(generaRipepilogo.pending, (state: BandoState, action: any) => {
      state.generaRiepilogoStatus = STATUS_PENDING;
    });
    builder.addCase(generaRipepilogo.fulfilled, (state: BandoState, action: any) => {
      state.generaRiepilogoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(generaRipepilogo.rejected, (state: BandoState, action: any) => {
      state.generaRiepilogoStatus = STATUS_REJECTED;
    });

    builder.addCase(getDettaglioRichiestaNullaOsta.pending, (state: BandoState, action: any) => {
      state.dettaglioRichiestaNullaOstaStatus = STATUS_PENDING;
    });
    builder.addCase(getDettaglioRichiestaNullaOsta.fulfilled, (state: BandoState, action: any) => {
      state.dettaglioRichiestaNullaOstaStatus = STATUS_FULLFILLED;
      state.dettaglioRichiestaNullaOstaResult = action.payload;
    });
    builder.addCase(getDettaglioRichiestaNullaOsta.rejected, (state: BandoState, action: any) => {
      state.dettaglioRichiestaNullaOstaStatus = STATUS_REJECTED;
    });

    builder.addCase(patchValutazioneDirigente.pending, (state: BandoState, action: any) => {
      state.patchValutazioneDirigenteStatus = STATUS_PENDING;
    });
    builder.addCase(patchValutazioneDirigente.fulfilled, (state: BandoState, action: any) => {
      state.patchValutazioneDirigenteStatus = STATUS_FULLFILLED;
    });
    builder.addCase(patchValutazioneDirigente.rejected, (state: BandoState, action: any) => {
      state.patchValutazioneDirigenteStatus = STATUS_REJECTED;
    });

    builder.addCase(patchValutazioneDirettore.pending, (state: BandoState, action: any) => {
      state.patchValutazioneDirettoreStatus = STATUS_PENDING;
    });
    builder.addCase(patchValutazioneDirettore.fulfilled, (state: BandoState, action: any) => {
      state.patchValutazioneDirettoreStatus = STATUS_FULLFILLED;
    });
    builder.addCase(patchValutazioneDirettore.rejected, (state: BandoState, action: any) => {
      state.patchValutazioneDirettoreStatus = STATUS_REJECTED;
    });

    builder.addCase(getNullaOstaRichiedibile.pending, (state: BandoState, action: any) => {
      state.nullaOstaRichiedibileStatus = STATUS_PENDING;
    });
    builder.addCase(getNullaOstaRichiedibile.fulfilled, (state: BandoState, action: any) => {
      state.nullaOstaRichiedibileStatus = STATUS_FULLFILLED;
      state.nullaOstaRichiedibileResult = action.payload;
    });
    builder.addCase(getNullaOstaRichiedibile.rejected, (state: BandoState, action: any) => {
      state.nullaOstaRichiedibileStatus = STATUS_REJECTED;
    });

    builder.addCase(getElencoAssegnazioniPerBando.pending, (state: BandoState, action: any) => {
      state.searchElencoAssegnazioniPerBandoStatus = STATUS_PENDING;
      state.searchElencoAssegnazioniPerBandoInput = action.meta.arg;
    });
    builder.addCase(getElencoAssegnazioniPerBando.fulfilled, (state: BandoState, action: any) => {
      state.searchElencoAssegnazioniPerBandoResult = action.payload;
      state.searchElencoAssegnazioniPerBandoStatus = STATUS_FULLFILLED;
      state.searchElencoAssegnazioniPerBandoInput = action.meta.arg;
    });
    builder.addCase(getElencoAssegnazioniPerBando.rejected, (state: BandoState, action: any) => {
      state.searchElencoAssegnazioniPerBandoStatus = STATUS_REJECTED;
    });

    builder.addCase(
      getElencoAssegnazioniBandiPoContestoForm.pending,
      (state: BandoState, action: any) => {
        state.elencoAssegnazioniBandiContestoFormStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      getElencoAssegnazioniBandiPoContestoForm.fulfilled,
      (state: BandoState, action: any) => {
        state.elencoAssegnazioniBandiContestoFormStatus = STATUS_FULLFILLED;
        state.elencoAssegnazioniBandiContestoFormResult = action.payload;
      }
    );
    builder.addCase(
      getElencoAssegnazioniBandiPoContestoForm.rejected,
      (state: BandoState, action: any) => {
        state.elencoAssegnazioniBandiContestoFormStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(insertValutazioneBandoPo.pending, (state: BandoState, action: any) => {
      state.insertValutazioneBandoPoStatus = STATUS_PENDING;
    });
    builder.addCase(insertValutazioneBandoPo.fulfilled, (state: BandoState, action: any) => {
      state.insertValutazioneBandoPoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(insertValutazioneBandoPo.rejected, (state: BandoState, action: any) => {
      state.insertValutazioneBandoPoStatus = STATUS_REJECTED;
    });

    builder.addCase(insertAssociazioneBandiPo.pending, (state: BandoState, action: any) => {
      state.insertValutazioneBandoPoStatus = STATUS_PENDING;
    });
    builder.addCase(insertAssociazioneBandiPo.fulfilled, (state: BandoState, action: any) => {
      state.insertValutazioneBandoPoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(insertAssociazioneBandiPo.rejected, (state: BandoState, action: any) => {
      state.insertValutazioneBandoPoStatus = STATUS_REJECTED;
    });

    builder.addCase(
      dettaglioCandidatureAssegnazioneBando.pending,
      (state: BandoState, action: any) => {
        state.dettaglioCandidatureAssegnazioneBandoStatus = STATUS_PENDING;
        state.dettaglioCandidatureAssegnazioneBandoInput = action.meta.arg;
      }
    );
    builder.addCase(
      dettaglioCandidatureAssegnazioneBando.fulfilled,
      (state: BandoState, action: any) => {
        state.dettaglioCandidatureAssegnazioneBandoStatus = STATUS_FULLFILLED;
        state.dettaglioCandidatureAssegnazioneBandoResult = action.payload;
      }
    );
    builder.addCase(
      dettaglioCandidatureAssegnazioneBando.rejected,
      (state: BandoState, action: any) => {
        state.dettaglioCandidatureAssegnazioneBandoStatus = STATUS_REJECTED;
      }
    );
  },
});

export const {
  reset,
  aggiungiNuovaPo,
  rimuoviNuovaPo,
  saveBreveEsposizione,
  saveComprovataEsperienza,
  saveProcedDisciplinari,
  saveDenominazioneAltraEq,
  resetElencoPoPerBando,
} = bandSlice.actions;

export default bandSlice.reducer;
