import FormDatiAnagrafici from '../../components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from '../../components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from '../../components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from '../../components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormRecapiti from '../../components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from '../../components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from '../../components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from '../../components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import { ConvalidaDatiForm } from '../../components/convalida-dati-form/ConvalidaDatiForm';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';

const ConvalidaDati: React.FC = () => {
  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={'Convalida dati'} />
      <div>
        <div className="mt-3">
          <ConvalidaDatiForm>
            <FormDatiAnagrafici />
            <FormEstremiIdentificazione />
            <FormRecapiti />
            <FormDatiResidenza />
            <FormDomicilio />
            <FormDatiDisabilita />
            <FormDatiConcorso />
            <FormTitoloDiStudio />
          </ConvalidaDatiForm>
        </div>
      </div>
    </div>
  );
};

export default ConvalidaDati;
