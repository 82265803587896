import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  DATA_DECORRENZA,
  DATA_SCADENZA,
  NOME_CONVENZIONE,
  SCOPO_CONVENZIONE,
} from './convenzioniConstants';
import { deleteConvenzioni, detailsConvenzione } from '../../store/ConvenzioniSlice';
import { STATUS_FULLFILLED, STATUS_REJECTED } from '../../store/store-constants';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import ConvenzioniDetailsComponent from '../../components/convenzioni-detail/ConvenzioniDetailsComponent';
import {
  HREF_ELENCO_CONVENZIONI,
  HREF_MODIFICA_CONVENZIONE,
} from '../../components/layout/sidemenu/sidemenuConstants';
import PageHeader from 'components/common/page-header/PageHeader';

const VisualizzaConvenzione = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fieldUsed: Array<string> = [
    NOME_CONVENZIONE,
    SCOPO_CONVENZIONE,
    DATA_DECORRENZA,
    DATA_SCADENZA,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const convenzione = useAppSelector((state) => state.convenzioni.detailsResult);
  const convenzioneStatus = useAppSelector((state) => state.convenzioni.detailsStatus);
  const deleteStatus = useAppSelector((state) => state.convenzioni.deleteStatus);
  const { userRoleActive } = useAppSelector((state) => state.user);

  useEffect(() => {
    getDetailsFromApi(id as unknown as number);
  }, []);

  useEffect(() => {
    if (deleteStatus === STATUS_FULLFILLED) {
      getDetailsFromApi(id as unknown as number);
    }
  }, [deleteStatus]);

  const getDetailsFromApi = (id: number) => {
    dispatch(detailsConvenzione(id));
  };

  const cancella = () => {
    setIsOpen(!isOpen);
  };
  const doEliminaConvenzione = () => {
    if (convenzione) {
      dispatch(deleteConvenzioni(id as unknown as number));
    }
  };

  return (
    <div className="dettaglio-convenzione">
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CONVENZIONI}
        title="Dettaglio Convenzione"
        buttons={[]}
      />
      {convenzioneStatus === STATUS_REJECTED && (
        <Alert color="danger">
          Siamo spiacenti, si &egrave; verificato un errore durante la richiesta
        </Alert>
      )}
      {convenzioneStatus === STATUS_FULLFILLED && (
        <div>
          <div className="row">
            <div className="col-12 mt-2 p-2">
              <ConvenzioniDetailsComponent fields={fieldUsed} value={convenzione!} />
            </div>
          </div>
          <div className="position-relative py-5">
            <div className="d-flex flex-row-reverse mt-2">
              {userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_PATCH') &&
                deleteStatus !== STATUS_FULLFILLED &&
                convenzione?.statoConvenzione?.codice !== 'ELIMINATA' && (
                  <Button
                    color="primary"
                    className="ml-2"
                    onClick={() => navigate(`${HREF_MODIFICA_CONVENZIONE}/${id}`)}
                  >
                    Modifica
                  </Button>
                )}
              {userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_DELETE') &&
                deleteStatus !== STATUS_FULLFILLED &&
                convenzione?.statoConvenzione?.codice !== 'ELIMINATA' && (
                  <Button color="danger" className="ml-2" onClick={() => cancella()}>
                    Elimina
                  </Button>
                )}
              <Button
                outline
                color="primary"
                className="ml-2"
                onClick={() => navigate(HREF_ELENCO_CONVENZIONI)}
              >
                Indietro
              </Button>
            </div>
          </div>
          <div>
            <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} labelledBy="esempio1" centered>
              <ModalHeader
                toggle={() => {
                  setIsOpen(!isOpen);
                }}
                id="esempio1"
              >
                Cancella convenzione
              </ModalHeader>
              <ModalBody>
                <p>Sei sicuro di voler cancellare questa convenzione?</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Annulla
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    doEliminaConvenzione();
                  }}
                >
                  Conferma
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualizzaConvenzione;
