import ExplodedPdfViewer from 'components/exploded-pdf-viewer/ExplodedPdfViewer';
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Icon,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useCompositeForm } from 'hooks/useCompositeForm';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

interface ITabellaAllegatiProps {
  fileList: File[];
  deleteFile: (fileIndex: number) => void;
}
const TabellaAllegati: React.FC<ITabellaAllegatiProps> = ({ fileList, deleteFile }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const modalId = v4();
  const [pdfUrl, setPdfUrl] = useState<string[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);

  function viewFile(fileIndex: number) {
    setSelectedFileIndex(fileIndex);
    toggleModal();
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  useEffect(() => {
    setPdfUrl(fileList.map((fileItem) => URL.createObjectURL(fileItem)));

    return () => pdfUrl.forEach((url) => URL.revokeObjectURL(url));
  }, [fileList, setPdfUrl]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome file</th>
            <th scope="col">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {fileList &&
            fileList.map((item, index) => (
              <tr key={item.name + index}>
                <th scope="row">{index + 1}</th>
                <td>{item.name}</td>
                <td>
                  <UncontrolledDropdown direction="left">
                    <DropdownToggle nav>
                      <Icon size="sm" color="primary" icon="it-more-items" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <LinkList>
                        <LinkListItem onClick={() => viewFile(index)}>Visualizza</LinkListItem>
                        <LinkListItem onClick={() => deleteFile(index)}>Elimina</LinkListItem>
                      </LinkList>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={'modal-' + modalId}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={'modal-' + modalId}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" icon="it-close" />
              <h2>Anteprima documento</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '70vh',
              overflowY: 'auto',
            }}
          >
            <ExplodedPdfViewer pdfFile={pdfUrl[selectedFileIndex]} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" outline className="link-underline-primary" onClick={toggleModal}>
            Annulla
          </Button>
          <Button color="primary" onClick={toggleModal}>
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const TabellaDocumentiConferimento = () => {
  const { setFieldValue } = useCompositeForm();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);

  function deleteFile(fileIndex: number) {
    setFiles((prevList) => prevList.filter((item, index) => index !== fileIndex));
  }

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setFiles((prevList) => [...prevList, file]);
    }

    if (uploadInputRef.current) {
      uploadInputRef.current.value = '';
    }
  };

  useEffect(() => {
    setFieldValue('documents', files);
  }, [files, setFieldValue]);

  return (
    <>
      {files.length > 0 && (
        <Row>
          <Col md={12}>
            <TabellaAllegati deleteFile={deleteFile} fileList={files} />
          </Col>
        </Row>
      )}

      <input
        id="fileInput"
        type="file"
        accept=".pdf"
        ref={uploadInputRef}
        onChange={(event) => {
          console.log('Selected file: ', event);
          handleFileUpload(event);
        }}
        hidden
      />

      <Button
        className="mt-2"
        /* @ts-ignore */
        onClick={() => uploadInputRef.current?.click()}
        color="primary"
        outline
        size="sm"
      >
        <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
        Seleziona file
      </Button>
    </>
  );
};

export default TabellaDocumentiConferimento;
