export enum NOTIFICA_STATO {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export interface Notifica {
  titolo: string;
  stato: number;
  messaggio: string;
  tipo: NOTIFICA_STATO;
}
