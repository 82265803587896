import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/common/page-header/PageHeader';
import CustomSelect, {
  CustomSelectItem,
} from './../../../components/common/custom-select/CustomSelect';
import {
  Button,
  Col,
  Row,
  FormGroup,
  Input,
  Alert,
  Table,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Icon,
  UncontrolledTooltip,
} from 'design-react-kit';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  fetchUploadBandiEqContestoForm,
  previewEqData,
  resetUploadStatus,
  uploadEqData,
} from '../../../store/gestioneMassivaSlice';

import { CaricamentoMassivoBandiEqControllerApiPreviewEqRequest } from '../../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { STATUS_FULLFILLED } from '../../../store/store-constants';

const initialValues: any = {
  tipoEQ: '',
  dataApertura: '',
  dataChiusura: '',
  requestType: '',
  nomeBando: '',
  faseBandoPo: '',
};

const today = new Date();
today.setHours(0, 0, 0, 0);

const schema = z
  .object({
    tipoEQ: z.string().optional(),
    dataApertura: z.coerce
      .date()
      .min(today, {
        message: 'Data apertura non valida, deve essere uguale o superiore alla data odierna',
      })
      .max(new Date(9999, 12), 'La data inserita non è corretta')
      .optional(),
    dataChiusura: z.coerce
      .date()
      .min(new Date(1900, 0), 'La data non può essere inferiore al 01/01/1900')
      .max(new Date(9999, 12), 'La data inserita non è corretta')
      .optional(),
    requestType: z.string().optional(),
    nomeBando: z.string().optional(),
    faseBandoPo: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.requestType !== 'EQ') {
        if (!data.dataApertura) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Campo obbligatorio',
      path: ['dataApertura'],
    }
  )
  .refine(
    (data) => {
      if (data.requestType !== 'EQ') {
        if (!data.dataChiusura) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Campo obbligatorio',
      path: ['dataChiusura'],
    }
  )
  .refine(
    (data) => {
      if (data.requestType !== 'EQ') {
        if (!data.faseBandoPo) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Campo obbligatorio',
      path: ['faseBandoPo'],
    }
  )
  .refine(
    (data) => {
      if (data.requestType !== 'EQ') {
        if (data.dataApertura && data.dataChiusura && data.dataChiusura < data.dataApertura) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'La data chiusura non può essere inferiore della data apertura',
      path: ['dataChiusura'],
    }
  )
  .refine(
    (data) => {
      if (data.requestType !== 'EQ') {
        if (data.dataApertura && data.dataChiusura && data.dataChiusura >= data.dataApertura) {
          const openDateObj = new Date(data.dataApertura);
          const closeDateObj = new Date(data.dataChiusura);
          const dateDifference =
            (closeDateObj.getTime() - openDateObj.getTime()) / (1000 * 3600 * 24);

          if (dateDifference < 10) {
            return false;
          }
        }
      }
      return true;
    },
    {
      message: 'La differenza tra la data di apertura e chiusura deve essere di almeno 10 giorni.',
      path: ['dataChiusura'],
    }
  );

const UploadGestioneMassiva = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [options, setOptions] = useState<Array<CustomSelectItem>>([]);
  const [phaseOptions, setPhaseOptions] = useState<Array<CustomSelectItem>>([]);
  const [tipoEqOptions, setTipoEqOptions] = useState<Array<CustomSelectItem>>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [isOpenListDetails, toggleModalListDetails] = useState(false);
  const [modalDescription, setModalDescription] = useState<string>('');
  const [submitType, setSubmitType] = useState<string>('');

  const responseTableState = useAppSelector((state) => state.gestioneMassiva.result);
  const { uploadStatus } = useAppSelector((state) => state.gestioneMassiva);

  const title: string = t('Gestione Massiva incarichi EQ e Bandi EQ') || '';
  const [editingValue, setEditingValue] = useState<any>({});
  const [responseTable, setResponseTable] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const form: FormikProps<any> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    validateOnBlur: false,
    onSubmit: (values) => {
      if (submitType == 'anteprima') {
        handleSubmit();
      } else {
        caricaIncarichi();
      }
      console.log(values);
    },
  });

  const preview = () => {
    if (file != null) {
      let caricamento: CaricamentoMassivoBandiEqControllerApiPreviewEqRequest = {
        tipoEQ: form.values.tipoEQ as 'BA' | 'BV',
        tipoCaricamento: form.values.requestType as 'EQ' | 'EQ_BANDI' | 'BANDI',
        file: file,
      };
      dispatch(previewEqData(caricamento))
        .unwrap()
        .then((res): any => {
          console.log('Res ::>', res);
          setShowTable(true);
        })
        .catch((error) => {
          console.error('Error:', error);
          setShowTable(true);
        });
    }
  };

  const caricaIncarichi = () => {
    dispatch(
      uploadEqData({
        caricamentoMassivoRequest: {
          elencoEq: responseTable.elencoEq,
          tipoEQ: form.values.tipoEQ as 'BA' | 'BV',
          dataApertura: form.values.dataApertura,
          dataChiusura: form.values.dataChiusura,
          requestType: form.values.requestType as 'EQ' | 'EQ_BANDI' | 'BANDI',
          nomeBando: form.values.nomeBando,
          faseBandoPo: (form.values.faseBandoPo as 'PRIMA_APPLICAZIONE') || 'REGIME',
        },
      })
    )
      .unwrap()
      .then((resp) => {
        if (!!resp) {
          const blob = new Blob([resp], { type: 'text/plain' });
          let url;
          const link = document.createElement('a');
          url = URL.createObjectURL(blob);
          link.href = url;
          link.download = 'Log';
          link.click();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  useEffect(() => {
    setResponseTable(responseTableState);
    console.log('result', responseTable);

    dispatch(resetUploadStatus());
  }, [responseTableState]);

  useEffect(() => {
    dispatch(fetchUploadBandiEqContestoForm())
      .unwrap()
      .then((res) => {
        if (res) {
          if (res.tipoCaricamento) {
            const selectOption: CustomSelectItem[] = res.tipoCaricamento.map((s) => ({
              label: s.label,
              value: s.value + '',
            }));
            setOptions(selectOption);
          }
          if (res.faseBando) {
            const selectPhaseOptions: CustomSelectItem[] = res.faseBando.map((phase) => ({
              label: phase.label,
              value: phase.value + '',
            }));
            setPhaseOptions(selectPhaseOptions);
          }
          if (res.tipoEq) {
            const selectTipoEqOptions: CustomSelectItem[] = res.tipoEq.map((tipo) => ({
              label: tipo.label,
              value: tipo.value + '',
            }));
            setTipoEqOptions(selectTipoEqOptions);
          }
        }
      });
  }, [dispatch]);

  const handleOptionChange = (optionValue: any) => {
    //setSelectedOption(optionValue);
    form.setFieldValue('requestType', optionValue);
    form.setFieldValue('tipoEQ', undefined);
    setFile(null);
    setResponseTable(undefined);
    if (optionValue === '') form.resetForm();
  };

  const handleSubOptionChange = (subOptionValue: string) => {
    form.setFieldValue('tipoEQ', subOptionValue);
    setResponseTable(undefined);
  };

  const handleFileDelete = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Resetting input value
    }
    setResponseTable(undefined);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    }
  };

  /*  const handleOpenDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOpenDate(event.target.value);
    };
  
    const handleCloseDateChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setCloseDate(event.target.value);
    };*/

  const handleBandNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('nomeBando', event.target.value);
  };

  const handlePhaseChange = (phaseValue: string) => {
    form.setFieldValue('faseBandoPo', phaseValue, true);
  };
  const handleChangeField = (value: string, field: string) => {
    form.setFieldValue(field, value);
    form.setFieldTouched(field, true);
  };
  const handleSubmit = () => {
    preview();
  };

  const modalViewDetails = (description: string) => {
    setModalDescription(description);
    toggleModalListDetails(!isOpenListDetails);
  };

  const handleInputChange = (idRiga: number, newValue: string) => {
    setEditingValue((prevState: any) => ({
      ...prevState,
      [idRiga]: newValue,
    }));
  };

  const resetEditing = (idRiga: any, originalValue: any) => {
    setEditingValue((prevState: any) => {
      const { [idRiga]: removed, ...rest } = prevState;
      return rest;
    });
    setErrors((prevState: any) => {
      const { [idRiga]: removed, ...rest } = prevState;
      return rest;
    });
  };

  const confirmEditing = (idRiga: any) => {
    if (editingValue[idRiga]?.trim() === '') {
      setErrors((prevState: any) => ({
        ...prevState,
        [idRiga]: 'Questo campo è obbligatorio',
      }));
      return;
    }
    if (editingValue[idRiga]?.length != 8) {
      setErrors((prevState: any) => ({
        ...prevState,
        [idRiga]: 'Lunghezza campo 8 caratteri',
      }));
      return;
    }
    const updatedElencoEq = responseTable.elencoEq.map((item: any) => {
      if (item.idRiga === idRiga) {
        return { ...item, codiceUnivoco: editingValue[idRiga] };
      }
      return item;
    });
    setResponseTable({ ...responseTable, elencoEq: updatedElencoEq });
    setEditingValue((prevState: any) => {
      const { [idRiga]: removed, ...rest } = prevState;
      return rest;
    });
    setErrors((prevState: any) => {
      const { [idRiga]: removed, ...rest } = prevState;
      return rest;
    });
  };

  const getFieldError = <T extends object>(form: FormikProps<T>, fieldName: keyof T): string => {
    console.log(fieldName);
    if (form.getFieldMeta(fieldName as string).touched) {
      return (form.errors[fieldName] as string) || '';
    } else return '';
  };

  return (
    <div className="upload-gestione-messive">
      <PageHeader showIcon={false} title={title} />

      <form onSubmit={handleSubmit} className="form-custom form-inserimento">
        <Row>
          <Col xl={6}>
            <CustomSelect
              name="option"
              label="Scegli il tipo di caricamento"
              options={options}
              placeholder={t('Scegli il tipo di caricamento')}
              value={form.values.requestType}
              onChange={(value: string) => handleOptionChange(value)}
            />
          </Col>
        </Row>

        {form.values.requestType === 'EQ' && (
          <>
            <Row>
              <Col xl={6}>
                <CustomSelect
                  name="subOption"
                  label="Seleziona il tipo di EQ"
                  options={tipoEqOptions}
                  placeholder={t('Seleziona il tipo di EQ')}
                  value={form.values.tipoEQ}
                  onChange={(value: string) => handleSubOptionChange(value)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6} className="d-flex align-items-center">
                {form.values.tipoEQ && (
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      id="fileInput"
                      ref={fileInputRef}
                      onChange={(event) => handleFileChange(event)}
                      style={{ display: 'none' }}
                    />
                    <Button
                      color="primary"
                      onClick={() => document.getElementById('fileInput')?.click()}
                    >
                      Seleziona File
                    </Button>
                    {file && (
                      <div className="ml-2 d-flex align-items-center">
                        <span>{file.name}</span>
                        <Button color="link" className="ml-2 p-0" onClick={handleFileDelete}>
                          <span style={{ cursor: 'pointer' }}>
                            <Icon icon={'it-close'} />
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </>
        )}

        {form.values.requestType === 'EQ_BANDI' && (
          <div className="mt-2">
            <Row>
              <Col xl={6}>
                <CustomSelect
                  name="subOption"
                  label="Seleziona il tipo di EQ"
                  options={tipoEqOptions}
                  placeholder={t('Seleziona il tipo di EQ')}
                  value={form.values.tipoEQ}
                  onChange={(value: string) => handleSubOptionChange(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  type="date"
                  label="Data apertura bando"
                  placeholder="Inserisci la data di apertura"
                  id="openDate"
                  name="dataApertura"
                  value={form.values.dataApertura}
                  onChange={(e: any) => handleChangeField(e.target.value, 'dataApertura')}
                  wrapperClassName="required"
                  invalid={!!getFieldError(form, 'dataApertura')}
                  infoText={t(getFieldError(form, 'dataApertura')) || ''}
                />
              </Col>
              <Col md={6}>
                <Input
                  type="date"
                  label="Data chiusura bando"
                  placeholder="Inserisci la data di chiusura"
                  id="closeDate"
                  name="dataChiusura"
                  value={form.values.dataChiusura}
                  onChange={(e: any) => handleChangeField(e.target.value, 'dataChiusura')}
                  wrapperClassName="required"
                  invalid={!!getFieldError(form, 'dataChiusura')}
                  infoText={t(getFieldError(form, 'dataChiusura')) || ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  label="Nome Bando"
                  placeholder="Nome Bando"
                  type="text"
                  id="bandName"
                  value={form.values.nomeBando}
                  onChange={handleBandNameChange}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  name="phase"
                  label="Fase Bando"
                  options={phaseOptions}
                  placeholder={t('Seleziona la fase del bando')}
                  wrapperClass="required"
                  value={form.values.faseBandoPo}
                  onChange={(value: string) => handlePhaseChange(value)}
                  invalid={!!getFieldError(form, 'faseBandoPo')}
                  infoText={t(getFieldError(form, 'faseBandoPo')) || ''}
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center">
              <input
                type="file"
                id="fileInput"
                onChange={(event) => handleFileChange(event)}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button color="primary" onClick={() => document.getElementById('fileInput')?.click()}>
                Seleziona File
              </Button>
              {file && (
                <div className="ml-2 d-flex align-items-center">
                  <span>{file.name}</span>
                  <Button color="link" className="ml-2 p-0" onClick={handleFileDelete}>
                    <span style={{ cursor: 'pointer' }}>×</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {form.values.requestType === 'BANDI' && (
          <div className="mt-2">
            <Row>
              <Col xl={6}>
                <CustomSelect
                  name="subOption"
                  label="Seleziona il tipo di EQ"
                  options={tipoEqOptions}
                  placeholder={t('Seleziona il tipo di EQ')}
                  value={form.values.tipoEQ}
                  onChange={(value: string) => handleSubOptionChange(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  type="date"
                  label="Data apertura bando"
                  placeholder="Inserisci la data di apertura"
                  id="openDate"
                  name="dataApertura"
                  value={form.values.dataApertura}
                  onChange={(e: any) => handleChangeField(e.target.value, 'dataApertura')}
                  wrapperClassName="required"
                  invalid={!!getFieldError(form, 'dataApertura')}
                  infoText={t(getFieldError(form, 'dataApertura')) || ''}
                />
              </Col>
              <Col md={6}>
                <Input
                  type="date"
                  label="Data chiusura bando"
                  placeholder="Inserisci la data di chiusura"
                  id="closeDate"
                  name="dataChiusura"
                  value={form.values.dataChiusura}
                  onChange={(e: any) => handleChangeField(e.target.value, 'dataChiusura')}
                  wrapperClassName="required"
                  invalid={!!getFieldError(form, 'dataChiusura')}
                  infoText={t(getFieldError(form, 'dataChiusura')) || ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  label="Nome Bando"
                  placeholder="Nome Bando"
                  type="text"
                  id="bandName"
                  value={form.values.nomeBando}
                  onChange={handleBandNameChange}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  name="phase"
                  label="Fase Bando"
                  options={phaseOptions}
                  placeholder={t('Seleziona la fase del bando')}
                  wrapperClass="required"
                  value={form.values.faseBandoPo}
                  onChange={(value: string) => handlePhaseChange(value)}
                  invalid={!!getFieldError(form, 'faseBandoPo')}
                  infoText={t(getFieldError(form, 'faseBandoPo')) || ''}
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center">
              <input
                type="file"
                id="fileInput"
                onChange={(event) => handleFileChange(event)}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button color="primary" onClick={() => document.getElementById('fileInput')?.click()}>
                Seleziona File
              </Button>
              {file && (
                <div className="ml-2 d-flex align-items-center">
                  <span>{file.name}</span>
                  <Button color="link" className="ml-2 p-0" onClick={handleFileDelete}>
                    <span style={{ cursor: 'pointer' }}>×</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="text-right mt-4">
          <Button
            color="primary"
            onClick={() => {
              setSubmitType('anteprima');
              form.submitForm();
            }}
            disabled={!file}
          >
            Anteprima
          </Button>
        </div>
        <div className="text-right mt-4">
          <Button
            color="primary"
            onClick={() => {
              setSubmitType('carica');
              form.submitForm();
            }}
            disabled={!showTable}
          >
            Carica
          </Button>
        </div>
      </form>

      {showTable && responseTable && (
        <div className="table-responsive" style={{ marginTop: '1rem', maxHeight: '40rem' }}>
          <Table striped className="table table-striped table-bordered mt-5">
            <thead>
              <tr>
                <th>Errori</th>
                <th>Id Riga</th>
                <th style={{ minWidth: '200px' }}>Codice Incarico</th>
                <th>Codice Struttura</th>
                <th>Descrizione</th>
                <th>Descrizione Breve</th>
                <th>Sede</th>
                <th>Equiparata</th>
                <th>Declaratoria Funzioni</th>
                {(form.values.requestType === 'EQ_BANDI' ||
                  form.values.requestType === 'BANDI') && (
                  <>
                    <th>Codice Cifra Determina Avviso</th>
                    {form.values.tipoEQ === 'BV' && <th>Progetto</th>}
                    <th>Requisiti Oggettivi</th>
                    <th>Requisiti Soggettivi</th>
                    <th>Durata Incarico</th>
                    <th>Tipologia EQ</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {responseTable.elencoEq.map((item: any) => (
                <tr
                  key={item.idRiga}
                  className={
                    uploadStatus !== STATUS_FULLFILLED && responseTable.errori[item.idRiga]
                      ? 'error-upload-massivo'
                      : ''
                  }
                >
                  <td>
                    {uploadStatus !== STATUS_FULLFILLED && responseTable.errori[item.idRiga] && (
                      <>
                        <Button className="" id={`error-${item.idRiga}`}>
                          <Icon style={{ float: 'left' }} icon={'it-warning-circle'} />
                        </Button>
                        <UncontrolledTooltip placement="top" target={`error-${item.idRiga}`}>
                          {responseTable.errori[item.idRiga]}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                  <td>{item.idRiga}</td>
                  <td>
                    <input
                      type="text"
                      value={
                        editingValue[item.idRiga] !== undefined
                          ? editingValue[item.idRiga]
                          : item.codiceUnivoco
                      }
                      onChange={(e) => handleInputChange(item.idRiga, e.target.value)}
                    />
                    {errors[item.idRiga] && (
                      <div style={{ color: 'red' }}>{errors[item.idRiga]}</div>
                    )}
                    {editingValue[item.idRiga] !== undefined && (
                      <div>
                        <Button onClick={() => confirmEditing(item.idRiga)}>
                          {' '}
                          <Icon icon={'it-check'} />
                        </Button>
                        <Button onClick={() => resetEditing(item.idRiga, item.codiceUnivoco)}>
                          <Icon icon={'it-close'} />
                        </Button>
                      </div>
                    )}
                  </td>
                  <td>{item.codiceIncaricoPadre}</td>
                  <td>
                    {item.descrizione.length > 10 ? (
                      <Button
                        className="btn-detail"
                        onClick={() => modalViewDetails(item.descrizione)}
                      >
                        {item.descrizione.substr(0, 50)}...{' '}
                        <span style={{ textDecoration: 'underline' }}>Leggi di più</span>
                      </Button>
                    ) : (
                      item.descrizione
                    )}
                  </td>
                  <td>{item.descBreve}</td>
                  <td>{item.indirizzo}</td>
                  <td>{item.flagEquiparata ? 'Si' : 'No'}</td>
                  <td>
                    {item.declaratoriaFunzioni.length > 10 ? (
                      <Button
                        className="btn-detail"
                        onClick={() => modalViewDetails(item.declaratoriaFunzioni)}
                      >
                        {item.declaratoriaFunzioni.substr(0, 50)}...{' '}
                        <span style={{ textDecoration: 'underline' }}>Leggi di più</span>
                      </Button>
                    ) : (
                      item.declaratoriaFunzioni
                    )}
                  </td>
                  {(form.values.requestType === 'EQ_BANDI' ||
                    form.values.requestType === 'BANDI') && (
                    <>
                      <td>{item.codiceCifraDeterminaAvviso}</td>
                      {form.values.tipoEQ === 'BV' && <td>{item.progetto}</td>}
                      <td>
                        {item.requisitiOggettivi.length > 10 ? (
                          <Button
                            className="btn-detail"
                            onClick={() => modalViewDetails(item.requisitiOggettivi)}
                          >
                            {item.requisitiOggettivi.substr(0, 50)}...{' '}
                            <span style={{ textDecoration: 'underline' }}>Leggi di più</span>
                          </Button>
                        ) : (
                          item.requisitiOggettivi
                        )}
                      </td>
                      <td>
                        {item.requisitiSoggettivi.length > 10 ? (
                          <Button
                            className="btn-detail"
                            onClick={() => modalViewDetails(item.requisitiSoggettivi)}
                          >
                            {item.requisitiSoggettivi.substr(0, 50)}...{' '}
                            <span style={{ textDecoration: 'underline' }}>Leggi di più</span>
                          </Button>
                        ) : (
                          item.requisitiSoggettivi
                        )}
                      </td>
                      <td>{item.durataIncarico}</td>
                      <td>{item.tipoBandoEq}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* Modal per i dettagli completi */}
      <Modal
        isOpen={isOpenListDetails}
        toggle={() => toggleModalListDetails(!isOpenListDetails)}
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalListDetails(!isOpenListDetails)}>
          Dettagli
        </ModalHeader>
        <ModalBody>
          <p className="mb-3">{modalDescription}</p>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default UploadGestioneMassiva;
