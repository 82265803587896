import { Button, Col, Input, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { useNavigate } from 'react-router-dom';
import { HREF_ELENCO_POSIZIONI_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch } from '../../hooks';
import {
  getStrutturaOrganizzativaOpts,
  insertPosizioneOrganizzativa,
} from '../../store/posizioneOrganizzativaSlice';
import {
  CreaPosizioneOrganizzativaContestoForm,
  FormsControllerApi,
  PosizioneOrganizzativaInsertRequestDto,
} from '../../services/ms-anagrafica-unica';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { MS_AU_CONFIG } from '../../store/store-constants';
import PageHeader from '../../components/common/page-header/PageHeader';
import CustomAutocomplete from '../../components/common/custom-autocomplete/CustomAutocomplete';
import { debounce } from 'lodash';
import TextareaInfoChars from 'components/common/textarea-infochars/TextareaInfoChars';

function NuovaPosizioneOrganizzativa() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [tipoOpts, setTipoOpts] = useState<CustomSelectItem[]>([]);
  const [soType, setSoType] = useState('');

  const navigate = useNavigate();
  const initialValues: PosizioneOrganizzativaInsertRequestDto = {
    codiceUnivoco: '',
    denominazione: '',
    declaratoria: '',
    dataInizio: undefined,
    dataFine: undefined,
    strutturaorganizzativa: '',
    tipo: undefined,
    idNotaPropostaIstituzione: undefined,
    idNullaOsta: undefined,
    idNotaDiUscita: undefined,
  };

  const schemaPO = z
    .object({
      codiceUnivoco: z
        .string({ required_error: 'Codice univoco è un campo obbligatorio' })
        .length(8, { message: 'lenghtCodInca' }),
      denominazione: z.string({ required_error: 'Denominazione è un campo obbligatorio' }).max(50),
      declaratoria: z.string({ required_error: 'Declaratoria è un campo obbligatorio' }).max(500),
      strutturaorganizzativa: z.string({
        required_error: 'Struttura organizzativa è un campo obbligatorio',
      }),
      tipo: z.string({ required_error: 'Tipo è un campo obbligatorio' }),
      dataInizio: z.coerce
        .date({ required_error: 'Il campo data inizio è obbligatorio' })
        .min(today, { message: 'Il campo data inizio non è valido' }),
      dataFine: z.coerce
        .date()
        .min(today, { message: 'Il campo data fine non è valido' })
        .optional(),
      idNotaPropostaIstituzione: z.number({
        required_error: 'Nota proposta è un campo obbligatorio',
      }),
      idNullaOsta: z
        .number({ required_error: 'Il campo nulla osta è obbligatorio per il tipo scelto' })
        .gt(0, { message: 'required' }),
      idNotaDiUscita: z
        .number({ required_error: "Il campo nota d'uscita è obbligatorio per il tipo scelto" })
        .gt(0, { message: 'required' }),
    })
    .refine(
      (data) =>
        !data.dataFine || isNaN(data.dataFine.getTime()) || data.dataFine >= data.dataInizio,
      {
        message: 'Data fine non può essere minore della data inizio',
        path: ['dataFine'], // path of error
      }
    );

  const schemaPOEquiparata = z
    .object({
      codiceUnivoco: z
        .string({ required_error: 'Codice univoco è un campo obbligatorio' })
        .length(8, { message: 'lenghtCodInca' }),
      denominazione: z.string({ required_error: 'Denominazione è un campo obbligatorio' }).max(50),
      declaratoria: z.string({ required_error: 'Declaratoria è un campo obbligatorio' }).max(500),
      strutturaorganizzativa: z.string({
        required_error: 'Struttura organizzativa è un campo obbligatorio',
      }),
      tipo: z.string({ required_error: 'Tipo è un campo obbligatorio' }),
      dataInizio: z.coerce
        .date({ required_error: 'Il campo data inizio è obbligatorio' })
        .min(today, { message: 'Il campo data inizio non è valido' }),
      dataFine: z.coerce
        .date()
        .min(today, { message: 'Il campo data fine non è valido' })
        .optional(),
      idNotaPropostaIstituzione: z.number({
        required_error: 'Nota proposta è un campo obbligatorio',
      }),
      idNullaOsta: z.number().optional(),
      idNotaDiUscita: z.number().optional(),
    })
    .refine(
      (data) =>
        !data.dataFine || isNaN(data.dataFine.getTime()) || data.dataFine >= data.dataInizio,
      {
        message: 'Data fine non può essere minore della data inizio',
        path: ['dataFine'], // path of error
      }
    );

  const schema =
    soType === 'PO'
      ? toFormikValidationSchema(schemaPO)
      : toFormikValidationSchema(schemaPOEquiparata);

  const formik: FormikProps<PosizioneOrganizzativaInsertRequestDto> = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(insertPosizioneOrganizzativa(values));
    },
  });

  const handleSelectChange = (e: any, name: string) => {
    setSoType(e);
    console.log(name, e);
    if (e === 'PO_EQUIPARATA') {
      formik.setFieldValue('idNullaOsta', undefined);
      formik.setFieldValue('idNotaDiUscita', undefined);
    }
    formik.setFieldValue(name, e);
  };

  const setDocumentId = (name: string, id: number | undefined) => {
    if (id && id !== -1) {
      formik.setFieldValue(name, id);
    } else {
      formik.setFieldValue(name, null);
      console.error('errore durante la chiamata di caricamento documento');
    }
  };

  const getFieldError = (
    form: FormikProps<PosizioneOrganizzativaInsertRequestDto>,
    fieldName: keyof PosizioneOrganizzativaInsertRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  useEffect(() => {
    initCombos();
  }, []);

  const initCombos = () => {
    dispatch(getStrutturaOrganizzativaOpts()).then((strutture) => {
      if (
        strutture.payload &&
        (strutture.payload as CreaPosizioneOrganizzativaContestoForm).struttureOrganizzative
      ) {
        setTipoOpts(
          (strutture.payload as CreaPosizioneOrganizzativaContestoForm).tipi! as CustomSelectItem[]
        );
      }
    });
  };

  const resetInputForm = () => {
    formik.resetForm();
  };

  const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getCreaPosizioniOrganizzativeContestoForm({
      chiave: inputValue,
    });
    let options: { value: string | undefined; label: string | undefined }[] = [];
    if (response.data.struttureOrganizzative) {
      response.data.struttureOrganizzative.forEach((d) => {
        options.push({ value: d.value, label: d.label });
      });
    }
    callback(options);
  }, 500);

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}
        title="Nuova Elevata Qualificazione"
      ></PageHeader>
      <div className="form-custom form-bg form-inserimento border">
        <div className="form-row">
          <Input
            label="Codice Univoco EQ"
            type="text"
            placeholder="Inserisci il codice univoco"
            id="input-codiceUnivoco"
            wrapperClassName="required col-xl-6"
            name="codiceUnivoco"
            invalid={!!getFieldError(formik, 'codiceUnivoco')}
            infoText={t(getFieldError(formik, 'codiceUnivoco')) || ''}
            value={formik.values.codiceUnivoco}
            onChange={formik.handleChange}
          />
          <Input
            label="Denominazione"
            type="text"
            placeholder="Inserisci la denominazione"
            id="input-denominazione"
            wrapperClassName="required col-xl-6"
            name="denominazione"
            invalid={!!getFieldError(formik, 'denominazione')}
            infoText={t(getFieldError(formik, 'denominazione')) || ''}
            value={formik.values.denominazione}
            onChange={formik.handleChange}
          />
        </div>
        <div className="form-row">
          <TextareaInfoChars
            label="Declaratoria"
            placeholder="Inserisci la declaratoria"
            id="input-declaratoria"
            wrapperClassName="required col-xl-12"
            name="declaratoria"
            maxLength={500}
            invalid={!!getFieldError(formik, 'declaratoria')}
            infoText={t(getFieldError(formik, 'declaratoria')) || ''}
            value={formik.values.declaratoria}
            onChange={formik.handleChange}
            rows={5}
          />
        </div>
        <div className="form-row">
          <Col xl={6} style={{ marginTop: '-35px' }}>
            <CustomAutocomplete
              required={true}
              label="Struttura Organizzativa"
              placeholder="Seleziona la struttura organizzativa"
              id="select-struttura-organizzativa"
              invalid={!!getFieldError(formik, 'strutturaorganizzativa')}
              infoText={t(getFieldError(formik, 'strutturaorganizzativa')) || ''}
              // value={searchForm.values.strutturaOrganizzativa}
              loadOptionsFn={autocompleteStruttureOrganizzative}
              handleOptionSelect={(e: string) => handleSelectChange(e, 'strutturaorganizzativa')}
            />
          </Col>

          <Col xl="6">
            <CustomSelect
              label="Tipo"
              placeholder="Seleziona il tipo"
              options={tipoOpts}
              name="tipo"
              value={formik.values.tipo}
              wrapperClass="required"
              invalid={!!getFieldError(formik, 'tipo')}
              infoText={t(getFieldError(formik, 'tipo')) || ''}
              onChange={(e) => {
                handleSelectChange(e, 'tipo');
              }}
            />
          </Col>
        </div>
        <div className="row">
          <Input
            type="date"
            label="Data inizio Validità"
            placeholder="Inserisci la data di inizio Validità"
            id="input-dataInizioValidita"
            name="dataInizio"
            invalid={!!getFieldError(formik, 'dataInizio')}
            infoText={t(getFieldError(formik, 'dataInizio')) || ''}
            value={formik.values.dataInizio}
            onChange={formik.handleChange}
            wrapperClassName="form-group required col-md-6"
          />

          <Input
            type="date"
            label="Data fine Validità"
            placeholder="Inserisci la data di fine Validità"
            id="input-dataFineValidita"
            name="dataFine"
            invalid={!!getFieldError(formik, 'dataFine')}
            infoText={t(getFieldError(formik, 'dataFine')) || ''}
            value={formik.values.dataFine}
            onChange={formik.handleChange}
            wrapperClassName="form-group col-md-6"
          />
        </div>

        <hr />
        <DocumentUpload
          documentName="Nota proposta istituzione"
          className="required my-3"
          idDocumento={formik.values.idNotaPropostaIstituzione}
          invalid={!!getFieldError(formik, 'idNotaPropostaIstituzione')}
          infoText={t(getFieldError(formik, 'idNotaPropostaIstituzione')) || ''}
          setDocumentId={(id) => setDocumentId('idNotaPropostaIstituzione', id)}
        />

        {soType === 'PO' && (
          <>
            <DocumentUpload
              documentName="Nulla osta"
              className={`my-3 ${formik.values.tipo !== 'PO_EQUIPARATA' ? 'required' : ''}`}
              idDocumento={formik.values.idNullaOsta}
              invalid={!!getFieldError(formik, 'idNullaOsta')}
              infoText={t(getFieldError(formik, 'idNullaOsta')) || ''}
              setDocumentId={(id) => setDocumentId('idNullaOsta', id)}
            />

            <DocumentUpload
              documentName="Nota di uscita"
              className={`my-3 ${formik.values.tipo !== 'PO_EQUIPARATA' ? 'required' : ''}`}
              idDocumento={formik.values.idNotaDiUscita}
              invalid={!!getFieldError(formik, 'idNotaDiUscita')}
              infoText={t(getFieldError(formik, 'idNotaDiUscita')) || ''}
              setDocumentId={(id) => setDocumentId('idNotaDiUscita', id)}
            />
          </>
        )}
      </div>
      <div className="d-flex flex-row-reverse pb-5 pt-2">
        <Button color="primary" className="mt-2" onClick={formik.submitForm}>
          Conferma
        </Button>
        <Button
          color="secondary"
          className="mt-2 mr-2"
          onClick={() => {
            resetInputForm();
          }}
        >
          {t('reset')}{' '}
        </Button>
        <Button
          outline
          color="primary"
          className="mt-2 mr-2"
          onClick={() => navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE)}
        >
          Indietro
        </Button>
      </div>
    </div>
  );
}

export default NuovaPosizioneOrganizzativa;
