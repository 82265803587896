import { Button, Icon } from 'design-react-kit';
import { ButtonCustomProps } from './ButtonCustomProps';

interface ButtonDownloadProps extends ButtonCustomProps {
  /**
   * Mostra l'icona vedi (occhio)
   */
  iconVedi?: boolean;
}

function ButtonDownload(props: ButtonDownloadProps) {
  return (
    <>
      {props.showOnlyIcon ? (
        <div
          className="item-icon-menu"
          onClick={props.onCliclEvent}
          title={props.titleBtn ?? 'Scarica File'}
        >
          <Icon icon="it-download" color="primary"></Icon>
        </div>
      ) : (
        <Button
          className={props.className}
          color="primary"
          outline
          size="xs"
          onClick={props.onCliclEvent}
          title={props.titleBtn ?? 'Scarica File'}
        >
          {props.iconVedi ? (
            <>
              <Icon icon="it-password-visible" size="xs" color="primary" />{' '}
              {props.testoBtn ?? 'Vedi File'}
            </>
          ) : (
            <>
              <Icon icon="it-download" size="xs" color="primary" />{' '}
              {props.testoBtn ?? 'Scarica File'}
            </>
          )}
        </Button>
      )}
    </>
  );
}

export default ButtonDownload;
