import { Col, Container, Headers, Row } from 'design-react-kit';
import { Outlet } from 'react-router-dom';
import HeaderCenter from './headers/HeaderCenter';
import HeaderSlim from './headers/HeaderSlim';
// import { getUserInfo } from "../../store/userSlice";
import Footer from './footer/Footer';

function LayoutCourtesy() {
  // useEffect(() => {
  //     document.title = 'Anagrafica unica';
  //     dispatch(initializeUser()).unwrap().then( resp => resp && dispatch(getUserInfo()))
  // }, []);

  return (
    <div>
      <Headers>
        <HeaderSlim />
        <HeaderCenter />
      </Headers>

      <Container fluid style={{ minHeight: '70rem' }}>
        <Row>
          <Col xs="lg-12" className="p-5">
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default LayoutCourtesy;
