/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BandoStakeholderRequestDto } from '../model';
// @ts-ignore
import { DatiAttoDto } from '../model';
// @ts-ignore
import { InadPecDto } from '../model';
// @ts-ignore
import { IniPecDto } from '../model';
// @ts-ignore
import { IpaDto } from '../model';
// @ts-ignore
import { PraticaStakeholderRequestDto } from '../model';
/**
 * DatiAmministrazioneControllerApi - axios parameter creator
 * @export
 */
export const DatiAmministrazioneControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Inserisci un bando per gli stakeholder esterni
     * @summary Inserimento bando stakeholder
     * @param {BandoStakeholderRequestDto} bandoStakeholderRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertBandoStakeholder: async (
      bandoStakeholderRequestDto: BandoStakeholderRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bandoStakeholderRequestDto' is not null or undefined
      assertParamExists(
        'insertBandoStakeholder',
        'bandoStakeholderRequestDto',
        bandoStakeholderRequestDto
      );
      const localVarPath = `/v1/dati-amministrazione/bandi-stakeholder`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bandoStakeholderRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Inserisci una pratica di uno stakeholder esterno
     * @summary Inserimento pratica stakeholder
     * @param {PraticaStakeholderRequestDto} praticaStakeholderRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertPraticaStakeholder: async (
      praticaStakeholderRequestDto: PraticaStakeholderRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'praticaStakeholderRequestDto' is not null or undefined
      assertParamExists(
        'insertPraticaStakeholder',
        'praticaStakeholderRequestDto',
        praticaStakeholderRequestDto
      );
      const localVarPath = `/v1/dati-amministrazione/pratiche-stakeholder`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        praticaStakeholderRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Recupero dati interoperabilità CIFRA a partire dal codice atto.
     * @summary CIFRA
     * @param {string} codiceAoo
     * @param {string} anno
     * @param {string} numero
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoAttoCifra: async (
      codiceAoo: string,
      anno: string,
      numero: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'codiceAoo' is not null or undefined
      assertParamExists('recuperoAttoCifra', 'codiceAoo', codiceAoo);
      // verify required parameter 'anno' is not null or undefined
      assertParamExists('recuperoAttoCifra', 'anno', anno);
      // verify required parameter 'numero' is not null or undefined
      assertParamExists('recuperoAttoCifra', 'numero', numero);
      const localVarPath = `/v1/dati-amministrazione/cifra/atto/{codiceAoo}/{anno}/{numero}`
        .replace(`{${'codiceAoo'}}`, encodeURIComponent(String(codiceAoo)))
        .replace(`{${'anno'}}`, encodeURIComponent(String(anno)))
        .replace(`{${'numero'}}`, encodeURIComponent(String(numero)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Recupero dati amministrazione a partire dal codice fiscale.
     * @summary IPA
     * @param {string} codiceFiscaleEnte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoIPA: async (
      codiceFiscaleEnte: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'codiceFiscaleEnte' is not null or undefined
      assertParamExists('recuperoIPA', 'codiceFiscaleEnte', codiceFiscaleEnte);
      const localVarPath = `/v1/dati-amministrazione/ipa/{codiceFiscaleEnte}`.replace(
        `{${'codiceFiscaleEnte'}}`,
        encodeURIComponent(String(codiceFiscaleEnte))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Recupero indirizzo PEC a partire dal codice fiscale.
     * @summary INI-PEC
     * @param {string} codiceFiscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoPec: async (
      codiceFiscale: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'codiceFiscale' is not null or undefined
      assertParamExists('recuperoPec', 'codiceFiscale', codiceFiscale);
      const localVarPath = `/v1/dati-amministrazione/inipec/{codiceFiscale}`.replace(
        `{${'codiceFiscale'}}`,
        encodeURIComponent(String(codiceFiscale))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Recupero domicilio digitale a partire dal codice fiscale.
     * @summary INAD
     * @param {string} codiceFiscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoPecInad: async (
      codiceFiscale: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'codiceFiscale' is not null or undefined
      assertParamExists('recuperoPecInad', 'codiceFiscale', codiceFiscale);
      const localVarPath = `/v1/dati-amministrazione/inad/{codiceFiscale}`.replace(
        `{${'codiceFiscale'}}`,
        encodeURIComponent(String(codiceFiscale))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatiAmministrazioneControllerApi - functional programming interface
 * @export
 */
export const DatiAmministrazioneControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatiAmministrazioneControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Inserisci un bando per gli stakeholder esterni
     * @summary Inserimento bando stakeholder
     * @param {BandoStakeholderRequestDto} bandoStakeholderRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertBandoStakeholder(
      bandoStakeholderRequestDto: BandoStakeholderRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertBandoStakeholder(
        bandoStakeholderRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Inserisci una pratica di uno stakeholder esterno
     * @summary Inserimento pratica stakeholder
     * @param {PraticaStakeholderRequestDto} praticaStakeholderRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertPraticaStakeholder(
      praticaStakeholderRequestDto: PraticaStakeholderRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertPraticaStakeholder(
        praticaStakeholderRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Recupero dati interoperabilità CIFRA a partire dal codice atto.
     * @summary CIFRA
     * @param {string} codiceAoo
     * @param {string} anno
     * @param {string} numero
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recuperoAttoCifra(
      codiceAoo: string,
      anno: string,
      numero: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatiAttoDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recuperoAttoCifra(
        codiceAoo,
        anno,
        numero,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Recupero dati amministrazione a partire dal codice fiscale.
     * @summary IPA
     * @param {string} codiceFiscaleEnte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recuperoIPA(
      codiceFiscaleEnte: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IpaDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recuperoIPA(
        codiceFiscaleEnte,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Recupero indirizzo PEC a partire dal codice fiscale.
     * @summary INI-PEC
     * @param {string} codiceFiscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recuperoPec(
      codiceFiscale: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IniPecDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recuperoPec(codiceFiscale, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Recupero domicilio digitale a partire dal codice fiscale.
     * @summary INAD
     * @param {string} codiceFiscale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recuperoPecInad(
      codiceFiscale: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InadPecDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recuperoPecInad(
        codiceFiscale,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DatiAmministrazioneControllerApi - factory interface
 * @export
 */
export const DatiAmministrazioneControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DatiAmministrazioneControllerApiFp(configuration);
  return {
    /**
     * Inserisci un bando per gli stakeholder esterni
     * @summary Inserimento bando stakeholder
     * @param {DatiAmministrazioneControllerApiInsertBandoStakeholderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertBandoStakeholder(
      requestParameters: DatiAmministrazioneControllerApiInsertBandoStakeholderRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .insertBandoStakeholder(requestParameters.bandoStakeholderRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Inserisci una pratica di uno stakeholder esterno
     * @summary Inserimento pratica stakeholder
     * @param {DatiAmministrazioneControllerApiInsertPraticaStakeholderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertPraticaStakeholder(
      requestParameters: DatiAmministrazioneControllerApiInsertPraticaStakeholderRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .insertPraticaStakeholder(requestParameters.praticaStakeholderRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Recupero dati interoperabilità CIFRA a partire dal codice atto.
     * @summary CIFRA
     * @param {DatiAmministrazioneControllerApiRecuperoAttoCifraRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoAttoCifra(
      requestParameters: DatiAmministrazioneControllerApiRecuperoAttoCifraRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<DatiAttoDto> {
      return localVarFp
        .recuperoAttoCifra(
          requestParameters.codiceAoo,
          requestParameters.anno,
          requestParameters.numero,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Recupero dati amministrazione a partire dal codice fiscale.
     * @summary IPA
     * @param {DatiAmministrazioneControllerApiRecuperoIPARequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoIPA(
      requestParameters: DatiAmministrazioneControllerApiRecuperoIPARequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<IpaDto> {
      return localVarFp
        .recuperoIPA(requestParameters.codiceFiscaleEnte, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Recupero indirizzo PEC a partire dal codice fiscale.
     * @summary INI-PEC
     * @param {DatiAmministrazioneControllerApiRecuperoPecRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoPec(
      requestParameters: DatiAmministrazioneControllerApiRecuperoPecRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<IniPecDto> {
      return localVarFp
        .recuperoPec(requestParameters.codiceFiscale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Recupero domicilio digitale a partire dal codice fiscale.
     * @summary INAD
     * @param {DatiAmministrazioneControllerApiRecuperoPecInadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recuperoPecInad(
      requestParameters: DatiAmministrazioneControllerApiRecuperoPecInadRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<InadPecDto> {
      return localVarFp
        .recuperoPecInad(requestParameters.codiceFiscale, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for insertBandoStakeholder operation in DatiAmministrazioneControllerApi.
 * @export
 * @interface DatiAmministrazioneControllerApiInsertBandoStakeholderRequest
 */
export interface DatiAmministrazioneControllerApiInsertBandoStakeholderRequest {
  /**
   *
   * @type {BandoStakeholderRequestDto}
   * @memberof DatiAmministrazioneControllerApiInsertBandoStakeholder
   */
  readonly bandoStakeholderRequestDto: BandoStakeholderRequestDto;
}

/**
 * Request parameters for insertPraticaStakeholder operation in DatiAmministrazioneControllerApi.
 * @export
 * @interface DatiAmministrazioneControllerApiInsertPraticaStakeholderRequest
 */
export interface DatiAmministrazioneControllerApiInsertPraticaStakeholderRequest {
  /**
   *
   * @type {PraticaStakeholderRequestDto}
   * @memberof DatiAmministrazioneControllerApiInsertPraticaStakeholder
   */
  readonly praticaStakeholderRequestDto: PraticaStakeholderRequestDto;
}

/**
 * Request parameters for recuperoAttoCifra operation in DatiAmministrazioneControllerApi.
 * @export
 * @interface DatiAmministrazioneControllerApiRecuperoAttoCifraRequest
 */
export interface DatiAmministrazioneControllerApiRecuperoAttoCifraRequest {
  /**
   *
   * @type {string}
   * @memberof DatiAmministrazioneControllerApiRecuperoAttoCifra
   */
  readonly codiceAoo: string;

  /**
   *
   * @type {string}
   * @memberof DatiAmministrazioneControllerApiRecuperoAttoCifra
   */
  readonly anno: string;

  /**
   *
   * @type {string}
   * @memberof DatiAmministrazioneControllerApiRecuperoAttoCifra
   */
  readonly numero: string;
}

/**
 * Request parameters for recuperoIPA operation in DatiAmministrazioneControllerApi.
 * @export
 * @interface DatiAmministrazioneControllerApiRecuperoIPARequest
 */
export interface DatiAmministrazioneControllerApiRecuperoIPARequest {
  /**
   *
   * @type {string}
   * @memberof DatiAmministrazioneControllerApiRecuperoIPA
   */
  readonly codiceFiscaleEnte: string;
}

/**
 * Request parameters for recuperoPec operation in DatiAmministrazioneControllerApi.
 * @export
 * @interface DatiAmministrazioneControllerApiRecuperoPecRequest
 */
export interface DatiAmministrazioneControllerApiRecuperoPecRequest {
  /**
   *
   * @type {string}
   * @memberof DatiAmministrazioneControllerApiRecuperoPec
   */
  readonly codiceFiscale: string;
}

/**
 * Request parameters for recuperoPecInad operation in DatiAmministrazioneControllerApi.
 * @export
 * @interface DatiAmministrazioneControllerApiRecuperoPecInadRequest
 */
export interface DatiAmministrazioneControllerApiRecuperoPecInadRequest {
  /**
   *
   * @type {string}
   * @memberof DatiAmministrazioneControllerApiRecuperoPecInad
   */
  readonly codiceFiscale: string;
}

/**
 * DatiAmministrazioneControllerApi - object-oriented interface
 * @export
 * @class DatiAmministrazioneControllerApi
 * @extends {BaseAPI}
 */
export class DatiAmministrazioneControllerApi extends BaseAPI {
  /**
   * Inserisci un bando per gli stakeholder esterni
   * @summary Inserimento bando stakeholder
   * @param {DatiAmministrazioneControllerApiInsertBandoStakeholderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatiAmministrazioneControllerApi
   */
  public insertBandoStakeholder(
    requestParameters: DatiAmministrazioneControllerApiInsertBandoStakeholderRequest,
    options?: AxiosRequestConfig
  ) {
    return DatiAmministrazioneControllerApiFp(this.configuration)
      .insertBandoStakeholder(requestParameters.bandoStakeholderRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Inserisci una pratica di uno stakeholder esterno
   * @summary Inserimento pratica stakeholder
   * @param {DatiAmministrazioneControllerApiInsertPraticaStakeholderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatiAmministrazioneControllerApi
   */
  public insertPraticaStakeholder(
    requestParameters: DatiAmministrazioneControllerApiInsertPraticaStakeholderRequest,
    options?: AxiosRequestConfig
  ) {
    return DatiAmministrazioneControllerApiFp(this.configuration)
      .insertPraticaStakeholder(requestParameters.praticaStakeholderRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Recupero dati interoperabilità CIFRA a partire dal codice atto.
   * @summary CIFRA
   * @param {DatiAmministrazioneControllerApiRecuperoAttoCifraRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatiAmministrazioneControllerApi
   */
  public recuperoAttoCifra(
    requestParameters: DatiAmministrazioneControllerApiRecuperoAttoCifraRequest,
    options?: AxiosRequestConfig
  ) {
    return DatiAmministrazioneControllerApiFp(this.configuration)
      .recuperoAttoCifra(
        requestParameters.codiceAoo,
        requestParameters.anno,
        requestParameters.numero,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Recupero dati amministrazione a partire dal codice fiscale.
   * @summary IPA
   * @param {DatiAmministrazioneControllerApiRecuperoIPARequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatiAmministrazioneControllerApi
   */
  public recuperoIPA(
    requestParameters: DatiAmministrazioneControllerApiRecuperoIPARequest,
    options?: AxiosRequestConfig
  ) {
    return DatiAmministrazioneControllerApiFp(this.configuration)
      .recuperoIPA(requestParameters.codiceFiscaleEnte, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Recupero indirizzo PEC a partire dal codice fiscale.
   * @summary INI-PEC
   * @param {DatiAmministrazioneControllerApiRecuperoPecRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatiAmministrazioneControllerApi
   */
  public recuperoPec(
    requestParameters: DatiAmministrazioneControllerApiRecuperoPecRequest,
    options?: AxiosRequestConfig
  ) {
    return DatiAmministrazioneControllerApiFp(this.configuration)
      .recuperoPec(requestParameters.codiceFiscale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Recupero domicilio digitale a partire dal codice fiscale.
   * @summary INAD
   * @param {DatiAmministrazioneControllerApiRecuperoPecInadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatiAmministrazioneControllerApi
   */
  public recuperoPecInad(
    requestParameters: DatiAmministrazioneControllerApiRecuperoPecInadRequest,
    options?: AxiosRequestConfig
  ) {
    return DatiAmministrazioneControllerApiFp(this.configuration)
      .recuperoPecInad(requestParameters.codiceFiscale, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
