import PageHeader from '../../components/common/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { HREF_RUBRICA_ELENCO_DIPENDENTI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  detailsDipendente,
  getNumeriTelefonoAssociabili,
  inserisciTelefonoCredenzialiEmail,
} from '../../store/rubricaSlice';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import DipendenteDetailsComponent from '../../components/dipendente-detail-form/DipendenteDetailsComponent';
import { Button, Col, Input, Toggle, PasswordInput, Icon } from 'design-react-kit';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import { ModificaInfoRubricaRequestDto } from '../../services/ms-anagrafica-unica';
import { FormikProps, useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import eye from '../../assets/images/password-icon.svg';
import eyeOff from '../../assets/images/password-icon-slash.svg';
import './style.scss';

let initialValuesTelCred: ModificaInfoRubricaRequestDto = {
  disassociaTelefono: undefined,
  disassociaCredenziali: undefined,
  disassociaMail: undefined,
  idNumeroTelefono: undefined,
  credenzialiDominio: undefined,
  passwordDominio: undefined,

  requestType: '',
};

const validationSchemaTelCredConfirm = z.object({
  idNumeroTelefono: z.number().optional(),
  credenzialiDominio: z.string().optional(),
  passwordDominio: z.string().optional(),
});

const ModificaUtenze = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dipendenteResult, dipendenteStatus, numeriAssociabiliResult } = useAppSelector(
    (state) => state.rubrica
  );
  const [disassociaTelefono, setDisassociaTelefono] = useState<boolean>(false);
  const [disassociaCredenziali, setDisassociaCredenziali] = useState<boolean>(false);
  const [disassociaMail, setDisassociaMail] = useState<boolean>(false);
  const [type, setType] = useState<any>('password');
  const [icon, setIcon] = useState<any>(eyeOff);

  let numerazioniAssociabili: CustomSelectItem[] = [];
  numeriAssociabiliResult?.forEach((s) => {
    numerazioniAssociabili.push({ label: s.label, value: s.valore });
  });

  const modificaForm: FormikProps<any> = useFormik({
    initialValues: initialValuesTelCred,
    validationSchema: toFormikValidationSchema(validationSchemaTelCredConfirm),
    onSubmit: (values) => {
      dispatch(
        inserisciTelefonoCredenzialiEmail({
          id: Number(id),
          patchDatiRubricaDipendenteRequest: {
            ...values,
            requestType: 'Modifica',
          },
        })
      )
        .unwrap()
        .then((resp) => resp && navigate(HREF_RUBRICA_ELENCO_DIPENDENTI));
      // .catch((error) => console.error('rejected', error))
    },
  });

  const getDetails = () => {
    dispatch(detailsDipendente(Number.parseInt(id!)));
  };

  const getFieldError = (
    form: FormikProps<ModificaInfoRubricaRequestDto>,
    fieldName: keyof ModificaInfoRubricaRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const handleChange = (name: string, selectedOption: any) => {
    console.log('selected options', selectedOption);
    if (selectedOption != '') {
      modificaForm.setFieldValue(name, selectedOption, false);
    } else {
      modificaForm.setFieldValue(name, undefined, false);
    }
  };

  const handleToggle = () => {
    if (type === 'password') {
      setType('text');
      setIcon(eye);
    } else {
      setType('password');
      setIcon(eyeOff);
    }
  };

  const submitConfirm = () => {
    setTimeout(() => {
      modificaForm.submitForm();
    }, 500);
  };

  useEffect(() => {
    if (id != '' && id != undefined && id != null) {
      dispatch(getNumeriTelefonoAssociabili());
      getDetails();
    }
  }, []);

  const handleCheckDisassociaTelefono = (event: any) => {
    console.log('EVENT => ', event.target.checked);
    modificaForm.setFieldValue('disassociaTelefono', event.target.checked);
    setDisassociaTelefono(event.target.checked);
  };

  const handleCheckDisassociaCredenziali = (event: any) => {
    console.log('EVENT => ', event.target.checked);
    modificaForm.setFieldValue('disassociaCredenziali', event.target.checked);
    setDisassociaCredenziali(event.target.checked);
  };

  const handleCheckDisassociaMail = (event: any) => {
    console.log('EVENT => ', event.target.checked);
    modificaForm.setFieldValue('disassociaMail', event.target.checked);
    setDisassociaMail(event.target.checked);
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_RUBRICA_ELENCO_DIPENDENTI}
        title={t('Modifica telefono, email, credenziali')!}
      />
      {dipendenteStatus === STATUS_FULLFILLED && (
        <div>
          <DipendenteDetailsComponent dipendente={dipendenteResult!} except={[]} />
          <div className="position-relative py-5">
            <div className="row">
              <Col>
                {!!dipendenteResult?.assegnazioneNumeroTelefono && (
                  <Col md={4} className="pb-5">
                    <Toggle
                      label="Disassocia numero di telefono"
                      disabled={false}
                      onClick={handleCheckDisassociaTelefono}
                    />
                  </Col>
                )}
                {!!!dipendenteResult?.assegnazioneNumeroTelefono && (
                  <Col>
                    <CustomSelect
                      label="Nuovo numero di telefono"
                      placeholder="Seleziona un numero di telefono"
                      options={numerazioniAssociabili}
                      name="modalitaSelezione"
                      invalid={!!getFieldError(modificaForm, 'idNumeroTelefono')}
                      infoText={t(getFieldError(modificaForm, 'idNumeroTelefono')) || ''}
                      value={modificaForm.values.idNumeroTelefono}
                      onChange={(e) => {
                        handleChange('idNumeroTelefono', e);
                      }}
                    />
                  </Col>
                )}
              </Col>
            </div>
            <div className="row">
              {!!dipendenteResult?.credenzialiDominio && !!dipendenteResult?.passwordDominio && (
                <Col md={5} className="pb-5">
                  <Toggle
                    label="Disassocia credenziali di dominio"
                    disabled={false}
                    onClick={handleCheckDisassociaCredenziali}
                  />
                </Col>
              )}
              {!disassociaCredenziali && (
                <Col md={12}>
                  <Input
                    label="Nuove credenziali di dominio"
                    type="text"
                    placeholder="Inserisci le credenziali di dominio"
                    id="input-credenzialiDominio"
                    wrapperClassName="form-group"
                    name="credenzialiDominio"
                    disabled={!!initialValuesTelCred.credenzialiDominio}
                    value={modificaForm.values.credenzialiDominio}
                    onChange={(e) => handleChange('credenzialiDominio', e.target.value)}
                    invalid={!!getFieldError(modificaForm, 'credenzialiDominio')}
                    infoText={t(getFieldError(modificaForm, 'credenzialiDominio')) || ''}
                  />
                  <div>
                    <Input
                      label="Nuova password di dominio"
                      type={type}
                      placeholder="Inserisci la password di dominio"
                      id="input-passwordDominio"
                      wrapperClassName="form-group"
                      name="passwordDominio"
                      disabled={!!initialValuesTelCred.passwordDominio}
                      value={modificaForm.values.passwordDominio}
                      onChange={(e) => handleChange('passwordDominio', e.target.value)}
                      invalid={!!getFieldError(modificaForm, 'passwordDominio')}
                      infoText={t(getFieldError(modificaForm, 'passwordDominio')) || ''}
                    />

                    <span id="eye-img" className="form-group" onClick={handleToggle}>
                      <Icon icon={icon} size="sm" />
                    </span>
                  </div>
                </Col>
              )}
            </div>
            <div className="row">
              {!!dipendenteResult?.email && (
                <Col md={5} className="pb-5">
                  <Toggle
                    label="Disassocia indirizzo email"
                    disabled={false}
                    onClick={handleCheckDisassociaMail}
                  />
                </Col>
              )}
            </div>
          </div>
          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2 mr-2"
              onClick={() => {
                submitConfirm();
              }}
            >
              Conferma
            </Button>
            <Button
              color="secondary"
              outline
              className="mt-2 mr-2"
              onClick={() => {
                navigate(`${HREF_RUBRICA_ELENCO_DIPENDENTI}`);
              }}
            >
              {t('annulla')}{' '}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModificaUtenze;
