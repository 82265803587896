/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConfigNotificheInsertRequestDto } from '../model';
// @ts-ignore
import { ConfigNotifichePatchRequestDto } from '../model';
// @ts-ignore
import { ConfigurazioneNotificheDto } from '../model';
// @ts-ignore
import { PagedDtoConfigurazioneNotificheDto } from '../model';
// @ts-ignore
import { PlaceholderNotificheDto } from '../model';
/**
 * GestioneConfigurazioniNotificheControllerApi - axios parameter creator
 * @export
 */
export const GestioneConfigurazioniNotificheControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * API Rest per l\'inserimento di una configurazione notifica
     * @summary Insert configurazione notifica
     * @param {ConfigNotificheInsertRequestDto} configNotificheInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertConfigurazioniNotifiche: async (
      configNotificheInsertRequestDto: ConfigNotificheInsertRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'configNotificheInsertRequestDto' is not null or undefined
      assertParamExists(
        'insertConfigurazioniNotifiche',
        'configNotificheInsertRequestDto',
        configNotificheInsertRequestDto
      );
      const localVarPath = `/v1/configurazioni/notifiche`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        configNotificheInsertRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la patch di una configurazione notifica
     * @summary Patch configurazione notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {ConfigNotifichePatchRequestDto} configNotifichePatchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchConfigurazioniNotifiche: async (
      idConfigurazioneNotifiche: string,
      configNotifichePatchRequestDto: ConfigNotifichePatchRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idConfigurazioneNotifiche' is not null or undefined
      assertParamExists(
        'patchConfigurazioniNotifiche',
        'idConfigurazioneNotifiche',
        idConfigurazioneNotifiche
      );
      // verify required parameter 'configNotifichePatchRequestDto' is not null or undefined
      assertParamExists(
        'patchConfigurazioniNotifiche',
        'configNotifichePatchRequestDto',
        configNotifichePatchRequestDto
      );
      const localVarPath = `/v1/configurazioni/notifiche/{idConfigurazioneNotifiche}`.replace(
        `{${'idConfigurazioneNotifiche'}}`,
        encodeURIComponent(String(idConfigurazioneNotifiche))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        configNotifichePatchRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero del dettaglio di una configurazione
     * @summary Ricerca configurazione notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchByIdConfigurazioniNotifiche: async (
      idConfigurazioneNotifiche: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idConfigurazioneNotifiche' is not null or undefined
      assertParamExists(
        'searchByIdConfigurazioniNotifiche',
        'idConfigurazioneNotifiche',
        idConfigurazioneNotifiche
      );
      const localVarPath = `/v1/configurazioni/notifiche/{idConfigurazioneNotifiche}`.replace(
        `{${'idConfigurazioneNotifiche'}}`,
        encodeURIComponent(String(idConfigurazioneNotifiche))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero dell\'elenco delle configurazioni notifiche
     * @summary Elenco configurazioni notifiche
     * @param {'TUTTE' | 'ATTIVE' | 'DISATTIVE'} [stato]
     * @param {string} [ruolo]
     * @param {'SO_INS_DIPARTIMENTO' | 'SO_INS_SEZIONE' | 'SO_INS_SERVIZIO' | 'SO_INOLTRA_DIPARTIMENTO' | 'SO_INOLTRA_SEZIONE' | 'SO_INOLTRA_SERVIZIO' | 'SO_ASS_CODCIFRA' | 'SO_ASS_CODIPA' | 'SO_ASS_CODBILANCIO' | 'CAND_INTEGRAZIONE_DATI' | 'CAND_VALID_DATI' | 'CAND_ACCETTAZIONE' | 'CAND_RIFIUTO' | 'CAND_RETTIFICA' | 'CAND_CESSAZIONE' | 'INCA_DIRIG_INS' | 'INCA_DIRIG_VAL_GIUNTA' | 'PO_INS' | 'DIPENDENTE_CESS_GRUPPO_PRES' | 'BANDO_PO_CONTESA_CANDIDATO' | 'CONV_ACCETTA' | 'CONV_RIFIUTO' | 'CONV_RETTIFICA' | 'CONC_CESSAZIONE' | 'CONC_VALID_DATI' | 'CONC_ACC_CONTR' | 'CONC_CONTR'} [funzionalita]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchConfigurazioniNotifiche: async (
      stato?: 'TUTTE' | 'ATTIVE' | 'DISATTIVE',
      ruolo?: string,
      funzionalita?:
        | 'SO_INS_DIPARTIMENTO'
        | 'SO_INS_SEZIONE'
        | 'SO_INS_SERVIZIO'
        | 'SO_INOLTRA_DIPARTIMENTO'
        | 'SO_INOLTRA_SEZIONE'
        | 'SO_INOLTRA_SERVIZIO'
        | 'SO_ASS_CODCIFRA'
        | 'SO_ASS_CODIPA'
        | 'SO_ASS_CODBILANCIO'
        | 'CAND_INTEGRAZIONE_DATI'
        | 'CAND_VALID_DATI'
        | 'CAND_ACCETTAZIONE'
        | 'CAND_RIFIUTO'
        | 'CAND_RETTIFICA'
        | 'CAND_CESSAZIONE'
        | 'INCA_DIRIG_INS'
        | 'INCA_DIRIG_VAL_GIUNTA'
        | 'PO_INS'
        | 'DIPENDENTE_CESS_GRUPPO_PRES'
        | 'BANDO_PO_CONTESA_CANDIDATO'
        | 'CONV_ACCETTA'
        | 'CONV_RIFIUTO'
        | 'CONV_RETTIFICA'
        | 'CONC_CESSAZIONE'
        | 'CONC_VALID_DATI'
        | 'CONC_ACC_CONTR'
        | 'CONC_CONTR',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/configurazioni/notifiche`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (stato !== undefined) {
        localVarQueryParameter['stato'] = stato;
      }

      if (ruolo !== undefined) {
        localVarQueryParameter['ruolo'] = ruolo;
      }

      if (funzionalita !== undefined) {
        localVarQueryParameter['funzionalita'] = funzionalita;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['pageNum'] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero del dettaglio dei placeholder di una configurazione
     * @summary Ricerca dei placeholder configurati su una notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPlaceholderById: async (
      idConfigurazioneNotifiche: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idConfigurazioneNotifiche' is not null or undefined
      assertParamExists(
        'searchPlaceholderById',
        'idConfigurazioneNotifiche',
        idConfigurazioneNotifiche
      );
      const localVarPath =
        `/v1/configurazioni/notifiche/{idConfigurazioneNotifiche}/placeholder`.replace(
          `{${'idConfigurazioneNotifiche'}}`,
          encodeURIComponent(String(idConfigurazioneNotifiche))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la modifica di una configurazione notifica
     * @summary Update configurazione notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {ConfigNotificheInsertRequestDto} configNotificheInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConfigurazioniNotifiche: async (
      idConfigurazioneNotifiche: string,
      configNotificheInsertRequestDto: ConfigNotificheInsertRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idConfigurazioneNotifiche' is not null or undefined
      assertParamExists(
        'updateConfigurazioniNotifiche',
        'idConfigurazioneNotifiche',
        idConfigurazioneNotifiche
      );
      // verify required parameter 'configNotificheInsertRequestDto' is not null or undefined
      assertParamExists(
        'updateConfigurazioniNotifiche',
        'configNotificheInsertRequestDto',
        configNotificheInsertRequestDto
      );
      const localVarPath = `/v1/configurazioni/notifiche/{idConfigurazioneNotifiche}`.replace(
        `{${'idConfigurazioneNotifiche'}}`,
        encodeURIComponent(String(idConfigurazioneNotifiche))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        configNotificheInsertRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GestioneConfigurazioniNotificheControllerApi - functional programming interface
 * @export
 */
export const GestioneConfigurazioniNotificheControllerApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    GestioneConfigurazioniNotificheControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per l\'inserimento di una configurazione notifica
     * @summary Insert configurazione notifica
     * @param {ConfigNotificheInsertRequestDto} configNotificheInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertConfigurazioniNotifiche(
      configNotificheInsertRequestDto: ConfigNotificheInsertRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurazioneNotificheDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertConfigurazioniNotifiche(
        configNotificheInsertRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la patch di una configurazione notifica
     * @summary Patch configurazione notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {ConfigNotifichePatchRequestDto} configNotifichePatchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchConfigurazioniNotifiche(
      idConfigurazioneNotifiche: string,
      configNotifichePatchRequestDto: ConfigNotifichePatchRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurazioneNotificheDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchConfigurazioniNotifiche(
        idConfigurazioneNotifiche,
        configNotifichePatchRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero del dettaglio di una configurazione
     * @summary Ricerca configurazione notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchByIdConfigurazioniNotifiche(
      idConfigurazioneNotifiche: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurazioneNotificheDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchByIdConfigurazioniNotifiche(
        idConfigurazioneNotifiche,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero dell\'elenco delle configurazioni notifiche
     * @summary Elenco configurazioni notifiche
     * @param {'TUTTE' | 'ATTIVE' | 'DISATTIVE'} [stato]
     * @param {string} [ruolo]
     * @param {'SO_INS_DIPARTIMENTO' | 'SO_INS_SEZIONE' | 'SO_INS_SERVIZIO' | 'SO_INOLTRA_DIPARTIMENTO' | 'SO_INOLTRA_SEZIONE' | 'SO_INOLTRA_SERVIZIO' | 'SO_ASS_CODCIFRA' | 'SO_ASS_CODIPA' | 'SO_ASS_CODBILANCIO' | 'CAND_INTEGRAZIONE_DATI' | 'CAND_VALID_DATI' | 'CAND_ACCETTAZIONE' | 'CAND_RIFIUTO' | 'CAND_RETTIFICA' | 'CAND_CESSAZIONE' | 'INCA_DIRIG_INS' | 'INCA_DIRIG_VAL_GIUNTA' | 'PO_INS' | 'DIPENDENTE_CESS_GRUPPO_PRES' | 'BANDO_PO_CONTESA_CANDIDATO' | 'CONV_ACCETTA' | 'CONV_RIFIUTO' | 'CONV_RETTIFICA' | 'CONC_CESSAZIONE' | 'CONC_VALID_DATI' | 'CONC_ACC_CONTR' | 'CONC_CONTR'} [funzionalita]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchConfigurazioniNotifiche(
      stato?: 'TUTTE' | 'ATTIVE' | 'DISATTIVE',
      ruolo?: string,
      funzionalita?:
        | 'SO_INS_DIPARTIMENTO'
        | 'SO_INS_SEZIONE'
        | 'SO_INS_SERVIZIO'
        | 'SO_INOLTRA_DIPARTIMENTO'
        | 'SO_INOLTRA_SEZIONE'
        | 'SO_INOLTRA_SERVIZIO'
        | 'SO_ASS_CODCIFRA'
        | 'SO_ASS_CODIPA'
        | 'SO_ASS_CODBILANCIO'
        | 'CAND_INTEGRAZIONE_DATI'
        | 'CAND_VALID_DATI'
        | 'CAND_ACCETTAZIONE'
        | 'CAND_RIFIUTO'
        | 'CAND_RETTIFICA'
        | 'CAND_CESSAZIONE'
        | 'INCA_DIRIG_INS'
        | 'INCA_DIRIG_VAL_GIUNTA'
        | 'PO_INS'
        | 'DIPENDENTE_CESS_GRUPPO_PRES'
        | 'BANDO_PO_CONTESA_CANDIDATO'
        | 'CONV_ACCETTA'
        | 'CONV_RIFIUTO'
        | 'CONV_RETTIFICA'
        | 'CONC_CESSAZIONE'
        | 'CONC_VALID_DATI'
        | 'CONC_ACC_CONTR'
        | 'CONC_CONTR',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoConfigurazioneNotificheDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchConfigurazioniNotifiche(
        stato,
        ruolo,
        funzionalita,
        pageNum,
        pageSize,
        sort,
        direction,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero del dettaglio dei placeholder di una configurazione
     * @summary Ricerca dei placeholder configurati su una notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPlaceholderById(
      idConfigurazioneNotifiche: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlaceholderNotificheDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPlaceholderById(
        idConfigurazioneNotifiche,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la modifica di una configurazione notifica
     * @summary Update configurazione notifica
     * @param {string} idConfigurazioneNotifiche
     * @param {ConfigNotificheInsertRequestDto} configNotificheInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateConfigurazioniNotifiche(
      idConfigurazioneNotifiche: string,
      configNotificheInsertRequestDto: ConfigNotificheInsertRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurazioneNotificheDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigurazioniNotifiche(
        idConfigurazioneNotifiche,
        configNotificheInsertRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GestioneConfigurazioniNotificheControllerApi - factory interface
 * @export
 */
export const GestioneConfigurazioniNotificheControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GestioneConfigurazioniNotificheControllerApiFp(configuration);
  return {
    /**
     * API Rest per l\'inserimento di una configurazione notifica
     * @summary Insert configurazione notifica
     * @param {GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotificheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertConfigurazioniNotifiche(
      requestParameters: GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotificheRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConfigurazioneNotificheDto> {
      return localVarFp
        .insertConfigurazioniNotifiche(requestParameters.configNotificheInsertRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la patch di una configurazione notifica
     * @summary Patch configurazione notifica
     * @param {GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchConfigurazioniNotifiche(
      requestParameters: GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConfigurazioneNotificheDto> {
      return localVarFp
        .patchConfigurazioniNotifiche(
          requestParameters.idConfigurazioneNotifiche,
          requestParameters.configNotifichePatchRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero del dettaglio di una configurazione
     * @summary Ricerca configurazione notifica
     * @param {GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchByIdConfigurazioniNotifiche(
      requestParameters: GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConfigurazioneNotificheDto> {
      return localVarFp
        .searchByIdConfigurazioniNotifiche(requestParameters.idConfigurazioneNotifiche, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero dell\'elenco delle configurazioni notifiche
     * @summary Elenco configurazioni notifiche
     * @param {GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchConfigurazioniNotifiche(
      requestParameters: GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedDtoConfigurazioneNotificheDto> {
      return localVarFp
        .searchConfigurazioniNotifiche(
          requestParameters.stato,
          requestParameters.ruolo,
          requestParameters.funzionalita,
          requestParameters.pageNum,
          requestParameters.pageSize,
          requestParameters.sort,
          requestParameters.direction,
          requestParameters.q,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero del dettaglio dei placeholder di una configurazione
     * @summary Ricerca dei placeholder configurati su una notifica
     * @param {GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPlaceholderById(
      requestParameters: GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<PlaceholderNotificheDto>> {
      return localVarFp
        .searchPlaceholderById(requestParameters.idConfigurazioneNotifiche, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la modifica di una configurazione notifica
     * @summary Update configurazione notifica
     * @param {GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConfigurazioniNotifiche(
      requestParameters: GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ConfigurazioneNotificheDto> {
      return localVarFp
        .updateConfigurazioniNotifiche(
          requestParameters.idConfigurazioneNotifiche,
          requestParameters.configNotificheInsertRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for insertConfigurazioniNotifiche operation in GestioneConfigurazioniNotificheControllerApi.
 * @export
 * @interface GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotificheRequest
 */
export interface GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotificheRequest {
  /**
   *
   * @type {ConfigNotificheInsertRequestDto}
   * @memberof GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotifiche
   */
  readonly configNotificheInsertRequestDto: ConfigNotificheInsertRequestDto;
}

/**
 * Request parameters for patchConfigurazioniNotifiche operation in GestioneConfigurazioniNotificheControllerApi.
 * @export
 * @interface GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest
 */
export interface GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotifiche
   */
  readonly idConfigurazioneNotifiche: string;

  /**
   *
   * @type {ConfigNotifichePatchRequestDto}
   * @memberof GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotifiche
   */
  readonly configNotifichePatchRequestDto: ConfigNotifichePatchRequestDto;
}

/**
 * Request parameters for searchByIdConfigurazioniNotifiche operation in GestioneConfigurazioniNotificheControllerApi.
 * @export
 * @interface GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest
 */
export interface GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotifiche
   */
  readonly idConfigurazioneNotifiche: string;
}

/**
 * Request parameters for searchConfigurazioniNotifiche operation in GestioneConfigurazioniNotificheControllerApi.
 * @export
 * @interface GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest
 */
export interface GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest {
  /**
   *
   * @type {'TUTTE' | 'ATTIVE' | 'DISATTIVE'}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly stato?: 'TUTTE' | 'ATTIVE' | 'DISATTIVE';

  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly ruolo?: string;

  /**
   *
   * @type {'SO_INS_DIPARTIMENTO' | 'SO_INS_SEZIONE' | 'SO_INS_SERVIZIO' | 'SO_INOLTRA_DIPARTIMENTO' | 'SO_INOLTRA_SEZIONE' | 'SO_INOLTRA_SERVIZIO' | 'SO_ASS_CODCIFRA' | 'SO_ASS_CODIPA' | 'SO_ASS_CODBILANCIO' | 'CAND_INTEGRAZIONE_DATI' | 'CAND_VALID_DATI' | 'CAND_ACCETTAZIONE' | 'CAND_RIFIUTO' | 'CAND_RETTIFICA' | 'CAND_CESSAZIONE' | 'INCA_DIRIG_INS' | 'INCA_DIRIG_VAL_GIUNTA' | 'PO_INS' | 'DIPENDENTE_CESS_GRUPPO_PRES' | 'BANDO_PO_CONTESA_CANDIDATO' | 'CONV_ACCETTA' | 'CONV_RIFIUTO' | 'CONV_RETTIFICA' | 'CONC_CESSAZIONE' | 'CONC_VALID_DATI' | 'CONC_ACC_CONTR' | 'CONC_CONTR'}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly funzionalita?:
    | 'SO_INS_DIPARTIMENTO'
    | 'SO_INS_SEZIONE'
    | 'SO_INS_SERVIZIO'
    | 'SO_INOLTRA_DIPARTIMENTO'
    | 'SO_INOLTRA_SEZIONE'
    | 'SO_INOLTRA_SERVIZIO'
    | 'SO_ASS_CODCIFRA'
    | 'SO_ASS_CODIPA'
    | 'SO_ASS_CODBILANCIO'
    | 'CAND_INTEGRAZIONE_DATI'
    | 'CAND_VALID_DATI'
    | 'CAND_ACCETTAZIONE'
    | 'CAND_RIFIUTO'
    | 'CAND_RETTIFICA'
    | 'CAND_CESSAZIONE'
    | 'INCA_DIRIG_INS'
    | 'INCA_DIRIG_VAL_GIUNTA'
    | 'PO_INS'
    | 'DIPENDENTE_CESS_GRUPPO_PRES'
    | 'BANDO_PO_CONTESA_CANDIDATO'
    | 'CONV_ACCETTA'
    | 'CONV_RIFIUTO'
    | 'CONV_RETTIFICA'
    | 'CONC_CESSAZIONE'
    | 'CONC_VALID_DATI'
    | 'CONC_ACC_CONTR'
    | 'CONC_CONTR';

  /**
   *
   * @type {number}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly sort?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly direction?: 'ASC' | 'DESC';

  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotifiche
   */
  readonly q?: string;
}

/**
 * Request parameters for searchPlaceholderById operation in GestioneConfigurazioniNotificheControllerApi.
 * @export
 * @interface GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest
 */
export interface GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiSearchPlaceholderById
   */
  readonly idConfigurazioneNotifiche: string;
}

/**
 * Request parameters for updateConfigurazioniNotifiche operation in GestioneConfigurazioniNotificheControllerApi.
 * @export
 * @interface GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest
 */
export interface GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotifiche
   */
  readonly idConfigurazioneNotifiche: string;

  /**
   *
   * @type {ConfigNotificheInsertRequestDto}
   * @memberof GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotifiche
   */
  readonly configNotificheInsertRequestDto: ConfigNotificheInsertRequestDto;
}

/**
 * GestioneConfigurazioniNotificheControllerApi - object-oriented interface
 * @export
 * @class GestioneConfigurazioniNotificheControllerApi
 * @extends {BaseAPI}
 */
export class GestioneConfigurazioniNotificheControllerApi extends BaseAPI {
  /**
   * API Rest per l\'inserimento di una configurazione notifica
   * @summary Insert configurazione notifica
   * @param {GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotificheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneConfigurazioniNotificheControllerApi
   */
  public insertConfigurazioniNotifiche(
    requestParameters: GestioneConfigurazioniNotificheControllerApiInsertConfigurazioniNotificheRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneConfigurazioniNotificheControllerApiFp(this.configuration)
      .insertConfigurazioniNotifiche(requestParameters.configNotificheInsertRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la patch di una configurazione notifica
   * @summary Patch configurazione notifica
   * @param {GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneConfigurazioniNotificheControllerApi
   */
  public patchConfigurazioniNotifiche(
    requestParameters: GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneConfigurazioniNotificheControllerApiFp(this.configuration)
      .patchConfigurazioniNotifiche(
        requestParameters.idConfigurazioneNotifiche,
        requestParameters.configNotifichePatchRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero del dettaglio di una configurazione
   * @summary Ricerca configurazione notifica
   * @param {GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneConfigurazioniNotificheControllerApi
   */
  public searchByIdConfigurazioniNotifiche(
    requestParameters: GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneConfigurazioniNotificheControllerApiFp(this.configuration)
      .searchByIdConfigurazioniNotifiche(requestParameters.idConfigurazioneNotifiche, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero dell\'elenco delle configurazioni notifiche
   * @summary Elenco configurazioni notifiche
   * @param {GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneConfigurazioniNotificheControllerApi
   */
  public searchConfigurazioniNotifiche(
    requestParameters: GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GestioneConfigurazioniNotificheControllerApiFp(this.configuration)
      .searchConfigurazioniNotifiche(
        requestParameters.stato,
        requestParameters.ruolo,
        requestParameters.funzionalita,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.sort,
        requestParameters.direction,
        requestParameters.q,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero del dettaglio dei placeholder di una configurazione
   * @summary Ricerca dei placeholder configurati su una notifica
   * @param {GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneConfigurazioniNotificheControllerApi
   */
  public searchPlaceholderById(
    requestParameters: GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneConfigurazioniNotificheControllerApiFp(this.configuration)
      .searchPlaceholderById(requestParameters.idConfigurazioneNotifiche, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la modifica di una configurazione notifica
   * @summary Update configurazione notifica
   * @param {GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneConfigurazioniNotificheControllerApi
   */
  public updateConfigurazioniNotifiche(
    requestParameters: GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneConfigurazioniNotificheControllerApiFp(this.configuration)
      .updateConfigurazioniNotifiche(
        requestParameters.idConfigurazioneNotifiche,
        requestParameters.configNotificheInsertRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
