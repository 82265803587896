import { Button, Icon } from 'design-react-kit';
import { useNavigate } from 'react-router-dom';

const BackButton = ({
  title = 'Annulla',
  link = '/elenco-candidati',
}: {
  title?: string;
  link?: string;
}) => {
  const navigate = useNavigate();
  const handleBackButton = () => navigate(link);
  return (
    <Button color="primary" outline onClick={handleBackButton}>
      <Icon color="primary" size="sm" className="mr-1" icon="it-arrow-left" />
      {title}
    </Button>
  );
};

export default BackButton;
