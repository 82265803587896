import ExplodedPdfViewer from 'components/exploded-pdf-viewer/ExplodedPdfViewer';
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownMenu,
  DropdownToggle,
  Icon,
  LinkList,
  LinkListItem,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useState, useEffect } from 'react';
import { v4 } from 'uuid';

interface ITabellaAllegatiProps {
  items: File[];
  deleteFile: (fileIndex: number) => void;
}

const TabellaAllegati: React.FC<ITabellaAllegatiProps> = ({ items, deleteFile }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const modalId = v4();
  const [pdfUrl, setPdfUrl] = useState<string[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);

  function viewFile(fileIndex: number) {
    setSelectedFileIndex(fileIndex);
    toggleModal();
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  useEffect(() => {
    const urls = items.map((file) => URL.createObjectURL(file));
    setPdfUrl(urls);

    return () => urls.forEach((url) => URL.revokeObjectURL(url));
  }, [items]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome file</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => (
            <tr key={`${index}-${item}`}>
              <th scope="row">{index + 1}</th>
              <td>{item.name}</td>
              <td>
                <UncontrolledDropdown direction="left">
                  <DropdownToggle nav>
                    <Icon size="sm" color="primary" icon="it-more-items" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <LinkList>
                      <LinkListItem onClick={() => viewFile(index)}>Visualizza</LinkListItem>
                      <LinkListItem onClick={() => deleteFile(index)}>Elimina</LinkListItem>
                    </LinkList>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={`modal-${modalId}`}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={`modal-${modalId}`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" icon="it-close" />
              <h2>Anteprima documento</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '70vh',
              overflowY: 'auto',
            }}
          >
            <ExplodedPdfViewer pdfFile={pdfUrl[selectedFileIndex]} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" outline className="link-underline-primary" onClick={toggleModal}>
            Annulla
          </Button>
          <Button color="primary" onClick={toggleModal}>
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TabellaAllegati;
