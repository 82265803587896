export const COGNOME = 'Cognome';
export const NOME = 'Nome';
export const SESSO = 'Sesso';
export const DATA_NASCITA = 'Data di nascita';
export const CITTA_NASCITA = 'Città di nascita';
export const CODICE_FISCALE = 'Codice fiscale';
export const CITTADINANZA = 'Cittadinanza';

export const CITTA_RESIDENZA = 'Comune';
export const PROVINCIA = 'Provincia';
export const CAP = 'CAP';
export const INDIRIZZO_RESIDENZA = 'Indirizzo di residenza';

export const STATO_CIVILE = ' Stato civile';
export const NUM_CARTA_IDENTITA = 'N° Carta di identità';
export const CELLULARE = 'Cellulare';
export const TELEFONO = 'Telefono';
export const EMAIL = 'Email';
export const PEC = 'Pec';
export const CLASSE_CONCORSO = 'Classe di concorso';
export const TITOLO_STUDIO = 'Titolo di studio';
export const NOTE_CANDIDATO = 'Note del candidato';
export const CODICE_CID = 'Codice Cid';

export const DOMICILIO_PROVINCIA = 'Provincia domicilio';
export const DOMICILIO_CAP = 'CAP domicilio';
export const DOMICILIO_INDIRIZZO = 'Indirizzo domicilio';
export const DOMICILIO_CITTA = 'Comune domicilio';

export const DOMICILIO_DIFFERENTE = 'Domicilio differente';
export const MILITARE = 'Militare';
export const DISABILE = 'Disabile';
export const PERCENTUALE_DISABILITA = 'Percentuale disabilità';
export const NULLA_OSTA_UC = 'Nulla Osta UC mirato';
