/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DipendenteRegionaleEssenzialeDto } from '../model';
// @ts-ignore
import { InsertRichiestaAbilitazioneDto } from '../model';
// @ts-ignore
import { PagedDtoRichiestaAbilitazioneDto } from '../model';
// @ts-ignore
import { PatchAssegnazioneFunzionalitaApplicativeRequest } from '../model';
// @ts-ignore
import { RichiestaAbilitazioneDto } from '../model';
// @ts-ignore
import { ValutazioneRichiestaAbilitazioneDto } from '../model';
/**
 * AssegnazioneFunzionalitaApplicativeControllerApi - axios parameter creator
 * @export
 */
export const AssegnazioneFunzionalitaApplicativeControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * API Rest per l\'eliminazione di una richiesta di abilitazione
     * @summary Eliminazione di una richiesta di abilitazione
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAssegnazioneFunzionalitaApplicative: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAssegnazioneFunzionalitaApplicative', 'id', id);
      const localVarPath = `/v1/richieste-funzionalita-applicativi/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il dettaglio di una richiesta di abilitazione
     * @summary Dettaglio di una richiesta di abilitazione
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailAssegnazioneFunzionalitaApplicative: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('detailAssegnazioneFunzionalitaApplicative', 'id', id);
      const localVarPath = `/v1/richieste-funzionalita-applicativi/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'inserimento di una richiesta di abilitazione
     * @summary Inserimento richiesta di abilitazione
     * @param {InsertRichiestaAbilitazioneDto} insertRichiestaAbilitazioneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertAssegnazioneFunzionalitaApplicative: async (
      insertRichiestaAbilitazioneDto: InsertRichiestaAbilitazioneDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insertRichiestaAbilitazioneDto' is not null or undefined
      assertParamExists(
        'insertAssegnazioneFunzionalitaApplicative',
        'insertRichiestaAbilitazioneDto',
        insertRichiestaAbilitazioneDto
      );
      const localVarPath = `/v1/richieste-funzionalita-applicativi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        insertRichiestaAbilitazioneDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la modifica su una richiesta di abilitazione
     * @summary Patch su richiesta di abilitazione
     * @param {number} id
     * @param {PatchAssegnazioneFunzionalitaApplicativeRequest} patchAssegnazioneFunzionalitaApplicativeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAssegnazioneFunzionalitaApplicative: async (
      id: number,
      patchAssegnazioneFunzionalitaApplicativeRequest: PatchAssegnazioneFunzionalitaApplicativeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchAssegnazioneFunzionalitaApplicative', 'id', id);
      // verify required parameter 'patchAssegnazioneFunzionalitaApplicativeRequest' is not null or undefined
      assertParamExists(
        'patchAssegnazioneFunzionalitaApplicative',
        'patchAssegnazioneFunzionalitaApplicativeRequest',
        patchAssegnazioneFunzionalitaApplicativeRequest
      );
      const localVarPath = `/v1/richieste-funzionalita-applicativi/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchAssegnazioneFunzionalitaApplicativeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la valutazione di una richiesta di abilitazione
     * @summary Patch per la valutazione di una richiesta di abilitazione
     * @param {number} id
     * @param {ValutazioneRichiestaAbilitazioneDto} valutazioneRichiestaAbilitazioneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchValutazioneAssegnazioneFunzionalitaApplicative: async (
      id: number,
      valutazioneRichiestaAbilitazioneDto: ValutazioneRichiestaAbilitazioneDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchValutazioneAssegnazioneFunzionalitaApplicative', 'id', id);
      // verify required parameter 'valutazioneRichiestaAbilitazioneDto' is not null or undefined
      assertParamExists(
        'patchValutazioneAssegnazioneFunzionalitaApplicative',
        'valutazioneRichiestaAbilitazioneDto',
        valutazioneRichiestaAbilitazioneDto
      );
      const localVarPath = `/v1/richieste-funzionalita-applicativi/{id}/valutazione`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        valutazioneRichiestaAbilitazioneDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca delle richieste di abilitazione
     * @summary Ricerca richieste di abilitazione
     * @param {string} [codiceFiscale]
     * @param {number} [codiceCid]
     * @param {string} [nome]
     * @param {string} [cognome]
     * @param {number} [applicativo]
     * @param {'BOZZA' | 'ATTESA_APPROVAZIONE_ATTIVAZIONE' | 'ATTESA_APPROVAZIONE_MODIFICA' | 'ATTESA_APPROVAZIONE_SOSPENSIONE' | 'ATTESA_APPROVAZIONE_REVOCA' | 'ATTESA_APPROVAZIONE_RIATTIVAZIONE' | 'ATTIVA' | 'ANNULLATA' | 'REVOCATA' | 'SOSPESA'} [stato]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAssegnazioneFunzionalitaApplicative: async (
      codiceFiscale?: string,
      codiceCid?: number,
      nome?: string,
      cognome?: string,
      applicativo?: number,
      stato?:
        | 'BOZZA'
        | 'ATTESA_APPROVAZIONE_ATTIVAZIONE'
        | 'ATTESA_APPROVAZIONE_MODIFICA'
        | 'ATTESA_APPROVAZIONE_SOSPENSIONE'
        | 'ATTESA_APPROVAZIONE_REVOCA'
        | 'ATTESA_APPROVAZIONE_RIATTIVAZIONE'
        | 'ATTIVA'
        | 'ANNULLATA'
        | 'REVOCATA'
        | 'SOSPESA',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/richieste-funzionalita-applicativi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (codiceFiscale !== undefined) {
        localVarQueryParameter['codiceFiscale'] = codiceFiscale;
      }

      if (codiceCid !== undefined) {
        localVarQueryParameter['codiceCid'] = codiceCid;
      }

      if (nome !== undefined) {
        localVarQueryParameter['nome'] = nome;
      }

      if (cognome !== undefined) {
        localVarQueryParameter['cognome'] = cognome;
      }

      if (applicativo !== undefined) {
        localVarQueryParameter['applicativo'] = applicativo;
      }

      if (stato !== undefined) {
        localVarQueryParameter['stato'] = stato;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['pageNum'] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca dei dipendenti per una richiesta di abilitazione
     * @summary Ricerca dipendenti per richiesta di abilitazione
     * @param {string} [chiave]
     * @param {boolean} [consulentiEsterni]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDipendentiAssegnazioneFunzionalitaApplicative: async (
      chiave?: string,
      consulentiEsterni?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/richieste-funzionalita-applicativi/dipendenti`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (chiave !== undefined) {
        localVarQueryParameter['chiave'] = chiave;
      }

      if (consulentiEsterni !== undefined) {
        localVarQueryParameter['consulentiEsterni'] = consulentiEsterni;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssegnazioneFunzionalitaApplicativeControllerApi - functional programming interface
 * @export
 */
export const AssegnazioneFunzionalitaApplicativeControllerApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AssegnazioneFunzionalitaApplicativeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per l\'eliminazione di una richiesta di abilitazione
     * @summary Eliminazione di una richiesta di abilitazione
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAssegnazioneFunzionalitaApplicative(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAssegnazioneFunzionalitaApplicative(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il dettaglio di una richiesta di abilitazione
     * @summary Dettaglio di una richiesta di abilitazione
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detailAssegnazioneFunzionalitaApplicative(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichiestaAbilitazioneDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.detailAssegnazioneFunzionalitaApplicative(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'inserimento di una richiesta di abilitazione
     * @summary Inserimento richiesta di abilitazione
     * @param {InsertRichiestaAbilitazioneDto} insertRichiestaAbilitazioneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertAssegnazioneFunzionalitaApplicative(
      insertRichiestaAbilitazioneDto: InsertRichiestaAbilitazioneDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichiestaAbilitazioneDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.insertAssegnazioneFunzionalitaApplicative(
          insertRichiestaAbilitazioneDto,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la modifica su una richiesta di abilitazione
     * @summary Patch su richiesta di abilitazione
     * @param {number} id
     * @param {PatchAssegnazioneFunzionalitaApplicativeRequest} patchAssegnazioneFunzionalitaApplicativeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchAssegnazioneFunzionalitaApplicative(
      id: number,
      patchAssegnazioneFunzionalitaApplicativeRequest: PatchAssegnazioneFunzionalitaApplicativeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichiestaAbilitazioneDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchAssegnazioneFunzionalitaApplicative(
          id,
          patchAssegnazioneFunzionalitaApplicativeRequest,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la valutazione di una richiesta di abilitazione
     * @summary Patch per la valutazione di una richiesta di abilitazione
     * @param {number} id
     * @param {ValutazioneRichiestaAbilitazioneDto} valutazioneRichiestaAbilitazioneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchValutazioneAssegnazioneFunzionalitaApplicative(
      id: number,
      valutazioneRichiestaAbilitazioneDto: ValutazioneRichiestaAbilitazioneDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichiestaAbilitazioneDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchValutazioneAssegnazioneFunzionalitaApplicative(
          id,
          valutazioneRichiestaAbilitazioneDto,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca delle richieste di abilitazione
     * @summary Ricerca richieste di abilitazione
     * @param {string} [codiceFiscale]
     * @param {number} [codiceCid]
     * @param {string} [nome]
     * @param {string} [cognome]
     * @param {number} [applicativo]
     * @param {'BOZZA' | 'ATTESA_APPROVAZIONE_ATTIVAZIONE' | 'ATTESA_APPROVAZIONE_MODIFICA' | 'ATTESA_APPROVAZIONE_SOSPENSIONE' | 'ATTESA_APPROVAZIONE_REVOCA' | 'ATTESA_APPROVAZIONE_RIATTIVAZIONE' | 'ATTIVA' | 'ANNULLATA' | 'REVOCATA' | 'SOSPESA'} [stato]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAssegnazioneFunzionalitaApplicative(
      codiceFiscale?: string,
      codiceCid?: number,
      nome?: string,
      cognome?: string,
      applicativo?: number,
      stato?:
        | 'BOZZA'
        | 'ATTESA_APPROVAZIONE_ATTIVAZIONE'
        | 'ATTESA_APPROVAZIONE_MODIFICA'
        | 'ATTESA_APPROVAZIONE_SOSPENSIONE'
        | 'ATTESA_APPROVAZIONE_REVOCA'
        | 'ATTESA_APPROVAZIONE_RIATTIVAZIONE'
        | 'ATTIVA'
        | 'ANNULLATA'
        | 'REVOCATA'
        | 'SOSPESA',
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoRichiestaAbilitazioneDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchAssegnazioneFunzionalitaApplicative(
          codiceFiscale,
          codiceCid,
          nome,
          cognome,
          applicativo,
          stato,
          pageNum,
          pageSize,
          sort,
          direction,
          q,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca dei dipendenti per una richiesta di abilitazione
     * @summary Ricerca dipendenti per richiesta di abilitazione
     * @param {string} [chiave]
     * @param {boolean} [consulentiEsterni]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchDipendentiAssegnazioneFunzionalitaApplicative(
      chiave?: string,
      consulentiEsterni?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DipendenteRegionaleEssenzialeDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchDipendentiAssegnazioneFunzionalitaApplicative(
          chiave,
          consulentiEsterni,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AssegnazioneFunzionalitaApplicativeControllerApi - factory interface
 * @export
 */
export const AssegnazioneFunzionalitaApplicativeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AssegnazioneFunzionalitaApplicativeControllerApiFp(configuration);
  return {
    /**
     * API Rest per l\'eliminazione di una richiesta di abilitazione
     * @summary Eliminazione di una richiesta di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicativeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAssegnazioneFunzionalitaApplicative(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il dettaglio di una richiesta di abilitazione
     * @summary Dettaglio di una richiesta di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicativeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<RichiestaAbilitazioneDto> {
      return localVarFp
        .detailAssegnazioneFunzionalitaApplicative(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'inserimento di una richiesta di abilitazione
     * @summary Inserimento richiesta di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicativeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<RichiestaAbilitazioneDto> {
      return localVarFp
        .insertAssegnazioneFunzionalitaApplicative(
          requestParameters.insertRichiestaAbilitazioneDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la modifica su una richiesta di abilitazione
     * @summary Patch su richiesta di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<RichiestaAbilitazioneDto> {
      return localVarFp
        .patchAssegnazioneFunzionalitaApplicative(
          requestParameters.id,
          requestParameters.patchAssegnazioneFunzionalitaApplicativeRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la valutazione di una richiesta di abilitazione
     * @summary Patch per la valutazione di una richiesta di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchValutazioneAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<RichiestaAbilitazioneDto> {
      return localVarFp
        .patchValutazioneAssegnazioneFunzionalitaApplicative(
          requestParameters.id,
          requestParameters.valutazioneRichiestaAbilitazioneDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca delle richieste di abilitazione
     * @summary Ricerca richieste di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedDtoRichiestaAbilitazioneDto> {
      return localVarFp
        .searchAssegnazioneFunzionalitaApplicative(
          requestParameters.codiceFiscale,
          requestParameters.codiceCid,
          requestParameters.nome,
          requestParameters.cognome,
          requestParameters.applicativo,
          requestParameters.stato,
          requestParameters.pageNum,
          requestParameters.pageSize,
          requestParameters.sort,
          requestParameters.direction,
          requestParameters.q,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca dei dipendenti per una richiesta di abilitazione
     * @summary Ricerca dipendenti per richiesta di abilitazione
     * @param {AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDipendentiAssegnazioneFunzionalitaApplicative(
      requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicativeRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<DipendenteRegionaleEssenzialeDto>> {
      return localVarFp
        .searchDipendentiAssegnazioneFunzionalitaApplicative(
          requestParameters.chiave,
          requestParameters.consulentiEsterni,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicative
   */
  readonly id: number;
}

/**
 * Request parameters for detailAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicative
   */
  readonly id: number;
}

/**
 * Request parameters for insertAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {InsertRichiestaAbilitazioneDto}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicative
   */
  readonly insertRichiestaAbilitazioneDto: InsertRichiestaAbilitazioneDto;
}

/**
 * Request parameters for patchAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicative
   */
  readonly id: number;

  /**
   *
   * @type {PatchAssegnazioneFunzionalitaApplicativeRequest}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicative
   */
  readonly patchAssegnazioneFunzionalitaApplicativeRequest: PatchAssegnazioneFunzionalitaApplicativeRequest;
}

/**
 * Request parameters for patchValutazioneAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicative
   */
  readonly id: number;

  /**
   *
   * @type {ValutazioneRichiestaAbilitazioneDto}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicative
   */
  readonly valutazioneRichiestaAbilitazioneDto: ValutazioneRichiestaAbilitazioneDto;
}

/**
 * Request parameters for searchAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {string}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly codiceFiscale?: string;

  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly codiceCid?: number;

  /**
   *
   * @type {string}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly nome?: string;

  /**
   *
   * @type {string}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly cognome?: string;

  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly applicativo?: number;

  /**
   *
   * @type {'BOZZA' | 'ATTESA_APPROVAZIONE_ATTIVAZIONE' | 'ATTESA_APPROVAZIONE_MODIFICA' | 'ATTESA_APPROVAZIONE_SOSPENSIONE' | 'ATTESA_APPROVAZIONE_REVOCA' | 'ATTESA_APPROVAZIONE_RIATTIVAZIONE' | 'ATTIVA' | 'ANNULLATA' | 'REVOCATA' | 'SOSPESA'}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly stato?:
    | 'BOZZA'
    | 'ATTESA_APPROVAZIONE_ATTIVAZIONE'
    | 'ATTESA_APPROVAZIONE_MODIFICA'
    | 'ATTESA_APPROVAZIONE_SOSPENSIONE'
    | 'ATTESA_APPROVAZIONE_REVOCA'
    | 'ATTESA_APPROVAZIONE_RIATTIVAZIONE'
    | 'ATTIVA'
    | 'ANNULLATA'
    | 'REVOCATA'
    | 'SOSPESA';

  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly sort?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly direction?: 'ASC' | 'DESC';

  /**
   *
   * @type {string}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicative
   */
  readonly q?: string;
}

/**
 * Request parameters for searchDipendentiAssegnazioneFunzionalitaApplicative operation in AssegnazioneFunzionalitaApplicativeControllerApi.
 * @export
 * @interface AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicativeRequest
 */
export interface AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicativeRequest {
  /**
   *
   * @type {string}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicative
   */
  readonly chiave?: string;

  /**
   *
   * @type {boolean}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicative
   */
  readonly consulentiEsterni?: boolean;
}

/**
 * AssegnazioneFunzionalitaApplicativeControllerApi - object-oriented interface
 * @export
 * @class AssegnazioneFunzionalitaApplicativeControllerApi
 * @extends {BaseAPI}
 */
export class AssegnazioneFunzionalitaApplicativeControllerApi extends BaseAPI {
  /**
   * API Rest per l\'eliminazione di una richiesta di abilitazione
   * @summary Eliminazione di una richiesta di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public deleteAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiDeleteAssegnazioneFunzionalitaApplicativeRequest,
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .deleteAssegnazioneFunzionalitaApplicative(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il dettaglio di una richiesta di abilitazione
   * @summary Dettaglio di una richiesta di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public detailAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiDetailAssegnazioneFunzionalitaApplicativeRequest,
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .detailAssegnazioneFunzionalitaApplicative(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'inserimento di una richiesta di abilitazione
   * @summary Inserimento richiesta di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public insertAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiInsertAssegnazioneFunzionalitaApplicativeRequest,
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .insertAssegnazioneFunzionalitaApplicative(
        requestParameters.insertRichiestaAbilitazioneDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la modifica su una richiesta di abilitazione
   * @summary Patch su richiesta di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public patchAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest,
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .patchAssegnazioneFunzionalitaApplicative(
        requestParameters.id,
        requestParameters.patchAssegnazioneFunzionalitaApplicativeRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la valutazione di una richiesta di abilitazione
   * @summary Patch per la valutazione di una richiesta di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public patchValutazioneAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest,
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .patchValutazioneAssegnazioneFunzionalitaApplicative(
        requestParameters.id,
        requestParameters.valutazioneRichiestaAbilitazioneDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca delle richieste di abilitazione
   * @summary Ricerca richieste di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public searchAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .searchAssegnazioneFunzionalitaApplicative(
        requestParameters.codiceFiscale,
        requestParameters.codiceCid,
        requestParameters.nome,
        requestParameters.cognome,
        requestParameters.applicativo,
        requestParameters.stato,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.sort,
        requestParameters.direction,
        requestParameters.q,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca dei dipendenti per una richiesta di abilitazione
   * @summary Ricerca dipendenti per richiesta di abilitazione
   * @param {AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssegnazioneFunzionalitaApplicativeControllerApi
   */
  public searchDipendentiAssegnazioneFunzionalitaApplicative(
    requestParameters: AssegnazioneFunzionalitaApplicativeControllerApiSearchDipendentiAssegnazioneFunzionalitaApplicativeRequest = {},
    options?: AxiosRequestConfig
  ) {
    return AssegnazioneFunzionalitaApplicativeControllerApiFp(this.configuration)
      .searchDipendentiAssegnazioneFunzionalitaApplicative(
        requestParameters.chiave,
        requestParameters.consulentiEsterni,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
