/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { AssegnazioneBandoPoRequestDto } from './assegnazione-bando-po-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { DatiAttoDto } from './dati-atto-dto';

/**
 *
 * @export
 * @interface CreaAggiornaBandoPoRequestDto
 */
export interface CreaAggiornaBandoPoRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  dataApertura?: string;
  /**
   *
   * @type {string}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  dataChiusura?: string;
  /**
   *
   * @type {string}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  codiceCifraDeliberaDetermina?: string;
  /**
   *
   * @type {string}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  fase?: CreaAggiornaBandoPoRequestDtoFaseEnum;
  /**
   *
   * @type {string}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  idSo?: string;
  /**
   *
   * @type {Array<AssegnazioneBandoPoRequestDto>}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  elencoPo?: Array<AssegnazioneBandoPoRequestDto>;
  /**
   *
   * @type {string}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  requestType?: CreaAggiornaBandoPoRequestDtoRequestTypeEnum;
  /**
   *
   * @type {DatiAttoDto}
   * @memberof CreaAggiornaBandoPoRequestDto
   */
  datiAtto?: DatiAttoDto;
}

export const CreaAggiornaBandoPoRequestDtoFaseEnum = {
  PrimaApplicazione: 'PRIMA_APPLICAZIONE',
  Regime: 'REGIME',
} as const;

export type CreaAggiornaBandoPoRequestDtoFaseEnum =
  (typeof CreaAggiornaBandoPoRequestDtoFaseEnum)[keyof typeof CreaAggiornaBandoPoRequestDtoFaseEnum];
export const CreaAggiornaBandoPoRequestDtoRequestTypeEnum = {
  Salva: 'SALVA',
  Conferma: 'CONFERMA',
} as const;

export type CreaAggiornaBandoPoRequestDtoRequestTypeEnum =
  (typeof CreaAggiornaBandoPoRequestDtoRequestTypeEnum)[keyof typeof CreaAggiornaBandoPoRequestDtoRequestTypeEnum];
