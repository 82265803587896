import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AssegnazioneFunzionalitaApplicativeControllerApi,
  AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest,
  AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest,
  AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest,
  FormsControllerApi,
  FunzionalitaApplicativeForm,
  InsertRichiestaAbilitazioneDto,
  PagedDtoRichiestaAbilitazioneDto,
  RichiestaAbilitazioneDto,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';

export interface FunzionalitaApplicativeState {
  searchResult: PagedDtoRichiestaAbilitazioneDto | null;
  searchStatus: string;
  searchInput: AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest | null;
  formsResult: FunzionalitaApplicativeForm | null;
  formsStatus: string;
  inserisciRichiestaInput: InsertRichiestaAbilitazioneDto | null;
  inserisciRichiestaStatus: string;
  elencoFunzionalitaResult: AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest | null;
  elencoFunzionalitaStatus: string;
  dettaglioRichiestaResult: RichiestaAbilitazioneDto | null;
  dettaglioRichiestaStatus: string;
  valutazioneRichiestaAttivazioneResult: RichiestaAbilitazioneDto | null;
  valutazioneRichiestaAttivazioneStatus: string;
  modificaRichiestaResult: RichiestaAbilitazioneDto | null;
  modificaRichiestaStatus: string;
  eliminaRichiestaAttivazioneStatus: string;
  sospendiUtenzaAbilitazioneStatus: string;
  riattivaUtenzaAbilitazioneStatus: string;
  revocaUtenzaAbilitazioneStatus: string;
}

const initialState: FunzionalitaApplicativeState = {
  searchResult: null,
  searchStatus: '',
  searchInput: null,
  formsResult: null,
  formsStatus: '',
  inserisciRichiestaInput: null,
  inserisciRichiestaStatus: '',
  elencoFunzionalitaResult: null,
  elencoFunzionalitaStatus: '',
  dettaglioRichiestaResult: null,
  dettaglioRichiestaStatus: '',
  valutazioneRichiestaAttivazioneResult: null,
  valutazioneRichiestaAttivazioneStatus: '',
  modificaRichiestaResult: null,
  modificaRichiestaStatus: '',
  eliminaRichiestaAttivazioneStatus: '',
  sospendiUtenzaAbilitazioneStatus: '',
  riattivaUtenzaAbilitazioneStatus: '',
  revocaUtenzaAbilitazioneStatus: '',
};

export const loadFormsFunzionalitaApplicative = createAsyncThunk(
  'funzionalitaApplicative/loadForms',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getFunzionalitaApplicativeForm();
    return response.data;
  }
);

export const getElencoFunzionalita = createAsyncThunk(
  'funzionalitaApplicative/elencoFunzionalita',
  async (args: number) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getFunzionalitaApplicativeForm({
      chiave: args,
    });
    return response.data;
  }
);

export const searchElencoRichieste = createAsyncThunk(
  'funzionalitaApplicative/searchRichieste',
  async (
    args: AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest
  ) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.searchAssegnazioneFunzionalitaApplicative({
      ...args,
      codiceCid: args.codiceCid === 0 ? undefined : args.codiceCid,
      applicativo: args.applicativo === 0 ? undefined : args.applicativo,
    });
    return response.data;
  }
);

export const inserisciRichiestaAbilitazione = createAsyncThunk(
  'funzionalitaApplicative/insertRichiesta',
  async (args: InsertRichiestaAbilitazioneDto) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.insertAssegnazioneFunzionalitaApplicative({
      insertRichiestaAbilitazioneDto: args,
    });
    return response.data;
  }
);

export const modificaRichiestaAbilitazione = createAsyncThunk(
  'funzionalitaApplicative/modificaRichiesta',
  async (
    args: AssegnazioneFunzionalitaApplicativeControllerApiPatchAssegnazioneFunzionalitaApplicativeRequest
  ) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.patchAssegnazioneFunzionalitaApplicative({
      id: Number(args.id),
      patchAssegnazioneFunzionalitaApplicativeRequest:
        args.patchAssegnazioneFunzionalitaApplicativeRequest,
    });
    return response.data;
  }
);

export const valutaRichiestaAbilitazioneAttivazione = createAsyncThunk(
  'funzionalitaApplicative/valutaAttivazione',
  async (
    args: AssegnazioneFunzionalitaApplicativeControllerApiPatchValutazioneAssegnazioneFunzionalitaApplicativeRequest
  ) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.patchValutazioneAssegnazioneFunzionalitaApplicative({
      id: args.id,
      valutazioneRichiestaAbilitazioneDto: args.valutazioneRichiestaAbilitazioneDto,
    });
    return response.data;
  }
);

export const getDettaglioRichiesta = createAsyncThunk(
  'funzionalitaApplicative/getDettaglio',
  async (args: number) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.detailAssegnazioneFunzionalitaApplicative({
      id: args,
    });
    return response.data;
  }
);

export const deleteRichiestaAbilitazione = createAsyncThunk(
  'funzionalitaApplicative/deleteRichiesta',
  async (args: number) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.deleteAssegnazioneFunzionalitaApplicative({
      id: args,
    });
    return response.status;
  }
);

export const sospendiUtenzaAbilitazione = createAsyncThunk(
  'funzionalitaApplicative/sospendiUtenza',
  async (args: number) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.patchAssegnazioneFunzionalitaApplicative({
      id: args,
      patchAssegnazioneFunzionalitaApplicativeRequest: {
        tipoGestione: 'GestioneUtenza',
        azioneRichiesta: 'SOSPENDI',
        requestType: 'CONFERMA',
      },
    });
    return response.status;
  }
);

export const riattivaUtenzaAbilitazione = createAsyncThunk(
  'funzionalitaApplicative/riattivaUtenza',
  async (args: number) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.patchAssegnazioneFunzionalitaApplicative({
      id: args,
      patchAssegnazioneFunzionalitaApplicativeRequest: {
        tipoGestione: 'GestioneUtenza',
        azioneRichiesta: 'RIATTIVA',
        requestType: 'CONFERMA',
      },
    });
    return response.status;
  }
);

export const revocaUtenzaAbilitazione = createAsyncThunk(
  'funzionalitaApplicative/revocaUtenza',
  async (args: number) => {
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.patchAssegnazioneFunzionalitaApplicative({
      id: args,
      patchAssegnazioneFunzionalitaApplicativeRequest: {
        tipoGestione: 'GestioneUtenza',
        azioneRichiesta: 'REVOCA',
        requestType: 'CONFERMA',
      },
    });
    return response.status;
  }
);

export const FunzionalitaApplicativeSlice = createSlice({
  name: 'funzionalitaApplicative',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchElencoRichieste.pending, (state: any, action: any) => {
      state.searchStatus = STATUS_PENDING;
    });
    builder.addCase(
      searchElencoRichieste.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.searchResult = action.payload;
        state.searchStatus = STATUS_FULLFILLED;
        state.searchInput = action.meta.arg;
      }
    );
    builder.addCase(searchElencoRichieste.rejected, (state: any, action: any) => {
      state.searchStatus = STATUS_REJECTED;
    });

    builder.addCase(
      loadFormsFunzionalitaApplicative.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.formsStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      loadFormsFunzionalitaApplicative.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.formsResult = action.payload;
        state.formsStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      loadFormsFunzionalitaApplicative.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.formsStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      getElencoFunzionalita.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.formsStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      getElencoFunzionalita.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.formsResult = action.payload;
        state.formsStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      getElencoFunzionalita.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.formsStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      inserisciRichiestaAbilitazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.inserisciRichiestaStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      inserisciRichiestaAbilitazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.inserisciRichiestaInput = action.payload;
        state.inserisciRichiestaStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      inserisciRichiestaAbilitazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.inserisciRichiestaStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      modificaRichiestaAbilitazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.modificaRichiestaStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      modificaRichiestaAbilitazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.modificaRichiestaResult = action.payload;
        state.modificaRichiestaStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      modificaRichiestaAbilitazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.modificaRichiestaStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      getDettaglioRichiesta.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.dettaglioRichiestaStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      getDettaglioRichiesta.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.dettaglioRichiestaResult = action.payload;
        state.dettaglioRichiestaStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      getDettaglioRichiesta.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.dettaglioRichiestaStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      valutaRichiestaAbilitazioneAttivazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.valutazioneRichiestaAttivazioneStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      valutaRichiestaAbilitazioneAttivazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.valutazioneRichiestaAttivazioneResult = action.payload;
        state.valutazioneRichiestaAttivazioneStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      valutaRichiestaAbilitazioneAttivazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.valutazioneRichiestaAttivazioneStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      deleteRichiestaAbilitazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.eliminaRichiestaAttivazioneStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      deleteRichiestaAbilitazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.eliminaRichiestaAttivazioneStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      deleteRichiestaAbilitazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.eliminaRichiestaAttivazioneStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      sospendiUtenzaAbilitazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.sospendiUtenzaAbilitazioneStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      sospendiUtenzaAbilitazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.sospendiUtenzaAbilitazioneStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      sospendiUtenzaAbilitazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.sospendiUtenzaAbilitazioneStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      riattivaUtenzaAbilitazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.riattivaUtenzaAbilitazioneStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      riattivaUtenzaAbilitazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.riattivaUtenzaAbilitazioneStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      riattivaUtenzaAbilitazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.riattivaUtenzaAbilitazioneStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      revocaUtenzaAbilitazione.pending,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.revocaUtenzaAbilitazioneStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      revocaUtenzaAbilitazione.fulfilled,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.revocaUtenzaAbilitazioneStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      revocaUtenzaAbilitazione.rejected,
      (state: FunzionalitaApplicativeState, action: any) => {
        state.revocaUtenzaAbilitazioneStatus = STATUS_REJECTED;
      }
    );
  },
});

// export const {} = FunzionalitaApplicativeSlice.actions;

export default FunzionalitaApplicativeSlice.reducer;
