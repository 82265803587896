import PageHeader from '../../components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from '../../components/sidemenu/sidemenuConstants';
import FormDatiAnagrafici from '../../components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from '../../components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from '../../components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from '../../components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormRecapiti from '../../components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from '../../components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from '../../components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from '../../components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import { CreaCandidatoForm } from 'components/crea-candidato-form/CreaCandidatoForm';

const CreaCandidato = () => {
  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={'Nuovo candidato'} />
      <CreaCandidatoForm>
        <FormDatiAnagrafici />
        <FormEstremiIdentificazione />
        <FormRecapiti />
        <FormDatiResidenza />
        <FormDomicilio />
        <FormDatiDisabilita />
        <FormDatiConcorso />
        <FormTitoloDiStudio />
      </CreaCandidatoForm>
    </div>
  );
};

export default CreaCandidato;
