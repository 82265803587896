import { Button } from 'design-react-kit';
import { useFormik } from 'formik';
import { useState, type ReactNode } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import validationSchema from 'components/forms/validationSchema';
import useUploadFiles from 'hooks/services/useUploadFiles';
import {
  type IFormCreaCandidatoForm,
  initialValues,
  OnboardingConcorsoStatoOnb,
} from 'components/crea-candidato-form/CreaCandidatoForm';
import { FormContext } from 'hooks/useCompositeForm';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import { useNavigate, useParams } from 'react-router-dom';
import useRegistraDocumenti from 'hooks/services/useRegistraDocumenti';
import useAsyncInitialValues from 'hooks/useAsyncInitialValues';
import BackButton from 'components/back-button/BackButton';
import { OnboardingConcorsoStato } from 'components/crea-candidato-form/types';
import useManageFiles, { documentTypeMap } from 'hooks/services/useManageFiles';

export const ModificaCandidatoForm = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  const uploadFiles = useUploadFiles();
  const registraDocumenti = useRegistraDocumenti();
  const navigate = useNavigate();
  const [enableValidation, setEnableValidation] = useState(true);
  const { syncFiles } = useManageFiles();

  const formik = useFormik({
    initialValues,
    validationSchema: enableValidation ? toFormikValidationSchema(validationSchema) : undefined,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  const isAsyncValuesLoading = useAsyncInitialValues(formik, candidato, onboarding);

  async function handleSubmit({
    // Dati anagrafici
    codiceFiscale,
    cognome,
    nome,
    sesso,
    statoCivile,
    cittadinanza,
    dataNascita,
    nazioneNascita,
    provNascita,
    cittaNascita,
    // Estremi identificazione
    tipoDocumento,
    numeroCartaIdentita,
    enteEmittente,
    dataRilascio,
    dataScadenza,
    filesIdentificazione,
    // Recapiti
    indirizzoPec,
    email,
    telefonoAbitazione,
    telefonoCellulare,
    // Dati residenza
    statoResidenza,
    provinciaResidenza,
    cittaResidenza,
    indirizzoResidenza,
    capResidenza,
    // Dati domicilio
    domicilioDifferente,
    statoDomicilio,
    provinciaDomicilio,
    cittaDomicilio,
    indirizzoDomicilio,
    capDomicilio,
    // Disabilità
    disabile,
    percentualeDisabilita,
    collocamentoMirato,
    fileCollocamento,
    documentiDisabilita,
    // Titolo di studio
    titoloStudio,
    titoloDichiarato,
    noteTitolo,
    // Concorsi
    livelli,
    idConcorso,
    areaProfessionale,
    vincitore,
    idoneo,
    posizioneGraduatoria,
    attiOnboarding,
  }: IFormCreaCandidatoForm) {
    /*     console.log('SUBMIT FORM CANDIDATO EDIT');
    console.log('filesIdentificazione', filesIdentificazione);
    console.log(
      'existing files',
      onboarding?.elencoDocumenti.filter(
        (doc) => doc.tipo === 'CARTA_IDENTITA' || doc.tipo === 'PASSAPORTO'
      ) ?? []
    ); */

    const documentiIdentificazione = await syncFiles({
      newFiles: filesIdentificazione,
      existingFiles:
        onboarding?.elencoDocumenti.filter(
          (doc) => doc.tipo === 'CARTA_IDENTITA' || doc.tipo === 'PASSAPORTO'
        ) ?? [],
      tipoDocumento:
        tipoDocumento === 'CARTA_IDENTITA' ? '15' : tipoDocumento === 'PASSAPORTO' ? '17' : '18',
      nomeFiles: 'Identificazione',
      idOnboarding: onboarding?.id,
    });

    const documentiDetermina = await syncFiles({
      newFiles: attiOnboarding.filter((doc) => doc.tipoAtto === 'DET').map(({ file }) => file),
      existingFiles:
        onboarding?.elencoDocumenti.filter((doc) => doc.tipo === 'ATTI_DETERMINA') ?? [],
      tipoDocumento: '28',
      nomeFiles: 'Atti Determina',
      idOnboarding: onboarding?.id,
    });

    const documentiDelibera = await syncFiles({
      newFiles: attiOnboarding.filter((doc) => doc.tipoAtto === 'DEL').map(({ file }) => file),
      existingFiles:
        onboarding?.elencoDocumenti.filter((doc) => doc.tipo === 'ATTI_DELIBERA') ?? [],
      tipoDocumento: '29',
      nomeFiles: 'Atti Delibera',
      idOnboarding: onboarding?.id,
    });

    const documentiDecreto = await syncFiles({
      newFiles: attiOnboarding.filter((doc) => doc.tipoAtto === 'DEC').map(({ file }) => file),
      existingFiles: onboarding?.elencoDocumenti.filter((doc) => doc.tipo === 'ATTI_DECRETO') ?? [],
      tipoDocumento: '21',
      nomeFiles: 'Atti Decreto',
      idOnboarding: onboarding?.id,
    });

    /*  const documentiSchedaFunzionaleUploaded = await syncFiles({
    newFiles: documentiDisabilita
      .filter((doc) => doc.tipoDisabilita === 19)
      .map((doc) => doc.documentoDisabilita),
    existingFiles: onboarding?.elencoDocumenti.filter(
      (doc) => doc.tipo === 'RICHIESTA_SCHEDA_FUNZIONALE'
    ),
    tipoDocumento: '19',
    nomeFiles: 'Richiesta Scheda Funzionale',
    idOnboarding: onboarding?.id,
  });

  const documentiVerbaleInvaliditaUploaded = await syncFiles({
    newFiles: documentiDisabilita
      .filter((doc) => doc.tipoDisabilita === 20)
      .map((doc) => doc.documentoDisabilita),
    existingFiles: onboarding?.elencoDocumenti.filter(
      (doc) => doc.tipo === 'RICHIESTA_VERBALE_INVALIDITA'
    ),
    tipoDocumento: '20',
    nomeFiles: 'Richiesta Verbale Invalidità',
    idOnboarding: onboarding?.id,
  });

  const documentoCollocamento = await syncFiles({
    newFiles: fileCollocamento ? [fileCollocamento] : [],
    existingFiles: onboarding?.elencoDocumenti.filter((doc) => doc.tipo === 'NULLA_OSTA'),
    tipoDocumento: '31',
    nomeFiles: 'Nulla Osta',
    idOnboarding: onboarding?.id,
  }); */

    console.log('All file operations completed!');

    const onboardingUpdated = {
      id: onboarding?.id,
      stato: OnboardingConcorsoStato.COM,
      statoOnb: OnboardingConcorsoStatoOnb.MODIFICATO_RECLUTAMENTO,
      idConcorso,
      areaProfessionale,
      tipologiaContrattoId: 1,
      livello: livelli,
      vincitore,
      idoneo,
      posizioneGraduatoria,
      attiOnboarding: attiOnboarding.map(({ file, ...rest }) => rest),
    };

    const payload = {
      ...candidato,
      //id: candidato?.id,
      codiceFiscale,
      cognome,
      nome,
      genere: sesso === 'F' ? 'FEMMINILE' : 'MASCHILE',
      statoCivile,
      cittadinanza,
      dataNascita,
      nazioneNascita,
      provNascita,
      cittaNascita,
      tipoDocumento,
      numeroCartaIdentita,
      enteEmittente,
      dataRilascio,
      dataScadenza,
      indirizzoPec,
      email,
      telefonoAbitazione,
      telefonoCellulare,
      statoResidenza,
      provinciaResidenza,
      cittaResidenza,
      indirizzoResidenza,
      capResidenza,
      domicilioDifferente: domicilioDifferente !== 'No',
      statoDomicilio,
      provinciaDomicilio,
      cittaDomicilio,
      indirizzoDomicilio,
      capDomicilio,
      disabile: disabile !== 'No',
      percentualeDisabilita,
      collocamentoMirato: collocamentoMirato !== 'No',
      ...(titoloStudio && { titoloStudio }),
      ...(titoloDichiarato && { titoloDichiarato }),
      ...(noteTitolo && { noteTitolo }),
      onboardingConcorsi: [onboardingUpdated],
    };

    if (!enableValidation) {
      payload.onboardingConcorsi[0].stato = OnboardingConcorsoStato.INC;
      payload.onboardingConcorsi[0].statoOnb = OnboardingConcorsoStatoOnb.BOZZA;
      try {
        axios.post(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/salva/onboarding`, payload);
        navigate('/elenco-candidati');
      } catch (error) {
        dispatch(
          showNotifica({
            titolo: 'Candidato salvataggio come bozza non riuscito',
            stato: 'error',
            messaggio: 'Il salvataggio come bozza non è riuscito',
            tipo: NOTIFICA_STATO.error,
          })
        );
      }
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/modifica/${candidato?.id}/onboarding/${onboarding?.id}`,
        payload
      );

      registraDocumenti(
        [
          ...(documentiIdentificazione ?? []),
          ...(documentiDetermina ?? []),
          ...(documentiDecreto ?? []),
          ...(documentiDelibera ?? []),
          /*     ...(documentiSchedaFunzionaleUploaded ?? []),
          ...(documentiVerbaleInvaliditaUploaded ?? []),
          ...(documentoCollocamento ?? []),
          ...(documentiDataRevisione ?? []),  */
        ],
        onboarding?.id
      );

      //navigate('/elenco-candidati');
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Candidato non inserito',
          stato: 'error',
          messaggio: 'Il candidato non è stato inserito',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  }

  if (isAsyncValuesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormContext.Provider value={formik}>
      <div>
        {children}
        <div className="d-flex col-12 mt-4">
          <BackButton />
          <div className="d-flex ml-auto">
            <Button
              className="mr-2"
              color="primary"
              outline
              onClick={() => {
                setEnableValidation(false);
                formik.handleSubmit();
              }}>
              Salva in bozza
            </Button>
            <Button className="ml-2" color="primary" onClick={() => formik.handleSubmit()}>
              Modifica
            </Button>
          </div>
        </div>
      </div>
    </FormContext.Provider>
  );
};