import { Button, Callout, CalloutText, CalloutTitle, Icon } from 'design-react-kit';
import './primo-accesso-cittadino.scss';
import { useAppDispatch } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { registraCittadino } from '../../store/userSlice';
import { deleteJwtToken, saveTokenFromInput } from '../../utility/cookie';
import { HREF_SCRIVANIA_VIRTUALE } from '../../components/layout/sidemenu/sidemenuConstants';

const PrimoAccessoCittadino = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const conferma = () => {
    dispatch(registraCittadino())
      .unwrap()
      .then((resp) => {
        if (resp && resp.status == 200) {
          saveTokenFromInput(resp.headers['x-au-user-token']);
          navigate(HREF_SCRIVANIA_VIRTUALE);
          window.location.reload();
        }
      });
  };

  const annulla = () => {
    deleteJwtToken();
    // Creazione di un form nascosto per il logout
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = process.env.REACT_APP_MS_AU_LOGOUT_URL || ''; // URL di logout su WSO2 Identity Server

    // Aggiungi il form alla pagina e invialo
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ minHeight: '75vh' }}
    >
      <div className="login-box">
        <Callout color="note">
          <CalloutTitle>
            <Icon aria-hidden icon="it-check-circle" />
            BENVENUTO
          </CalloutTitle>
          <CalloutText>
            Questo è il portale di Anagrafica Unica. <br />
            Se confermi avrai la possibilità di visualizzare bandi e concorsi inerenti al tuo
            profilo.
          </CalloutText>
        </Callout>
        <div className="d-flex flex-row-reverse">
          <Button color="primary" onClick={conferma}>
            Conferma
          </Button>
          &nbsp;
          <Button color="outline-primary" onClick={annulla}>
            Annulla
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrimoAccessoCittadino;
