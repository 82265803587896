import { useEffect, useState } from 'react';
import {
  AssegnazioneFunzionalitaApplicativeControllerApi,
  DipendenteRegionaleEssenzialeDto,
  FunzionalitaRichiestaRequestDto,
  FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum,
  InsertRichiestaAbilitazioneDto,
  InsertRichiestaAbilitazioneDtoTipoUtenteEnum,
  RuoloUtenteAutenticatoDto,
} from '../../services/ms-anagrafica-unica';
import { Alert, Button, Col, FormGroup, Input, Label, Table, Toggle } from 'design-react-kit';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import CustomAutocomplete from '../../components/common/custom-autocomplete/CustomAutocomplete';
import { debounce } from 'lodash';
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HREF_ABILITAZIONI_ELENCO_RICHIESTE } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getElencoFunzionalita,
  inserisciRichiestaAbilitazione,
  loadFormsFunzionalitaApplicative,
} from '../../store/funzionalitaApplicativeSlice';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import { hideLoader, showLoader } from '../../store/loaderSlice';
import PageHeader from '../../components/common/page-header/PageHeader';

const NuovaRichiestaAbilitazione = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const [dipendente, setDipendente] = useState<Boolean>(true);
  const [keyState, setKeyState] = useState<any>();
  const [btnSalvataggio, setBtnSalvataggio] = useState<'SALVA' | 'CONFERMA'>('SALVA');
  const [dipendentiRecuperati, setDipendentiRecuperati] = useState<
    Array<DipendenteRegionaleEssenzialeDto>
  >([]);
  const initialValues: InsertRichiestaAbilitazioneDto = {
    codiceCid: 0,
    nome: '',
    cognome: '',
    codiceFiscale: '',
    applicativo: undefined,
    elencoFunzionalita: undefined,
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formsResult, formsStatus } = useAppSelector((state) => state.abilitazioni);
  const dispatch = useAppDispatch();
  const [arrFunz, setArrayFunz] = useState<Array<FunzionalitaRichiestaRequestDto>>([]);

  useEffect(() => {
    !formsResult?.applicativi && dispatch(loadFormsFunzionalitaApplicative());
  }, []);

  const schemaConferma = z.object({
    codiceCid: z.number({ required_error: 'required' }).gte(0, { message: 'required' }),
    codiceFiscale: z.string().max(16, { message: 'lengthcodicefiscale' }),
    nome: z.string().max(200, { message: 'lenghtnome' }),
    cognome: z.string().max(200, { message: 'lenghtcognome' }),
    applicativo: z.number({ required_error: 'required' }),
    elencoFunzionalita: z.array(
      z.object({
        idFunzionalita: z.number(),
        tipoAbilitazione: z.string(),
        noteDirigente: z.string().optional(),
      })
    ),
  });

  const schemaSalva = z.object({
    codiceCid: z.number().optional(),
    codiceFiscale: z.string().max(16, { message: 'lengthcodicefiscale' }).optional(),
    nome: z.string().max(200, { message: 'lenghtnome' }).optional(),
    cognome: z.string().max(200, { message: 'lenghtcognome' }).optional(),
    applicativo: z.number().optional(),
    elencoFunzionalita: z
      .array(
        z.object({
          idFunzionalita: z.number(),
          tipoAbilitazione: z.string(),
          noteDirigente: z.string().optional(),
        })
      )
      .optional(),
  });

  const validationSchema =
    btnSalvataggio === 'CONFERMA'
      ? toFormikValidationSchema(schemaConferma)
      : toFormikValidationSchema(schemaSalva);

  const searchForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const req: InsertRichiestaAbilitazioneDto = {
        ...values,
        elencoFunzionalita: arrFunz,
        tipoUtente: dipendente
          ? InsertRichiestaAbilitazioneDtoTipoUtenteEnum.Dipendente
          : InsertRichiestaAbilitazioneDtoTipoUtenteEnum.ConsulenteEsterno,
        requestType: btnSalvataggio,
      };
      console.log('Invio form inserimento >>> ', req);
      dispatch(inserisciRichiestaAbilitazione(req))
        .unwrap()
        .then((resp) => navigate(HREF_ABILITAZIONI_ELENCO_RICHIESTE));
    },
  });

  const autocompleteDipendenti = debounce(async (inputValue: string, callback: any) => {
    dispatch(showLoader());
    const api = new AssegnazioneFunzionalitaApplicativeControllerApi(MS_AU_CONFIG);
    const response = await api.searchDipendentiAssegnazioneFunzionalitaApplicative({
      chiave: inputValue,
      consulentiEsterni: !dipendente,
    });
    let options: { value: number | undefined; label: string | undefined }[] = [];
    if (response.data) {
      dispatch(hideLoader());
      setDipendentiRecuperati(response.data);
      response.data?.forEach((value) => {
        options.push({ value: value.id, label: value.nomeCompleto });
      });
    }
    callback(options);
  }, 500);

  const popolaForm = (e: any) => {
    searchForm.setFieldValue('codiceCid', e.id, false);
    searchForm.setFieldValue('codiceFiscale', e.codiceFiscale, false);
    searchForm.setFieldValue('nome', e.nome, false);
    searchForm.setFieldValue('cognome', e.cognome, false);
  };

  let applicativoOptions: CustomSelectItem[] = [];
  formsResult?.applicativi?.forEach((s) => {
    applicativoOptions.push({ label: s.label, value: s.valore });
  });

  const handleChange = (name: string, selectedOption: any) => {
    searchForm.setFieldValue(name, selectedOption, false);
  };

  const handleDipendenteSelect = (value: CustomSelectItem) => {
    const dip = dipendentiRecuperati
      .filter((d) => {
        if (d.id === value.value) {
          return d;
        } else {
          return null;
        }
      })
      .at(0);
    !!dip && popolaForm(dip);
  };

  const setApplicativo = (e: any) => {
    handleChange('applicativo', Number(e));
    dispatch(getElencoFunzionalita(Number(e)))
      .unwrap()
      .then((resp) => {
        if (resp && resp.funzionalitaApplicativo && resp.funzionalitaApplicativo.length > 0) {
          const arr: Array<FunzionalitaRichiestaRequestDto> = [];
          resp.funzionalitaApplicativo.forEach((f) => {
            const funzionalita: FunzionalitaRichiestaRequestDto = {
              idFunzionalita: f.valore,
              tipoAbilitazione: FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.Off,
              noteDirigente: '',
            };
            arr.push(funzionalita);
            setArrayFunz(arr);
          });
          searchForm.setFieldValue('elencoFunzionalita', arrFunz, false);
        }
      });
  };

  const submitConfirm = () => {
    setBtnSalvataggio('CONFERMA');
    setTimeout(() => {
      searchForm.submitForm();
    }, 500);
  };

  const submitSave = () => {
    setBtnSalvataggio('SALVA');
    setTimeout(() => {
      searchForm.submitForm();
    }, 500);
  };

  const changeAbilitazione = (e: any) => {
    const newArrFunz = arrFunz.map((f) => {
      if (f.idFunzionalita === e) {
        if (f.tipoAbilitazione === FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.Off) {
          f.tipoAbilitazione = FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.On;
        } else {
          f.tipoAbilitazione = FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum.Off;
        }
      }
      return f;
    });
    setArrayFunz(newArrFunz);
  };

  const changeNoteDirigente = (e: any, funzionalita: number) => {
    const newArrFunz = arrFunz.map((f) => {
      if (f.idFunzionalita === funzionalita) {
        f.noteDirigente = e.target.value;
      }
      return f;
    });
    setArrayFunz(newArrFunz);
  };

  const abilitaSalvataggio = () =>
    !!searchForm.values.applicativo &&
    !!searchForm.values.codiceCid &&
    !!searchForm.values.codiceFiscale &&
    !!searchForm.values.nome &&
    !!searchForm.values.cognome;

  const handleDipendenteConsulente = () => {
    console.log('handleDipendenteConsulente');
    setDipendente(!dipendente);
    setKeyState(!keyState);
    searchForm.setFieldValue('codiceCid', '');
    searchForm.setFieldValue('codiceFiscale', '');
  };

  return (
    <div>
      <PageHeader showIcon={false} title={t('Nuova richiesta di abilitazione')!} buttons={[]} />
      <div className="form-custom form-inserimento form-bg border">
        <div className="form-row">
          <Col md={3}>
            {dipendente ? (
              <Toggle
                label="Dipendente"
                disabled={false}
                defaultChecked
                onClick={() => handleDipendenteConsulente()}
              />
            ) : (
              <Toggle
                label="Dipendente"
                disabled={false}
                onClick={() => handleDipendenteConsulente()}
              />
            )}
          </Col>
        </div>
        <div className="form-row">
          <Col md>
            <CustomAutocomplete
              key={keyState}
              returnLabel={true}
              required={true}
              label={dipendente ? 'Dipendente' : 'Consulente esterno'}
              placeholder="Ricerca per CID, Codice Fiscale, Nome e/o Cognome"
              id="select-idDipendente"
              loadOptionsFn={autocompleteDipendenti}
              handleOptionSelect={(e: any) => handleDipendenteSelect(e)}
              invalid={!!searchForm.errors.nome || !!searchForm.errors.cognome}
              infoText={t(searchForm.errors.nome || searchForm.errors.cognome || '') || ''}
            />
          </Col>
        </div>
        <div className="form-row mt-2">
          <Input
            type="text"
            label="Codice CID"
            id="codiceCid"
            placeholder="Codice CID"
            wrapperClassName="col-md-6 required"
            name="codiceCid"
            invalid={!!searchForm.errors.codiceCid}
            infoText={t(searchForm.errors.codiceCid || '') || ''}
            value={searchForm.values.codiceCid || ''}
            disabled={true}
          />
          <Input
            type="text"
            label="Codice Fiscale"
            id="codiceFiscale"
            placeholder="Codice Fiscale"
            wrapperClassName="col-md-6 required"
            name="codiceFiscale"
            invalid={!!searchForm.errors.codiceFiscale}
            infoText={t(searchForm.errors.codiceFiscale || '') || ''}
            value={searchForm.values.codiceFiscale}
            disabled={true}
          />
          <CustomSelect
            label="Applicativo"
            options={applicativoOptions}
            value={searchForm.values.applicativo}
            placeholder="Seleziona applicativo"
            invalid={!!searchForm.errors.applicativo}
            infoText={t(searchForm.errors.applicativo || '') || ''}
            onChange={(e) => setApplicativo(e)}
            name="applicativo"
            wrapperClass="col-md required"
          />
        </div>
        {formsStatus === STATUS_FULLFILLED && !formsResult?.funzionalitaApplicativo && (
          <Alert color="info">Nessuna funzionalità censita per l'applicativo.</Alert>
        )}
        {formsResult?.funzionalitaApplicativo && formsResult.funzionalitaApplicativo.length > 0 && (
          <div className="form-row mt-3">
            <Table md={12}>
              <thead>
                <tr>
                  <th>Funzionalità</th>
                  <th>Attiva</th>
                  <th>Note Dirigente</th>
                </tr>
              </thead>
              <tbody>
                {formsResult?.funzionalitaApplicativo.map((funz, key) => (
                  <tr key={key}>
                    <td>{funz.label}</td>
                    <td>
                      <FormGroup check className="mb-0">
                        <Input
                          id={`funz${funz.valore}`}
                          type="checkbox"
                          onChange={() => changeAbilitazione(funz.valore)}
                        />
                        <Label for={`funz${funz.valore}`} check></Label>
                      </FormGroup>
                    </td>
                    <td>
                      <Input
                        type="text"
                        id="note"
                        name="note"
                        onChange={(e) => changeNoteDirigente(e, funz.valore)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <div className="d-flex flex-row-reverse py-3">
        <Button
          color="primary"
          className="mt-2 mr-2"
          disabled={!abilitaSalvataggio()}
          onClick={() => submitConfirm()}
        >
          Conferma
        </Button>
        <Button
          color="primary"
          className="mt-2 mr-2"
          disabled={!abilitaSalvataggio()}
          onClick={() => submitSave()}
        >
          Salva
        </Button>
        <Button
          color="secondary"
          outline
          className="mt-2 mr-2"
          onClick={() => {
            navigate(`${HREF_ABILITAZIONI_ELENCO_RICHIESTE}`);
          }}
        >
          {t('annulla')}{' '}
        </Button>
      </div>
    </div>
  );
};

export default NuovaRichiestaAbilitazione;
