/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OptionDtoStatoRichiestaAbilitazione
 */
export interface OptionDtoStatoRichiestaAbilitazione {
  /**
   *
   * @type {string}
   * @memberof OptionDtoStatoRichiestaAbilitazione
   */
  value?: OptionDtoStatoRichiestaAbilitazioneValueEnum;
  /**
   *
   * @type {string}
   * @memberof OptionDtoStatoRichiestaAbilitazione
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OptionDtoStatoRichiestaAbilitazione
   */
  valore: OptionDtoStatoRichiestaAbilitazioneValoreEnum;
}

export const OptionDtoStatoRichiestaAbilitazioneValueEnum = {
  Bozza: 'BOZZA',
  AttesaApprovazioneAttivazione: 'ATTESA_APPROVAZIONE_ATTIVAZIONE',
  AttesaApprovazioneModifica: 'ATTESA_APPROVAZIONE_MODIFICA',
  AttesaApprovazioneSospensione: 'ATTESA_APPROVAZIONE_SOSPENSIONE',
  AttesaApprovazioneRevoca: 'ATTESA_APPROVAZIONE_REVOCA',
  AttesaApprovazioneRiattivazione: 'ATTESA_APPROVAZIONE_RIATTIVAZIONE',
  Attiva: 'ATTIVA',
  Annullata: 'ANNULLATA',
  Revocata: 'REVOCATA',
  Sospesa: 'SOSPESA',
} as const;

export type OptionDtoStatoRichiestaAbilitazioneValueEnum =
  (typeof OptionDtoStatoRichiestaAbilitazioneValueEnum)[keyof typeof OptionDtoStatoRichiestaAbilitazioneValueEnum];
export const OptionDtoStatoRichiestaAbilitazioneValoreEnum = {
  Bozza: 'BOZZA',
  AttesaApprovazioneAttivazione: 'ATTESA_APPROVAZIONE_ATTIVAZIONE',
  AttesaApprovazioneModifica: 'ATTESA_APPROVAZIONE_MODIFICA',
  AttesaApprovazioneSospensione: 'ATTESA_APPROVAZIONE_SOSPENSIONE',
  AttesaApprovazioneRevoca: 'ATTESA_APPROVAZIONE_REVOCA',
  AttesaApprovazioneRiattivazione: 'ATTESA_APPROVAZIONE_RIATTIVAZIONE',
  Attiva: 'ATTIVA',
  Annullata: 'ANNULLATA',
  Revocata: 'REVOCATA',
  Sospesa: 'SOSPESA',
} as const;

export type OptionDtoStatoRichiestaAbilitazioneValoreEnum =
  (typeof OptionDtoStatoRichiestaAbilitazioneValoreEnum)[keyof typeof OptionDtoStatoRichiestaAbilitazioneValoreEnum];
