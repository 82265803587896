import { Header, HeaderBrand, HeaderContent } from 'design-react-kit';
// import './HeaderCenter.scss'
import logoArpal from '../../../assets/images/Arpal_puglia_white.png';

function HeaderCenter() {
  return (
    <Header type="center" theme="">
      <HeaderContent>
        <HeaderBrand iconName={logoArpal}>
          <h2>Anagrafica Unica</h2>
        </HeaderBrand>
      </HeaderContent>
    </Header>
  );
}

export default HeaderCenter;
