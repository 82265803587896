import DocumentView from 'components/common/document-view/DocumentView';
import { useTranslation } from 'react-i18next';
import { IncaricoDirigenzialeDto } from 'services/ms-anagrafica-unica';
import { formatDate } from 'utility/formatDate';

export interface DocumentiProps {
  showDocumenti: boolean;
  download?: boolean;
  view?: boolean;
  protocolla?: boolean;
}

export interface DettaglioPropostaIncaricoProps {
  propostaIncarico: IncaricoDirigenzialeDto;
  documenti?: DocumentiProps;
}

export const DettaglioPropostaIncarico = ({
  propostaIncarico,
  documenti = { showDocumenti: false },
}: DettaglioPropostaIncaricoProps) => {
  const { t } = useTranslation();

  return (
    <div className="row dettaglio-incarico">
      <div className="col-sm-12">
        <div className="info-box-light-so bg-light-gray">
          <h5 className="pb-2 border-bottom font-weight-bold">Riepilogo</h5>
          <div className="row inserimento-parere-table-info">
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">
                    {t('valutazioneProposta.riepilogo.denominazioneSo')}
                  </dt>
                  <dd className="col-sm-12">{propostaIncarico.denominazioneSo}</dd>
                </div>
              </div>
            </dl>
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">
                    {t('valutazioneProposta.riepilogo.codiceStruttura')}
                  </dt>
                  <dd className="col-sm-12">{propostaIncarico.codiceIncaricoSo}</dd>
                </div>
              </div>
            </dl>
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">{t('valutazioneProposta.riepilogo.tipoStruttura')}</dt>
                  <dd className="col-sm-12">{propostaIncarico.tipoStrutturaOrganizzativa}</dd>
                </div>
              </div>
            </dl>
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">{t('valutazioneProposta.riepilogo.tipoIncarico')}</dt>
                  <dd className="col-sm-12">{propostaIncarico.tipoIncarico}</dd>
                </div>
              </div>
            </dl>
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">{t('valutazioneProposta.riepilogo.candidato')}</dt>
                  {propostaIncarico.candidatoInterno ? (
                    <dd className="col-sm-12">
                      {propostaIncarico.dipendenteRegionale?.nomeCompleto}
                    </dd>
                  ) : (
                    <dd className="col-sm-12">{`${propostaIncarico.nomeCognomeEsterno} ${propostaIncarico.codiceFiscaleEsterno && '(' + propostaIncarico.codiceFiscaleEsterno + ')'}`}</dd>
                  )}
                </div>
              </div>
            </dl>
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">
                    {t('valutazioneProposta.riepilogo.candidatoInterno')}
                  </dt>
                  {propostaIncarico.candidatoInterno ? (
                    <dd className="col-sm-12">{t('si')}</dd>
                  ) : (
                    <dd className="col-sm-12">{t('no')}</dd>
                  )}
                </div>
              </div>
            </dl>
            <dl className="col-sm-3">
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">{t('valutazioneProposta.riepilogo.inComando')}</dt>
                  <dd className="col-sm-12">{propostaIncarico.inComando ? t('si') : t('no')}</dd>
                </div>
              </div>
            </dl>
          </div>

          {documenti.showDocumenti && (
            <div className="row border-top">
              <dl className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    {propostaIncarico.documenti?.length! > 0 && (
                      <div className="documentView pt-3">
                        <h4>Documenti caricati</h4>
                        <div className="form-row">
                          {propostaIncarico.documenti?.map((value) => (
                            <>
                              <div className="col-md-12" key={value.documento?.id}>
                                <DocumentView
                                  documentName={
                                    t(`tipo-documento.${value.tipo!}`) +
                                    ' (' +
                                    formatDate(value.documento?.dataInserimento || '') +
                                    ')'
                                  }
                                  documento={value.documento!}
                                  download={documenti.download}
                                  view={documenti.view}
                                  protocolla={documenti.protocolla}
                                />
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </dl>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
