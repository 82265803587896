/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OptionDtoTipoStrutturaOrganizzativa
 */
export interface OptionDtoTipoStrutturaOrganizzativa {
  /**
   *
   * @type {string}
   * @memberof OptionDtoTipoStrutturaOrganizzativa
   */
  value?: OptionDtoTipoStrutturaOrganizzativaValueEnum;
  /**
   *
   * @type {string}
   * @memberof OptionDtoTipoStrutturaOrganizzativa
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OptionDtoTipoStrutturaOrganizzativa
   */
  valore: OptionDtoTipoStrutturaOrganizzativaValoreEnum;
}

export const OptionDtoTipoStrutturaOrganizzativaValueEnum = {
  Dipartimento: 'DIPARTIMENTO',
  Sezione: 'SEZIONE',
  Servizio: 'SERVIZIO',
} as const;

export type OptionDtoTipoStrutturaOrganizzativaValueEnum =
  (typeof OptionDtoTipoStrutturaOrganizzativaValueEnum)[keyof typeof OptionDtoTipoStrutturaOrganizzativaValueEnum];
export const OptionDtoTipoStrutturaOrganizzativaValoreEnum = {
  Dipartimento: 'DIPARTIMENTO',
  Sezione: 'SEZIONE',
  Servizio: 'SERVIZIO',
} as const;

export type OptionDtoTipoStrutturaOrganizzativaValoreEnum =
  (typeof OptionDtoTipoStrutturaOrganizzativaValoreEnum)[keyof typeof OptionDtoTipoStrutturaOrganizzativaValoreEnum];
