import { Input } from 'design-react-kit';
import React from 'react';
import { InfoPatrimonialiFinanziarieDto } from '../../../services/ms-anagrafica-unica';

const RIInformazioniPatrimonialiComponent = (dati: { value: InfoPatrimonialiFinanziarieDto }) => {
  return (
    <div className={'row pt-5'}>
      <Input
        type="text"
        label="Capitale Sociale Deliberato"
        disabled={true}
        value={dati.value['capitale-sociale']?.deliberato?.ammontare ?? '-'}
        wrapperClassName="col-md-4"
      />
      <Input
        type="text"
        label="Capitale Sociale Sottoscritto"
        disabled={true}
        value={dati.value['capitale-sociale']?.sottoscritto?.ammontare ?? '-'}
        wrapperClassName="col-md-4"
      />
      <Input
        type="text"
        label="Capitale Sociale Versato"
        disabled={true}
        value={dati.value['capitale-sociale']?.versato?.ammontare ?? '-'}
        wrapperClassName="col-md-4"
      />
    </div>
  );
};

export default RIInformazioniPatrimonialiComponent;
