export const COLLAPSE_NAME_GESTIONE_SO = 'gestione-strutture-organizzative';
export const COLLAPSE_NAME_GESTIONE_PO = 'gestione-eq';
export const COLLAPSE_NAME_GESTIONE_CONVENZIONI = 'gestione-convenzioni';
export const COLLAPSE_NAME_GESTIONE_CANDIDATI = 'gestione-candidati';
export const COLLAPSE_NAME_GESTIONE_DIPENDENTI = 'gestione-dipendenti';
export const COLLAPSE_NAME_PAGINE_NASCOSTE = 'pagine-nascoste';

export const HREF_UPLOAD_GESTIONE_MASSIVA = 'upload-gestione-massiva';
export const HREF_PRIMO_ACCESSO_CITTADINO = 'primo-accesso-cittadino';

export const HREF_SCRIVANIA_VIRTUALE = '/scrivania-virtuale';

export const HREF_ORGANIGRAMMA = '/organigramma';
export const HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA =
  '/organigramma/dettaglio-struttura-organizzativa';
export const HREF_SEZIONE = '/organigramma/sezione';
export const HREF_SERVIZIO = '/organigramma/servizio';
export const HREF_LOGIN = '/login';
export const HREF_LOGOUT = '/fai-logout';
export const HREF_ELENCO_STRUTTURE_ORGANIZZATIVE = '/elenco-strutture-organizzative';
export const HREF_NUOVA_STRUTTURA_ORGANIZZATIVA = '/nuova-struttura-organizzativa';
export const HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA = '/assegnazione-struttura-organizzativa';
export const HREF_BOZZA_STRUTTURA_ORGANIZZATIVA = '/bozza-struttura-organizzativa';
export const HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA =
  '/modifica-associazione-struttura-organizzativa';
export const HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA = '/modifca-struttura-organizzativa';

export const HREF_ASSEGNAZIONE_CODICE_CIFRA = '/assegnazione-codice-cifra';
export const HREF_ASSEGNAZIONE_CODICE_IPA = '/assegnazione-codice-ipa';
export const HREF_ASSEGNAZIONE_CODICE_BILANCIO = '/assegnazione-codice-bilancio';
export const HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA =
  '/disattivazione-struttura-organizzativa';
export const HREF_ASSEGNAZIONE_UTENZA_CIFRA = '/assegnazione-utenza-cifra';

export const HREF_ELENCO_POSIZIONI_ORGANIZZATIVE = '/elenco-eq';
export const HREF_NUOVA_POSIZIONE_ORGANIZZATIVA = '/nuova-eq';
export const HREF_ELENCO_BANDI = '/elenco-bandi';
export const HREF_NUOVO_BANDO = '/nuovo-bando';
export const HREF_CANDIDATURA_ELENCO_BANDI = '/elenco-bandi-eq';
export const HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA = '/assegnazione-eq';
export const HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO = '/upload-determina-istituzione-avviso';
export const HREF_DOWNLOAD_NULLA_OSTA = '/download-nulla-osta';
export const HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA = '/modifica-eq';
export const HREF_UPLOAD_NOMINA = '/upload-nomina';
export const HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA = '/visualizza-eq';
export const HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA = '/disattiva-eq';
export const HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA = '/disassociazione-eq';
export const HREF_ELENCO_RICHIESTE_NULLA_OSTA = '/elenco-richieste-nulla-osta';
export const HREF_ELENCO_ASSEGNAZIONI = '/elenco-assegnazioni-eq';
export const HREF_CANDIDATURA_NULLA_OSTA = '/richiesta-nulla-osta';
export const HREF_CANDIDATURA_VISUALIZZA_BANDO = '/visualizza-bando';
export const HREF_CANDIDATURA_DETTAGLIO_NULLA_OSTA = '/valutazione-richiesta-nulla-osta';
export const HREF_ELENCO_CANDIDATURE = '/elenco-candidature';
export const HREF_CANDIDATURA_ELENCO_CANDIDATURE = '/elenco-candidature';
export const HREF_MODIFICA_BANDO = '/modifica-bando';

export const COLLAPSE_NAME_GESTIONE_RUOLI_FUNZIONALITA = 'gestione-ruoli-funzionalita';
export const HREF_ELENCO_RUOLI = '/elenco-ruoli';
export const HREF_NUOVO_RUOLO = '/nuovo-ruolo';
export const HREF_MODIFICA_RUOLO = '/modifica-ruolo';
export const HREF_GESTIONE_NOTIFICHE = '/gestione-notifiche';
export const HREF_NUOVA_CONFIGURAZIONE_NOTIFICA = '/nuova-configurazione-notifica';
export const HREF_MODIFICA_CONFIGURAZIONE_NOTIFICA = '/modifica-configurazione-notifica';
export const HREF_GESTIONE_RUOLO_FUNZIONALITA = '/gestisci-ruolo-funzionalita';
export const HREF_GESTIONE_RUOLO_UTENTI = '/gestisci-ruolo-utenti';

export const HREF_ELENCO_CONVENZIONI = '/elenco-convenzioni';
export const HREF_VISUALIZZA_CONVENZIONE = '/visualizza-convenzione';
export const HREF_NUOVA_CONVENZIONE = '/nuova-convenzione';
export const HREF_MODIFICA_CONVENZIONE = '/modifica-convenzione';

export const COLLASPE_REGISTRO_IMPRESE = 'registro-imprese';
export const HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA = 'registro-imprese/';
export const HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA = 'registro-imprese/ricerca-multipla';
export const HREF_ELENCO_CANDIDATI = '/elenco-candidati';
export const HREF_INSERIMENTO_CANDIDATO = '/inserimento-candidato';
export const HREF_TROVA_CANDIDATO = '/trova-candidato';
export const HREF_INSERIMENTO_MASSIVO_CANDIDATI = '/inserimento-massivo-candidati';
export const HREF_INSERIMENTO_CONCORSO = '/inserimento-concorso';
export const HREF_MODIFICA_CANDIDATO = '/modifica-candidato';
export const HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO = '/validazione-integrazione-candidato';
export const HREF_VERIFICA_DATI_DOCUMENTI_CANDIDATO = '/verifica-dati-documenti-candidato';
export const HREF_COM_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO =
  '/comunicazione-sottoscrizione-contratto';
export const HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO = '/gestione-sottoscrizione-contratto';
export const HREF_GEST_ACCETTAZIONE_CONTRATTO = '/gestione-accettazione-contratto';
export const HREF_GEST_CESSAZIONE_CONTRATTO = '/gestione-cessazione-contratto';
export const HREF_CONF_AREA_DOCUMENTI = '/conf-area-documenti';
export const HREF_MOD_DOCUMENTI_CANDIDATO = '/modifica-documenti-candidato';
export const HREF_VISUALIZZA_CANDIDATO = '/dettaglio-candidato';
export const HREF_VERIFICA_DOC_CANDIDATO = '/verifica-dati-candidato';
export const HREF_CONVOCAZIONE_CANDIDATO = '/convocazione-candidato';
export const HREF_GESTIONE_CONVOCAZIONE_CANDIDATO = '/gestione-convocazione-candidato';
export const HREF_WIZARD_ACCETTAZIONE = 'wizard-accettazione';
export const HREF_GESTIONE_ACCETTAZIONE_CONTRATTO = '/gestione-accettazione-contratto';

export const HREF_FEEDBACK_NOTIFICA = '/feedback-notifica';



export const HREF_ELENCO_DIPENDENTI_REG = '/elenco-dipendenti-regionali';
export const HREF_VISUALIZZA_CESSAZIONE_DIPENDENTE = '/visualizza-cessazione';
export const HREF_CESSAZIONE_DIPENDENTE = '/cessazione-dipendente';

export const COLLAPSE_NAME_GESTIONE_INCARICHI_DIRIGENZIALI = 'gestione-incarichi-dirigenziali';
export const HREF_ELENCO_INCARICHI_DIRIGENZIALI = '/elenco-incarichi-dirigenziali';
export const HREF_NUOVA_PROPOSTA_INCARICO = '/nuova-proposta-incarico';
export const HREF_MODIFICA_PROPOSTA_INCARICO = '/modifica-proposta-incarico';
export const HREF_VALUTA_PROPOSTA_INCARICO = '/valuta-proposta-incarico';
export const HREF_INSERIMENTO_PARERE_GIUNTA = '/inserimento-parere-giunta';
export const HREF_ASSEGNA_INCARICO = '/assegna-incarico';
export const HREF_MODIFICA_INCARICO = '/modifica-incarico';
export const HREF_VISUALIZZA_INCARICO = '/visualizza-incarico';

export const COLLAPSE_NAME_RUBRICA = 'rubrica';
export const HREF_RUBRICA_ELENCO_DIPENDENTI = '/elenco-dipendenti';
export const HREF_RUBRICA_INSERISCI_TEL_CRED = '/aggiungi-telefono-credenziali';
export const HREF_RUBRICA_INSERISCI_EMAIL_DIPENDENTE = '/aggiungi-email-dipendente';
export const HREF_RUBRICA_MODIFICA_UTENZE = '/modifica-utenze-dipendente';
export const HREF_RUBRICA_VISUALIZZA_UTENZE = '/visualizzazione-utenze-dipendente';

export const COLLAPSE_NAME_GESTIONE_CONSULENTE_ESTERNO = 'gestione-consulente-esterno';
export const HREF_CONSULENTE_ESTERNO_ELENCO = '/elenco-consulenti-esterni';
export const HREF_CONSULENTE_ESTERNO_INSERISCI = '/inserimento-consulente-esterno';
export const HREF_CONSULENTE_ESTERNO_MODIFICA = '/modifica-consulente-esterno';
export const HREF_CONSULENTE_ESTERNO_DETTAGLIO = '/dettaglio-consulente-esterno';
// export const HREF_RUBRICA_MAIL_SO_SERVIZIO = '/creazione-email-so-servizio'

export const COLLAPSE_NAME_GESTIONE_ABILITAZIONI = 'gestione-abilitazioni';
export const HREF_ABILITAZIONI_ELENCO_RICHIESTE = '/elenco-richieste';
export const HREF_ABILITAZIONI_NUOVA_RICHIESTA = '/inserisci-nuova-richiesta';
export const HREF_ABILITAZIONI_MODIFICA_RICHIESTA = '/modifica-richiesta';
export const HREF_ABILITAZIONI_DETTAGLIO_RICHIESTA = '/dettaglio-richiesta';
export const HREF_ABILITAZIONI_APPROVAZIONE_ATTIVAZIONE = '/approva-richiesta-attivazione';

export const COLLASPE_NAME_GESTIONE_CV = 'gestione-cv';
export const HREF_GESTIONE_CV = '/gestione-cv';
export const HREF_VISUALIZZA_CV = '/visualizza-cv';

export const HREF_SH_AREA_PERSONALE = '/area-personale';
export const HREF_SH_MODIFICA_INFO = '/modifica-informazioni';
export const HREF_SH_DOCUMENTI = '/documenti';
export const HREF_SH_PRATICHE = '/pratiche';
export const HREF_SH_BANDI_CONCORSI = '/bandi-e-concorsi';

export const HREF_NOT_AUTHORIZED = '/sessione-scaduta';

export const HREF_CONVALIDA_DATI = '/convalida-dati';

export const HREF_GESTIONE_CONTRATTUALIZZAZIONE = '/gestione-contrattualizzazione';

export const HREF_GESTIONE_RINUNCIA = '/gestione-rinuncia';

export const HREF_CONFERIMENTO_DOCUMENTI = '/conferimento-documenti';

export const HREF_VERIFICA_DATI = '/verifica-dati';

export const HREF_VALIDAZIONE_DATI_DOCUMENTI = '/validazione-dati-documenti';

export const HREF_VERIFICA_INTEGRAZIONE_DATI = '/verifica-integrazione-dati';

export const HREF_INSERIMENTO_DATI_ASSUNZIONE = '/inserimento-dati-assunzione';

export const HREF_CONVOCAZIONE_SINGOLA = '/convocazione-singola';

//export const mappaCollapse = [];

export const mappaCollapse = [
  {
    collapse: COLLAPSE_NAME_GESTIONE_SO,
    items: [
      HREF_ELENCO_STRUTTURE_ORGANIZZATIVE,
      HREF_NUOVA_STRUTTURA_ORGANIZZATIVA,
      HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA,
      HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA,
      HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA,
      HREF_ASSEGNAZIONE_CODICE_CIFRA,
      HREF_ASSEGNAZIONE_CODICE_IPA,
      HREF_ASSEGNAZIONE_CODICE_BILANCIO,
      HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA,
      HREF_ASSEGNAZIONE_UTENZA_CIFRA,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_PO,
    items: [
      HREF_ELENCO_POSIZIONI_ORGANIZZATIVE,
      HREF_NUOVA_POSIZIONE_ORGANIZZATIVA,
      HREF_ELENCO_BANDI,
      HREF_NUOVO_BANDO,
      HREF_CANDIDATURA_ELENCO_BANDI,
      HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA,
      HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO,
      HREF_DOWNLOAD_NULLA_OSTA,
      HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA,
      HREF_UPLOAD_NOMINA,
      HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA,
      HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA,
      HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA,
      HREF_ELENCO_RICHIESTE_NULLA_OSTA,
      HREF_ELENCO_ASSEGNAZIONI,
      HREF_CANDIDATURA_NULLA_OSTA,
      HREF_CANDIDATURA_VISUALIZZA_BANDO,
      HREF_CANDIDATURA_DETTAGLIO_NULLA_OSTA,
      HREF_ELENCO_CANDIDATURE,
      HREF_CANDIDATURA_ELENCO_CANDIDATURE,
      HREF_MODIFICA_BANDO,
      HREF_UPLOAD_GESTIONE_MASSIVA,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_RUOLI_FUNZIONALITA,
    items: [
      HREF_ELENCO_RUOLI,
      HREF_NUOVO_RUOLO,
      HREF_MODIFICA_RUOLO,
      HREF_GESTIONE_NOTIFICHE,
      HREF_NUOVA_CONFIGURAZIONE_NOTIFICA,
      HREF_MODIFICA_CONFIGURAZIONE_NOTIFICA,
      HREF_GESTIONE_RUOLO_FUNZIONALITA,
      HREF_GESTIONE_RUOLO_UTENTI,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_CONVENZIONI,
    items: [
      HREF_ELENCO_CONVENZIONI,
      HREF_VISUALIZZA_CONVENZIONE,
      HREF_NUOVA_CONVENZIONE,
      HREF_MODIFICA_CONVENZIONE,
    ],
  },
  {
    collapse: COLLASPE_REGISTRO_IMPRESE,
    items: [HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA, HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_CANDIDATI,
    items: [
      HREF_ELENCO_CANDIDATI,
      HREF_INSERIMENTO_CANDIDATO,
      HREF_MODIFICA_CANDIDATO,
      HREF_CONF_AREA_DOCUMENTI,
      HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO,
      HREF_VERIFICA_DATI_DOCUMENTI_CANDIDATO,
      HREF_COM_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO,
      HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO,
      HREF_GEST_ACCETTAZIONE_CONTRATTO,
      HREF_GEST_CESSAZIONE_CONTRATTO,
      HREF_MOD_DOCUMENTI_CANDIDATO,
      HREF_VISUALIZZA_CANDIDATO,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_DIPENDENTI,
    items: [
      HREF_ELENCO_DIPENDENTI_REG,
      HREF_VISUALIZZA_CESSAZIONE_DIPENDENTE,
      HREF_CESSAZIONE_DIPENDENTE,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_INCARICHI_DIRIGENZIALI,
    items: [
      HREF_ELENCO_INCARICHI_DIRIGENZIALI,
      HREF_NUOVA_PROPOSTA_INCARICO,
      HREF_MODIFICA_PROPOSTA_INCARICO,
      HREF_VALUTA_PROPOSTA_INCARICO,
      HREF_INSERIMENTO_PARERE_GIUNTA,
      HREF_ASSEGNA_INCARICO,
      HREF_MODIFICA_INCARICO,
      HREF_VISUALIZZA_INCARICO,
    ],
  },
  {
    collapse: COLLAPSE_NAME_RUBRICA,
    items: [
      HREF_RUBRICA_ELENCO_DIPENDENTI,
      HREF_RUBRICA_INSERISCI_TEL_CRED,
      HREF_RUBRICA_INSERISCI_EMAIL_DIPENDENTE,
      HREF_RUBRICA_MODIFICA_UTENZE,
      HREF_RUBRICA_VISUALIZZA_UTENZE,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_CONSULENTE_ESTERNO,
    items: [
      HREF_CONSULENTE_ESTERNO_ELENCO,
      HREF_CONSULENTE_ESTERNO_INSERISCI,
      HREF_CONSULENTE_ESTERNO_MODIFICA,
      HREF_CONSULENTE_ESTERNO_DETTAGLIO,
    ],
  },
  {
    collapse: COLLAPSE_NAME_GESTIONE_ABILITAZIONI,
    items: [
      HREF_ABILITAZIONI_ELENCO_RICHIESTE,
      HREF_ABILITAZIONI_NUOVA_RICHIESTA,
      HREF_ABILITAZIONI_MODIFICA_RICHIESTA,
      HREF_ABILITAZIONI_DETTAGLIO_RICHIESTA,
      HREF_ABILITAZIONI_APPROVAZIONE_ATTIVAZIONE,
    ],
  },
  { collapse: COLLASPE_NAME_GESTIONE_CV, items: [HREF_GESTIONE_CV, HREF_VISUALIZZA_CV] },
];
