import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BreadcrumbItemModel } from '../components/common/custom-breadcrumb/BreacrumbModel';
import { GestioneOrganigrammaControllerApi } from '../services/ms-anagrafica-unica/api/gestione-organigramma-controller-api';
import { DipartimentoDto } from '../services/ms-anagrafica-unica/model';
import { PagedDtoDipartimentoDto } from '../services/ms-anagrafica-unica/model/paged-dto-dipartimento-dto';
import {
  StrutturaOrganizzativaOrganigrammaDto,
  StrutturaOrganizzativaOrganigrammaDtoTipoEnum,
} from '../services/ms-anagrafica-unica/model/struttura-organizzativa-organigramma-dto';
import { RootState } from './store';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';

export interface OrganigrammaState {
  elencoDipartimentiResult: PagedDtoDipartimentoDto;
  elencoDipartimentiStatus: string;
  elencoDipartimentiInput: SearchOrganigrammaRequest | null;
  elencoDipartimentiStoricoInput: SearchOrganigrammaStoricoRequest | null;
  elencoDipartimentiStoricoResult: PagedDtoDipartimentoDto;
  elencoDipartimentiStoricoStatus: string;
  dipartimentoSelezionato: DipartimentoDto | null;
  dipartimentoResult: StrutturaOrganizzativaOrganigrammaDto | null;
  dipartimentoStatus: string;
  dipartimentoInput: SearchOrganigrammaRequest | null;
  soFigliaResult: StrutturaOrganizzativaOrganigrammaDto | null;
  soFigliaStatus: string;
  soFigliaInput: SearchOrganigrammaRequest | null;
  breadcrumb: Array<BreadcrumbItemModel>;
  strutturaOrganizzativaAttiva: StrutturaOrganizzativaOrganigrammaDto | null;
  organigrammaStorico: Boolean;
  fromOrganigrammaPage: Boolean;
  dataStorico: Date | undefined;
  dipartimentoStoricoInput: SearchOrganigrammaStoricoRequest | null;
  dipartimentoStoricoResult: StrutturaOrganizzativaOrganigrammaDto | null;
  dipartimentoStoricoStatus: string;
  soFigliaStoricoResult: StrutturaOrganizzativaOrganigrammaDto | null;
  soFigliaStoricoStatus: string;
  soFigliaStoricoInput: SearchOrganigrammaStoricoRequest | null;
}

const initialState: OrganigrammaState = {
  elencoDipartimentiResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  elencoDipartimentiStatus: '',
  elencoDipartimentiInput: null,
  elencoDipartimentiStoricoResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  elencoDipartimentiStoricoStatus: '',
  elencoDipartimentiStoricoInput: null,
  dipartimentoSelezionato: null,
  dipartimentoResult: null,
  dipartimentoStatus: '',
  dipartimentoInput: null,
  soFigliaResult: null,
  soFigliaStatus: '',
  soFigliaInput: null,
  breadcrumb: [],
  strutturaOrganizzativaAttiva: null,
  organigrammaStorico: false,
  fromOrganigrammaPage: false,
  dataStorico: undefined,
  dipartimentoStoricoResult: null,
  dipartimentoStoricoStatus: '',
  dipartimentoStoricoInput: null,
  soFigliaStoricoResult: null,
  soFigliaStoricoStatus: '',
  soFigliaStoricoInput: null,
};

export interface SearchOrganigrammaRequest {
  pageSize?: number;
  pageNum?: number;
  sort?: string;
  direction?: 'ASC' | 'DESC';
  q?: string;
  idStrutturaOrganizzativa?: string;
}

export interface SearchOrganigrammaStoricoRequest {
  pageSize?: number;
  pageNum?: number;
  sort?: string;
  direction?: 'ASC' | 'DESC';
  q?: string;
  idStrutturaOrganizzativa?: string;
  dataInput?: Date;
}

export const loadDipartimenti = createAsyncThunk('organigramma/loadDipartimenti', async () => {
  const api = new GestioneOrganigrammaControllerApi(MS_AU_CONFIG);
  const response = await api.searchOrganigramma({
    direction: 'ASC',
  });
  return response.data;
});

export const loadDipartimentiStorico = createAsyncThunk(
  'organigramma/loadDipartimentiStorico',
  async (args: string) => {
    const api = new GestioneOrganigrammaControllerApi(MS_AU_CONFIG);

    const response = await api.elencoStoricoDipartimenti({
      direction: 'ASC',
      dataInput: args,
    });
    return response.data;
  }
);

export const detailsStrutturaOrganizzativa = createAsyncThunk<
  StrutturaOrganizzativaOrganigrammaDto,
  { idStrutturaOrganizzativa: string; setAttiva: boolean },
  { state: RootState }
>('organigramma/detailsStrutturaOrganizzativa', async (data, { getState, dispatch }) => {
  const api = new GestioneOrganigrammaControllerApi(MS_AU_CONFIG);
  const response = await api.searchByIdOrganigramma({
    idStrutturaOrganizzativa: data.idStrutturaOrganizzativa,
  });
  if (response.data && data.setAttiva) {
    dispatch(setDettaglioStrutturaAttiva(response.data));
    dispatch(setBreadcrumbItem(response.data));
  }
  return response.data;
});

export const detailsStrutturaOrganizzativaStorico = createAsyncThunk<
  StrutturaOrganizzativaOrganigrammaDto,
  { idStrutturaOrganizzativa: string; setAttiva: boolean; dataInput: string },
  { state: RootState }
>('organigramma/detailsStrutturaOrganizzativaStorico', async (data, { getState, dispatch }) => {
  const api = new GestioneOrganigrammaControllerApi(MS_AU_CONFIG);
  const response = await api.getSOStorico({
    idStrutturaOrganizzativa: data.idStrutturaOrganizzativa,
    dataInput: data.dataInput,
  });
  if (response.data && data.setAttiva) {
    dispatch(setDettaglioStrutturaAttiva(response.data));
    dispatch(setBreadcrumbItem(response.data));
  }
  return response.data;
});

export const setDipartimentoSelezionato = createAction<DipartimentoDto>(
  'organigramma/setDipartimentoSelezionato'
);
export const setOrganigrammaStorico = createAction<Boolean>('organigramma/setOrganigrammaStorico');
export const setFromOrganigrammaPage = createAction<Boolean>(
  'organigramma/setFromOrganigrammaPage'
);
export const setDataStorico = createAction<Date>('organigramma/setDataStorico');
export const setDettaglioStrutturaAttiva = createAction<StrutturaOrganizzativaOrganigrammaDto>(
  'organigramma/setDettaglioStrutturaAttiva'
);
export const emptyBreadcrumb = createAction('organigramma/emptyBreadcrumb');
export const setBreadcrumbItem = createAction<
  StrutturaOrganizzativaOrganigrammaDto | DipartimentoDto
>('organigramma/setBreadcrumbItem');
export const reset = createAction('organigramma /reset');

export const organigrammaSlice = createSlice({
  name: 'organigrammaSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // reset
    builder.addCase(reset, (state: OrganigrammaState, action: any) => {
      state.dipartimentoSelezionato = initialState.dipartimentoSelezionato;
      state.breadcrumb = initialState.breadcrumb;
      state.dipartimentoInput = initialState.dipartimentoInput;
      state.dipartimentoResult = initialState.dipartimentoResult;
      state.dipartimentoStatus = initialState.dipartimentoStatus;
      state.dipartimentoStoricoInput = initialState.dipartimentoStoricoInput;
      state.dipartimentoStoricoResult = initialState.dipartimentoStoricoResult;
      state.dipartimentoStoricoStatus = initialState.dipartimentoStoricoStatus;
      state.elencoDipartimentiStoricoInput = initialState.elencoDipartimentiStoricoInput;
      state.elencoDipartimentiStoricoResult = initialState.elencoDipartimentiStoricoResult;
      state.elencoDipartimentiStoricoStatus = initialState.elencoDipartimentiStoricoStatus;
      state.soFigliaInput = initialState.soFigliaInput;
      state.soFigliaResult = initialState.soFigliaResult;
      state.soFigliaStatus = initialState.soFigliaStatus;
      state.strutturaOrganizzativaAttiva = initialState.strutturaOrganizzativaAttiva;
    });

    // salvo il dipartimento selezionato nella pagina di organigramma
    builder.addCase(setDipartimentoSelezionato, (state: OrganigrammaState, action: any) => {
      state.dipartimentoSelezionato = action.payload;
    });

    // salvo il dettaglio della struttura organizzativa selezionato
    builder.addCase(setDettaglioStrutturaAttiva, (state: OrganigrammaState, action: any) => {
      state.strutturaOrganizzativaAttiva = action.payload;
    });

    // salvo il booleano che indica se storico
    builder.addCase(setOrganigrammaStorico, (state: OrganigrammaState, action: any) => {
      state.organigrammaStorico = action.payload;
    });

    // salvo il dettaglio della data dello storico
    builder.addCase(setDataStorico, (state: OrganigrammaState, action: any) => {
      state.dataStorico = action.payload;
    });

    builder.addCase(setFromOrganigrammaPage, (state: OrganigrammaState, action: any) => {
      state.fromOrganigrammaPage = action.payload;
    });

    // BREADCRUMB
    builder.addCase(emptyBreadcrumb, (state: OrganigrammaState) => {
      while (state.breadcrumb.length) {
        state.breadcrumb.pop();
      }
    });

    builder.addCase(setBreadcrumbItem, (state: OrganigrammaState, action: any) => {
      const newBreadcrumb: Array<BreadcrumbItemModel> = [];
      let soAttiva = state.strutturaOrganizzativaAttiva;
      // If the active organization structure is of type "SECTION" or "SERVICE",
      // the method is entered after two calls to the BE: one to retrieve the information of the "SECTION"/"SERVICE" and
      // one to retrieve the information related to the department (in the second case, "action" contains the department information).

      if (soAttiva?.id !== action.payload.id) {
        newBreadcrumb.unshift({
          nome: soAttiva?.descrizioneBreve ?? '',
          id: soAttiva?.id ?? '',
          tipo: soAttiva?.tipo ?? '',
        });

        newBreadcrumb.unshift({
          nome: soAttiva?.descrizioneBrevePadre ?? '',
          id: soAttiva?.codiceIncaricoPadre ?? '',
          tipo: soAttiva?.tipo === 'SEZIONE' ? 'DIPARTIMENTO' : 'SEZIONE',
        });

        if (soAttiva?.tipo === 'SERVIZIO') {
          newBreadcrumb.unshift({
            nome: action.payload.descrizioneBreve ?? '',
            id: action.payload.id ?? '',
            tipo: 'DIPARTIMENTO',
          });
        }
      } else {
        newBreadcrumb.unshift({
          nome: action.payload.descrizioneBreve ?? '',
          id: action.payload.id ?? '',
          tipo: action.payload.tipo ?? '',
        });
        if (action.payload.tipo !== 'DIPARTIMENTO') {
          newBreadcrumb.unshift({
            nome: soAttiva?.descrizioneBrevePadre ?? '',
            id: soAttiva?.codiceIncaricoPadre ?? '',
            tipo: soAttiva?.tipo === 'SEZIONE' ? 'DIPARTIMENTO' : 'SEZIONE',
          });

          if (soAttiva?.tipo === 'SERVIZIO' && state.dipartimentoResult) {
            newBreadcrumb.unshift({
              nome: state.dipartimentoResult.descrizioneBreve ?? '',
              id: state.dipartimentoResult.id ?? '',
              tipo: 'DIPARTIMENTO',
            });
          }
        }
      }
      state.breadcrumb = newBreadcrumb;
    });

    // CARICAMENTO DIPARTIMENTI NELLA HOME DELL'ORGANIGRAMMA
    builder.addCase(loadDipartimenti.pending, (state: OrganigrammaState, action: any) => {
      state.elencoDipartimentiStatus = STATUS_PENDING;
    });
    builder.addCase(loadDipartimenti.fulfilled, (state: OrganigrammaState, action: any) => {
      state.elencoDipartimentiResult = action.payload;
      state.elencoDipartimentiStatus = STATUS_FULLFILLED;
      state.elencoDipartimentiInput = action.meta.arg;
    });
    builder.addCase(loadDipartimenti.rejected, (state: OrganigrammaState, action: any) => {
      state.elencoDipartimentiStatus = STATUS_REJECTED;
    });

    // CARICAMENTO DIPARTIMENTI NELLA HOME DELL'ORGANIGRAMMA --STORICO
    builder.addCase(loadDipartimentiStorico.pending, (state: OrganigrammaState, action: any) => {
      state.elencoDipartimentiStoricoStatus = STATUS_PENDING;
    });
    builder.addCase(loadDipartimentiStorico.fulfilled, (state: OrganigrammaState, action: any) => {
      state.elencoDipartimentiStoricoResult = action.payload;
      state.elencoDipartimentiStoricoStatus = STATUS_FULLFILLED;
      state.elencoDipartimentiStoricoInput = action.meta.arg;
    });
    builder.addCase(loadDipartimentiStorico.rejected, (state: OrganigrammaState, action: any) => {
      state.elencoDipartimentiStoricoStatus = STATUS_REJECTED;
    });

    // CARICAMENTO DEI DETTAGLI DELLA STRUTTURA ORGANIZZATIVA SELEZIONATA
    // Tengo conto del parametro passato alla action relativo al tipo di struttura che si desidera recuperare
    // in modo da non sovrascrivere le informazioni sul dipartimento quando si recuperano le strutture di tipo
    // sezione o servizio.
    builder.addCase(
      detailsStrutturaOrganizzativa.pending,
      (state: OrganigrammaState, action: any) => {
        if (
          action.meta.arg.tipo &&
          action.meta.arg.tipo === StrutturaOrganizzativaOrganigrammaDtoTipoEnum.Dipartimento
        ) {
          state.dipartimentoStatus = STATUS_PENDING;
        } else {
          state.soFigliaStatus = STATUS_PENDING;
        }
      }
    );
    builder.addCase(
      detailsStrutturaOrganizzativa.fulfilled,
      (state: OrganigrammaState, action: any) => {
        if (action.payload.tipo === StrutturaOrganizzativaOrganigrammaDtoTipoEnum.Dipartimento) {
          state.dipartimentoResult = action.payload;
          state.dipartimentoStatus = STATUS_FULLFILLED;
          state.dipartimentoInput = action.meta.arg;
        } else {
          state.soFigliaResult = action.payload;
          state.soFigliaStatus = STATUS_FULLFILLED;
          state.soFigliaInput = action.meta.arg;
        }
      }
    );
    builder.addCase(
      detailsStrutturaOrganizzativa.rejected,
      (state: OrganigrammaState, action: any) => {
        if (
          action.meta.arg.tipo &&
          action.meta.arg.tipo === StrutturaOrganizzativaOrganigrammaDtoTipoEnum.Dipartimento
        ) {
          state.dipartimentoStatus = STATUS_REJECTED;
        } else {
          state.soFigliaStatus = STATUS_REJECTED;
        }
      }
    );

    //DETTAGLIO STORICO
    builder.addCase(
      detailsStrutturaOrganizzativaStorico.pending,
      (state: OrganigrammaState, action: any) => {
        if (
          action.meta.arg.tipo &&
          action.meta.arg.tipo === StrutturaOrganizzativaOrganigrammaDtoTipoEnum.Dipartimento
        ) {
          state.dipartimentoStoricoStatus = STATUS_PENDING;
        } else {
          state.soFigliaStoricoStatus = STATUS_PENDING;
        }
      }
    );
    builder.addCase(
      detailsStrutturaOrganizzativaStorico.fulfilled,
      (state: OrganigrammaState, action: any) => {
        if (action.payload.tipo === StrutturaOrganizzativaOrganigrammaDtoTipoEnum.Dipartimento) {
          state.dipartimentoStoricoResult = action.payload;
          state.dipartimentoStoricoStatus = STATUS_FULLFILLED;
          state.dipartimentoStoricoInput = action.meta.arg;
        } else {
          state.soFigliaStoricoResult = action.payload;
          state.soFigliaStoricoStatus = STATUS_FULLFILLED;
          state.soFigliaStoricoInput = action.meta.arg;
        }
      }
    );
    builder.addCase(
      detailsStrutturaOrganizzativaStorico.rejected,
      (state: OrganigrammaState, action: any) => {
        if (
          action.meta.arg.tipo &&
          action.meta.arg.tipo === StrutturaOrganizzativaOrganigrammaDtoTipoEnum.Dipartimento
        ) {
          state.dipartimentoStoricoStatus = STATUS_REJECTED;
        } else {
          state.soFigliaStoricoStatus = STATUS_REJECTED;
        }
      }
    );
  },
});

// export const {} = organigrammaSlice.actions;

export default organigrammaSlice.reducer;
