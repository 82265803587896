/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { AllegatiCvRequestDto } from './allegati-cv-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CapacitaCompetenzePersonaliRequestDto } from './capacita-competenze-personali-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EsperienzeProfessionaliInRegioneRequestDto } from './esperienze-professionali-in-regione-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EsperienzeProfessionaliRequestDto } from './esperienze-professionali-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { InformazioniPersonaliRequestDto } from './informazioni-personali-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { IstruzioneFormazioneRequestDto } from './istruzione-formazione-request-dto';

/**
 *
 * @export
 * @interface CurriculumVitaeRequestDto
 */
export interface CurriculumVitaeRequestDto {
  /**
   *
   * @type {InformazioniPersonaliRequestDto}
   * @memberof CurriculumVitaeRequestDto
   */
  informazioniPersonali?: InformazioniPersonaliRequestDto;
  /**
   *
   * @type {Array<EsperienzeProfessionaliRequestDto>}
   * @memberof CurriculumVitaeRequestDto
   */
  elencoEsperienzeProfessionali?: Array<EsperienzeProfessionaliRequestDto>;
  /**
   *
   * @type {Array<EsperienzeProfessionaliInRegioneRequestDto>}
   * @memberof CurriculumVitaeRequestDto
   */
  elencoEsperienzeProfessionaliInRegione?: Array<EsperienzeProfessionaliInRegioneRequestDto>;
  /**
   *
   * @type {Array<IstruzioneFormazioneRequestDto>}
   * @memberof CurriculumVitaeRequestDto
   */
  elencoIstruzioneFormazione?: Array<IstruzioneFormazioneRequestDto>;
  /**
   *
   * @type {CapacitaCompetenzePersonaliRequestDto}
   * @memberof CurriculumVitaeRequestDto
   */
  capacitaCompetenzePersonali?: CapacitaCompetenzePersonaliRequestDto;
  /**
   *
   * @type {Array<AllegatiCvRequestDto>}
   * @memberof CurriculumVitaeRequestDto
   */
  elencoAllegati?: Array<AllegatiCvRequestDto>;
  /**
   *
   * @type {string}
   * @memberof CurriculumVitaeRequestDto
   */
  sezione?: CurriculumVitaeRequestDtoSezioneEnum;
}

export const CurriculumVitaeRequestDtoSezioneEnum = {
  InformazioniPersonali: 'INFORMAZIONI_PERSONALI',
  EsperienzeProfessionali: 'ESPERIENZE_PROFESSIONALI',
  EsperienzeProfessionaliInRegione: 'ESPERIENZE_PROFESSIONALI_IN_REGIONE',
  IstruzioneFormazione: 'ISTRUZIONE_FORMAZIONE',
  CompetenzePersonali: 'COMPETENZE_PERSONALI',
  Allegati: 'ALLEGATI',
  Conferma: 'CONFERMA',
} as const;

export type CurriculumVitaeRequestDtoSezioneEnum =
  (typeof CurriculumVitaeRequestDtoSezioneEnum)[keyof typeof CurriculumVitaeRequestDtoSezioneEnum];
