import { Button, Icon, Input, Row } from 'design-react-kit';
import CandidatoDetailsComponent from '../../../components/candidato-detail-form/CandidatoDetailsComponent';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { ricercaCandidato } from '../../../store/candidatoSlice';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import {
  NOME,
  COGNOME,
  SESSO,
  DATA_NASCITA,
  CITTA_NASCITA,
  CODICE_FISCALE,
  CITTADINANZA,
  CITTA_RESIDENZA,
  PROVINCIA,
  CAP,
  INDIRIZZO_RESIDENZA,
  STATO_CIVILE,
  NUM_CARTA_IDENTITA,
} from '../candidatiConstants';
import { showNotifica } from '../../../store/loaderSlice';
import { NOTIFICA_STATO } from '../../../utility/Notifica';
import { useNavigate } from 'react-router-dom';
import { HREF_INSERIMENTO_CANDIDATO } from 'components/sidemenu/sidemenuConstants';

const initialValuesCodiceFiscale = {
  codiceFiscale: '',
};

const validationSchemaCodiceFiscale = z.object({
  codiceFiscale: z
    .string({ required_error: 'Inserisci il Codice Fiscale' })
    .max(16, 'Il Codice Fiscale deve essere composto esattamente da 16 caratteri'),
});

export default function TrovaCandidato() {
  const navigate = useNavigate();
  const [codiceFiscale, setCodiceFiscale] = useState<string | undefined>(undefined);
  const [utenteRecuperato, setUtenteRecuperato] = useState(false);
  const { detailsResult, ricercaCandidatoResult } = useAppSelector((state) => state.candidato);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const findCodiceFiscale = async () => {
      if (!codiceFiscale) return;
      try {
        const response = await dispatch(
          ricercaCandidato({
            codiceFiscale,
          })
        );

        // @ts-ignore
        if (response.payload?.codiceFiscale) {
          navigate(`${HREF_INSERIMENTO_CANDIDATO}/${codiceFiscale}`);
          setUtenteRecuperato(true);
        } else {
          setUtenteRecuperato(false);
          dispatch(
            showNotifica({
              titolo: 'CODICE FISCALE NON TROVATO',
              stato: 'error',
              messaggio: 'Il codice fiscale inserito non è presente in ANPR',
              tipo: NOTIFICA_STATO.error,
            })
          );
        }
      } catch (error) {
        dispatch(
          showNotifica({
            titolo: 'CODICE FISCALE NON TROVATO',
            stato: 'error',
            messaggio: 'Il codice fiscale inserito non è presente in ANPR',
            tipo: NOTIFICA_STATO.error,
          })
        );
      }
    };
    findCodiceFiscale();
  }, [codiceFiscale, dispatch, navigate]);

  const handleConfermaCandidato = () => {
    navigate(`${HREF_INSERIMENTO_CANDIDATO}/${codiceFiscale}`);
  };

  const handleInserisciCandidato = () => {
    navigate(`${HREF_INSERIMENTO_CANDIDATO}`);
  };

  const form = useFormik({
    initialValues: initialValuesCodiceFiscale,
    validationSchema: toFormikValidationSchema(validationSchemaCodiceFiscale),
    onSubmit: async ({ codiceFiscale }, { setSubmitting, setErrors }) => {
      setCodiceFiscale(codiceFiscale);

      try {
        const response = await dispatch(
          ricercaCandidato({
            codiceFiscale,
          })
        );

        // @ts-ignore
        if (response.payload?.codiceFiscale) {
          setUtenteRecuperato(true);
        } else {
          setErrors({
            codiceFiscale: 'A validation error occurred.',
          });
          setUtenteRecuperato(false);
          dispatch(
            showNotifica({
              titolo: 'CODICE FISCALE NON TROVATO',
              stato: 'error',
              messaggio: 'Il codice fiscale inserito non è presente in ANPR',
              tipo: NOTIFICA_STATO.error,
            })
          );
        }
      } catch (error) {
        setErrors({
          codiceFiscale: 'A validation error occurred.',
        });
        dispatch(
          showNotifica({
            titolo: 'CODICE FISCALE NON TROVATO',
            stato: 'error',
            messaggio: 'Il codice fiscale inserito non è presente in ANPR',
            tipo: NOTIFICA_STATO.error,
          })
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      {utenteRecuperato ? (
        <div className="row">
          <CandidatoDetailsComponent
            value={detailsResult! || { anagraficaUnica: ricercaCandidatoResult }}
            fileds={[
              NOME,
              COGNOME,
              SESSO,
              DATA_NASCITA,
              CITTA_NASCITA,
              CODICE_FISCALE,
              CITTADINANZA,
              CITTA_RESIDENZA,
              PROVINCIA,
              CAP,
              INDIRIZZO_RESIDENZA,
              STATO_CIVILE,
              NUM_CARTA_IDENTITA,
            ]}
          />
        </div>
      ) : (
        <form onSubmit={form.handleSubmit}>
          <div
            className="p-3 mb-4"
            style={{
              height: '250px',
              width: '100%',
              borderRadius: '12px',
              border: '1px solid #06c',
            }}
          >
            <h6
              style={{
                textTransform: 'uppercase',
                fontSize: '14px',
                marginBottom: '40px',
                color: '#06c',
              }}
            >
              Trova Candidato
            </h6>

            <Row>
              <Input
                label="Codice fiscale"
                type="text"
                placeholder="Inserisci il codice fiscale"
                id="input-codiceFiscale"
                wrapperClassName="required col-md-12"
                name="codiceFiscale"
                disabled={
                  detailsResult?.anagraficaUnica != null &&
                  detailsResult.anagraficaUnica.codiceFiscale !== null &&
                  detailsResult.anagraficaUnica.codiceFiscale !== ''
                }
                value={form.values.codiceFiscale}
                onChange={form.handleChange}
                maxLength={16}
                minLength={16}
                invalid={!!(form.touched.codiceFiscale && form.errors.codiceFiscale)}
              />
              {form.touched.codiceFiscale && form.errors.codiceFiscale && (
                <div id="codiceFiscaleError" className="invalid-feedback">
                  {form.errors.codiceFiscale}
                </div>
              )}
            </Row>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '10px',
              }}
            >
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                type="submit"
                disabled={
                  detailsResult?.anagraficaUnica != null &&
                  detailsResult.anagraficaUnica.codiceFiscale !== null &&
                  detailsResult.anagraficaUnica.codiceFiscale !== ''
                }
              >
                Recupera Dati
              </Button>
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                onClick={() => {
                  form.resetForm();
                }}
                disabled={form.values.codiceFiscale === ''}
              >
                Pulisci
              </Button>
            </div>
          </div>
        </form>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          className="steppers-btn-next"
          color="primary"
          outline
          size="sm"
          onClick={() => {
            navigate('/elenco-candidati');
          }}
        >
          Annulla
        </Button>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            className="steppers-btn-next"
            color="primary"
            outline
            size="sm"
            disabled={!utenteRecuperato && !form.errors.codiceFiscale}
            onClick={() => {
              form.resetForm();
              setUtenteRecuperato(false);
            }}
          >
            Cerca nuovo CF
          </Button>
          {form.errors.codiceFiscale ? (
            <Button
              className="steppers-btn-next"
              color="primary"
              outline
              size="sm"
              onClick={handleInserisciCandidato}
            >
              Inserisci candidato manualmente
              <Icon color="primary" icon="it-chevron-right" />
            </Button>
          ) : (
            <Button
              className="steppers-btn-next"
              color="primary"
              size="sm"
              onClick={handleConfermaCandidato}
              disabled={!utenteRecuperato}
            >
              <>Conferma Candidato </>
              <Icon color="white" icon="it-arrow-right" size="sm" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
