import MandatoryModal from 'components/mandatory-modal/MandatoryModal';
import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { useCompositeForm } from 'hooks/useCompositeForm';
//@ts-ignore
import pdf from '../../assets/pdf/InformativaPrivacyConcorso.pdf';
import { useMandatoryViewModal } from 'hooks/useMandatoryViewModal';
import ExplodedPdfViewer from 'components/exploded-pdf-viewer/ExplodedPdfViewer';

const FormPrivacy = () => {
  const { values, handleChange, setFieldValue, errors, touched } = useCompositeForm();
  const { toggleModal, handleAcceptClick, handleScroll, dialogRef, nextBtnActive, isPdfModalOpen } =
    useMandatoryViewModal({
      callbackFn: () => {
        setFieldValue('trattamentoDatiPersonali', true);
      },
    });

  return (
    <>
      <Button
        className="w-100 d-flex justify-content-between mb-3"
        color="primary"
        onClick={toggleModal}
      >
        Presa visione privacy
        <Icon icon="it-arrow-right" color="white" />
      </Button>
      {/* <Button onClick={toggleModal}>Presa visione privacy</Button> */}
      <Modal
        isOpen={isPdfModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={'modal-privacy'}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={'modal-privacy'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" icon="it-close" />
              <h2>Anteprima documento</h2>
            </div>
            <div className="d-flex align-items-center gap-2">
              {nextBtnActive ? (
                <>
                  <Icon size="sm" color="success" icon="it-check" />
                  <div>Visualizzato</div>
                </>
              ) : (
                <>
                  <Icon size="sm" color="primary" icon="it-clock" />
                  <div>Da visualizzare</div>
                </>
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            onScroll={handleScroll}
            ref={dialogRef}
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '70vh',
              overflowY: 'auto',
            }}
          >
            <ExplodedPdfViewer pdfFile={pdf} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color="primary"
            outline
            className="link-underline-primary"
            onClick={() => toggleModal()}
          >
            Annulla
          </Button>
          <Button color="primary" disabled={!nextBtnActive} onClick={handleAcceptClick}>
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormPrivacy;
