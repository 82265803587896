import sidemenuReducer from './sidemenuSlice';
import strutturaOrganizzativaReducer from './strutturaOrganizzativaSlice';
import posizioneOrganizzativaReducer from './posizioneOrganizzativaSlice';
import ruoloReducer from './ruoloSlice';
import organigrammaReducer from './organigrammaSlice';
import configurazioniNotificheReducer from './configurazioniNotificheSlice';
import candidatoReducer from './candidatoSlice';
import notificheReducer from './notificheSlice';
import dipendentiRegionaliReducer from './dipendentiRegionaliSlice';
import consulentiEsterniReducer from './consulentiEsterniSlice';
import proposteIncarichiReducer from './proposteIncarichiSlice';
import userSlice from './userSlice';
import convenzioniReducer from './ConvenzioniSlice';
import registroImpreseReducer from './RegistroImpreseSlice';

import { configureStore } from '@reduxjs/toolkit';
import documentiReducer from './DocumentiSlice';
import rubricaReducer from './rubricaSlice';
import funzionalitaApplicativeReducer from './funzionalitaApplicativeSlice';
import StakeholderReducer from './stakeholderSlice';
import loaderReducer from './loaderSlice';
import bandoReducer from './bandoSlice';
import curriculumVitaeReducer from './curriculumVitaeSlice';
import gestioneMassivaReducer from './gestioneMassivaSlice';

export const store = configureStore({
  reducer: {
    sidemenu: sidemenuReducer,
    strutturaOrganizzativa: strutturaOrganizzativaReducer,
    posizioneOrganizzativa: posizioneOrganizzativaReducer,
    ruolo: ruoloReducer,
    organigramma: organigrammaReducer,
    configurazioniNotifiche: configurazioniNotificheReducer,
    candidato: candidatoReducer,
    notifiche: notificheReducer,
    dipendentiRegionali: dipendentiRegionaliReducer,
    proposteIncarichi: proposteIncarichiReducer,
    user: userSlice,
    documenti: documentiReducer,
    convenzioni: convenzioniReducer,
    registroImprese: registroImpreseReducer,
    rubrica: rubricaReducer,
    consulentiEsterni: consulentiEsterniReducer,
    loader: loaderReducer,
    abilitazioni: funzionalitaApplicativeReducer,
    stakeholder: StakeholderReducer,
    bando: bandoReducer,
    curriculumVitae: curriculumVitaeReducer,
    gestioneMassiva: gestioneMassivaReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // AxiosHeaders not serializeable
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
