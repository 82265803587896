import { useParams } from 'react-router-dom';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import FormCv from 'components/FormCv/FormCv';
import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormNote from 'components/FormNote/FormNote';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import { FormReadingOnly } from 'components/forms/FormReadingOnly/FormReadingOnly';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import FormGeneraContratto from 'components/forms/FormGeneraContratto/FormGeneraContratto';
import { FormNoteUfficioReclutamento } from 'pages/gestione-contrattualizzazione/GestioneContrattualizzazione';

const VisualizzaCandidato = () => {
  const { codiceFiscale } = useParams();
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={candidato ? `${candidato.nome} ${candidato.cognome}` : 'Visualizza candidato'}
      />
      <FormReadingOnly>
        <FormDatiAnagrafici readingOnly />
        <FormEstremiIdentificazione readingOnly />
        <FormRecapiti readingOnly />
        <FormDatiResidenza readingOnly />
        <FormDomicilio readingOnly />
        <FormDatiDisabilita readingOnly />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv readingOnly />
        <FormNote readingOnly />
      </FormReadingOnly>
      <FormNoteUfficioReclutamento />
      <FormGeneraContratto />
    </div>
  );
};

export default VisualizzaCandidato;