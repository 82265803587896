// @ts-nocheck
import { Select } from 'design-react-kit';

export interface CustomSelectItem {
  value: string | number | null;
  label: string;
}

interface CustomSelectProps {
  label?: string;
  options: Array<CustomSelectItem>;
  value: string | number | undefined;
  placeholder?: string | null;
  onChange: (item: string) => void;
  wrapperClass?: string;
  name?: string;
  disabled?: boolean;
  invalid?: boolean;
  infoText?: string;
}

function CustomSelect(props: CustomSelectProps) {
  const onChangeSelect = (item: CustomSelectItem) => {
    props.onChange(item?.value || '');
  };

  let value = null;
  if (props.options) {
    value = props.options.find((option) => option.value === props.value) || null;
  }

  return (
    <div className={`form-group ${props.wrapperClass || ''}`}>
      <div className="bootstrap-select-wrapper">
        {props.label && <label>{props.label}</label>}
        <Select
          isDisabled={props.disabled}
          value={value}
          options={props.options}
          placeholder={props.placeholder}
          aria-label={props.placeholder}
          onChange={onChangeSelect}
          className={'opacity ' + (props.invalid ? ' is-invalid ' : '')}
          isClearable={true}
        />
        {!!props.infoText && (
          <small className={(props.invalid ? 'invalid-feedback' : '') + ' form-text text-muted'}>
            {props.infoText}
          </small>
        )}
      </div>
    </div>
  );
}

export default CustomSelect;
