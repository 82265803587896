import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import jwtInterceptor from './components/auth/jwtInterceptor';
import { saveTokenFromUri } from './utility/cookie';
import xsrfInterceptor from './components/auth/xsrfInterceptor';
import { GlobalWorkerOptions } from 'pdfjs-dist';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

GlobalWorkerOptions.workerSrc = `${process.env.REACT_APP_PUBLIC_URL}/pdf.worker.mjs`;

saveTokenFromUri();

jwtInterceptor(store.dispatch);
xsrfInterceptor();
root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
