import { createSlice } from '@reduxjs/toolkit';
import { Notifica } from '../utility/Notifica';

export interface LoaderState {
  loaderActive: number;
  notifica: Notifica | null;
}

const initialState: LoaderState = {
  loaderActive: 0,
  notifica: null,
};

export const LoaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loaderActive += 1;
    },
    hideLoader: (state) => {
      state.loaderActive -= 1;
    },
    showNotifica: (state, action) => {
      state.notifica = action.payload;
    },
  },
});

export const { showLoader, hideLoader, showNotifica } = LoaderSlice.actions;

export default LoaderSlice.reducer;
