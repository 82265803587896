/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OptionDtoTipoPosizioneOrganizzativa
 */
export interface OptionDtoTipoPosizioneOrganizzativa {
  /**
   *
   * @type {string}
   * @memberof OptionDtoTipoPosizioneOrganizzativa
   */
  value?: OptionDtoTipoPosizioneOrganizzativaValueEnum;
  /**
   *
   * @type {string}
   * @memberof OptionDtoTipoPosizioneOrganizzativa
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OptionDtoTipoPosizioneOrganizzativa
   */
  valore: OptionDtoTipoPosizioneOrganizzativaValoreEnum;
}

export const OptionDtoTipoPosizioneOrganizzativaValueEnum = {
  Po: 'PO',
  PoEquiparata: 'PO_EQUIPARATA',
} as const;

export type OptionDtoTipoPosizioneOrganizzativaValueEnum =
  (typeof OptionDtoTipoPosizioneOrganizzativaValueEnum)[keyof typeof OptionDtoTipoPosizioneOrganizzativaValueEnum];
export const OptionDtoTipoPosizioneOrganizzativaValoreEnum = {
  Po: 'PO',
  PoEquiparata: 'PO_EQUIPARATA',
} as const;

export type OptionDtoTipoPosizioneOrganizzativaValoreEnum =
  (typeof OptionDtoTipoPosizioneOrganizzativaValoreEnum)[keyof typeof OptionDtoTipoPosizioneOrganizzativaValoreEnum];
