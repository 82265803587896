import type { IFormCreaCandidatoForm } from 'components/crea-candidato-form/CreaCandidatoForm';
import type { ICandidatoConcorso } from 'hooks/services/useGetCandidatoByCodiceFiscale';
import type { IOnboardingConcorso } from 'hooks/services/useGetOnboardingById';
import downloadDocumento from './downloadDocumento';
import { DocumentoUploadedDto } from 'components/crea-candidato-form/types';

async function documentoUploadedDtoToFile(dto: DocumentoUploadedDto): Promise<File> {
  //console.log("Fetching file from:", dto.path);

  const response = await fetch(dto.path);
  if (!response.ok) {
    throw new Error(`Failed to fetch file from ${dto.path}`);
  }

  const blob = await response.blob();
  const file = new File([blob], dto.filename, { type: dto.contentType });

  //console.log("File created:", file);

  return file;
}

async function mapDtosToFiles(dtos: DocumentoUploadedDto[]) {
  const filePromises = dtos.map((dto) => documentoUploadedDtoToFile(dto));
  const files: File[] = await Promise.all(filePromises); // Resolve all Promises
  return files;
  //return await Promise.all(fileIdentificazionePromises);
}

export const getInitialValuesFromCandidato = async (
  initialValues: IFormCreaCandidatoForm,
  candidato?: ICandidatoConcorso,
  onboarding?: IOnboardingConcorso
): Promise<IFormCreaCandidatoForm> => {
  if (!candidato || !onboarding) {
    return initialValues;
  }

  const // Dati anagrafici
    {
      codiceFiscale,
      cognome,
      nome,
      genere,
      statoCivile,
      cittadinanza,
      dataNascita,
      nazioneNascita,
      provNascita,
      cittaNascita,
      // Estremi identificazione
      tipoDocumento,
      numeroCartaIdentita,
      enteEmittente,
      dataRilascio,
      dataScadenza,
      // Recapiti
      indirizzoPec,
      email,
      telefonoAbitazione,
      telefonoCellulare,
      // Dati residenza
      statoResidenza,
      provinciaResidenza,
      cittaResidenza,
      indirizzoResidenza,
      capResidenza,
      // Dati domicilio
      domicilioDifferente,
      statoDomicilio,
      provinciaDomicilio,
      cittaDomicilio,
      indirizzoDomicilio,
      capDomicilio,
      // Disabilità
      disabile,
      percentualeDisabilita,
      collocamentoMirato,
      // Titolo di studio
      titoloStudio,
      titoloDichiarato,
      noteTitolo,
      // Concorsi
    } = candidato;

  const { idConcorso, areaProfessionale, livello, vincitore, idoneo, posizioneGraduatoria, note } =
    onboarding;

  const fileIdentificazionePromises = onboarding?.elencoDocumenti
    .filter(
      (documento) =>
        documento.tipo === 'CARTA_IDENTITA' ||
        documento.tipo === 'PASSAPORTO' ||
        documento.tipo === 'PATENTE'
    )
    .map((documento) => documentoUploadedDtoToFile(documento.documentoDto));
  //.map(documento => documento.documentoDto)
  //.map((documento) => documentoUploadedDtoToFile(documento.documentoDto));

  //const filesIdentificazione = mapDtosToFiles(fileIdentificazionePromises);
  const filesIdentificazione = await Promise.all(fileIdentificazionePromises); //mapDtosToFiles(fileIdentificazionePromises);

  const fileAttiOnboardingPromises = onboarding?.elencoDocumenti
    .filter((documento) => documento.tipo === 'ATTI_ONBOARDING')
    .map((documento) => documentoUploadedDtoToFile(documento.documentoDto));
  const fileAttiOnboarding = await Promise.all(fileAttiOnboardingPromises);
  const attiOnboarding =
    fileAttiOnboarding.length < 1
      ? []
      : onboarding.attiOnboarding.map((atto, index) => ({
          ...atto,
          file: fileAttiOnboarding[index],
        }));

  const initialValuesFromCandidato: IFormCreaCandidatoForm = {
    // Dati anagrafici
    codiceFiscale,
    cognome,
    nome,
    sesso: genere === 'FEMMINILE' ? 'F' : 'M',
    statoCivile,
    cittadinanza,
    dataNascita,
    nazioneNascita,
    provNascita,
    cittaNascita,

    // Estremi identificazione
    tipoDocumento,
    numeroCartaIdentita,
    enteEmittente,
    dataRilascio,
    dataScadenza,
    filesIdentificazione: [] as File[],

    // Recapiti
    indirizzoPec,
    email,
    telefonoAbitazione,
    telefonoCellulare,

    //Dati di residenza
    statoResidenza,
    provinciaResidenza,
    cittaResidenza,
    indirizzoResidenza,
    capResidenza,

    //Dati domicilio
    domicilioDifferente: domicilioDifferente ? 'Si' : 'No',
    statoDomicilio,
    provinciaDomicilio: provinciaDomicilio ?? undefined,
    cittaDomicilio: cittaDomicilio ?? undefined,
    indirizzoDomicilio: indirizzoDomicilio ?? undefined,
    capDomicilio: capDomicilio ?? undefined,

    // Form dati concorso: atti
    attiOnboarding: [],

    // Dati disabilità
    disabile: disabile ? 'Si' : 'No',
    percentualeDisabilita: percentualeDisabilita ?? undefined,
    collocamentoMirato: collocamentoMirato ? 'Si' : 'No',
    fileCollocamento: undefined,
    documentiDisabilita: [],

    // Titolo di studio
    titoloStudio,
    titoloDichiarato: titoloDichiarato ?? undefined,
    noteTitolo: noteTitolo ?? undefined,

    //Dati concorso
    idConcorso,
    areaProfessionale,
    livelli: livello ?? '',
    vincitore: vincitore ?? false,
    idoneo: idoneo ?? false,
    posizioneGraduatoria: posizioneGraduatoria ?? '',
    note,
  };

  return initialValuesFromCandidato;
};
