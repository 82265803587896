import AccordionElement from 'components/accordion-element/AccordionElement';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import { Button, Col, FormGroup, Input, Row } from 'design-react-kit';
import { useFormik } from 'formik';

const GestioneRinuncia: React.FC = () => {
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={'Gestione rinuncia'}
      ></PageHeader>
      <div>
        <div className="mt-3">
          <Rinuncia />
        </div>
      </div>
    </div>
  );
};

const Rinuncia: React.FC = () => {
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = useFormik({
    initialValues: {
      noteUfficioReclutamento: '',
    },
    onSubmit: () => {},
  });

  return (
    <>
      <AccordionElement title="Dettaglio candidato">
        <>
          <Row className="mt-4">
            <Col md={6} className="px-1">
              <FormGroup>
                <Input
                  id="noteUfficioReclutamento"
                  name="noteUfficioReclutamento"
                  type="email"
                  label="Note ufficio reclutamento"
                  placeholder="Inserisci note"
                  value={values.noteUfficioReclutamento}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.noteUfficioReclutamento && !!errors.noteUfficioReclutamento}
                  aria-describedby="noteUfficioReclutamentoError"
                />
              </FormGroup>
              {touched.noteUfficioReclutamento && errors.noteUfficioReclutamento && (
                <div id="noteUfficioReclutamentoError" className="invalid-feedback">
                  {errors.noteUfficioReclutamento}
                </div>
              )}
            </Col>
          </Row>
        </>
      </AccordionElement>

      <div>
        <Button>Indietro</Button>

        <Button>Mancata presenza</Button>
        <Button>Genera rinuncia</Button>
        <Button color="primary">Contrattualizza</Button>
      </div>
    </>
  );
};

export default GestioneRinuncia;
