/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { AssegnazioneIncaricoDto } from './assegnazione-incarico-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CandidaturaBandoPoEssenzialeDto } from './candidatura-bando-po-essenziale-dto';
// May contain unused imports in some cases
// @ts-ignore
import { CessazioneDipendenteDto } from './cessazione-dipendente-dto';
// May contain unused imports in some cases
// @ts-ignore
import { IncaricoEssenzialeDto } from './incarico-essenziale-dto';

/**
 *
 * @export
 * @interface DipendenteRegionaleDto
 */
export interface DipendenteRegionaleDto {
  /**
   *
   * @type {number}
   * @memberof DipendenteRegionaleDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  categoria?: DipendenteRegionaleDtoCategoriaEnum;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  categoriaLabel?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  codiceFiscale?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  cognome?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  nome?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  telefono?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  cellulare?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  dataInizio?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  dataFine?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  dataInizioRapporto?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  tipoCessazione?: DipendenteRegionaleDtoTipoCessazioneEnum;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  descrizioneCessazione?: string;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  nomeCompleto?: string;
  /**
   *
   * @type {number}
   * @memberof DipendenteRegionaleDto
   */
  numeroIncarichiPrincipali?: number;
  /**
   *
   * @type {Set<AssegnazioneIncaricoDto>}
   * @memberof DipendenteRegionaleDto
   */
  elencoAssegnazioni?: Set<AssegnazioneIncaricoDto>;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  stato?: DipendenteRegionaleDtoStatoEnum;
  /**
   *
   * @type {Array<IncaricoEssenzialeDto>}
   * @memberof DipendenteRegionaleDto
   */
  elencoStruttureOrganizzative?: Array<IncaricoEssenzialeDto>;
  /**
   *
   * @type {Array<string>}
   * @memberof DipendenteRegionaleDto
   */
  elencoIncarichiInCorso?: Array<string>;
  /**
   *
   * @type {CessazioneDipendenteDto}
   * @memberof DipendenteRegionaleDto
   */
  cessazione?: CessazioneDipendenteDto;
  /**
   *
   * @type {string}
   * @memberof DipendenteRegionaleDto
   */
  tipoContratto?: DipendenteRegionaleDtoTipoContrattoEnum;
  /**
   *
   * @type {boolean}
   * @memberof DipendenteRegionaleDto
   */
  isCvPubblicato?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DipendenteRegionaleDto
   */
  isPrimoIncarico?: boolean;
  /**
   *
   * @type {Array<CandidaturaBandoPoEssenzialeDto>}
   * @memberof DipendenteRegionaleDto
   */
  elencoCandidatureBandi?: Array<CandidaturaBandoPoEssenzialeDto>;
  /**
   *
   * @type {boolean}
   * @memberof DipendenteRegionaleDto
   */
  conIncarichiPrincipali?: boolean;
  /**
   *
   * @type {number}
   * @memberof DipendenteRegionaleDto
   */
  numeroSO?: number;
  /**
   *
   * @type {number}
   * @memberof DipendenteRegionaleDto
   */
  numeroIncarichiInCorso?: number;
}

export const DipendenteRegionaleDtoCategoriaEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  Aro: 'ARO',
  Are: 'ARE',
  Ari: 'ARI',
  Arf: 'ARF',
  Cd: 'CD',
  Sz: 'SZ',
  Sr: 'SR',
  Altro: 'ALTRO',
} as const;

export type DipendenteRegionaleDtoCategoriaEnum =
  (typeof DipendenteRegionaleDtoCategoriaEnum)[keyof typeof DipendenteRegionaleDtoCategoriaEnum];
export const DipendenteRegionaleDtoTipoCessazioneEnum = {
  P: 'P',
  S: 'S',
} as const;

export type DipendenteRegionaleDtoTipoCessazioneEnum =
  (typeof DipendenteRegionaleDtoTipoCessazioneEnum)[keyof typeof DipendenteRegionaleDtoTipoCessazioneEnum];
export const DipendenteRegionaleDtoStatoEnum = {
  Censito: 'CENSITO',
  Terminato: 'TERMINATO',
} as const;

export type DipendenteRegionaleDtoStatoEnum =
  (typeof DipendenteRegionaleDtoStatoEnum)[keyof typeof DipendenteRegionaleDtoStatoEnum];
export const DipendenteRegionaleDtoTipoContrattoEnum = {
  Pt: 'PT',
  Ind: 'IND',
  Det: 'DET',
  IndPt: 'IND_PT',
  DetDg: 'DET_DG',
} as const;

export type DipendenteRegionaleDtoTipoContrattoEnum =
  (typeof DipendenteRegionaleDtoTipoContrattoEnum)[keyof typeof DipendenteRegionaleDtoTipoContrattoEnum];
