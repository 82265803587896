import axios from 'axios';
import {
  Button,
  FormGroup,
  Icon,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'design-react-kit';
import { useCompositeForm } from 'hooks/useCompositeForm';
import { useEffect, useState } from 'react';

const FormIban = () => {
  const { values, handleChange, setFieldValue, errors, touched } = useCompositeForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payrollOptions, setPayrollOptions] = useState<
    { value: string; label: string; valore: string }[]
  >([]);

  const toggleModal = () => {
    setIsModalOpen((prevValue) => !prevValue);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/forms/concorso/onboarding`)
      .then((response) => {
        setPayrollOptions(response.data.accreditamentoCompetenze);
      })
      .catch((error) => {
        console.error('Error fetching select options:', error);
      });
  }, []);

  return (
    <>
      <Button
        className="w-100 d-flex justify-content-between mb-3"
        color="primary"
        onClick={toggleModal}
      >
        Inserimento IBAN accredito Stipendio
        <Icon icon="it-arrow-right" color="white" />
      </Button>
      {/* <Button onClick={toggleModal}>Inserimento IBAN accredito Stipendio</Button> */}
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={'modal-privacy'}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={'modal-privacy'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" onClick={toggleModal} icon="it-close" />
              <h2>Accredito Stipendio</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="col-12 mb-4">
              <fieldset className="d-flex flex-column">
                <label>
                  Seleziona tipologia di conto
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <div className="row">
                  {payrollOptions.map((item) => {
                    return (
                      <FormGroup check inline key={item.value}>
                        <Input
                          id={item.value}
                          name="tipoAccreditamento"
                          type="radio"
                          value={item.value}
                          checked={item.value === values.tipoAccreditamento}
                          onChange={handleChange}
                        />
                        <Label check htmlFor={item.value}>
                          {item.label}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </div>
              </fieldset>
            </div>
            <div className="col-6 ">
              <FormGroup check>
                <Input
                  id="iban"
                  name="iban"
                  label="IBAN"
                  type="text"
                  value={values.iban}
                  onChange={handleChange}
                  invalid={!!errors['iban']}
                  infoText={(errors['iban'] as string) || ''}
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color="primary"
            outline
            className="link-underline-primary"
            onClick={() => toggleModal()}
          >
            Annulla
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleModal();
              console.log('Form values: ', values);
            }}
          >
            Conferma
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormIban;
