import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  ATTO_DI_ASSGENAZIONE,
  CODICE_CID_DIPENDENTE,
  CODICE_UNIVOCO_PO,
  DATA_FINE_VALIDITA_INCARICO,
  DATA_FINE_VALIDITA_PO,
  DATA_INIZIO_VALIDITA_INCARICO,
  DATA_INIZIO_VALIDITA_PO,
  DECLARATORIA_PO,
  DENOMINAZIONE_PO,
  NOME_E_COGNOME_DIPENDENTE,
  STRUTTURA_ORGANIZZATIVA_PO,
  TIPO_INCARICO,
  TIPO_PO,
} from './posizioniOrganizzativeConstants';
import { detailsPosizioneOrganizzativa } from '../../store/posizioneOrganizzativaSlice';
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants';
import { Button } from 'design-react-kit';
import PosizioniOrganizzativeDetailsComponent from '../../components/posizioni-organizzative-detail/PosizioniOrganizzativeDetailsComponent';
import { HREF_ELENCO_POSIZIONI_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import PageHeader from '../../components/common/page-header/PageHeader';

const VisualizzaPosizioneOrganizzativa = () => {
  const { t } = useTranslation();
  const { idCodiceIncarico } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fieldUsed: Array<string> = [
    CODICE_UNIVOCO_PO,
    DENOMINAZIONE_PO,
    DECLARATORIA_PO,
    STRUTTURA_ORGANIZZATIVA_PO,
    DATA_INIZIO_VALIDITA_PO,
    DATA_FINE_VALIDITA_PO,
    TIPO_PO,
    CODICE_CID_DIPENDENTE,
    NOME_E_COGNOME_DIPENDENTE,
    TIPO_INCARICO,
    ATTO_DI_ASSGENAZIONE,
    DATA_INIZIO_VALIDITA_INCARICO,
    DATA_FINE_VALIDITA_INCARICO,
  ];

  const posizioneOrganizzativa = useAppSelector(
    (state) => state.posizioneOrganizzativa.detailsResult
  );
  const posizioneOrganizzativaResult = useAppSelector(
    (state) => state.posizioneOrganizzativa.detailsStatus
  );

  useEffect(() => {
    getDetail();
  }, [0]);

  const getDetailsFromApi = (idCodiceIncarico: string) => {
    dispatch(detailsPosizioneOrganizzativa({ idCodiceIncarico }));
  };

  const getDetail = () => {
    getDetailsFromApi(idCodiceIncarico!);
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}
        state={{ form: true }}
        title={t('visualizza elevata qualificazione')!}
      ></PageHeader>
      {posizioneOrganizzativaResult === STATUS_FULLFILLED && (
        <div>
          <div className="row">
            <div className="col-12">
              <PosizioniOrganizzativeDetailsComponent
                fields={fieldUsed}
                value={posizioneOrganizzativa!}
              />

              <div className="mt-3 float-right mr-2 d-flex align-items-center justify-content-end">
                <span className="font-italic font-weight-semibold mr-3 w-50">
                  {t('download file posizioni organizzative')}
                </span>
                <a
                  color="primary"
                  className="btn btn-primary"
                  target={'_blank'}
                  rel="noopener noreferrer"
                  href={MS_AU_CONFIG.basePath + '/v1/incarico/documenti/' + idCodiceIncarico!}
                >
                  Download
                </a>
              </div>
            </div>
          </div>
          <div className="postion-relative py-5">
            <div className="d-flex flex-row-reverse">
              <Button
                color="secondary"
                outline
                className="mt-2 mr-2"
                onClick={() => {
                  navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE, { state: { form: true } });
                }}
              >
                Indietro
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualizzaPosizioneOrganizzativa;
