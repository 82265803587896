import { useEffect } from 'react';
import { deleteJwtToken } from '../../utility/cookie';

function Logout() {
  const logout = () => {
    deleteJwtToken();
    // Creazione di un form nascosto per il logout
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = process.env.REACT_APP_MS_AU_LOGOUT_URL || ''; // URL di logout su WSO2 Identity Server

    // Aggiungi il form alla pagina e invialo
    document.body.appendChild(form);
    form.submit();
    return;
  };

  useEffect(() => {
    logout();
  });

  return <></>;
}

export default Logout;
