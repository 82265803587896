import React from 'react';
import { AnagraficaRuoloDto } from '../../services/ms-anagrafica-unica';
import { showFieldIfNotNull } from 'utility/utility';
import { formatDate } from 'utility/formatDate';

export interface RuoloDetailsProps {
  detailsResult: AnagraficaRuoloDto;
}

const RuoloDetailsComponent = (props: RuoloDetailsProps) => {
  return (
    <div className="col-sm-12">
      {(props.detailsResult?.codice ||
        props.detailsResult?.nome ||
        props.detailsResult?.descrizione ||
        props.detailsResult?.stato ||
        props.detailsResult?.dataInserimento ||
        props.detailsResult?.dataModifica) && (
        <div className="card-div row">
          <div className="col-sm-12">
            <div className="info-box-light bg-light-gray">
              <h5 className="pb-2 border-bottom font-weight-bold">Riepilogo</h5>
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">Codice</dt>
                  <dd className="col-sm-12">{showFieldIfNotNull(props.detailsResult?.codice)}</dd>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xl-4">
                  <dt className="col-sm-12">Stato</dt>
                  <dd className="col-sm-12">{showFieldIfNotNull(props.detailsResult?.stato)}</dd>
                </div>
                <div className="col-12 col-xl-4">
                  <dt className="col-sm-12">Data Inserimento</dt>
                  <dd className="col-sm-12">{formatDate(props.detailsResult?.dataInserimento)}</dd>
                </div>
                <div className="col-12 col-xl-4">
                  <dt className="col-sm-12">Data Modifica</dt>
                  <dd className="col-sm-12">
                    {showFieldIfNotNull(props.detailsResult?.dataModifica)}
                  </dd>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <dt className="col-sm-12">Descrizione</dt>
                  <dd className="col-sm-12">
                    {showFieldIfNotNull(props.detailsResult?.descrizione)}
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RuoloDetailsComponent;
