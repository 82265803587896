import { ElencoSociDto } from '../../../services/ms-anagrafica-unica';
import React from 'react';
import { Table } from 'design-react-kit';

const RISociComponent = (dati: { value: ElencoSociDto }) => {
  return (
    <div className={'col-12 pt-5'}>
      {(dati?.value?.riquadri?.riquadro ?? []).map((riquadro, index) => (
        <Table
          striped
          responsive
          className={'mb-5'}
          key={riquadro?.titolari?.titolare?.[0]?.['anagrafica-titolare']?.['c-fiscale']}
        >
          <thead className={'primary-bg text-white'}>
            {riquadro?.['composizione-quote']?.['valore-nominale'] && (
              <tr>
                <th colSpan={3}>
                  Valore nominale:
                  {riquadro['composizione-quote']['valore-nominale']}
                  {riquadro['composizione-quote'].valuta}
                </th>
              </tr>
            )}
            {riquadro?.['composizione-quote']?.tipo && (
              <tr>
                <th colSpan={3}>
                  {riquadro['composizione-quote'].tipo}: {riquadro['composizione-quote'].n}
                </th>
              </tr>
            )}

            <tr>
              <th>Denominazione</th>
              <th>Codice fiscale</th>
              <th>Diritto partecipazione</th>
            </tr>
          </thead>
          <tbody>
            {(riquadro?.titolari?.titolare ?? []).map((titolare, index) => (
              <tr key={'titolare' + titolare?.['anagrafica-titolare']?.['c-fiscale']}>
                <td>{titolare?.['anagrafica-titolare']?.denominazione ?? '-'}</td>
                <td>{titolare?.['anagrafica-titolare']?.['c-fiscale'] ?? '-'}</td>
                <td>{titolare?.['diritto-partecipazione']?.tipo ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ))}
    </div>
  );
};

export default RISociComponent;
