/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentoDto } from '../model';
// @ts-ignore
import { PagedDtoDocumentoDto } from '../model';
/**
 * GestioneDocumentaleControllerApi - axios parameter creator
 * @export
 */
export const GestioneDocumentaleControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * API Rest per la cancellazione del documento
     * @summary Cancellazione Documento
     * @param {number} idDocumento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocument: async (
      idDocumento: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDocumento' is not null or undefined
      assertParamExists('deleteDocument', 'idDocumento', idDocumento);
      const localVarPath = `/v1/documenti/{id-documento}`.replace(
        `{${'id-documento'}}`,
        encodeURIComponent(String(idDocumento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il download del documento
     * @summary Download Documento
     * @param {number} idDocumento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDocument: async (
      idDocumento: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDocumento' is not null or undefined
      assertParamExists('downloadDocument', 'idDocumento', idDocumento);
      const localVarPath = `/v1/documenti/{id-documento}/download`.replace(
        `{${'id-documento'}}`,
        encodeURIComponent(String(idDocumento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il dettaglio del documento
     * @summary Dettaglio Documento
     * @param {number} idDocumento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument: async (
      idDocumento: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDocumento' is not null or undefined
      assertParamExists('getDocument', 'idDocumento', idDocumento);
      const localVarPath = `/v1/documenti/{id-documento}`.replace(
        `{${'id-documento'}}`,
        encodeURIComponent(String(idDocumento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il recupero della lista dei documenti relativi all\'utente loggato
     * @summary Elenco Documenti Utente Loggato
     * @param {string} [nomeDocumento]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getElencoDocumenti: async (
      nomeDocumento?: string,
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/documenti`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (nomeDocumento !== undefined) {
        localVarQueryParameter['nomeDocumento'] = nomeDocumento;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['pageNum'] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'inserimento di un nuovo documento
     * @summary Upload Nuovo Documento
     * @param {File} file
     * @param {boolean} [signed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDocument: async (
      file: File,
      signed?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('uploadDocument', 'file', file);
      const localVarPath = `/v1/documenti`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (signed !== undefined) {
        localVarQueryParameter['signed'] = signed;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GestioneDocumentaleControllerApi - functional programming interface
 * @export
 */
export const GestioneDocumentaleControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GestioneDocumentaleControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per la cancellazione del documento
     * @summary Cancellazione Documento
     * @param {number} idDocumento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDocument(
      idDocumento: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(
        idDocumento,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il download del documento
     * @summary Download Documento
     * @param {number} idDocumento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadDocument(
      idDocumento: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocument(
        idDocumento,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il dettaglio del documento
     * @summary Dettaglio Documento
     * @param {number} idDocumento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocument(
      idDocumento: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(idDocumento, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il recupero della lista dei documenti relativi all\'utente loggato
     * @summary Elenco Documenti Utente Loggato
     * @param {string} [nomeDocumento]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getElencoDocumenti(
      nomeDocumento?: string,
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDocumentoDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoDocumenti(
        nomeDocumento,
        pageNum,
        pageSize,
        sort,
        direction,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'inserimento di un nuovo documento
     * @summary Upload Nuovo Documento
     * @param {File} file
     * @param {boolean} [signed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadDocument(
      file: File,
      signed?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(
        file,
        signed,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GestioneDocumentaleControllerApi - factory interface
 * @export
 */
export const GestioneDocumentaleControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GestioneDocumentaleControllerApiFp(configuration);
  return {
    /**
     * API Rest per la cancellazione del documento
     * @summary Cancellazione Documento
     * @param {GestioneDocumentaleControllerApiDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocument(
      requestParameters: GestioneDocumentaleControllerApiDeleteDocumentRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<boolean> {
      return localVarFp
        .deleteDocument(requestParameters.idDocumento, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il download del documento
     * @summary Download Documento
     * @param {GestioneDocumentaleControllerApiDownloadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDocument(
      requestParameters: GestioneDocumentaleControllerApiDownloadDocumentRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<File> {
      return localVarFp
        .downloadDocument(requestParameters.idDocumento, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il dettaglio del documento
     * @summary Dettaglio Documento
     * @param {GestioneDocumentaleControllerApiGetDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument(
      requestParameters: GestioneDocumentaleControllerApiGetDocumentRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<DocumentoDto> {
      return localVarFp
        .getDocument(requestParameters.idDocumento, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il recupero della lista dei documenti relativi all\'utente loggato
     * @summary Elenco Documenti Utente Loggato
     * @param {GestioneDocumentaleControllerApiGetElencoDocumentiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getElencoDocumenti(
      requestParameters: GestioneDocumentaleControllerApiGetElencoDocumentiRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedDtoDocumentoDto> {
      return localVarFp
        .getElencoDocumenti(
          requestParameters.nomeDocumento,
          requestParameters.pageNum,
          requestParameters.pageSize,
          requestParameters.sort,
          requestParameters.direction,
          requestParameters.q,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'inserimento di un nuovo documento
     * @summary Upload Nuovo Documento
     * @param {GestioneDocumentaleControllerApiUploadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDocument(
      requestParameters: GestioneDocumentaleControllerApiUploadDocumentRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<DocumentoDto> {
      return localVarFp
        .uploadDocument(requestParameters.file, requestParameters.signed, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteDocument operation in GestioneDocumentaleControllerApi.
 * @export
 * @interface GestioneDocumentaleControllerApiDeleteDocumentRequest
 */
export interface GestioneDocumentaleControllerApiDeleteDocumentRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneDocumentaleControllerApiDeleteDocument
   */
  readonly idDocumento: number;
}

/**
 * Request parameters for downloadDocument operation in GestioneDocumentaleControllerApi.
 * @export
 * @interface GestioneDocumentaleControllerApiDownloadDocumentRequest
 */
export interface GestioneDocumentaleControllerApiDownloadDocumentRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneDocumentaleControllerApiDownloadDocument
   */
  readonly idDocumento: number;
}

/**
 * Request parameters for getDocument operation in GestioneDocumentaleControllerApi.
 * @export
 * @interface GestioneDocumentaleControllerApiGetDocumentRequest
 */
export interface GestioneDocumentaleControllerApiGetDocumentRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneDocumentaleControllerApiGetDocument
   */
  readonly idDocumento: number;
}

/**
 * Request parameters for getElencoDocumenti operation in GestioneDocumentaleControllerApi.
 * @export
 * @interface GestioneDocumentaleControllerApiGetElencoDocumentiRequest
 */
export interface GestioneDocumentaleControllerApiGetElencoDocumentiRequest {
  /**
   *
   * @type {string}
   * @memberof GestioneDocumentaleControllerApiGetElencoDocumenti
   */
  readonly nomeDocumento?: string;

  /**
   *
   * @type {number}
   * @memberof GestioneDocumentaleControllerApiGetElencoDocumenti
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof GestioneDocumentaleControllerApiGetElencoDocumenti
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof GestioneDocumentaleControllerApiGetElencoDocumenti
   */
  readonly sort?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof GestioneDocumentaleControllerApiGetElencoDocumenti
   */
  readonly direction?: 'ASC' | 'DESC';

  /**
   *
   * @type {string}
   * @memberof GestioneDocumentaleControllerApiGetElencoDocumenti
   */
  readonly q?: string;
}

/**
 * Request parameters for uploadDocument operation in GestioneDocumentaleControllerApi.
 * @export
 * @interface GestioneDocumentaleControllerApiUploadDocumentRequest
 */
export interface GestioneDocumentaleControllerApiUploadDocumentRequest {
  /**
   *
   * @type {File}
   * @memberof GestioneDocumentaleControllerApiUploadDocument
   */
  readonly file: File;

  /**
   *
   * @type {boolean}
   * @memberof GestioneDocumentaleControllerApiUploadDocument
   */
  readonly signed?: boolean;
}

/**
 * GestioneDocumentaleControllerApi - object-oriented interface
 * @export
 * @class GestioneDocumentaleControllerApi
 * @extends {BaseAPI}
 */
export class GestioneDocumentaleControllerApi extends BaseAPI {
  /**
   * API Rest per la cancellazione del documento
   * @summary Cancellazione Documento
   * @param {GestioneDocumentaleControllerApiDeleteDocumentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneDocumentaleControllerApi
   */
  public deleteDocument(
    requestParameters: GestioneDocumentaleControllerApiDeleteDocumentRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneDocumentaleControllerApiFp(this.configuration)
      .deleteDocument(requestParameters.idDocumento, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il download del documento
   * @summary Download Documento
   * @param {GestioneDocumentaleControllerApiDownloadDocumentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneDocumentaleControllerApi
   */
  public downloadDocument(
    requestParameters: GestioneDocumentaleControllerApiDownloadDocumentRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneDocumentaleControllerApiFp(this.configuration)
      .downloadDocument(requestParameters.idDocumento, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il dettaglio del documento
   * @summary Dettaglio Documento
   * @param {GestioneDocumentaleControllerApiGetDocumentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneDocumentaleControllerApi
   */
  public getDocument(
    requestParameters: GestioneDocumentaleControllerApiGetDocumentRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneDocumentaleControllerApiFp(this.configuration)
      .getDocument(requestParameters.idDocumento, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il recupero della lista dei documenti relativi all\'utente loggato
   * @summary Elenco Documenti Utente Loggato
   * @param {GestioneDocumentaleControllerApiGetElencoDocumentiRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneDocumentaleControllerApi
   */
  public getElencoDocumenti(
    requestParameters: GestioneDocumentaleControllerApiGetElencoDocumentiRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GestioneDocumentaleControllerApiFp(this.configuration)
      .getElencoDocumenti(
        requestParameters.nomeDocumento,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.sort,
        requestParameters.direction,
        requestParameters.q,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'inserimento di un nuovo documento
   * @summary Upload Nuovo Documento
   * @param {GestioneDocumentaleControllerApiUploadDocumentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneDocumentaleControllerApi
   */
  public uploadDocument(
    requestParameters: GestioneDocumentaleControllerApiUploadDocumentRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneDocumentaleControllerApiFp(this.configuration)
      .uploadDocument(requestParameters.file, requestParameters.signed, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
