import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Badge,
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useTranslation } from 'react-i18next';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  deleteRuolo,
  formSearchRuoloStatoSelect,
  pathDeleteRuolo,
  resetDeleteStatus,
  searchRuoli,
} from '../../store/ruoloSlice';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import Pagination from '../../components/common/pagination/Pagination';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  HREF_GESTIONE_RUOLO_FUNZIONALITA,
  HREF_GESTIONE_RUOLO_UTENTI,
  HREF_MODIFICA_RUOLO,
  HREF_NUOVO_RUOLO,
} from '../../components/layout/sidemenu/sidemenuConstants';
import TruncateText from '../../components/common/truncateText/TruncateText';
import PageHeader from '../../components/common/page-header/PageHeader';
import { RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

const schema = z.object({
  ruolo: z.string().max(50, 'lenghtnomeruolo').optional(),
});

const initialValues = {
  stato: '',
  ruolo: '',
  pageNum: 0,
  pageSize: 10,
  sort: '',
  direction: '',
  dipendente: '',
  funzionalita: '',
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
  direction: Direction;
}

function ElencoRuoli(props: { operations: RuoloUtenteAutenticatoDto }) {
  const { t } = useTranslation();
  const [formCollapseOpen, setFormCollapseOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { searchResult, searchStatus, searchInput, formSearchRuolo } = useAppSelector(
    (state) => state.ruolo
  );
  const [sorting, setSorting] = useState<SortingState>({ direction: 'ASC' });
  const navigate = useNavigate();
  const [isOpen, toggleModal] = useState(false);
  const [ruoloSelezionato, setRuoloSelezionato] = useState<number>();

  const [isOpenListDetails, toggleModalListDetails] = useState(false);
  const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);

  useEffect(() => {
    dispatch(resetDeleteStatus());
    dispatch(formSearchRuoloStatoSelect());
    if (
      (searchInput?.ruolo == '' &&
        searchInput?.stato == '' &&
        searchInput?.dipendente == '' &&
        searchInput?.funzionalita == '') ||
      searchInput == null
    ) {
      doSearch();
    }
  }, [0]);

  let hasResults =
    searchResult && searchResult.totalElements ? searchResult.totalElements > 0 : false;

  const searchForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: () => {
      doSearch();
    },
  });

  useEffect(() => {
    searchForm.setValues({
      stato: searchInput?.stato || '',
      ruolo: searchInput?.ruolo || '',
      dipendente: searchInput?.dipendente || '',
      funzionalita: searchInput?.funzionalita || '',
      pageNum: searchInput?.pageNum || 0,
      pageSize: searchInput?.pageSize || 10,
      sort: searchInput?.sort || '',
      direction: searchInput?.direction || '',
    });
    if (
      searchInput != undefined &&
      (searchInput.ruolo != '' ||
        searchInput.stato != '' ||
        searchInput.dipendente != '' ||
        searchInput.funzionalita != '')
    ) {
      setFormCollapseOpen(true);
      dispatch(searchRuoli(searchInput));
    }
  }, [searchInput]);

  let statoOptions: CustomSelectItem[] = [];
  formSearchRuolo?.stato?.forEach((s) => {
    statoOptions.push({ label: s.label, value: s.valore });
  });

  let funzionalitaOptions: CustomSelectItem[] = [];
  formSearchRuolo?.funzionalita?.forEach((s) => {
    funzionalitaOptions.push({ label: s.label, value: s.valore });
  });

  const searchStartKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      searchForm.setFieldValue('pageNum', 0);
      searchForm.handleSubmit();
    }
  };

  const handleChange = (name: string, selectedOption: any) => {
    searchForm.setFieldValue(name, selectedOption);
    // searchForm.handleChange(selectedOption || '');
  };

  const doSearch = () => {
    dispatch(searchRuoli(searchForm.values));
  };

  const handlePageChange = (pageNum: number) => {
    // setCurrentPage(pageNum);
    searchForm.setFieldValue('pageNum', pageNum - 1);
    // doSearch();
    searchForm.handleSubmit();
  };

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const cancella = (id: number) => {
    setRuoloSelezionato(id);
    toggleModal(!isOpen);
  };

  const confermaCancellazione = () => {
    const body: pathDeleteRuolo = {
      idRuolo: ruoloSelezionato,
      bodyRequest: { requestType: 'Cancella' },
    };

    dispatch(deleteRuolo(body))
      .unwrap()
      .then((resp) => resp && dispatch(searchRuoli(initialValues)));
    setRuoloSelezionato(undefined);
  };

  const modalViewDetails = (list: any) => {
    if (list.length > 0) {
      toggleModalListDetails(!isOpenListDetails);
      setElencoDetails(list);
    }
  };

  const nuovoRuolo = () => {
    window.location.replace(HREF_NUOVO_RUOLO);
  };

  const showGestisciFunzionalitaOperations = () =>
    props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLOFUNZ_ELENCO');

  const showGestisciDipendentiOperations = () =>
    props.operations?.elencoFunzionalita?.includes('ASSEGNARUOLODIPE_ELENCO');

  const showModificaRuoloOperations = () =>
    props.operations?.elencoFunzionalita?.includes('RUOLO_PATCH');

  const showInserisciRuoloOperations = () =>
    props.operations?.elencoFunzionalita?.includes('RUOLO_INSERT');

  const newButton = {
    buttonProperties: { outline: true },
    title: 'Nuovo ruolo',
    buttonColor: 'primary',
    action: nuovoRuolo,
    showButtonIcon: true,
    icon: 'it-plus',
    iconColor: 'primary',
  };

  const expandButton = {
    buttonProperties: { outline: true },
    title: 'Ricerca',
    buttonColor: 'primary',
    showButtonIcon: true,
    icon: 'it-search',
    iconColor: 'primary',
    action: () => {
      formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true);
    },
  };

  return (
    <div>
      <PageHeader
        showIcon={false}
        title={t('elenco ruoli')!}
        buttons={showInserisciRuoloOperations() ? [newButton, expandButton] : [expandButton]}
      />

      <Accordion className="form-custom form-bg filtri-ricerca" background="active">
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="form-row mt-2">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />
            <Input
              type="text"
              id="input-ruolo"
              placeholder="Inserisci un ruolo"
              wrapperClassName="col col-md-6"
              name="ruolo"
              invalid={!!searchForm.errors.ruolo}
              infoText={t(searchForm.errors.ruolo || '') || ''}
              value={searchForm.values.ruolo}
              onChange={searchForm.handleChange}
              onKeyUp={searchStartKeyPress}
            />
            <Input
              type="text"
              id="input-dipendente"
              placeholder="Inserisci un dipendente"
              wrapperClassName="col col-md-6"
              name="dipendente"
              invalid={!!searchForm.errors.dipendente}
              infoText={t(searchForm.errors.dipendente || '') || ''}
              value={searchForm.values.dipendente}
              onChange={searchForm.handleChange}
              onKeyUp={searchStartKeyPress}
            />
            <Col md="6">
              <CustomSelect
                options={statoOptions}
                value={searchForm.values.stato}
                placeholder="Seleziona stato"
                onChange={(e) => handleChange('stato', e)}
                invalid={!!searchForm.errors.stato}
                infoText={t(searchForm.errors.stato || '') || ''}
                name="stato"
              />
            </Col>
            <Col md="6">
              <CustomSelect
                options={funzionalitaOptions}
                value={searchForm.values.funzionalita}
                placeholder="Seleziona una funzionalità"
                onChange={(e) => handleChange('funzionalita', e)}
                invalid={!!searchForm.errors.funzionalita}
                infoText={t(searchForm.errors.funzionalita || '') || ''}
                name="funzionalita"
              />
            </Col>
          </div>

          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                searchForm.setFieldValue('pageNum', 0);
                searchForm.handleSubmit();
              }}
            >
              Applica
            </Button>
            <Button outline color="primary" className="mt-2 mr-2" onClick={searchForm.handleReset}>
              Pulisci
            </Button>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-2 position-relative">
        <div>
          {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && (
            <Alert color="info">Nessun risultato disponibile</Alert>
          )}
          {searchStatus === STATUS_FULLFILLED && hasResults && (
            <div className={searchResult.totalPages === 1 ? 'mb-5' : ''}>
              <Table
                responsive
                striped
                style={{ marginTop: searchResult.totalElements! === 1 ? '50px' : '20px' }}
              >
                <TableTheadCustom
                  colonne={[
                    { titolo: 'Stato' },
                    { titolo: 'Codice' },
                    { titolo: 'Ruolo', nomeColonna: 'nome' },
                    { titolo: 'Descrizione', nomeColonna: 'descrizione' },
                    { titolo: 'Inserimento' },
                    { titolo: 'Modifica' },
                    { titolo: 'Funzionalità' },
                    { titolo: 'Dipendenti/Consulenti' },
                    { titolo: 'Azioni' },
                  ]}
                  datiOrdinamento={searchForm.values}
                  eseguiOrdinamento={ordinamento}
                />

                <tbody>
                  {(searchResult.data || []).map((x, i) => (
                    <tr key={i}>
                      <td title={x.stato == 'ATTIVO' ? 'Attivo' : 'Cancellato'}>
                        {x.stato == 'ATTIVO' ? (
                          <div className="cicle-status-active"></div>
                        ) : (
                          <div className="cicle-status-deactive"></div>
                        )}
                      </td>
                      <td>{x.codice}</td>
                      <td>{x.nome}</td>
                      <td>
                        {<TruncateText text={x.descrizione} maxLength={50} idTooltip={`${i}`} />}
                      </td>
                      <td>
                        {x.dataInserimento != null
                          ? format(parseISO(x.dataInserimento!), 'dd/MM/yyyy')
                          : ''}
                      </td>
                      <td>
                        {x.dataModifica != null
                          ? format(parseISO(x.dataModifica!), 'dd/MM/yyyy')
                          : ''}
                      </td>
                      <td>
                        <Badge
                          color="primary"
                          className=" col-sm-12"
                          style={
                            x.decodificaFunzionalita?.length! > 0
                              ? { cursor: 'pointer', padding: '10px', fontSize: '15px' }
                              : { padding: '10px', fontSize: '15px' }
                          }
                          onClick={() => modalViewDetails(x.decodificaFunzionalita)}
                        >
                          {x.decodificaFunzionalita?.length! > 0
                            ? x.decodificaFunzionalita?.length! + ' funzionalità'
                            : 'Nessuna funzionalità'}
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          className=" col-sm-12"
                          style={
                            x.decodificaDipendente?.length! > 0
                              ? { cursor: 'pointer', padding: '10px', fontSize: '15px' }
                              : { padding: '10px', fontSize: '15px' }
                          }
                          color="primary"
                          onClick={() => modalViewDetails(x.decodificaDipendente)}
                        >
                          {x.decodificaDipendente?.length! > 0
                            ? x.decodificaDipendente?.length +
                              ` assegnatar${x.decodificaDipendente?.length! > 1 ? 'i' : 'io'}`
                            : 'Nessun assegnatario'}
                        </Badge>
                      </td>
                      <td>
                        {!x.cancellato && (
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle nav>
                              <Icon icon="it-settings" color="primary" size="sm" />
                            </DropdownToggle>
                            <DropdownMenu className="no-arrow">
                              <LinkList>
                                {showModificaRuoloOperations() && (
                                  <>
                                    <LinkListItem
                                      size="medium"
                                      onClick={() => navigate(`${HREF_MODIFICA_RUOLO}/${x.id}`)}
                                    >
                                      <span>Modifica</span>
                                    </LinkListItem>
                                    <LinkListItem size="medium" onClick={() => cancella(x.id!)}>
                                      <span> Cancella</span>
                                    </LinkListItem>
                                  </>
                                )}
                                {showGestisciFunzionalitaOperations() && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(`${HREF_GESTIONE_RUOLO_FUNZIONALITA}/${x.id}`)
                                    }
                                  >
                                    <span>Funzionalità</span>
                                  </LinkListItem>
                                )}
                                {showGestisciDipendentiOperations() && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(`${HREF_GESTIONE_RUOLO_UTENTI}/${x.id}`)
                                    }
                                  >
                                    <span>Dipendenti/Consulenti</span>
                                  </LinkListItem>
                                )}
                              </LinkList>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div>
                <Modal
                  isOpen={isOpen}
                  toggle={() => toggleModal(!isOpen)}
                  labelledBy="esempio1"
                  centered
                >
                  <ModalHeader
                    toggle={() => {
                      toggleModal(!isOpen);
                      setRuoloSelezionato(undefined);
                    }}
                    id="esempio1"
                  >
                    Cancella ruolo
                  </ModalHeader>
                  <ModalBody>
                    <p>Sei sicuro di voler cancellare questo ruolo?</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={() => {
                        toggleModal(!isOpen);
                        setRuoloSelezionato(undefined);
                      }}
                    >
                      Annulla
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        toggleModal(!isOpen);
                        confermaCancellazione();
                      }}
                    >
                      Conferma
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  isOpen={isOpenListDetails}
                  toggle={() => toggleModalListDetails(!isOpenListDetails)}
                  labelledBy="listDetails"
                  size="lg"
                  centered
                >
                  <ModalHeader
                    toggle={() => {
                      toggleModalListDetails(!isOpenListDetails);
                      setElencoDetails(undefined);
                    }}
                    id="listDetails"
                  >
                    Elenco Funzionalità
                  </ModalHeader>
                  <ModalBody>
                    {elencoDetails?.map((value, index) => (
                      <ul key={index}>
                        <li>{`${value != null ? value.label : ''} ${value.name != null ? `[${value.name}]` : ''}`}</li>
                      </ul>
                    ))}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={() => {
                        toggleModalListDetails(!isOpenListDetails);
                        setElencoDetails(undefined);
                      }}
                    >
                      Annulla
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <Pagination
                totalCount={searchResult.totalElements || 0}
                siblingCount={1}
                currentPage={searchForm.values.pageNum + 1}
                pageSize={searchResult.pageSize || 10}
                onPageChange={(page: number) => {
                  handlePageChange(page);
                }}
                className="justify-content-center"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ElencoRuoli;
