/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedbackNotificaDto } from '../model';
// @ts-ignore
import { FeedbackNotificaInsertRequestDto } from '../model';
// @ts-ignore
import { NotificaDto } from '../model';
// @ts-ignore
import { NotificaPatchRequestDto } from '../model';
// @ts-ignore
import { PagedDtoNotificaDto } from '../model';
/**
 * NotificheControllerApi - axios parameter creator
 * @export
 */
export const NotificheControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * API Rest per aggiornare lo stato della notifica
     * @summary Aggiorna stato notifica
     * @param {number} idNotifica
     * @param {NotificaPatchRequestDto} notificaPatchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggiornaStatoLettura: async (
      idNotifica: number,
      notificaPatchRequestDto: NotificaPatchRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idNotifica' is not null or undefined
      assertParamExists('aggiornaStatoLettura', 'idNotifica', idNotifica);
      // verify required parameter 'notificaPatchRequestDto' is not null or undefined
      assertParamExists('aggiornaStatoLettura', 'notificaPatchRequestDto', notificaPatchRequestDto);
      const localVarPath = `/v1/utenti/notifiche/{idNotifica}`.replace(
        `{${'idNotifica'}}`,
        encodeURIComponent(String(idNotifica))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificaPatchRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest che restituisce l\'elenco dei feedback in base all\'id utente e notifica
     * @summary Elenco feedback
     * @param {number} idNotifica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    elencoFeedbackNotifica: async (
      idNotifica: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idNotifica' is not null or undefined
      assertParamExists('elencoFeedbackNotifica', 'idNotifica', idNotifica);
      const localVarPath = `/v1/utenti/notifiche/{idNotifica}/feedbacks`.replace(
        `{${'idNotifica'}}`,
        encodeURIComponent(String(idNotifica))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'eliminazione logica della notifica
     * @summary Elimina notifica
     * @param {number} idNotifica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eliminaNotifica: async (
      idNotifica: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idNotifica' is not null or undefined
      assertParamExists('eliminaNotifica', 'idNotifica', idNotifica);
      const localVarPath = `/v1/utenti/notifiche/{idNotifica}`.replace(
        `{${'idNotifica'}}`,
        encodeURIComponent(String(idNotifica))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'inserimento di un feedback per una notifica specifica
     * @summary Inserisci feedback
     * @param {number} idNotifica
     * @param {FeedbackNotificaInsertRequestDto} feedbackNotificaInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inserisciFeedbackNotifica: async (
      idNotifica: number,
      feedbackNotificaInsertRequestDto: FeedbackNotificaInsertRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idNotifica' is not null or undefined
      assertParamExists('inserisciFeedbackNotifica', 'idNotifica', idNotifica);
      // verify required parameter 'feedbackNotificaInsertRequestDto' is not null or undefined
      assertParamExists(
        'inserisciFeedbackNotifica',
        'feedbackNotificaInsertRequestDto',
        feedbackNotificaInsertRequestDto
      );
      const localVarPath = `/v1/utenti/notifiche/{idNotifica}/feedbacks`.replace(
        `{${'idNotifica'}}`,
        encodeURIComponent(String(idNotifica))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        feedbackNotificaInsertRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per che restituisce il numero delle notifiche non lette in base all\'id utente
     * @summary Numero notifiche non lette
     * @param {'NON_LETTA' | 'LETTA' | 'ARCHIVIATA'} stato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificheNonLette: async (
      stato: 'NON_LETTA' | 'LETTA' | 'ARCHIVIATA',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'stato' is not null or undefined
      assertParamExists('notificheNonLette', 'stato', stato);
      const localVarPath = `/v1/utenti/notifiche-non-lette`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (stato !== undefined) {
        localVarQueryParameter['stato'] = stato;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per la ricerca delle notifiche utente
     * @summary Ricerca notifiche utente
     * @param {'NON_LETTA' | 'LETTA' | 'ARCHIVIATA'} [stato]
     * @param {boolean} [notArchiviate]
     * @param {boolean} [attivo]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNotifiche: async (
      stato?: 'NON_LETTA' | 'LETTA' | 'ARCHIVIATA',
      notArchiviate?: boolean,
      attivo?: boolean,
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/utenti/notifiche`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (stato !== undefined) {
        localVarQueryParameter['stato'] = stato;
      }

      if (notArchiviate !== undefined) {
        localVarQueryParameter['notArchiviate'] = notArchiviate;
      }

      if (attivo !== undefined) {
        localVarQueryParameter['attivo'] = attivo;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['pageNum'] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificheControllerApi - functional programming interface
 * @export
 */
export const NotificheControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificheControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per aggiornare lo stato della notifica
     * @summary Aggiorna stato notifica
     * @param {number} idNotifica
     * @param {NotificaPatchRequestDto} notificaPatchRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async aggiornaStatoLettura(
      idNotifica: number,
      notificaPatchRequestDto: NotificaPatchRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificaDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.aggiornaStatoLettura(
        idNotifica,
        notificaPatchRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest che restituisce l\'elenco dei feedback in base all\'id utente e notifica
     * @summary Elenco feedback
     * @param {number} idNotifica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async elencoFeedbackNotifica(
      idNotifica: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedbackNotificaDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.elencoFeedbackNotifica(
        idNotifica,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'eliminazione logica della notifica
     * @summary Elimina notifica
     * @param {number} idNotifica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eliminaNotifica(
      idNotifica: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificaDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eliminaNotifica(
        idNotifica,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'inserimento di un feedback per una notifica specifica
     * @summary Inserisci feedback
     * @param {number} idNotifica
     * @param {FeedbackNotificaInsertRequestDto} feedbackNotificaInsertRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inserisciFeedbackNotifica(
      idNotifica: number,
      feedbackNotificaInsertRequestDto: FeedbackNotificaInsertRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackNotificaDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inserisciFeedbackNotifica(
        idNotifica,
        feedbackNotificaInsertRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per che restituisce il numero delle notifiche non lette in base all\'id utente
     * @summary Numero notifiche non lette
     * @param {'NON_LETTA' | 'LETTA' | 'ARCHIVIATA'} stato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificheNonLette(
      stato: 'NON_LETTA' | 'LETTA' | 'ARCHIVIATA',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificheNonLette(stato, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per la ricerca delle notifiche utente
     * @summary Ricerca notifiche utente
     * @param {'NON_LETTA' | 'LETTA' | 'ARCHIVIATA'} [stato]
     * @param {boolean} [notArchiviate]
     * @param {boolean} [attivo]
     * @param {number} [pageNum]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {'ASC' | 'DESC'} [direction]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchNotifiche(
      stato?: 'NON_LETTA' | 'LETTA' | 'ARCHIVIATA',
      notArchiviate?: boolean,
      attivo?: boolean,
      pageNum?: number,
      pageSize?: number,
      sort?: string,
      direction?: 'ASC' | 'DESC',
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoNotificaDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchNotifiche(
        stato,
        notArchiviate,
        attivo,
        pageNum,
        pageSize,
        sort,
        direction,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NotificheControllerApi - factory interface
 * @export
 */
export const NotificheControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificheControllerApiFp(configuration);
  return {
    /**
     * API Rest per aggiornare lo stato della notifica
     * @summary Aggiorna stato notifica
     * @param {NotificheControllerApiAggiornaStatoLetturaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggiornaStatoLettura(
      requestParameters: NotificheControllerApiAggiornaStatoLetturaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<NotificaDto> {
      return localVarFp
        .aggiornaStatoLettura(
          requestParameters.idNotifica,
          requestParameters.notificaPatchRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest che restituisce l\'elenco dei feedback in base all\'id utente e notifica
     * @summary Elenco feedback
     * @param {NotificheControllerApiElencoFeedbackNotificaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    elencoFeedbackNotifica(
      requestParameters: NotificheControllerApiElencoFeedbackNotificaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<FeedbackNotificaDto>> {
      return localVarFp
        .elencoFeedbackNotifica(requestParameters.idNotifica, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'eliminazione logica della notifica
     * @summary Elimina notifica
     * @param {NotificheControllerApiEliminaNotificaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eliminaNotifica(
      requestParameters: NotificheControllerApiEliminaNotificaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<NotificaDto> {
      return localVarFp
        .eliminaNotifica(requestParameters.idNotifica, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'inserimento di un feedback per una notifica specifica
     * @summary Inserisci feedback
     * @param {NotificheControllerApiInserisciFeedbackNotificaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inserisciFeedbackNotifica(
      requestParameters: NotificheControllerApiInserisciFeedbackNotificaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<FeedbackNotificaDto> {
      return localVarFp
        .inserisciFeedbackNotifica(
          requestParameters.idNotifica,
          requestParameters.feedbackNotificaInsertRequestDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per che restituisce il numero delle notifiche non lette in base all\'id utente
     * @summary Numero notifiche non lette
     * @param {NotificheControllerApiNotificheNonLetteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificheNonLette(
      requestParameters: NotificheControllerApiNotificheNonLetteRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<number> {
      return localVarFp
        .notificheNonLette(requestParameters.stato, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per la ricerca delle notifiche utente
     * @summary Ricerca notifiche utente
     * @param {NotificheControllerApiSearchNotificheRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNotifiche(
      requestParameters: NotificheControllerApiSearchNotificheRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedDtoNotificaDto> {
      return localVarFp
        .searchNotifiche(
          requestParameters.stato,
          requestParameters.notArchiviate,
          requestParameters.attivo,
          requestParameters.pageNum,
          requestParameters.pageSize,
          requestParameters.sort,
          requestParameters.direction,
          requestParameters.q,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for aggiornaStatoLettura operation in NotificheControllerApi.
 * @export
 * @interface NotificheControllerApiAggiornaStatoLetturaRequest
 */
export interface NotificheControllerApiAggiornaStatoLetturaRequest {
  /**
   *
   * @type {number}
   * @memberof NotificheControllerApiAggiornaStatoLettura
   */
  readonly idNotifica: number;

  /**
   *
   * @type {NotificaPatchRequestDto}
   * @memberof NotificheControllerApiAggiornaStatoLettura
   */
  readonly notificaPatchRequestDto: NotificaPatchRequestDto;
}

/**
 * Request parameters for elencoFeedbackNotifica operation in NotificheControllerApi.
 * @export
 * @interface NotificheControllerApiElencoFeedbackNotificaRequest
 */
export interface NotificheControllerApiElencoFeedbackNotificaRequest {
  /**
   *
   * @type {number}
   * @memberof NotificheControllerApiElencoFeedbackNotifica
   */
  readonly idNotifica: number;
}

/**
 * Request parameters for eliminaNotifica operation in NotificheControllerApi.
 * @export
 * @interface NotificheControllerApiEliminaNotificaRequest
 */
export interface NotificheControllerApiEliminaNotificaRequest {
  /**
   *
   * @type {number}
   * @memberof NotificheControllerApiEliminaNotifica
   */
  readonly idNotifica: number;
}

/**
 * Request parameters for inserisciFeedbackNotifica operation in NotificheControllerApi.
 * @export
 * @interface NotificheControllerApiInserisciFeedbackNotificaRequest
 */
export interface NotificheControllerApiInserisciFeedbackNotificaRequest {
  /**
   *
   * @type {number}
   * @memberof NotificheControllerApiInserisciFeedbackNotifica
   */
  readonly idNotifica: number;

  /**
   *
   * @type {FeedbackNotificaInsertRequestDto}
   * @memberof NotificheControllerApiInserisciFeedbackNotifica
   */
  readonly feedbackNotificaInsertRequestDto: FeedbackNotificaInsertRequestDto;
}

/**
 * Request parameters for notificheNonLette operation in NotificheControllerApi.
 * @export
 * @interface NotificheControllerApiNotificheNonLetteRequest
 */
export interface NotificheControllerApiNotificheNonLetteRequest {
  /**
   *
   * @type {'NON_LETTA' | 'LETTA' | 'ARCHIVIATA'}
   * @memberof NotificheControllerApiNotificheNonLette
   */
  readonly stato: 'NON_LETTA' | 'LETTA' | 'ARCHIVIATA';
}

/**
 * Request parameters for searchNotifiche operation in NotificheControllerApi.
 * @export
 * @interface NotificheControllerApiSearchNotificheRequest
 */
export interface NotificheControllerApiSearchNotificheRequest {
  /**
   *
   * @type {'NON_LETTA' | 'LETTA' | 'ARCHIVIATA'}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly stato?: 'NON_LETTA' | 'LETTA' | 'ARCHIVIATA';

  /**
   *
   * @type {boolean}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly notArchiviate?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly attivo?: boolean;

  /**
   *
   * @type {number}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly pageSize?: number;

  /**
   *
   * @type {string}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly sort?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly direction?: 'ASC' | 'DESC';

  /**
   *
   * @type {string}
   * @memberof NotificheControllerApiSearchNotifiche
   */
  readonly q?: string;
}

/**
 * NotificheControllerApi - object-oriented interface
 * @export
 * @class NotificheControllerApi
 * @extends {BaseAPI}
 */
export class NotificheControllerApi extends BaseAPI {
  /**
   * API Rest per aggiornare lo stato della notifica
   * @summary Aggiorna stato notifica
   * @param {NotificheControllerApiAggiornaStatoLetturaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificheControllerApi
   */
  public aggiornaStatoLettura(
    requestParameters: NotificheControllerApiAggiornaStatoLetturaRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificheControllerApiFp(this.configuration)
      .aggiornaStatoLettura(
        requestParameters.idNotifica,
        requestParameters.notificaPatchRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest che restituisce l\'elenco dei feedback in base all\'id utente e notifica
   * @summary Elenco feedback
   * @param {NotificheControllerApiElencoFeedbackNotificaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificheControllerApi
   */
  public elencoFeedbackNotifica(
    requestParameters: NotificheControllerApiElencoFeedbackNotificaRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificheControllerApiFp(this.configuration)
      .elencoFeedbackNotifica(requestParameters.idNotifica, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'eliminazione logica della notifica
   * @summary Elimina notifica
   * @param {NotificheControllerApiEliminaNotificaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificheControllerApi
   */
  public eliminaNotifica(
    requestParameters: NotificheControllerApiEliminaNotificaRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificheControllerApiFp(this.configuration)
      .eliminaNotifica(requestParameters.idNotifica, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'inserimento di un feedback per una notifica specifica
   * @summary Inserisci feedback
   * @param {NotificheControllerApiInserisciFeedbackNotificaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificheControllerApi
   */
  public inserisciFeedbackNotifica(
    requestParameters: NotificheControllerApiInserisciFeedbackNotificaRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificheControllerApiFp(this.configuration)
      .inserisciFeedbackNotifica(
        requestParameters.idNotifica,
        requestParameters.feedbackNotificaInsertRequestDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per che restituisce il numero delle notifiche non lette in base all\'id utente
   * @summary Numero notifiche non lette
   * @param {NotificheControllerApiNotificheNonLetteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificheControllerApi
   */
  public notificheNonLette(
    requestParameters: NotificheControllerApiNotificheNonLetteRequest,
    options?: AxiosRequestConfig
  ) {
    return NotificheControllerApiFp(this.configuration)
      .notificheNonLette(requestParameters.stato, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per la ricerca delle notifiche utente
   * @summary Ricerca notifiche utente
   * @param {NotificheControllerApiSearchNotificheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificheControllerApi
   */
  public searchNotifiche(
    requestParameters: NotificheControllerApiSearchNotificheRequest = {},
    options?: AxiosRequestConfig
  ) {
    return NotificheControllerApiFp(this.configuration)
      .searchNotifiche(
        requestParameters.stato,
        requestParameters.notArchiviate,
        requestParameters.attivo,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.sort,
        requestParameters.direction,
        requestParameters.q,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
