import { Col, FormGroup, Input, Row } from 'design-react-kit';
import { useCompositeForm } from '../../hooks/useCompositeForm';
import AccordionElement from '../accordion-element/AccordionElement';
import { z } from 'zod';

export interface IFormNote {
  note?: string;
}

const FormNote: React.FC<{
  initValuesProps?: IFormNote;
  readingOnly?: boolean;
}> = ({ initValuesProps, readingOnly }) => {
  const { values, handleChange, setFieldValue, errors, touched, handleBlur } = useCompositeForm();

  return (
    <AccordionElement title="Note">
      <>
        <Row className="mt-4">
          <Col md={12} className="px-1">
            <FormGroup>
              <Input
                id="note"
                name="note"
                type="text"
                placeholder="Testo"
                label="Note"
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.note && !!errors.note}
                aria-describedby="noteError"
                disabled={readingOnly}
              />
            </FormGroup>
            {touched.note && errors.note && (
              <div id="noteError" className="invalid-feedback">
                {errors.note}
              </div>
            )}
          </Col>
        </Row>
      </>
    </AccordionElement>
  );
};

export default FormNote;
