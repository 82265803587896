import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  assegnaCodiceBilancio,
  detailsStruttureOrganizzative,
  resetUpdateForm,
} from '../../store/strutturaOrganizzativaSlice';
import { FormikProps, useFormik } from 'formik';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail/StruttureOrganizzativeDetails';
import { Alert, Button, Input } from 'design-react-kit';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import {
  CITTA,
  CODICE,
  DATA_INIZIO_VALIDITA,
  DECLARATORIA,
  DENOMINAZIONE,
  DIPARTIMENTO,
  EMAIL,
  FAX,
  INDIRIZZO,
  PEC,
  TELEFONO,
  TIPOLOGIA_STRUTTURA,
} from './struttureOrganizzativeConstants';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { z } from 'zod';
import {
  SOCodiceBilancioRequestDto,
  StrutturaOrganizzativaDtoTipoEnum,
} from '../../services/ms-anagrafica-unica';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import PageHeader from '../../components/common/page-header/PageHeader';

const initialValues: SOCodiceBilancioRequestDto = {
  codiceBilancioDipartimento: '',
  codiceBilancioSezione: '',
  requestType: 'CodiceBilancio',
};

const AssegnazioneCodiceBilancio = () => {
  const schemaConfirmDipartimento = z.object({
    codiceBilancioDipartimento: z
      .string({ invalid_type_error: 'required', required_error: 'required' })
      .max(2, 'lenghtcodiceBilancio'),
  });

  const schemaConfirmSezione = z.object({
    codiceBilancioSezione: z
      .string({ invalid_type_error: 'required', required_error: 'required' })
      .max(2, 'lenghtcodiceBilancio'),
  });

  const schemaSave = z.object({
    codiceBilancioDipartimento: z
      .string({ invalid_type_error: 'required', required_error: 'required' })
      .max(2, 'lenghtcodiceBilancio')
      .optional(),
    codiceBilancioSezione: z
      .string({ invalid_type_error: 'required', required_error: 'required' })
      .max(2, 'lenghtcodiceBilancio')
      .optional(),
  });

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fieldUsed: Array<string> = [
    CODICE,
    DENOMINAZIONE,
    DECLARATORIA,
    DATA_INIZIO_VALIDITA,
    TIPOLOGIA_STRUTTURA,
    DIPARTIMENTO,
    EMAIL,
    PEC,
    TELEFONO,
    FAX,
    INDIRIZZO,
    CITTA,
  ];
  const [button, setButtonSubmit] = useState('');

  useEffect(() => {
    dispatch(resetUpdateForm());
    getDetailsFromApi(id!);
  }, [0]);

  const strutturaOrganizzativa = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsResult
  );
  const strutturaOrganizzativaResult = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsStatus
  );
  const updateStatus = useAppSelector((state) => state.strutturaOrganizzativa.updateStatus);

  const schemaConfirm =
    strutturaOrganizzativa?.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento
      ? schemaConfirmDipartimento
      : strutturaOrganizzativa?.tipo === StrutturaOrganizzativaDtoTipoEnum.Sezione
        ? schemaConfirmSezione
        : z.object({});

  const validationSchema =
    button === 'CONFIRM'
      ? toFormikValidationSchema(schemaConfirm)
      : toFormikValidationSchema(schemaSave);

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id))
      .unwrap()
      .then((resp) => {
        if (resp.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento) {
          formData.setFieldValue('codiceBilancioDipartimento', resp.codiceDipartimentoRagioneria);
        }

        if (resp.tipo === StrutturaOrganizzativaDtoTipoEnum.Sezione) {
          formData.setFieldValue(
            'codiceBilancioDipartimento',
            resp.strutturaOrganizzativaPadre?.codiceDipartimentoRagioneria
          );
        }
      });
  };

  const addCodiceBilancio = () => {
    const body = {
      idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
      bodyRequest: formData.values,
    };

    dispatch(assegnaCodiceBilancio(body));
  };

  const formData: FormikProps<SOCodiceBilancioRequestDto> = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      addCodiceBilancio();
    },
  });

  const submitConfirm = () => {
    formData.setFieldValue('stato', 'CONFIRM');
    // if(strutturaOrganizzativa?.tipo == 'DIPARTIMENTO'){
    //     // formData.setFieldValue('codiceBilancioSezione', '00')
    // }
    setTimeout(() => {
      formData.submitForm();
    }, 500);
  };

  const submitSave = () => {
    formData.setFieldValue('stato', 'SAVE');
    setTimeout(() => {
      formData.submitForm();
    }, 500);
  };

  const getFieldError = (
    form: FormikProps<SOCodiceBilancioRequestDto>,
    fieldName: keyof SOCodiceBilancioRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const handleChange = (name: string, selectedOption: any) => {
    console.log('selected options', selectedOption);
    if (selectedOption != '') {
      formData.setFieldValue(name, selectedOption, false);
    } else {
      formData.setFieldValue(name, undefined, false);
    }
  };

  useEffect(() => {
    if (strutturaOrganizzativa) {
      if (strutturaOrganizzativa.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento) {
        formData.setValues({
          codiceBilancioDipartimento:
            strutturaOrganizzativa.codiceDipartimentoRagioneria ||
            formData.initialValues.codiceBilancioDipartimento,
          requestType: 'CodiceBilancio',
        });
      }
      if (strutturaOrganizzativa.tipo === StrutturaOrganizzativaDtoTipoEnum.Sezione) {
        formData.setValues({
          codiceBilancioSezione:
            strutturaOrganizzativa.codiceSezioneRagioneria ||
            formData.initialValues.codiceBilancioSezione,
          requestType: 'CodiceBilancio',
        });
      }
    }
  }, [strutturaOrganizzativa]);

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}
        state={{ form: true }}
        title={t('assegnazione Codice Bilancio')!}
      ></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && (
        <div>
          <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
          <div className="postion-relative py-5">
            <div className="form-row">
              {formData.values.codiceBilancioDipartimento == null &&
                strutturaOrganizzativa?.tipo === 'SEZIONE' && (
                  <Alert style={{ position: 'relative' }} color="danger">
                    {' '}
                    {t('mancanzaCodiceDipartimento')}
                  </Alert>
                )}

              <Input
                label={
                  formData.values.codiceBilancioDipartimento == null &&
                  strutturaOrganizzativa?.tipo === 'SEZIONE'
                    ? ''
                    : 'Codice Bilancio Dipartimento'
                }
                type="text"
                placeholder="Inserisci il Codice Bilancio Dipartimento"
                id="input-codiceBilancioDipartimento"
                wrapperClassName="form-group numberInputArrow col-md-12"
                name="codiceBilancioDipartimento"
                invalid={!!getFieldError(formData, 'codiceBilancioDipartimento')}
                infoText={t(getFieldError(formData, 'codiceBilancioDipartimento')) || ''}
                value={formData.values.codiceBilancioDipartimento || ''}
                onChange={(e) => handleChange('codiceBilancioDipartimento', e.target.value)}
                hidden={
                  formData.values.codiceBilancioDipartimento == null &&
                  strutturaOrganizzativa?.tipo === 'SEZIONE'
                    ? true
                    : false
                }
                disabled={strutturaOrganizzativa?.tipo === 'SEZIONE'}
              />

              {strutturaOrganizzativa?.tipo === 'SEZIONE' && (
                <Input
                  label="Codice Bilancio Sezione"
                  type="number"
                  placeholder="Inserisci il Codice Bilancio Sezione"
                  id="input-codiceBilancioSezione"
                  wrapperClassName="form-group required numberInputArrow col-md-12"
                  name="codiceBilancioSezione"
                  invalid={!!getFieldError(formData, 'codiceBilancioSezione')}
                  infoText={t(getFieldError(formData, 'codiceBilancioSezione')) || ''}
                  value={
                    formData.values.codiceBilancioSezione != undefined
                      ? formData.values.codiceBilancioSezione
                      : ''
                  }
                  onChange={(e) => handleChange('codiceBilancioSezione', e.target.value)}
                />
              )}
            </div>
            <div className="d-flex flex-row-reverse">
              {/* <Button color='primary' className="mt-2" onClick={() => { submitConfirm(); setButtonSubmit('CONFIRM') }}
                        disabled={formData.values.codiceBilancioDipartimento == null}>{t('conferma')}  </Button>*/}
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                disabled={formData.values.codiceBilancioDipartimento == null}
                onClick={() => {
                  submitSave();
                  setButtonSubmit('SAVE');
                }}
              >
                {t('salva')}
              </Button>
              <Button
                color="secondary"
                outline
                className="mt-2 mr-2"
                onClick={() =>
                  navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`, { state: { form: true } })
                }
              >
                {t('annulla')}{' '}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssegnazioneCodiceBilancio;
