import { useTranslation } from 'react-i18next';
import {
  getElencoAssegnazioniBandiPoContestoForm,
  annullaCandidatura,
  getElencoEQ,
} from '../../../store/bandoSlice';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  AssegnazioneBandiPoDto,
  BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest,
  CandidaturaBandoPoDtoStatoEnum,
  DocumentoDto,
  RuoloUtenteAutenticatoDto,
} from '../../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Fragment, useEffect, useState } from 'react';
import PageHeader from '../../../components/common/page-header/PageHeader';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import CustomSelect, {
  CustomSelectItem,
} from '../../../components/common/custom-select/CustomSelect';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import Pagination from '../../../components/common/pagination/Pagination';
import { HREF_CANDIDATURA_VISUALIZZA_BANDO } from '../../../components/layout/sidemenu/sidemenuConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utility/formatDate';
import { dettaglioDocumento, downloadDocument } from '../../../store/DocumentiSlice';
import { checkCampiRicercaValorizzati } from 'utility/utility';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';
import { upperCase } from 'lodash';

const ElencoBandiEQ = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { searchElencoEQResult, searchElencoEQStatus, searchElencoEQInput } = useAppSelector(
    (state) => state.bando
  );
  const { userInfoResult } = useAppSelector((state) => state.user);

  const [formBandiCollapseOpen, setFormBandiCollapseOpen] = useState<boolean>(false);
  const [modalAnnullaOpen, setModalAnnullaOpen] = useState<boolean>(false);
  const [idAssegnazioneSelected, setIdAssegnazioneSelected] = useState<any>(undefined);
  const [statiOptions, setStatiOptions] = useState<CustomSelectItem[]>([]);
  const [tipologiaBandoOptions, setTipologiaBandoOptions] = useState<CustomSelectItem[]>([]);
  const [struttureOrganizzative, setStruttureOrganizzative] = useState<CustomSelectItem[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

  let tipoEqOptions: CustomSelectItem[] = [
    { label: t('tipoEQ_PO'), value: 'PO' },
    { label: t('tipoEQ_PO_EQUIPARATA'), value: 'PO_EQUIPARATA' },
  ];

  const initialValues: BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest =
    (location.state?.form && searchElencoEQInput) || {
      dataApertura: '',
      dataChiusura: '',
      idSo: '',
      denominazionePo: '',
      stato: undefined,
      tipoPosizioneOrganizzativa: undefined,
      codiceCifra: '',
      sede: '',
      durataIncarico: undefined,
      tipologiaBando: undefined,
      pageNum: 0,
      pageSize: 10,
      sort: '',
      direction: 'ASC',
      q: '',
    };

  type Direction = 'ASC' | 'DESC';
  interface SortingState {
    direction: Direction;
  }

  useEffect(() => {
    dispatch(getElencoAssegnazioniBandiPoContestoForm())
      .unwrap()
      .then((resp) => {
        if (resp) {
          const selectItemsStruttureOrganizzative: Array<CustomSelectItem> = [];
          resp.struttureOrganizzative &&
            resp.struttureOrganizzative.forEach((f: any) => {
              selectItemsStruttureOrganizzative.push({
                value: f.valore,
                label: f.label,
              });
            });
          setStruttureOrganizzative(selectItemsStruttureOrganizzative);
          const selectItemsStato: Array<CustomSelectItem> = [];
          resp.stato &&
            resp.stato.forEach((f: any) => {
              selectItemsStato.push({
                value: f.valore,
                label: f.label,
              });
            });
          setStatiOptions(selectItemsStato);
          const selectTipologiaBando: Array<CustomSelectItem> = [];
          resp.tipologiaBando &&
            resp.tipologiaBando.forEach((t: any) => {
              selectTipologiaBando.push({
                value: t.valore,
                label: t.label,
              });
            });
          setTipologiaBandoOptions(selectTipologiaBando);
        }
      });
    doSearch();
  }, []);

  const schema = z
    .object({
      dataApertura: z.coerce.date().optional(),
      dataChiusura: z.coerce.date().optional(),
      stato: z.string().optional(),
      idSo: z.string().optional(),
      tipoPosizioneOrganizzativa: z.string().optional(),
      denominazionePo: z.string().optional(),
      codiceCifra: z.string().optional(),
      sede: z.string().optional(),
      durataIncarico: z.number().optional(),
      tipologiaBando: z.string().optional(),
    })
    .refine((data) => {
      if (data.dataApertura! > data.dataChiusura!) {
        const error = 'La data di apertura deve essere precedente alla data di chiusura';
        setErrorMessage(error);
        return {
          message: error,
          path: ['dataChiusura'],
        };
      } else {
        setErrorMessage('');
        return true;
      }
    });

  const searchForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: () => {
      doSearch();
    },
  });

  useEffect(() => {
    if (location.state?.form) {
      setFormBandiCollapseOpen(checkCampiRicercaValorizzati(searchElencoEQInput));
      searchForm.setValues({
        dataApertura: searchElencoEQInput?.dataApertura,
        dataChiusura: searchElencoEQInput?.dataChiusura,
        idSo: searchElencoEQInput?.idSo,
        denominazionePo: searchElencoEQInput?.denominazionePo,
        stato: searchElencoEQInput?.stato,
        tipoPosizioneOrganizzativa: searchElencoEQInput?.tipoPosizioneOrganizzativa,
        codiceCifra: searchElencoEQInput?.codiceCifra,
        sede: searchElencoEQInput?.sede,
        durataIncarico: searchElencoEQInput?.durataIncarico,
        tipologiaBando: searchElencoEQInput?.tipologiaBando,
        pageNum: searchElencoEQInput?.pageNum || 0,
        pageSize: searchElencoEQInput?.pageSize || 10,
        direction: searchElencoEQInput?.direction || '' || undefined,
        sort: searchElencoEQInput?.sort || '',
      });
    }
  }, [searchElencoEQInput]);

  const doSearch = () => {
    dispatch(getElencoEQ(searchForm.values));
  };

  const handleChangeStato = (selectedOption: any) => {
    searchForm.setFieldValue('stato', selectedOption);
    searchForm.handleChange(selectedOption);
  };

  const handleChangeTipoEq = (selectedOption: any) => {
    searchForm.setFieldValue('tipoPosizioneOrganizzativa', selectedOption);
    searchForm.handleChange(selectedOption);
  };

  const handleChangetipologiaEQ = (selectedOption: any) => {
    searchForm.setFieldValue('tipologiaBando', selectedOption);
    searchForm.handleChange(selectedOption);
  };

  const ordinamentoBandi = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const handlePageChangeBandi = (pageNum: number) => {
    searchForm.setFieldValue('pageNum', pageNum - 1);
    searchForm.handleSubmit();
  };

  const showScaricaRiepilogo = (candidatura: AssegnazioneBandiPoDto) =>
    props.operations.elencoFunzionalita?.includes('BANDO_PO_ELENCO_ASSEGNAZIONI') &&
    candidatura.elencoCandidature?.find(
      (item) =>
        upperCase(item.dipendenteRegionale?.codiceFiscale) === upperCase(userInfoResult?.cf) &&
        item.idRiepilogoFirmato
    );

  const showAnnullaCandidatura = (assegnazione: AssegnazioneBandiPoDto) =>
    props.operations.elencoFunzionalita?.includes('BANDO_PO_CANDIDATURA_ELIMINA') &&
    assegnazione.statoBando === 'APERTO' &&
    assegnazione.elencoCandidature?.find(
      (item) =>
        upperCase(item.dipendenteRegionale?.codiceFiscale) === upperCase(userInfoResult?.cf) &&
        item.stato === 'CONFERMATA'
    );

  const scaricaRiepologo = (riepilogoFirmato: DocumentoDto) => {
    let filename = '';
    let contentType = '';
    dispatch(dettaglioDocumento(riepilogoFirmato.id!))
      .unwrap()
      .then((resp) => {
        if (resp && resp.id) {
          filename = resp.filename || resp.nomeDocumento || 'RiepilogoCandidatura';
          contentType = resp.contentType || '';
          dispatch(
            downloadDocument({
              idDocumento: resp.id,
            })
          )
            .unwrap()
            .then((resp) => {
              if (!!resp) {
                const blob = new Blob([resp], { type: contentType });
                let url;
                const link = document.createElement('a');
                url = URL.createObjectURL(blob);
                link.href = url;
                link.download = filename;
                link.click();
              }
            });
        }
      });
  };

  const faiAnnullaCandidatura = () => {
    dispatch(annullaCandidatura({ id: idAssegnazioneSelected }))
      .unwrap()
      .then((resp) => {
        resp === 200 && doSearch();
      });
  };

  const printClassName = (assegnazione: AssegnazioneBandiPoDto) => {
    if (
      assegnazione.elencoCandidature?.find(
        (item) =>
          upperCase(item.dipendenteRegionale?.codiceFiscale) === upperCase(userInfoResult?.cf) &&
          item.stato !== CandidaturaBandoPoDtoStatoEnum.InComp &&
          item?.dataConferma
      )
    ) {
      return 'candidature-inviate';
    }
    return '';
  };

  const printDataInserimentoCandidatura = (assegnazione: AssegnazioneBandiPoDto) => {
    let candidatura = assegnazione.elencoCandidature?.find(
      (item) =>
        upperCase(item.dipendenteRegionale?.codiceFiscale) === upperCase(userInfoResult?.cf) &&
        item.stato !== CandidaturaBandoPoDtoStatoEnum.InComp &&
        item?.dataConferma
    );
    if (candidatura) return formatDate(candidatura?.dataConferma!, true);
    return ' - ';
  };

  return (
    <div className="elenco-bandi-eq">
      <PageHeader showIcon={false} title={t('Elenco EQ')!} buttons={[]} />
      {searchElencoEQStatus === STATUS_FULLFILLED &&
        searchElencoEQResult?.numeroCandidature! > 0 && (
          <div className="row col-12 d-flex justify-content-end">
            <div className="candidature-inviate px-3 mb-2 py-2 border-solid">
              Numero di candidature inviate: {searchElencoEQResult?.numeroCandidature}
            </div>
          </div>
        )}
      <Accordion className="form-custom form-bg filtri-ricerca" background="active">
        <AccordionHeader
          active={formBandiCollapseOpen}
          onToggle={() => setFormBandiCollapseOpen(!formBandiCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formBandiCollapseOpen}>
          <div className="form-row mt-2">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />
            <Input
              type="text"
              id="input-denominazione-po"
              placeholder="Denominazione EQ"
              wrapperClassName="col-md-6"
              name="denominazionePo"
              invalid={!!searchForm.errors.denominazionePo}
              infoText={t(searchForm.errors.denominazionePo || '') || ''}
              value={searchForm.values.denominazionePo}
              onChange={searchForm.handleChange}
            />
            <CustomSelect
              options={struttureOrganizzative}
              value={searchForm.values.idSo}
              placeholder="Seleziona la struttura organizzativa"
              invalid={!!searchForm.errors.idSo}
              infoText={''}
              disabled={struttureOrganizzative.length === 0}
              onChange={(e) => searchForm.setFieldValue('idSo', e, false)}
              name="idSo"
              wrapperClass="col-md-6"
            />
          </div>
          <div className="form-row">
            <CustomSelect
              options={statiOptions}
              value={searchForm.values.stato}
              placeholder="Seleziona stato"
              onChange={handleChangeStato}
              name="stato"
              wrapperClass="col-md-4"
            />
            <CustomSelect
              name="tipoEQ"
              placeholder="Seleziona un tipo EQ"
              options={tipoEqOptions}
              value={searchForm.values.tipoPosizioneOrganizzativa}
              onChange={handleChangeTipoEq}
              wrapperClass="col-md-4"
            />
            <Input
              type="text"
              placeholder="Inserisci il codice cifra"
              id="input-codiceCifra"
              wrapperClassName="col-md-4"
              name="codiceCifra"
              value={searchForm.values.codiceCifra}
              onChange={searchForm.handleChange}
              invalid={!!errorMessage}
              infoText={errorMessage}
            />
          </div>
          <div className="form-row">
            <Input
              type="text"
              id="sede"
              placeholder="Indica la sede"
              wrapperClassName="col col-md-4"
              name="sede"
              invalid={!!searchForm.errors.sede}
              infoText={t(searchForm.errors.sede || '') || ''}
              value={searchForm.values.sede}
              onChange={searchForm.handleChange}
            />
            <Input
              type="number"
              id="durataIncarico"
              placeholder="Indica la durata dell'incarico (mesi)"
              wrapperClassName="col col-md-4"
              name="durataIncarico"
              invalid={!!searchForm.errors.durataIncarico}
              infoText={t(searchForm.errors.durataIncarico || '') || ''}
              value={searchForm.values.durataIncarico || ''}
              onChange={searchForm.handleChange}
            />
            <CustomSelect
              name="tipologiaEQ"
              placeholder="Seleziona la tipologia EQ"
              options={tipologiaBandoOptions}
              value={searchForm.values.tipologiaBando}
              onChange={handleChangetipologiaEQ}
              wrapperClass="col-md-4"
            />
          </div>
          <div className="form-row mt-2">
            <Input
              type="date"
              label="Inserisci la data di apertura"
              placeholder="Inserisci la data di apertura"
              id="input-dataApertura"
              wrapperClassName="col-md-4"
              name="dataApertura"
              value={searchForm.values.dataApertura}
              onChange={searchForm.handleChange}
              invalid={!!errorMessage}
            />
            <Input
              type="date"
              label="Inserisci la data di chiusura"
              placeholder="Inserisci la data di chiusura"
              id="input-dataChiusura"
              wrapperClassName="col-md-4"
              name="dataChiusura"
              value={searchForm.values.dataChiusura}
              onChange={searchForm.handleChange}
              invalid={!!errorMessage}
              infoText={errorMessage}
            />
            <div className="d-flex flex-row-reverse col-4">
              <Button
                color="primary"
                className="mt-2"
                onClick={() => {
                  searchForm.setFieldValue('pageNum', 0);
                  searchForm.submitForm();
                }}
              >
                Applica
              </Button>
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                onClick={() => {
                  searchForm.setFieldValue('durataIncarico', '');
                  searchForm.resetForm();
                }}
                //onClick={() => searchForm.resetForm()}
              >
                Pulisci
              </Button>
            </div>
          </div>
        </AccordionBody>
      </Accordion>
      <div className="mt-4 position-relative">
        {searchElencoEQStatus === STATUS_FULLFILLED &&
          searchElencoEQResult?.totalElements === 0 && (
            <Alert color="info">Nessun risultato disponibile</Alert>
          )}
        {searchElencoEQStatus === STATUS_FULLFILLED && searchElencoEQResult?.totalElements! > 0 && (
          <div className={searchElencoEQResult?.totalPages === 1 ? 'mb-5' : ''}>
            <Table responsive striped size="sm">
              <TableTheadCustom
                colonne={[
                  { titolo: 'Denominazione EQ', nomeColonna: 'posizioneOrganizzativa' },
                  { titolo: 'Struttura organizzativa', nomeColonna: 'strutturaOrganizzativaBando' },
                  { titolo: 'Data apertura', nomeColonna: 'dataApertura' },
                  { titolo: 'Data chiusura', nomeColonna: 'dataChiusura' },
                  { titolo: 'Stato', nomeColonna: 'stato' },
                  { titolo: 'Data invio candidatura', nomeColonna: 'dataCandidatura' },
                  { titolo: 'Tipo EQ' },
                  { titolo: 'Codice Cifra' },
                  { titolo: 'Azioni' },
                ]}
                datiOrdinamento={searchForm.values}
                eseguiOrdinamento={ordinamentoBandi}
              />

              <tbody>
                {(searchElencoEQResult?.data || []).map((x, i) => (
                  <Fragment key={x.codiceUnivoco}>
                    <tr className={printClassName(x)}>
                      <td>{x.posizioneOrganizzativa?.denominazione}</td>
                      <td>{x.posizioneOrganizzativa?.nomeStrutturaOrganizzativa}</td>
                      <td>{formatDate(x.dataApertura!, true)}</td>
                      <td>{formatDate(x.dataChiusura!, true)}</td>
                      <td>{x.statoBando}</td>
                      <td>{printDataInserimentoCandidatura(x)}</td>
                      <td>{x.posizioneOrganizzativa?.tipoLabel || ' - '}</td>
                      <td>{x.codiceCifra}</td>
                      <td>
                        <UncontrolledDropdown direction="left">
                          <DropdownToggle nav>
                            <Icon icon="it-settings" color="primary" size="sm" />
                          </DropdownToggle>
                          <DropdownMenu className="no-arrow">
                            <LinkList>
                              <LinkListItem
                                size="medium"
                                onClick={() =>
                                  navigate(
                                    `${HREF_CANDIDATURA_VISUALIZZA_BANDO}/${x.codiceUnivoco}`
                                  )
                                }
                              >
                                <span>{t('Visualizza bando')}</span>
                              </LinkListItem>
                              {showScaricaRiepilogo(x) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    scaricaRiepologo(
                                      x.elencoCandidature?.at(0)?.idRiepilogoFirmato!
                                    )
                                  }
                                >
                                  <span>{t('Scarica riepilogo')}</span>
                                </LinkListItem>
                              )}
                              {showAnnullaCandidatura(x) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() => {
                                    setModalAnnullaOpen(true);
                                    setIdAssegnazioneSelected(x.codiceUnivoco);
                                  }}
                                >
                                  <span>{t('Annulla candidatura')}</span>
                                </LinkListItem>
                              )}
                            </LinkList>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <Modal
                      isOpen={modalAnnullaOpen}
                      toggle={() => setModalAnnullaOpen(!modalAnnullaOpen)}
                      labelledBy="modalAnnulla"
                      centered
                    >
                      <ModalHeader
                        toggle={() => {
                          setModalAnnullaOpen(!modalAnnullaOpen);
                        }}
                        id="modalAnnulla"
                      >
                        {t('Annulla candidatura')}
                      </ModalHeader>
                      <ModalBody>{t('Conferma annulla candidatura')}</ModalBody>
                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setModalAnnullaOpen(!modalAnnullaOpen);
                          }}
                        >
                          {t('Annulla')}
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => {
                            setModalAnnullaOpen(!modalAnnullaOpen);
                            faiAnnullaCandidatura();
                          }}
                        >
                          {t('Conferma')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Fragment>
                ))}
              </tbody>
            </Table>
            <Pagination
              totalCount={searchElencoEQResult?.totalElements || 0}
              siblingCount={1}
              currentPage={searchForm.values.pageNum! + 1}
              pageSize={searchElencoEQResult?.pageSize || 10}
              onPageChange={(page: number) => {
                handlePageChangeBandi(page);
              }}
              className="justify-content-center"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ElencoBandiEQ;
