import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';

import { z } from 'zod';
import { regexEmail } from '../pages/convenzioni/convenzioniConstants';
import {
  ContattoDTO,
  ConvenzioneDto,
  ConvenzioneInsertModifyRequestDto,
  ConvenzioniControllerApi,
  ConvenzioniControllerApiConvenzioniSearchRequest,
  EnteDTO,
  PagedDtoConvenzioneDto,
  TipologicaDto,
} from '../services/ms-anagrafica-unica';

export interface SelectItem {
  label: string;
  value: string | number;
  __isNew__?: boolean;
}

export interface SearchConvenzioneRequest {
  nomeConvenzione?: string;
  scopoConvenzione?: string;
  enteSottoscrittore?: number;
  responsabile?: string;
  strutturaReferente?: string;
  contattoTecnico?: string;
  contattoAmministrativo?: string;
  stato?: string;
  dataDecorrenza?: string;
  dataScadenza?: string;
  pageNum?: number;
  pageSize?: number;
  sort?: string;
  direction?: 'ASC' | 'DESC';
  q?: string;
}

export interface InsertConvenzioneRequest {
  nomeConvenzione: string;
  scopoConvenzione: string;
  entiSottoscrittori: Array<EnteDTO>;
  struttureRegionali: Array<TipologicaDto>;
  note: string;
  // stato?: string
  dataDecorrenza: string;
  dataScadenza: string;
  contattoAmministrativo: string;
  contattoTecnico: string;
  responsabile: string;
  strutturaReferente: string;
  dataAlert1: string;
  dataAlert2: string;
  dataAlert3: string;
  condizioniRinnovo: string;
  emailContatto: string;
  allegato1: string;
  allegato2: string;
  allegato3: string;
  dataRecesso: string;
  motivoRecesso: string;
  contatti: Array<ContattoDTO>;
}

export interface ConvenzioniState {
  state?: ConvenzioneDto;
  searchResult: PagedDtoConvenzioneDto;
  searchStatus: string;
  searchInput: SearchConvenzioneRequest | null;
  insertInput: InsertConvenzioneRequest | null;
  detailsResult: ConvenzioneDto | null;
  listaStati: TipologicaDto[];
  listaEnti: EnteDTO[];
  listaContatti: ContattoDTO[];
  listaStrutture: TipologicaDto[];
  insertStatus: string;
  detailsStatus: string;
  editStatus: string;
  insertCompleted: boolean;
  deleteStatus: string;
  errorText: string;
}

const initialState: ConvenzioniState = {
  searchResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  searchStatus: '',
  searchInput: null,
  insertInput: null,
  detailsResult: null,
  insertStatus: '',
  detailsStatus: '',
  listaStati: [],
  listaContatti: [],
  listaEnti: [],
  listaStrutture: [],
  editStatus: '',
  deleteStatus: '',
  insertCompleted: false,
  errorText: '',
};

const today = new Date();
today.setHours(0, 0, 0, 0);
export const schema = z
  .object({
    nomeConvenzione: z.string({ required_error: 'Campo obbligatorio' }),
    scopoConvenzione: z.string({ required_error: 'Campo obbligatorio' }),
    dataDecorrenza: z.coerce.date({ required_error: 'Campo obbligatorio' }),
    dataScadenza: z.coerce.date({ required_error: 'Campo obbligatorio' }),
    contattoAmministrativo: z.string({ required_error: 'Campo obbligatorio' }),
    contattoTecnico: z.string({ required_error: 'Campo obbligatorio' }),
    responsabile: z.string({ required_error: 'Campo obbligatorio' }),
    strutturaReferente: z.string({ required_error: 'Campo obbligatorio' }),
    dataAlert1: z.coerce.date({ required_error: 'Campo obbligatorio' }),
    dataAlert2: z.coerce.date().optional(),
    dataAlert3: z.coerce.date().optional(),
    dataRecesso: z.coerce.date().optional(),
    motivoRecesso: z.string().optional(),
    entiSottoscrittori: z.any({ required_error: 'Campo obbligatorio' }),
    contatti: z.any({ required_error: 'Campo obbligatorio' }),
    condizioniRinnovo: z.string({ required_error: 'Campo obbligatorio' }),
    emailContatto: z
      .string({ required_error: 'Campo obbligatorio' })
      .regex(regexEmail, { message: "La mail inserita non e' in un formato valido" }),
  })
  .refine((data) => data.dataScadenza > data.dataDecorrenza, {
    message: 'Data scadenza deve essere successiva alla Data decorrenza',
    path: ['dataScadenza'], // path of error
  })
  .refine((data) => data.dataScadenza >= data.dataAlert1, {
    message: 'Data Alert 1 non deve essere successiva alla Data scadenza',
    path: ['dataAlert1'], // path of error
  })
  .refine((data) => data.dataAlert1 > data.dataDecorrenza, {
    message: 'Data Alert 1 deve essere successiva alla Data decorrenza',
    path: ['dataAlert1'], // path of error
  })
  .refine((data) => !data.dataAlert2 || data.dataScadenza >= data.dataAlert2, {
    message: 'Data Alert 2 non deve essere successiva alla Data scadenza',
    path: ['dataAlert2'], // path of error
  })
  .refine((data) => !data.dataAlert2 || data.dataAlert2 > data.dataDecorrenza, {
    message: 'Data Alert 2 deve essere successiva alla Data decorrenza',
    path: ['dataAlert2'], // path of error
  })
  .refine((data) => !data.dataAlert3 || data.dataScadenza >= data.dataAlert3, {
    message: 'Data Alert 3 non deve essere successiva alla Data scadenza',
    path: ['dataAlert3'], // path of error
  })
  .refine((data) => !data.dataAlert3 || data.dataAlert3 > data.dataDecorrenza, {
    message: 'Data Alert 3 deve essere successiva alla Data decorrenza',
    path: ['dataAlert3'], // path of error
  })
  .refine((data) => !data.dataRecesso || data.dataRecesso < data.dataScadenza, {
    message: 'Data recesso deve essere antecedente alla Data scadenza',
    path: ['dataRecesso'], // path of error
  })
  .refine((data) => !data.dataRecesso || data.dataRecesso > data.dataDecorrenza, {
    message: 'Data recesso deve essere successiva alla Data decorrenza',
    path: ['dataRecesso'], // path of error
  })
  .refine((data) => !(!data.dataRecesso && data.motivoRecesso), {
    message: 'Campo obbligatorio',
    path: ['dataRecesso'], // path of error
  })
  .refine((data) => !(data.dataRecesso && !data.motivoRecesso), {
    message: 'Campo obbligatorio',
    path: ['motivoRecesso'], // path of error
  })
  .refine((data) => !data.dataAlert2 || data.dataAlert2.getTime() !== data.dataAlert1.getTime(), {
    message: 'Le date alert non posso coincidere',
    path: ['dataAlert2'], // path of error
  })
  .refine((data) => !data.dataAlert2 || data.dataAlert2.getTime() !== data.dataAlert1.getTime(), {
    message: 'Le date alert non posso coincidere',
    path: ['dataAlert1'], // path of error
  })
  .refine((data) => !data.dataAlert3 || data.dataAlert3.getTime() !== data.dataAlert1.getTime(), {
    message: 'Le date alert non posso coincidere',
    path: ['dataAlert3'], // path of error
  })
  .refine((data) => !data.dataAlert3 || data.dataAlert3.getTime() !== data.dataAlert1.getTime(), {
    message: 'Le date alert non posso coincidere',
    path: ['dataAlert1'], // path of error
  })
  .refine(
    (data) =>
      !data.dataAlert3 ||
      !data.dataAlert2 ||
      data.dataAlert2.getTime() !== data.dataAlert3.getTime(),
    {
      message: 'Le date alert non posso coincidere',
      path: ['dataAlert2'], // path of error
    }
  )
  .refine(
    (data) =>
      !data.dataAlert3 ||
      !data.dataAlert2 ||
      data.dataAlert2.getTime() !== data.dataAlert3.getTime(),
    {
      message: 'Le date alert non posso coincidere',
      path: ['dataAlert3'], // path of error
    }
  );

export const detailsConvenzione = createAsyncThunk('convenzioni/details', async (id: number) => {
  const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
  const response = await api.convenzioniDetail({
    idConvenzione: id,
  });
  return response.data;
});

export const searchConvenzioni = createAsyncThunk(
  'convenzioni/search',
  async (args: ConvenzioniControllerApiConvenzioniSearchRequest) => {
    const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
    const response = await api.convenzioniSearch(args);
    return response.data;
  }
);

export const insertConvenzione = createAsyncThunk(
  'convenzioni/insert',
  async (insertConvenzioneRequest: ConvenzioneInsertModifyRequestDto, { rejectWithValue }) => {
    const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
    try {
      const response = await api.convenzioniInsert({
        convenzioneInsertModifyRequestDto: insertConvenzioneRequest,
      });
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const editConvenzione = createAsyncThunk(
  'convenzioni/edit',
  async (editConvenzioneRequest: ConvenzioneInsertModifyRequestDto, { rejectWithValue }) => {
    const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
    try {
      const response = await api.convenzioniPatch({
        convenzioneInsertModifyRequestDto: editConvenzioneRequest,
      });
      return response?.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const deleteConvenzioni = createAsyncThunk('convenzioni/delete', async (id: number) => {
  const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
  const response = await api.convenzioniDelete({ idConvenzione: id });
  return response.data;
});

export const fetchStatiConvenzioni = createAsyncThunk(
  'convenzioni/find-all-stati-convenzioni',
  async () => {
    const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
    const response = await api.listaStati();
    return response.data;
  }
);

export const fetchEntiSottoscrittori = createAsyncThunk('convenzioni/find-all-enti', async () => {
  const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
  const response = await api.listaEnti();
  return response.data;
});

export const fetchContatti = createAsyncThunk('convenzioni/find-all-contatti', async () => {
  const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
  const response = await api.listaContatti();
  return response.data;
});

export const fetchStrutture = createAsyncThunk('convenzioni/find-all-strutture', async () => {
  const api = new ConvenzioniControllerApi(MS_AU_CONFIG);
  const response = await api.listaStrutture();
  return response.data;
});

export const ConvenzioniSlice = createSlice({
  name: 'ConvenzioniSlice',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.insertCompleted = false;
    },
  },
  extraReducers: (builder) => {
    // reducer ricerca
    builder.addCase(searchConvenzioni.pending, (state: any) => {
      state.searchStatus = STATUS_PENDING;
    });
    builder.addCase(searchConvenzioni.fulfilled, (state: ConvenzioniState, action: any) => {
      state.searchResult = action.payload;
      state.searchStatus = STATUS_FULLFILLED;
      state.insertCompleted = false;
      state.searchInput = action.meta.arg;
    });
    builder.addCase(searchConvenzioni.rejected, (state: any) => {
      state.searchStatus = STATUS_REJECTED;
    });

    // reducer dettaglio
    builder.addCase(detailsConvenzione.pending, (state: ConvenzioniState) => {
      state.detailsStatus = STATUS_PENDING;
    });
    builder.addCase(detailsConvenzione.fulfilled, (state: ConvenzioniState, action) => {
      state.detailsResult = action.payload;
      state.insertCompleted = false;
      state.detailsStatus = STATUS_FULLFILLED;
    });
    builder.addCase(detailsConvenzione.rejected, (state: ConvenzioniState) => {
      state.detailsStatus = STATUS_REJECTED;
    });

    // reducer inserimento
    builder.addCase(insertConvenzione.pending, (state: ConvenzioniState) => {
      state.insertStatus = STATUS_PENDING;
    });
    builder.addCase(insertConvenzione.fulfilled, (state: ConvenzioniState) => {
      state.insertStatus = STATUS_FULLFILLED;
      state.insertCompleted = true;
    });
    builder.addCase(insertConvenzione.rejected, (state: ConvenzioniState, action: any) => {
      state.errorText = action.payload?.parameters[0]?.value;
      state.insertStatus = STATUS_REJECTED;
    });

    // reducer modifica
    builder.addCase(editConvenzione.pending, (state: ConvenzioniState) => {
      state.editStatus = STATUS_PENDING;
    });
    builder.addCase(editConvenzione.fulfilled, (state: ConvenzioniState) => {
      state.editStatus = STATUS_FULLFILLED;
    });
    builder.addCase(editConvenzione.rejected, (state: ConvenzioniState, action: any) => {
      state.errorText = action.payload?.parameters[0]?.value;
      state.editStatus = STATUS_REJECTED;
    });

    // reducer elimina
    builder.addCase(deleteConvenzioni.pending, (state: ConvenzioniState) => {
      state.deleteStatus = STATUS_PENDING;
    });
    builder.addCase(deleteConvenzioni.fulfilled, (state: ConvenzioniState) => {
      state.deleteStatus = STATUS_FULLFILLED;
    });
    builder.addCase(deleteConvenzioni.rejected, (state: ConvenzioniState) => {
      state.deleteStatus = STATUS_REJECTED;
    });

    // reducer fetch lista stati
    builder.addCase(fetchStatiConvenzioni.fulfilled, (state: ConvenzioniState, action) => {
      state.listaStati = action.payload;
    });

    // reducer fetch lista enti
    builder.addCase(fetchEntiSottoscrittori.fulfilled, (state: ConvenzioniState, action) => {
      state.listaEnti = action.payload;
    });

    // reducer fetch lista enti
    builder.addCase(fetchStrutture.fulfilled, (state: ConvenzioniState, action) => {
      state.listaStrutture = action.payload;
    });

    // reducer fetch lista contatti
    builder.addCase(fetchContatti.fulfilled, (state: ConvenzioniState, action) => {
      state.listaContatti = action.payload;
    });
  },
});
export const { resetStatus } = ConvenzioniSlice.actions;

export default ConvenzioniSlice.reducer;
