import axios from 'axios';
import { useEffect, useState } from 'react';
import type { IOnboardingConcorso } from './useGetOnboardingById';

export interface ICandidatoConcorso {
  id: number;
  // Dati anagrafici
  codiceFiscale: string;
  cognome: string;
  nome: string;
  genere: 'FEMMINILE' | 'MASCHILE';
  statoCivile: string;
  cittadinanza: string;
  dataNascita: string;
  nazioneNascita: string;
  provNascita: string;
  cittaNascita: string;
  // Estremi identificazione
  tipoDocumento: string;
  numeroCartaIdentita: string;
  enteEmittente: string;
  dataRilascio: string;
  dataScadenza: string;
  // Recapiti
  indirizzoPec?: string;
  email?: string;
  telefonoAbitazione?: string;
  telefonoCellulare?: string;
  // Dati residenza
  statoResidenza: string;
  provinciaResidenza?: string;
  cittaResidenza?: string;
  indirizzoResidenza: string;
  capResidenza: string;
  // Dati domicilio
  domicilioDifferente: boolean;
  statoDomicilio?: string;
  provinciaDomicilio?: string;
  cittaDomicilio?: string;
  indirizzoDomicilio?: string;
  capDomicilio?: string;
  // Disabilità
  disabile: boolean;
  percentualeDisabilita?: number;
  collocamentoMirato?: boolean;
  //fileCollocamento?: File;
  //documentiDisabilita?: Array<File>;
  // Titolo di studio
  titoloStudio?: string | null;
  titoloDichiarato?: boolean;
  noteTitolo?: string;
  // Concorsi
  onboardingConcorsi: Array<IOnboardingConcorso>;
}

const useGetCandidatoByCodiceFiscale = (codiceFiscale?: string) => {
  useEffect(() => {
    if (!codiceFiscale) return;
    axios
      .get(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/anpr/${codiceFiscale}`)
      .then((response) => {
        setCandidato(response.data);
      })
      .catch((error) => {
        console.error('Error fetching candidato:', error);
      });
  }, [codiceFiscale]);

  const [candidato, setCandidato] = useState<ICandidatoConcorso | undefined>(undefined);

  return candidato;
};

export default useGetCandidatoByCodiceFiscale;
