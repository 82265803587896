import { useParams } from 'react-router-dom';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import FormCv from 'components/FormCv/FormCv';
import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormNote from 'components/FormNote/FormNote';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import { FormReadingOnly } from 'components/forms/FormReadingOnly/FormReadingOnly';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import FormGeneraContratto from 'components/forms/FormGeneraContratto/FormGeneraContratto';
import { Row, Col, FormGroup, Input, Button, Form } from 'design-react-kit';
import { errors } from 'jose';
import { values } from 'lodash';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';
import { useAppDispatch } from 'hooks';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';

export const FormNoteUfficioReclutamento = () => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);

  const form = useFormik({
    initialValues: { noteUfficioReclutamento: '' },
    validationSchema: toFormikValidationSchema(
      z.object({
        noteUfficioReclutamento: z
          .string({
            required_error: 'Obbligatorio',
          })
          .min(1, 'Obbligatorio')
          .max(5000, 'Massimo 5000 caratteri'),
      })
    ),
    onSubmit: ({ noteUfficioReclutamento }) => {
      if (!onboarding?.id) {
        dispatch(
          showNotifica({
            titolo: 'Inserimento mancata presenza non è andatq a buon fine',
            stato: 'error',
            messaggio: 'Inserimento mancata presenza non è andatq a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
        return;
      }

      try {
        axios.post(
          `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/${onboarding.id}/accettazione/assunzione`,
          {
            ...candidato,
            onboardingConcorsi: [
              {
                ...onboarding,
                noteUfficioReclutamento,
              },
            ],
          }
        );
      } catch (error) {
        dispatch(
          showNotifica({
            titolo: 'Inserimento mancata presenza non è andatq a buon fine',
            stato: 'error',
            messaggio: 'Inserimento mancata presenza non è andatq a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
      }
    },
  });

  return (
    <div
      style={{
        paddingTop: '20px',
      }}
    >
      <Form>
        <Row className="mt-4">
          <Col md={6} className="px-1">
            <Input
              id="noteUfficioReclutamento"
              name="noteUfficioReclutamento"
              type="text"
              placeholder="Inserisci note ufficio reclutamento"
              label="Note ufficio reclutamento"
              value={form.values.noteUfficioReclutamento}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              invalid={
                form.touched.noteUfficioReclutamento && !!form.errors.noteUfficioReclutamento
              }
              wrapperClassName="required"
              infoText={form.errors.noteUfficioReclutamento && form.errors.noteUfficioReclutamento}
            />
          </Col>
          <Col>
            <Button className="ml-2" color="primary" onClick={() => form.handleSubmit()}>
              Inserisci note
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const GestioneContrattualizzazione = () => {
  const { codiceFiscale } = useParams();
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={candidato ? `${candidato.nome} ${candidato.cognome}` : 'Visualizza candidato'}
      />
      <FormReadingOnly>
        <FormDatiAnagrafici readingOnly />
        <FormEstremiIdentificazione readingOnly />
        <FormRecapiti readingOnly />
        <FormDatiResidenza readingOnly />
        <FormDomicilio readingOnly />
        <FormDatiDisabilita readingOnly />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv readingOnly />
        <FormNote readingOnly />
      </FormReadingOnly>
      <FormNoteUfficioReclutamento />
      <FormGeneraContratto />
    </div>
  );
};

export default GestioneContrattualizzazione;
