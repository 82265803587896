import { showFieldIfNotNull } from 'utility/utility';
import { CessazioneDipendenteProps } from './CessazioneDipendenteDetailsComponent';
import { formatDate } from 'utility/formatDate';

const CessazioneDipendenteLightDetails = (props: CessazioneDipendenteProps) => {
  return (
    <div className="col-sm-12 cessazione-dipendente">
      {(props.detailsResult?.id ||
        props.detailsResult?.nome ||
        props.detailsResult?.cognome ||
        props.detailsResult?.codiceFiscale ||
        props.detailsResult?.elencoStruttureOrganizzative ||
        props.detailsResult?.tipoContratto ||
        props.detailsResult?.elencoIncarichiInCorso ||
        props.detailsResult?.dataInizioRapporto) && (
        <div className="card-div col-sm-12">
          <div className="col-sm-12">
            <div className="info-box-light bg-light-gray">
              <h5 className="pb-2 border-bottom font-weight-bold">Riepilogo</h5>
              <div className="row p-2">
                <div className="col-12 col-xl-3">
                  <dt>Matricola</dt>
                  <dd>{showFieldIfNotNull(props.detailsResult?.id)}</dd>
                </div>
                <dl className="col-12 col-xl-3">
                  <dt>Nome</dt>
                  <dd>{showFieldIfNotNull(props.detailsResult?.nome)}</dd>
                </dl>
                <dl className="col-12 col-xl-3">
                  <dt>Cognome</dt>
                  <dd>{showFieldIfNotNull(props.detailsResult?.cognome)}</dd>
                </dl>
                <dl className="col-12 col-xl-3">
                  <dt>Codice fiscale</dt>
                  <dd>{showFieldIfNotNull(props.detailsResult?.codiceFiscale)}</dd>
                </dl>

                {props.detailsResult?.numeroSO! > 0 && (
                  <dl className="col-sm-12">
                    <dt>Strutture organizzative di appartenenza</dt>
                    <dd>
                      {props.detailsResult?.numeroSO === 1 ? (
                        `${props.detailsResult?.elencoStruttureOrganizzative![0].descrizione}`
                      ) : (
                        <ul>
                          {props.detailsResult?.elencoStruttureOrganizzative!.map((data) => (
                            <li key={data.descrizione}>{data.descrizione}</li>
                          ))}{' '}
                        </ul>
                      )}
                    </dd>
                  </dl>
                )}

                {props.detailsResult?.tipoContratto && (
                  <dl className="col-sm-12">
                    <dt>Tipologia contrattuale</dt>
                    <dd>{props.detailsResult?.tipoContratto}</dd>
                  </dl>
                )}
                {props.detailsResult?.elencoIncarichiInCorso &&
                  props.detailsResult?.elencoIncarichiInCorso.length > 0 && (
                    <dl className="col-sm-12">
                      <dt>Incarichi in corso</dt>
                      <dd>
                        {props.detailsResult?.numeroIncarichiInCorso === 1 ? (
                          `${props.detailsResult?.elencoIncarichiInCorso[0]}`
                        ) : (
                          <ul key={props.detailsResult?.id}>
                            {props.detailsResult?.elencoIncarichiInCorso!.map((data, i) => (
                              <li key={i}>{data}</li>
                            ))}{' '}
                          </ul>
                        )}
                      </dd>
                    </dl>
                  )}

                <dl className="col-sm-12">
                  <dt>Data inizio rapporto</dt>
                  <dd>{formatDate(props.detailsResult?.dataInizioRapporto, false, '-')}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CessazioneDipendenteLightDetails;
