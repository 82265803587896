import { Button, Col, FormGroup, Icon, Input, Row } from 'design-react-kit';
import { useRef } from 'react';
import AccordionElement from '../accordion-element/AccordionElement';
import { useCompositeForm } from '../../hooks/useCompositeForm';
import CustomSelect from 'components/custom-select/CustomSelect';
import TabellaAllegati from './TabellaAllegati';
import useDownloadDocumentiIdentificazionePdfs from 'hooks/services/useDownloadDocumentiIdentificazionePdfs';
import { ErrorMessage } from 'components/error-message/ErrorMessage';

export interface IFormEstremiIdentificazione {
  tipoDocumento: string;
  numeroCartaIdentita: string;
  enteEmittente: string;
  dataRilascio: string | null;
  dataScadenza: string | null;
  filesIdentificazione: File[];
}

const FormEstremiIdentificazione: React.FC<{
  initValuesProps?: IFormEstremiIdentificazione;
  readingOnly?: boolean;
}> = ({ initValuesProps, readingOnly }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { values, handleChange, handleBlur, setFieldValue, errors, touched } = useCompositeForm();

  useDownloadDocumentiIdentificazionePdfs({
    onFilesReady: (files) => {
      setFieldValue('filesIdentificazione', files);
    },
  });

  function deleteFile(fileIndex: number) {
    setFieldValue(
      'filesIdentificazione',
      values.filesIdentificazione?.filter((_: any, index: number) => index !== fileIndex)
    );
  }

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue('filesIdentificazione', [...(values.filesIdentificazione ?? []), file]);
    }

    if (uploadInputRef.current) {
      uploadInputRef.current.value = '';
    }
  };

  const handleTipoDocumentoChange = (event: any) => {
    setFieldValue('tipoDocumento', event);
  };

  return (
    <AccordionElement title="Estremi di Identificazione">
      <>
        <Row className="mt-4">
          <Col md={4} className="px-1" style={{ marginTop: '9px' }}>
            <CustomSelect
              label="Tipo Documento"
              name="tipoDocumento"
              placeholder="Seleziona il tipo di documento"
              options={[
                { value: 'CARTA_IDENTITA', label: "Carta d'identità" },
                { value: 'PASSAPORTO', label: 'Passaporto' },
                { value: 'PATENTE', label: 'Patente di guida' },
              ]}
              value={values.tipoDocumento}
              invalid={!!errors.tipoDocumento}
              infoText={touched.tipoDocumento && errors.tipoDocumento}
              onChange={handleTipoDocumentoChange}
              wrapperClass="col-md required"
              disabled={readingOnly}
            />
          </Col>
          <Col md={4} className="px-1">
            <FormGroup check>
              <Input
                id="numeroCartaIdentita"
                name="numeroCartaIdentita"
                type="text"
                label="Numero documento identità"
                placeholder="Numero documento identità"
                value={values.numeroCartaIdentita}
                onBlur={handleBlur}
                onChange={handleChange}
                aria-describedby="numeroDocumentoError"
                wrapperClassName="required"
                invalid={touched.numeroCartaIdentita && !!errors.numeroCartaIdentita}
                infoText={
                  touched.numeroCartaIdentita &&
                  errors.numeroCartaIdentita &&
                  errors.numeroCartaIdentita
                }
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="px-1">
            <FormGroup check>
              <Input
                id="enteEmittente"
                name="enteEmittente"
                value={values.enteEmittente}
                type="text"
                placeholder="Inserisci nome ente"
                onChange={handleChange}
                label="Ente emittente"
                invalid={touched.enteEmittente && !!errors.enteEmittente}
                aria-describedby="enteEmittenteError"
                wrapperClassName="required"
                onBlur={handleBlur}
                infoText={touched.enteEmittente && errors.enteEmittente && errors.enteEmittente}
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="px-1">
            <Input
              id="dataRilascio"
              name="dataRilascio"
              type="date"
              label="Data di rilascio"
              className="active"
              placeholder="gg/mm/aaaa"
              value={values.dataRilascio}
              onChange={(e) => setFieldValue('dataRilascio', e.target.value)}
              wrapperClassName="required"
              invalid={touched.dataRilascio && !!errors.dataRilascio}
              infoText={touched.dataRilascio && errors.dataRilascio && errors.dataRilascio}
              disabled={readingOnly}
            />
          </Col>
          <Col md={6} className="px-1">
            <Input
              id="dataScadenza"
              name="dataScadenza"
              type="date"
              className="active"
              label="Data di scadenza"
              placeholder="gg/mm/aaaa"
              value={values.dataScadenza}
              onChange={(e) => setFieldValue('dataScadenza', e.target.value)}
              invalid={touched.dataScadenza && !!errors.dataScadenza}
              aria-describedby="dataScadenzaError"
              wrapperClassName="required"
              infoText={touched.dataScadenza && errors.dataScadenza && errors.dataScadenza}
              disabled={readingOnly}
            />
          </Col>
          <input
            id="fileInput"
            type="file"
            accept=".pdf"
            ref={uploadInputRef}
            onChange={handleFileUpload}
            hidden
          />
        </Row>

        {values.filesIdentificazione && values.filesIdentificazione?.length > 0 && (
          <Row>
            <Col md={12}>
              <TabellaAllegati deleteFile={deleteFile} items={values?.filesIdentificazione} />
            </Col>
          </Row>
        )}

        <Button
          /* @ts-ignore */
          onClick={() => uploadInputRef.current?.click()}
          color="primary"
          outline
          size="sm"
          disabled={readingOnly}
        >
          <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
          Seleziona file
        </Button>
        <ErrorMessage touched={touched.filesIdentificazione} error={errors.filesIdentificazione} />
      </>
    </AccordionElement>
  );
};

export default FormEstremiIdentificazione;
