/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OptionDtoSiNo
 */
export interface OptionDtoSiNo {
  /**
   *
   * @type {string}
   * @memberof OptionDtoSiNo
   */
  value?: OptionDtoSiNoValueEnum;
  /**
   *
   * @type {string}
   * @memberof OptionDtoSiNo
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OptionDtoSiNo
   */
  valore: OptionDtoSiNoValoreEnum;
}

export const OptionDtoSiNoValueEnum = {
  S: 'S',
  N: 'N',
} as const;

export type OptionDtoSiNoValueEnum =
  (typeof OptionDtoSiNoValueEnum)[keyof typeof OptionDtoSiNoValueEnum];
export const OptionDtoSiNoValoreEnum = {
  S: 'S',
  N: 'N',
} as const;

export type OptionDtoSiNoValoreEnum =
  (typeof OptionDtoSiNoValoreEnum)[keyof typeof OptionDtoSiNoValoreEnum];
