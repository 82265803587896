import { PersoneSedeDto } from '../../../services/ms-anagrafica-unica';
import React, { useState } from 'react';
import { Table } from 'design-react-kit';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const RIPersoneComponent = (dati: { value: PersoneSedeDto }) => {
  const initialState = (dati?.value?.persona ?? []).map(() => false);
  const [rowsVisibility, setRowsVisibility] = useState<boolean[]>(initialState);

  function handleClick(index: number) {
    let rows = !rowsVisibility[index] ? [...initialState] : [...rowsVisibility];
    rows[index] = !rowsVisibility[index];
    setRowsVisibility([...rows]);
  }

  return (
    <div className={'col-12 pt-5'}>
      <Table striped responsive>
        <thead className={'primary-bg text-white'}>
          <tr>
            <th></th>
            <th>Cognome</th>
            <th>Nome</th>
            <th>Data di nascita</th>
            <th>Codice fiscale</th>
          </tr>
        </thead>
        <tbody>
          {(dati.value.persona ?? []).map((persona, index) => (
            <React.Fragment key={'persona' + persona?.['persona-fisica']?.['c-fiscale']}>
              <tr>
                <td>
                  <Button outline color="primary" onClick={() => handleClick(index)}>
                    {rowsVisibility[index] ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </Button>
                </td>
                <td>{persona?.['persona-fisica']?.cognome ?? '-'}</td>
                <td>{persona?.['persona-fisica']?.nome ?? '-'}</td>
                <td>{persona?.['persona-fisica']?.['estremi-nascita']?.dt ?? '-'}</td>
                <td>{persona?.['persona-fisica']?.['c-fiscale'] ?? '-'}</td>
              </tr>
              {rowsVisibility[index] && (
                <tr>
                  <td colSpan={5}>
                    <ul>
                      {(
                        persona?.['atti-conferimento-cariche']?.['atto-conferimento-cariche'] ?? []
                      ).map((carica) => (
                        <li key={'carica' + carica?.cariche?.carica?.value}>
                          {carica?.cariche?.carica?.value}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RIPersoneComponent;
