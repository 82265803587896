import {
  DropdownMenu,
  DropdownToggle,
  Icon,
  LinkList,
  LinkListItem,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import {
  AssegnazioneBandoPoRequestDto,
  PosizioneOrganizzativaDto,
} from '../../../services/ms-anagrafica-unica';

interface TableElencoEQABandoProps {
  content: Array<AssegnazioneBandoPoRequestDto>;
  EQInseriteABando: Array<PosizioneOrganizzativaDto>;
  apriVisualizzaDettaglioEQABando: Function;
  rimuoviEQDaBando: Function;
}

const TableElencoEQABando = (props: TableElencoEQABandoProps) => {
  return (
    <div className="mb-3">
      <Table responsive striped>
        <thead>
          <tr>
            <th scope="col">
              <div className="table-order">Denominazione</div>
            </th>
            <th scope="col">
              <div className="table-order">Struttura Organizzativa</div>
            </th>
            <th scope="col">
              <div className="table-order">Tipologia EQ</div>
            </th>
            <th scope="col">
              <div className="table-order">Associato</div>
            </th>
            <th scope="col">
              <div className="table-order">Data fine associazione</div>
            </th>
            <th scope="col">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {(props.content || []).map((eq, index) => (
            <tr key={index}>
              <td>
                {
                  props.EQInseriteABando.filter(
                    (eqDetail) => eqDetail.codiceUnivoco === eq.idPosizioneOrganizzativa
                  ).at(0)?.denominazione
                }
              </td>
              <td>
                {
                  props.EQInseriteABando.filter(
                    (eqDetail) => eqDetail.codiceUnivoco === eq.idPosizioneOrganizzativa
                  ).at(0)?.nomeStrutturaOrganizzativa
                }
              </td>
              <td>
                {props.EQInseriteABando.filter(
                  (eqDetail) => eqDetail.codiceUnivoco === eq.idPosizioneOrganizzativa
                ).at(0)?.tipoLabel || '-'}
              </td>
              <td>
                {
                  props.EQInseriteABando.filter(
                    (eqDetail) => eqDetail.codiceUnivoco === eq.idPosizioneOrganizzativa
                  ).at(0)?.dipendenteRegionale
                }
              </td>
              <td>
                {
                  props.EQInseriteABando.filter(
                    (eqDetail) => eqDetail.codiceUnivoco === eq.idPosizioneOrganizzativa
                  ).at(0)?.dataFine
                }
              </td>
              <td>
                <UncontrolledDropdown direction="left">
                  <DropdownToggle nav>
                    <Icon icon="it-settings" color="primary" size="sm" />
                  </DropdownToggle>
                  <DropdownMenu className="no-arrow">
                    <LinkList>
                      <LinkListItem
                        size="medium"
                        onClick={() =>
                          props.apriVisualizzaDettaglioEQABando(eq.idPosizioneOrganizzativa)
                        }
                      >
                        <span>Dettaglio assegnazione</span>
                      </LinkListItem>
                      <LinkListItem
                        size="medium"
                        onClick={() => props.rimuoviEQDaBando(eq.idPosizioneOrganizzativa)}
                      >
                        <span>Rimuovi assegnazione</span>
                      </LinkListItem>
                    </LinkList>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableElencoEQABando;
