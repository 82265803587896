import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA } from '../../components/layout/sidemenu/sidemenuConstants';
import {
  loadDipartimenti,
  setBreadcrumbItem,
  setDipartimentoSelezionato,
  reset,
  loadDipartimentiStorico,
  setDataStorico,
  setOrganigrammaStorico,
  setFromOrganigrammaPage,
} from '../../store/organigrammaSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { Alert, Button, Input, UncontrolledTooltip } from 'design-react-kit';
import { DipartimentoDto, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import { showNotifica } from '../../store/loaderSlice';
import { NOTIFICA_STATO } from '../../utility/Notifica';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import PageTopHeader from '../../components/common/page-header/PageTopHeader';
import {
  AccountTree,
  Dialog,
  DirectionBus,
  DocTransmission,
  EmergencyHeat,
  Finance,
  FinanceMode,
  Gavel,
  GroupPeople,
  Handshake,
  Monitor,
  Nature,
  NaturePeople,
  Organization,
  Pa,
  Picture,
  Presidente,
  Raven,
  School,
  Star,
} from 'assets/icons/organigramma';

function Organigramma(props: { operations: RuoloUtenteAutenticatoDto }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    elencoDipartimentiResult,
    elencoDipartimentiStoricoResult,
    elencoDipartimentiStatus,
    elencoDipartimentiStoricoStatus,
    dataStorico,
  } = useAppSelector((state) => state.organigramma);
  const [showDettaglioStrutturaOrganizzativa, setShowDettaglioStrutturaOrganizzativa] =
    useState<boolean>(false);
  const [isStorico, setIsStorico] = useState<boolean>(false);

  const oneDay = 3600 * 1000 * 24;

  const showOrganigrammaOperations = () => {
    props.operations?.elencoFunzionalita?.includes('ORGANIGRAMMA_DETTAGLIO') &&
      setShowDettaglioStrutturaOrganizzativa(true);
  };

  const validationSchema = z.object({
    dataInput: z.coerce
      .date()
      .max(new Date(Date.now() - oneDay), {
        message: 'Il campo data invio deve essere inferiore alla data odierna',
      })
      .optional(),
  });

  const initialValues = {
    dataInput: undefined,
  };

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: (values) => {
      if (values.dataInput) {
        console.log('Submit form', JSON.stringify(values, null, 2));
        var data = new Date(values.dataInput!);
        data.setUTCHours(23);
        data.setMinutes(59);
        data.setSeconds(59);
        setIsStorico(true);
        dispatch(loadDipartimentiStorico(data.toISOString()));
      } else {
        setIsStorico(false);
        dispatch(loadDipartimenti());
      }
    },
  });

  const hasResults = () => {
    if (!isStorico)
      return elencoDipartimentiResult && elencoDipartimentiResult.totalElements
        ? elencoDipartimentiResult.totalElements > 0
        : false;
    else
      return elencoDipartimentiStoricoResult && elencoDipartimentiStoricoResult.totalElements
        ? elencoDipartimentiStoricoResult.totalElements > 0
        : false;
  };

  const navigateToTop = (so: DipartimentoDto) => {
    if (showDettaglioStrutturaOrganizzativa) {
      dispatch(setDipartimentoSelezionato(so));
      dispatch(setBreadcrumbItem(so));
      if (isStorico) {
        dispatch(setOrganigrammaStorico(isStorico));
        dispatch(setDataStorico(form.values.dataInput!));
      } else {
        dispatch(setOrganigrammaStorico(false));
      }
      dispatch(setFromOrganigrammaPage(true));
      navigate(`${HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA}/${so.id}`, { replace: true });
    } else {
      dispatch(
        showNotifica({
          titolo: 'ERRORE',
          stato: 498,
          messaggio: 'Verificare',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(reset());
    !hasResults() && dispatch(loadDipartimenti());
    showOrganigrammaOperations();
  }, [dispatch]);

  const controlloResponseVuota = () => {
    if (!isStorico)
      return (
        elencoDipartimentiStatus === STATUS_FULLFILLED &&
        elencoDipartimentiResult.totalElements === 0
      );
    else
      return (
        elencoDipartimentiStoricoStatus === STATUS_FULLFILLED &&
        elencoDipartimentiStoricoResult.totalElements === 0
      );
  };

  const controlloResponseOk = () => {
    if (!isStorico) return elencoDipartimentiStatus === STATUS_FULLFILLED;
    else return elencoDipartimentiStoricoStatus === STATUS_FULLFILLED;
  };

  const controlloResponse = () => {
    if (isStorico) return elencoDipartimentiStoricoResult;
    else return elencoDipartimentiResult;
  };
  return (
    <div className="organigramma">
      <PageTopHeader
        title="page-organigramma.titolo"
        descrizione="page-organigramma.descrizione"
        subDescrizione="page-organigramma.subDescrizione"
        contextRight={
          <div className="ricerca-data">
            <Input
              label="Visualizza l'organigramma alla seguente data"
              type="date"
              placeholder="Inserisci la data"
              id="input-data"
              wrapperClassName=""
              name="dataInput"
              value={form.values.dataInput}
              onChange={form.handleChange}
              invalid={!!form.errors.dataInput}
              infoText={t(form.errors.dataInput || '') || ''}
            />
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                disabled={form.values.dataInput === undefined}
                onClick={() => {
                  form.submitForm();
                }}
              >
                Conferma
              </Button>
            </div>
          </div>
        }
      ></PageTopHeader>

      <div className="mt-5 position-relative">
        {controlloResponseVuota() && <Alert color="info">Nessun risultato disponibile</Alert>}
        {controlloResponseOk() && hasResults() && (
          <div className="d-flex flex-wrap">
            {(controlloResponse().data || []).map((x, i) => (
              <div
                className="col-xl-4 col-lg-6 dipartimento mb-3"
                onClick={() => navigateToTop(x)}
                key={i}
              >
                <div className={`card ${x.id === 'E110000' ? 'card-agricoltura-' : 'card-so-'}`}>
                  <div className="card-block">
                    <div className="card-titolo">
                      {x.id === 'E000000' ? (
                        <Organization className="color-icon-organigramma" />
                      ) : x.id === 'E010000' ? (
                        <Presidente className="color-icon-organigramma" />
                      ) : x.id === 'E020000' ? (
                        <Dialog className="color-icon-organigramma" />
                      ) : x.id === 'E030000' ? (
                        <Monitor className="color-icon-organigramma" />
                      ) : x.id === 'E040000' ? (
                        <DocTransmission className="color-icon-organigramma" />
                      ) : x.id === 'E050000' ? (
                        <GroupPeople className="color-icon-organigramma" />
                      ) : x.id === 'E060000' ? (
                        <Gavel className="color-icon-organigramma" />
                      ) : x.id === 'E070000' ? (
                        <Finance className="color-icon-organigramma" />
                      ) : x.id === 'E080000' ? (
                        <NaturePeople className="color-icon-organigramma" />
                      ) : x.id === 'E090000' ? (
                        <FinanceMode className="color-icon-organigramma" />
                      ) : x.id === 'E100000' ? (
                        <Picture className="color-icon-organigramma" />
                      ) : x.id === 'E110000' ? (
                        <Nature className="color-icon-organigramma" />
                      ) : x.id === 'E120000' ? (
                        <Raven className="color-icon-organigramma" />
                      ) : x.id === 'E130000' ? (
                        <DirectionBus className="color-icon-organigramma" />
                      ) : x.id === 'E140000' ? (
                        <Handshake className="color-icon-organigramma" />
                      ) : x.id === 'E150000' ? (
                        <School className="color-icon-organigramma" />
                      ) : x.id === 'E160000' ? (
                        <AccountTree className="color-icon-organigramma" />
                      ) : x.id === 'E170000' ? (
                        <Star className="color-icon-organigramma" />
                      ) : x.id === 'E180000' ? (
                        <EmergencyHeat className="color-icon-organigramma" />
                      ) : (
                        <Pa className="color-icon-organigramma-pa" />
                      )}
                      <span>{x.tipo}</span>
                    </div>
                    <div className="d-flex">
                      <span className="card-descrizione" id={x.id}>
                        {x.descrizioneBreve}
                      </span>
                      <UncontrolledTooltip placement="top" target={x.id!}>
                        {x.descrizioneBreve}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Organigramma;
