import { FormValidazioneDatiDocumenti } from 'components/form-validazione-dati-documenti/FormValidazioneDatiDocumenti';
import FormCv from 'components/FormCv/FormCv';
import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormNote from 'components/FormNote/FormNote';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';

const ValidazioneDatiDocumenti = () => {
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={'Validazione dati e documenti'}
      />
      <FormValidazioneDatiDocumenti>
        <FormDatiAnagrafici readingOnly />
        <FormEstremiIdentificazione readingOnly />
        <FormRecapiti readingOnly />
        <FormDatiResidenza readingOnly />
        <FormDomicilio readingOnly />
        <FormDatiDisabilita readingOnly />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv readingOnly />
        <FormNote readingOnly />
      </FormValidazioneDatiDocumenti>
    </div>
  );
};

export default ValidazioneDatiDocumenti;
