import { Col, Input, Row } from 'design-react-kit';
import AccordionElement from '../accordion-element/AccordionElement';
import { useCompositeForm } from '../../hooks/useCompositeForm';

const FormDatiAnagrafici = ({ readingOnly }: { readingOnly?: boolean }) => {
  const { values, handleChange, handleBlur, setFieldValue, errors, touched } = useCompositeForm();

  return (
    <AccordionElement title="Dati Anagrafici">
      <>
        <Row className="mt-4">
          <Col md={4} className="px-1">
            <Input
              id="codiceFiscale"
              name="codiceFiscale"
              type="text"
              placeholder="Inserisci codice fiscale"
              label="Codice fiscale"
              value={values.codiceFiscale}
              onBlur={handleBlur}
              onChange={handleChange}
              wrapperClassName="required"
              invalid={touched.codiceFiscale && !!errors.codiceFiscale}
              infoText={touched.codiceFiscale && errors.codiceFiscale && errors.codiceFiscale}
              disabled={readingOnly}
            />
          </Col>
          <Col md={4} className="px-1">
            <Input
              id="cognome"
              name="cognome"
              type="text"
              placeholder="Inserisci cognome"
              label="Cognome"
              value={values.cognome}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.cognome && !!errors.cognome}
              wrapperClassName="required"
              infoText={touched.cognome && errors.cognome && errors.cognome}
              disabled={readingOnly}
            />
          </Col>
          <Col md={4} className="px-1">
            <Input
              id="nome"
              name="nome"
              type="text"
              placeholder="Inserisci nome"
              label="Nome"
              value={values.nome}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.nome && !!errors.nome}
              wrapperClassName="required"
              infoText={touched.nome && errors.nome && errors.nome}
              disabled={readingOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className="px-1">
            <Input
              id="sesso"
              name="sesso"
              type="text"
              maxLength={1}
              placeholder="Inserisci sesso"
              label="Sesso"
              value={values.sesso}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.sesso && !!errors.sesso}
              wrapperClassName="required"
              infoText={touched.sesso && errors.sesso && errors.sesso}
              disabled={readingOnly}
            />
          </Col>
          <Col md={5} className="px-1">
            <Input
              id="statoCivile"
              name="statoCivile"
              type="text"
              placeholder="Inserisci stato civile"
              label="Stato civile"
              value={values.statoCivile}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.statoCivile && !!errors.statoCivile}
              wrapperClassName="required"
              infoText={touched.statoCivile && errors.statoCivile && errors.statoCivile}
              disabled={readingOnly}
            />
          </Col>
          <Col md={5} className="px-1">
            <Input
              id="cittadinanza"
              name="cittadinanza"
              type="text"
              placeholder="Inserisci cittadinanza"
              label="Cittadinanza"
              value={values.cittadinanza}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.cittadinanza && !!errors.cittadinanza}
              wrapperClassName="required"
              infoText={touched.cittadinanza && errors.cittadinanza && errors.cittadinanza}
              disabled={readingOnly}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <Input
              id="dataNascita"
              name="dataNascita"
              label="Data di nascita"
              type="date"
              placeholder="Data di nascita"
              value={values.dataNascita}
              onChange={(e) => {
                console.log('Selected date: ', e.target.value);
                setFieldValue('dataNascita', e.target.value);
              }}
              onBlur={handleBlur}
              wrapperClassName="required"
              invalid={touched.dataNascita && !!errors.dataNascita}
              infoText={touched.dataNascita && errors.dataNascita && errors.dataNascita}
              disabled={readingOnly}
            />
          </Col>
          <Col md={3}>
            <Input
              id="nazioneNascita"
              name="nazioneNascita"
              type="text"
              placeholder="Inserisci stato di nascita"
              label="Stato di nascita"
              value={values.nazioneNascita}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.nazioneNascita && !!errors.nazioneNascita}
              wrapperClassName="required"
              infoText={touched.nazioneNascita && errors.nazioneNascita && errors.nazioneNascita}
              disabled={readingOnly}
            />
          </Col>
          <Col md={3}>
            <Input
              id="provNascita"
              name="provNascita"
              type="text"
              placeholder="Inserisci provincia di nascita"
              label="Provincia di nascita"
              value={values.provNascita}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.provNascita && !!errors.provNascita}
              wrapperClassName="required"
              infoText={touched.provNascita && errors.provNascita && errors.provNascita}
              disabled={readingOnly}
            />
          </Col>
          <Col md={3}>
            <Input
              id="cittaNascita"
              name="cittaNascita"
              type="text"
              label="Città di nascita"
              placeholder="Inserisci città di nascita"
              value={values.cittaNascita}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.cittaNascita && !!errors.cittaNascita}
              wrapperClassName="required"
              infoText={touched.cittaNascita && errors.cittaNascita && errors.cittaNascita}
              disabled={readingOnly}
            />
          </Col>
        </Row>
      </>
    </AccordionElement>
  );
};

export default FormDatiAnagrafici;
