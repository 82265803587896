import {
  AssegnazioneBandoPoRequestDto,
  PosizioneOrganizzativaDto,
} from '../../services/ms-anagrafica-unica';

interface PosizioniOrganizzativeDetailProps {
  assegnazione: AssegnazioneBandoPoRequestDto;
  eq: PosizioneOrganizzativaDto;
}

const PosizioniOrganizzativeDetail = (props: PosizioniOrganizzativeDetailProps) => {
  return (
    <div className="col-sm-12">
      <div className="card-div row">
        <div className="col-sm-12">
          <div className="card-title">
            <h5 className="font-bold">{props.eq.denominazione}</h5>
          </div>
          <div className="info-box-light bg-light-gray my-3">
            <h6 className="mb-3">Riepilogo EQ</h6>
            <hr />
            <div className="row">
              {props.eq.tipo && (
                <div className="col-sm-4">
                  <dt className="col-sm-12">Tipo EQ</dt>
                  <dd className="col-sm-12">{props.eq.tipo}</dd>
                </div>
              )}
              {props.eq.strutturaOrganizzativa && (
                <div className="col-sm-4">
                  <dt className="col-sm-12">Codice struttura</dt>
                  <dd className="col-sm-12">{props.eq.strutturaOrganizzativa}</dd>
                </div>
              )}
              {props.eq.strutturaOrganizzativa && (
                <div className="col-sm-4">
                  <dt className="col-sm-12">Codice univoco EQ</dt>
                  <dd className="col-sm-12">{props.eq.codiceUnivoco}</dd>
                </div>
              )}
              {props.assegnazione.requisitiOggettivi && (
                <div className="col-sm-12">
                  <dt className="col-sm-12">Requisiti oggettivi</dt>
                  <dd className="col-sm-12">{props.assegnazione.requisitiOggettivi}</dd>
                </div>
              )}
              {props.assegnazione.requisitiSoggettivi && (
                <div className="col-sm-12">
                  <dt className="col-sm-12">Requisiti soggettivi</dt>
                  <dd className="col-sm-12">{props.assegnazione.requisitiSoggettivi}</dd>
                </div>
              )}
              {props.assegnazione.descrizioneBreveIncarico && (
                <div className="col-sm-12">
                  <dt className="col-sm-12">Descrizione breve dell'incarico</dt>
                  <dd className="col-sm-12">{props.assegnazione.descrizioneBreveIncarico}</dd>
                </div>
              )}
              {props.assegnazione.descrizioneEstesaIncarico && (
                <div className="col-sm-12">
                  <dt className="col-sm-12">Descrizione estesa dell'incarico</dt>
                  <dd className="col-sm-12">{props.assegnazione.descrizioneEstesaIncarico}</dd>
                </div>
              )}
              {props.assegnazione.progetto && (
                <div className="col-sm-12">
                  <dt className="col-sm-12">Progetto</dt>
                  <dd className="col-sm-12">{props.assegnazione.progetto}</dd>
                </div>
              )}
              {props.assegnazione.sedi && (
                <div className="col-sm-12">
                  <dt className="col-sm-12">Sedi</dt>
                  <dd className="col-sm-12">{props.assegnazione.sedi.join(', ')}</dd>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosizioniOrganizzativeDetail;
