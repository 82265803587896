import { format } from 'date-fns';
import { it } from 'date-fns/locale';

/**
 * Formatta la data
 *
 * @param date
 * @param numericFormat
 * @param defaultValueIfNull stringa vuota
 * @returns
 */
export const formatDate = (
  date: string | undefined,
  numericFormat?: boolean,
  defaultValueIfNull: string = ''
) => {
  if (!date) return defaultValueIfNull;

  let mese = '';
  let separatore = '/';

  if (numericFormat) {
    mese = format(new Date(date), 'MM', { locale: it });
  } else {
    mese = format(new Date(date), 'MMMM', { locale: it });
    mese = mese.charAt(0).toUpperCase() + mese.slice(1).toLowerCase();
    separatore = ' ';
  }

  const giorno = format(new Date(date), 'dd');
  const anno = format(new Date(date), 'yyyy');

  return `${giorno}${separatore}${mese}${separatore}${anno}`;
};
