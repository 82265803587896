import axios from 'axios';
import { useEffect, useState } from 'react';
import useGetOnboardingById from './useGetOnboardingById';
import { useParams } from 'react-router-dom';

const useDownloadDocumentiIdentificazionePdfs = ({
  onFilesReady, // Callback to update Formik when files are ready
}: {
  onFilesReady: (files: File[]) => void; // Function to pass downloaded files to Formik
}) => {
  const { onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const [downloadedPdfs, setDownloadedPdfs] = useState<File[]>([]);

  useEffect(() => {
    const fetchDocumenti = async () => {
      if (!onboarding) return;

      const documentiIdentificazione = onboarding?.elencoDocumenti.filter((documento) =>
        // @ts-ignore
        ['CARTA_IDENTITA', 'PASSAPORTO', 'PATENTE'].includes(documento.tipo)
      );

      const pdfPromises = documentiIdentificazione.map(async (doc) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/documenti/${doc.documentoDto.id}/download`,
            { responseType: 'blob' }
          );

          const pdfBlob = new Blob([response.data], {
            type: 'application/pdf',
          });

          return new File([pdfBlob], `${doc.documentoDto.filename}`, {
            type: 'application/pdf',
          });
        } catch (error) {
          console.error('Error downloading document:', error);
          return null;
        }
      });

      const pdfFiles = await Promise.all(pdfPromises);
      const validPdfFiles = pdfFiles.filter((file) => file !== null) as File[];

      // Update local state first
      setDownloadedPdfs(validPdfFiles);

      // Update Formik after PDFs are fully downloaded
      onFilesReady(validPdfFiles);
    };

    fetchDocumenti();
  }, [onboarding]);

  return downloadedPdfs;
};

export default useDownloadDocumentiIdentificazionePdfs;
