import { Button, Col, Form, FormGroup, Input, Label, Row } from 'design-react-kit';
import { useFormik } from 'formik';
import CampoForm from '../campo-form/CampoForm';
import CustomSelect from 'components/custom-select/CustomSelect';
import useConcorsiSelectOptions from 'hooks/useConcorsiSelectOptions';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

export interface IFormCreaConcorsoForm {
  nome: string;
  areaProfessionale: string;
  livelli: string;
  posizioneGraduatoria?: string;
  vincitore?: boolean;
  idoneo?: boolean;
}

const initialValues: IFormCreaConcorsoForm = {
  nome: '',
  areaProfessionale: '',
  livelli: '',
  /*   posizioneGraduatoria: '',
  vincitore: undefined,
  idoneo: undefined, */
};

const validationSchema = z.object({
  nome: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci nome concorso')
    .max(150),
  areaProfessionale: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci area professionale'),
  livelli: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci posizione economica'),
  /*   posizioneGraduatoria: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci posizione graduatoria')
    .max(5, 'Massimo 5 caratteri'),
  vincitore: z.boolean().optional(),
  idoneo: z.boolean().optional(), */
});

const CreaConcorsoForm = ({
  onSubmit,
  onBackButtonClick,
}: {
  onSubmit: (values: IFormCreaConcorsoForm) => void | Promise<any>;
  onBackButtonClick: () => void;
  submitButtonTitle?: string;
}) => {
  const concorsiOptions = useConcorsiSelectOptions();

  const form = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit,
  });

  const handleVincitoreChange = (event: any) => {
    form.setFieldValue('vincitore', event.target.checked);
  };

  const handleIdoneoChange = (event: any) => {
    form.setFieldValue('idoneo', event.target.checked);
  };

  return (
    <>
      <CampoForm
        titolo="Crea Concorso"
        style={{
          width: '100%',
          border: '1px solid #06c',
          borderRadius: '12px',
        }}
      >
        <Form>
          <Row className="mt-5">
            <Col md={12}>
              <Input
                type="text"
                id="nome"
                name="nome"
                label="Nome Concorso"
                value={form.values.nome}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Nome Concorso"
                invalid={!!(form.touched.nome && form.errors.nome)}
                infoText={form.errors.nome}
                wrapperClass="col-md required"
              />
            </Col>
          </Row>
          {/*      <Row>
            <Col md={12}>
              <Input
                type="text"
                id="classe"
                name="classe"
                label="Classe"
                value={form.values.classe}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder="Classe"
                invalid={!!(form.touched.classe && form.errors.classe)}
                wrapperClass="col-md"
              />
              {form.touched.classe && form.errors.classe && (
                <div id="classeError" className="invalid-feedback">
                  {form.errors.classe}
                </div>
              )}
            </Col>
          </Row> */}
          <Row>
            {concorsiOptions?.areeProfessionali && (
              <Col md={6}>
                <CustomSelect
                  name="areaProfessionale"
                  label="Area Professionale"
                  placeholder="Area Professionale"
                  options={concorsiOptions.areeProfessionali.map(({ value, label }) => ({
                    value,
                    label,
                  }))}
                  value={form.values.areaProfessionale}
                  invalid={!!form.errors.areaProfessionale}
                  infoText={form.errors.areaProfessionale}
                  onChange={(e) => form.setFieldValue('areaProfessionale', e)}
                  wrapperClass="col-md required"
                />
              </Col>
            )}
            {concorsiOptions?.livelli && (
              <Col md={6}>
                <CustomSelect
                  name="livelli"
                  label="Posizione Economica"
                  placeholder="Posizione Economica"
                  options={concorsiOptions.livelli.map(({ value, label }) => ({
                    value,
                    label,
                  }))}
                  value={form.values.livelli}
                  invalid={!!form.errors.livelli}
                  infoText={form.errors.livelli}
                  onChange={(e) => form.setFieldValue('livelli', e)}
                  wrapperClass="col-md required"
                />
              </Col>
            )}
          </Row>
          {/*   <Row>
            <Col md={6}>
              <Input
                label="Posizione in graduatoria"
                type="text"
                placeholder="Posizione in Graduatoria"
                id="input-posizioneGraduatoria"
                name="posizioneGraduatoria"
                wrapperClassName="form-group required col col-md-6"
                value={form.values.posizioneGraduatoria}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={form.touched.posizioneGraduatoria && !!form.errors.posizioneGraduatoria}
                // @ts-ignore
                infoText={
                  form.touched.posizioneGraduatoria &&
                  form.errors.posizioneGraduatoria &&
                  form.errors.posizioneGraduatoria
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="vincitore"
                  name="vincitore"
                  // @ts-ignore
                  value={form.values.vincitore}
                  onChange={handleVincitoreChange}
                />
                <Label check for="vincitore">
                  Vincitore
                </Label>
              </FormGroup>
              {form.touched.vincitore && form.errors.vincitore && (
                <div id="vincitoreError" className="invalid-feedback">
                  {form.errors.vincitore}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="idoneo"
                  name="idoneo"
                  disabled={form.values.vincitore}
                  // @ts-ignore
                  value={form.values.idoneo}
                  onChange={handleIdoneoChange}
                />
                <Label check for="idoneo">
                  Idoneo
                </Label>
              </FormGroup>
            </Col>
          </Row> */}
        </Form>
      </CampoForm>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '10px',
          paddingBottom: '15px',
        }}
      >
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            form.submitForm();
          }}
        >
          Crea Concorso
        </Button>
        <Button outline color="primary" size="sm" onClick={onBackButtonClick}>
          Indietro
        </Button>
      </div>
    </>
  );
};

export default CreaConcorsoForm;