import { CustomSelectItem } from '../components/common/custom-select/CustomSelect';
import {
  OptionDtoInteger,
  OptionDtoString,
  OptionDtoTipoPosizioneOrganizzativa,
  OptionDtoTipologiaBandoPo,
} from '../services/ms-anagrafica-unica';

export const creaSelectOptions = (
  obj: Array<OptionDtoString | OptionDtoTipologiaBandoPo | OptionDtoTipoPosizioneOrganizzativa>
) => {
  const selectOptions: Array<CustomSelectItem> = [];
  obj.forEach((item: any) => {
    selectOptions.push({
      value: item.valore,
      label: item.label,
    });
  });

  return selectOptions;
};

export const creaSelectOptionsWithOther = (
  obj: Array<
    | OptionDtoString
    | OptionDtoInteger
    | OptionDtoTipologiaBandoPo
    | OptionDtoTipoPosizioneOrganizzativa
  >
) => {
  const selectOptions: Array<CustomSelectItem> = [];
  obj.forEach((item: any) => {
    selectOptions.push({
      value: item.valore,
      label: item.label,
    });
  });

  selectOptions.push({ value: 9999, label: 'Altro' });

  return selectOptions;
};

/**
 *
 * @param zod
 * @param required default false
 * @returns
 */
export const zodCifra = (zod: any, required: boolean = false) => {
  let zodCifra = zod
    .string(required ? { required_error: 'required' } : {})
    .regex(new RegExp(controlliCodiceCifra.regExp), 'codiceCifraNonValido')
    .min(controlliCodiceCifra.min, 'lenghtMinCodiceCifra')
    .max(controlliCodiceCifra.max, 'lenghtMaxCodiceCifra');

  return zodCifra;
};

export const controlliCodiceCifra = {
  min: 10,
  max: 14,
  regExp: /^[a-zA-Z0-9]{3}\/\d{4}\/[1-9]\d{0,}$/,
};
