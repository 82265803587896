import { initialValues } from 'components/crea-candidato-form/CreaCandidatoForm';
import { getInitialValuesFromCandidato } from 'components/forms/utils/getInitialValuesFromCandidato';
import { useEffect, useState } from 'react';
import type { ICandidatoConcorso } from './services/useGetCandidatoByCodiceFiscale';
import type { IOnboardingConcorso } from './services/useGetOnboardingById';

const useAsyncInitialValues = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  formik: any,
  candidato?: ICandidatoConcorso,
  onboarding?: IOnboardingConcorso
) => {
  const [isAsyncValuesLoading, setIsAsyncValuesLoading] = useState(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchInitialValues = async () => {
      setIsAsyncValuesLoading(true);
      try {
        const asyncInitialValues = await getInitialValuesFromCandidato(
          initialValues,
          candidato,
          onboarding
        );
        formik.setValues(asyncInitialValues);
      } catch (error) {
        console.error('Error fetching initial values:', error);
      } finally {
        setIsAsyncValuesLoading(false);
      }
    };

    fetchInitialValues();
  }, [candidato, onboarding]);

  return isAsyncValuesLoading;
};

export default useAsyncInitialValues;
