import { Button, Col, Input } from 'design-react-kit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HREF_CONSULENTE_ESTERNO_ELENCO } from '../../components/layout/sidemenu/sidemenuConstants';
import { useAppDispatch } from '../../hooks';
import { insertConsulenteEsterno, resetInsertStatus } from '../../store/consulentiEsterniSlice';
import {
  InsertConsulenteEsternoRequestDto,
  RuoloUtenteAutenticatoDto,
} from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { t } from 'i18next';
import PageHeader from '../../components/common/page-header/PageHeader';

const InserisciConsulenteEsterno = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    resetInsertStatus();
  }, [0]);

  const handleChange = (name: string, selectedOption: any) => {
    console.log('selected options', selectedOption);
    if (selectedOption != '') {
      formik.setFieldValue(name, selectedOption, false);
    } else {
      formik.setFieldValue(name, undefined, false);
    }
  };

  const initialValues: InsertConsulenteEsternoRequestDto = {
    codiceFiscale: undefined,
    nome: undefined,
    cognome: undefined,
    cellulare: undefined,
    telefono: undefined,
  };

  const schema = z.object({
    nome: z.string().max(50),
    cognome: z.string().max(200),
    telefono: z.string().regex(/^\d+$/).max(20).optional(),
    cellulare: z.string().regex(/^\d+$/).max(30).optional(),
    codiceFiscale: z.string().length(16, 'Il codice fiscale deve essere di 16 caratteri'),
  });

  const formik: FormikProps<InsertConsulenteEsternoRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      dispatch(
        insertConsulenteEsterno({
          insertConsulenteEsternoRequestDto: values,
        })
      )
        .unwrap()
        .then((resp) => {
          navigate(HREF_CONSULENTE_ESTERNO_ELENCO);
        });
    },
  });

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_CONSULENTE_ESTERNO_ELENCO}
        title="Inserimento consulente esterno"
      ></PageHeader>
      <div>
        <div className="row">
          <div className="col-12 form-custom form-inserimento form-bg border">
            <div className="mt-3">
              <Col>
                <Input
                  label="Codice Fiscale"
                  type="text"
                  placeholder="Inserisci codiceFiscale"
                  id="input-codiceFiscale"
                  wrapperClassName="required col-md-12 "
                  name="codiceFiscale"
                  value={formik.values.codiceFiscale}
                  onChange={(e) => handleChange('codiceFiscale', e.target.value)}
                  invalid={!!formik.errors.codiceFiscale}
                  infoText={t(formik.errors.codiceFiscale || '') || ''}
                />
              </Col>
              <Col>
                <Input
                  label="Nome"
                  type="text"
                  placeholder="Inserisci nome"
                  id="input-nome"
                  wrapperClassName="required col-md-12 "
                  name="nome"
                  value={formik.values.nome}
                  onChange={(e) => handleChange('nome', e.target.value)}
                  invalid={!!formik.errors.nome}
                  infoText={t(formik.errors.nome || '') || ''}
                />
              </Col>
              <Col>
                <Input
                  label="Cognome"
                  type="text"
                  placeholder="Inserisci cognome"
                  id="input-cognome"
                  wrapperClassName="required col-md-12 "
                  name="cognome"
                  value={formik.values.cognome}
                  onChange={(e) => handleChange('cognome', e.target.value)}
                  invalid={!!formik.errors.cognome}
                  infoText={t(formik.errors.cognome || '') || ''}
                />
              </Col>
              <Col>
                <Input
                  label="Telefono"
                  type="text"
                  placeholder="Inserisci telefono"
                  id="input-telefono"
                  wrapperClassName="form-group col-md-12 "
                  name="telefono"
                  value={formik.values.telefono}
                  onChange={(e) => handleChange('telefono', e.target.value)}
                  invalid={!!formik.errors.telefono}
                  infoText={t(formik.errors.telefono || '') || ''}
                />
              </Col>
              <Col>
                <Input
                  label="Cellulare"
                  type="text"
                  placeholder="Inserisci cellulare"
                  id="input-cellulare"
                  wrapperClassName="form-group col-md-12 "
                  name="cellulare"
                  value={formik.values.cellulare}
                  onChange={(e) => handleChange('cellulare', e.target.value)}
                  invalid={!!formik.errors.cellulare}
                  infoText={t(formik.errors.cellulare || '') || ''}
                />
              </Col>
            </div>
          </div>

          <div className="col-12 py-3">
            <div className="d-flex flex-row-reverse pb-5">
              <Button color="primary" className="mt-2" onClick={() => formik.submitForm()}>
                Conferma
              </Button>
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                onClick={() => navigate(HREF_CONSULENTE_ESTERNO_ELENCO)}
              >
                Annulla
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InserisciConsulenteEsterno;
