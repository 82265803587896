import { Col, FormGroup, Input, Label, Row, Select } from 'design-react-kit';
import { useEffect, useState } from 'react';
import comuni from '../../assets/json/comuni.json';
import provincie from '../../assets/json/province.json';
import AccordionElement from '../accordion-element/AccordionElement';
import { useCompositeForm } from '../../hooks/useCompositeForm';
import { ErrorMessage } from 'components/error-message/ErrorMessage';

export interface IFormDatiResidenza {
  stato: string;
  provinciaResidenza?: string;
  cittaResidenza?: string;
  indirizzo: string;
  cap: string;
}

const FormDatiResidenza: React.FC<{
  initValuesProps?: IFormDatiResidenza;
  readingOnly?: boolean;
}> = ({ initValuesProps, readingOnly }) => {
  const { values, handleChange, setFieldValue, errors, touched, handleBlur } = useCompositeForm();

  const statiOptions = [
    { value: 'Italia', label: 'Italia' },
    { value: 'Altro', label: 'Altro' },
  ];
  const provinceOptions = provincie.map((item) => {
    return {
      value: item.sigla_automobilistica,
      label: item.nome,
    };
  });
  const [cittaOptions, setCittaOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    let selectedProvinceId = provincie.find(
      (item) => item.sigla_automobilistica === values.provinciaDomicilio
    )?.id;
    setCittaOptions(
      comuni
        .filter((item) => item.id_provincia === selectedProvinceId)
        .map((item) => {
          return {
            value: item.nome,
            label: item.nome,
          };
        })
    );
  }, []);

  function setCitiesByProvince(selectedProvince: { value: string; label: string }) {
    let selectedProvinceId = provincie.find((item) => item.nome === selectedProvince.label)?.id;
    console.log('Selected province id: ', selectedProvince);
    console.log(
      'Comuni: ',
      comuni.filter((item) => item.id_provincia === selectedProvinceId)
    );
    setCittaOptions(
      comuni
        .filter((item) => item.id_provincia === selectedProvinceId)
        .map((item) => {
          return {
            value: item.nome,
            label: item.nome,
          };
        })
    );
  }

  return (
    <AccordionElement title="Dati Residenza">
      <>
        <Row>
          <Col md={4} className="px-1">
            <Label for="statoResidenza">Stato residenza</Label>
            <FormGroup>
              <Select
                id="statoResidenza"
                name="statoResidenza"
                value={{
                  value: values.statoResidenza,
                  label: values.statoResidenza,
                }}
                options={statiOptions}
                onChange={(option) => {
                  if (option) {
                    setFieldValue('statoResidenza', option.value);
                    setFieldValue('provinciaResidenza', '');
                    setFieldValue('cittaResidenza', '');
                  }
                }}
                aria-describedby="statoError"
                placeholder="Seleziona Stato"
                isDisabled={readingOnly}
              />
              <ErrorMessage touched={touched.statoResidenza} error={errors.statoResidenza} />
            </FormGroup>
          </Col>

          <Col md={4} className="px-1">
            <Label for="provinciaResidenza">Provincia residenza</Label>
            <FormGroup>
              {values.statoResidenza === 'Italia' ? (
                <Select
                  id="provinciaResidenza"
                  name="provinciaResidenza"
                  value={{
                    value: values.provinciaResidenza,
                    label: values.provinciaResidenza,
                  }}
                  options={provinceOptions}
                  onChange={(option) => {
                    if (option) {
                      setFieldValue('provinciaResidenza', option.value);
                      /* @ts-ignore */
                      setCitiesByProvince(option);
                    }
                  }}
                  aria-describedby="provinciaError"
                  placeholder="Seleziona Provincia"
                  isDisabled={readingOnly}
                />
              ) : (
                <Input
                  id="provinciaResidenza"
                  name="provinciaResidenza"
                  type="text"
                  maxLength={50}
                  placeholder="Inserisci Provincia"
                  value={values.provinciaResidenza}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.provinciaResidenza && !!errors.provinciaResidenza}
                  infoText={
                    touched.provinciaResidenza &&
                    errors.provinciaResidenza &&
                    errors.provinciaResidenza
                  }
                  disabled={readingOnly}
                />
              )}
            </FormGroup>
          </Col>

          <Col md={4} className="px-1">
            <Label for="cittaResidenza">Città residenza</Label>
            <FormGroup>
              {values.statoResidenza === 'Italia' ? (
                <Select
                  id="cittaResidenza"
                  name="cittaResidenza"
                  value={{
                    value: values.cittaResidenza,
                    label: values.cittaResidenza,
                  }}
                  options={cittaOptions}
                  onChange={(option) => {
                    if (option) {
                      setFieldValue('cittaResidenza', option.value);
                    }
                  }}
                  aria-describedby="cittaError"
                  placeholder="Seleziona Città"
                  isDisabled={readingOnly}
                />
              ) : (
                <Input
                  id="cittaResidenza"
                  name="cittaResidenza"
                  type="text"
                  maxLength={50}
                  placeholder="Inserisci Città"
                  value={values.cittaResidenza}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.cittaResidenza && !!errors.cittaResidenza}
                  infoText={
                    touched.cittaResidenza && errors.cittaResidenza && errors.cittaResidenza
                  }
                  disabled={readingOnly}
                />
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={8} className="px-1">
            <FormGroup>
              <Input
                id="indirizzoResidenza"
                name="indirizzoResidenza"
                type="text"
                placeholder="Inserisci indirizzo residenza"
                label="Indirizzo residenza"
                maxLength={100}
                value={values.indirizzoResidenza}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.indirizzoResidenza && !!errors.indirizzoResidenza}
                aria-describedby="indirizzoError"
                wrapperClassName="required"
                infoText={
                  touched.indirizzoResidenza &&
                  errors.indirizzoResidenza &&
                  errors.indirizzoResidenza
                }
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>

          <Col md={4} className="px-1">
            <FormGroup>
              <Input
                id="capResidenza"
                name="capResidenza"
                type="text"
                label="CAP residenza"
                placeholder="Inserisci CAP residenza"
                maxLength={5}
                value={values.capResidenza}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.capResidenza && !!errors.capResidenza}
                aria-describedby="capError"
                wrapperClassName="required"
                infoText={touched.capResidenza && errors.capResidenza && errors.capResidenza}
                disabled={readingOnly}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    </AccordionElement>
  );
};

export default FormDatiResidenza;
