import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'design-react-kit';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { STATUS_FULLFILLED, STATUS_REJECTED } from '../../store/store-constants';
import CreatableSelect from 'react-select/creatable';
import { SelectItem } from '../../store/ConvenzioniSlice';
import {
  RegistroImpreseMultiSearchRequest,
  searchByCodiciFiscali,
} from '../../store/RegistroImpreseSlice';
import CustomSelectItemComponent from '../../components/common/custom-select-item/CustomSelectItemComponent';

function RegistroImpreseRicercaMultipla() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let { multiSearchStatus } = useAppSelector((state) => state.registroImprese);
  const [isOpen, setIsOpen] = useState(true);
  const [formCollapseOpen, setFormCollapseOpen] = useState(true);
  const [codiciFiscali, setCodiciFiscali] = useState<SelectItem[]>([]);
  const [errorMsgObbligatorio, setErrorMsgObbligatorio] = useState<boolean>(false);
  const searchForm = useFormik<RegistroImpreseMultiSearchRequest>({
    initialValues: { codiciFiscali: [] },
    onSubmit: (values) => {
      setErrorMsgObbligatorio(values?.codiciFiscali?.length === 0);

      if (values?.codiciFiscali?.length > 0) {
        setFormCollapseOpen(false);
        dispatch(searchByCodiciFiscali(searchForm.values.codiciFiscali));
      }
    },
  });

  useEffect(() => {
    multiSearchStatus = '';
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (multiSearchStatus === STATUS_FULLFILLED) {
      setIsOpen(true);
    }
  }, [multiSearchStatus]);

  function handleChange(selectedOption: any) {
    setErrorMsgObbligatorio(false);
    setCodiciFiscali(selectedOption as SelectItem[]);
    let cfs = (selectedOption as SelectItem[]).map((opt) => opt.label);
    searchForm.setFieldValue('codiciFiscali', cfs);
  }

  function handleReset() {
    setCodiciFiscali([]);
    setIsOpen(false);
    searchForm.setFieldValue('codiciFiscali', []);
  }

  return (
    <div>
      <h2>{t('Registro imprese')}</h2>

      <Accordion className="form-custom form-bg filtri-ricerca mt-3" background={'active'}>
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('Ricerca multipla')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="mt-2">
            <div className={'row'}>
              <div className={'col'}>
                <CreatableSelect
                  key={'input-codice-fiscale'}
                  id="input-codice-fiscale"
                  name="cf"
                  isValidNewOption={(string) => string.length >= 11}
                  noOptionsMessage={() =>
                    CustomSelectItemComponent('Inserisci un codice fiscale con almeno 11 caratteri')
                  }
                  openMenuOnFocus={false}
                  openMenuOnClick={false}
                  value={codiciFiscali}
                  formatCreateLabel={(value) =>
                    CustomSelectItemComponent(
                      'Aggiungi ' + value + ' alla lista dei codici fiscali da cercare'
                    )
                  }
                  placeholder="Inserisci i codici fiscali su cui verificare la visura"
                  isMulti={true}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-row-reverse">
                <Button
                  color="primary"
                  className="mt-1"
                  onClick={() => {
                    searchForm.handleSubmit();
                  }}
                >
                  Ricerca
                </Button>
                <Button outline color="primary" className="mt-1 mx-2" onClick={handleReset}>
                  Pulisci
                </Button>
              </div>

              <div className={'col-12'}>
                <span className={'text-info'} style={{ fontSize: '.777rem' }}>
                  <b>
                    <i>
                      Dopo aver inserito un codice fiscale, premi invio per aggiungerlo all'elenco
                    </i>
                  </b>
                </span>
                <div className="col">
                  {errorMsgObbligatorio && (
                    <span className={'red'} style={{ fontSize: '.777rem', color: 'red' }}>
                      Nessun elemento da ricercare
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-5 position-relative">
        {/*{multiSearchStatus === STATUS_PENDING &&*/}
        {/*    <PageLoader loadingMessage="Invio richiesta in corso.."/>*/}
        {/*}*/}

        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} labelledBy="id" centered>
          <ModalHeader id="id">Richiesta accettata.</ModalHeader>
          <ModalBody>
            <p>
              La richiesta è stata presa in carico. Riceverai una mail con i risultati ad
              elaborazione conclusa.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>

        {multiSearchStatus === STATUS_REJECTED && (
          <Alert color="danger">
            Siamo spiacenti, si &egrave; verificato un errore durante la richiesta
          </Alert>
        )}
      </div>
    </div>
  );
}

export default RegistroImpreseRicercaMultipla;
