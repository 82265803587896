import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'design-react-kit';
import {
  deleteNotifica,
  getNotificheNonLette,
  patchNotifica,
  searchNotifiche,
} from '../../store/notificheSlice';
import { NotificaDtoStatoEnum } from '../../services/ms-anagrafica-unica';

export enum ModalType {
  SegnaDaLeggere = 'SEGNA_DA_LEGGERE',
  SegnaComeLetto = 'SEGNA_LETTO',
  Elimina = 'ELIMINA',
  Archivia = 'ARCHIVIA',
}

export interface CustomModalProps {
  open: boolean;
  id?: string;
  title?: string;
  body?: string;
  action?: ModalType;
  resetModalProps: Function;
}

export const ScrivaniaVirtualeModale = (props: CustomModalProps) => {
  const dispatch = useAppDispatch();
  const { searchInput } = useAppSelector((state) => state.notifiche);

  const [isOpen, toggleModal] = useState(false);

  useEffect(() => {
    if (props.open) {
      toggleModal(props.open);
      console.log('action', props.action);
    }
  }, [props.open]);

  const confermoInoltro = () => {
    switch (props.action) {
      case ModalType.SegnaDaLeggere:
        dispatch(
          patchNotifica({
            idNotifica: Number(props.id),
            notificaPatchRequestDto: {
              stato: NotificaDtoStatoEnum.NonLetta,
            },
          })
        )
          .unwrap()
          .then(
            (resp) =>
              !!resp &&
              dispatch(
                searchNotifiche({
                  ...searchInput,
                  notArchiviate: true,
                })
              )
                .unwrap()
                .then(
                  (resp) =>
                    !!resp &&
                    dispatch(getNotificheNonLette({ stato: NotificaDtoStatoEnum.NonLetta }))
                )
          );
        break;
      case ModalType.SegnaComeLetto:
        dispatch(
          patchNotifica({
            idNotifica: Number(props.id),
            notificaPatchRequestDto: {
              stato: NotificaDtoStatoEnum.Letta,
            },
          })
        )
          .unwrap()
          .then(
            (resp) =>
              !!resp &&
              dispatch(
                searchNotifiche({
                  ...searchInput,
                  notArchiviate: true,
                })
              )
                .unwrap()
                .then(
                  (resp) =>
                    !!resp &&
                    dispatch(getNotificheNonLette({ stato: NotificaDtoStatoEnum.NonLetta }))
                )
          );
        break;
      case ModalType.Archivia:
        dispatch(
          patchNotifica({
            idNotifica: Number(props.id),
            notificaPatchRequestDto: {
              stato: NotificaDtoStatoEnum.Archiviata,
            },
          })
        )
          .unwrap()
          .then(
            (resp) =>
              !!resp &&
              dispatch(
                searchNotifiche({
                  ...searchInput,
                  notArchiviate: true,
                })
              )
                .unwrap()
                .then(
                  (resp) =>
                    !!resp &&
                    dispatch(getNotificheNonLette({ stato: NotificaDtoStatoEnum.NonLetta }))
                )
          );
        break;
      case ModalType.Elimina:
        dispatch(
          deleteNotifica({
            idNotifica: Number(props.id),
          })
        )
          .unwrap()
          .then(
            (resp) =>
              !!resp &&
              dispatch(
                searchNotifiche({
                  ...searchInput,
                  notArchiviate: true,
                })
              )
                .unwrap()
                .then(
                  (resp) =>
                    !!resp &&
                    dispatch(getNotificheNonLette({ stato: NotificaDtoStatoEnum.NonLetta }))
                )
          );
        break;

      default:
        break;
    }

    toggleModal(!isOpen);
  };

  const reset = () => {
    props.resetModalProps();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy={props.id}
        centered
        onClosed={() => reset()}
      >
        <ModalHeader toggle={() => toggleModal(!isOpen)} id={props.id}>
          {props.title}
        </ModalHeader>
        <ModalBody>
          <p>{props.body}</p>
        </ModalBody>
        <ModalFooter>
          {props.action === undefined ? (
            ''
          ) : (
            <>
              <Button
                color="secondary"
                onClick={() => {
                  toggleModal(!isOpen);
                }}
              >
                Annulla
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  confermoInoltro();
                }}
              >
                Conferma
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
