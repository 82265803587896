import { Button, Icon } from 'design-react-kit';
import { ButtonCustomProps } from './ButtonCustomProps';

function ButtonProtocolla(props: ButtonCustomProps) {
  return (
    <>
      {props.showOnlyIcon ? (
        <div
          className="item-icon-menu"
          onClick={props.onCliclEvent}
          title={props.titleBtn ?? 'Protocolla File'}
        >
          <Icon icon="it-locked" color="primary"></Icon>
        </div>
      ) : (
        <Button
          className={props.className}
          color="primary"
          size="xs"
          onClick={props.onCliclEvent}
          title={props.titleBtn ?? 'Protocolla File'}
        >
          <Icon icon="it-locked" size="xs" color="white"></Icon> {props.testoBtn ?? 'Protocolla'}
        </Button>
      )}
    </>
  );
}

export default ButtonProtocolla;
