import { Col, Input } from 'design-react-kit';
import { FocusEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import { FormsControllerApi, SODisassociaRequestDto } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  associazioneSoDipentente,
  detailsAssociazioneSoDipendente,
  detailsStruttureOrganizzative,
  disassociaStrutturaDipendente,
  resetDetailsAssocazioneSoDipendente
} from '../../store/strutturaOrganizzativaSlice';
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants';
import CustomAutocomplete from '../../components/common/custom-autocomplete/CustomAutocomplete';
import { debounce } from 'lodash';
import PageHeader from '../../components/common/page-header/PageHeader';
import {
  CAP,
  CITTA,
  CODICE,
  DATA_INIZIO_VALIDITA,
  DECLARATORIA,
  DENOMINAZIONE,
  DIPARTIMENTO,
  EMAIL,
  EMAIL_2,
  INDIRIZZO,
  PEC,
  PEC_2,
  TELEFONO,
  TELEFONO_2,
  TIPOLOGIA_STRUTTURA,
} from './struttureOrganizzativeConstants';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail/StruttureOrganizzativeDetails';
import { zodCifra } from '../../utility/formUtils';
import DocumentUpload, {
  DocumentUploadRef,
} from '../../components/common/document-upload/DocumentUpload';

const DisassociazioneStrutturaOrganizzativa = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const refDocumentUpload = useRef<DocumentUploadRef>(null);
  const detailsDipendenteAssociato = useAppSelector(
    (state) => state.strutturaOrganizzativa.assegnazioneSoDipendenteResult
  );
  const fieldUsed: Array<string> = [
    CODICE,
    DENOMINAZIONE,
    DECLARATORIA,
    DATA_INIZIO_VALIDITA,
    TIPOLOGIA_STRUTTURA,
    DIPARTIMENTO,
    EMAIL,
    EMAIL_2,
    PEC,
    PEC_2,
    TELEFONO,
    TELEFONO_2,
    INDIRIZZO,
    CITTA,
    CAP,
  ];
  const strutturaOrganizzativaResult = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsStatus
  );
  const strutturaOrganizzativa = useAppSelector(
    (state) => state.strutturaOrganizzativa.detailsResult
  );
  const [optDipendenti, setOptDipendenti] = useState<any[] | undefined>([]);

  const initialValues: SODisassociaRequestDto = {
    idDipendente: undefined,
    dataInizioValidita: '',
    dataFineValidita: '',
    // attoAutorizzativoAss: undefined,
    // attoAutorizzativoDiss: undefined,
    idFile: undefined,
    requestType: 'Disassocia',
  };

  const schema = z.object({
    idDipendente: z.number({ required_error: 'required' }),
    dataInizioValidita: z.coerce.date({ required_error: 'required' }).max(new Date('9999-12-31'), { message: 'Invalid' }),
    dataFineValidita: z.coerce.date().max(new Date('9999-12-31'), { message: 'Invalid' }).refine((data) => data >= new Date(formik.values.dataInizioValidita!), {
      message: 'La data di fine validità non può essere minore della data di inizio',
    }).optional(),
    // attoAutorizzativoAss: zodCifra(z),
    // attoAutorizzativoDiss: zodCifra(z, false).optional(),
    idFile: z.number({ required_error: 'required' }),
  });

  useEffect(() => {
    dispatch(resetDetailsAssocazioneSoDipendente());
    getDetailsFromApi(id!);
  }, []);

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  };

  const formik: FormikProps<SODisassociaRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      disassociaSoDipendenti();
    },
  });

  const getFieldError = (
    form: FormikProps<SODisassociaRequestDto>,
    fieldName: keyof SODisassociaRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const submitConfirm = () => {
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  const handleChangeDipendente = (name: string, selectedOption: any) => {
    if (selectedOption !== '') {
      const body: associazioneSoDipentente = {
        idDipendente: selectedOption,
        idIncarico: id!,
      };

      formik.setFieldValue(
        'dataInizioValidita',
        optDipendenti?.find((dip) => dip.value === id)?.dataInizio ?? undefined
      );
      dispatch(detailsAssociazioneSoDipendente(body));
    }
  };

  const handleIdChange = (id: number | undefined) => {
    if (id && id != -1) {
      formik.setFieldValue('idFile', id);
    } else {
      formik.setFieldValue('idFile', undefined);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      formik.validateForm(); 
  };


  const disassociaSoDipendenti = () => {
    const body = {
      idStrutturaOrganizzativa: id,
      bodyRequest: formik.values,
    };

    dispatch(disassociaStrutturaDipendente(body));
  };

  const autocompleteDipendenti = debounce(async (inputValue: string, callback: any) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getDisassociaIncarichiContestoFormSuBaseDipendente({
      idIncarico: id!,
      chiave: inputValue,
    });
    let options: { value: number | undefined; label: string | undefined; data: any | undefined }[] =
      [];
    if (response.data.dipendenti) {
      response.data.dipendenti?.data?.forEach((value) => {
        options.push({
          value: value.id,
          label: `${value.nomeCompleto} - ${value.codiceFiscale}`,
          data: value,
        });
      });
    }
    setOptDipendenti(options);
    callback(options);
  }, 500);

  useEffect(() => {
    refDocumentUpload.current?.resetUploadFile();
    formik.resetForm();
    formik.setFieldValue('idDipendente', detailsDipendenteAssociato?.idDipendente);
    formik.setFieldValue('dataInizioValidita', detailsDipendenteAssociato?.dataInizio || '');
    formik.setFieldValue('dataFineValidita', detailsDipendenteAssociato?.dataFine || '');
    // formik.setFieldValue('attoAutorizzativoAss', detailsDipendenteAssociato?.estremiAttoConferimento || '')
    // formik.setFieldValue('attoAutorizzativoDiss', detailsDipendenteAssociato?.estremiAttoDisassociazione || '')
    formik.setFieldValue(
      'idFile',
      detailsDipendenteAssociato?.documenti?.find((doc) => doc.tipo === 'ASSEGNAZIONE_SO')?.id
        ?.documento?.id
    );
  }, [detailsDipendenteAssociato]);

  return (
    <div className="disassociazione-so">
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}
        state={{ form: true }}
        title={t('modifica associazione struttura organizzativa')!}
      ></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && (
        <>
          <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
          <div className="form-row form-custom form-inserimento mt-3">
            <Col md="12">
              <CustomAutocomplete
                required={true}
                label="Dipendente Associato"
                placeholder="Cerca un dipendente associato"
                id="select-dipendente"
                loadOptionsFn={autocompleteDipendenti}
                handleOptionSelect={(e: string) => handleChangeDipendente('idDipendente', e)}
                invalid={!!getFieldError(formik, 'idDipendente')}
                infoText={t(getFieldError(formik, 'idDipendente')) || ''}
              />
            </Col>
            {/* <Input
              type="text"
              label="Atto Autorizzativo di Associazione (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
              placeholder="Inserisci l'atto autorizzativo"
              id="input-attoAutorizzativoAss"
              wrapperClassName="required col-md-12"
              name="attoAutorizzativoAss"
              value={formik.values.attoAutorizzativoAss}
              onChange={formik.handleChange}
              invalid={!!getFieldError(formik, "attoAutorizzativoAss")}
              infoText={t(getFieldError(formik, "attoAutorizzativoAss")) || ""}
            />
            <Input
              type="text"
              label="Atto Autorizzativo di Disassociazione (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
              placeholder="Inserisci l'atto autorizzativo"
              id="input-attoAutorizzativoDiss"
              wrapperClassName="col-md-12"
              name="attoAutorizzativoDiss"
              value={formik.values.attoAutorizzativoDiss}
              onChange={formik.handleChange}
              invalid={!!getFieldError(formik, "attoAutorizzativoDiss")}
              infoText={t(getFieldError(formik, "attoAutorizzativoDiss")) || ""}
            /> */}
            <Input
              type="date"
              label="Data inizio validità"
              placeholder="Inserisci la data di inizio Validità"
              id="input-dataInizioValidita"
              name="dataInizioValidita"
              value={formik.values.dataInizioValidita}
              invalid={!!getFieldError(formik, 'dataInizioValidita')}
              infoText={t(getFieldError(formik, 'dataInizioValidita')) || ''}
              onBlur={handleBlur}
              onChange={formik.handleChange}
              wrapperClassName="col-md-6 required"
            />
            <Input
              type="date"
              label="Data fine validità"
              placeholder="Inserisci la data di fine Validità"
              id="input-dataFineValidita"
              name="dataFineValidita"
              value={formik.values.dataFineValidita}
              onBlur={handleBlur}
              onChange={formik.handleChange}
              invalid={!!getFieldError(formik, 'dataFineValidita')}
              infoText={t(getFieldError(formik, 'dataFineValidita')) || ''}
              wrapperClassName="col-md-6"
            />

            <DocumentUpload
              disabilitaDeleteFile
              invalid={!!formik.errors.idFile}
              infoText={t(getFieldError(formik, 'idFile')) || ''}
              setDocumentId={handleIdChange}
              idDocumento={formik.values.idFile}
              documentName="Autorizzazione disassociazione del dipendente alla struttura"
              className="my-3 required"
            />
          </div>
        </>
      )}

      <div className="py-4">
        <div className="d-flex flex-row-reverse">
          <Button
            color="primary"
            className="mt-2"
            onClick={() => {
              submitConfirm();
            }}
          >
            {t('conferma')}
          </Button>
          <Button
            color="secondary"
            outline
            className="mt-2 mr-2"
            onClick={() =>
              navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`, { state: { form: true } })
            }
          >
            {t('annulla')}{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisassociazioneStrutturaOrganizzativa;
