import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import {
  Button,
  Form,
  FormGroup,
  Icon,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Select,
} from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useRef, useState } from 'react';
import CustomSelect from 'components/custom-select/CustomSelect';
import useConcorsiSelectOptions from 'hooks/useConcorsiSelectOptions';
import Papa from 'papaparse';
import { useAppDispatch } from 'hooks';
import axios from 'axios';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';
import { OnboardingConcorsoStato } from 'components/crea-candidato-form/types';

interface IFormCaricaCSVForm {
  idConcorso: number;
  file: File | null;
}

const initialValues: IFormCaricaCSVForm = {
  idConcorso: 0,
  file: null,
};

export const CaricaCSVModal = ({
  isOpen,
  closeModal,
  onSubmit,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (values: IFormCaricaCSVForm) => void | Promise<any>;
}) => {
  const concorsiOptions = useConcorsiSelectOptions();
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const form = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(
      z.object({
        idConcorso: z
          .number({
            required_error: 'Obbligatorio',
          })
          .max(150, 'Massimo 150 caratteri'),
        file: z.instanceof(File).refine((file) => ['text/csv'].includes(file.type), {
          message: 'Documento deve essere di tipo CSV',
        }),
      })
    ),
    onSubmit,
  });

  const handleConcorsoChange = (event: any) => {
    form.setFieldValue('idConcorso', event);
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Upload CSV</ModalHeader>
      <ModalBody>
        <Form className="mt-4">
          {concorsiOptions?.concorsi && (
            <CustomSelect
              label="Nome Concorso"
              placeholder="Nome Concorso"
              options={
                concorsiOptions?.concorsi.map(({ value, label }) => ({
                  value,
                  label,
                })) || []
              }
              value={form.values.idConcorso}
              invalid={!!form.errors.idConcorso}
              infoText={form.errors.idConcorso}
              onChange={handleConcorsoChange}
              name="idConcorso"
              wrapperClass="col-md required"
            />
          )}

          <Button
            /* @ts-ignore */
            onClick={() => uploadInputRef.current?.click()}
            color="primary"
            outline
            size="sm"
          >
            <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
            Seleziona file
          </Button>

          <input
            id="fileInput"
            type="file"
            accept=".csv"
            ref={uploadInputRef}
            onChange={(event: any) => {
              const file = event.target.files[0];
              form.setFieldValue('file', file);
            }}
            hidden
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '10px',
              paddingBottom: '15px',
            }}
          >
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                form.submitForm();
              }}
            >
              Conferma
            </Button>
            <Button outline color="primary" onClick={closeModal} size="sm">
              Annulla
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

const InserimentoMassivoCandidatiForm = () => {
  const dispatch = useAppDispatch();
  const [openModalCaricaCSV, setOpenModalCaricaCSV] = useState(false);
  const handleOpenModalCaricaCSV = () => {
    setOpenModalCaricaCSV(true);
  };
  const handleCloseModalCaricaCSV = () => {
    setOpenModalCaricaCSV(false);
  };

  const handleCaricaCSV = () => {
    handleOpenModalCaricaCSV();
  };

  const createCandidatoAPI = async (payload: any) => {
    try {
      const salvaBozzaResponse = axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/salva/onboarding`,
        payload
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Candidato salvataggio come bozza non riuscito',
          stato: 'error',
          messaggio: 'Il salvataggio come bozza non è riuscito',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  const createCandidatiAPI = async (payload: any) => {
    try {
      const salvaBozzaResponse = axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/bozza/onboarding`,
        payload
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Candidato salvataggio come bozza non riuscito',
          stato: 'error',
          messaggio: 'Il salvataggio come bozza non è riuscito',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  const handleCaricaCSVSubmit = async ({ idConcorso, file }: IFormCaricaCSVForm) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();

    reader.onload = async (e) => {
      const text = e.target?.result as string;

      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const candidati = results.data as any[];
          // let candidatiPayload: any[] = [];

          for (const row of candidati) {
            try {
              const onboarding = {
                stato: OnboardingConcorsoStato.INC,
                statoOnb: OnboardingConcorsoStatoOnb.BOZZA,
                idConcorso,
                tipologiaContrattoId: 1,
                vincitore: row['Vincitore']?.trim() === 'Si',
                idoneo: row['Idoneo']?.trim() === 'Si',
                posizioneGraduatoria: row['Posizione in graduatoria']?.trim(),
              };

              const candidato = {
                codiceFiscale: row['Codice Fiscale']?.trim(),
                tipoDocumento: row['Tipologia documenti']?.trim() || null,
                cognome: row['Cognome']?.trim(),
                nome: row['Nome']?.trim(),
                genere: row['Sesso']?.trim(),
                statoCivile: row['Stato civile']?.trim() || null,
                cittadinanza: row['Cittadinanza']?.trim(),
                numeroCartaIdentita: row['Numero documento Identità']?.trim() || null,
                dataRilascio: row['Data rilascio']?.trim() || null,
                dataScadenza: row['Data Scadenza']?.trim() || null,
                enteEmittente: row['Ente Emittente']?.trim() || null,
                statoNascita: row['Stato']?.trim().substring(0, 1) || 'IT',
                provinciaNascita: row['Provincia di nascita']?.trim().substring(0, 1),
                cittaNascita: row['Comune di Nascita']?.trim(),
                indirizzoPec: row['Pec']?.trim() || null,
                email: row['E-mail']?.trim() || null,
                telefonoAbitazione: row['Tel abitazione']?.trim() || null,
                telefonoCellulare: row['Tel cellulare']?.trim() || null,
                statoResidenza: row['Stato']?.trim().substring(0, 1) || 'IT',
                provinciaResidenza: row['Provincia residenza']?.trim().substring(0, 1) || null,
                cittaResidenza: row['Comune Residenza']?.trim() || null,
                indirizzoResidenza: row['Indirizzo Residenza']?.trim() || null,
                capResidenza: row['CAP Residenza']?.trim().substring(0, 4) || null,
                domicilioDifferente: row['Domicilio Differente']?.trim() === 'Si',
                statoDomicilio: row['Stato']?.trim() || null,
                provinciaDomicilio: row['Provincia domicilio']?.trim() || null,
                cittaDomicilio: row['Comune domicilio']?.trim() || null,
                indirizzoDomicilio: row['Indirizzo domicilio']?.trim() || null,
                capDomicilio: row['CAP domicilio']?.trim().substring(0, 4) || null,
                disabile: row['Disabile']?.trim() === 'Si',
                percentualeDisabilita: row['Percentuale disabilità']?.trim() || null,
                collocamentoMirato: row['Iscrizione al collocamento mirato']?.trim() === 'Si',
                titoloStudio: row['Titolo di studio dichiarato']?.trim() || null,
                titoloDichiarato:
                  row[
                    'Titolo di studio dichiarato prodotto come titolo dichiarato o superiore'
                  ]?.trim() || null,
                noteTitolo: row['Note titolo di studio']?.trim() || null,
                onboardingConcorsi: [onboarding],
              };

              //candidatiPayload = [...candidatiPayload, candidato];

              await createCandidatoAPI(candidato);
              // await createCandidatiAPI(candidato);
            } catch (error) {
              console.error('Error processing candidato:', error);
            }
          }

          //await createCandidatiAPI(candidatiPayload);
        },
      });
    };

    reader.readAsText(file);

    // Close modal after processing
    handleCloseModalCaricaCSV();
  };

  const handleScaricaTemplate = () => {
    const csvHeaders = [
      'Codice Fiscale*',
      'Tipologia documenti',
      'Numero documento Identità',
      'Data rilascio',
      'Data Scadenza',
      'Ente Emittente',
      'Cognome*',
      'Nome*',
      'Sesso',
      'Stato civile',
      'Cittadinanza*',
      'Data nascita',
      'Stato',
      'Provincia di nascita',
      'Comune di Nascita',
      'Pec',
      'E-mail',
      'Tel abitazione',
      'Tel cellulare',
      'Stato',
      'Provincia residenza',
      'Comune Residenza',
      'Indirizzo Residenza',
      'CAP Residenza',
      'Domicilio Differente',
      'Stato',
      'Provincia domicilio',
      'Comune domicilio',
      'Indirizzo domicilio',
      'CAP domicilio',
      'Disabile',
      'Percentuale disabilità',
      'Iscrizione al collocamento mirato',
      'Titolo di studio dichiarato',
      'Titolo di studio dichiarato prodotto',
      'Note titolo di studio',
      'Vincitore*',
      'Idoneo*',
      'Posizione in graduatoria*',
    ];

    // Convert headers to a CSV string
    const csvContent = csvHeaders.join(';') + '\n'; // Use semicolon (;) as the delimiter for CSV

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'inserimento-massivo-candidati-template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Cleanup
  };

  return (
    <Form>
      <div className="d-flex col-12 mt-4">
        <div className="d-flex ml-auto">
          <Button color="primary" outline onClick={handleScaricaTemplate}>
            Scarica template
          </Button>
          <Button className="ml-2" color="primary" onClick={handleCaricaCSV}>
            Carica CSV
          </Button>
        </div>
      </div>
      <CaricaCSVModal
        isOpen={openModalCaricaCSV}
        closeModal={handleCloseModalCaricaCSV}
        onSubmit={handleCaricaCSVSubmit}
      />
    </Form>
  );
};

const InserimentoMassivoCandidati = () => {
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={'Inserimento massivo candidati'}
      />
      <InserimentoMassivoCandidatiForm />
    </div>
  );
};

export default InserimentoMassivoCandidati;
