/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { DipendenteRegionaleEssenzialeDto } from './dipendente-regionale-essenziale-dto';
// May contain unused imports in some cases
// @ts-ignore
import { DipendenteWrapper } from './dipendente-wrapper';
// May contain unused imports in some cases
// @ts-ignore
import { PermessoFunzionalitaWrapper } from './permesso-funzionalita-wrapper';

/**
 *
 * @export
 * @interface AnagraficaRuoloDto
 */
export interface AnagraficaRuoloDto {
  /**
   *
   * @type {number}
   * @memberof AnagraficaRuoloDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  stato?: AnagraficaRuoloDtoStatoEnum;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  nome?: string;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  codice?: string;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  descrizione?: string;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  dataInserimento?: string;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  dataModifica?: string;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  dataCancellazione?: string;
  /**
   *
   * @type {string}
   * @memberof AnagraficaRuoloDto
   */
  note?: string;
  /**
   *
   * @type {boolean}
   * @memberof AnagraficaRuoloDto
   */
  cancellato?: boolean;
  /**
   *
   * @type {Array<DipendenteRegionaleEssenzialeDto>}
   * @memberof AnagraficaRuoloDto
   */
  elencoDipendente?: Array<DipendenteRegionaleEssenzialeDto>;
  /**
   *
   * @type {Array<string>}
   * @memberof AnagraficaRuoloDto
   */
  elencoFunzionalita?: Array<AnagraficaRuoloDtoElencoFunzionalitaEnum>;
  /**
   *
   * @type {Array<DipendenteWrapper>}
   * @memberof AnagraficaRuoloDto
   */
  decodificaDipendente?: Array<DipendenteWrapper>;
  /**
   *
   * @type {Array<PermessoFunzionalitaWrapper>}
   * @memberof AnagraficaRuoloDto
   */
  decodificaFunzionalita?: Array<PermessoFunzionalitaWrapper>;
}

export const AnagraficaRuoloDtoStatoEnum = {
  Attivo: 'ATTIVO',
  Cancellato: 'CANCELLATO',
} as const;

export type AnagraficaRuoloDtoStatoEnum =
  (typeof AnagraficaRuoloDtoStatoEnum)[keyof typeof AnagraficaRuoloDtoStatoEnum];
export const AnagraficaRuoloDtoElencoFunzionalitaEnum = {
  PoElenco: 'PO_ELENCO',
  PoElencoCompleto: 'PO_ELENCO_COMPLETO',
  PoDettaglio: 'PO_DETTAGLIO',
  PoInserimento: 'PO_INSERIMENTO',
  PoPatch: 'PO_PATCH',
  PoProtocolla: 'PO_PROTOCOLLA',
  PoComunica: 'PO_COMUNICA',
  PoDisattiva: 'PO_DISATTIVA',
  PoUploadDeterminaAvviso: 'PO_UPLOAD_DETERMINA_AVVISO',
  PoModifica: 'PO_MODIFICA',
  PoUploadDeterminaDiNomina: 'PO_UPLOAD_DETERMINA_DI_NOMINA',
  PoAssocia: 'PO_ASSOCIA',
  PoDisassocia: 'PO_DISASSOCIA',
  SoElenco: 'SO_ELENCO',
  SoDettaglio: 'SO_DETTAGLIO',
  SoElencoComuni: 'SO_ELENCO_COMUNI',
  SoInsDipa: 'SO_INS_DIPA',
  SoInsSez: 'SO_INS_SEZ',
  SoInsServ: 'SO_INS_SERV',
  SoInoltra: 'SO_INOLTRA',
  SoAssociaServ: 'SO_ASSOCIA_SERV',
  SoAssociaDip: 'SO_ASSOCIA_DIP',
  SoAssociaSez: 'SO_ASSOCIA_SEZ',
  SoCensisci: 'SO_CENSISCI',
  SoModDipa: 'SO_MOD_DIPA',
  SoModSez: 'SO_MOD_SEZ',
  SoModServ: 'SO_MOD_SERV',
  SoDisaServ: 'SO_DISA_SERV',
  SoDisaDipa: 'SO_DISA_DIPA',
  SoDisaSez: 'SO_DISA_SEZ',
  SoAssCodcifra: 'SO_ASS_CODCIFRA',
  SoAssCodipa: 'SO_ASS_CODIPA',
  SoAssCodbila: 'SO_ASS_CODBILA',
  SoDisassociazioneServ: 'SO_DISASSOCIAZIONE_SERV',
  SoDisassociazioneDipa: 'SO_DISASSOCIAZIONE_DIPA',
  SoDisassociazioneSez: 'SO_DISASSOCIAZIONE_SEZ',
  SoElencoCifra: 'SO_ELENCO_CIFRA',
  SoInserimentoCifra: 'SO_INSERIMENTO_CIFRA',
  SoDeleteCifra: 'SO_DELETE_CIFRA',
  SoEliminazione: 'SO_ELIMINAZIONE',
  OrganigrammaElenco: 'ORGANIGRAMMA_ELENCO',
  OrganigrammaDettaglio: 'ORGANIGRAMMA_DETTAGLIO',
  NotificheElenco: 'NOTIFICHE_ELENCO',
  NotificheElencoNonlette: 'NOTIFICHE_ELENCO_NONLETTE',
  NotifichePatch: 'NOTIFICHE_PATCH',
  NotificheDelete: 'NOTIFICHE_DELETE',
  NotificheElencoFeedback: 'NOTIFICHE_ELENCO_FEEDBACK',
  NotificheInsertFeedback: 'NOTIFICHE_INSERT_FEEDBACK',
  IncdirigenzialeElenco: 'INCDIRIGENZIALE_ELENCO',
  IncdirigenzialeSelectDip: 'INCDIRIGENZIALE_SELECT_DIP',
  IncdirigenzialeSelectSez: 'INCDIRIGENZIALE_SELECT_SEZ',
  IncdirigenzialeSelectSer: 'INCDIRIGENZIALE_SELECT_SER',
  IncdirigenzialeInsertDip: 'INCDIRIGENZIALE_INSERT_DIP',
  IncdirigenzialeInsertSez: 'INCDIRIGENZIALE_INSERT_SEZ',
  IncdirigenzialeInsertSer: 'INCDIRIGENZIALE_INSERT_SER',
  IncdirigenzialeSelectSoAppartenenza: 'INCDIRIGENZIALE_SELECT_SO_APPARTENENZA',
  IncdirigenzialeModDip: 'INCDIRIGENZIALE_MOD_DIP',
  IncdirigenzialeModSez: 'INCDIRIGENZIALE_MOD_SEZ',
  IncdirigenzialeModSer: 'INCDIRIGENZIALE_MOD_SER',
  IncdirigenzialeProtocolla: 'INCDIRIGENZIALE_PROTOCOLLA',
  IncdirigenzialeInsertValpersonale: 'INCDIRIGENZIALE_INSERT_VALPERSONALE',
  IncdirigenzialePatchValpersonale: 'INCDIRIGENZIALE_PATCH_VALPERSONALE',
  IncdirigenzialePatchValgiunta: 'INCDIRIGENZIALE_PATCH_VALGIUNTA',
  IncdirigenzialeDettaglioValutazione: 'INCDIRIGENZIALE_DETTAGLIO_VALUTAZIONE',
  IncdirigenzialeInsertAssegnazione: 'INCDIRIGENZIALE_INSERT_ASSEGNAZIONE',
  IncdirigenzialeModificaAssegnazione: 'INCDIRIGENZIALE_MODIFICA_ASSEGNAZIONE',
  DocumentaleUpload: 'DOCUMENTALE_UPLOAD',
  DocumentaleDettaglio: 'DOCUMENTALE_DETTAGLIO',
  DocumentaleDelete: 'DOCUMENTALE_DELETE',
  DocumentaleDownload: 'DOCUMENTALE_DOWNLOAD',
  AssunzioniElenco: 'ASSUNZIONI_ELENCO',
  AssunzioniDettaglio: 'ASSUNZIONI_DETTAGLIO',
  AssunzioniInsert: 'ASSUNZIONI_INSERT',
  AssunzioniModifica: 'ASSUNZIONI_MODIFICA',
  AssunzioniPatch: 'ASSUNZIONI_PATCH',
  AssunzioniRettifica: 'ASSUNZIONI_RETTIFICA',
  AssunzioniAnteprimaConvocazione: 'ASSUNZIONI_ANTEPRIMA_CONVOCAZIONE',
  AssunzioniAnteprimaDocumento: 'ASSUNZIONI_ANTEPRIMA_DOCUMENTO',
  AssunzioniComunicaConvocazione: 'ASSUNZIONI_COMUNICA_CONVOCAZIONE',
  AssunzioniElencoConvocazioni: 'ASSUNZIONI_ELENCO_CONVOCAZIONI',
  AssunzioniPatchDecisione: 'ASSUNZIONI_PATCH_DECISIONE',
  AssunzioniConvocazioneAccettazione: 'ASSUNZIONI_CONVOCAZIONE_ACCETTAZIONE',
  AssunzioniConvocazioneCessazione: 'ASSUNZIONI_CONVOCAZIONE_CESSAZIONE',
  AssunzioniElencoDocumenti: 'ASSUNZIONI_ELENCO_DOCUMENTI',
  AssunzioniDettaglioDocumenti: 'ASSUNZIONI_DETTAGLIO_DOCUMENTI',
  AssunzioniPatchDocumentocandidato: 'ASSUNZIONI_PATCH_DOCUMENTOCANDIDATO',
  AssunzioniDettaglioAnpr: 'ASSUNZIONI_DETTAGLIO_ANPR',
  DipendenteElenco: 'DIPENDENTE_ELENCO',
  DipendenteDettaglio: 'DIPENDENTE_DETTAGLIO',
  DipendentePatch: 'DIPENDENTE_PATCH',
  DipendenteInfoDecesso: 'DIPENDENTE_INFO_DECESSO',
  ConfignotificaElenco: 'CONFIGNOTIFICA_ELENCO',
  ConfignotificaElencoPlaceholder: 'CONFIGNOTIFICA_ELENCO_PLACEHOLDER',
  ConfignotificaDettaglio: 'CONFIGNOTIFICA_DETTAGLIO',
  ConfignotificaInsert: 'CONFIGNOTIFICA_INSERT',
  ConfignotificaUpdate: 'CONFIGNOTIFICA_UPDATE',
  ConfignotificaPatch: 'CONFIGNOTIFICA_PATCH',
  AssegnaruolodipeElenco: 'ASSEGNARUOLODIPE_ELENCO',
  AssegnaruolodipeInsert: 'ASSEGNARUOLODIPE_INSERT',
  AssegnaruolodipeElencoDipeassociabili: 'ASSEGNARUOLODIPE_ELENCO_DIPEASSOCIABILI',
  AssegnaruolodipeDelete: 'ASSEGNARUOLODIPE_DELETE',
  AssegnaruolofunzElenco: 'ASSEGNARUOLOFUNZ_ELENCO',
  AssegnaruolofunzInsert: 'ASSEGNARUOLOFUNZ_INSERT',
  AssegnaruolofunzDelete: 'ASSEGNARUOLOFUNZ_DELETE',
  RuoloElenco: 'RUOLO_ELENCO',
  RuoloDettaglio: 'RUOLO_DETTAGLIO',
  RuoloInsert: 'RUOLO_INSERT',
  RuoloPatch: 'RUOLO_PATCH',
  RubricaElencoDipendenti: 'RUBRICA_ELENCO_DIPENDENTI',
  RubricaDettaglioDipendente: 'RUBRICA_DETTAGLIO_DIPENDENTE',
  RubricaPatchDipendente: 'RUBRICA_PATCH_DIPENDENTE',
  RubricaGeneraEmailDipendente: 'RUBRICA_GENERA_EMAIL_DIPENDENTE',
  RichiestefunzappElenco: 'RICHIESTEFUNZAPP_ELENCO',
  RichiestefunzappInsert: 'RICHIESTEFUNZAPP_INSERT',
  RichiestefunzappPatch: 'RICHIESTEFUNZAPP_PATCH',
  RichiestefunzappDettaglio: 'RICHIESTEFUNZAPP_DETTAGLIO',
  RichiestefunzappPatchValutazione: 'RICHIESTEFUNZAPP_PATCH_VALUTAZIONE',
  RichiestefunzappDelete: 'RICHIESTEFUNZAPP_DELETE',
  ConsulenteesternoDettaglio: 'CONSULENTEESTERNO_DETTAGLIO',
  ConsulenteesternoElenco: 'CONSULENTEESTERNO_ELENCO',
  ConsulenteesternoInsert: 'CONSULENTEESTERNO_INSERT',
  ConsulenteesternoPatch: 'CONSULENTEESTERNO_PATCH',
  ConsulenteesternoDelete: 'CONSULENTEESTERNO_DELETE',
  RegistroImpreseRicercaSingola: 'REGISTRO_IMPRESE_RICERCA_SINGOLA',
  RegistroImpreseRicercaMultipla: 'REGISTRO_IMPRESE_RICERCA_MULTIPLA',
  ConvenzioniDettaglio: 'CONVENZIONI_DETTAGLIO',
  ConvenzioniInsert: 'CONVENZIONI_INSERT',
  ConvenzioniRicerca: 'CONVENZIONI_RICERCA',
  ConvenzioniPatch: 'CONVENZIONI_PATCH',
  ConvenzioniDelete: 'CONVENZIONI_DELETE',
  BandoPoElenco: 'BANDO_PO_ELENCO',
  BandoPoDettaglio: 'BANDO_PO_DETTAGLIO',
  BandoPoElencoAssegnazioni: 'BANDO_PO_ELENCO_ASSEGNAZIONI',
  BandoPoDettaglioAssegnazione: 'BANDO_PO_DETTAGLIO_ASSEGNAZIONE',
  BandoPoInsert: 'BANDO_PO_INSERT',
  BandoPoPatch: 'BANDO_PO_PATCH',
  BandoPoDelete: 'BANDO_PO_DELETE',
  BandoPoCandidatura: 'BANDO_PO_CANDIDATURA',
  BandoPoValutazione: 'BANDO_PO_VALUTAZIONE',
  BandoPoValutazioneConferma: 'BANDO_PO_VALUTAZIONE_CONFERMA',
  BandoPoValutazioneConfermaVisualizza: 'BANDO_PO_VALUTAZIONE_CONFERMA_VISUALIZZA',
  BandoPoAssociazione: 'BANDO_PO_ASSOCIAZIONE',
  BandoPoCandidaturaElimina: 'BANDO_PO_CANDIDATURA_ELIMINA',
  BandoPoAccessoCompleto: 'BANDO_PO_ACCESSO_COMPLETO',
  BandoPoReport: 'BANDO_PO_REPORT',
  NullaOstaElenco: 'NULLA_OSTA_ELENCO',
  NullaOstaDettaglio: 'NULLA_OSTA_DETTAGLIO',
  NullaOstaInserimento: 'NULLA_OSTA_INSERIMENTO',
  NullaOstaValutazioneDirettore: 'NULLA_OSTA_VALUTAZIONE_DIRETTORE',
  NullaOstaValutazioneDirigente: 'NULLA_OSTA_VALUTAZIONE_DIRIGENTE',
  CvInserimento: 'CV_INSERIMENTO',
  CvDettaglio: 'CV_DETTAGLIO',
  CvDettaglioTutti: 'CV_DETTAGLIO_TUTTI',
  CvDownload: 'CV_DOWNLOAD',
  CvDownloadTutti: 'CV_DOWNLOAD_TUTTI',
  UploadMassivoBandiEq: 'UPLOAD_MASSIVO_BANDI_EQ',
  UploadMassivoBandiEqPreview: 'UPLOAD_MASSIVO_BANDI_EQ_PREVIEW',
} as const;

export type AnagraficaRuoloDtoElencoFunzionalitaEnum =
  (typeof AnagraficaRuoloDtoElencoFunzionalitaEnum)[keyof typeof AnagraficaRuoloDtoElencoFunzionalitaEnum];
