import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Callout, CalloutTitle, FormGroup, Icon, Input, Label } from 'design-react-kit';
import { CurriculumVitaeRequestDtoSezioneEnum } from '../../../services/ms-anagrafica-unica';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { creaNuovoCv, getCvDetail } from '../../../store/curriculumVitaeSlice';
import { GestioneCvPageRef } from '../GestioneCvForm';
import { Ref } from 'react';

export interface PrivacyProps {
  disabled: boolean;
  continuaAction: Function;
  scrollAction: Function;
  setPubblicato: (pubblicato: boolean) => void;
  disabilitaContinuaBtn: (disabilita: boolean) => void;
}

const PrivacyTab = forwardRef((props: PrivacyProps, ref: Ref<GestioneCvPageRef>) => {
  const dispatch = useAppDispatch();
  const { userInfoResult } = useAppSelector((state) => state.user);
  const { detailResult } = useAppSelector((state) => state.curriculumVitae);
  const [pubblicato, setPubblicato] = useState<boolean>(false);
  const [dichiarazione1, setDichiarazione1] = useState<boolean>(false);
  const [dichiarazione2, setDichiarazione2] = useState<boolean>(false);

  const sendForm = () => {
    dispatch(
      creaNuovoCv({
        sezione: CurriculumVitaeRequestDtoSezioneEnum.Conferma,
      })
    )
      .unwrap()
      .then((resp) => resp != null && props.continuaAction());
  };

  useEffect(() => {
    dispatch(getCvDetail(userInfoResult?.entityId!))
      .unwrap()
      .then((resp) => {
        if (!!resp && resp.stato === 'PUBBLICATO') {
          setPubblicato(true);
          props.setPubblicato(true);
          setDichiarazione1(true);
          setDichiarazione2(true);
        } else props.setPubblicato(false);
      });
  }, []);

  useEffect(() => {
    props.disabilitaContinuaBtn(!dichiarazione1 || !dichiarazione2);
  }, [dichiarazione1, dichiarazione2]);

  useImperativeHandle(
    ref,
    () => ({
      submitForm() {
        sendForm();
      },
    }),
    []
  );

  return (
    <div className="private-tab">
      <div className="form-custom form-row mt-5">
        <FormGroup check className="form-group col-md-12">
          <Input
            type="checkbox"
            defaultChecked={detailResult?.stato === 'PUBBLICATO'}
            disabled={pubblicato}
            id="dichiarazione1"
            onChange={() => setDichiarazione1(!dichiarazione1)}
          />
          <Label for={'dichiarazione1'} style={{ whiteSpace: 'normal', overflow: 'visible' }}>
            Il/la sottoscritto/a {userInfoResult?.nome} {userInfoResult?.cognome}, consapevole delle
            sanzioni penali previste dagli artt. 75 e 76 del D.P.R. n. 445/2000 nel caso di
            dichiarazioni non veritiere, mendaci, di formazione o uso di atti falsi, dichiara, ai
            sensi degli artt. 46 e 47 del D.P.R. n. 445/2000 e sotto la propria personale
            responsabilità, di essere in possesso dei titoli di studio e professionali
            precedentemente dichiarati e che quanto sopra inserito corrisponde a verità.
          </Label>
          <Callout className="au-callout au-callout-info col-md-12">
            <CalloutTitle>
              <Icon icon="it-info-circle" color="primary" size="sm" /> Informativa sulla privacy
            </CalloutTitle>
            <div className="privacy-content">
              <p>
                <u>
                  <b>Finalità</b>
                </u>
                : I dati richiesti sono raccolti e trattati esclusivamente per gli adempimenti
                attinenti allo svolgimento delle attività collegate alla gestione degli avvisi
                interni [incarichi dirigenziali e di comparto, elevate qualificazione, progressioni
                verticali e orizzontali] che necessitano dell’acquisizione dei curricula del
                personale regionale.
              </p>

              <p>
                <u>
                  <b>Base giuridica</b>
                </u>
                : Il trattamento dei dati per la finalità sopra descritta viene effettuato in
                applicazione del D. Lgs. n. 29/1993, del D. Lgs. n. 165/2001, del vigente CCNL
                Funzioni Locali Triennio 2019-2021. Inoltre, tale trattamento dei dati personali
                comuni è necessario per l’assolvimento degli obblighi di legge ai quali è soggetto
                il Titolare del trattamento ai sensi dell’art. 6, par. 1), lett. c) del Regolamento
                (UE) 2016/679 e per l'esecuzione di un compito di interesse pubblico o connesso
                all'esercizio di pubblici poteri di cui è investito il titolare del trattamento
                (art. 6, par. 1, lett. c) e lett. e) del GDPR.
              </p>

              <p>
                <u>
                  <b>Tipologie di dati personali oggetto di trattamento</b>
                </u>
                : dati personali comuni contenuti nei curricula, dati relativi all’utente che
                utilizza il sistema [nome, cognome, c.f., email personale istituzionale, ruolo], in
                fase di autenticazione tramite SPID o IAM.
              </p>

              <p>
                <u>
                  <b>Titolare dei dati</b>
                </u>
                : ARPAL Puglia, con sede in Bari alla via Niceforo 1-3 , in persona del
                Rappresentante legale Dott. Beniamino Di Cagno[b.dicagno@arpal.regione.puglia.it].
              </p>

              <p>
                <u>
                  <b>Responsabili del trattamento</b>
                </u>
                : ai sensi dell’art. 28 del Regolamento (UE) 2016/679, sono responsabili del
                trattamento le Società del Fornitore RTI [fornitore sul sistema di Anagrafica Unica
                di ARPAL Puglia] e la società in house InnovaPuglia S.p.A. [gestore del cloud
                regionale sul quale è ospitato il sistema informativo].
              </p>

              <p>
                <u>
                  <b>Responsabile della protezione dei dati</b>
                </u>
                : Il punto di contatto con il DPO è il seguente: dpo@regione.puglia.it.
              </p>

              <p>
                <u>
                  <b>
                    Soggetti/Categorie di soggetti ai quali i dati possono essere comunicati o che
                    possono venirne a conoscenza
                  </b>
                </u>
                : i dati sono trattati esclusivamente dai dipendenti di ARPAL Puglia, autorizzati al
                trattamento ai sensi dell’art. 29 del Regolamento (UE) 2016/679 nell’ambito delle
                specifiche attività di ufficio, e dagli eventuali ulteriori soggetti a ciò
                espressamente autorizzati. I curricula potranno essere oggetto di pubblicazione in
                “Amministrazione Trasparente” ai sensi del D. Lgs. n. 33/2013.
              </p>

              <p>
                <u>
                  <b>Trasferimento in Paesi Terzi</b>
                </u>
                : i dati personali trattati non saranno oggetto di trasferimento in Paesi terzi
                extraeuropee.
              </p>

              <p>
                <u>
                  <b>Presenza di processi decisionali automatizzati</b>
                </u>
                : il trattamento in oggetto non comporta processi decisionali automatizzati.
              </p>

              <p>
                <u>
                  <b>Modalità del trattamento</b>
                </u>
                : Il trattamento dei dati avviene sia con strumenti informatici che in modalità
                analogica.
              </p>

              <p>
                <u>
                  <b>Periodo di conservazione</b>
                </u>
                : I dati saranno trattati e conservati per tutta la durata delle relative procedure.
                Al termine, i dati saranno conservati in conformità alla disciplina sulla
                conservazione della documentazione amministrativa per l’eventuale ulteriore periodo
                previsto dalle specifiche norme di settore.
              </p>

              <p>
                <u>
                  <b>Diritti degli interessati</b>
                </u>
                : Gli interessati hanno il diritto di ottenere dalla ARPAL Puglia l'accesso ai
                propri dati personali e la rettifica o la cancellazione degli stessi o la
                limitazione del trattamento che li riguarda o di opporsi al trattamento (articoli 15
                e ss. GDPR). L'apposita istanza all’ ARPAL Puglia è presentata al Titolare del
                Trattamento, o in alternativa, contattando il Responsabile della Protezione dei
                dati.
              </p>

              <p>
                <u>
                  <b>Diritto di reclamo</b>
                </u>
                : Gli interessati che ritengono che il presente trattamento dei dati personali
                avvenga in violazione di quanto previsto dal GDPR hanno il diritto di proporre
                reclamo al Garante per la Protezione dei dati personali come previsto dall'articolo
                77 del Regolamento stesso, o di adire le opportune sedi giudiziarie ai sensi
                dell’articolo 79 del medesimo Regolamento.
              </p>
            </div>
          </Callout>
          <Input
            type="checkbox"
            id="dichiarazione2"
            defaultChecked={detailResult?.stato === 'PUBBLICATO'}
            disabled={pubblicato}
            onChange={() => setDichiarazione2(!dichiarazione2)}
          />
          <Label for={'dichiarazione2'} style={{ whiteSpace: 'normal', overflow: 'visible' }}>
            Il/la sottoscritto/a {userInfoResult?.nome} {userInfoResult?.cognome}, ai sensi del
            Regolamento (UE) 2016/679 (GDPR) e del D.Lgs. 30.06.2003, n. 196 dichiara di aver preso
            visione dell’informativa sopra riportata.
          </Label>
        </FormGroup>
      </div>
    </div>
  );
});

export default PrivacyTab;
