import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreaCVContestoForm,
  CurriculumVitaeDto,
  CurriculumVitaeRequestDto,
  FormsControllerApi,
  GestioneCVControllerApi,
  GestioneCVControllerApiDownloadCVRequest,
} from '../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';
import { AxiosError } from 'axios';

export interface GestioneCvState {
  insertResult: CurriculumVitaeRequestDto | null;
  insertStatus: string;
  detailResult: CurriculumVitaeDto | null;
  detailStatus: string;
  contestoResult: CreaCVContestoForm | null;
  contestoStatus: string;
  cvDownloadStatus: string;
}

const initialState: GestioneCvState = {
  insertResult: null,
  insertStatus: '',
  detailResult: null,
  detailStatus: '',
  contestoResult: null,
  contestoStatus: '',
  cvDownloadStatus: '',
};

export const creaNuovoCv = createAsyncThunk(
  'cv/crea-modifica',
  async (body: CurriculumVitaeRequestDto) => {
    const api = new GestioneCVControllerApi(MS_AU_CONFIG);
    const response = await api.putCv({
      curriculumVitaeRequestDto: body,
    });
    return response instanceof AxiosError ? null : response.data;
  }
);

export const getCvDetail = createAsyncThunk('cv/dettaglio', async (id: number) => {
  const api = new GestioneCVControllerApi(MS_AU_CONFIG);
  const response = await api.dettaglioCV({
    idCid: id,
  });
  return response.data;
});

export const getGestioneCvOpts = createAsyncThunk('cv/domini', async () => {
  const api = new FormsControllerApi(MS_AU_CONFIG);
  const response = await api.getCreaCVContestoForm();
  return response.data;
});

export const getTitoliStudioOpts = createAsyncThunk('cv/titoli-studio', async () => {
  const api = new FormsControllerApi(MS_AU_CONFIG);
  const response = await api.getElencoTitoliDiStudioCVContestoForm();
  return response.data;
});

export const getDettaglioTitoloStudioOpts = createAsyncThunk(
  'cv/titolo-studio-dettaglio',
  async (id: number) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoTitoliDiStudioCVContestoFormConChiave({
      id: id,
    });
    return response.data;
  }
);

export const getElencoAmbitoEsperienza = createAsyncThunk(
  'cv/elenco-ambito-di-esperienza',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoAmbitoEsperienzaForm();
    return response.data;
  }
);

export const getElencoTipologiaContratto = createAsyncThunk(
  'cv/elenco-ambito-di-esperienza',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getTipologiaDiContrattoForm();
    return response.data;
  }
);

export const getProfiliProfessionali = createAsyncThunk(
  'cv/elenco-profili-professionali/{idArea}',
  async (idArea: number) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getProfiliProfessionali({ idArea });
    return response.data;
  }
);

export const getAmbitoRuolo = createAsyncThunk(
  'cv/elenco-ambito-ruolo/{idProfilo}',
  async (idProfilo: number) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getAmbitoRuolo({ idProfilo });
    return response.data;
  }
);

export const cvDownload = createAsyncThunk(
  'cv/download',
  async (args: GestioneCVControllerApiDownloadCVRequest) => {
    const api = new GestioneCVControllerApi(MS_AU_CONFIG);
    const response = await api.downloadCV(args, { responseType: 'arraybuffer' });
    return response.data;
  }
);

const gestioneCvSlice = createSlice({
  name: 'gestioneCv',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state = {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(creaNuovoCv.pending, (state: any, action: any) => {
      state.insertStatus = STATUS_PENDING;
    });
    builder.addCase(creaNuovoCv.fulfilled, (state: GestioneCvState, action: any) => {
      state.insertResult = action.payload;
      state.insertStatus = STATUS_FULLFILLED;
    });
    builder.addCase(creaNuovoCv.rejected, (state: any, action: any) => {
      state.insertStatus = STATUS_REJECTED;
    });
    builder.addCase(getCvDetail.pending, (state: any, action: any) => {
      state.detailStatus = STATUS_PENDING;
    });
    builder.addCase(getCvDetail.fulfilled, (state: GestioneCvState, action: any) => {
      state.detailResult = action.payload;
      state.detailStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getCvDetail.rejected, (state: any, action: any) => {
      state.detailStatus = STATUS_REJECTED;
    });
    builder.addCase(getGestioneCvOpts.pending, (state: any, action: any) => {
      state.contestoStatus = STATUS_PENDING;
    });
    builder.addCase(getGestioneCvOpts.fulfilled, (state: GestioneCvState, action: any) => {
      state.contestoResult = action.payload;
      state.contestoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getGestioneCvOpts.rejected, (state: any, action: any) => {
      state.contestoStatus = STATUS_REJECTED;
    });
    builder.addCase(getElencoAmbitoEsperienza.fulfilled, (state: GestioneCvState, action: any) => {
      state.contestoResult = action.payload;
      state.contestoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getProfiliProfessionali.fulfilled, (state: GestioneCvState, action: any) => {
      state.contestoResult = action.payload;
      state.contestoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(getAmbitoRuolo.fulfilled, (state: GestioneCvState, action: any) => {
      state.contestoResult = action.payload;
      state.contestoStatus = STATUS_FULLFILLED;
    });
    builder.addCase(cvDownload.pending, (state: GestioneCvState, action: any) => {
      state.cvDownloadStatus = STATUS_PENDING;
    });
    builder.addCase(cvDownload.fulfilled, (state: GestioneCvState, action: any) => {
      state.cvDownloadStatus = STATUS_FULLFILLED;
    });
    builder.addCase(cvDownload.rejected, (state: GestioneCvState, action: any) => {
      state.cvDownloadStatus = STATUS_REJECTED;
    });
  },
});

export default gestioneCvSlice.reducer;
