import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  LinkList,
  LinkListItem,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useTranslation } from 'react-i18next';
import Pagination from '../../components/common/pagination/Pagination';
import { useFormik } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  loadPOFormSearch,
  protocollaPO,
  resetDetailResult,
  resetStatus,
  SearchPoRequest,
  searchPosizioniOrganizzative,
} from '../../store/posizioneOrganizzativaSlice';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import CustomAutocomplete, {
  CustomAutocompleteRef,
} from '../../components/common/custom-autocomplete/CustomAutocomplete';
import {
  OptionDtoStatoPosizioneOrganizzativaValueEnum,
  PosizioneOrganizzativaDtoTipoEnum,
  PosizioneOrganizzativaProtocollaRequestDto,
  RuoloUtenteAutenticatoDto,
  StrutturaOrganizzativaControllerApi,
} from '../../services/ms-anagrafica-unica';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from '../../store/store-constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA,
  HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA,
  HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA,
  HREF_DOWNLOAD_NULLA_OSTA,
  HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA,
  HREF_NUOVA_POSIZIONE_ORGANIZZATIVA,
  HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO,
  HREF_UPLOAD_NOMINA,
  HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA,
} from '../../components/layout/sidemenu/sidemenuConstants';
import { debounce } from 'lodash';
import { format, parseISO } from 'date-fns';
import PageHeader from '../../components/common/page-header/PageHeader';
import { checkCampiRicercaValorizzati } from 'utility/utility';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

type Direction = 'ASC' | 'DESC';

interface SortingState {
  direction: Direction;
}

const initialValues: SearchPoRequest = {
  pageNum: 0,
  pageSize: 10,
  denominazione: '',
  strutturaOrganizzativa: '',
  stato: undefined,
  tipo: undefined,
  sort: '',
  direction: undefined,
};

function ElencoPosizioniOrganizzative(props: { operations: RuoloUtenteAutenticatoDto }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchResult, searchStatus, formSearchComponents, searchInput } = useAppSelector(
    (state) => state.posizioneOrganizzativa
  );
  const [formCollapseOpen, setFormCollapseOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<CustomAutocompleteRef>(null);

  let hasResults =
    searchResult && searchResult.totalElements ? searchResult.totalElements > 0 : false;

  let statoOptions: CustomSelectItem[] = [];
  let tipoEqOptions: CustomSelectItem[] = [
    { label: t('tipoEQ_PO'), value: 'PO' },
    { label: t('tipoEQ_PO_EQUIPARATA'), value: 'PO_EQUIPARATA' },
  ];

  formSearchComponents?.stato?.forEach((s) => {
    statoOptions.push({ label: s.label, value: s.valore });
  });

  const searchForm = useFormik<SearchPoRequest>({
    initialValues: initialValues,
    onSubmit: () => {
      //handlePageChange(1);
      doSearch();
    },
  });

  const doSearch = () => {
    dispatch(searchPosizioniOrganizzative(searchForm.values));
  };

  const handlePageChange = (page: number) => {
    searchForm.setFieldValue('pageNum', page - 1);
    searchForm.handleSubmit();
  };

  const handleChange = (selectedOption: any) => {
    searchForm.setFieldValue('stato', selectedOption);
    searchForm.handleChange(selectedOption);
  };
  const handleChangeTipoEq = (selectedOption: any) => {
    searchForm.setFieldValue('tipo', selectedOption);
    searchForm.handleChange(selectedOption);
  };

  const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.searchStrutturaOrganizzativa({
      denominazione: inputValue,
    });
    let options: { value: string | undefined; label: string | undefined }[] = [];
    if (response.data && response.data.data) {
      response.data.data.forEach((d) => {
        options.push({ value: d.id, label: d.descrizioneBreve });
      });
    }
    callback(options);
  }, 500);

  const handleStrutturaOrganizzativaSelect = (value: string) => {
    searchForm.setFieldValue('strutturaOrganizzativa', value);
    searchForm.handleChange(value);
  };

  const resetForm = () => {
    searchForm.resetForm();
    ref.current?.resetAutocompleteForm();
  };

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const protocollaPatch = (idCodiceIncarico: string | undefined) => {
    const param: PosizioneOrganizzativaProtocollaRequestDto = {
      codiceUnivoco: idCodiceIncarico,
      requestType: 'Protocollazione',
    };
    dispatch(
      protocollaPO({
        patchPosizioneOrganizzativaRequest: param,
        idCodiceIncarico: idCodiceIncarico as string,
      })
    ).then(() => {
      doSearch();
    });
  };

  const showInserisciPOOperations = () =>
    props.operations?.elencoFunzionalita?.includes('PO_INSERIMENTO');

  const showModificaPOOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
    props.operations?.elencoFunzionalita?.includes('PO_PATCH') &&
    poStato !== OptionDtoStatoPosizioneOrganizzativaValueEnum.Disattivata;

  const showVisualizzaPOOperations = () =>
    props.operations?.elencoFunzionalita?.includes('PO_DETTAGLIO');

  const showAssociaPOOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
    props.operations?.elencoFunzionalita?.includes('PO_ASSOCIA') &&
    poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Associabile;

  const showDisassociaPOOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
    props.operations?.elencoFunzionalita?.includes('PO_DISASSOCIA') &&
    poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Associata;

  const showUploadNominaOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
    props.operations?.elencoFunzionalita?.includes('PO_UPLOAD_DETERMINA_DI_NOMINA') &&
    poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Creata;

  const showUploadDeterminaOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
    props.operations?.elencoFunzionalita?.includes('PO_UPLOAD_DETERMINA_AVVISO') &&
    poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.InseritaEComunicata;

  const showDisattivaPOOperations = () =>
    props.operations?.elencoFunzionalita?.includes('PO_DISATTIVA');

  const showDownloadNullaOstaOperations = (
    poTipo: PosizioneOrganizzativaDtoTipoEnum,
    poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum
  ) =>
    poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Inserita &&
    poTipo === PosizioneOrganizzativaDtoTipoEnum.Po;

  useEffect(() => {
    if (location.state?.form && !!searchInput && checkCampiRicercaValorizzati(searchInput)) {
      setFormCollapseOpen(true);
      dispatch(searchPosizioniOrganizzative(searchInput));
    } else {
      doSearch();
    }
    dispatch(loadPOFormSearch());
    dispatch(resetDetailResult());
    dispatch(resetStatus());
  }, []);

  useEffect(() => {
    searchForm.setValues({
      denominazione: searchInput?.denominazione || '',
      stato: searchInput?.stato || '' || undefined,
      tipo: searchInput?.tipo || '' || undefined,
      pageNum: searchInput?.pageNum || 0,
      pageSize: searchInput?.pageSize || 10,
      strutturaOrganizzativa: searchInput?.strutturaOrganizzativa || '',
      direction: searchInput?.direction || '' || undefined,
      sort: searchInput?.sort || '',
    });
  }, [searchInput]);

  const newButton = {
    buttonProperties: { outline: false },
    title: 'Nuova Elevata Qualificazione',
    buttonColor: 'primary',
    action: () => navigate(HREF_NUOVA_POSIZIONE_ORGANIZZATIVA),
    showButtonIcon: true,
    icon: 'it-plus-circle',
    iconColor: 'white',
  };

  return (
    <div>
      <PageHeader
        showIcon={false}
        title={t('elenco elevate qualificazioni')!}
        buttons={showInserisciPOOperations() ? [newButton] : []}
      />

      <Accordion className="form-custom form-bg filtri-ricerca" background="active">
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="form-row mt-2">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />
            <Col md="6">
              <Input
                type="text"
                id="input-denominazione-1"
                placeholder="Inserisci una denominazione"
                name="denominazione"
                value={searchForm.values.denominazione}
                onChange={searchForm.handleChange}
              />
            </Col>

            <Col md="6">
              <CustomSelect
                placeholder="Stato"
                options={statoOptions}
                value={searchForm.values.stato}
                onChange={handleChange}
              />
            </Col>
          </div>

          <div className="form-row">
            <Col md="6">
              <CustomAutocomplete
                ref={ref}
                placeholder="Seleziona Struttura organizzativa"
                id="select-struttura-organizzativa"
                value={searchForm.values.strutturaOrganizzativa}
                loadOptionsFn={autocompleteStruttureOrganizzative}
                handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
              />
            </Col>

            <Col md="6">
              <CustomSelect
                name="tipoEQ"
                placeholder="Tipo EQ"
                options={tipoEqOptions}
                value={searchForm.values.tipo}
                onChange={handleChangeTipoEq}
              />
            </Col>
          </div>

          <div className="d-flex flex-row-reverse">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                searchForm.handleSubmit();
              }}
            >
              Applica
            </Button>
            <Button
              outline
              color="primary"
              className="mt-2 mr-2"
              onClick={() => {
                resetForm();
              }}
            >
              Pulisci
            </Button>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-4 position-relative">
        {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && (
          <Alert color="info">Nessun risultato disponibile</Alert>
        )}
        {searchStatus === STATUS_FULLFILLED && hasResults && (
          <>
            <Table striped responsive size="sm">
              <TableTheadCustom
                colonne={[
                  { titolo: 'Denominazione', nomeColonna: 'descrizione' },
                  { titolo: 'Struttura organizzativa', nomeColonna: 'struttura_organizzativa' },
                  { titolo: 'Inserimento', nomeColonna: 'data_inserimento' },
                  { titolo: 'data modifica', nomeColonna: 'data_modifica' },
                  { titolo: 'Stato' },
                  { titolo: 'Associato' },
                  { titolo: 'Tipo EQ' },
                  { titolo: 'Azioni' },
                ]}
                datiOrdinamento={searchForm.values}
                eseguiOrdinamento={ordinamento}
              />

              <tbody>
                {(searchResult.data || []).map((x, i, dataset) => (
                  <Fragment key={i}>
                    <tr>
                      <td>{x.denominazione}</td>
                      <td>{x.nomeStrutturaOrganizzativa}</td>
                      <td>
                        {x.dataInserimento != null
                          ? format(parseISO(x.dataInserimento!), 'dd/MM/yyyy')
                          : ''}
                      </td>
                      <td>
                        {x.dataModifica != null
                          ? format(parseISO(x.dataModifica!), 'dd/MM/yyyy')
                          : ''}
                      </td>
                      <td>{x.stato}</td>
                      <td>{x.dipendenteRegionale}</td>
                      <td>{x.tipo != null ? t('tipoEQ_' + x.tipo) : ''}</td>
                      <td>
                        {
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle nav>
                              <Icon icon="it-settings" color="primary" size="sm" />
                            </DropdownToggle>
                            <DropdownMenu className="no-arrow">
                              <LinkList>
                                {showVisualizzaPOOperations() && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(
                                        `${HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`
                                      )
                                    }
                                  >
                                    <span>Visualizza</span>
                                  </LinkListItem>
                                )}
                                {showModificaPOOperations(x.stato!) && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(
                                        `${HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`
                                      )
                                    }
                                  >
                                    <span>Modifica</span>
                                  </LinkListItem>
                                )}
                                {x.protocollabile && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() => protocollaPatch(x.codiceUnivoco)}
                                  >
                                    <span>Protocolla</span>
                                  </LinkListItem>
                                )}
                                {showAssociaPOOperations(x.stato!) && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(
                                        `${HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`
                                      )
                                    }
                                  >
                                    <span>Associa</span>
                                  </LinkListItem>
                                )}
                                {showDisassociaPOOperations(x.stato!) && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(
                                        `${HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`
                                      )
                                    }
                                  >
                                    <span>Disassocia</span>
                                  </LinkListItem>
                                )}
                                {showUploadNominaOperations(x.stato!) && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(`${HREF_UPLOAD_NOMINA}/${x.codiceUnivoco}`)
                                    }
                                  >
                                    <span>Upload nomina</span>
                                  </LinkListItem>
                                )}
                                {showUploadDeterminaOperations(x.stato!) && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(
                                        `${HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO}/${x.codiceUnivoco}`
                                      )
                                    }
                                  >
                                    <span>Upload determina istituzione</span>
                                  </LinkListItem>
                                )}
                                {showDownloadNullaOstaOperations(x.tipo!, x.stato!) && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(`${HREF_DOWNLOAD_NULLA_OSTA}/${x.codiceUnivoco}`)
                                    }
                                  >
                                    <span>Scarica Nulla Osta</span>
                                  </LinkListItem>
                                )}
                                {showDisattivaPOOperations() && x.disattivabile && (
                                  <LinkListItem
                                    size="medium"
                                    onClick={() =>
                                      navigate(
                                        `${HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`
                                      )
                                    }
                                  >
                                    <span>Disattiva</span>
                                  </LinkListItem>
                                )}
                              </LinkList>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        }
                      </td>
                    </tr>
                    {/* corregge il popup delle azioni in caso di 1 risultato */}
                    {dataset.length === 1 && (
                      <tr>
                        <td style={{ height: 100, border: 0 }} colSpan={7}></td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </Table>

            <Pagination
              totalCount={searchResult.totalElements || 0}
              siblingCount={1}
              currentPage={
                searchForm && searchForm.values && searchForm.values.pageNum
                  ? searchForm.values.pageNum + 1
                  : 1
              }
              pageSize={searchResult.pageSize || 10}
              onPageChange={(page: number) => {
                handlePageChange(page);
              }}
              className="justify-content-center"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ElencoPosizioniOrganizzative;
