//@ts-nocheck
import { Icon, Pager, PagerItem, PagerLink } from 'design-react-kit';
import { DOTS, usePagination } from './usePagination';
import { v4 as uuidv4 } from 'uuid';

import './Pagination.scss';

export interface PaginationProps {
  onPageChange: Function;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
  className?: string;
}

function Pagination(props: PaginationProps) {
  const { onPageChange, totalCount, siblingCount, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage != lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage != 1) {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Pager className={className} aria-label="Esempio di paginazione">
      <ul className="pagination">
        <PagerItem disabled={currentPage === 1} onClick={onPrevious}>
          <PagerLink previous href="#">
            <Icon icon="it-chevron-left" aria-hidden />
          </PagerLink>
        </PagerItem>

        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            const k = uuidv4();
            return (
              <PagerItem className="d-none d-sm-block" key={k}>
                <PagerLink tag="span">…</PagerLink>
              </PagerItem>
            );
          }

          return (
            <PagerItem
              key={pageNumber}
              className="d-none d-sm-block"
              active={pageNumber === currentPage}
              aria-label={`Vai a pagina ${pageNumber}`}
              title={`Vai a pagina ${pageNumber}`}
              onClick={() => {
                onPageChange(pageNumber);
              }}
            >
              <PagerLink href="#" aria-current={pageNumber === currentPage}>
                {pageNumber}
              </PagerLink>
            </PagerItem>
          );
        })}

        <PagerItem disabled={currentPage === lastPage} onClick={onNext}>
          <PagerLink next href="#">
            <Icon icon="it-chevron-right" aria-hidden />
          </PagerLink>
        </PagerItem>
      </ul>
    </Pager>
  );
}

export default Pagination;
