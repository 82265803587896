import { z } from 'zod';

const validationSchemaWithAddedFields = z.object({
  //Dati anagrafici
  codiceFiscale: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(16, 'Il codice fiscale deve essere di 16 caratteri')
    .max(16, 'Il codice fiscale deve essere di 16 caratteri'),
  cognome: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci il cognome')
    .max(200),
  nome: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci il nome')
    .max(50),
  sesso: z.enum(['F', 'M'], {
    errorMap: (issue, ctx) => ({
      message: 'Inserisci F o M',
    }),
  }),
  statoCivile: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona lo stato civile')
    .max(40),
  cittadinanza: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci la cittadinanza')
    .max(20),
  dataNascita: z.string({
    required_error: 'Obbligatorio',
  }),
  nazioneNascita: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona la nazione di nascita')
    .max(50),
  provNascita: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona la provincia di nascita')
    .max(50),
  cittaNascita: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci la città di nascita')
    .max(50),
  //Estremi identificazione
  tipoDocumento: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona una tipologia di documento'),
  numeroCartaIdentita: z
    .string({
      required_error: 'Obbligatorio',
    })
    .max(10, 'Massimo 10 caratteri'),
  enteEmittente: z
    .string({
      required_error: 'Obbligatorio',
    })
    .max(30, 'Massimo 30 caratteri'),
  dataRilascio: z.string({
    required_error: 'Obbligatorio',
  }),
  dataScadenza: z.string({
    required_error: 'Obbligatorio',
  }),
  //Recapiti
  indirizzoPec: z
    .string()
    .email('Inserisci un indirizzo PEC valido')
    .max(150, 'Massimo 150 caratteri')
    .optional(),
  email: z
    .string()
    .email('Inserisci un indirizzo email valido')
    .max(150, 'Massimo 150 caratteri')
    .optional(),
  telefonoAbitazione: z.string().max(15, 'Massimo 15 caratteri').optional(),
  telefonoCellulare: z.string().max(15, 'Massimo 15 caratteri').optional(),
  //Dati residenza
  statoResidenza: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Seleziona uno stato'),
  provinciaResidenza: z.string().optional(),
  cittaResidenza: z.string().optional(),
  indirizzoResidenza: z
    .string({
      required_error: 'Obbligatorio',
    })
    .max(100, 'Massimo 100 caratteri')
    .min(1, "Inserisci l'indirizzo"),
  capResidenza: z
    .string({
      required_error: 'Obbligatorio',
    })
    .max(5, 'CAP non valido')
    .min(1, 'Inserisci il CAP'),
  //Dati domicilio
  domicilioDifferente: z.enum(['No', 'Si']),
  statoDomicilio: z.string().optional(),
  provinciaDomicilio: z.string().optional(),
  cittaDomicilio: z.string().optional(),
  indirizzoDomicilio: z.string().max(200, 'Massimo 200 caratteri').optional(),
  capDomicilio: z.string().max(5, 'CAP non valido').optional(),
  // Concorso
  idConcorso: z
    .number({
      required_error: 'Obbligatorio',
    })
    .max(150, 'Massimo 150 caratteri'),
  nomeNuovoConcorso: z.string().max(150).optional(),
  areaProfessionale: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci area professionale'),
  livelli: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci posizione economica'),
  vincitore: z.boolean().optional(),
  idoneo: z.boolean().optional(),
  posizioneGraduatoria: z
    .string({
      required_error: 'Obbligatorio',
    })
    .min(1, 'Inserisci posizione graduatoria')
    .max(5, 'Massimo 5 caratteri'),
  // Disabilità
  disabile: z.enum(['No', 'Si']),
  percentualeDisabilita: z.number().optional(),
  collocamentoMirato: z.enum(['No', 'Si']).optional(),
  //Titolo di studio
  titoloStudio: z.string().max(200, { message: 'Massimo 200 caratteri' }).optional().nullable(),
  titoloDichiarato: z.boolean().optional(),
  noteTitolo: z.string().max(5000, { message: 'Massimo 5000 caratteri' }).optional(),
  note: z.string().max(5000, { message: 'Massimo 5000 caratteri' }).optional(),
  filesIdentificazione: z.array(z.instanceof(File)).min(1, 'Devi caricare almeno un file'),
  attiOnboarding: z.array(z.any()).min(1, 'Devi aggiungere almeno un atto'),
});
/*  .refine(
    (data) => {
      console.log("DATAAAAAAAAAAAAAAA", data);
      const isValid = data.email || data.indirizzoPec;
      console.log("Refine validation triggered:", isValid);
      return isValid;
    },
    {
      message: "Inserisci almeno un indirizzo email o PEC",
      path: ["email"], // Attach the error to the email field
    }
  )
  .refine((data) => data.disabile, {
    message: "Seleziona percentuale disabilità e collocamento mirato",
    path: ["disabile"],
  })
  .refine((data) => data.collocamentoMirato, {
    message: "Seleziona file per collocamento mirato",
    path: ["collocamentoMirato"],
  }); */

export default validationSchemaWithAddedFields;
