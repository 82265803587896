import { Badge, Collapse, Icon, LinkList, LinkListItem, Sidebar } from 'design-react-kit';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  COLLAPSE_NAME_GESTIONE_ABILITAZIONI,
  COLLAPSE_NAME_GESTIONE_CANDIDATI,
  COLLAPSE_NAME_GESTIONE_CONSULENTE_ESTERNO,
  COLLAPSE_NAME_GESTIONE_CONVENZIONI,
  COLLAPSE_NAME_GESTIONE_DIPENDENTI,
  COLLAPSE_NAME_GESTIONE_INCARICHI_DIRIGENZIALI,
  COLLAPSE_NAME_GESTIONE_PO,
  COLLAPSE_NAME_GESTIONE_RUOLI_FUNZIONALITA,
  COLLAPSE_NAME_GESTIONE_SO,
  COLLAPSE_NAME_RUBRICA,
  COLLASPE_REGISTRO_IMPRESE,
  HREF_ABILITAZIONI_ELENCO_RICHIESTE,
  HREF_ABILITAZIONI_NUOVA_RICHIESTA,
  HREF_CANDIDATURA_NULLA_OSTA,
  HREF_CONF_AREA_DOCUMENTI,
  HREF_CONSULENTE_ESTERNO_ELENCO,
  HREF_CONSULENTE_ESTERNO_INSERISCI,
  HREF_ELENCO_BANDI,
  HREF_ELENCO_CANDIDATI,
  HREF_ELENCO_CONVENZIONI,
  HREF_ELENCO_DIPENDENTI_REG,
  HREF_ELENCO_INCARICHI_DIRIGENZIALI,
  HREF_ELENCO_POSIZIONI_ORGANIZZATIVE,
  HREF_ELENCO_RICHIESTE_NULLA_OSTA,
  HREF_ELENCO_RUOLI,
  HREF_ELENCO_STRUTTURE_ORGANIZZATIVE,
  HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO,
  HREF_VISUALIZZA_CV,
  HREF_GESTIONE_NOTIFICHE,
  HREF_INSERIMENTO_CANDIDATO,
  HREF_NUOVA_CONFIGURAZIONE_NOTIFICA,
  HREF_NUOVA_CONVENZIONE,
  HREF_NUOVA_POSIZIONE_ORGANIZZATIVA,
  HREF_NUOVA_PROPOSTA_INCARICO,
  HREF_NUOVA_STRUTTURA_ORGANIZZATIVA,
  HREF_NUOVO_BANDO,
  HREF_NUOVO_RUOLO,
  HREF_ORGANIGRAMMA,
  HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA,
  HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA,
  HREF_RUBRICA_ELENCO_DIPENDENTI,
  HREF_SCRIVANIA_VIRTUALE,
  HREF_SH_AREA_PERSONALE,
  HREF_SH_BANDI_CONCORSI,
  HREF_SH_DOCUMENTI,
  HREF_SH_PRATICHE,
  HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO,
  HREF_CANDIDATURA_ELENCO_BANDI,
  COLLASPE_NAME_GESTIONE_CV,
  mappaCollapse,
  HREF_GESTIONE_CV,
  HREF_UPLOAD_GESTIONE_MASSIVA,
  HREF_INSERIMENTO_MASSIVO_CANDIDATI,
} from './sidemenuConstants';
import { setCurrentCollapse, setCurrentPage } from '../../../store/sidemenuSlice';
import './Sidemenu.scss';
import { useEffect, useState } from 'react';
import { detailsCandidato } from '../../../store/candidatoSlice';

function Sidemenu() {
  const [processAuth] = useState<boolean>(process.env.REACT_APP_MS_ACTIVATE_AUTH === 'false');

  const [showElencoSO, setShowElencoSO] = useState<boolean>(false);
  const [showNuovaSO, setShowNuovaSO] = useState<boolean>(false);
  const [showElencoPO, setShowElencoPO] = useState<boolean>(false);
  const [showNuovaPO, setShowNuovaPO] = useState<boolean>(false);
  const [showOrganigrammaElenco, setShowOrganigrammaElenco] = useState<boolean>(false);
  const [showElencoRuolo, setShowElencoRuolo] = useState<boolean>(false);
  const [showNuovoRuolo, setShowNuovoRuolo] = useState<boolean>(false);
  const [showElencoConfigNotifica, setShowElencoConfigNotifica] = useState<boolean>(false);
  const [showNuovoConfigNotifica, setShowNuovoConfigNotifica] = useState<boolean>(false);
  const [showElencoDipendentiRegionali, setShowElencoDipendentiRegionali] =
    useState<boolean>(false);
  const [showElencoIncarichiDirigenziali, setShowElencoIncarichiDirigenziali] =
    useState<boolean>(false);
  const [showNuovoIncaricoDirigenziale, setShowNuovoIncaricoDirigenziale] =
    useState<boolean>(false);
  const [showElencoAssunzioni, setShowElencoAssunzioni] = useState<boolean>(false);
  const [showNuovoAssunzioni, setShowNuovoAssunzioni] = useState<boolean>(false);
  const [showElencoDocumentiAssunzioni, setShowElencoDocumentiAssunzioni] =
    useState<boolean>(false);
  const [showScrivaniaVirtuale, setShowScrivaniaVirtuale] = useState<boolean>(false);
  const [showElencoRichiesteAbilitazione, setShowElencoRichiesteAbilitazione] =
    useState<boolean>(false);
  const [showInserisciRichiestaAbilitazione, setShowInserisciRichiestaAbilitazione] =
    useState<boolean>(false);
  const [showConsulenteEsterno, setConsulenteEsterno] = useState<boolean>(false);
  const [showConsulenteEsternoInsert, setConsulenteEsternoInsert] = useState<boolean>(false);
  const [isCandidato, setIsCandidato] = useState<boolean>(false);
  const [isCittadino, setIsCittadino] = useState<boolean>(false);
  const [isCessato, setIsCessato] = useState<boolean>(false);
  const [isValidato, setIsValidato] = useState<boolean>(false);
  const [isCVPubblicato, setIsCVPubblicato] = useState<boolean>(false);

  const [showConvenzioni, setShowConvenzioni] = useState<boolean>(false);
  const [solaLetturaConvenzioni, setSolaLetturaConvenzioni] = useState<boolean>(false);
  const [showRegistroImprese, setShowRegistroImprese] = useState<boolean>(false);
  const { currentCollapse, currentPage } = useSelector((state: any) => {
    return state.sidemenu;
  });
  const { pathname } = useLocation();

  let _currentCollapse = currentCollapse;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let toggleMenuItem = (collapseName: string) => {
    if (collapseName !== _currentCollapse) {
      dispatch(setCurrentCollapse(collapseName));
    } else {
      dispatch(setCurrentCollapse(''));
    }
  };

  const getContextPath = (realPathName: string) => {
    let contextPath = realPathName.substring(
      0,
      realPathName.indexOf('/', 1) !== -1 ? realPathName.indexOf('/', 1) : realPathName.length
    );
    return contextPath;
  };

  let goto = (href: string) => {
    dispatch(setCurrentPage(getContextPath(href)));
    navigate(href);
  };

  const { notificheNonLette } = useAppSelector((state) => state.notifiche);
  const { userRoleActive, userInfoResult } = useAppSelector((state) => state.user);

  const checkConvenzioni = () => {
    (userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_RICERCA') ||
      userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_DETTAGLIO') ||
      processAuth) &&
      setShowConvenzioni(true);
    setSolaLetturaConvenzioni(
      !(
        userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_PATCH') ||
        userRoleActive?.elencoFunzionalita?.includes('CONVENZIONI_INSERT') ||
        processAuth
      )
    );
  };

  const checkRegistroImprese = () => {
    (userRoleActive?.elencoFunzionalita?.includes('REGISTRO_IMPRESE_RICERCA_SINGOLA') ||
      userRoleActive?.elencoFunzionalita?.includes('REGISTRO_IMPRESE_RICERCA_MULTIPLA') ||
      processAuth) &&
      setShowRegistroImprese(true);
  };

  const checkScrivaniaVirtuale = () => {
    (userRoleActive?.elencoFunzionalita?.includes('NOTIFICHE_ELENCO') || processAuth) &&
      setShowScrivaniaVirtuale(true);
  };

  const checkOrganigramma = () => {
    (userRoleActive?.elencoFunzionalita?.includes('ORGANIGRAMMA_ELENCO') || processAuth) &&
      setShowOrganigrammaElenco(true);
  };

  const checkStruttureOrganizzative = () => {
    (userRoleActive?.elencoFunzionalita?.includes('SO_ELENCO') || processAuth) &&
      setShowElencoSO(true);
    (userRoleActive?.elencoFunzionalita?.includes('SO_INS_DIPA') ||
      userRoleActive?.elencoFunzionalita?.includes('SO_INS_SEZ') ||
      userRoleActive?.elencoFunzionalita?.includes('SO_INS_SERV') ||
      processAuth) &&
      setShowNuovaSO(true);
  };

  const checkPosizioniOrganizzative = () => {
    (userRoleActive?.elencoFunzionalita?.includes('PO_ELENCO') ||
      userRoleActive?.elencoFunzionalita?.includes('PO_ELENCO_COMPLETO') ||
      processAuth) &&
      setShowElencoPO(true);
    (userRoleActive?.elencoFunzionalita?.includes('PO_INSERIMENTO') || processAuth) &&
      setShowNuovaPO(true);
  };

  const checkRuoliFunzionalita = () => {
    (userRoleActive?.elencoFunzionalita?.includes('RUOLO_ELENCO') || processAuth) &&
      setShowElencoRuolo(true);
    (userRoleActive?.elencoFunzionalita?.includes('RUOLO_INSERT') || processAuth) &&
      setShowNuovoRuolo(true);
    (userRoleActive?.elencoFunzionalita?.includes('CONFIGNOTIFICA_ELENCO') || processAuth) &&
      setShowElencoConfigNotifica(true);
    (userRoleActive?.elencoFunzionalita?.includes('CONFIGNOTIFICA_INSERT') || processAuth) &&
      setShowNuovoConfigNotifica(true);
  };

  const checkDipendentiRegionali = () => {
    (userRoleActive?.elencoFunzionalita?.includes('DIPENDENTE_ELENCO') || processAuth) &&
      setShowElencoDipendentiRegionali(true);
  };

  const checkIncarichiDirigenziali = () => {
    (userRoleActive?.elencoFunzionalita?.includes('INCDIRIGENZIALE_ELENCO') || processAuth) &&
      setShowElencoIncarichiDirigenziali(true);
    (userRoleActive?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_DIP') ||
      userRoleActive?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_SEZ') ||
      userRoleActive?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_SER') ||
      userRoleActive?.elencoFunzionalita?.includes('INCDIRIGENZIALE_SELECT_SO_APPARTENENZA') ||
      processAuth) &&
      setShowNuovoIncaricoDirigenziale(true);
  };

  const checkNuoveAssunzioni = () => {
    (userRoleActive?.elencoFunzionalita?.includes('ASSUNZIONI_ELENCO') || processAuth) &&
      setShowElencoAssunzioni(true);
    (userRoleActive?.elencoFunzionalita?.includes('ASSUNZIONI_INSERT') || processAuth) &&
      setShowNuovoAssunzioni(true);
    (userRoleActive?.elencoFunzionalita?.includes('ASSUNZIONI_ELENCO_DOCUMENTI') || processAuth) &&
      setShowElencoDocumentiAssunzioni(true);
  };

  const checkConsulenteEsterno = () => {
    (userRoleActive?.elencoFunzionalita?.includes('CONSULENTEESTERNO_ELENCO') || processAuth) &&
      setConsulenteEsterno(true);
  };

  const checkConsulenteEsternoInsert = () => {
    (userRoleActive?.elencoFunzionalita?.includes('CONSULENTEESTERNO_INSERT') || processAuth) &&
      setConsulenteEsternoInsert(true);
  };

  const checkRichiestaAbilitazioni = () => {
    (userRoleActive?.elencoFunzionalita?.includes('RICHIESTEFUNZAPP_ELENCO') || processAuth) &&
      setShowElencoRichiesteAbilitazione(true);
    (userRoleActive?.elencoFunzionalita?.includes('RICHIESTEFUNZAPP_INSERT') || processAuth) &&
      setShowInserisciRichiestaAbilitazione(true);
  };

  const showStrutturaOrganizzativaItem = () => {
    if (showElencoSO || showNuovaSO) {
      return true;
    }
  };

  const showUploadMassivo = () =>
    userRoleActive?.elencoFunzionalita?.includes('UPLOAD_MASSIVO_BANDI_EQ_PREVIEW') || processAuth;

  const showPosizioniOrganizzativaItem = () =>
    showElencoEq() ||
    showNuovaEq() ||
    showElencoBandi() ||
    showElencoBandiPerCandidatura() ||
    showElencoNullaOsta() ||
    showCreazioneBando() ||
    showRichiestaNullaOsta() ||
    showUploadMassivo();

  const showOrganigrammaItem = () => {
    if (showOrganigrammaElenco) {
      return true;
    }
  };

  const showRuoliFunzionalitaItem = () => {
    if (showElencoRuolo || showNuovoRuolo || showElencoConfigNotifica || showNuovoConfigNotifica) {
      return true;
    }
  };

  const showDipendentiRegionaiItem = () => {
    if (showElencoDipendentiRegionali) {
      return true;
    }
  };

  const showIncarichiDirigenzialiItem = () => {
    if (showElencoIncarichiDirigenziali || showNuovoIncaricoDirigenziale) {
      return true;
    }
  };

  const showAssunzioniItem = () => {
    if (showElencoAssunzioni || showNuovoAssunzioni || showElencoDocumentiAssunzioni) {
      return true;
    }
  };

  const showScrivaniaVirtualeItem = () => {
    if (showScrivaniaVirtuale) {
      return true;
    }
  };

  const showRubricaItem = () =>
    userRoleActive?.elencoFunzionalita?.includes('RUBRICA_ELENCO_DIPENDENTI') || processAuth;

  const showConsulenteEsternoItem = () => {
    if (showConsulenteEsterno) {
      return true;
    }
  };

  const showConsulenteEsternoInsertItem = () => {
    if (showConsulenteEsternoInsert) {
      return true;
    }
  };

  const showFunzionalitaApplicativeItem = () =>
    showElencoRichiesteAbilitazione || showInserisciRichiestaAbilitazione;

  const showElencoEq = () =>
    userRoleActive?.elencoFunzionalita?.includes('PO_ELENCO') ||
    userRoleActive?.elencoFunzionalita?.includes('PO_ELENCO_COMPLETO') ||
    processAuth;

  const showNuovaEq = () =>
    userRoleActive?.elencoFunzionalita?.includes('PO_INSERIMENTO') || processAuth;

  const showElencoBandi = () =>
    userRoleActive?.elencoFunzionalita?.includes('BANDO_PO_ELENCO') ||
    userRoleActive?.elencoFunzionalita?.includes('BANDO_PO_ACCESSO_COMPLETO') ||
    processAuth;

  const showElencoBandiPerCandidatura = () =>
    (userRoleActive?.elencoFunzionalita?.includes('BANDO_PO_ELENCO_ASSEGNAZIONI') &&
      userRoleActive?.elencoFunzionalita?.includes('BANDO_PO_CANDIDATURA') &&
      userInfoResult?.partecipazioneBandi) ||
    processAuth;

  const showElencoNullaOsta = () =>
    // nascosta come da issue: https://tools.linksmt.it/jira/browse/DXCAUF2-21
    (userRoleActive?.elencoFunzionalita?.includes('NULLA_OSTA_ELENCO') || processAuth) && false;

  const showCreazioneBando = () =>
    userRoleActive?.elencoFunzionalita?.includes('BANDO_PO_INSERT') ||
    userRoleActive?.elencoFunzionalita?.includes('BANDO_PO_ACCESSO_COMPLETO') ||
    processAuth;

  const showRichiestaNullaOsta = () =>
    // nascosta come da issue: https://tools.linksmt.it/jira/browse/DXCAUF2-21
    (userRoleActive?.elencoFunzionalita?.includes('NULLA_OSTA_INSERIMENTO') || processAuth) &&
    false;

  const showCV = () =>
    userRoleActive?.elencoFunzionalita?.includes('CV_INSERIMENTO') ||
    userRoleActive?.elencoFunzionalita?.includes('CV_DETTAGLIO');

  useEffect(() => {
    if (
      (userRoleActive?.elencoFunzionalita && userRoleActive?.elencoFunzionalita.length > 0) ||
      processAuth
    ) {
      checkScrivaniaVirtuale();
      checkOrganigramma();
      checkStruttureOrganizzative();
      checkPosizioniOrganizzative();
      checkRuoliFunzionalita();
      checkDipendentiRegionali();
      checkIncarichiDirigenziali();
      checkNuoveAssunzioni();
      checkConsulenteEsterno();
      checkConsulenteEsternoInsert();
      checkRichiestaAbilitazioni();
      checkConvenzioni();
      checkRegistroImprese();
    }
  }, [userRoleActive]);

  useEffect(() => {
    if (userInfoResult) {
      userInfoResult.candidato && setIsCandidato(true);
      userInfoResult.cittadino && setIsCittadino(true);
      userInfoResult.dipendenteCessato && setIsCessato(true);
      userInfoResult.candidato &&
        dispatch(detailsCandidato(userInfoResult.entityId!))
          .unwrap()
          .then((response) => {
            response.validato && setIsValidato(true);
          });
      userInfoResult.isCvCreato && setIsCVPubblicato(true);
    }
  }, [userInfoResult]);

  /*     useEffect(() => {
        let contextPath = getContextPath(pathname.replace(process.env.PUBLIC_URL, ""));

        let item = mappaCollapse.find(collapse => {
            if(collapse.items.some(el => el === contextPath))
                return true;
            return false;
        });
        if(item)
            dispatch(setCurrentCollapse(item.collapse))
      }, [pathname]);

    useEffect(() => {
        dispatch(setCurrentPage(getContextPath(pathname.replace(process.env.PUBLIC_URL, ""))));
    }, []) */

  return (
    <Sidebar right>
      <LinkList>
        <LinkListItem header>Menu Principale</LinkListItem>

        {(showScrivaniaVirtualeItem() || isCandidato || isCittadino || isCessato) && (
          <LinkListItem
            size="large"
            active={currentPage === HREF_SCRIVANIA_VIRTUALE}
            onClick={() => goto(HREF_SCRIVANIA_VIRTUALE)}
          >
            <span>Scrivania virtuale</span>
            <Badge color="primary" className="text-white mr-0" pill>
              {notificheNonLette}
            </Badge>
          </LinkListItem>
        )}

        {showOrganigrammaItem() && (
          <LinkListItem
            size="large"
            active={currentPage === HREF_ORGANIGRAMMA}
            onClick={() => goto(HREF_ORGANIGRAMMA)}
          >
            <span>Organigramma</span>
          </LinkListItem>
        )}

        {showStrutturaOrganizzativaItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_SO)}
            >
              <span>Gestione strutture organizzative</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_SO}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_SO}>
              <LinkList sublist>
                {showElencoSO && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}
                    onClick={() => goto(HREF_ELENCO_STRUTTURE_ORGANIZZATIVE)}
                  >
                    <span>Elenco strutture organizzative </span>
                  </LinkListItem>
                )}
                {showNuovaSO && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVA_STRUTTURA_ORGANIZZATIVA}
                    onClick={() => goto(HREF_NUOVA_STRUTTURA_ORGANIZZATIVA)}
                  >
                    <span>Nuova struttura organizzativa </span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {showPosizioniOrganizzativaItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_PO)}
            >
              <span>Gestione incarichi di Elevata Qualificazione</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_PO}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_PO}>
              <LinkList sublist>
                {showElencoPO && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}
                    onClick={() => goto(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE)}
                  >
                    <span>Elenco Elevate Qualificazioni</span>
                  </LinkListItem>
                )}
                {showNuovaPO && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVA_POSIZIONE_ORGANIZZATIVA}
                    onClick={() => goto(HREF_NUOVA_POSIZIONE_ORGANIZZATIVA)}
                  >
                    <span>Nuova Elevata Qualificazione</span>
                  </LinkListItem>
                )}
                {showElencoBandi() && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_BANDI}
                    onClick={() => goto(HREF_ELENCO_BANDI)}
                  >
                    <span>Elenco bandi</span>
                  </LinkListItem>
                )}
                {showCreazioneBando() && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVO_BANDO}
                    onClick={() => goto(HREF_NUOVO_BANDO)}
                  >
                    <span>Nuovo bando</span>
                  </LinkListItem>
                )}
                {showRichiestaNullaOsta() && (
                  <LinkListItem
                    active={currentPage === HREF_CANDIDATURA_NULLA_OSTA}
                    onClick={() => goto(HREF_CANDIDATURA_NULLA_OSTA)}
                  >
                    <span>Richiesta nulla osta</span>
                  </LinkListItem>
                )}
                {showElencoNullaOsta() && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_RICHIESTE_NULLA_OSTA}
                    onClick={() => goto(HREF_ELENCO_RICHIESTE_NULLA_OSTA)}
                  >
                    <span>Elenco richieste nulla osta</span>
                  </LinkListItem>
                )}
                {/* {showElencoAssegnazioniPO() && <LinkListItem
                            active={currentPage === HREF_ELENCO_ASSEGNAZIONI}
                            onClick={() => goto(HREF_ELENCO_ASSEGNAZIONI)}>
                            <span>Elenco assegnazioni EQ</span>
                        </LinkListItem>} */}
                {showElencoBandiPerCandidatura() && (
                  <LinkListItem
                    active={currentPage === HREF_CANDIDATURA_ELENCO_BANDI}
                    onClick={() => goto(HREF_CANDIDATURA_ELENCO_BANDI)}
                  >
                    <span>Elenco EQ</span>
                  </LinkListItem>
                )}
                {showUploadMassivo() && (
                  <LinkListItem
                    active={currentPage === HREF_UPLOAD_GESTIONE_MASSIVA}
                    onClick={() => goto(HREF_UPLOAD_GESTIONE_MASSIVA)}
                  >
                    <span>Gestione Massiva Incarichi EQ e Bandi EQ</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {/* Gestione ruoli e funzionalita */}
        {showRuoliFunzionalitaItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_RUOLI_FUNZIONALITA)}
            >
              <span>Gestione ruoli e funzionalità</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_RUOLI_FUNZIONALITA}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_RUOLI_FUNZIONALITA}>
              <LinkList sublist>
                {showElencoRuolo && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_RUOLI}
                    onClick={() => goto(HREF_ELENCO_RUOLI)}
                  >
                    <span>Elenco ruoli </span>
                  </LinkListItem>
                )}
                {showNuovoRuolo && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVO_RUOLO}
                    onClick={() => goto(HREF_NUOVO_RUOLO)}
                  >
                    <span>Nuovo ruolo </span>
                  </LinkListItem>
                )}
                {showElencoConfigNotifica && (
                  <LinkListItem
                    active={currentPage === HREF_GESTIONE_NOTIFICHE}
                    onClick={() => goto(HREF_GESTIONE_NOTIFICHE)}
                  >
                    <span>Gestione notifiche </span>
                  </LinkListItem>
                )}
                {showNuovoConfigNotifica && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVA_CONFIGURAZIONE_NOTIFICA}
                    onClick={() => goto(HREF_NUOVA_CONFIGURAZIONE_NOTIFICA)}
                  >
                    <span>Nuova notifica </span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {/* Gestione candidati */}
        {showAssunzioniItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_CANDIDATI)}
            >
              <span>Gestione candidati</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_CANDIDATI}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_CANDIDATI}>
              <LinkList sublist>
                {showElencoAssunzioni && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_CANDIDATI}
                    onClick={() => goto(HREF_ELENCO_CANDIDATI)}
                  >
                    <span>Elenco candidati </span>
                  </LinkListItem>
                )}
                {showNuovoAssunzioni && (
                  <LinkListItem
                    active={currentPage === HREF_INSERIMENTO_CANDIDATO}
                    onClick={() => goto(HREF_INSERIMENTO_CANDIDATO)}
                  >
                    <span>Nuovo candidato </span>
                  </LinkListItem>
                )}
                {showNuovoAssunzioni && (
                  <LinkListItem
                    active={currentPage === HREF_INSERIMENTO_MASSIVO_CANDIDATI}
                    onClick={() => goto(HREF_INSERIMENTO_MASSIVO_CANDIDATI)}
                  >
                    <span>Inserimento massivo candidati</span>
                  </LinkListItem>
                )}
                {/*  <LinkListItem
                  active={currentPage === HREF_CONF_AREA_DOCUMENTI}
                  onClick={() => goto(HREF_CONF_AREA_DOCUMENTI)}
                >
                  <span>Area documenti</span>
                </LinkListItem> */}
              </LinkList>
            </Collapse>
          </>
        )}

        {showDipendentiRegionaiItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_DIPENDENTI)}
            >
              <span>Gestione dipendenti</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_DIPENDENTI}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_DIPENDENTI}>
              <LinkList sublist>
                {showElencoDipendentiRegionali && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_DIPENDENTI_REG}
                    onClick={() => goto(HREF_ELENCO_DIPENDENTI_REG)}
                  >
                    <span>Elenco dipendenti</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {showIncarichiDirigenzialiItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_INCARICHI_DIRIGENZIALI)}
            >
              <span>Gestione incarichi dirigenziali</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_INCARICHI_DIRIGENZIALI}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_INCARICHI_DIRIGENZIALI}>
              <LinkList sublist>
                {showElencoIncarichiDirigenziali && (
                  <LinkListItem
                    active={currentPage === HREF_ELENCO_INCARICHI_DIRIGENZIALI}
                    onClick={() => goto(HREF_ELENCO_INCARICHI_DIRIGENZIALI)}
                  >
                    <span>Elenco proposte incarichi dirigenziali</span>
                  </LinkListItem>
                )}
                {showNuovoIncaricoDirigenziale && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVA_PROPOSTA_INCARICO}
                    onClick={() => goto(HREF_NUOVA_PROPOSTA_INCARICO)}
                  >
                    <span>Nuova proposta incarico</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {(isCittadino || isCandidato || isCessato) && (
          <>
            <LinkListItem
              active={currentPage === HREF_SH_AREA_PERSONALE}
              onClick={() => goto(`${HREF_SH_AREA_PERSONALE}`)}
            >
              <span>Area personale</span>
            </LinkListItem>
            <LinkListItem
              active={currentPage === HREF_SH_DOCUMENTI}
              onClick={() => goto(`${HREF_SH_DOCUMENTI}`)}
            >
              <span>Documenti</span>
            </LinkListItem>
            <LinkListItem
              active={currentPage === HREF_SH_PRATICHE}
              onClick={() => goto(`${HREF_SH_PRATICHE}`)}
            >
              <span>Pratiche</span>
            </LinkListItem>
            <LinkListItem
              active={currentPage === HREF_SH_BANDI_CONCORSI}
              onClick={() => goto(`${HREF_SH_BANDI_CONCORSI}`)}
            >
              <span>Bandi e Concorsi</span>
            </LinkListItem>
          </>
        )}

        {isCandidato && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_CANDIDATI)}
            >
              <span>Gestione candidati</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_CANDIDATI}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_CANDIDATI}>
              <LinkList sublist>
                <LinkListItem
                  active={currentPage === HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO}
                  onClick={() => goto(`${HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO}`)}
                >
                  <span>Validazione/Integrazione dati candidato</span>
                </LinkListItem>
                {isValidato && (
                  <LinkListItem
                    active={currentPage === HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO}
                    onClick={() => goto(HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO)}
                  >
                    <span>Gestione convocazione sottoscrizione contratto</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {/* <LinkListItem size="large" className="right-icon"
                    onClick={() => toggleMenuItem(COLLAPSE_NAME_PAGINE_NASCOSTE)}>
                    <span>Pagine nascoste</span>
                    <Icon icon="it-expand" className="right" aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_CANDIDATI} />
                </LinkListItem>
                <Collapse isOpen={currentCollapse === COLLAPSE_NAME_PAGINE_NASCOSTE}>
                    <LinkList sublist>
                        <LinkListItem
                            active={currentPage === HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO}
                            onClick={() => goto(`${HREF_VALIDAZIONE_INTEGRAZIONE_CANDIDATO}/27`)}>
                            <span>Validazione-integrazione dati candidato</span>
                        </LinkListItem>
                        <LinkListItem
                            active={currentPage === HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO}
                            onClick={() => goto(HREF_GEST_CONVOCAZIONE_SOTTOSCRIZIONE_CONTRATTO + '/27')}>
                            <span>Gestione convocazione sottoscrizione contratto</span>
                        </LinkListItem>
                    </LinkList>
                </Collapse> */}

        {/* Rubrica */}
        {showRubricaItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_RUBRICA)}
            >
              <span>Elenco dipendenti</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_RUBRICA}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_RUBRICA}>
              <LinkList sublist>
                <LinkListItem
                  active={currentPage === HREF_RUBRICA_ELENCO_DIPENDENTI}
                  onClick={() => goto(HREF_RUBRICA_ELENCO_DIPENDENTI)}
                >
                  <span>Elenco dipendenti </span>
                </LinkListItem>
                {/* <LinkListItem
                            active={currentPage === HREF_RUBRICA_MAIL_SO_SERVIZIO}
                            onClick={() => goto(HREF_RUBRICA_MAIL_SO_SERVIZIO)}>
                            <span>Creazione Email SO/Servizio</span>
                        </LinkListItem> */}
              </LinkList>
            </Collapse>
          </>
        )}
        {/* Consulente Esterno */}
        {showConsulenteEsternoItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_CONSULENTE_ESTERNO)}
            >
              <span>Gestione consulenti esterni</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_CONSULENTE_ESTERNO}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_CONSULENTE_ESTERNO}>
              <LinkList sublist>
                <LinkListItem
                  active={currentPage === HREF_CONSULENTE_ESTERNO_ELENCO}
                  onClick={() => goto(HREF_CONSULENTE_ESTERNO_ELENCO)}
                >
                  <span>Elenco consulenti esterni </span>
                </LinkListItem>
                {showConsulenteEsternoInsertItem() && (
                  <LinkListItem
                    active={currentPage === HREF_CONSULENTE_ESTERNO_INSERISCI}
                    onClick={() => goto(HREF_CONSULENTE_ESTERNO_INSERISCI)}
                  >
                    <span>Inserisci consulente esterno</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}

        {/* Funzionalità applicative */}
        {showFunzionalitaApplicativeItem() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_ABILITAZIONI)}
            >
              <span>Gestione abilitazioni</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_ABILITAZIONI}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_ABILITAZIONI}>
              <LinkList sublist>
                {showElencoRichiesteAbilitazione && (
                  <LinkListItem
                    active={currentPage === HREF_ABILITAZIONI_ELENCO_RICHIESTE}
                    onClick={() => goto(HREF_ABILITAZIONI_ELENCO_RICHIESTE)}
                  >
                    <span>Elenco richieste</span>
                  </LinkListItem>
                )}
                {showInserisciRichiestaAbilitazione && (
                  <LinkListItem
                    active={currentPage === HREF_ABILITAZIONI_NUOVA_RICHIESTA}
                    onClick={() => goto(HREF_ABILITAZIONI_NUOVA_RICHIESTA)}
                  >
                    <span>Nuova richiesta abilitazione</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}
        {/* Gestione convenzioni */}
        {showConvenzioni && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLAPSE_NAME_GESTIONE_CONVENZIONI)}
            >
              <span>Gestione convenzioni</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLAPSE_NAME_GESTIONE_CONVENZIONI}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLAPSE_NAME_GESTIONE_CONVENZIONI}>
              <LinkList sublist>
                <LinkListItem
                  active={currentPage === HREF_ELENCO_CONVENZIONI}
                  onClick={() => goto(HREF_ELENCO_CONVENZIONI)}
                >
                  <span>Elenco convenzioni</span>
                </LinkListItem>
                {!solaLetturaConvenzioni && (
                  <LinkListItem
                    active={currentPage === HREF_NUOVA_CONVENZIONE}
                    onClick={() => goto(HREF_NUOVA_CONVENZIONE)}
                  >
                    <span>Nuova convenzione</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}
        {/* Registro imprese */}
        {showRegistroImprese && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLASPE_REGISTRO_IMPRESE)}
            >
              <span>Registro imprese</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLASPE_REGISTRO_IMPRESE}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLASPE_REGISTRO_IMPRESE}>
              <LinkList sublist>
                <LinkListItem
                  size="large"
                  active={currentPage === HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA}
                  onClick={() => goto(HREF_REGISTRO_IMPRESE_RICERCA_SINGOLA)}
                >
                  <span>Ricerca singola</span>
                </LinkListItem>
                <LinkListItem
                  size="large"
                  active={currentPage === HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA}
                  onClick={() => goto(HREF_REGISTRO_IMPRESE_RICERCA_MULTIPLA)}
                >
                  <span>Ricerca multipla</span>
                </LinkListItem>
              </LinkList>
            </Collapse>
          </>
        )}
        {/*Gestione cv*/}
        {showCV() && (
          <>
            <LinkListItem
              size="large"
              className="right-icon"
              onClick={() => toggleMenuItem(COLLASPE_NAME_GESTIONE_CV)}
            >
              <span>Gestione Curriculum Vitae</span>
              <Icon
                icon="it-expand"
                className="right"
                aria-expanded={currentCollapse === COLLASPE_NAME_GESTIONE_CV}
              />
            </LinkListItem>
            <Collapse isOpen={currentCollapse === COLLASPE_NAME_GESTIONE_CV}>
              <LinkList sublist>
                <LinkListItem
                  size="large"
                  active={currentPage === HREF_GESTIONE_CV}
                  onClick={() => goto(HREF_GESTIONE_CV)}
                >
                  <span>Compila CV</span>
                </LinkListItem>
                {isCVPubblicato && (
                  <LinkListItem
                    size="large"
                    active={currentPage === HREF_VISUALIZZA_CV}
                    onClick={() => goto(`${HREF_VISUALIZZA_CV}/${userInfoResult!.entityId}`)}
                  >
                    <span>Visualizza CV</span>
                  </LinkListItem>
                )}
              </LinkList>
            </Collapse>
          </>
        )}
      </LinkList>
    </Sidebar>
  );
}

export default Sidemenu;