import axios from 'axios';
import { MS_AU_CONFIG } from '../../store/store-constants';
import { hideLoader, showLoader, showNotifica } from '../../store/loaderSlice';
import { NOTIFICA_STATO } from '../../utility/Notifica';
import { deleteJwtToken, getLocalStorageKey } from '../../utility/cookie';

function waitForMe(millisec: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(''), millisec);
  });
}

function decode(array: ArrayBuffer) {
  const enc = new TextDecoder('utf-8');

  const decoded = JSON.parse(enc.decode(array));

  return decoded.parameters.map((x: { value: any }) => x.value).join('\n');
}

const jwtInterceptor = (dispatch: any) => {
  let method: string = '';
  let url: string = '';
  let body: any = null;

  // ABILITARE PER RITARDO FORZATO PER TESTARE LOADER
  // axios.interceptors.request.use(async request => {
  axios.interceptors.request.use((request) => {
    // console.log('REQUEST >>> ', request);

    dispatch(showLoader());
    const isApiUrl = request.url?.startsWith(MS_AU_CONFIG.basePath!);
    if (process.env.REACT_APP_MS_ACTIVATE_AUTH == 'true') {
      const jwtToken = getLocalStorageKey('au-user-token');
      if (isApiUrl && !!jwtToken) {
        request.headers.Authorization = `Bearer ${jwtToken}`;
        request.withCredentials = true;
      }
    }
    method = request.method || '';
    url = request.url || '';
    body = request.data;
    // ABILITARE PER RITARDO FORZATO PER TESTARE LOADER
    // if (request.method === 'post' && request.url === 'http://localhost:9000/au/v1/documenti') {
    //     await waitForMe(400000);
    // }
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      // console.log('RESPONSE >>> ', response);

      // if (!!response.request.responseURL && response.request.responseURL !== 'http://localhost:9000/au/auth/leggi-utente-loggato') {
      //     await waitForMe(4000);
      // }
      dispatch(hideLoader());

      if (
        method !== 'get' &&
        method === 'put' &&
        url === 'http://localhost:9000/au/v1/gestione-cv' &&
        body.sezione !== 'CONFERMA'
      ) {
        return response;
      }

      if (method !== 'get') {
        dispatch(
          showNotifica({
            titolo: 'OPERAZIONE COMPLETATA',
            stato: response.status,
            messaggio: 'Operazione richiesta completata con successo!',
            tipo: NOTIFICA_STATO.success,
          })
        );
        return response;
      }

      return response;
    },
    function (error) {
      dispatch(hideLoader());
      switch (true) {
        case error.response.status === 400:
          dispatch(
            showNotifica({
              titolo: 'DATI NON CORRETTI',
              stato: error.response.status,
              messaggio:
                error.response.data instanceof ArrayBuffer
                  ? decode(error.response.data)
                  : error.response.data.parameters[0].value,
              tipo: NOTIFICA_STATO.error,
            })
          );
          break;
        case error.response.status === 401:
          deleteJwtToken();
          window.location.href = process.env.REACT_APP_PUBLIC_SESSIONE_SCADUTA || '';
          break;
        case error.response.status === 403:
          dispatch(
            showNotifica({
              titolo: 'OPERAZIONE NON CONSENTITA',
              stato: error.response.status,
              messaggio:
                error.response.data instanceof ArrayBuffer
                  ? 'ACCESSO NEGATO'
                  : error.response.data.parameters[0].value || ' - - ',
              tipo: NOTIFICA_STATO.error,
            })
          );
          break;
        default:
          console.error(error);
          dispatch(
            showNotifica({
              titolo: 'ERRORE GENERICO',
              stato: error.response.status,
              messaggio:
                error.response.data.parameters[0].value ||
                'Si è verificato un errore durante la richiesta.',
              tipo: NOTIFICA_STATO.error,
            })
          );
          break;
      }
      return error;
    }
  );
};

export default jwtInterceptor;
