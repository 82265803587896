import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  FormsControllerApi,
  GestioneDocumentaleControllerApi,
  StrutturaOrganizzativaControllerApi,
} from '../services/ms-anagrafica-unica';
import {
  AssegnazioneIncaricoDto,
  ElencoStruttureOrganizzativeContestoForm,
  NuovaStrutturaOrganizzativaContestoForm,
  OptionDtoString,
  PagedDtoStrutturaOrganizzativaDto,
  PatchStrutturaOrganizzativaRequest,
  SOAssociaRequestDto,
  SOCensisciRequestDto,
  SOCodiceBilancioRequestDto,
  SOCodiceCifraRequestDto,
  SOCodiceIpaRequestDto,
  SODeactiveRequestDto,
  SODisassociaRequestDto,
  SOInsertRequestDto,
  SOUpdateRequestDto,
  SOUtenzaCifraRequestDto,
  StrutturaOrganizzativaDto,
  UtenzaCifraDto,
} from '../services/ms-anagrafica-unica/model';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './store-constants';
import { AxiosError } from 'axios';

export interface StrutturaOrganizzativaState {
  searchResult: PagedDtoStrutturaOrganizzativaDto;
  searchStatus: string;
  searchInput: SearchSORequest | null;
  formSearchComponents: ElencoStruttureOrganizzativeContestoForm | null;
  formCreate: NuovaStrutturaOrganizzativaContestoForm | null;
  inputStatus: string;
  inputResult: StrutturaOrganizzativaDto | null;
  detailsResult: StrutturaOrganizzativaDto | null;
  detailsStatus: string;
  updateResult: StrutturaOrganizzativaDto | null;
  updateStatus: string;
  selectCreateFormResult: NuovaStrutturaOrganizzativaContestoForm | null;
  selectCreateFormStatus: string;
  utenzaCifraResult: UtenzaCifraDto | null;
  utenzaCifraStatus: string;
  utenzaCifraListResult: Array<UtenzaCifraDto> | null;
  utenzaCifraListStatus: string;
  assegnazioneSoDipendenteResult: AssegnazioneIncaricoDto | null;
  assegnazioneSoDipendenteStatus: string;
  sezioniCreaStrutturaOrganizzativaResult: Array<OptionDtoString> | null;
  sezioniCreaStrutturaOrganizzativaStatus: string;
}

const initialState: StrutturaOrganizzativaState = {
  searchResult: {
    pageNum: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    data: [],
  },
  searchStatus: '',
  searchInput: null,
  formSearchComponents: null,
  formCreate: null,
  inputStatus: '',
  inputResult: null,
  detailsResult: null,
  detailsStatus: '',
  updateResult: null,
  updateStatus: '',
  selectCreateFormResult: null,
  selectCreateFormStatus: '',
  utenzaCifraResult: null,
  utenzaCifraStatus: '',
  utenzaCifraListResult: null,
  utenzaCifraListStatus: '',
  assegnazioneSoDipendenteResult: null,
  assegnazioneSoDipendenteStatus: '',
  sezioniCreaStrutturaOrganizzativaResult: null,
  sezioniCreaStrutturaOrganizzativaStatus: '',
};

export interface SearchSORequest {
  pageSize?: number;
  pageNum?: number;
  codInca?: string;
  denominazione?: string;
  comune?: string;
  tipo?: any;
  sort: any;
  direction: any;
}

export interface patchValueUpdate {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOUpdateRequestDto;
}

export interface patchCodiceCifra {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOCodiceCifraRequestDto;
}

export interface patchCodiceIpa {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOCodiceIpaRequestDto;
}

export interface patchCodiceBilancio {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOCodiceBilancioRequestDto;
}

export interface utenzaCifraRequest {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOUtenzaCifraRequestDto | undefined;
}

export interface patchDeactivateStruttura {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SODeactiveRequestDto;
}

export interface patchAssegnaSoDipente {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOAssociaRequestDto;
}

export interface patchDisassociaSoDipente {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SODisassociaRequestDto;
}

export interface associazioneSoDipentente {
  idDipendente: number;
  idIncarico: string;
}

export interface patchInoltraSo {
  idStrutturaOrganizzativa: string | undefined;
  bodyRequest: SOCensisciRequestDto;
}

export const searchStruttureOrganizzative = createAsyncThunk(
  'struttureOrganizzative/search',
  async (args: SearchSORequest) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.searchStrutturaOrganizzativa({
      pageNum: args.pageNum,
      pageSize: args.pageSize,
      codInca: args.codInca,
      denominazione: args.denominazione,
      tipologiaStruttura: args.tipo,
      comune: args.comune,
      direction: args.direction,
      sort: args.sort,
    });
    return response.data;
  }
);

export const loadSOFormSearch = createAsyncThunk(
  'struttureOrganizzative/loadFormSearch',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getElencoStruttureOrganizzativeContestoForm();
    return response.data;
  }
);

export const insertStruttureOrganizzative = createAsyncThunk(
  'struttureOrganizzative/insert',
  async (insert: SOInsertRequestDto) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.insertStrutturaOrganizzativa({
      sOInsertRequestDto: {
        ...insert,
      },
    });
    return response.data;
  }
);

export const insertAndInoltraStrutturaOrganizzativa = createAsyncThunk(
  'struttureOrganizzative/insert/inoltra',
  async (insert: SOInsertRequestDto) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.insertAndInoltraStrutturaOrganizzativa({
      sOInsertRequestDto: {
        ...insert,
      },
    });
    return response.data;
  }
);

export const updateSoBozza = createAsyncThunk(
  'struttureOrganizzative/updateBozza',
  async (insert: SOInsertRequestDto) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: insert.codiceInca!,
      patchStrutturaOrganizzativaRequest: {
        ...insert,
        requestType: 'UpdateBozzaEntity',
      },
    });
    return response.data;
  }
);

export const inoltraStruttureOrganizzative = createAsyncThunk(
  'struttureOrganizzative/inoltra',
  async (body: patchInoltraSo) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response.data;
  }
);

export const deleteStruttureOrganizzative = createAsyncThunk(
  'struttureOrganizzative/inoltra',
  async (idStrutturaOrganizzativa: string) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.deleteStrutturaOrganizzativa({
      idStrutturaOrganizzativa,
    });
    return response.data;
  }
);

export const detailsStruttureOrganizzative = createAsyncThunk(
  'struttureOrganizzative/details',
  async (idStruttura: string) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.detailStrutturaOrganizzativa({
      idStrutturaOrganizzativa: idStruttura,
    });
    return response.data;
  }
);

export const updateStruttureOrganizzative = createAsyncThunk(
  'struttureOrganizzative/update',
  async (body: patchValueUpdate) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response.data;
  }
);

export const assegnaCodiceCIFRA = createAsyncThunk(
  'struttureOrganizzative/codice_CIFRA',
  async (body: patchCodiceCifra) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response.data;
  }
);

export const assegnaCodiceIPA = createAsyncThunk(
  'struttureOrganizzative/codice_IPA',
  async (body: patchCodiceIpa) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response.data;
  }
);

export const assegnaCodiceBilancio = createAsyncThunk(
  'struttureOrganizzative/codice_Bilancio',
  async (body: patchCodiceBilancio) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response.data;
  }
);

export const formSelectPopulateCreate = createAsyncThunk(
  'struttureOrganizzative/formSelectPopulate',
  async () => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getNuovaStrutturaOrganizzativaContestoForm();
    return response.data;
  }
);

export const assegnazioneUtenzeCifra = createAsyncThunk(
  'gestioneUtenzeCifra/assegnaUtenzeCifra',
  async (body: utenzaCifraRequest) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    console.log(body);
    const response = await api.saveUtenzaCifra({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      sOUtenzaCifraRequestDto: {
        ...body.bodyRequest,
      },
    });
    return response.data;
  }
);

export const getUtenzeCifraByStruttura = createAsyncThunk(
  'gestioneUtenzeCifra/getUtenzeCifra',
  async (id: string) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.getAllByStruttura({ idStrutturaOrganizzativa: id });
    return response.data;
  }
);

export const deleteUtenzeCifra = createAsyncThunk(
  'gestioneUtenzeCifra/deleteUtenzeCifra',
  async (id: number) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.deleteUenteCifra({ idUtenza: id });
    return response.data;
  }
);

export const disattivazioneStruttura = createAsyncThunk(
  'struttureOrganizzative/disattivazione',
  async (body: patchDeactivateStruttura, { rejectWithValue }) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response instanceof AxiosError ? rejectWithValue(response.statusText) : response.data;
  }
);

/*export const associaSOFormPopulate = createAsyncThunk(
    'struttureOrganizzative/associaSOForm',
    async (idStrutturaOperativa:string) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getStruttureOrganizzativeContestoForm({
            idIncarico:idStrutturaOperativa
        });
        return response.data;
    }
)*/

export const associazioneStrutturaDipendente = createAsyncThunk(
  'strutturaOrganizzativa/associaStrutturaOrganizzativa',
  async (body: patchAssegnaSoDipente, { rejectWithValue }) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response instanceof AxiosError ? rejectWithValue(response.statusText) : response.data;
  }
);

export const disassociaStrutturaDipendente = createAsyncThunk(
  'strutturaOrganizzativa/disassociaStrutturaOrganizzativa',
  async (body: patchDisassociaSoDipente, { rejectWithValue }) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.patchStrutturaOrganizzativa({
      idStrutturaOrganizzativa: body.idStrutturaOrganizzativa!,
      patchStrutturaOrganizzativaRequest: body.bodyRequest,
    });
    return response instanceof AxiosError ? rejectWithValue(response.statusText) : response.data;
  }
);

/*export const disassociaSOListaDipendenti = createAsyncThunk(
    'strutturaOrganizzativa/disassociaSOListDipendenti',
    async(idStrutturaOrganizzativa: string) =>{
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getDisassociaStruttureOrganizzativeContestoFormSuBaseDipendente({
            idIncarico: idStrutturaOrganizzativa
        })
        return response.data
    }
)*/

export const detailsAssociazioneSoDipendente = createAsyncThunk(
  'strutturaOrganizzativa/detailsAssociazioneSoDipendente',
  async (body: associazioneSoDipentente) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.getAssociazioneIncarico({
      idDipendente: body.idDipendente,
      idIncarico: body.idIncarico,
    });
    return response.data;
  }
);

export const selectCreateSOSezioni = createAsyncThunk(
  'strutturaOrganizzativa/selectCreateSOSezioni',
  async (id: string) => {
    const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
    const response = await api.getSezioniByDipartimento({
      idDipartimento: id,
    });
    return response.data;
  }
);

export const strutturaOrganizzativaSlice = createSlice({
  name: 'strutturaOrganizzativa',
  initialState,
  reducers: {
    resetInputForm: (state) => {
      state.inputStatus = '';
    },
    resetUpdateForm: (state) => {
      state.updateResult = null;
      state.updateStatus = '';
      state.detailsResult = null;
      state.detailsStatus = '';
    },
    resetDetailsAssocazioneSoDipendente: (state) => {
      state.assegnazioneSoDipendenteResult = null;
    },
    resetInsertResult: (state) => {
      state.inputResult = null;
    },
    resetSelectSezioni: (state) => {
      state.sezioniCreaStrutturaOrganizzativaResult = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      searchStruttureOrganizzative.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.searchStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      searchStruttureOrganizzative.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.searchResult = action.payload;
        state.searchInput = action.meta.arg;
        state.searchStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      searchStruttureOrganizzative.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.searchStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(
      loadSOFormSearch.pending,
      (state: StrutturaOrganizzativaState, action: any) => {}
    );
    builder.addCase(
      loadSOFormSearch.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.formSearchComponents = action.payload;
      }
    );
    builder.addCase(
      loadSOFormSearch.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {}
    );

    builder.addCase(
      insertStruttureOrganizzative.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.inputStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      insertStruttureOrganizzative.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.inputResult = action.payload;
        state.inputStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      insertStruttureOrganizzative.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.inputStatus = STATUS_REJECTED;
      }
    );

    builder.addCase(insertAndInoltraStrutturaOrganizzativa.pending, (state: StrutturaOrganizzativaState, action: any) => {
        state.inputStatus = STATUS_PENDING
    })
    builder.addCase(insertAndInoltraStrutturaOrganizzativa.fulfilled, (state: StrutturaOrganizzativaState, action: any) => {
        state.inputResult = action.payload
        state.inputStatus = STATUS_FULLFILLED
    })
    builder.addCase(insertAndInoltraStrutturaOrganizzativa.rejected, (state: StrutturaOrganizzativaState, action: any) => {
        state.inputStatus = STATUS_REJECTED
    })

    builder.addCase(updateSoBozza.pending, (state: StrutturaOrganizzativaState, action: any) => {
      state.inputStatus = STATUS_PENDING;
    });
    builder.addCase(updateSoBozza.fulfilled, (state: StrutturaOrganizzativaState, action: any) => {
      state.inputResult = action.payload;
      state.inputStatus = STATUS_FULLFILLED;
    });
    builder.addCase(updateSoBozza.rejected, (state: StrutturaOrganizzativaState, action: any) => {
      state.inputStatus = STATUS_REJECTED;
    });

    builder.addCase(
      detailsStruttureOrganizzative.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.detailsStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      detailsStruttureOrganizzative.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.detailsResult = action.payload;
        state.detailsStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      detailsStruttureOrganizzative.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.detailsStatus = STATUS_REJECTED;
      }
    );

    //update Struttura organizzativa
    builder.addCase(
      updateStruttureOrganizzative.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      updateStruttureOrganizzative.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      updateStruttureOrganizzative.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //add codice cifra
    builder.addCase(
      assegnaCodiceCIFRA.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      assegnaCodiceCIFRA.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      assegnaCodiceCIFRA.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //add codice ipa
    builder.addCase(assegnaCodiceIPA.pending, (state: StrutturaOrganizzativaState, action: any) => {
      state.updateStatus = STATUS_PENDING;
    });
    builder.addCase(
      assegnaCodiceIPA.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      assegnaCodiceIPA.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //add codice bilancio
    builder.addCase(
      assegnaCodiceBilancio.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      assegnaCodiceBilancio.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      assegnaCodiceBilancio.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //get list populate select create SO

    builder.addCase(
      formSelectPopulateCreate.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.selectCreateFormStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      formSelectPopulateCreate.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.selectCreateFormResult = action.payload;
        state.selectCreateFormStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      formSelectPopulateCreate.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.selectCreateFormStatus = STATUS_REJECTED;
      }
    );

    //insert utenza cifra

    builder.addCase(
      assegnazioneUtenzeCifra.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      assegnazioneUtenzeCifra.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraResult = action.payload;
        state.utenzaCifraStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      assegnazioneUtenzeCifra.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraStatus = STATUS_REJECTED;
      }
    );

    //get by struttura utenze cifra

    builder.addCase(
      getUtenzeCifraByStruttura.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraListStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      getUtenzeCifraByStruttura.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraListResult = action.payload;
        state.utenzaCifraListStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      getUtenzeCifraByStruttura.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraListStatus = STATUS_REJECTED;
      }
    );

    //delete utenze cifra

    builder.addCase(
      deleteUtenzeCifra.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      deleteUtenzeCifra.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      deleteUtenzeCifra.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.utenzaCifraStatus = STATUS_REJECTED;
      }
    );
    //deactivate struttura organizzativa

    builder.addCase(
      disattivazioneStruttura.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      disattivazioneStruttura.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      disattivazioneStruttura.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //associaSOForm
    /*  builder.addCase(associaSOFormPopulate.pending, (state: StrutturaOrganizzativaState, action: any) => {
            state.formAssociaSOStatus = STATUS_PENDING
        })
        builder.addCase(associaSOFormPopulate.fulfilled, (state: StrutturaOrganizzativaState, action: any) => {
            state.formAssociaSOStatus = STATUS_FULLFILLED
            state.formAssociaSO = action.payload
        })
        builder.addCase(associaSOFormPopulate.rejected, (state: StrutturaOrganizzativaState, action: any) => {
            state.formAssociaSOStatus = STATUS_REJECTED
        })*/

    //associa Struttura organizzativa dipendente
    builder.addCase(
      associazioneStrutturaDipendente.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      associazioneStrutturaDipendente.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      associazioneStrutturaDipendente.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //disassocia Struttura organizzativa dipendente
    builder.addCase(
      disassociaStrutturaDipendente.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      disassociaStrutturaDipendente.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      disassociaStrutturaDipendente.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //get select dipendenti associati ad una struttura Organizzativa
    /*    builder.addCase(disassociaSOListaDipendenti.pending, (state: StrutturaOrganizzativaState, action: any) => {
            state.formDipententiListDisassociaStatus = STATUS_PENDING
        })
        builder.addCase(disassociaSOListaDipendenti.fulfilled, (state: StrutturaOrganizzativaState, action: any) => {
            state.formDipententiListDisassocia = action.payload
            state.formDipententiListDisassociaStatus = STATUS_FULLFILLED
        })
        builder.addCase(disassociaSOListaDipendenti.rejected, (state: StrutturaOrganizzativaState, action: any) => {
            state.formDipententiListDisassociaStatus = STATUS_REJECTED
        })*/

    //get dettaglio tabella associazione tra so e dipendente
    builder.addCase(
      detailsAssociazioneSoDipendente.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.assegnazioneSoDipendenteStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      detailsAssociazioneSoDipendente.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.assegnazioneSoDipendenteResult = action.payload;
        state.assegnazioneSoDipendenteStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      detailsAssociazioneSoDipendente.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.assegnazioneSoDipendenteStatus = STATUS_REJECTED;
      }
    );

    //inoltra struttura organizzativa
    builder.addCase(
      inoltraStruttureOrganizzative.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      inoltraStruttureOrganizzative.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateResult = action.payload;
        state.updateStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      inoltraStruttureOrganizzative.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.updateStatus = STATUS_REJECTED;
      }
    );

    //poplamento select Sezione by id dipartimento
    builder.addCase(
      selectCreateSOSezioni.pending,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.sezioniCreaStrutturaOrganizzativaStatus = STATUS_PENDING;
      }
    );
    builder.addCase(
      selectCreateSOSezioni.fulfilled,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.sezioniCreaStrutturaOrganizzativaResult = action.payload;
        state.sezioniCreaStrutturaOrganizzativaStatus = STATUS_FULLFILLED;
      }
    );
    builder.addCase(
      selectCreateSOSezioni.rejected,
      (state: StrutturaOrganizzativaState, action: any) => {
        state.sezioniCreaStrutturaOrganizzativaStatus = STATUS_REJECTED;
      }
    );
  },
});

export const {
  resetInputForm,
  resetUpdateForm,
  resetDetailsAssocazioneSoDipendente,
  resetInsertResult,
  resetSelectSezioni,
} = strutturaOrganizzativaSlice.actions;

export default strutturaOrganizzativaSlice.reducer;