import { Button, Icon, Input, Modal, ModalBody, ModalHeader } from 'design-react-kit';
import { useFormik } from 'formik';
import { useState, type ReactNode } from 'react';
import { FormContext } from '../../hooks/useCompositeForm';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import useUploadFiles from 'hooks/services/useUploadFiles';
import {
  initialValues,
  OnboardingConcorsoStatoOnb,
  type IFormCreaCandidatoForm,
} from 'components/crea-candidato-form/CreaCandidatoForm';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useRegistraDocumenti from 'hooks/services/useRegistraDocumenti';
import useAsyncInitialValues from 'hooks/useAsyncInitialValues';
import validationSchemaWithAddedFields from 'components/forms/validationSchemaWithAddedFields';
import { z } from 'zod';
import BackButton from 'components/back-button/BackButton';
import { OnboardingConcorsoStato } from 'components/crea-candidato-form/types';

export interface IFormCandidatoWithAddedFields extends IFormCreaCandidatoForm {
  fileCv?: File;
  note?: string;
}

export const initialValuesWithAddedFields = {
  ...initialValues,
};

export interface IFormRettifica {
  descrizione: string;
}

export const FormValidazioneDatiDocumenti = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  const uploadFiles = useUploadFiles();
  const registraDocumenti = useRegistraDocumenti();
  const navigate = useNavigate();
  const [isConfermaModalOpen, setIsConfermaModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: initialValuesWithAddedFields,
    validationSchema: toFormikValidationSchema(validationSchemaWithAddedFields),
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  const isAsyncValuesLoading = useAsyncInitialValues(formik, candidato, onboarding);

  const [isRettificaModalOpen, setIsRettificaModalOpen] = useState(false);
  const handleOpenRettifica = () => {
    setIsRettificaModalOpen(true);
  };

  const handleOpenConferma = () => {
    setIsConfermaModalOpen(true);
  };

  const rettificaForm = useFormik({
    initialValues: {
      descrizione: '',
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        descrizione: z
          .string({
            required_error: 'Obbligatorio',
          })
          .min(1, 'Inserisci la descrizione')
          .max(5000, { message: 'Massimo 5000 caratteri' }),
      })
    ),
    onSubmit: async ({ descrizione }) => {
      if (!candidato?.id) {
        dispatch(
          showNotifica({
            titolo: 'Invio notifica rettifica non è andato a buon fine',
            stato: 'error',
            messaggio: 'Invio notifica rettifica non è andato a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
        return;
      }
      try {
        await axios.post(
          `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${candidato.id}/rettifica`,
          { descrizione }
        );
        await axios.put(
          `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/rettifica/${candidato?.id}/onboarding/${onboarding?.id}`,
          {
            ...candidato,
          }
        );
        navigate('/elenco-candidati');
      } catch (error) {
        dispatch(
          showNotifica({
            titolo: 'Invio notifica rettifica non è andato a buon fine',
            stato: 'error',
            messaggio: 'Invio notifica rettifica non è andato a buon fine',
            tipo: NOTIFICA_STATO.error,
          })
        );
      }
    },
  });

  async function handleSubmit({
    // Dati anagrafici
    codiceFiscale,
    cognome,
    nome,
    sesso,
    statoCivile,
    cittadinanza,
    dataNascita,
    nazioneNascita,
    provNascita,
    cittaNascita,
    // Estremi identificazione
    tipoDocumento,
    numeroCartaIdentita,
    enteEmittente,
    dataRilascio,
    dataScadenza,
    filesIdentificazione,
    // Recapiti
    indirizzoPec,
    email,
    telefonoAbitazione,
    telefonoCellulare,
    // Dati residenza
    statoResidenza,
    provinciaResidenza,
    cittaResidenza,
    indirizzoResidenza,
    capResidenza,
    // Dati domicilio
    domicilioDifferente,
    statoDomicilio,
    provinciaDomicilio,
    cittaDomicilio,
    indirizzoDomicilio,
    capDomicilio,
    // Disabilità
    disabile,
    percentualeDisabilita,
    collocamentoMirato,
    fileCollocamento,
    documentiDisabilita,
    // Titolo di studio
    titoloStudio,
    titoloDichiarato,
    noteTitolo,
    // Concorsi
    livelli,
    idConcorso,
    areaProfessionale,
    vincitore,
    idoneo,
    posizioneGraduatoria,
    attiOnboarding,
    note,
    fileCv,
  }: IFormCandidatoWithAddedFields) {
    const onboardingUpdated = {
      id: onboarding?.id,
      stato: OnboardingConcorsoStato.REG,
      statoOnb: OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO,
      idConcorso,
      areaProfessionale,
      tipologiaContrattoId: 1,
      livello: livelli,
      vincitore,
      idoneo,
      posizioneGraduatoria,
      attiOnboarding: attiOnboarding.map(({ file, ...rest }) => rest),
    };

    const payload = {
      ...candidato,
      codiceFiscale,
      cognome,
      nome,
      genere: sesso === 'F' ? 'FEMMINILE' : 'MASCHILE',
      statoCivile,
      cittadinanza,
      dataNascita,
      nazioneNascita,
      provNascita,
      cittaNascita,
      tipoDocumento,
      numeroCartaIdentita,
      enteEmittente,
      dataRilascio,
      dataScadenza,
      indirizzoPec,
      email,
      telefonoAbitazione,
      telefonoCellulare,
      statoResidenza,
      provinciaResidenza,
      cittaResidenza,
      indirizzoResidenza,
      capResidenza,
      domicilioDifferente: domicilioDifferente !== 'No',
      statoDomicilio,
      provinciaDomicilio,
      cittaDomicilio,
      indirizzoDomicilio,
      capDomicilio,
      disabile: disabile !== 'No',
      percentualeDisabilita,
      collocamentoMirato: collocamentoMirato !== 'No',
      ...(titoloStudio && { titoloStudio }),
      ...(titoloDichiarato && { titoloDichiarato }),
      ...(noteTitolo && { noteTitolo }),
      onboardingConcorsi: [onboardingUpdated],
      note,
    };

    try {
      /*  const salvataggioIncompletoResponse = await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/salva/${candidato?.id}/onboarding/${onboarding?.id}`,
        payload
      ); */

      await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/valida/${candidato?.id}/onboarding/${onboarding?.id}`,
        payload
      );

      navigate('/elenco-candidati');
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Integrazione non andata a buon fine',
          stato: 'error',
          messaggio: 'Integrazione non andata a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  }

  if (isAsyncValuesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormContext.Provider value={formik}>
      <div>
        {children}
        <div className="d-flex col-12 mt-4">
          <BackButton />
          <div className="d-flex ml-auto">
            <Button className="mr-2" color="primary" outline onClick={handleOpenRettifica}>
              Richiedi Rettifica
            </Button>
            <Button className="ml-2" color="primary" onClick={handleOpenConferma}>
              Conferma
            </Button>
          </div>
        </div>
        <Modal
          isOpen={isConfermaModalOpen}
          toggle={() => setIsConfermaModalOpen(false)}
          labelledBy="elimina-attivita"
          centered
        >
          <ModalHeader
            toggle={() => {
              setIsConfermaModalOpen(false);
            }}
            id="elimina-attivita"
          >
            Conferma
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '50px',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: '20px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  outline
                  size="sm"
                  color="primary"
                  onClick={() => {
                    setIsConfermaModalOpen(false);
                  }}
                >
                  Annulla
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    setIsConfermaModalOpen(false);
                    formik.handleSubmit();
                  }}
                >
                  Conferma validazione dati e documenti
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isRettificaModalOpen}
          toggle={() => setIsRettificaModalOpen(false)}
          labelledBy="elimina-attivita"
          centered
        >
          <ModalHeader
            toggle={() => {
              setIsRettificaModalOpen(false);
            }}
            id="elimina-attivita"
          >
            Invia Notifica Rettifica
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '50px',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <Input
                label="Descrizione"
                type="text"
                placeholder=""
                id="descrizione"
                wrapperClassName="form-group required col-md-12"
                name="descrizione"
                value={rettificaForm.values.descrizione}
                onChange={rettificaForm.handleChange}
                onBlur={rettificaForm.handleBlur}
                invalid={rettificaForm.touched.descrizione && !!rettificaForm.errors.descrizione}
                infoText={rettificaForm.errors.descrizione && rettificaForm.errors.descrizione}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: '20px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  outline
                  size="sm"
                  color="primary"
                  onClick={() => {
                    setIsRettificaModalOpen(false);
                  }}
                >
                  Annulla
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    rettificaForm.submitForm();
                  }}
                >
                  Invia notifica
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </FormContext.Provider>
  );
};
