import axios from 'axios';
import { useEffect, useState } from 'react';
import useGetOnboardingById from './useGetOnboardingById';
import { useParams } from 'react-router-dom';
import { AttoOnboardingConcorsoDto } from 'services/ms-anagrafica-unica';

const useDownloadAttiOnboardingPdfs = ({
  onFilesReady,
}: {
  onFilesReady: (files: AttoOnboardingConcorsoDto[]) => void;
}) => {
  const { onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const [downloadedAtti, setDownloadedAtti] = useState<AttoOnboardingConcorsoDto[]>([]);

  useEffect(() => {
    const fetchAtti = async () => {
      if (!onboarding) return;

      const attiMap = [
        { tipo: 'ATTI_DETERMINA', attoTipo: 'DET' },
        { tipo: 'ATTI_DELIBERA', attoTipo: 'DEL' },
        { tipo: 'ATTI_ONBOARDING', attoTipo: 'DEC' },
      ];

      const attiPromises: Promise<AttoOnboardingConcorsoDto | null>[] = attiMap.flatMap(
        ({ tipo, attoTipo }) => {
          const documenti = onboarding.elencoDocumenti.filter((doc) => doc.tipo === tipo);
          const attiData = onboarding.attiOnboarding.filter((atto) => atto.tipoAtto === attoTipo);

          return documenti.map(async (doc) => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/documenti/${doc.documentoDto.id}/download`,
                { responseType: 'blob' }
              );

              const pdfBlob = new Blob([response.data], {
                type: 'application/pdf',
              });
              const file = new File([pdfBlob], `document-${doc.documentoDto.id}.pdf`, {
                type: 'application/pdf',
              });

              return {
                tipoAtto: attoTipo,
                oggettoAtto: attiData[0]?.oggettoAtto || 'N/A',
                dataAtto: attiData[0]?.dataAtto || 'N/A',
                numeroAtto: attiData[0]?.numeroAtto || 'N/A',
                file,
              };
            } catch (error) {
              console.error(`Error downloading document (${tipo}):`, error);
              return null;
            }
          });
        }
      );

      const attiFiles = await Promise.all(attiPromises);
      const validAttiFiles: AttoOnboardingConcorsoDto[] = attiFiles.filter(
        (file): file is AttoOnboardingConcorsoDto => file !== null
      );

      setDownloadedAtti(validAttiFiles);
      onFilesReady(validAttiFiles); // Update Formik with valid attiOnboarding files
    };

    fetchAtti();
  }, [onboarding]);

  return downloadedAtti;
};

export default useDownloadAttiOnboardingPdfs;
