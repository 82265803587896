import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import FormCv from 'components/FormCv/FormCv';
import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormNote from 'components/FormNote/FormNote';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import { FormReadingOnly } from 'components/forms/FormReadingOnly/FormReadingOnly';
import { ConferimentoDocumentiForm } from 'components/conferimento-documenti-form/ConferimentoDocumentiForm';
import DichiarazioneRegime from 'components/DichiarazioneRegime/DichiarazioneRegime';
import FormComportamentoArpal from 'components/FormComportamentoArpal/FormComportamentoArpal';
import FormFondoPerseo from 'components/FormFondoPerseo/FormFondoPerseo';
import FormIban from 'components/FormIban/FormIban';
import FormPrivacy from 'components/FormPrivacy/FormPrivacy';
import FormVisitaMedica from 'components/FormVisitaMedica/FormVisitaMedica';
import TabellaDocumentiConferimento from 'components/tabella-documenti-conferimento/TabellaDocumentiConferimento';

const ConferimentoDocumenti = () => {
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={'Conferimento Documenti'}
      />
      <FormReadingOnly>
        <FormDatiAnagrafici readingOnly />
        <FormEstremiIdentificazione readingOnly />
        <FormRecapiti readingOnly />
        <FormDatiResidenza readingOnly />
        <FormDomicilio readingOnly />
        <FormDatiDisabilita readingOnly />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv readingOnly />
        <FormNote readingOnly />
      </FormReadingOnly>
      <ConferimentoDocumentiForm>
        <FormPrivacy />
        <FormComportamentoArpal />
        <FormFondoPerseo />
        <DichiarazioneRegime />
        <FormIban />
        <FormVisitaMedica />
        <TabellaDocumentiConferimento />
      </ConferimentoDocumentiForm>
    </div>
  );
};

export default ConferimentoDocumenti;
