/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ConfigurazioneNotificheDto
 */
export interface ConfigurazioneNotificheDto {
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  funzionalita?: ConfigurazioneNotificheDtoFunzionalitaEnum;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  descrizioneFunzionalita?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  listaDistribuzione?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConfigurazioneNotificheDto
   */
  filtro?: boolean;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  oggetto?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  testo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfigurazioneNotificheDto
   */
  ruoli?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  dataInserimento?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  dataModifica?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurazioneNotificheDto
   */
  stato?: ConfigurazioneNotificheDtoStatoEnum;
}

export const ConfigurazioneNotificheDtoFunzionalitaEnum = {
  SoInsDipartimento: 'SO_INS_DIPARTIMENTO',
  SoInsSezione: 'SO_INS_SEZIONE',
  SoInsServizio: 'SO_INS_SERVIZIO',
  SoInoltraDipartimento: 'SO_INOLTRA_DIPARTIMENTO',
  SoInoltraSezione: 'SO_INOLTRA_SEZIONE',
  SoInoltraServizio: 'SO_INOLTRA_SERVIZIO',
  SoAssCodcifra: 'SO_ASS_CODCIFRA',
  SoAssCodipa: 'SO_ASS_CODIPA',
  SoAssCodbilancio: 'SO_ASS_CODBILANCIO',
  CandIntegrazioneDati: 'CAND_INTEGRAZIONE_DATI',
  CandValidDati: 'CAND_VALID_DATI',
  CandAccettazione: 'CAND_ACCETTAZIONE',
  CandRifiuto: 'CAND_RIFIUTO',
  CandRettifica: 'CAND_RETTIFICA',
  CandCessazione: 'CAND_CESSAZIONE',
  IncaDirigIns: 'INCA_DIRIG_INS',
  IncaDirigValGiunta: 'INCA_DIRIG_VAL_GIUNTA',
  PoIns: 'PO_INS',
  DipendenteCessGruppoPres: 'DIPENDENTE_CESS_GRUPPO_PRES',
  BandoPoContesaCandidato: 'BANDO_PO_CONTESA_CANDIDATO',
  ConvAccetta: 'CONV_ACCETTA',
  ConvRifiuto: 'CONV_RIFIUTO',
  ConvRettifica: 'CONV_RETTIFICA',
  ConcCessazione: 'CONC_CESSAZIONE',
  ConcValidDati: 'CONC_VALID_DATI',
  ConcAccContr: 'CONC_ACC_CONTR',
  ConcContr: 'CONC_CONTR',
} as const;

export type ConfigurazioneNotificheDtoFunzionalitaEnum =
  (typeof ConfigurazioneNotificheDtoFunzionalitaEnum)[keyof typeof ConfigurazioneNotificheDtoFunzionalitaEnum];
export const ConfigurazioneNotificheDtoStatoEnum = {
  Attiva: 'ATTIVA',
  Disattiva: 'DISATTIVA',
} as const;

export type ConfigurazioneNotificheDtoStatoEnum =
  (typeof ConfigurazioneNotificheDtoStatoEnum)[keyof typeof ConfigurazioneNotificheDtoStatoEnum];
