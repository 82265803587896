/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { DatiAttoDto } from './dati-atto-dto';

/**
 *
 * @export
 * @interface CessazioneDipendenteDto
 */
export interface CessazioneDipendenteDto {
  /**
   *
   * @type {number}
   * @memberof CessazioneDipendenteDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  dataFineRapporto?: string;
  /**
   *
   * @type {boolean}
   * @memberof CessazioneDipendenteDto
   */
  collaboratore?: boolean;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  motivoCessazione?: CessazioneDipendenteDtoMotivoCessazioneEnum;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  numeroAttoCessazione?: string;
  /**
   *
   * @type {boolean}
   * @memberof CessazioneDipendenteDto
   */
  disattivazioneCredenzialiDominio?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CessazioneDipendenteDto
   */
  disattivazioneNumeroTelefono?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CessazioneDipendenteDto
   */
  disattivazioneCasellaPosta?: boolean;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  numeroAttoDecesso?: string;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  dataDecesso?: string;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  comune?: string;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  codiceIstat?: string;
  /**
   *
   * @type {string}
   * @memberof CessazioneDipendenteDto
   */
  provinciaIstat?: string;
  /**
   *
   * @type {number}
   * @memberof CessazioneDipendenteDto
   */
  idDocumento?: number;
  /**
   *
   * @type {DatiAttoDto}
   * @memberof CessazioneDipendenteDto
   */
  datiAtto?: DatiAttoDto;
}

export const CessazioneDipendenteDtoMotivoCessazioneEnum = {
  Decesso: 'DECESSO',
  DimissioniVolontarieRecesso: 'DIMISSIONI_VOLONTARIE_RECESSO',
  LimiteEta: 'LIMITE_ETA',
  LimiteServizio: 'LIMITE_SERVIZIO',
  DispensatoInabile: 'DISPENSATO_INABILE',
  DispensatoInvalido: 'DISPENSATO_INVALIDO',
  DimissioniVolontarieQuota100: 'DIMISSIONI_VOLONTARIE_QUOTA100',
  DimissioniVolontarieTrattativaMobilita: 'DIMISSIONI_VOLONTARIE_TRATTATIVA_MOBILITA',
  CessazioneContratto: 'CESSAZIONE_CONTRATTO',
  Licenziamento: 'LICENZIAMENTO',
  FineIncarico: 'FINE_INCARICO',
  SuperatiLimitiEta: 'SUPERATI_LIMITI_ETA',
  RisoluzioneConsensualeDirigente: 'RISOLUZIONE_CONSENSUALE_DIRIGENTE',
  Inabilita: 'INABILITA',
  Esonero: 'ESONERO',
  Mobilita: 'MOBILITA',
  Destituzione: 'DESTITUZIONE',
  Trasferimento: 'TRASFERIMENTO',
  RientroEnteAppartenenza: 'RIENTRO_ENTE_APPARTENENZA',
  InabilitaPermanete: 'INABILITA_PERMANETE',
  RisoluzioneConsensualeConservazionePosto: 'RISOLUZIONE_CONSENSUALE_CONSERVAZIONE_POSTO',
  InAttribuzione: 'IN_ATTRIBUZIONE',
  InabilitaAllaMansione: 'INABILITA_ALLA_MANSIONE',
  DimissioniRisoluzioneConsensuale: 'DIMISSIONI_RISOLUZIONE_CONSENSUALE',
} as const;

export type CessazioneDipendenteDtoMotivoCessazioneEnum =
  (typeof CessazioneDipendenteDtoMotivoCessazioneEnum)[keyof typeof CessazioneDipendenteDtoMotivoCessazioneEnum];
