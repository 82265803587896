import { Button, Icon } from 'design-react-kit';
import { useFormik } from 'formik';
import type { ReactNode } from 'react';
import { FormContext } from '../../hooks/useCompositeForm';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import useUploadFiles from 'hooks/services/useUploadFiles';
import { OnboardingConcorsoStatoOnb } from 'components/crea-candidato-form/CreaCandidatoForm';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useRegistraDocumenti from 'hooks/services/useRegistraDocumenti';
import useAsyncInitialValues from 'hooks/useAsyncInitialValues';
import validationSchemaWithAddedFields from 'components/forms/validationSchemaWithAddedFields';
import {
  initialValuesWithAddedFields,
  IFormCandidatoWithAddedFields,
} from 'components/form-validazione-dati-documenti/FormValidazioneDatiDocumenti';
import BackButton from 'components/back-button/BackButton';
import { OnboardingConcorsoStato } from 'components/crea-candidato-form/types';

export const FormVerificaIntegrazioneDati = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);
  const uploadFiles = useUploadFiles();
  const registraDocumenti = useRegistraDocumenti();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValuesWithAddedFields,
    validationSchema: toFormikValidationSchema(validationSchemaWithAddedFields),
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  console.log('formik values', formik.values);
  console.log('formik errors', formik.errors);

  const isAsyncValuesLoading = useAsyncInitialValues(formik, candidato, onboarding);

  async function handleSubmit({
    // Dati anagrafici
    codiceFiscale,
    cognome,
    nome,
    sesso,
    statoCivile,
    cittadinanza,
    dataNascita,
    nazioneNascita,
    provNascita,
    cittaNascita,
    // Estremi identificazione
    tipoDocumento,
    numeroCartaIdentita,
    enteEmittente,
    dataRilascio,
    dataScadenza,
    filesIdentificazione,
    // Recapiti
    indirizzoPec,
    email,
    telefonoAbitazione,
    telefonoCellulare,
    // Dati residenza
    statoResidenza,
    provinciaResidenza,
    cittaResidenza,
    indirizzoResidenza,
    capResidenza,
    // Dati domicilio
    domicilioDifferente,
    statoDomicilio,
    provinciaDomicilio,
    cittaDomicilio,
    indirizzoDomicilio,
    capDomicilio,
    // Disabilità
    disabile,
    percentualeDisabilita,
    collocamentoMirato,
    fileCollocamento,
    documentiDisabilita,
    // Titolo di studio
    titoloStudio,
    titoloDichiarato,
    noteTitolo,
    // Concorsi
    livelli,
    idConcorso,
    areaProfessionale,
    vincitore,
    idoneo,
    posizioneGraduatoria,
    attiOnboarding,
    note,
    fileCv,
  }: IFormCandidatoWithAddedFields) {
    console.log('fileCv', fileCv);
    // File handling: check if new ones
    /*   const documentiIdentificazione = await uploadFiles({
      files: filesIdentificazione,
      tipoDocumento:
        tipoDocumento === "CARTA_IDENTITA"
          ? "15"
          : tipoDocumento === "PASSAPORTO"
          ? "17"
          : "18",
      nomeFiles: "Identificazione",
    });
    const documentiAttiOnBoarding = await uploadFiles({
      files: attiOnboarding.map(({ file }) => file),
      tipoDocumento: "21",
      nomeFiles: "Atti",
    }); */
    const documentoCv =
      fileCv &&
      (await uploadFiles({
        files: [fileCv],
        tipoDocumento: '8',
        nomeFiles: 'CV',
      }));

    const onboardingUpdated = {
      id: onboarding?.id,
      stato: OnboardingConcorsoStato.REG,
      statoOnb: OnboardingConcorsoStatoOnb.CONVALIDA_CANDIDATO,
      idConcorso,
      areaProfessionale,
      tipologiaContrattoId: 1,
      livello: livelli,
      vincitore,
      idoneo,
      posizioneGraduatoria,
      attiOnboarding: attiOnboarding.map(({ file, ...rest }) => rest),
      note,
    };

    const payload = {
      ...candidato,
      codiceFiscale,
      cognome,
      nome,
      genere: sesso === 'F' ? 'FEMMINILE' : 'MASCHILE',
      statoCivile,
      cittadinanza,
      dataNascita,
      nazioneNascita,
      provNascita,
      cittaNascita,
      tipoDocumento,
      numeroCartaIdentita,
      enteEmittente,
      dataRilascio,
      dataScadenza,
      indirizzoPec,
      email,
      telefonoAbitazione,
      telefonoCellulare,
      statoResidenza,
      provinciaResidenza,
      cittaResidenza,
      indirizzoResidenza,
      capResidenza,
      domicilioDifferente: domicilioDifferente !== 'No',
      statoDomicilio,
      provinciaDomicilio,
      cittaDomicilio,
      indirizzoDomicilio,
      capDomicilio,
      disabile: disabile !== 'No',
      percentualeDisabilita,
      collocamentoMirato: collocamentoMirato !== 'No',
      ...(titoloStudio && { titoloStudio }),
      ...(titoloDichiarato && { titoloDichiarato }),
      ...(noteTitolo && { noteTitolo }),
      onboardingConcorsi: [onboardingUpdated],
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/salva/${candidato?.id}/onboarding/${onboarding?.id}`,
        payload
      );

      await axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/onboarding/${onboarding?.id}/valida`,
        onboardingUpdated
      );

      /*   await axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/integrazione/${candidato?.id}/onboarding/${onboarding?.id}`,
        payload
      ); */

      if (documentoCv) registraDocumenti([...documentoCv], onboarding?.id);
      navigate('/elenco-candidati');
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Integrazione non andata a buon fine',
          stato: 'error',
          messaggio: 'Integrazione non andata a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  }

  if (isAsyncValuesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormContext.Provider value={formik}>
      <div>
        {children}
        <div className="d-flex col-12 mt-4">
          <BackButton />
          <div className="d-flex ml-auto">
            <Button
              className="mr-2"
              color="primary"
              outline
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Salva in bozza
            </Button>
            <Button className="ml-2" color="primary" onClick={() => formik.handleSubmit()}>
              Conferma
            </Button>
          </div>
        </div>
      </div>
    </FormContext.Provider>
  );
};
