import { useCompositeConferimentoDocumentiForm } from 'components/conferimento-documenti-form/ConferimentoDocumentiForm';
import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader, Toggle } from 'design-react-kit';
import { useState } from 'react';

const FormFondoPerseo = () => {
  const { values, handleChange, setFieldValue, errors, touched } =
    useCompositeConferimentoDocumentiForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <Button
        className="w-100 d-flex justify-content-between mb-3"
        color="primary"
        onClick={toggleModal}
      >
        Fondo Perseo-Sirio
        <Icon icon="it-arrow-right" color="white" />
      </Button>
      {/* <Button onClick={toggleModal}>Fondo Perseo-Sirio</Button> */}
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={'modal-privacy'}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={'modal-privacy'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" onClick={toggleModal} icon="it-close" />
              <h2>Fondo Perseo-Sirio</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            In caso di mancata adesione esplicita al Fondo Perseo Sirio o di mancata compilazione e
            consegna alla propria Amministrazione del modulo di rinuncia entro sei mesi dalla data
            di assunzione (art. 4, c. 2 dell'Accordo), il dipendente sarà iscritto automaticamente
            al Fondo pensione complementare Perseo-Sirio, a decorrere dal primo giorno del mese
            successivo alla scadenza dei sei mesi.
          </div>
          <div>
            <Toggle
              label="Manifesto la volontà di NON aderire al Fondo Perseo-Sirio"
              name="rinunciaPerseo"
              checked={values.rinunciaPerseo}
              // @ts-ignore
              value={values.rinunciaPerseo}
              invalid={!!errors.rinunciaPerseo}
              infoText={errors.rinunciaPerseo}
              onChange={handleChange}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color="primary"
            outline
            className="link-underline-primary"
            onClick={() => toggleModal()}
          >
            Annulla
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleModal();
              console.log('Form values: ', values);
            }}
          >
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormFondoPerseo;
