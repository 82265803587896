import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/common/page-header/PageHeader';
import { HREF_CANDIDATURA_ELENCO_BANDI } from '../../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useEffect, useState } from 'react';
import {
  eliminaDocumentoFirmato,
  eliminaDocumentoRiepilogo,
  generaRipepilogo,
  generaRipepilogoFirmato,
  getDettaglioAssegnazioneBando,
  insertCandidatura,
} from '../../../store/bandoSlice';
import { useNavigate, useParams } from 'react-router-dom';
import BandoDetail from '../../../components/bando-eq-detail-form/BandoDetail';
import DocumentUpload from '../../../components/common/document-upload/DocumentUpload';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  AssegnazioneBandiPoDto,
  BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest,
  CandidaturaBandoPoDto,
  DocumentoDto,
  DocumentoRiepilogoCandidaturaRequestDto,
} from '../../../services/ms-anagrafica-unica';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Col,
  Icon,
  FormGroup,
  Label,
} from 'design-react-kit';
import TextareaInfoChars from '../../../components/common/textarea-infochars/TextareaInfoChars';
import { downloadDocument } from 'store/DocumentiSlice';

const VisualizzaBandoEQ = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { idBando } = useParams();

  const { assegnazioneBandoStatus, assegnazioneBandoResult } = useAppSelector(
    (state) => state.bando
  );
  const { userInfoResult } = useAppSelector((state) => state.user);

  const [candidatura, setCandidatura] = useState<CandidaturaBandoPoDto>();
  const [documentoRiepilogo, setDocumentoRiepilogo] = useState<DocumentoDto | undefined>(undefined);
  const [documentoFirmato, setDocumentoFirmato] = useState<DocumentoDto | undefined>(undefined);
  const [isCaricatoDocFirmato, setIsCaricatoDocFirmato] = useState<boolean>(false);

  const [showModalDatiFirma, setShowModalDatiFirma] = useState<boolean>(false);
  const [messaggioErrore, setMessaggioErrore] = useState<any>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [datiIstanzaSaved, setDatiIstanzaSaved] = useState<boolean>(false);
  const [candidaturaInviata, setCandidaturaInviata] = useState<boolean>(false);
  const [datiRiepilogoCandidatura, setDatiRiepilogoCandidatura] =
    useState<DocumentoRiepilogoCandidaturaRequestDto>();

  //flag
  const [flagDichiarazioneCondannePenali, setFlagDichiarazioneCondannePenali] =
    useState<boolean>(false);
  const [flagPeriodoDiProvali, setFlagPeriodoDiProva] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getDettaglioAssegnazioneBando({
        id: Number(idBando),
      })
    )
      .unwrap()
      .then((resp) => {
        if (resp) {
          elaboraMessaggioErrore(resp);
          const candidatura =
            resp.elencoCandidature &&
            resp.elencoCandidature[
              resp.elencoCandidature.findIndex(
                (x) => x.dipendenteRegionale?.id === userInfoResult?.entityId
              )
            ];
          setCandidatura(candidatura);
          const datiIstanza = {
            breveEsposizione: candidatura?.breveEsposizione || '',
            specificareComprovataEsperienza: candidatura?.specificareComprovataEsperienza || '',
            denominazioneAltraEq: candidatura?.denominazioneAltraEq || '',
            procedDisciplinari: candidatura?.procedDisciplinari || '',
          };
          formSalvaDatiCandidatura.setValues(datiIstanza);

          if (
            candidatura?.breveEsposizione !== '' &&
            candidatura?.specificareComprovataEsperienza
          ) {
            setFlagDichiarazioneCondannePenali(true);
            setFlagPeriodoDiProva(true);
          }

          setDatiRiepilogoCandidatura(datiIstanza);
          if (candidatura?.idRiepilogoFirmato) {
            setDocumentoFirmato(candidatura.idRiepilogoFirmato);
            setIsCaricatoDocFirmato(true);
          }
          if (candidatura?.idRiepilogoDoc) setDocumentoRiepilogo(candidatura.idRiepilogoDoc);

          if (candidatura !== undefined && candidatura.stato !== 'IN_COMP') {
            setCandidaturaInviata(true);
          } else if (candidatura !== undefined && candidatura.stato === 'IN_COMP') {
            setDatiIstanzaSaved(true);
          }
        }
      });
  }, []);

  //FORM FIRMA
  const initialValuesFirma: {
    codiceCertificato: string;
    password: string;
    otp: string;
  } = {
    codiceCertificato: '',
    password: '',
    otp: '',
  };
  const validateSchemaFirma = z.object({
    codiceCertificato: z.string().optional(),
    password: z.string({ required_error: 'required' }),
    otp: z.string({ required_error: 'required' }).length(8, 'lengthOTP'),
  });
  const formDatiFirma = useFormik({
    initialValues: initialValuesFirma,
    validationSchema: toFormikValidationSchema(validateSchemaFirma),
    onSubmit: (values) => {
      generaRiepilogoFirmato();
    },
  });
  //****************************************

  //FORM SALVA DATI CANDIDATURA
  const initialDatiCandidaturaForm: DocumentoRiepilogoCandidaturaRequestDto = {
    breveEsposizione: '',
    specificareComprovataEsperienza: '',
    procedDisciplinari: undefined,
    denominazioneAltraEq: undefined,
  };
  const validateSchemaDatiCandidatura = z.object({
    breveEsposizione: z
      .string({ required_error: 'required' })
      .max(600, { message: 'length600chars' }),
    specificareComprovataEsperienza: z
      .string({ required_error: 'required' })
      .max(600, { message: 'length600chars' }),
    procedDisciplinari: z.string().max(600, { message: 'length600chars' }).optional(),
    denominazioneAltraEq: z.string().max(600, { message: 'length600chars' }).optional(),
  });
  const formSalvaDatiCandidatura = useFormik({
    initialValues: initialDatiCandidaturaForm,
    validationSchema: toFormikValidationSchema(validateSchemaDatiCandidatura),
    onSubmit: (values) => {
      const body: BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest = {
        id: Number.parseInt(idBando!),
        candidaturaBandoPoRequestDto: {
          riepilogoCandidaturaRequestDto: values,
          requestType: 'SALVA',
        },
      };
      dispatch(insertCandidatura(body))
        .unwrap()
        .then((resp) => {
          if (resp) {
            setDatiIstanzaSaved(true);
            setDatiRiepilogoCandidatura(values);
          }
        });
    },
  });
  //****************************************

  const setIdDocumentoFirmatoCaricato = (id: number | undefined) => {
    if (id !== -1) {
      dispatch(
        insertCandidatura({
          id: Number.parseInt(idBando!),
          candidaturaBandoPoRequestDto: {
            idRiepilogoFirmato: id,
            riepilogoCandidaturaRequestDto: datiRiepilogoCandidatura!,
            requestType: 'SALVA',
          },
        })
      )
        .unwrap()
        .then((resp) => {
          if (resp) {
            setIsCaricatoDocFirmato(true);
            setDocumentoFirmato({ id });
          }
        });
    } else {
      setIsCaricatoDocFirmato(false);
      setDocumentoFirmato(undefined);
    }
  };

  const elaboraMessaggioErrore = (resp: AssegnazioneBandiPoDto) => {
    if (
      resp.elencoCandidature &&
      resp.elencoCandidature[
        resp.elencoCandidature
          .filter((c) => c.stato !== 'IN_COMP')
          .findIndex((x) => x.dipendenteRegionale?.id === userInfoResult?.entityId)
      ]
    ) {
      setMessaggioErrore('Candidatura già inviata');
    } else {
      setMessaggioErrore(resp.messaggioRiepilogoNonGenerabile);
    }
  };

  const getFieldErrorFormFirma = (
    formConfermaCandidatura: FormikProps<{
      codiceCertificato: string;
      password: string;
      otp: string;
    }>,
    fieldName: keyof {
      codiceCertificato: string;
      password: string;
      otp: string;
    }
  ): string => {
    if (formConfermaCandidatura.getFieldMeta(fieldName).touched) {
      return formConfermaCandidatura.errors[fieldName] || '';
    } else return '';
  };

  const getFieldErrorInviaCandidatura = (
    formConfermaCandidatura: FormikProps<DocumentoRiepilogoCandidaturaRequestDto>,
    fieldName: keyof DocumentoRiepilogoCandidaturaRequestDto
  ): string => {
    if (formConfermaCandidatura.getFieldMeta(fieldName).touched) {
      return t(formConfermaCandidatura.errors[fieldName]!) || '';
    } else return '';
  };

  const closeModalFirma = () => {
    formDatiFirma.setValues(initialValuesFirma);
    formDatiFirma.resetForm();
    setShowModalDatiFirma(false);
    setShowPassword(false);
  };

  const getDatiIstanzaSalvati = (): DocumentoRiepilogoCandidaturaRequestDto => {
    return {
      breveEsposizione: datiRiepilogoCandidatura?.breveEsposizione || '',
      specificareComprovataEsperienza:
        datiRiepilogoCandidatura?.specificareComprovataEsperienza || '',
      procedDisciplinari: datiRiepilogoCandidatura?.procedDisciplinari || '',
      denominazioneAltraEq: datiRiepilogoCandidatura?.denominazioneAltraEq || '',
    };
  };

  const generaIstanza = (idBando: number) => {
    dispatch(
      generaRipepilogo({
        idAssegnazioneBando: idBando,
        documentoRiepilogoCandidaturaRequestDto: getDatiIstanzaSalvati(),
      })
    )
      .unwrap()
      .then((resp) => {
        if (resp) setDocumentoRiepilogo(resp);
      });
  };

  const generaRiepilogoFirmato = () => {
    setShowModalDatiFirma(false);
    dispatch(
      generaRipepilogoFirmato({
        idAssegnazioneBando: assegnazioneBandoResult?.codiceUnivoco!,
        ...formDatiFirma.values,
        documentoRiepilogoCandidaturaRequestDto: getDatiIstanzaSalvati(),
      })
    )
      .unwrap()
      .then((documento) => {
        if (documento) {
          setIdDocumentoFirmatoCaricato(documento?.id);
          closeModalFirma();
        } else {
          setShowModalDatiFirma(true);
        }
      });
  };

  const scaricaDocumentoFirmato = () => {
    if (documentoFirmato?.id) {
      dispatch(
        downloadDocument({
          idDocumento: documentoFirmato.id,
        })
      )
        .unwrap()
        .then((resp) => {
          if (resp) {
            const blob = new Blob([resp], { type: 'application/pdf' });
            let url;
            const link = document.createElement('a');
            url = URL.createObjectURL(blob);
            link.href = url;
            link.download = documentoFirmato.filename || 'RiepilogoCandidaturaFirmato';
            link.click();
          }
        });
    }
  };

  const callRimozioneFileRiepilogo = () => {
    dispatch(
      eliminaDocumentoRiepilogo({
        id: candidatura?.id!,
        idDocumento: documentoRiepilogo?.id!,
      })
    )
      .unwrap()
      .then((resp) => {
        if (resp) {
          setDocumentoRiepilogo(undefined);
        }
      });
  };

  const callRimozioneFileFirmato = () => {
    dispatch(
      eliminaDocumentoFirmato({
        id: candidatura?.id!,
        idDocumentoFirmato: documentoFirmato?.id!,
      })
    )
      .unwrap()
      .then((resp) => {
        if (resp) {
          setDocumentoFirmato(undefined);
          setIsCaricatoDocFirmato(false);
        }
      });
  };

  const confermaCandidatura = () => {
    const body: BandiPosizioniOrganizzativeControllerApiCandidaturaBandoPoRequest = {
      id: Number.parseInt(idBando!),
      candidaturaBandoPoRequestDto: {
        riepilogoCandidaturaRequestDto: datiRiepilogoCandidatura!,
        idRiepilogoFirmato: documentoFirmato?.id,
        requestType: 'CONFERMA',
      },
    };
    dispatch(insertCandidatura(body))
      .unwrap()
      .then((resp) => resp && navigate(HREF_CANDIDATURA_ELENCO_BANDI));
  };

  const breveEsposizioneChange = (e: any) => {
    setDatiIstanzaSaved(false);
    formSalvaDatiCandidatura.setFieldValue('breveEsposizione', e.target.value);
  };

  const comprovataEsperienzaChange = (e: any) => {
    setDatiIstanzaSaved(false);
    formSalvaDatiCandidatura.setFieldValue('specificareComprovataEsperienza', e.target.value);
  };

  const procedDisciplinariChange = (e: any) => {
    setDatiIstanzaSaved(false);
    formSalvaDatiCandidatura.setFieldValue('procedDisciplinari', e.target.value);
  };

  const denominazioneAltraEqChange = (e: any) => {
    setDatiIstanzaSaved(false);
    formSalvaDatiCandidatura.setFieldValue('denominazioneAltraEq', e.target.value);
  };

  const rimozioneDocumentoFirmato = (param: boolean) => {
    setIsCaricatoDocFirmato(!param);
    setDocumentoFirmato(undefined);
  };
  const rimozioneDocumentoRiepilogo = (param: boolean) => {
    setDocumentoRiepilogo(undefined);
  };

  const changeDichiarazioneCondannePenali = () => {
    setDatiIstanzaSaved(false);
    setFlagDichiarazioneCondannePenali(!flagDichiarazioneCondannePenali);
  };

  const changePeriodoDiProva = () => {
    setDatiIstanzaSaved(false);
    setFlagPeriodoDiProva(!flagPeriodoDiProvali);
  };

  const disabledBtnSalvaIstanza = () => {
    return (
      !flagDichiarazioneCondannePenali ||
      !flagPeriodoDiProvali ||
      !formSalvaDatiCandidatura.isValid ||
      datiIstanzaSaved ||
      messaggioErrore !== ''
    );
  };
  const disableBtnConfermaCandidatura = () => {
    return !flagDichiarazioneCondannePenali || !flagPeriodoDiProvali || !isCaricatoDocFirmato;
  };
  const disabledBtnGeneraIstanza = () => {
    return (
      !flagDichiarazioneCondannePenali ||
      !flagPeriodoDiProvali ||
      !datiIstanzaSaved ||
      !!documentoRiepilogo ||
      !!documentoFirmato ||
      messaggioErrore !== '' ||
      !formSalvaDatiCandidatura.isValid
    );
  };

  const showBoxUploadOrGeneraDocumentoFirmato = () => {
    return (
      flagDichiarazioneCondannePenali &&
      flagPeriodoDiProvali &&
      !!documentoRiepilogo &&
      datiIstanzaSaved &&
      !isCaricatoDocFirmato
    );
  };
  const disabilitaCampiFormCandidatura = () => {
    return isCaricatoDocFirmato || documentoRiepilogo !== undefined;
  };

  return (
    <div className="visualizza-bando">
      <PageHeader
        showIcon={true}
        urlBack={HREF_CANDIDATURA_ELENCO_BANDI}
        state={{ form: true }}
        title={t('Candidati')!}
        buttons={[]}
      />
      {assegnazioneBandoStatus === STATUS_FULLFILLED && !!assegnazioneBandoResult && (
        <div>
          <div className="mt-4">
            <BandoDetail bando={assegnazioneBandoResult} />
          </div>
          {messaggioErrore && messaggioErrore !== '' ? (
            <Alert style={{ top: '10px' }} color="warning">
              {t('candidatura errore') + messaggioErrore}
            </Alert>
          ) : (
            <div className="col-sm-12 mt-5">
              <div className=" ">
                <div className="mt-3 mb-2">
                  <span className="font-bold ml-2">{t('candidatura.datiIstanza.titolo')}</span>
                </div>
                <div className="form-custom form-bg border dati-istanza">
                  <p className="mt-3 mb-1 ml-4 font-bold">Si dichiara:</p>
                  <ol>
                    <li>
                      <FormGroup check className="condanne-penali">
                        <Input
                          label={t('candidatura.datiIstanza.condannePenali')}
                          type="checkbox"
                          id="condannePenali"
                          name="condannePenali"
                          disabled={disabilitaCampiFormCandidatura()}
                          className="required-checkbox-before"
                          checked={flagDichiarazioneCondannePenali}
                          onChange={changeDichiarazioneCondannePenali}
                          invalid={
                            formSalvaDatiCandidatura.isSubmitting &&
                            !flagDichiarazioneCondannePenali
                          }
                          infoText={
                            formSalvaDatiCandidatura.isSubmitting &&
                            !flagDichiarazioneCondannePenali
                              ? 'Campo obbligatorio'
                              : ''
                          }
                        />
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup className="col-md-12 candidatura-curriculum procedimenti-disciplinari">
                        <Label>{t('candidatura.datiIstanza.procedimentiDisciplinari')}</Label>
                        <TextareaInfoChars
                          rows={10}
                          className=""
                          id="procedDisciplinari"
                          wrapperClassName={' col-md-12'}
                          name="procedDisciplinari"
                          maxLength={600}
                          disabled={disabilitaCampiFormCandidatura()}
                          value={formSalvaDatiCandidatura.values.procedDisciplinari || ''}
                          onChange={procedDisciplinariChange}
                          invalid={
                            !!getFieldErrorInviaCandidatura(
                              formSalvaDatiCandidatura,
                              'procedDisciplinari'
                            )
                          }
                          infoText={
                            t(
                              `Testo massimo di 600 caratteri: si ricorda di non riportare dati e informazioni personali  `
                            ) || ''
                          }
                        />
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup check className="periodo-prova">
                        <Input
                          label={t('candidatura.datiIstanza.periodoProva')}
                          type="checkbox"
                          id="flagPeriodoDiProvali"
                          name="flagPeriodoDiProvali"
                          className="required-checkbox-before"
                          disabled={disabilitaCampiFormCandidatura()}
                          checked={flagPeriodoDiProvali}
                          onChange={changePeriodoDiProva}
                          invalid={formSalvaDatiCandidatura.isSubmitting && !flagPeriodoDiProvali}
                          infoText={
                            formSalvaDatiCandidatura.isSubmitting && !flagPeriodoDiProvali
                              ? 'Campo obbligatorio'
                              : ''
                          }
                        />
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup className="col-md-12 candidatura-curriculum procedimenti-disciplinari">
                        <Label>{t('candidatura.datiIstanza.possessoTitoli')}</Label>
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup className="col-md-12 candidatura-curriculum comprovata-esperienza">
                        <Label className="required-label">
                          {t('candidatura.datiIstanza.comprovateEsperienza')}
                        </Label>
                        <TextareaInfoChars
                          className=""
                          id="specificareComprovataEsperienza"
                          wrapperClassName={' col-md-12'} //
                          name="specificareComprovataEsperienza"
                          maxLength={600}
                          disabled={disabilitaCampiFormCandidatura()}
                          value={formSalvaDatiCandidatura.values.specificareComprovataEsperienza}
                          onChange={comprovataEsperienzaChange}
                          invalid={
                            !!getFieldErrorInviaCandidatura(
                              formSalvaDatiCandidatura,
                              'specificareComprovataEsperienza'
                            )
                          }
                          infoText={
                            !!getFieldErrorInviaCandidatura(
                              formSalvaDatiCandidatura,
                              'specificareComprovataEsperienza'
                            )
                              ? getFieldErrorInviaCandidatura(
                                  formSalvaDatiCandidatura,
                                  'specificareComprovataEsperienza'
                                )
                              : t(
                                  `Testo massimo di 600 caratteri: si ricorda di non riportare dati e informazioni personali`
                                ) || ''
                          }
                        />
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup className="col-md-12 candidatura-curriculum altra-eq">
                        <Label>{t('candidatura.datiIstanza.denominazione')}</Label>
                        <TextareaInfoChars
                          className=""
                          id="denominazioneAltraEq"
                          wrapperClassName={' col-md-12'}
                          name="denominazioneAltraEq"
                          maxLength={600}
                          disabled={disabilitaCampiFormCandidatura()}
                          value={formSalvaDatiCandidatura.values.denominazioneAltraEq || ''}
                          onChange={denominazioneAltraEqChange}
                          invalid={
                            !!getFieldErrorInviaCandidatura(
                              formSalvaDatiCandidatura,
                              'denominazioneAltraEq'
                            )
                          }
                          infoText={
                            t(
                              `Testo massimo di 600 caratteri: si ricorda di non riportare dati e informazioni personali`
                            ) || ''
                          }
                        />
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup className="col-md-12 candidatura-curriculum breve-esposizione">
                        <Label className="required-label">
                          {t('candidatura.datiIstanza.breveEsposizione')}
                        </Label>
                        <TextareaInfoChars
                          className=""
                          id="breveEsposizione"
                          wrapperClassName={' col-md-12'} //
                          name="breveEsposizione"
                          maxLength={600}
                          disabled={disabilitaCampiFormCandidatura()}
                          value={formSalvaDatiCandidatura.values.breveEsposizione}
                          onChange={breveEsposizioneChange}
                          invalid={
                            !!getFieldErrorInviaCandidatura(
                              formSalvaDatiCandidatura,
                              'breveEsposizione'
                            )
                          }
                          infoText={
                            !!getFieldErrorInviaCandidatura(
                              formSalvaDatiCandidatura,
                              'breveEsposizione'
                            )
                              ? getFieldErrorInviaCandidatura(
                                  formSalvaDatiCandidatura,
                                  'breveEsposizione'
                                )
                              : t(
                                  `Testo massimo di 600 caratteri: si ricorda di non riportare dati e informazioni personali`
                                ) || ''
                          }
                        />
                      </FormGroup>
                    </li>
                  </ol>
                </div>
                <div className="font-italic font-font-weight-semibold w-100 d-flex justify-content-end py-4">
                  <span>{t('campiObbligatoriAsterisco')}</span>
                </div>
              </div>

              {documentoRiepilogo && (
                <>
                  <Alert style={{ top: '10px' }} color="warning">
                    {t('candidatura.infoModificaDatiCandidatura')}
                  </Alert>
                  <div className="info-box-light py-3 mt-4">
                    <div>
                      <h6>{t('candidatura.infoDocumentoRiepilogo')}</h6>
                    </div>
                    <DocumentUpload
                      types={['application/pkcs7-mime', 'application/pdf']}
                      documentName=""
                      idDocumento={documentoRiepilogo?.id}
                      removedFile={rimozioneDocumentoRiepilogo}
                      emitRimozioneFile={() => callRimozioneFileRiepilogo()}
                      testoBtnScarica="Scarica"
                      disabilitaDeleteFile={true}
                      hiddenDeleteButton={isCaricatoDocFirmato}
                    />
                  </div>
                </>
              )}

              {showBoxUploadOrGeneraDocumentoFirmato() && (
                <div className="info-box-light py-3 mt-3">
                  <div>
                    <h6>{t('candidatura.istruzioniPerCandidatura')}</h6>
                  </div>
                  <DocumentUpload
                    types={['application/pkcs7-mime', 'application/pdf']}
                    documentName=""
                    idDocumento={documentoFirmato?.id}
                    setDocumentId={(id) => setIdDocumentoFirmatoCaricato(id)}
                    isSigned={true}
                  />

                  <div className="mt-4">
                    <h6>{t('candidatura.istruzioniPerCandidaturaDocFirmato')}</h6>
                  </div>

                  <Button
                    color="primary"
                    className="my-2 ml-2"
                    onClick={() => setShowModalDatiFirma(true)}
                  >
                    {t('Genera riepilogo candidatura (firmato)')}
                  </Button>

                  <Modal
                    isOpen={showModalDatiFirma}
                    toggle={() => setShowModalDatiFirma(!showModalDatiFirma)}
                    modalClassName="modal-custom"
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                  >
                    <ModalHeader>
                      <h5>Dati per applicare la firma Aruba</h5>
                    </ModalHeader>
                    <ModalBody>
                      <div className="form-custom m-3">
                        <div className="row">
                          <Col md="12" className="mb-2">
                            <Input
                              label="Codice Certificato (obbligatorio se si possiedono più certificati)"
                              type="text"
                              placeholder="Codice Certificato"
                              id="input-codiceCertificato"
                              wrapperClassName="mt-5 mb-2 "
                              name="codiceCertificato"
                              onChange={formDatiFirma.handleChange}
                              value={formDatiFirma.values.codiceCertificato}
                              invalid={!!getFieldErrorFormFirma(formDatiFirma, 'codiceCertificato')}
                              infoText={
                                t(getFieldErrorFormFirma(formDatiFirma, 'codiceCertificato')) ?? ''
                              }
                            />
                          </Col>
                        </div>
                        <div className="row">
                          <Col md="10" className="mb-2">
                            <Input
                              label="Password"
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Password"
                              id="input-password"
                              wrapperClassName="mt-5 mb-2  required"
                              name="password"
                              onChange={formDatiFirma.handleChange}
                              value={formDatiFirma.values.password}
                              invalid={!!getFieldErrorFormFirma(formDatiFirma, 'password')}
                              infoText={t(getFieldErrorFormFirma(formDatiFirma, 'password')) ?? ''}
                            />
                          </Col>
                          <Col
                            md="2"
                            className="mb-3 d-flex align-items-end justify-content-center col-md-2"
                          >
                            <Button
                              color="primary"
                              outline
                              size="xs"
                              className="mx-2"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              <Icon icon="it-password-visible" size="xs" color="primary"></Icon>{' '}
                              {showPassword ? 'Nascondi' : 'Vedi'}
                            </Button>
                          </Col>
                        </div>
                        <div className="row">
                          <Col md="12" className="mb-2">
                            <Input
                              label="OTP (Codice 'usa e getta' di 8 cifre generato ogni 30 secondi dall'app PugliaFD)"
                              type="text"
                              placeholder="One-Time Password"
                              id="input-otp"
                              wrapperClassName="mt-5 mb-2  required"
                              name="otp"
                              onChange={formDatiFirma.handleChange}
                              value={formDatiFirma.values.otp}
                              invalid={!!getFieldErrorFormFirma(formDatiFirma, 'otp')}
                              infoText={t(getFieldErrorFormFirma(formDatiFirma, 'otp')) ?? ''}
                            />
                          </Col>
                        </div>
                      </div>
                      <Button
                        color="primary"
                        className="mt-3 mx-3"
                        onClick={formDatiFirma.submitForm}
                      >
                        {t('Genera riepilogo candidatura (firmato)')}
                      </Button>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={() => closeModalFirma()}>
                        Chiudi
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              )}

              {isCaricatoDocFirmato && (
                <>
                  <div className="info-box-light py-3 mt-3">
                    <div>
                      <h6>{t('candidatura.infoDocumentoFirmato')}</h6>
                    </div>
                    <DocumentUpload
                      types={['application/pkcs7-mime', 'application/pdf']}
                      documentName=""
                      idDocumento={documentoFirmato?.id}
                      isSigned={true}
                      removedFile={rimozioneDocumentoFirmato}
                      emitRimozioneFile={() => callRimozioneFileFirmato()}
                      testoBtnScarica="Scarica"
                      disabilitaDeleteFile={true}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {candidaturaInviata && (
            <Button color="primary" className="mt-2 mr-2" onClick={() => scaricaDocumentoFirmato()}>
              {t('Scarica riepilogo candidatura (Firmato)')}
            </Button>
          )}

          <div className="d-flex flex-row-reverse">
            {!candidaturaInviata && (
              <>
                <Button
                  color="primary"
                  className="mt-3 mr-2"
                  disabled={disableBtnConfermaCandidatura()}
                  onClick={confermaCandidatura}
                >
                  {t('Conferma candidatura')}
                </Button>
                <Button
                  color="primary"
                  className="mt-3 mr-2"
                  onClick={formSalvaDatiCandidatura.submitForm}
                  disabled={disabledBtnSalvaIstanza()}
                >
                  {t('Salva istanza')}
                </Button>
                <Button
                  color="primary"
                  className="mt-3 mr-2"
                  onClick={() => generaIstanza(assegnazioneBandoResult?.codiceUnivoco!)}
                  disabled={disabledBtnGeneraIstanza()}
                >
                  {t('Genera istanza')}
                </Button>
              </>
            )}

            <Button
              outline
              color="secondary"
              className="mt-3 mr-2"
              onClick={() => navigate(HREF_CANDIDATURA_ELENCO_BANDI, { state: { form: true } })}
            >
              Indietro
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualizzaBandoEQ;
