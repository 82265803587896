import axios from 'axios';
import BackButton from 'components/back-button/BackButton';
import CampoForm from 'components/campo-form/CampoForm';
import {
  initialValues,
  OnboardingConcorsoStatoOnb,
} from 'components/crea-candidato-form/CreaCandidatoForm';
import CustomSelect from 'components/custom-select/CustomSelect';
import { Form, Button, Icon } from 'design-react-kit';
import { useAppDispatch } from 'hooks';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import { useParams } from 'react-router-dom';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';

const FormGeneraContratto = () => {
  const dispatch = useAppDispatch();
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);

  const handleGeneraRinuncia = () => {
    if (!onboarding?.id || !candidato?.id) {
      dispatch(
        showNotifica({
          titolo: 'Generazione rinuncia non è andata a buon fine',
          stato: 'error',
          messaggio: 'Generazione rinuncia non è andata a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }

    const updatedOnboarding = {
      ...onboarding,
    };

    try {
      axios.put(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/rinuncia/${candidato.id}/onboarding/${onboarding.id}`,
        {
          ...candidato,
          onboardingConcorsi: [
            {
              ...onboarding,
              ...updatedOnboarding,
              statoOnb: OnboardingConcorsoStatoOnb.RINUNCIATARIO,
            },
          ],
        }
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Generazione rinuncia non è andata a buon fine',
          stato: 'error',
          messaggio: 'Generazione rinuncia non è andata a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  const handleMancataPresenza = () => {
    if (!onboarding?.id) {
      dispatch(
        showNotifica({
          titolo: 'Inserimento mancata presenza non è andatq a buon fine',
          stato: 'error',
          messaggio: 'Inserimento mancata presenza non è andatq a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }

    try {
      axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/${onboarding.id}/accettazione/assunzione`,
        {
          ...candidato,
          onboardingConcorsi: [
            {
              ...onboarding,
              statoOnb: OnboardingConcorsoStatoOnb.DA_CONVOCARE,
            },
          ],
        }
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Inserimento mancata presenza non è andatq a buon fine',
          stato: 'error',
          messaggio: 'Inserimento mancata presenza non è andatq a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  const handleGeneraContratto = () => {
    if (!onboarding?.id) {
      dispatch(
        showNotifica({
          titolo: 'Generazione contratto non è andata a buon fine',
          stato: 'error',
          messaggio: 'Generazione contratto non è andata a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }

    const updatedOnboarding = {
      ...onboarding,
    };

    try {
      axios.post(
        `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/${onboarding.id}/contrattualizza`,
        {
          ...candidato,
          onboardingConcorsi: [
            {
              ...onboarding,
              ...updatedOnboarding,
              statoOnb: OnboardingConcorsoStatoOnb.CONTRATTUALIZZATO,
            },
          ],
        }
      );
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: 'Generazione contratto non è andata a buon fine',
          stato: 'error',
          messaggio: 'Generazione contratto non è andata a buon fine',
          tipo: NOTIFICA_STATO.error,
        })
      );
    }
  };

  return (
    <Form>
      <div className="d-flex col-12 mt-4">
        <BackButton title="Indietro" />
        <div className="d-flex ml-auto">
          <Button
            className="mr-2"
            color="danger"
            onClick={handleGeneraRinuncia}

            /*   disabled={
              onboarding?.statoOnb !== OnboardingConcorsoStatoOnb.CONVOCATO
            } */
          >
            Genera Rinuncia
          </Button>
          <Button
            color="primary"
            outline
            onClick={handleMancataPresenza}
            /*  disabled={
              onboarding?.statoOnb !== OnboardingConcorsoStatoOnb.CONVOCATO
            } */
          >
            Mancata Presenza
          </Button>
          <Button
            className="ml-2"
            color="primary"
            onClick={handleGeneraContratto}
            /*   disabled={
              onboarding?.statoOnb !== OnboardingConcorsoStatoOnb.CONVOCATO
            } */
          >
            Genera contratto
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default FormGeneraContratto;
