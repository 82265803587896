import { Input } from 'design-react-kit';
import React from 'react';
import { InfoStatutoDto } from '../../../services/ms-anagrafica-unica';

const RIInformazioniStatutoComponent = (dati: { value: InfoStatutoDto }) => {
  return (
    <div className={'row pt-5'}>
      <Input
        type="text"
        label="Data Primo Esercizio"
        disabled={true}
        value={dati.value['durata-societa']?.['scadenza-esercizi']?.['dt-primo-esercizio'] ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Esericizi Successivi"
        disabled={true}
        value={dati.value['durata-societa']?.['scadenza-esercizi']?.['esercizi-successivi'] ?? '-'}
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Giorni Proroga Bilancio"
        disabled={true}
        value={
          dati.value['durata-societa']?.['scadenza-esercizi']?.['giorni-proroga-bilancio'] ?? '-'
        }
        wrapperClassName="col-md-3"
      />
      <Input
        type="text"
        label="Data Termine"
        disabled={true}
        value={dati.value['durata-societa']?.['dt-termine'] ?? '-'}
        wrapperClassName="col-md-3"
      />
    </div>
  );
};

export default RIInformazioniStatutoComponent;
