import { createContext, useContext } from 'react';

export const FormContext = createContext<any>(null);

export const useCompositeForm = () => useContext(FormContext);

/* 
import { createContext, useContext } from "react";
import { FormikProps } from "formik";
import { IFormCreaCandidatoForm } from "components/crea-candidato-form/CreaCandidatoForm";

// Create a properly typed context
export const FormContext = createContext<
  FormikProps<IFormCreaCandidatoForm> | any
>(null);

// Hook to use the context safely
export const useCompositeForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      "useCompositeForm must be used within a FormContext.Provider"
    );
  }
  return context;
};


*/
