/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { StruttureOrganizzativeDocumentiDto } from './strutture-organizzative-documenti-dto';
// May contain unused imports in some cases
// @ts-ignore
import { WarningParameterDto } from './warning-parameter-dto';

/**
 *
 * @export
 * @interface StrutturaOrganizzativaDto
 */
export interface StrutturaOrganizzativaDto {
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  warningMessageCode?: string;
  /**
   *
   * @type {Array<WarningParameterDto>}
   * @memberof StrutturaOrganizzativaDto
   */
  warningParameters?: Array<WarningParameterDto>;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof StrutturaOrganizzativaDto
   */
  struttura?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StrutturaOrganizzativaDto
   */
  equiparata?: boolean;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  tipo?: StrutturaOrganizzativaDtoTipoEnum;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  descrizioneBreve?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  descrizione?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceUfficioDetermine?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceUfficioDelibere?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceDipartimentoRagioneria?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceSezioneRagioneria?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceIpa?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceBilancio?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  responsabile?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  pec1?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  pec2?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  email1?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  email2?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  telefono1?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  telefono2?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  fax?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  indirizzo?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  cap?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  citta?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  dataInizioValidita?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  dataFineValidita?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceIncaricoPadre?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  stato?: StrutturaOrganizzativaDtoStatoEnum;
  /**
   *
   * @type {Array<StruttureOrganizzativeDocumentiDto>}
   * @memberof StrutturaOrganizzativaDto
   */
  documenti?: Array<StruttureOrganizzativeDocumentiDto>;
  /**
   *
   * @type {StrutturaOrganizzativaDto}
   * @memberof StrutturaOrganizzativaDto
   */
  strutturaOrganizzativaPadre?: StrutturaOrganizzativaDto;
  /**
   *
   * @type {boolean}
   * @memberof StrutturaOrganizzativaDto
   */
  dipendentiPresenti?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StrutturaOrganizzativaDto
   */
  dipendentiAttiviPresenti?: boolean;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  dataInserimento?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  dataModifica?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  dataCancellazione?: string;
  /**
   *
   * @type {string}
   * @memberof StrutturaOrganizzativaDto
   */
  tipoStrutturaDescrizione?: string;
  /**
   *
   * @type {boolean}
   * @memberof StrutturaOrganizzativaDto
   */
  codiceBilancioSezioneInseribile?: boolean;
}

export const StrutturaOrganizzativaDtoTipoEnum = {
  Dipartimento: 'DIPARTIMENTO',
  Sezione: 'SEZIONE',
  Servizio: 'SERVIZIO',
} as const;

export type StrutturaOrganizzativaDtoTipoEnum =
  (typeof StrutturaOrganizzativaDtoTipoEnum)[keyof typeof StrutturaOrganizzativaDtoTipoEnum];
export const StrutturaOrganizzativaDtoStatoEnum = {
  Compilata: 'COMPILATA',
  Registrata: 'REGISTRATA',
  Censita: 'CENSITA',
  Cessata: 'CESSATA',
} as const;

export type StrutturaOrganizzativaDtoStatoEnum =
  (typeof StrutturaOrganizzativaDtoStatoEnum)[keyof typeof StrutturaOrganizzativaDtoStatoEnum];
