import axios from 'axios';
import { useEffect, useState } from 'react';
import useGetOnboardingById from './useGetOnboardingById';
import { useParams } from 'react-router-dom';

interface IDocumentoDisabilita {
  tipoDisabilita: string;
  previsioneRevisione: 'Si' | 'No';
  dataRevisione: string;
  documentoDisabilita: File;
}

const useDownloadDocumentiDisabilita = ({
  onFilesReady,
}: {
  onFilesReady: (files: IDocumentoDisabilita[]) => void;
}) => {
  const { onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const [downloadedDocumenti, setDownloadedDocumenti] = useState<IDocumentoDisabilita[]>([]);

  useEffect(() => {
    const fetchDocumenti = async () => {
      if (!onboarding) return;

      // Filter disability-related documents
      const documentiDisabilita = onboarding.elencoDocumenti.filter((doc) =>
        [
          'RICHIESTA_SCHEDA_FUNZIONALE',
          'RICHIESTA_VERBALE_INVALIDITA',
          // @ts-ignore
        ].includes(doc.tipo)
      );

      const downloadFile = async (
        documentId: number | string,
        filename: string
      ): Promise<File | null> => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/documenti/${documentId}/download`,
            { responseType: 'blob' }
          );

          const pdfBlob = new Blob([response.data], {
            type: 'application/pdf',
          });
          return new File([pdfBlob], filename, { type: 'application/pdf' });
        } catch (error) {
          console.error(`Error downloading document (${documentId}):`, error);
          return null;
        }
      };

      const disabilitaPromises = documentiDisabilita.map(async (doc) => {
        const documentoDisabilita = await downloadFile(
          doc.documentoDto.id,
          doc.documentoDto.filename
        );
        if (!documentoDisabilita) return null;

        return {
          tipoDisabilita: doc.tipo || '',
          previsioneRevisione: 'No', // Default value since revisione is ignored
          dataRevisione: '', // Empty string as requested
          documentoDisabilita,
        };
      });

      const documentiFiles = await Promise.all(disabilitaPromises);
      const validDocumentiFiles: IDocumentoDisabilita[] = documentiFiles.filter(
        (file): file is IDocumentoDisabilita => file !== null
      );

      setDownloadedDocumenti(validDocumentiFiles);
      onFilesReady(validDocumentiFiles);
    };

    fetchDocumenti();
  }, [onboarding]);

  return downloadedDocumenti;
};

export default useDownloadDocumentiDisabilita;

/* import axios from "axios";
import { useEffect, useState } from "react";
import useGetOnboardingById from "./useGetOnboardingById";
import { useParams } from "react-router-dom";

interface IDocumentoDisabilita {
  tipoDisabilita: string;
  previsioneRevisione: "Si" | "No";
  dataRevisione?: string;
  documentoRevisione?: File;
  documentoDisabilita: File;
}

const useDownloadDocumentiDisabilita = ({
  onFilesReady,
}: {
  onFilesReady: (files: IDocumentoDisabilita[]) => void;
}) => {
  const { onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const [downloadedDocumenti, setDownloadedDocumenti] = useState<
    IDocumentoDisabilita[]
  >([]);

  useEffect(() => {
    const fetchDocumenti = async () => {
      if (!onboarding) return;

      // Get all disability-related documents
      const documentiDisabilita = onboarding.elencoDocumenti.filter((doc) =>
        [
          "RICHIESTA_SCHEDA_FUNZIONALE",
          "RICHIESTA_VERBALE_INVALIDITA",
          // @ts-ignore
        ].includes(doc.tipo)
      );

      const documentiRevisione = onboarding.elencoDocumenti.filter(
        (doc) => doc.tipo === "DISABILITA"
      );

      const downloadFile = async (
        documentId: number | string,
        filename: string
      ): Promise<File | null> => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/documenti/${documentId}/download`,
            { responseType: "blob" }
          );

          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          return new File([pdfBlob], filename, { type: "application/pdf" });
        } catch (error) {
          console.error(`Error downloading document (${documentId}):`, error);
          return null;
        }
      };

      const disabilitaPromises = documentiDisabilita.map(async (doc) => {
        const documentoDisabilita = await downloadFile(
          doc.documentoDto.id,
          doc.documentoDto.filename
        );
        if (!documentoDisabilita) return null;

        // Try to find a related documentoRevisione, but there's no clear linking key
        const documentoRevisioneDoc = documentiRevisione.find(() => true); // Placeholder: No real way to link them

        const documentoRevisione = documentoRevisioneDoc
          ? await downloadFile(
              documentoRevisioneDoc.documentoDto.id,
              documentoRevisioneDoc.documentoDto.filename
            )
          : undefined;

        return {
          tipoDisabilita: doc.tipo,
          previsioneRevisione: documentoRevisione ? "Si" : "No",
          dataRevisione: documentoRevisioneDoc
            ? documentoRevisioneDoc.documentoDto.dataInserimento
            : undefined,
          documentoRevisione,
          documentoDisabilita,
        };
      });

      const documentiFiles = await Promise.all(disabilitaPromises);
      const validDocumentiFiles: IDocumentoDisabilita[] = documentiFiles.filter(
        (file): file is IDocumentoDisabilita => file !== null
      );

      setDownloadedDocumenti(validDocumentiFiles);
      onFilesReady(validDocumentiFiles);
    };

    fetchDocumenti();
  }, [onboarding]);

  return downloadedDocumenti;
};

export default useDownloadDocumentiDisabilita;
 */
