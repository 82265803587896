import { FormVerificaIntegrazioneDati } from 'components/form-verifica-integrazione-dati/FormVerificaIntegrazioneDati';
import FormCv from 'components/FormCv/FormCv';
import FormDatiAnagrafici from 'components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from 'components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from 'components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from 'components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormNote from 'components/FormNote/FormNote';
import FormRecapiti from 'components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from 'components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from 'components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from 'components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';

const VerificatIntegrazioneDati = () => {
  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_CANDIDATI}
        title={'Verifica ed integrazione dati'}
      />
      <FormVerificaIntegrazioneDati>
        <FormDatiAnagrafici />
        <FormEstremiIdentificazione />
        <FormRecapiti />
        <FormDatiResidenza />
        <FormDomicilio />
        <FormDatiDisabilita />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv />
        <FormNote />
      </FormVerificaIntegrazioneDati>
    </div>
  );
};

export default VerificatIntegrazioneDati;
