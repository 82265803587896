import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Input } from 'design-react-kit';

import { useTranslation } from 'react-i18next';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { STATUS_FULLFILLED, STATUS_REJECTED } from '../../store/store-constants';
import RegistroImpreseDetailComponent from '../../components/registro-imprese/registro-imprese-detail-component/RegistroImpreseDetailComponent';
import {
  RegistroImpreseSearchRequest,
  searchByCodiceFiscale,
} from '../../store/RegistroImpreseSlice';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

function RegistroImprese() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchResult, searchInput, searchStatus } = useAppSelector(
    (state) => state.registroImprese
  );

  const [formCollapseOpen, setFormCollapseOpen] = useState(true);
  const [hasResults, setHasResults] = useState(false);

  const schema = z.object({
    codiceFiscale: z.string({ required_error: 'Nessun elemento da ricercare' }),
  });

  const searchForm = useFormik<RegistroImpreseSearchRequest>({
    initialValues: { codiceFiscale: '' },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: () => {
      setHasResults(false);
      setFormCollapseOpen(false);
      dispatch(searchByCodiceFiscale(searchForm.values.codiceFiscale));
    },
  });

  const getFieldError = (
    form: FormikProps<RegistroImpreseSearchRequest>,
    fieldName: keyof RegistroImpreseSearchRequest
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return (form.errors[fieldName] as string) || ('' as string);
    } else return '';
  };

  useEffect(() => {
    setHasResults(!!searchResult);
  }, [searchResult]);

  return (
    <div>
      <h2>{t('Registro imprese')}</h2>
      <Accordion className="form-custom form-bg filtri-ricerca mt-3" background={'active'}>
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('Ricerca Singola')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="mt-2">
            <div className={'form-row justify-content-between'}>
              <Input
                type="text"
                id="input-codice-fiscale"
                placeholder="Inserisci il codice fiscale su cui verificare la visura"
                wrapperClassName="col col-md-6 mb-2"
                name="codiceFiscale"
                value={searchForm.values.codiceFiscale}
                onChange={searchForm.handleChange}
                invalid={!!getFieldError(searchForm, 'codiceFiscale')}
                infoText={t(getFieldError(searchForm, 'codiceFiscale')) ?? ''}
              />
              <div className="d-flex flex-row-reverse">
                <Button
                  color="primary"
                  className="mt-2"
                  onClick={() => {
                    searchForm.handleSubmit();
                  }}
                >
                  Ricerca
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mt-2 mx-2"
                  onClick={searchForm.handleReset}
                >
                  Pulisci
                </Button>
              </div>
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-5 position-relative">
        {/*{searchStatus === STATUS_PENDING &&*/}
        {/*    <PageLoader loadingMessage="Ricerca in corso.."/>*/}
        {/*}*/}

        {/*{exportStatus === STATUS_PENDING &&*/}
        {/*    <PageLoader loadingMessage="Esportazione in corso.."/>*/}
        {/*}*/}

        {searchStatus === STATUS_REJECTED && (
          <Alert color="danger">
            Siamo spiacenti, si &egrave; verificato un errore durante la richiesta
          </Alert>
        )}

        {searchStatus === STATUS_FULLFILLED && !hasResults && (
          <Alert color="info">Nessun risultato disponibile</Alert>
        )}

        {searchStatus === STATUS_FULLFILLED && hasResults && (
          <>
            <RegistroImpreseDetailComponent value={searchResult} input={searchInput} />{' '}
          </>
        )}
      </div>
    </div>
  );
}

export default RegistroImprese;
