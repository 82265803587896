import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { useCompositeForm } from 'hooks/useCompositeForm';
import { useState } from 'react';

const FormVisitaMedica = () => {
  const { values, handleChange, setFieldValue, errors, touched } = useCompositeForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModal() {
    setIsModalOpen((prevValue) => !prevValue);
  }

  function handleConfirm() {
    setFieldValue('visitaMedica', true);
    toggleModal();
  }

  return (
    <>
      <Button
        className="w-100 d-flex justify-content-between mb-3"
        color="primary"
        onClick={toggleModal}
      >
        Sottoscrizione impegno ad effettuare la visita medica
        <Icon icon="it-arrow-right" color="white" />
      </Button>
      {/* <Button onClick={toggleModal}>Sottoscrizione impegno ad effettuare la visita medica</Button> */}
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={toggleModal}
        centered
        labelledBy={'modal-privacy'}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={'modal-privacy'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" onClick={toggleModal} icon="it-close" />
              <h2>Impegno ad effettuare la visita medica</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <div className="mb-2">SI IMPEGNA</div>
            <div className="mb-4 text-justify">
              a sottoporsi alla visita medica preventiva prevista dall'art. 41 co. 2 D. Lgs 81/2008
              materia di tutela della salute e di sicurezza dei lavoratori sui luoghi di lavoro, nei
              termini previsti e comunicati dal Medico competente nominato da Arpal Puglia.
            </div>
            <div className="text-justify">
              Il sottoscritto è consapevole che la visita medica preventiva è "intesa a constatare
              l'assenza di controindicazioni al lavoro cui il lavoratore è destinato al fine di
              valutare la sua idoneità alla mansione specifica" e che, in caso di assenza dalla
              convocazione,
              <u>
                la data della presa di servizio sarà procrastinata fino all'effettivo espletamento
                della suddetta visita
              </u>
              .
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" outline className="link-underline-primary" onClick={toggleModal}>
            Annulla
          </Button>
          <Button color="primary" onClick={handleConfirm}>
            Conferma
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormVisitaMedica;
