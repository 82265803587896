import { useEffect, useState } from 'react';
import { Button, Col, Input, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  DocumentoPrimaConvocazioneRequestDto,
  NuoveAssunzioniInsertUpdateRequestDto,
  RuoloUtenteAutenticatoDto,
} from '../../services/ms-anagrafica-unica';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  detailsCandidato,
  formSrachPopulate,
  generaDocumentoPrimaConvocazione,
  generaDocumentoRequest,
  insertCandidato,
  resetAll,
  resetGeneraDocumento,
  ricercaCandidato,
  updateCandidato,
} from '../../store/candidatoSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import CandidatoDetailsComponent from '../../components/candidato-detail-form/CandidatoDetailsComponent';
import {
  CAP,
  CITTADINANZA,
  CITTA_NASCITA,
  CODICE_FISCALE,
  COGNOME,
  CITTA_RESIDENZA,
  DATA_NASCITA,
  INDIRIZZO_RESIDENZA,
  NOME,
  NUM_CARTA_IDENTITA,
  PROVINCIA,
  SESSO,
  STATO_CIVILE,
} from './candidatiConstants';
import PageHeader from 'components/common/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/layout/sidemenu/sidemenuConstants';
import TrovaCandidato from './StepperInserimentoCandidato/TrovaCandidato';

const oneDay = 3600 * 1000 * 24;

const initialValuesCodiceFiscale = {
  codiceFiscale: '',
};

const validationSchemaCodiceFiscale = z.object({
  codiceFiscale: z.string({ required_error: 'required' }).max(16, 'lengthcodicefiscale'),
});

let initialValuesUtenteRecuperato: NuoveAssunzioniInsertUpdateRequestDto = {
  codiceUnivoco: undefined,
  pec: '',
  email: '',
  telefono: '',
  cellulare: '',
  modalitaSelezione: undefined,
  attoIndizione: undefined,
  tipoContratto: undefined,
  areaProfessionale: undefined,
  categoria: undefined,
  livello: undefined,
  dataInvioDocumenti: '',
  note: '',
  requestType: 'CONFERMA',
};

const validationSchemaUtenteRecuperatoConfirm = z.object({
  pec: z
    .string({ required_error: 'required' })
    .trim()
    .min(1)
    .max(150, 'lenghtEmailCandidato')
    .email({ message: 'emailNonCorretta' }),
  email: z
    .string()
    .trim()
    .min(1)
    .max(150, 'lenghtEmailCandidato')
    .email({ message: 'emailNonCorretta' })
    .optional(),
  telefono: z
    .string()
    .regex(new RegExp(/[0-9]/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
  cellulare: z
    .string()
    .regex(new RegExp(/[0-9]/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
  modalitaSelezione: z.string({ required_error: 'required' }),
  attoIndizione: z.string({ required_error: 'required' }).optional(),
  tipoContratto: z.string({ required_error: 'required' }),
  areaProfessionale: z.string({ required_error: 'required' }),
  livello: z.string({ required_error: 'required' }),
  categoria: z.string({ required_error: 'required' }).optional(),
  dataInvioDocumenti: z.coerce.date(),
  note: z.string({ required_error: 'required' }).optional(),
});

const validationSchemaGeneraDocumento = z.object({
  tipoContratto: z.string({ required_error: 'required' }),
  areaProfessionale: z.string({ required_error: 'required' }),
  livello: z.string({ required_error: 'required' }),
  dataInvioDocumenti: z.coerce.date().min(new Date(Date.now() - oneDay), {
    message: 'Il campo data invio documenti non deve essere inferiore alla data odierna',
  }),
});

const validationSchemaUtenteRecuperatoSave = z.object({
  pec: z
    .string({ required_error: 'required' })
    .trim()
    .min(1)
    .max(150, 'lenghtEmailCandidato')
    .email({ message: 'emailNonCorretta' })
    .optional(),
  email: z
    .string()
    .trim()
    .min(1)
    .max(150, 'lenghtEmailCandidato')
    .email({ message: 'emailNonCorretta' })
    .optional(),
  telefono: z
    .string()
    .regex(new RegExp(/[0-9]/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
  cellulare: z
    .string()
    .regex(new RegExp(/[0-9]/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
  modalitaSelezione: z.string({ required_error: 'required' }).optional(),
  attoIndizione: z.string({ required_error: 'required' }).optional(),
  tipoContratto: z.string({ required_error: 'required' }).optional(),
  areaProfessionale: z.string({ required_error: 'required' }).optional(),
  livello: z.string({ required_error: 'required' }).optional(),
  categoria: z.string({ required_error: 'required' }).optional(),
  dataInvioDocumenti: z.coerce.date().optional(),
  note: z.string({ required_error: 'required' }).optional(),
});

const InserimentoCandidato = () => {
  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={'Nuovo candidato'} />
      <TrovaCandidato />
    </div>
  );
};

export default InserimentoCandidato;