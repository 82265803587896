import {
  DropdownMenu,
  DropdownToggle,
  Header,
  HeaderBrand,
  HeaderContent,
  HeaderRightZone,
  Icon,
  LinkList,
  LinkListItem,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { showLoader } from '../../../store/loaderSlice';
import { switchRuoloAttivo } from '../../../store/userSlice';
import {
  getUserFromCookieAuUserToken,
  deleteJwtToken,
  saveTokenFromInput,
} from '../../../utility/cookie';
import { JWTModel } from '../../auth/jwtModel';
import { HREF_SCRIVANIA_VIRTUALE } from '../sidemenu/sidemenuConstants';

function HeaderSlim() {
  const { userRoleActive } = useAppSelector((state) => state.user);
  const userResult: JWTModel | null | undefined = getUserFromCookieAuUserToken();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = () => {
    deleteJwtToken();

    // Creazione di un form nascosto per il logout
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = process.env.REACT_APP_MS_AU_LOGOUT_URL || ''; // URL di logout su WSO2 Identity Server

    // Aggiungi il form alla pagina e invialo
    document.body.appendChild(form);
    form.submit();
  };

  const attivaRuolo = (ruolo: any) => {
    dispatch(switchRuoloAttivo(ruolo.id))
      .unwrap()
      .then((resp) => {
        if (resp && resp.status == 200) {
          saveTokenFromInput(resp.headers['x-au-user-token']);
          navigate(HREF_SCRIVANIA_VIRTUALE);
          window.location.reload();
        } else {
          deleteJwtToken();
          window.location.href = process.env.REACT_APP_PUBLIC_SESSIONE_SCADUTA || '';
        }
      });
  };

  const login = () => {
    dispatch(showLoader());
    window.location.href = process.env.REACT_APP_MS_AU_LOGIN_URL || '';
    return;
  };

  return (
    <Header theme="" type="slim">
      <HeaderContent>
        <HeaderBrand responsive>ARPAL Puglia</HeaderBrand>

        {!!userResult?.userInfo.nome && !!userResult?.userInfo.cognome && (
          <HeaderRightZone>
            <UncontrolledDropdown>
              <DropdownToggle caret nav>
                <Icon color="primary" icon="it-user" className="mr-1 icon-user" />
                {userResult?.userInfo.nome + ' ' + userResult?.userInfo.cognome}
                <Icon className="d-lg-block" color="icon-white" icon="it-expand" />
              </DropdownToggle>
              <DropdownMenu>
                <div className="menu-info">
                  <span>Codice Fiscale: {userResult?.userInfo.cf}</span>
                  <br />
                  <p>{userResult?.userInfo.tipoUtente}</p>
                </div>
                <LinkList>
                  <LinkListItem divider />
                  {userResult?.userInfo.ruoli.map((resp: any, index: number) =>
                    resp.attivo ? (
                      <LinkListItem key={index}>
                        <span>{resp.nome}</span>
                        {userRoleActive != null && resp.id === userRoleActive.id && (
                          <Icon icon="it-check" color="primary" />
                        )}
                      </LinkListItem>
                    ) : (
                      <LinkListItem key={index} onClick={() => attivaRuolo(resp)}>
                        <span>{resp.nome}</span>
                        {userRoleActive != null && resp.id === userRoleActive.id && (
                          <Icon icon="it-check" color="primary" />
                        )}
                      </LinkListItem>
                    )
                  )}
                  <LinkListItem divider />
                  <LinkListItem onClick={logout}>
                    <span>Esci</span>
                  </LinkListItem>
                </LinkList>
              </DropdownMenu>
            </UncontrolledDropdown>
          </HeaderRightZone>
        )}

        {!userResult?.userInfo && (
          <HeaderRightZone>
            <DropdownToggle disabled caret nav>
              <Icon color="primary" icon="it-user" className="mr-1 icon-user" />
              <span onClick={login}> Accedi all'area personale</span>
              <Icon className="d-lg-block" color="icon-white" icon="it-expand" />
            </DropdownToggle>
          </HeaderRightZone>
        )}
      </HeaderContent>
    </Header>
  );
}

export default HeaderSlim;
