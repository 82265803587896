import axios from 'axios';
import { DocumentoUploaded } from 'components/crea-candidato-form/types';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';
import { NOTIFICA_STATO } from 'utility/Notifica';

const useUploadFiles = () => {
  const dispatch = useAppDispatch();

  const uploadFiles = async ({
    files,
    tipoDocumento,
    nomeFiles,
  }: {
    files?: File[];
    tipoDocumento: string;
    nomeFiles: string;
  }) => {
    if (!files || files.length === 0) {
      dispatch(
        showNotifica({
          titolo: `Files ${nomeFiles} non inseriti`,
          stato: 'error',
          messaggio: `I ${nomeFiles} non sono stati inseriti`,
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }
    const uploadedFiles: DocumentoUploaded[] = [];

    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        const {
          data: {
            id,
            contentType,
            dataInserimento,
            dimensione,
            path,
            filename,
            nomeDocumento,
            stato,
          },
        } = await axios.post(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/documenti`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        uploadedFiles.push({
          tipo: tipoDocumento,
          descrizioneDocumento: '',
          documentoDto: {
            id,
            dataInserimento,
            dimensione,
            path,
            filename,
            nomeDocumento,
            stato,
            contentType,
          },
        });
      }

      return uploadedFiles;
    } catch (error) {
      dispatch(
        showNotifica({
          titolo: `Files ${nomeFiles} non inseriti`,
          stato: 'error',
          messaggio: `I ${nomeFiles} non sono stati inseriti`,
          tipo: NOTIFICA_STATO.error,
        })
      );
      return;
    }
  };

  return uploadFiles;
};

export default useUploadFiles;
