import { useEffect, useState } from 'react';
import PageHeader from '../../../components/common/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Button,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  LinkList,
  LinkListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  searchElencoBandiPO,
  reset,
  getElencoBandiPoContestoForm,
  deleteBando,
  riportaInLavorazioneBando,
  getDownloadExportBandi,
} from '../../../store/bandoSlice';
import {
  BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest,
  BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest,
  BandoPoDto,
  RuoloUtenteAutenticatoDto,
} from '../../../services/ms-anagrafica-unica';
import CustomSelect, {
  CustomSelectItem,
} from '../../../components/common/custom-select/CustomSelect';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import Pagination from '../../../components/common/pagination/Pagination';
import { HREF_ELENCO_ASSEGNAZIONI } from '../../../components/layout/sidemenu/sidemenuConstants';
import { HREF_MODIFICA_BANDO } from '../../../components/layout/sidemenu/sidemenuConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utility/formatDate';
import { checkCampiRicercaValorizzati } from 'utility/utility';
import TableTheadCustom from 'components/common/custom-table/table-thead-custom';

function ElencoBandi(props: { operations: RuoloUtenteAutenticatoDto }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [formCollapseOpen, setFormCollapseOpen] = useState<boolean>(false);
  const [statiOptions, setStatiOptions] = useState<CustomSelectItem[]>([]);
  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
  const [bando, setBando] = useState<string>();
  const [isOpenElimininaBando, toggleModalElimininaBando] = useState(false);
  const [isOpenRiportaInLavorazioneBando, toggleModalRiportaInLavorazioneBando] = useState(false);

  const { searchElencoBandiStatus, searchElencoBandiResult, searchElencoBandiInput } =
    useAppSelector((state) => state.bando);

  useEffect(() => {
    dispatch(reset());
    dispatch(getElencoBandiPoContestoForm())
      .unwrap()
      .then((resp) => {
        if (resp) {
          const selectItems: Array<CustomSelectItem> = [];
          resp.stato &&
            resp.stato.forEach((f: any) => {
              selectItems.push({
                value: f.valore,
                label: f.label,
              });
            });
          setStatiOptions(selectItems);
        }
      });
    doSearch();
  }, []);

  type Direction = 'ASC' | 'DESC';

  type downloadType = 'RIEPILOGO_ASSEGNAZIONI' | 'RIEPILOGO_CANDIDATURE';

  interface SortingState {
    direction: Direction;
  }
  let initialValues: BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest = (location.state
    ?.form &&
    searchElencoBandiInput) || {
    so: '',
    eq: '',
    attoCifra: '',
    dataApertura: '',
    dataChiusura: '',
    stato: undefined,
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: '',
  };

  useEffect(() => {
    setFormCollapseOpen(checkCampiRicercaValorizzati(searchElencoBandiInput));
  }, [searchElencoBandiInput]);

  const schema = z
    .object({
      so: z.string().optional(),
      eq: z.string().optional(),
      attoCifra: z.string().optional(),
      denominazionePo: z.string().optional(),
      dataApertura: z.coerce.date().optional(),
      dataChiusura: z.coerce.date().optional(),
      stato: z.string().optional(),
    })
    .refine((data) => {
      if (!data.dataApertura || !data.dataChiusura) {
        return true;
      } else if (data.dataApertura > data.dataChiusura) {
        return {
          message: 'La data di apertura deve essere precedente alla data di chiusura',
          path: ['dataChiusura'],
        };
      }
    });
  /*
    !!data && !!data.dataApertura?.getTime() > !!data.dataChiusura?.getTime(),
    {
        message: "Data di chiusura non può essere minore della data di apertura",
        path: ["dataChiusura"], // path of error
    }
    */

  const doSearch = () => {
    dispatch(searchElencoBandiPO(searchForm.values));
  };

  const searchForm = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: () => {
      doSearch();
    },
  });

  const getFieldError = (
    searchForm: FormikProps<BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest>,
    fieldName: keyof BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest
  ): string => {
    if (searchForm.getFieldMeta(fieldName).touched) {
      return searchForm.errors[fieldName] || '';
    } else return '';
  };

  const handleChangeStato = (selectedOption: any) => {
    searchForm.setFieldValue('stato', selectedOption);
    searchForm.handleChange(selectedOption);
  };

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const showCancellaOperation = (bando: BandoPoDto) => {
    if (bando.stato === 'IN_LAVORAZIONE') {
      return true;
    }
    return false;
  };

  const showRiportaInLavorazione = (bando: BandoPoDto) => {
    if (bando.stato === 'IN_PUBBLICAZIONE') {
      return true;
    }
    return false;
  };

  const showVisualizzaAssegnazioni = (bando: BandoPoDto) =>
    props.operations.elencoFunzionalita?.includes('BANDO_PO_ELENCO_ASSEGNAZIONI') ||
    props.operations.elencoFunzionalita?.includes('BANDO_PO_ACCESSO_COMPLETO');

  const showVisualizzaCandidature = (bando: BandoPoDto) =>
    props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE') &&
    (bando.stato === 'APERTO' || bando.stato === 'CHIUSO');

  const showModificaBando = (bando: BandoPoDto) => {
    return bando.stato === 'IN_LAVORAZIONE';
  };

  const confermaEliminazione = (id: string) => {
    setBando(id);
    toggleModalElimininaBando(!isOpenElimininaBando);
  };

  const confermaRiportaInLavorazione = (id: string) => {
    setBando(id);
    toggleModalRiportaInLavorazioneBando(!isOpenElimininaBando);
  };

  const eliminaBando = () => {
    dispatch(deleteBando(bando!))
      .unwrap()
      .then((resp) => {
        resp === 200 && doSearch();
      });
  };

  const riportaInLavorazione = () => {
    dispatch(riportaInLavorazioneBando(bando!))
      .unwrap()
      .then((resp) => {
        resp === 200 && doSearch();
      });
  };

  const handlePageChange = (pageNum: number) => {
    console.log('Handle page change', pageNum - 1);
    searchForm.setFieldValue('pageNum', pageNum - 1);
    searchForm.handleSubmit();
  };

  const resetSearchForm = () => {
    searchForm.setFieldValue('dataApertura', '');
    searchForm.setFieldValue('dataChiusura', '');
    searchForm.setFieldValue('so', '');
    searchForm.setFieldValue('eq', '');
    searchForm.setFieldValue('attoCifra', '');
    searchForm.setFieldValue('stato', undefined);
  };

  const callgetExportElencoBandi = (
    tipo: downloadType,
    args: BandiPosizioniOrganizzativeControllerApiElencoBandiPoRequest
  ) => {
    let param: BandiPosizioniOrganizzativeControllerApiDownloadExportBandiRequest = {
      tipo: tipo,
      attoCifra: args.attoCifra,
      dataApertura: args.dataApertura,
      dataChiusura: args.dataChiusura,
      direction: args.direction,
      eq: args.eq,
      pageNum: args.pageNum,
      pageSize: args.pageSize,
      q: args.q,
      so: args.so,
      sort: args.sort,
      stato: args.stato,
    };
    dispatch(getDownloadExportBandi(param))
      .unwrap()
      .then((resp) => {
        if (!!resp) {
          const blob = new Blob([resp], { type: 'application/vnd.ms-excel' });
          let url;
          const link = document.createElement('a');
          url = URL.createObjectURL(blob);
          link.href = url;
          link.download = tipo == 'RIEPILOGO_ASSEGNAZIONI' ? `ElencoBandi` : `ElencoCandidature`;
          link.click();
        }
      });
  };

  const showButtons = () => {
    return props.operations.elencoFunzionalita?.includes('BANDO_PO_REPORT') ||
      props.operations.elencoFunzionalita?.includes('BANDO_PO_ACCESSO_COMPLETO')
      ? [
          {
            buttonProperties: { outline: true },
            title: 'Scarica elenco bandi',
            buttonColor: 'primary',
            action: () => callgetExportElencoBandi('RIEPILOGO_ASSEGNAZIONI', initialValues),
            showButtonIcon: true,
            icon: 'it-download',
            iconColor: 'primary',
          },
          {
            buttonProperties: { outline: true },
            title: 'Scarica candidature',
            buttonColor: 'primary',
            action: () => callgetExportElencoBandi('RIEPILOGO_CANDIDATURE', initialValues),
            showButtonIcon: true,
            icon: 'it-download',
            iconColor: 'primary',
          },
        ]
      : [];
  };

  return (
    <div className="elenco-bandi">
      <PageHeader showIcon={false} title={t('Elenco bandi EQ')!} buttons={showButtons()} />
      <Accordion className="form-custom form-bg filtri-ricerca" background="active">
        <AccordionHeader
          active={formCollapseOpen}
          onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
        >
          {t('filtri di ricerca')}
        </AccordionHeader>
        <AccordionBody active={formCollapseOpen}>
          <div className="form-row mt-2">
            <Input
              type="hidden"
              name="pageNum"
              value={searchForm.values.pageNum}
              onChange={searchForm.handleChange}
            />
            <Input
              type="text"
              placeholder="Nome o codice della Struttura Organizzativa"
              id="so"
              wrapperClassName="form-group col-md-6"
              name="so"
              value={searchForm.values.so}
              onChange={searchForm.handleChange}
              invalid={!!searchForm.errors.so}
              infoText={t(searchForm.errors.so || '') || ''}
            />
            <Input
              type="text"
              placeholder="Nome o codice della EQ"
              id="eq"
              wrapperClassName="form-group col-md-6"
              name="eq"
              value={searchForm.values.eq}
              onChange={searchForm.handleChange}
              invalid={!!searchForm.errors.eq}
              infoText={t(searchForm.errors.eq || '') || ''}
            />
            <Input
              type="text"
              placeholder="Inserisci il codice cifra"
              id="attoCifra"
              wrapperClassName="form-group col-md-6"
              name="attoCifra"
              value={searchForm.values.attoCifra}
              onChange={searchForm.handleChange}
              invalid={!!searchForm.errors.attoCifra}
              infoText={t(searchForm.errors.attoCifra || '') || ''}
            />
            <CustomSelect
              options={statiOptions}
              value={searchForm.values.stato}
              placeholder="Seleziona lo stato del bando"
              onChange={handleChangeStato}
              name="stato"
              wrapperClass="form-group col-md-6"
            />
            <Row className="col-12 mt-2">
              <Input
                type="date"
                label="Inserisci la data di apertura"
                placeholder="Inserisci la data di apertura"
                id="input-dataApertura"
                wrapperClassName="form-group col-md-4"
                name="dataApertura"
                value={searchForm.values.dataApertura}
                // disabled={(detailsResult?.anagraficaUnica?.dataNascita != null && detailsResult?.anagraficaUnica?.dataNascita != "") || candidatoValidato}
                onChange={searchForm.handleChange}
                invalid={!!getFieldError(searchForm, 'dataApertura')}
                infoText={t(getFieldError(searchForm, 'dataApertura')) || ''}
              />
              <Input
                type="date"
                label="Inserisci la data di chiusura"
                placeholder="Inserisci la data di chiusura"
                id="input-dataChiusura"
                wrapperClassName="form-group col-md-4"
                name="dataChiusura"
                value={searchForm.values.dataChiusura}
                // disabled={(detailsResult?.anagraficaUnica?.dataNascita != null && detailsResult?.anagraficaUnica?.dataNascita != "") || candidatoValidato}
                onChange={searchForm.handleChange}
                invalid={!!getFieldError(searchForm, 'dataChiusura')}
                infoText={t(getFieldError(searchForm, 'dataChiusura')) || ''}
              />
              <div className="d-flex flex-row-reverse col-4">
                <Button
                  color="primary"
                  className="mt-2"
                  onClick={() => {
                    searchForm.setFieldValue('pageNum', 0);
                    searchForm.handleSubmit();
                  }}
                >
                  Applica
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mt-2 mr-2"
                  onClick={() => resetSearchForm()}
                >
                  Pulisci
                </Button>
              </div>
            </Row>
          </div>
        </AccordionBody>
      </Accordion>

      <div className="mt-4 position-relative">
        {searchElencoBandiStatus === STATUS_FULLFILLED &&
          searchElencoBandiResult?.totalElements === 0 && (
            <Alert color="info">Nessun risultato disponibile</Alert>
          )}
        {searchElencoBandiStatus === STATUS_FULLFILLED &&
          searchElencoBandiResult?.totalElements! > 0 && (
            <div className={searchElencoBandiResult?.totalPages === 1 ? 'mb-5' : ''}>
              <Table responsive striped size="sm">
                <TableTheadCustom
                  colonne={[
                    { titolo: 'Bando', nomeColonna: 'nome' },
                    { titolo: 'Identificativo', nomeColonna: 'codiceUnivoco' },
                    { titolo: 'Struttura organizzativa' },
                    { titolo: 'Data apertura', nomeColonna: 'dataApertura' },
                    { titolo: 'Data chiusura', nomeColonna: 'dataChiusura' },
                    { titolo: 'Stato', nomeColonna: 'stato' },
                    { titolo: 'Azioni' },
                  ]}
                  datiOrdinamento={searchForm.values}
                  eseguiOrdinamento={ordinamento}
                />

                <tbody>
                  {(searchElencoBandiResult?.data || []).map((x, i) => (
                    <tr key={i} className={x.stato === 'TERMINATO' ? 'finished' : ''}>
                      <td>{x.nome}</td>
                      <td>{x.codiceUnivoco}</td>
                      <td>{x.strutturaOrganizzativa}</td>
                      <td>{formatDate(x.dataApertura!, true)}</td>
                      <td>{formatDate(x.dataChiusura!, true)}</td>
                      <td>{x.descrizioneStato}</td>
                      <td>
                        <UncontrolledDropdown direction="left">
                          <DropdownToggle nav>
                            <Icon icon="it-settings" color="primary" size="sm" />
                          </DropdownToggle>
                          <DropdownMenu className="no-arrow">
                            <LinkList>
                              {showCancellaOperation(x) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() => confermaEliminazione(x.codiceUnivoco!)}
                                >
                                  <span>Elimina</span>
                                </LinkListItem>
                              )}
                              {showRiportaInLavorazione(x) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() => confermaRiportaInLavorazione(x.codiceUnivoco!)}
                                >
                                  <span>{t('Riporta in lavorazione')}</span>
                                </LinkListItem>
                              )}
                              {showVisualizzaAssegnazioni(x) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(`${HREF_ELENCO_ASSEGNAZIONI}/${x.codiceUnivoco}`)
                                  }
                                >
                                  <span>{t('Dettaglio bando e assegnazioni')}</span>
                                </LinkListItem>
                              )}
                              {showModificaBando(x) && (
                                <LinkListItem
                                  size="medium"
                                  onClick={() =>
                                    navigate(`${HREF_MODIFICA_BANDO}/${x.codiceUnivoco}`)
                                  }
                                >
                                  <span>Modifica</span>
                                </LinkListItem>
                              )}
                            </LinkList>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                totalCount={searchElencoBandiResult?.totalElements || 0}
                siblingCount={1}
                currentPage={searchForm.values.pageNum! + 1}
                pageSize={searchElencoBandiResult?.pageSize || 10}
                onPageChange={(page: number) => {
                  handlePageChange(page);
                }}
                className="justify-content-center"
              />
            </div>
          )}
      </div>
      <Modal
        isOpen={isOpenElimininaBando}
        toggle={() => toggleModalElimininaBando(!isOpenElimininaBando)}
        labelledBy="eliminaBando"
        centered
      >
        <ModalHeader
          toggle={() => {
            toggleModalElimininaBando(!isOpenElimininaBando);
            setBando(undefined);
          }}
          id="eliminaBando"
        >
          {t('Elimina bando')}
        </ModalHeader>
        <ModalBody>{bando && t('conferma eliminazione bando')}</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggleModalElimininaBando(!isOpenElimininaBando);
              setBando(undefined);
            }}
          >
            {t('Annulla')}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleModalElimininaBando(!isOpenElimininaBando);
              eliminaBando();
            }}
          >
            {t('Conferma')}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isOpenRiportaInLavorazioneBando}
        toggle={() => toggleModalRiportaInLavorazioneBando(!isOpenRiportaInLavorazioneBando)}
        labelledBy="riportaInLavorazione"
        centered
      >
        <ModalHeader
          toggle={() => {
            toggleModalRiportaInLavorazioneBando(!isOpenRiportaInLavorazioneBando);
            setBando(undefined);
          }}
          id="riportaInLavorazione"
        >
          {t('Riporta in lavorazione')}
        </ModalHeader>
        <ModalBody>{bando && t('conferma riporta in lavorazione')}</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggleModalRiportaInLavorazioneBando(!isOpenRiportaInLavorazioneBando);
              setBando(undefined);
            }}
          >
            {t('Annulla')}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleModalRiportaInLavorazioneBando(!isOpenRiportaInLavorazioneBando);
              riportaInLavorazione();
            }}
          >
            {t('Conferma')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ElencoBandi;
