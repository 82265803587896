import FormDatiAnagrafici from '../../components/FormDatiAnagrafici/FormDatiAnagrafici';
import FormDatiResidenza from '../../components/FormDatiResidenza/FormDatiResidenza';
import FormDomicilio from '../../components/FormDomicilio/FormDomicilio';
import FormEstremiIdentificazione from '../../components/FormEstremiIndetificazione/FormEstremiIndetificazione';
import FormRecapiti from '../../components/FormRecapiti/FormRecapiti';
import FormDatiConcorso from '../../components/forms/FormDatiConcorso/FormDatiConcorso';
import FormDatiDisabilita from '../../components/forms/FormDatiDisabilita.tsx/FormDatiDisabilita';
import FormTitoloDiStudio from '../../components/forms/FormTitoloDiStudio/FormTitoloDiStudio';
import PageHeader from '../../components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from '../../components/sidemenu/sidemenuConstants';
import { FormVerificaAltriDati } from 'components/forms/FormVerificaAltriDati/FormVerificaAltriDati';
import FormCv from 'components/FormCv/FormCv';
import FormNote from 'components/FormNote/FormNote';

const VerificaAltriDati = () => {
  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={'Verifica dati'} />
      <FormVerificaAltriDati>
        <FormDatiAnagrafici readingOnly />
        <FormEstremiIdentificazione readingOnly />
        <FormRecapiti readingOnly />
        <FormDatiResidenza readingOnly />
        <FormDomicilio readingOnly />
        <FormDatiDisabilita readingOnly />
        <FormDatiConcorso readingOnly />
        <FormTitoloDiStudio readingOnly />
        <FormCv readingOnly />
        <FormNote readingOnly />
      </FormVerificaAltriDati>
    </div>
  );
};

export default VerificaAltriDati;
