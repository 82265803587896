/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CurriculumVitaeDto } from '../model';
// @ts-ignore
import { CurriculumVitaeRequestDto } from '../model';
/**
 * GestioneCVControllerApi - axios parameter creator
 * @export
 */
export const GestioneCVControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * API Rest per il dettaglio di un CV
     * @summary Dettaglio CV
     * @param {number} idCid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettaglioCV: async (idCid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idCid' is not null or undefined
      assertParamExists('dettaglioCV', 'idCid', idCid);
      const localVarPath = `/v1/gestione-cv/{idCid}`.replace(
        `{${'idCid'}}`,
        encodeURIComponent(String(idCid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per il download di un CV
     * @summary Download CV
     * @param {number} idCid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadCV: async (idCid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idCid' is not null or undefined
      assertParamExists('downloadCV', 'idCid', idCid);
      const localVarPath = `/v1/gestione-cv/{idCid}`.replace(
        `{${'idCid'}}`,
        encodeURIComponent(String(idCid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API Rest per l\'inserimento o la modifica di un CV
     * @summary Inserimento CV
     * @param {CurriculumVitaeRequestDto} curriculumVitaeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putCv: async (
      curriculumVitaeRequestDto: CurriculumVitaeRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'curriculumVitaeRequestDto' is not null or undefined
      assertParamExists('putCv', 'curriculumVitaeRequestDto', curriculumVitaeRequestDto);
      const localVarPath = `/v1/gestione-cv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        curriculumVitaeRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GestioneCVControllerApi - functional programming interface
 * @export
 */
export const GestioneCVControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GestioneCVControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * API Rest per il dettaglio di un CV
     * @summary Dettaglio CV
     * @param {number} idCid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dettaglioCV(
      idCid: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurriculumVitaeDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioCV(idCid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per il download di un CV
     * @summary Download CV
     * @param {number} idCid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadCV(
      idCid: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCV(idCid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API Rest per l\'inserimento o la modifica di un CV
     * @summary Inserimento CV
     * @param {CurriculumVitaeRequestDto} curriculumVitaeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putCv(
      curriculumVitaeRequestDto: CurriculumVitaeRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurriculumVitaeDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putCv(
        curriculumVitaeRequestDto,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GestioneCVControllerApi - factory interface
 * @export
 */
export const GestioneCVControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GestioneCVControllerApiFp(configuration);
  return {
    /**
     * API Rest per il dettaglio di un CV
     * @summary Dettaglio CV
     * @param {GestioneCVControllerApiDettaglioCVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dettaglioCV(
      requestParameters: GestioneCVControllerApiDettaglioCVRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<CurriculumVitaeDto> {
      return localVarFp
        .dettaglioCV(requestParameters.idCid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per il download di un CV
     * @summary Download CV
     * @param {GestioneCVControllerApiDownloadCVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadCV(
      requestParameters: GestioneCVControllerApiDownloadCVRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<File> {
      return localVarFp
        .downloadCV(requestParameters.idCid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API Rest per l\'inserimento o la modifica di un CV
     * @summary Inserimento CV
     * @param {GestioneCVControllerApiPutCvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putCv(
      requestParameters: GestioneCVControllerApiPutCvRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<CurriculumVitaeDto> {
      return localVarFp
        .putCv(requestParameters.curriculumVitaeRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for dettaglioCV operation in GestioneCVControllerApi.
 * @export
 * @interface GestioneCVControllerApiDettaglioCVRequest
 */
export interface GestioneCVControllerApiDettaglioCVRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneCVControllerApiDettaglioCV
   */
  readonly idCid: number;
}

/**
 * Request parameters for downloadCV operation in GestioneCVControllerApi.
 * @export
 * @interface GestioneCVControllerApiDownloadCVRequest
 */
export interface GestioneCVControllerApiDownloadCVRequest {
  /**
   *
   * @type {number}
   * @memberof GestioneCVControllerApiDownloadCV
   */
  readonly idCid: number;
}

/**
 * Request parameters for putCv operation in GestioneCVControllerApi.
 * @export
 * @interface GestioneCVControllerApiPutCvRequest
 */
export interface GestioneCVControllerApiPutCvRequest {
  /**
   *
   * @type {CurriculumVitaeRequestDto}
   * @memberof GestioneCVControllerApiPutCv
   */
  readonly curriculumVitaeRequestDto: CurriculumVitaeRequestDto;
}

/**
 * GestioneCVControllerApi - object-oriented interface
 * @export
 * @class GestioneCVControllerApi
 * @extends {BaseAPI}
 */
export class GestioneCVControllerApi extends BaseAPI {
  /**
   * API Rest per il dettaglio di un CV
   * @summary Dettaglio CV
   * @param {GestioneCVControllerApiDettaglioCVRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneCVControllerApi
   */
  public dettaglioCV(
    requestParameters: GestioneCVControllerApiDettaglioCVRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneCVControllerApiFp(this.configuration)
      .dettaglioCV(requestParameters.idCid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per il download di un CV
   * @summary Download CV
   * @param {GestioneCVControllerApiDownloadCVRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneCVControllerApi
   */
  public downloadCV(
    requestParameters: GestioneCVControllerApiDownloadCVRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneCVControllerApiFp(this.configuration)
      .downloadCV(requestParameters.idCid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API Rest per l\'inserimento o la modifica di un CV
   * @summary Inserimento CV
   * @param {GestioneCVControllerApiPutCvRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GestioneCVControllerApi
   */
  public putCv(
    requestParameters: GestioneCVControllerApiPutCvRequest,
    options?: AxiosRequestConfig
  ) {
    return GestioneCVControllerApiFp(this.configuration)
      .putCv(requestParameters.curriculumVitaeRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
