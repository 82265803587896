import {
  RuoloUtenteAutenticatoDto,
  StrutturaOrganizzativaDto,
  StrutturaOrganizzativaDtoTipoEnum,
  ValutazionePropostaIncaDirigRequestDto,
} from '../../services/ms-anagrafica-unica';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/layout/sidemenu/sidemenuConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getPropostaIncarico,
  getValutazioniPropostaIncarico,
  insertValutazionePropostaIncarico,
  updateValutazionePropostaIncarico,
} from '../../store/proposteIncarichiSlice';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Input, Label, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import PageLoader from '../../components/common/page-loader/PageLoader';
import CustomAlert from '../../components/common/custom-alert/CustomAlert';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import NotificationManagerComponent from '../../components/notification/NotificationManagerComponent';
import { DettaglioPropostaIncarico } from 'components/incarichi-dirigenziali/DettaglioPropostaIncarico';

export interface SODetailsForm {
  value: StrutturaOrganizzativaDto;
  fields: Array<string>;
}

const FORCE_PROTOCOLLA = true;

const ValutazioneProposta = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    searchValutazioniStatus,
    searchValutazioniResult,
    propostaSelezionataResult,
    propostaSelezionataStatus,
  } = useAppSelector((state) => state.proposteIncarichi);
  const insertStatus = useAppSelector((state) => state.proposteIncarichi.insertValutazioneStatus);
  const patchStatus = useAppSelector((state) => state.proposteIncarichi.updateValutazioneStatus);

  const [tipoSalvataggio, setTipoSalvataggio] = useState('');
  const [, setStatoValutazione] = useState<string | undefined>(undefined);

  const note = (): string => {
    if (
      searchValutazioniResult &&
      searchValutazioniResult?.note &&
      typeof searchValutazioniResult.note !== 'undefined' &&
      searchValutazioniResult.note !== null
    ) {
      return searchValutazioniResult.note;
    }
    return '';
  };

  const initialValues: ValutazionePropostaIncaDirigRequestDto = {
    esitoValutazioneProposta: searchValutazioniResult?.esitoValutazioneProposta,
    note: searchValutazioniResult?.note,
    idDocumento: searchValutazioniResult?.documentiDto?.at(0)?.documento?.id,
  };

  const schemaSalva = z.object({
    note: z.string().max(1500).optional(),
    esitoValutazioneProposta: z.string().optional(),
    idDocumento: z.number().optional(),
  });

  const schemaConferma = z
    .object({
      note: z.string().max(1500).optional(),
      esitoValutazioneProposta: z.enum(['ACCETTATA', 'RIGETTATA']),
      idDocumento: z.number().optional(),
    })
    .refine((data) => data.esitoValutazioneProposta === 'RIGETTATA' || data.idDocumento, {
      message: 'Il campo DGR è obbligatorio',
      path: ['idDocumento'],
    });

  const validationSchema =
    tipoSalvataggio === 'CONFERMA'
      ? toFormikValidationSchema(schemaConferma)
      : toFormikValidationSchema(schemaSalva);

  const formik: FormikProps<ValutazionePropostaIncaDirigRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      if (propostaSelezionataResult?.codiceUnivoco) {
        // INSERT VALUTAZIONE
        !searchValutazioniResult?.codiceUnivoco &&
          dispatch(
            insertValutazionePropostaIncarico({
              id: propostaSelezionataResult?.codiceUnivoco,
              valutazionePropostaIncaDirigRequestDto: values,
            })
          );
        // UPDATE VALUTAZIONE
        searchValutazioniResult?.codiceUnivoco &&
          dispatch(
            updateValutazionePropostaIncarico({
              id: propostaSelezionataResult?.codiceUnivoco,
              valutazionePropostaIncaDirigRequestDto: values,
            })
          );
      }
    },
  });

  useEffect(() => {
    dispatch(getPropostaIncarico({ id: Number(id) }))
      .unwrap()
      .then((resp) => {
        if (resp) {
          getValutazioneValutazioneProposta();
        }
      });
  }, []);

  useEffect(() => {
    formik.setValues({
      esitoValutazioneProposta: searchValutazioniResult
        ? searchValutazioniResult.esitoValutazioneProposta
        : undefined,
      note: note(),
      idDocumento: searchValutazioniResult
        ? searchValutazioniResult.documentiDto?.at(0)?.documento?.id
        : undefined,
    });
  }, [searchValutazioniResult]);

  const getValutazioneValutazioneProposta = () => {
    dispatch(getValutazioniPropostaIncarico({ id: Number(id) }));
  };

  const retryButtonCallbackValutazioneProposta = () => {
    getValutazioneValutazioneProposta();
  };

  const setRadioButtonValutazioneProposta = (fieldName: string, value: string | undefined) => {
    console.log(fieldName, value);
    formik.setFieldValue(fieldName, value, false);
    setStatoValutazione(value);
  };

  const getFieldErrorValutazioneProposta = (
    form: FormikProps<ValutazionePropostaIncaDirigRequestDto>,
    fieldName: keyof ValutazionePropostaIncaDirigRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const getTitleUploadValutazioneProposta = () => {
    return propostaSelezionataResult?.tipoStrutturaOrganizzativa ===
      StrutturaOrganizzativaDtoTipoEnum.Servizio
      ? t('Determina dirigenziale')
      : t('DGR');
  };

  const setDocumentIdValutazioneProposta = (name: string, id: number | undefined) => {
    if (id) {
      formik.setFieldValue(name, id, false);
    } else {
      console.error('errore durante la chiamata di caricamento documento');
    }
  };

  const submitConfirmValutazioneProposta = () => {
    formik.setFieldValue('requestType', 'CONFERMA');
    !formik.values.esitoValutazioneProposta && formik.setFieldValue('esitoValutazioneProposta', '');
    !formik.values.idDocumento && formik.setFieldValue('idDocumento', undefined);
    !formik.values.note && formik.setFieldValue('note', '');
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  const submitSaveValutazioneProposta = () => {
    formik.setFieldValue('requestType', 'SALVA');
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI}
        title={'valutazioneProposta.titolo'}
      />
      {(insertStatus === STATUS_REJECTED || patchStatus === STATUS_REJECTED) &&
        formik.isSubmitting && (
          <NotificationManagerComponent
            state="error"
            title="Errore"
            content="Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio"
          />
        )}
      {(insertStatus === STATUS_FULLFILLED || patchStatus === STATUS_FULLFILLED) &&
        formik.isSubmitting && (
          <NotificationManagerComponent
            state="success"
            title="Success"
            content="Salvataggio avvenuto con successo"
          />
        )}
      {searchValutazioniStatus === STATUS_REJECTED && (
        <CustomAlert
          color="danger"
          message="Siamo spiacenti, si &egrave; verificato un errore durante la richiesta"
          showButton={true}
          callback={retryButtonCallbackValutazioneProposta}
        />
      )}
      {propostaSelezionataStatus === STATUS_REJECTED && (
        <CustomAlert
          color="danger"
          message="Siamo spiacenti, si &egrave; verificato un errore durante la richiesta"
          showButton={true}
          callback={retryButtonCallbackValutazioneProposta}
        />
      )}
      {propostaSelezionataStatus === STATUS_FULLFILLED && !!propostaSelezionataResult && (
        <>
          <DettaglioPropostaIncarico
            propostaIncarico={propostaSelezionataResult}
            documenti={{ showDocumenti: true, download: true, view: true, protocolla: true }}
          />

          {/* {searchValutazioniStatus === STATUS_FULLFILLED && !!propostaSelezionataResult.nprotocolloUscita ? ( */}
          {FORCE_PROTOCOLLA ? (
            <div className="form-custom form-inserimento">
              <Col md="12" className="required">
                <label className="font-weight-500">Valutazione proposta</label>
                <br />
                <FormGroup check inline className="mb-0">
                  <Input
                    name="esitoValutazione"
                    type="radio"
                    id="esitoValutazione"
                    value={'ACCETTATA'}
                    checked={formik.values.esitoValutazioneProposta === 'ACCETTATA'}
                    onChange={(e) =>
                      setRadioButtonValutazioneProposta('esitoValutazioneProposta', e.target.value)
                    }
                  />
                  <Label check htmlFor="esitoValutazione">
                    Accettata
                  </Label>
                </FormGroup>
                <FormGroup check inline className="mb-0">
                  <Input
                    name="esitoValutazione"
                    type="radio"
                    id="esitoValutazione1"
                    value={'RIGETTATA'}
                    checked={formik.values.esitoValutazioneProposta === 'RIGETTATA'}
                    onChange={(e) =>
                      setRadioButtonValutazioneProposta('esitoValutazioneProposta', e.target.value)
                    }
                  />
                  <Label check htmlFor="esitoValutazione1">
                    Rigettata
                  </Label>
                </FormGroup>
                {!!getFieldErrorValutazioneProposta(formik, 'esitoValutazioneProposta') && (
                  <small className="invalid-feedback form-text text-muted">
                    {t(getFieldErrorValutazioneProposta(formik, 'esitoValutazioneProposta'))}
                  </small>
                )}
              </Col>
              <Col className="mt-5 ">
                <TextArea
                  label="Note personale"
                  placeholder="Note valutazione"
                  id="input-note"
                  wrapperClassName={
                    formik.values.esitoValutazioneProposta === 'RIGETTATA'
                      ? 'form-group col-md-12 required'
                      : 'form-group col-md-12'
                  }
                  name="note"
                  disabled={!formik.values.esitoValutazioneProposta}
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  invalid={!!getFieldErrorValutazioneProposta(formik, 'note')}
                  infoText={t(getFieldErrorValutazioneProposta(formik, 'note')) || ''}
                />
              </Col>
              <DocumentUpload
                documentName={getTitleUploadValutazioneProposta()}
                className={`m-2 ${formik.values.esitoValutazioneProposta === 'ACCETTATA' ? 'required' : ''}`}
                invalid={!!getFieldErrorValutazioneProposta(formik, 'idDocumento')}
                infoText={t(getFieldErrorValutazioneProposta(formik, 'idDocumento')) || ''}
                idDocumento={formik.values.idDocumento}
                setDocumentId={(id) => setDocumentIdValutazioneProposta('idDocumento', id)}
              />
              <div className="d-flex flex-row-reverse">
                <Button
                  color="primary"
                  className="mt-2 mr-2"
                  onClick={() => {
                    submitConfirmValutazioneProposta();
                    setTipoSalvataggio('CONFERMA');
                  }}
                >
                  Conferma
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mt-2 mr-2"
                  onClick={() => {
                    submitSaveValutazioneProposta();
                    setTipoSalvataggio('SALVA');
                  }}
                >
                  Salva
                </Button>
                <Button
                  color="secondary"
                  className="mt-2 mr-2"
                  onClick={() => {
                    navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`);
                  }}
                >
                  {t('annulla')}{' '}
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row-reverse">
              <Button
                color="secondary"
                className="mt-2 mr-2"
                onClick={() => {
                  navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`);
                }}
              >
                {t('annulla')}{' '}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ValutazioneProposta;
