import {
  Input,
  Col,
  Button,
  Icon,
  Row,
  Toggle,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  DropdownMenu,
  DropdownToggle,
  LinkList,
  LinkListItem,
  ModalFooter,
  UncontrolledDropdown,
} from 'design-react-kit';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import AccordionElement from '../../accordion-element/AccordionElement';
import useConcorsiSelectOptions from '../../../hooks/useConcorsiSelectOptions';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import ExplodedPdfViewer from '../../exploded-pdf-viewer/ExplodedPdfViewer';
import { useCompositeForm } from '../../../hooks/useCompositeForm';
import CustomSelect from 'components/custom-select/CustomSelect';
import useGetConcorsi from 'hooks/services/useGetConcorsi';
import useDownloadAttiOnboardingPdfs from 'hooks/services/useDownloadAttiOnboardingPdfs';
import { AttoOnboardingConcorsoDto } from 'components/crea-candidato-form/types';
import { ErrorMessage } from 'components/error-message/ErrorMessage';

interface ITabellaAllegatiProps {
  items: AttoOnboardingConcorsoDto[];
  deleteFile: (fileIndex: number) => void;
}
const TabellaAllegati: React.FC<ITabellaAllegatiProps> = ({ items, deleteFile }) => {
  const concorsiOptions = useConcorsiSelectOptions();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const modalId = v4();
  const [pdfUrl, setPdfUrl] = useState<string[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);

  function viewFile(fileIndex: number) {
    setSelectedFileIndex(fileIndex);
    toggleModal();
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  useEffect(() => {
    setPdfUrl(items.map(({ file }) => URL.createObjectURL(file)));

    return () => pdfUrl.forEach((url) => URL.revokeObjectURL(url));
  }, [items, setPdfUrl]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th scope="col" style={{ width: '15%' }}>
              #
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Tipologia di atto di indizione
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Oggetto atto
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Data atto
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Numero atto
            </th>
            <th scope="col" style={{ width: '20%' }}>
              File pdf
            </th>
            <th scope="col" style={{ width: '20%' }} />
            <th scope="col" style={{ width: '20%' }} />
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {concorsiOptions?.tipoAtto.find((option) => option.value === item.tipoAtto)?.label}
              </td>
              <td>{item.oggettoAtto}</td>
              <td>{item.dataAtto}</td>
              <td>{item.numeroAtto}</td>
              <td>
                <UncontrolledDropdown direction="left">
                  <DropdownToggle nav>
                    <Icon size="sm" color="primary" icon="it-more-items" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <LinkList>
                      <LinkListItem onClick={() => viewFile(index)}>Visualizza</LinkListItem>
                      <LinkListItem onClick={() => deleteFile(index)}>Elimina</LinkListItem>
                    </LinkList>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={`modal-${modalId}`}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={`modal-${modalId}`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h2>Anteprima documento</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '70vh',
              overflowY: 'auto',
            }}
          >
            <ExplodedPdfViewer pdfFile={pdfUrl[selectedFileIndex]} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" outline className="link-underline-primary" onClick={toggleModal}>
            Annulla
          </Button>
          <Button color="primary" onClick={toggleModal}>
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

/* const initialValues = {
  idConcorso: undefined,
  areaProfessionale: undefined,
  livelli: undefined,
  vincitore: undefined,
  // TODO: fix Toggle component to default value true
  idoneo: false,
  posizioneGraduatoria: undefined,
  attiOnboarding: [],
}; */

export default function FormDatiConcorso({ readingOnly }: { readingOnly?: boolean }) {
  const [openModalAtto, setOpenModalAtto] = useState(false);
  const [isNuovoConcorso, setIsNuovoConcorso] = useState(false);

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const concorsiOptions = useConcorsiSelectOptions();
  const concorsi = useGetConcorsi();

  const { values, handleChange, handleBlur, setFieldValue, errors, touched } = useCompositeForm();

  useDownloadAttiOnboardingPdfs({
    onFilesReady: (files) => {
      setFieldValue('attiOnboarding', files);
    },
  });

  function deleteFile(fileIndex: number) {
    setFieldValue(
      'attiOnboarding',
      values.attiOnboarding.filter((_: any, index: number) => index !== fileIndex)
    );
  }

  const attiForm = useFormik({
    initialValues: {
      tipoAtto: '',
      oggettoAtto: '',
      dataAtto: '',
      numeroAtto: '',
      file: null,
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        tipoAtto: z.string({
          required_error: "Inserisci tipo d'atto",
        }),
        oggettoAtto: z
          .string({
            required_error: "Inserisci oggetto d'atto",
          })
          .max(50, { message: 'Massimo 50 caratteri' }),
        dataAtto: z.string({
          required_error: "Inserisci data d'atto",
        }),
        numeroAtto: z
          .string({
            required_error: "Inserisci numero d'atto",
          })
          .max(20, { message: 'Massimo 20 caratteri' }),
      })
    ),
    onSubmit: ({ tipoAtto, oggettoAtto, dataAtto, numeroAtto, file }) => {
      setFieldValue('attiOnboarding', [
        ...values.attiOnboarding,
        {
          tipoAtto,
          oggettoAtto,
          dataAtto,
          numeroAtto,
          file,
        },
      ]);
      setOpenModalAtto(false);
    },
  });

  const handleVincitoreChange = (event: any) => {
    setFieldValue('vincitore', event.target.checked);
  };
  const handleIdoneoChange = (event: any) => {
    setFieldValue('idoneo', event.target.checked);
  };
  const handleOpenModalAtto = () => {
    setOpenModalAtto(true);
  };
  const handleConcorsoChange = (event: any) => {
    setFieldValue('idConcorso', event);

    const concorso = concorsi?.find((concorso) => concorso.id === String(values.idConcorso));
    if (concorso) {
      setFieldValue('areaProfessionale', concorso.areaProfessionale);
      setFieldValue('livelli', concorso.livelli);
      setFieldValue('vincitore', concorso.vincitore);
      setFieldValue('idoneo', concorso.idoneo);
      setFieldValue('posizioneGraduatoria', concorso.posizioneGraduatoria);
    }
  };

  const handleNuovoConcorso = () => {
    setIsNuovoConcorso(!isNuovoConcorso);
  };

  return (
    <AccordionElement title="Dati Concorso">
      <div className="form-row mt-5">
        {concorsiOptions?.concorsi && !isNuovoConcorso && (
          <CustomSelect
            label="Nome Concorso"
            placeholder="Nome Concorso"
            options={
              concorsiOptions?.concorsi.map(({ value, label }) => ({
                value,
                label,
              })) || []
            }
            value={values.idConcorso}
            invalid={!!errors.idConcorso}
            infoText={touched.idConcorso && errors.idConcorso && errors.idConcorso}
            onChange={handleConcorsoChange}
            name="idConcorso"
            wrapperClass="col-md required"
            disabled={readingOnly}
          />
        )}
        {isNuovoConcorso && (
          <Input
            id="nomeNuovoConcorso"
            name="nomeNuovoConcorso"
            type="text"
            label="Nome Concorso"
            placeholder="Inserisci nome concorso"
            value={values.nomeNuovoConcorso}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={touched.nomeNuovoConcorso && !!errors.nomeNuovoConcorso}
            wrapperClassName="col-md required"
            // @ts-ignore
            infoText={
              touched.nomeNuovoConcorso && errors.nomeNuovoConcorso && errors.nomeNuovoConcorso
            }
          />
        )}
        <Col md={6} className="form-row form-group" style={{ paddingLeft: '22px' }}>
          <Button
            size="sm"
            color="primary"
            className="mt-2 mr-2"
            onClick={handleNuovoConcorso}
            // @ts-ignore
            disabled={values.idConcorso}
          >
            <Icon size="sm" color="white" icon="it-plus-circle" />
            <> Nuovo concorso</>
          </Button>
        </Col>
      </div>
      <div className="form-row">
        <Col md={6}>
          <CustomSelect
            name="areaProfessionale"
            label="Area Professionale"
            placeholder="Area Professionale"
            options={
              concorsiOptions?.areeProfessionali.map(({ value, label }) => ({
                value,
                label,
              })) || []
            }
            value={values.areaProfessionale}
            invalid={!!errors.areaProfessionale}
            infoText={errors.areaProfessionale}
            onChange={(e) => setFieldValue('areaProfessionale', e)}
            wrapperClass="col-md required"
            disabled={readingOnly}
          />
        </Col>
        <Col md={6}>
          <CustomSelect
            name="livelli"
            label="Posizione Economica"
            placeholder="Posizione Economica"
            options={
              concorsiOptions?.livelli.map(({ value, label }) => ({
                value,
                label,
              })) || []
            }
            value={values.livelli}
            invalid={!!errors.livelli}
            infoText={errors.livelli}
            onChange={(e) => setFieldValue('livelli', e)}
            wrapperClass="col-md required"
            disabled={readingOnly}
          />
        </Col>
      </div>
      <div className="form-row">
        <Toggle
          label="Vincitore"
          name="vincitore"
          // @ts-ignore
          value={values.vincitore}
          invalid={!!errors.vincitore}
          infoText={errors.vincitore}
          onChange={handleVincitoreChange}
        />
        <Toggle
          label="Idoneo"
          name="idoneo"
          disabled={values.vincitore || readingOnly}
          // @ts-ignore
          value={values.idoneo}
          invalid={!!errors.idoneo}
          infoText={errors.idoneo}
          onChange={handleIdoneoChange}
        />
        <Input
          label="Posizione in graduatoria"
          type="text"
          placeholder="Posizione in Graduatoria"
          id="input-posizioneGraduatoria"
          name="posizioneGraduatoria"
          wrapperClassName="form-group col col-md-6"
          value={values.posizioneGraduatoria}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={readingOnly}
          invalid={touched.posizioneGraduatoria && !!errors.posizioneGraduatoria}
          infoText={
            touched.posizioneGraduatoria &&
            errors.posizioneGraduatoria &&
            errors.posizioneGraduatoria
          }
        />
      </div>
      <div className="form-row">
        <Button size="sm" color="primary" onClick={handleOpenModalAtto} disabled={readingOnly}>
          <Icon color="white" size="sm" icon="it-plus-circle" />
          <> aggiungi atto</>
        </Button>
      </div>
      <ErrorMessage touched={touched.attiOnboarding} error={errors.attiOnboarding} />

      {values.attiOnboarding.length > 0 && (
        <Row>
          <Col md={12}>
            <TabellaAllegati deleteFile={deleteFile} items={values.attiOnboarding} />
          </Col>
        </Row>
      )}

      <Modal isOpen={openModalAtto} toggle={() => setOpenModalAtto(!openModalAtto)}>
        <ModalHeader toggle={() => setOpenModalAtto(!openModalAtto)}>
          Compila e carica il file
        </ModalHeader>
        <ModalBody>
          <>
            <CustomSelect
              label="Tipologia di atto di indizione"
              placeholder="Seleziona atto di indizione"
              options={
                concorsiOptions?.tipoAtto.map(({ value, label }) => ({
                  value,
                  label,
                })) || []
              }
              value={attiForm.values.tipoAtto}
              invalid={!!attiForm.errors.tipoAtto}
              infoText={attiForm.errors.tipoAtto}
              onChange={(e) => attiForm.setFieldValue('tipoAtto', e)}
              name="tipoAtto"
              wrapperClass="col-md required"
            />
            <Input
              label="Data atto"
              type="date"
              placeholder="Inserisci una data"
              id="input-dataAtto"
              wrapperClassName="form-group required col-md-12"
              name="dataAtto"
              value={attiForm.values.dataAtto}
              onChange={attiForm.handleChange}
              onBlur={attiForm.handleBlur}
            />
            <Input
              label="Numero Atto"
              type="text"
              placeholder="Inserisci numero atto"
              id="input-numeroAtto"
              wrapperClassName="form-group required col-md-12"
              name="numeroAtto"
              value={attiForm.values.numeroAtto}
              onChange={attiForm.handleChange}
              onBlur={attiForm.handleBlur}
            />
            <Input
              label="Oggetto atto"
              type="text"
              placeholder="Inserisci oggetto atto"
              id="input-oggettoAtto"
              wrapperClassName="form-group required col-md-12"
              name="oggettoAtto"
              value={attiForm.values.oggettoAtto}
              onChange={attiForm.handleChange}
              onBlur={attiForm.handleBlur}
            />
            <Button
              /* @ts-ignore */
              onClick={() => uploadInputRef.current?.click()}
              color="primary"
              outline
              size="sm"
            >
              <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
              Seleziona file
            </Button>

            <input
              id="fileInput"
              type="file"
              accept=".pdf"
              ref={uploadInputRef}
              onChange={(event: any) => {
                const file = event.target.files[0];
                attiForm.setFieldValue('file', file);
              }}
              hidden
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '10px',
                paddingBottom: '15px',
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  attiForm.submitForm();
                }}
              >
                Aggiungi atto
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => setOpenModalAtto(!openModalAtto)}
                size="sm"
              >
                Annulla
              </Button>
            </div>
          </>
        </ModalBody>
      </Modal>
    </AccordionElement>
  );
}
