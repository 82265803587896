import axios from 'axios';

const deleteCandidato = (id?: string | number) => {
  if (!id) return;
  axios
    .delete(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/candidato/${id}`)
    .then((response) => {})
    .catch((error) => {
      console.error('Error deleting candidato:', error);
    });
};

export default deleteCandidato;
