import { Button, Col, FormGroup, Icon, Input, Row } from 'design-react-kit';
import AccordionElement from '../accordion-element/AccordionElement';
import { z } from 'zod';
import { useRef } from 'react';
import { useCompositeForm } from 'hooks/useCompositeForm';

export interface IFormCv {
  cv?: string;
}

const FormCv: React.FC<{
  initValuesProps?: IFormCv;
  readingOnly?: boolean;
}> = ({ initValuesProps, readingOnly }) => {
  const { values, handleChange, setFieldValue, errors, touched, handleBlur } = useCompositeForm();
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: any) => {
    const file: File = event.target.files[0];

    if (file) {
      setFieldValue('fileCv', file);
    }

    if (uploadInputRef.current) {
      uploadInputRef.current.value = '';
    }
  };

  return (
    <AccordionElement title="CV">
      <>
        <Row className="mt-4">
          <Col md={12} className="px-1">
            <input
              id="fileInput"
              type="file"
              accept=".pdf"
              ref={uploadInputRef}
              onChange={(event) => {
                console.log('Selected file: ', event);
                handleFileUpload(event);
              }}
              hidden
            />
          </Col>

          <Col md={12}>
            <div className="d-flex align-items-center">
              <Button
                /* @ts-ignore */
                onClick={() => uploadInputRef.current?.click()}
                color="primary"
                outline
                size="sm"
                disabled={readingOnly}
              >
                <Icon color="primary" className="mx-1" size="sm" icon="it-plus-circle" />
                Seleziona file
              </Button>

              <div className="ml-2">{values.fileCv?.name || ''}</div>
            </div>
          </Col>

          <Col md={12}>
            <div style={{ marginTop: '20px' }}>
              <Icon icon="it-info-circle"></Icon>
              Il file PDF allegato deve essere firmato dal candidato
            </div>
            {/*   <NotificationManagerComponent
              state="warning"
              title=""
              content="Il file pdf allegato deve essere firmato dal candidato"
            /> */}
          </Col>
        </Row>
      </>
    </AccordionElement>
  );
};

export default FormCv;
