import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  CODICE_UNIVOCO_PO,
  DATA_FINE_VALIDITA_PO,
  DATA_INIZIO_VALIDITA_PO,
  DECLARATORIA_PO,
  DENOMINAZIONE_PO,
  STRUTTURA_ORGANIZZATIVA_PO,
  TIPO_PO,
} from './posizioniOrganizzativeConstants';
import {
  MS_AU_CONFIG,
  STATUS_FULLFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
} from '../../store/store-constants';
import PageLoader from '../../components/common/page-loader/PageLoader';
import { Button } from 'design-react-kit';
import PosizioniOrganizzativeDetailsComponent from '../../components/posizioni-organizzative-detail/PosizioniOrganizzativeDetailsComponent';
import { HREF_ELENCO_POSIZIONI_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import { comunicaPO, detailsPosizioneOrganizzativa } from '../../store/posizioneOrganizzativaSlice';
import {
  PosizioneOrganizzativaComunicaRequestDto,
  PosizioneOrganizzativaDtoStatoEnum,
  PosizioneOrganizzativaDtoTipoEnum,
} from '../../services/ms-anagrafica-unica';
import NotificationManagerComponent from '../../components/notification/NotificationManagerComponent';
import CustomAlert from '../../components/common/custom-alert/CustomAlert';
import PageHeader from '../../components/common/page-header/PageHeader';
import DocumentView from '../../components/common/document-view/DocumentView';

const DownloadNullaOsta = () => {
  const { t } = useTranslation();
  const { idCodiceIncarico } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const fieldUsed: Array<string> = [
    CODICE_UNIVOCO_PO,
    DENOMINAZIONE_PO,
    DECLARATORIA_PO,
    STRUTTURA_ORGANIZZATIVA_PO,
    DATA_INIZIO_VALIDITA_PO,
    DATA_FINE_VALIDITA_PO,
    TIPO_PO,
  ];

  const comunicaStatus = useAppSelector((state) => state.posizioneOrganizzativa.comunicaStatus);
  const posizioneOrganizzativa = useAppSelector(
    (state) => state.posizioneOrganizzativa.detailsResult
  );
  const posizioneOrganizzativaResult = useAppSelector(
    (state) => state.posizioneOrganizzativa.detailsStatus
  );

  useEffect(() => {
    getDetail();
    setOpenAlert(false);
  }, [0]);

  const getDetailsFromApi = (idCodiceIncarico: string) => {
    dispatch(detailsPosizioneOrganizzativa({ idCodiceIncarico }));
  };

  const getDetail = () => {
    getDetailsFromApi(idCodiceIncarico!);
  };

  const comunicaPo = () => {
    const param: PosizioneOrganizzativaComunicaRequestDto = {
      codiceUnivoco: posizioneOrganizzativa!.codiceUnivoco,
      requestType: 'Comunica',
    };
    setOpenAlert(true);
    dispatch(
      comunicaPO({
        idCodiceIncarico: idCodiceIncarico as string,
        patchPosizioneOrganizzativaRequest: param,
      })
    );
  };

  const enableProtocollazioneBtn = () =>
    posizioneOrganizzativa?.tipo === PosizioneOrganizzativaDtoTipoEnum.Po &&
    posizioneOrganizzativa.stato === PosizioneOrganizzativaDtoStatoEnum.Inserita;

  return (
    <div>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}
        title={t('download nulla osta')!}
      ></PageHeader>
      {posizioneOrganizzativaResult == STATUS_PENDING && (
        <PageLoader loadingMessage="Caricamento..." />
      )}
      {posizioneOrganizzativaResult == STATUS_REJECTED && (
        <CustomAlert
          color="danger"
          message="Siamo spiacenti, si &egrave; verificato un errore durante la richiesta"
          showButton={true}
          callback={getDetail}
        />
      )}

      {comunicaStatus == STATUS_REJECTED && openAlert && (
        <NotificationManagerComponent
          state="error"
          title="Errore"
          content="Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio"
        />
      )}
      {comunicaStatus == STATUS_FULLFILLED && openAlert && (
        <NotificationManagerComponent
          state="success"
          title="Success"
          content="Salvataggio avvenuto con successo"
        />
      )}
      {posizioneOrganizzativaResult === STATUS_FULLFILLED && (
        <div>
          <div className="row">
            <div className="col-8">
              <PosizioniOrganizzativeDetailsComponent
                fields={fieldUsed}
                value={posizioneOrganizzativa!}
              />
              <h6>{t('download nulla osta button')}</h6>

              <DocumentView
                idDocumento={posizioneOrganizzativa!.idNullaOsta}
                download
              ></DocumentView>
              {/*<h6>{t('protocollazione nota e nulla osta')}</h6>*/}
              {/*<Button outline color='primary' className="mt-2 mr-2" onClick={() => {*/}
              {/*}}>Protocollazione</Button>*/}
            </div>
          </div>

          <div className="postion-relative py-5">
            <div className="d-flex flex-row-reverse">
              <Button
                color="primary"
                className="mt-2"
                disabled={!enableProtocollazioneBtn()}
                onClick={comunicaPo}
              >
                Protocollazione
              </Button>
              <Button
                outline
                color="primary"
                className="mt-2 mr-2"
                onClick={() => navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE)}
              >
                Indietro
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadNullaOsta;
