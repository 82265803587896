import { useNavigate, useParams } from 'react-router-dom';
import {
  AssegnazioneBandiPoDto,
  BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest,
  BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest,
  CandidaturaBandoPoDto,
  CandidaturaBandoPoDtoStatoEnum,
  CandidaturaBandoPoEssenzialeDto,
  CandidaturaBandoPoVintaDto,
  DipendenteRegionaleDto,
  IncaricoEssenzialeDto,
  RuoloUtenteAutenticatoDto,
  ValutaCandidaturaBandoPoRequestDto,
} from '../../../services/ms-anagrafica-unica';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  dettaglioCandidatureAssegnazioneBando,
  getDettaglioAssegnazioneBando,
  insertValutazioneBandoPo,
} from '../../../store/bandoSlice';
import PageHeader from '../../../components/common/page-header/PageHeader';
import { HREF_ELENCO_ASSEGNAZIONI } from '../../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'design-react-kit';
import { useFormik } from 'formik';
import EqDetail from '../../../components/bando-eq-detail-form/EqDetail';
import './style.scss';
import { cvDownload } from '../../../store/curriculumVitaeSlice';
import DocumentView from '../../../components/common/document-view/DocumentView';
import TextareaInfoChars from 'components/common/textarea-infochars/TextareaInfoChars';

const ElencoCandidature = (props: { operations: RuoloUtenteAutenticatoDto }) => {
  interface VinciteI {
    nomePo: string;
    so: string;
    dirigentePO: string;
    assegnata: Boolean;
  }

  const { codiceUnivocoEQ } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { assegnazioneBandoStatus } = useAppSelector((state) => state.bando);

  const [assegnazioneBandoResult, setAssegnazioneBandoResult] = useState<AssegnazioneBandiPoDto>();

  const [isOpenConteso, toggleModalConteso] = useState(false);
  const [isOpenAltreCandidature, toggleModalAltreCandidature] = useState(false);
  const [vincite, setVincite] = useState<Array<VinciteI>>([]);
  const [elencoValutazioni, setElencoValutazioni] = useState<
    Array<ValutaCandidaturaBandoPoRequestDto>
  >([]);
  const [elencoValutazioniOld, setElencoValutazioniOld] = useState<
    Array<ValutaCandidaturaBandoPoRequestDto>
  >([]);
  const [elencoAltreCandidatureDipRegi, setElencoAltreCandidatureDipRegi] = useState<
    Array<CandidaturaBandoPoEssenzialeDto>
  >([]);
  const [nessunaPreferenzaDirigente, setNessunaPreferenzaDirigente] = useState<boolean>(false);
  const [nessunaPreferenzaDirettore, setNessunaPreferenzaDirettore] = useState<boolean>(false);
  const [candidatureDaVisualizzare, setCandidatureDaVisualizzare] = useState<
    AssegnazioneBandiPoDto | undefined
  >(undefined);

  const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

  let initialValuesValutazione: BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest =
    {
      id: Number(codiceUnivocoEQ),
      valutazioneBandoPoRequestDto: {
        elencoCandidature: elencoValutazioni,
        nonSceltaDirettore: nessunaPreferenzaDirettore,
        nonSceltaDirigente: nessunaPreferenzaDirigente,
      },
    };

  const initialValuesOrdinamento: BandiPosizioniOrganizzativeControllerApiDettaglioCandidatureAssegnazioneBandoRequest =
    {
      id: Number(codiceUnivocoEQ),
      pageNum: 0,
      pageSize: 10,
      sort: '',
      direction: 'ASC',
      q: '',
    };

  type Direction = 'ASC' | 'DESC';
  interface SortingState {
    direction: Direction;
  }

  const searchForm = useFormik({
    initialValues: initialValuesOrdinamento,
    onSubmit: (values) => {
      dispatch(dettaglioCandidatureAssegnazioneBando(values))
        .unwrap()
        .then(
          (resp) =>
            !!resp &&
            setCandidatureDaVisualizzare((prev) => ({
              ...prev,
              elencoCandidature: resp,
            }))
        );
    },
  });

  const esistonoAltreCandidature = (dipReg: DipendenteRegionaleDto) => {
    return (
      dipReg?.elencoCandidatureBandi &&
      dipReg?.elencoCandidatureBandi.filter(
        (cand) => cand.assegnazioneBandoPo?.codiceUnivoco != codiceUnivocoEQ
      ).length > 0
    );
  };

  function recuperaInfoDaDto(resp: AssegnazioneBandiPoDto) {
    if (!!resp && resp.elencoCandidature && resp.elencoCandidature.length > 0) {
      const valutazioniObj = resp.elencoCandidature.map((candidatura) => {
        return {
          idCandidatura: candidatura.id!,
          note: candidatura.note,
          scelta: candidatura.vincitore,
          secondaScelta: candidatura.flagForzaVincitore,
        };
      });
      setElencoValutazioni(valutazioniObj);
      setAssegnazioneBandoResult(resp);
    } else if (!!resp) {
      setAssegnazioneBandoResult(resp);
    }
    setNessunaPreferenzaDirettore(resp.nonSceltaDirettore!);
    setNessunaPreferenzaDirigente(resp.nonSceltaDirigente!);
  }

  useEffect(() => {
    dispatch(
      getDettaglioAssegnazioneBando({
        id: Number(codiceUnivocoEQ!),
      })
    )
      .unwrap()
      .then((resp) => {
        recuperaInfoDaDto(resp);
        setCandidatureDaVisualizzare(resp);
      });
  }, []);

  const ordinamento = (columnName: string) => {
    let direction: Direction;
    direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
    setSorting({ direction });
    searchForm.setFieldValue('sort', columnName);
    searchForm.setFieldValue('direction', sorting.direction);
    searchForm.handleSubmit();
  };

  const stampaStruttureDiAppartenenza = (struttureOrganizzative: IncaricoEssenzialeDto[]) => {
    return struttureOrganizzative.map((so, index) => so.descrizione);
  };

  const modalConteso = (vincita: CandidaturaBandoPoVintaDto[]) => {
    const v: VinciteI[] = [];
    vincita.forEach((x) => {
      v.push({
        nomePo: x.assegnazioneBandoPo?.posizioneOrganizzativa?.denominazione!,
        so: x.assegnazioneBandoPo?.strutturaOrganizzativaBando!,
        dirigentePO:
          x.assegnazioneBandoPo?.posizioneOrganizzativa?.dirigenteStrutturaOrganizzativa!,
        assegnata: x.assegnazioneBandoPo?.assegnato!,
      });
    });
    setVincite(v);
    toggleModalConteso(!isOpenConteso);
  };

  const modalAltreCandidature = (dipReg: DipendenteRegionaleDto) => {
    if (esistonoAltreCandidature(dipReg)) {
      setElencoAltreCandidatureDipRegi(dipReg.elencoCandidatureBandi!);
    }
    toggleModalAltreCandidature(!isOpenAltreCandidature);
  };

  const changeValutazione = (
    idCandidatura: number,
    nota?: string,
    scelta?: boolean,
    secondaScelta?: boolean
  ) => {
    const indiceValutazioneDaModificare = elencoValutazioni.findIndex(
      (v) => v.idCandidatura === idCandidatura
    );
    if (elencoValutazioni[indiceValutazioneDaModificare]) {
      const newElencoValutazioni = [...elencoValutazioni];
      newElencoValutazioni[indiceValutazioneDaModificare].note = nota;
      newElencoValutazioni[indiceValutazioneDaModificare].scelta = scelta;
      newElencoValutazioni[indiceValutazioneDaModificare].secondaScelta = secondaScelta;
      setElencoValutazioni(newElencoValutazioni);
    }
  };

  const submitValutazione = () => {
    dispatch(insertValutazioneBandoPo(initialValuesValutazione))
      .unwrap()
      .then((resp) => !!resp && recuperaInfoDaDto(resp));
  };

  const showPotenzialeConteso = (candidatura: CandidaturaBandoPoDto) =>
    candidatura.elencoCandidatureVinte?.filter(
      (vincite) => vincite.assegnazioneBandoPo?.codiceUnivoco != codiceUnivocoEQ
    ).length;

  const showConteso = (candidatura: CandidaturaBandoPoDto) =>
    candidatura.elencoCandidatureVinte?.length;

  const isAssegnato = (candidatura: CandidaturaBandoPoDto) =>
    !!candidatura.dipendenteRegionale?.bandoInCorsoSuCuiAssegnato;

  const definisciStato = (candidatura: CandidaturaBandoPoDto) => {
    if (isAssegnato(candidatura)) {
      return 'Assegnato';
    } else if (showConteso(candidatura)! > 1) {
      return 'Conteso';
    } else if (showPotenzialeConteso(candidatura) === 1) {
      return 'Opzionato';
    }

    return '';
  };

  const disableScelta = (candidatura: CandidaturaBandoPoDto) => candidatura.conteso;

  const callCvDownload = (id: number, nomeDipendente: string) => {
    dispatch(
      cvDownload({
        idCid: Number(id!),
      })
    )
      .unwrap()
      .then((resp) => {
        if (!!resp) {
          const blob = new Blob([resp], { type: 'application/pdf' });
          let url;
          const link = document.createElement('a');
          url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `CV_${nomeDipendente.replace(' ', '_').replace("'", '')}`;
          link.click();
        }
      });
  };

  const showSecondoFlag = () => {
    return (
      props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE_CONFERMA') ||
      props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE_CONFERMA_VISUALIZZA')
    );
  };

  const disableNullaOsta = (bando: AssegnazioneBandiPoDto) => {
    return (
      props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE_CONFERMA_VISUALIZZA') ||
      bando.statoBando !== 'CHIUSO' ||
      nessunaPreferenzaDirettore
    );
  };

  const visualizzaAzione = () => {
    return (
      assegnazioneBandoResult?.elencoCandidature?.some(
        (cand) => cand.stato !== CandidaturaBandoPoDtoStatoEnum.InComp
      ) &&
      (props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE') ||
        props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE_CONFERMA') ||
        props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE_CONFERMA_VISUALIZZA'))
    );
  };

  const disabilitaNoPreferenzaDirigente = () => {
    return (
      !props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE') &&
      assegnazioneBandoResult!.statoBando !== 'CHIUSO'
    );
  };

  const disabilitaControlloDirigente = () => {
    return (
      !props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE') ||
      assegnazioneBandoResult!.statoBando !== 'CHIUSO'
    ); // || nessunaPreferenzaDirigente
  };

  const disabilitaControlloDirettore = () => {
    return (
      !props.operations.elencoFunzionalita?.includes('BANDO_PO_VALUTAZIONE_CONFERMA') ||
      assegnazioneBandoResult!.statoBando !== 'CHIUSO'
    );
  };

  const controllaFlagCandidatureDirigente = (e: any) => {
    setNessunaPreferenzaDirigente(!nessunaPreferenzaDirigente);
    if (e.target.checked) {
      const newValutazione = elencoValutazioni.map((v) => {
        return {
          ...v,
          scelta: false,
        };
      });
      setElencoValutazioni(newValutazione);
      setElencoValutazioniOld(elencoValutazioni);
    } else {
      setElencoValutazioni([...elencoValutazioni]);
      setElencoValutazioniOld(elencoValutazioni);
    }
  };

  const controllaFlagCandidatureDirettore = (e: any) => {
    setNessunaPreferenzaDirettore(!nessunaPreferenzaDirettore);
    if (e.target.checked) {
      const newValutazione = elencoValutazioni.map((v) => {
        return {
          ...v,
          secondaScelta: false,
        };
      });
      setElencoValutazioni(newValutazione);
      setElencoValutazioniOld(elencoValutazioni);
    } else {
      setElencoValutazioni([...elencoValutazioni]);
      setElencoValutazioniOld(elencoValutazioni);
    }
  };

  return (
    <>
      {assegnazioneBandoStatus === STATUS_FULLFILLED && !!assegnazioneBandoResult && (
        <div className="elenco-candidature">
          <PageHeader
            showIcon={true}
            urlBack={`${HREF_ELENCO_ASSEGNAZIONI}/${assegnazioneBandoResult.idBando}`}
            title={`${t('Candidature per ')!} ${assegnazioneBandoResult.nomeBando}`}
            buttons={[]}
          />
          <EqDetail bando={assegnazioneBandoResult} />

          <div className="mt-4 position-relative">
            {assegnazioneBandoResult.elencoCandidature?.length === 0 && (
              <Alert color="info">Non sono ancora state presentate candidature per il bando.</Alert>
            )}
            {assegnazioneBandoResult.elencoCandidature?.length! > 0 && (
              <>
                <div className="card-custom m-0">
                  <Row>
                    {candidatureDaVisualizzare?.elencoCandidature
                      ?.filter((cand) => cand.stato !== CandidaturaBandoPoDtoStatoEnum.InComp)
                      .map((x, i) => (
                        <Col key={i} xl="6" className="mb-3">
                          <Card className="item-card card-candidatura card-bg p-4">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-column">
                                <span className="titolo-dipendente">Dipendente: </span>
                                <span className="nome-dipendente">
                                  {x.dipendenteRegionale?.nomeCompleto}
                                </span>
                              </div>
                              {esistonoAltreCandidature(x.dipendenteRegionale!) && (
                                <Button
                                  outline
                                  color="primary"
                                  size="xs"
                                  icon
                                  title="Visualizza altre candidature"
                                  onClick={() => modalAltreCandidature(x.dipendenteRegionale!)}
                                >
                                  <Icon color="primary" size="xs" icon="it-files"></Icon>{' '}
                                  Candidature
                                </Button>
                              )}
                            </div>

                            <div className="d-flex justify-content-between border-bottom py-3">
                              <div className="d-flex flex-column">
                                <span className="titolo-struttura">Struttura: </span>
                                <span className="nome-struttura">
                                  {x.dipendenteRegionale?.strutturaDiServizio || ' - '}
                                </span>
                              </div>
                              {x.dipendenteRegionale?.flagCvInserito ? (
                                <Button
                                  color="primary"
                                  size="xs"
                                  onClick={() =>
                                    callCvDownload(
                                      x.dipendenteRegionale?.id!,
                                      x.dipendenteRegionale?.nomeCompleto!
                                    )
                                  }
                                >
                                  <Icon icon="it-download" size="xs" color="white" /> Download CV
                                </Button>
                              ) : (
                                '-'
                              )}
                            </div>

                            <div
                              className="d-flex justify-content-between py-3"
                              style={{ height: '5rem' }}
                            >
                              <div className="d-flex flex-column conteso">
                                <span className="titolo">Conteso: </span>
                                <span className="valore">
                                  {definisciStato(x) !== '' ? (
                                    <Badge
                                      color="primary"
                                      className=" col-sm-12"
                                      style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        fontSize: '15px',
                                      }}
                                      onClick={() => modalConteso(x.elencoCandidatureVinte!)}
                                    >
                                      {definisciStato(x)}
                                    </Badge>
                                  ) : (
                                    <span>NO</span>
                                  )}
                                </span>
                              </div>

                              <div className="d-flex justify-content-between w-50">
                                <div className="d-flex flex-column modello-istanza">
                                  <span className="titolo">Modello d'istanza: </span>
                                  <span className="valore">
                                    {' '}
                                    {!!x.idRiepilogoFirmato ? 'Caricata' : 'Non caricata'}{' '}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center">
                                  {!!x.idRiepilogoFirmato && (
                                    <DocumentView
                                      documento={x.idRiepilogoFirmato}
                                      download
                                    ></DocumentView>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-custom mt-2">
                              <TextareaInfoChars
                                id={'note' + i}
                                placeholder="Inserisci Note"
                                wrapperClassName="no-form-group"
                                name="note"
                                maxLength={1000}
                                disabled={assegnazioneBandoResult.statoBando !== 'CHIUSO'}
                                defaultValue={
                                  elencoValutazioni[
                                    elencoValutazioni.findIndex((v) => v.idCandidatura === x.id!)
                                  ]
                                    ? elencoValutazioni[
                                        elencoValutazioni.findIndex(
                                          (v) => v.idCandidatura === x.id!
                                        )
                                      ].note
                                    : ''
                                }
                                onChange={(e: any) =>
                                  changeValutazione(
                                    x.id!,
                                    e.target.value,
                                    elencoValutazioni[
                                      elencoValutazioni.findIndex((v) => v.idCandidatura === x.id!)
                                    ].scelta,
                                    elencoValutazioni[
                                      elencoValutazioni.findIndex((v) => v.idCandidatura === x.id!)
                                    ].secondaScelta
                                  )
                                }
                              />

                              <Row className="mt-1">
                                <Col xl={12} className="px-1">
                                  <FormGroup check inline className="">
                                    <Input
                                      type="checkbox"
                                      id={'scelta' + i}
                                      label="Scelta *"
                                      disabled={disabilitaControlloDirigente()}
                                      checked={
                                        elencoValutazioni[
                                          elencoValutazioni.findIndex(
                                            (v) => v.idCandidatura === x.id!
                                          )
                                        ]
                                          ? elencoValutazioni[
                                              elencoValutazioni.findIndex(
                                                (v) => v.idCandidatura === x.id!
                                              )
                                            ].scelta
                                          : false
                                      }
                                      name="scelta"
                                      wrapperClassName="no-form-group mr-3"
                                      defaultChecked={
                                        elencoValutazioni[
                                          elencoValutazioni.findIndex(
                                            (v) => v.idCandidatura === x.id!
                                          )
                                        ]?.scelta
                                      }
                                      onChange={(e: any) =>
                                        changeValutazione(
                                          x.id!,
                                          elencoValutazioni[
                                            elencoValutazioni.findIndex(
                                              (v) => v.idCandidatura === x.id!
                                            )
                                          ].note,
                                          e.target.checked,
                                          elencoValutazioni[
                                            elencoValutazioni.findIndex(
                                              (v) => v.idCandidatura === x.id!
                                            )
                                          ].secondaScelta
                                        )
                                      }
                                    />
                                    {showSecondoFlag() && (
                                      <Input
                                        type="checkbox"
                                        id={'forza-scelta' + i}
                                        label="Conferma/Nulla Osta"
                                        disabled={disableNullaOsta(assegnazioneBandoResult)}
                                        name="forza-scelta"
                                        wrapperClassName="no-form-group ml-3"
                                        checked={
                                          elencoValutazioni[
                                            elencoValutazioni.findIndex(
                                              (v) => v.idCandidatura === x.id!
                                            )
                                          ]
                                            ? elencoValutazioni[
                                                elencoValutazioni.findIndex(
                                                  (v) => v.idCandidatura === x.id!
                                                )
                                              ].secondaScelta
                                            : false
                                        }
                                        defaultChecked={
                                          elencoValutazioni[
                                            elencoValutazioni.findIndex(
                                              (v) => v.idCandidatura === x.id!
                                            )
                                          ]?.secondaScelta
                                        }
                                        onChange={(e: any) =>
                                          changeValutazione(
                                            x.id!,
                                            elencoValutazioni[
                                              elencoValutazioni.findIndex(
                                                (v) => v.idCandidatura === x.id!
                                              )
                                            ].note,
                                            elencoValutazioni[
                                              elencoValutazioni.findIndex(
                                                (v) => v.idCandidatura === x.id!
                                              )
                                            ].scelta,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <span className="info-scelta">
                                <strong>*</strong> La scelta è operata nel rispetto del programma di
                                rotazione ordinaria del personale di Regione Puglia.
                              </span>
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>

                {visualizzaAzione() && (
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <span className="mr-4 font-weight-semibold">
                      Non voglio esprimere alcuna preferenza
                    </span>
                    <FormGroup
                      check
                      inline
                      className="form-group text-left mt-0 mb-0 nessuna-preferenza"
                    >
                      <Input
                        type="checkbox"
                        label="Dirigente"
                        id="nessuna-preferenza-dirigente"
                        checked={nessunaPreferenzaDirigente}
                        disabled={disabilitaControlloDirigente()}
                        name="nessuna-preferenza-dirigente"
                        onChange={controllaFlagCandidatureDirigente}
                      />
                      <Input
                        type="checkbox"
                        label="Direttore"
                        checked={nessunaPreferenzaDirettore}
                        id="nessuna-preferenza-direttore"
                        disabled={disabilitaControlloDirettore()}
                        name="nessuna-preferenza-direttore"
                        onChange={controllaFlagCandidatureDirettore}
                      />
                    </FormGroup>
                  </div>
                )}
              </>
            )}
            {assegnazioneBandoResult.statoBando !== 'CHIUSO' && (
              <div className="mt-3">
                <Alert color="warning">
                  La valutazione delle candidature sarà disponibile alla chiusura del bando
                </Alert>
              </div>
            )}
            <div className="d-flex flex-row-reverse">
              <Button
                color="primary"
                className="mt-2 mr-2"
                disabled={assegnazioneBandoResult.statoBando !== 'CHIUSO'}
                onClick={submitValutazione}
              >
                Conferma
              </Button>
              <Button
                color="secondary"
                outline
                className="mt-2 mr-2"
                onClick={() => {
                  navigate(`${HREF_ELENCO_ASSEGNAZIONI}/${assegnazioneBandoResult.idBando}`);
                }}
              >
                {t('annulla')}{' '}
              </Button>
            </div>
          </div>
          <Modal
            isOpen={isOpenConteso}
            toggle={() => toggleModalConteso(!isOpenConteso)}
            labelledBy="conteso"
            centered
            size="lg"
          >
            <ModalHeader toggle={() => toggleModalConteso(!isOpenConteso)} id="conteso">
              Elenco EQ per le quali il dipendente è stato indicato come scelta
            </ModalHeader>
            <ModalBody>
              {vincite.length > 0 && (
                <Table responsive>
                  <thead>
                    <th>Nome EQ</th>
                    <th>Struttura organizzativa</th>
                    <th>Dirigente</th>
                  </thead>
                  <tbody>
                    {vincite.map((v, i) => (
                      <tr key={i} className={v.assegnata ? 'candidato' : ''}>
                        <td>{v.nomePo}</td>
                        <td>{v.so}</td>
                        <td>{v.dirigentePO}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModalConteso(!isOpenConteso)}>
                Annulla
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={isOpenAltreCandidature}
            toggle={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}
            labelledBy="altreCandidature"
            centered
            size="lg"
          >
            <ModalHeader
              toggle={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}
              id="altreCandidature"
            >
              Elenco altre candidature
            </ModalHeader>
            <ModalBody>
              {elencoAltreCandidatureDipRegi.filter((c) => c.stato !== 'IN_COMP').length > 0 && (
                <Table responsive>
                  <thead>
                    <th>Nome EQ</th>
                    <th>Struttura organizzativa</th>
                    <th>Dirigente</th>
                  </thead>
                  <tbody>
                    {elencoAltreCandidatureDipRegi
                      .filter((c) => c.stato !== 'IN_COMP')
                      .map((cand) => (
                        <tr>
                          <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.denominazione}</td>
                          <td>
                            {
                              cand.assegnazioneBandoPo?.posizioneOrganizzativa
                                ?.nomeStrutturaOrganizzativa
                            }
                          </td>
                          <td>
                            {
                              cand.assegnazioneBandoPo?.posizioneOrganizzativa
                                ?.dirigenteStrutturaOrganizzativa
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}
              >
                Annulla
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ElencoCandidature;
