import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/common/page-header/PageHeader';
import FormRicercaEQ from '../../../components/bandi/NuovoBando/FormRicercaEQ';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  creaNuovoBando,
  elencoPoPerBando,
  getCreaBandoContestoForm,
} from '../../../store/bandoSlice';
import {
  AssegnazioneBandoPoRequestDto,
  BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest,
  CreaAggiornaBandoPoRequestDto,
  CreaAggiornaBandoPoRequestDtoRequestTypeEnum,
  OptionDtoInteger,
  OptionDtoString,
  OptionDtoTipoPosizioneOrganizzativa,
  OptionDtoTipologiaBandoPo,
  PosizioneOrganizzativaDto,
} from '../../../services/ms-anagrafica-unica';
import TableResult from '../../../components/bandi/NuovoBando/TableResult';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import PosizioniOrganizzativeForm from '../../../components/bandi/PosizioniOrganizzativeForm';
import { creaSelectOptions, creaSelectOptionsWithOther } from '../../../utility/formUtils';
import TableElencoEQABando from '../../../components/bandi/NuovoBando/TableElencoEQABando';
import PosizioniOrganizzativeDetail from '../../../components/bando-eq-detail-form/PosizioniOrganizzativeDetail';
import FormDatiEQ from '../../../components/bandi/NuovoBando/FormDatiEQ';
import { HREF_ELENCO_BANDI } from '../../../components/layout/sidemenu/sidemenuConstants';
import { useNavigate } from 'react-router-dom';

const NuovoBando = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { elencoPoPerBandoStatus, elencoPoPerBandoResult, elencoPoPerBandoInput } = useAppSelector(
    (state) => state.bando
  );

  const [idSo, setIdSo] = useState<string | undefined>(undefined);
  const [struttureOrganizzative, setStruttureOrganizzative] = useState<OptionDtoString[]>([]);
  const [tipiEQ, setTipiEQ] = useState<OptionDtoTipoPosizioneOrganizzativa[]>([]);
  const [tipologiaBando, setTipologiaBando] = useState<OptionDtoTipologiaBandoPo[]>([]);
  const [durateIncarico, setDurateIncarico] = useState<OptionDtoInteger[]>([]);
  const [EQinseriteABando, setEQinseriteABando] = useState<Array<AssegnazioneBandoPoRequestDto>>(
    []
  );
  const [EQDaInserireABando, setEQDaInserireABando] = useState<
    PosizioneOrganizzativaDto | undefined
  >(undefined);
  const [EQDettaglioInseriteABando, setEQDettaglioInseriteABando] = useState<
    Array<PosizioneOrganizzativaDto>
  >([]);
  const [riepilogoEQ, setRiepilogoEQ] = useState<PosizioneOrganizzativaDto | undefined>(undefined);
  const [riepilogoAssegnazione, setRiepilogoAssegnazione] = useState<
    AssegnazioneBandoPoRequestDto | undefined
  >(undefined);
  const [isModalOpenAggiungiEQABando, setModalOpenAggiungiEQABando] = useState<boolean>(false);
  const [isModalOpenDettaglioEQABando, setModalOpenDettaglioEQABando] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCreaBandoContestoForm())
      .unwrap()
      .then((resp) => {
        if (resp) {
          resp.struttureOrganizzative && setStruttureOrganizzative(resp.struttureOrganizzative);
          resp.tipi && setTipiEQ(resp.tipi);
          resp.durataIncarico && setDurateIncarico(resp.durataIncarico);
          resp.tipologiaBandoPo && setTipologiaBando(resp.tipologiaBandoPo);
        }
      });
    setIdSo(elencoPoPerBandoInput?.idSo);
  }, []);

  const submitRicercaEqPerStrutturaOrganizzativa = (
    parametriRicerca: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest
  ) => {
    setIdSo(parametriRicerca.idSo);
    const params = {
      ...elencoPoPerBandoInput,
      ...parametriRicerca,
    };
    dispatch(elencoPoPerBando(params));
  };

  const inserisciDettagliEq = (eq: PosizioneOrganizzativaDto) => {
    setEQDaInserireABando(eq);
    setModalOpenAggiungiEQABando(true);
  };

  const inserisciABando = (assegnazione: AssegnazioneBandoPoRequestDto) => {
    const EQinseriteABandoAggiornate = [...EQinseriteABando, assegnazione];
    setEQinseriteABando(EQinseriteABandoAggiornate);
    setEQDettaglioInseriteABando((prevEQDettaglioInseriteABando) => {
      return [...prevEQDettaglioInseriteABando, EQDaInserireABando!];
    });
    setEQDaInserireABando(undefined);
    setModalOpenAggiungiEQABando(false);
  };

  const apriVisualizzaDettaglioEQABandoNuovo = (idEQ: string) => {
    const eq = EQDettaglioInseriteABando.filter((eq) => eq.codiceUnivoco === idEQ).at(0);
    const assegnazione = EQinseriteABando.filter((eq) => eq.idPosizioneOrganizzativa === idEQ).at(
      0
    );
    if (!!eq) {
      setRiepilogoEQ(eq);
      setRiepilogoAssegnazione(assegnazione);
      setModalOpenDettaglioEQABando(true);
    }
  };

  const chiudiVisualizzaDettaglioEQABandoNuovo = () => {
    setRiepilogoEQ(undefined);
    setRiepilogoAssegnazione(undefined);
    setModalOpenDettaglioEQABando(false);
  };

  const rimuoviEQDaBandoNuovo = (idEQ: string) => {
    setEQinseriteABando((prevEQinseriteABando) => {
      const EQinseriteABandoAggiornate = prevEQinseriteABando.filter(
        (prevEq) => prevEq.idPosizioneOrganizzativa !== idEQ
      );
      return EQinseriteABandoAggiornate;
    });
    setEQDettaglioInseriteABando((prevEQDettaglioInseriteABando) => {
      const EQDaInserireABandoAggiornate = prevEQDettaglioInseriteABando.filter(
        (prevEq) => prevEq.codiceUnivoco !== idEQ
      );
      return EQDaInserireABandoAggiornate;
    });
    setEQDaInserireABando(undefined);
  };

  const creaBando = (values: CreaAggiornaBandoPoRequestDto) => {
    dispatch(creaNuovoBando(values))
      .unwrap()
      .then((resp) => {
        if (values.requestType === CreaAggiornaBandoPoRequestDtoRequestTypeEnum.Conferma)
          navigate(HREF_ELENCO_BANDI);
      });
  };

  const annullaInserimentoEQ = () => {
    setModalOpenAggiungiEQABando(false);
  };

  const azzeraListaEqInserite = () => {
    setEQinseriteABando([]);
    setEQDettaglioInseriteABando([]);
    setEQDaInserireABando(undefined);
    setModalOpenAggiungiEQABando(false);
  };

  return (
    <div>
      <PageHeader title={t('Nuovo Bando EQ')!} />
      <FormRicercaEQ
        dominioStruttureOrganizzative={creaSelectOptions(struttureOrganizzative)}
        dominioTipiEQ={creaSelectOptions(tipiEQ)}
        ricercaEqStrutturaOrganizzativa={submitRicercaEqPerStrutturaOrganizzativa}
        disabled={EQinseriteABando.length > 0}
        elencoPoPerBandoInput={elencoPoPerBandoInput!}
      ></FormRicercaEQ>

      {elencoPoPerBandoStatus === STATUS_FULLFILLED &&
      elencoPoPerBandoResult &&
      elencoPoPerBandoResult.totalElements! > 0 ? (
        <div className="mt-3">
          <h4>Elenco EQ inseribili a bando</h4>
          <TableResult
            ricercaEqStrutturaOrganizzativa={submitRicercaEqPerStrutturaOrganizzativa}
            content={elencoPoPerBandoResult}
            selezioneEqPerInserimento={inserisciDettagliEq}
            EQInseriteABando={EQinseriteABando}
            idSo={idSo}
            sort={elencoPoPerBandoInput?.sort}
            direction={elencoPoPerBandoInput?.direction}
          ></TableResult>
        </div>
      ) : (
        <>
          {elencoPoPerBandoStatus === STATUS_FULLFILLED && (
            <Alert color="info" className="mt-2">
              Nessuna EQ inseribile a bando
            </Alert>
          )}
        </>
      )}

      {EQinseriteABando.length > 0 && (
        <div className="nuovo-bando-wrapper">
          <h4>Elenco EQ inserite a bando</h4>
          <TableElencoEQABando
            content={EQinseriteABando}
            EQInseriteABando={EQDettaglioInseriteABando}
            apriVisualizzaDettaglioEQABando={apriVisualizzaDettaglioEQABandoNuovo}
            rimuoviEQDaBando={rimuoviEQDaBandoNuovo}
          ></TableElencoEQABando>
          <div className="d-flex flex-row-reverse">
            <Button color="primary" className="mt-1" onClick={() => azzeraListaEqInserite()}>
              Azzera lista EQ
            </Button>
          </div>
          {riepilogoAssegnazione && riepilogoEQ && (
            <Modal
              isOpen={isModalOpenDettaglioEQABando}
              toggle={() => setModalOpenDettaglioEQABando(!isModalOpenDettaglioEQABando)}
              modalClassName="modal-custom"
              size="lg"
              backdrop="static"
              keyboard={false}
            >
              <ModalHeader />
              <ModalBody>
                <PosizioniOrganizzativeDetail
                  assegnazione={riepilogoAssegnazione}
                  eq={riepilogoEQ}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => chiudiVisualizzaDettaglioEQABandoNuovo()}>
                  Chiudi
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      )}
      {EQDaInserireABando && (
        <Modal
          isOpen={isModalOpenAggiungiEQABando}
          toggle={() => {
            setModalOpenAggiungiEQABando(!isModalOpenAggiungiEQABando);
          }}
          modalClassName="modal-custom"
          size="lg"
        >
          <ModalBody>
            <PosizioniOrganizzativeForm
              po={EQDaInserireABando}
              tipologieBandoOptions={creaSelectOptions(tipologiaBando)}
              durataIncaricoOptions={creaSelectOptionsWithOther(durateIncarico)}
              btnConferma={inserisciABando}
              btnAnnulla={annullaInserimentoEQ}
            />
          </ModalBody>
        </Modal>
      )}

      {!!idSo && EQinseriteABando.length > 0 && (
        <div className="mt-5">
          <h4>Si prega di compilare i seguenti campi</h4>
          <FormDatiEQ
            idSo={idSo}
            elencoPo={EQinseriteABando}
            salvaDatiForm={creaBando}
            nuovoBando={true}
          ></FormDatiEQ>
        </div>
      )}
    </div>
  );
};

export default NuovoBando;
