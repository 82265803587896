import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import CustomSelect, { CustomSelectItem } from '../../common/custom-select/CustomSelect';
import { Button, Callout, CalloutTitle } from 'design-react-kit';
import { t } from 'i18next';
import {
  CapacitaCompetenzePersonaliLingueRequestDto,
  CapacitaCompetenzePersonaliRequestDto,
  CurriculumVitaeRequestDtoSezioneEnum,
} from '../../../services/ms-anagrafica-unica';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { creaNuovoCv, getCvDetail, getGestioneCvOpts } from '../../../store/curriculumVitaeSlice';
import CreatableSelect from 'react-select/creatable';
import { SelectItem } from '../../../store/ConvenzioniSlice';
import TextareaInfoChars from '../../common/textarea-infochars/TextareaInfoChars';
import { GestioneCvPageRef } from '../GestioneCvForm';

export interface EspProfessionaliProps {
  disabled: boolean;
  continuaAction: Function;
  scrollAction: Function;
  disabilitaContinuaBtn: (disabilita: boolean) => void;
}

const CapacitaCompetenzaTab = forwardRef(
  (props: EspProfessionaliProps, ref: Ref<GestioneCvPageRef>) => {
    const dispatch = useAppDispatch();
    const { contestoResult } = useAppSelector((state) => state.curriculumVitae);
    const { userInfoResult } = useAppSelector((state) => state.user);

    const [lingueOptions, setLingueOptions] = useState<Array<CustomSelectItem>>([]);
    const [madrelinguaID, setMadrelinguaID] = useState<string | number | undefined>(undefined);

    const [comprensioneOptions, setComprensioneOptions] = useState<Array<CustomSelectItem>>([]);
    const [capacitaCompetenzeOrganizzativeOptions, setCapacitaCompetenzeOrganizzativeOptions] =
      useState<SelectItem[]>([]);
    const [capacitaCompetenzeOrganizzative, setCapacitaCompetenzeOrganizzative] = useState<
      SelectItem[]
    >([]);

    const initialValues: CapacitaCompetenzePersonaliRequestDto = {
      idMadrelingua: '',
      capacitaCompetenzeSociali: '',
      capacitaCompetenzeOrganizzative: [],
      capacitaCompetenzeTecniche: '',
      capacitaCompetenzeInformatiche: '',
      altreCapacitaCompetenze: '',
      certificazioni: '',
      patente: '',
      ulterioriInformazioni: '',
      elencoCompetenzeLingue: [],
    };

    useEffect(() => {
      dispatch(getGestioneCvOpts());
      dispatch(getCvDetail(userInfoResult?.entityId!))
        .unwrap()
        .then((resp) => {
          if (!!resp && resp.capacitaCompetenzePersonali) {
            form.setValues(resp.capacitaCompetenzePersonali!);
            setMadrelinguaID(resp.capacitaCompetenzePersonali?.madrelingua?.codice);
            form.setFieldValue(
              'idMadrelingua',
              resp.capacitaCompetenzePersonali?.madrelingua?.codice || ''
            );
            if (
              resp.capacitaCompetenzePersonali?.elencoCompetenzeLingue &&
              resp.capacitaCompetenzePersonali.elencoCompetenzeLingue.length > 0
            ) {
              resp.capacitaCompetenzePersonali.elencoCompetenzeLingue.forEach((l, i) => {
                if (!!l.lingua?.codice) {
                  setCompetenzaLingua(l.lingua?.codice || '', i, 'idLingua');
                  setCompetenzaLingua(l.comprensioneAscolto || '', i, 'comprensioneAscolto');
                  setCompetenzaLingua(l.comprensioneLettura || '', i, 'comprensioneLettura');
                  setCompetenzaLingua(
                    l.parlatoInterazioneOrale || '',
                    i,
                    'parlatoInterazioneOrale'
                  );
                  setCompetenzaLingua(l.parlatoProduzioneOrale || '', i, 'parlatoProduzioneOrale');
                  setCompetenzaLingua(l.scritto || '', i, 'scritto');
                }
              });
            }
            if (
              resp.capacitaCompetenzePersonali?.capacitaCompetenzeOrganizzative &&
              resp.capacitaCompetenzePersonali.capacitaCompetenzeOrganizzative.length > 0
            ) {
              const selectItems: SelectItem[] = [];
              resp.capacitaCompetenzePersonali.capacitaCompetenzeOrganizzative.forEach(
                (cap: any) => {
                  selectItems.push(
                    capacitaCompetenzeOrganizzativeOptions.find((x: any) => x.value === cap)!
                  );
                  setCapacitaCompetenzeOrganizzative(selectItems);
                }
              );
            }
          }
        });
    }, []);

    // useEffect(() => {
    //     console.log(props.result)
    //     if (props.result && props.result.capacitaCompetenzePersonali != null ) {
    //         form.setValues(props.result.capacitaCompetenzePersonali)
    //         form.setFieldValue('idMadrelingua', props.result.capacitaCompetenzePersonali.madrelingua?.codice)
    //         if (props.result.capacitaCompetenzePersonali.elencoCompetenzeLingue && props.result.capacitaCompetenzePersonali.elencoCompetenzeLingue.length > 0) {
    //             props.result.capacitaCompetenzePersonali.elencoCompetenzeLingue.forEach( (l,i) => {
    //                 if(!!l.lingua?.codice) {
    //                     setCompetenzaLingua(l.lingua?.codice || '', i, 'idLingua')
    //                     setCompetenzaLingua(l.comprensioneAscolto || '', i, 'comprensioneAscolto')
    //                     setCompetenzaLingua(l.comprensioneLettura || '', i, 'comprensioneLettura')
    //                     setCompetenzaLingua(l.parlatoInterazioneOrale || '', i, 'parlatoInterazioneOrale')
    //                     setCompetenzaLingua(l.parlatoProduzioneOrale || '', i, 'parlatoProduzioneOrale')
    //                     setCompetenzaLingua(l.scritto || '', i, 'scritto')
    //                 }
    //             })
    //         }
    //     }
    // }, [props.result && props.result.capacitaCompetenzePersonali])

    if (lingueOptions.length === 0) {
      const selectItems: Array<CustomSelectItem> = [];
      contestoResult &&
        contestoResult.lingue &&
        contestoResult.lingue.forEach((option: any) => {
          selectItems.push({
            value: option.valore,
            label: option.label,
          });
          setLingueOptions(selectItems);
        });
    }

    if (comprensioneOptions.length === 0) {
      const selectItems: Array<CustomSelectItem> = [];
      contestoResult &&
        contestoResult.livelliLingue &&
        contestoResult.livelliLingue.forEach((option: any) => {
          selectItems.push({
            value: option.valore,
            label: option.label,
          });
          setComprensioneOptions(selectItems);
        });
    }

    if (capacitaCompetenzeOrganizzativeOptions.length === 0) {
      const selectItems: SelectItem[] = [];
      contestoResult &&
        contestoResult.capacitaCompetenzeOrganizzative &&
        contestoResult.capacitaCompetenzeOrganizzative.forEach((option: any) => {
          selectItems.push(option);
          setCapacitaCompetenzeOrganizzativeOptions(selectItems);
        });
    }

    const schema = z
      .object({
        idMadrelingua: z.string({ required_error: 'Il campo Madrelingua è obbligatorio' }),
        capacitaCompetenzeSociali: z.string().max(300, { message: 'length300chars' }).optional(),
        capacitaCompetenzeOrganizzative: z.array(z.string().max(300)).optional(),
        capacitaCompetenzeTecniche: z.string().max(300, { message: 'length300chars' }).optional(),
        capacitaCompetenzeInformatiche: z
          .string()
          .max(300, { message: 'length300chars' })
          .optional(),
        altreCapacitaCompetenze: z.string().max(300, { message: 'length300chars' }).optional(),
        certificazioni: z.string().max(300, { message: 'length300chars' }).optional(),
        patente: z.string().max(300, { message: 'length300chars' }).optional(),
        ulterioriInformazioni: z.string().max(300, { message: 'length300chars' }).optional(),
        elencoCompetenzeLingue: z.array(
          z.object({
            idLingua: z.string().optional(),
            comprensioneAscolto: z.string().optional(),
            comprensioneLettura: z.string().optional(),
            parlatoInterazioneOrale: z.string().optional(),
            parlatoProduzioneOrale: z.string().optional(),
            scritto: z.string().optional(),
          })
        ),
      })
      .superRefine((data, ctx) => {
        data.elencoCompetenzeLingue.forEach((l, i) => {
          if (
            !!l.idLingua &&
            data.elencoCompetenzeLingue.filter((lingua) => l.idLingua === lingua.idLingua).length >
              1
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Lingua già inserita',
              path: ['idLingua', i],
            });
          }
          if (!!l.idLingua && !l.comprensioneAscolto) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Livello di comprensione in ascolto è obbligatorio',
              path: ['comprensioneAscolto', i],
            });
          }
          if (!!l.idLingua && !l.comprensioneLettura) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Livello di comprensione in lettura è obbligatorio',
              path: ['comprensioneLettura', i],
            });
          }
          if (!!l.idLingua && !l.parlatoInterazioneOrale) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Livello di interazione orale è obbligatorio',
              path: ['parlatoInterazioneOrale', i],
            });
          }
          if (!!l.idLingua && !l.parlatoProduzioneOrale) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Livello di produzione orale è obbligatorio',
              path: ['parlatoProduzioneOrale', i],
            });
          }
          if (!!l.idLingua && !l.scritto) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Livello di produzione scritto è obbligatorio',
              path: ['scritto', i],
            });
          }
        });
      });

    const form: FormikProps<CapacitaCompetenzePersonaliRequestDto> = useFormik({
      initialValues,
      validationSchema: toFormikValidationSchema(schema),
      onSubmit: (values) => {
        const competenzeValide = values.elencoCompetenzeLingue?.filter((c) => !!c.idLingua);

        const req = {
          ...values,
          elencoCompetenzeLingue: competenzeValide,
        };
        dispatch(
          creaNuovoCv({
            capacitaCompetenzePersonali: req,
            sezione: CurriculumVitaeRequestDtoSezioneEnum.CompetenzePersonali,
          })
        )
          .unwrap()
          .then((resp) => resp != null && props.continuaAction());
      },
    });

    const getFieldError = (
      form: FormikProps<CapacitaCompetenzePersonaliRequestDto>,
      fieldName: keyof CapacitaCompetenzePersonaliRequestDto
    ): string => {
      if (form.getFieldMeta(fieldName).touched) {
        return form.errors[fieldName] || '';
      } else return '';
    };

    const getFieldArrayError = (
      errors: any,
      fieldName: keyof CapacitaCompetenzePersonaliLingueRequestDto,
      index: number
    ): string => {
      return (
        (Object.keys(errors).length > 0 && errors[fieldName] && errors[fieldName][index]) || ''
      );
    };

    const setMadreLingua = (e: string) => {
      setMadrelinguaID(e);
      form.setFieldValue('idMadrelingua', e);
    };

    function handleChange(selectedOption: any, actionMeta: any) {
      setCapacitaCompetenzeOrganizzative(selectedOption as SelectItem[]);

      let capacita = (selectedOption as SelectItem[]).map((opt) => (opt.value ? opt.value : opt));
      form.setFieldValue('capacitaCompetenzeOrganizzative', capacita);
    }

    const setCompetenzaLingua = (e: string, i: number, campo: string) => {
      const competenzeLingua: Array<CapacitaCompetenzePersonaliLingueRequestDto> =
        form.values.elencoCompetenzeLingue || [];
      var obj;

      if (campo === 'idLingua' && e === '') {
        obj = {
          idLingua: undefined,
          comprensioneAscolto: undefined,
          comprensioneLettura: undefined,
          parlatoInterazioneOrale: undefined,
          parlatoProduzioneOrale: undefined,
          scritto: undefined,
        };
      } else {
        obj = {
          ...competenzeLingua[i],
          [campo]: e,
        };
      }

      competenzeLingua[i] = obj;

      form.setFieldValue('elencoCompetenzeLingue', competenzeLingua, true);
    };

    const aggiungiLingua = () => {
      const competenzeLingua: Array<CapacitaCompetenzePersonaliLingueRequestDto> =
        form.values.elencoCompetenzeLingue || [];
      const nuovaCompetenzaLingua = [
        {
          idLingua: undefined,
          comprensioneAscolto: undefined,
          comprensioneLettura: undefined,
          parlatoInterazioneOrale: undefined,
          parlatoProduzioneOrale: undefined,
          scritto: undefined,
        },
        ...competenzeLingua,
      ];
      form.setFieldValue('elencoCompetenzeLingue', nuovaCompetenzaLingua);
    };

    const rimuoviLingua = (i: number) => {
      const competenzeLingua: Array<CapacitaCompetenzePersonaliLingueRequestDto> =
        form.values.elencoCompetenzeLingue || [];
      const nuovoCompetenzeLingua = competenzeLingua.filter(
        (competenzaLingua, index) => i !== index
      );
      form.setFieldValue('elencoCompetenzeLingue', nuovoCompetenzeLingua, true);
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm() {
          form.submitForm();
        },
      }),
      [form.values]
    );

    useEffect(() => {
      props.disabilitaContinuaBtn(!form.isValid);
    }, [form.isValid]);

    return (
      <div className="capacita-compentenza">
        <div className="form-custom form-inserimento form-row mt-5">
          <CustomSelect
            label="Madrelingua"
            name="idMadrelingua"
            placeholder="Indica la lingua madre"
            wrapperClass="col-md-12 required"
            options={lingueOptions}
            value={form.values.idMadrelingua}
            onChange={(e) => setMadreLingua(e)}
            invalid={!!getFieldError(form, 'idMadrelingua')}
            infoText={t(getFieldError(form, 'idMadrelingua')) || ''}
          />
          <div className="d-flex">
            <Button color="primary" onClick={aggiungiLingua}>
              {t('Aggiungi lingua')}
            </Button>
          </div>
        </div>

        {form.values.elencoCompetenzeLingue &&
          form.values.elencoCompetenzeLingue.map((l, i) => (
            <Callout className="au-callout col-12 my-5 pb-2 px-4">
              <CalloutTitle>Lingua aggiuntiva</CalloutTitle>
              <div className="form-custom mt-4 px-3">
                <div className="form-row">
                  <CustomSelect
                    label="Altra(e) lingua(e)"
                    name="altraLingua"
                    placeholder="Indica altra lingua"
                    wrapperClass="col-md-12 required"
                    options={lingueOptions.filter((l) => l.value !== madrelinguaID)}
                    onChange={(e) => setCompetenzaLingua(e, i, 'idLingua')}
                    value={l.idLingua}
                    invalid={!!getFieldArrayError(form.errors, 'idLingua', i)}
                    infoText={getFieldArrayError(form.errors, 'idLingua', i)}
                  />
                  <CustomSelect
                    label="Comprensione - Ascolto"
                    name="comprensioneAscolto"
                    placeholder="Indica livello comprensione/ascolto"
                    wrapperClass="col-md-6 required"
                    options={comprensioneOptions}
                    value={
                      (form.values.elencoCompetenzeLingue &&
                        form.values.elencoCompetenzeLingue[i].comprensioneAscolto) ||
                      ''
                    }
                    onChange={(e) => setCompetenzaLingua(e, i, 'comprensioneAscolto')}
                    disabled={
                      form.values.elencoCompetenzeLingue &&
                      !form.values.elencoCompetenzeLingue[i].idLingua
                    }
                    invalid={!!getFieldArrayError(form.errors, 'comprensioneAscolto', i)}
                    infoText={getFieldArrayError(form.errors, 'comprensioneAscolto', i)}
                  />
                  <CustomSelect
                    label="Comprensione - Lettura"
                    name="comprensioneLettura"
                    placeholder="Indica livello comprensione/lettura"
                    wrapperClass="col-md-6 required"
                    options={comprensioneOptions}
                    value={
                      (form.values.elencoCompetenzeLingue &&
                        form.values.elencoCompetenzeLingue[i].comprensioneLettura) ||
                      ''
                    }
                    onChange={(e) => setCompetenzaLingua(e, i, 'comprensioneLettura')}
                    disabled={
                      form.values.elencoCompetenzeLingue &&
                      !form.values.elencoCompetenzeLingue[i].idLingua
                    }
                    invalid={!!getFieldArrayError(form.errors, 'comprensioneLettura', i)}
                    infoText={getFieldArrayError(form.errors, 'comprensioneLettura', i)}
                  />
                  <CustomSelect
                    label="Parlato - Interazione orale"
                    name="parlatoInterazioneOrale"
                    placeholder="Indica livello parlato/interazione orale"
                    wrapperClass="col-md-6 required"
                    options={comprensioneOptions}
                    value={
                      (form.values.elencoCompetenzeLingue &&
                        form.values.elencoCompetenzeLingue[i].parlatoInterazioneOrale) ||
                      ''
                    }
                    onChange={(e) => setCompetenzaLingua(e, i, 'parlatoInterazioneOrale')}
                    disabled={
                      form.values.elencoCompetenzeLingue &&
                      !form.values.elencoCompetenzeLingue[i].idLingua
                    }
                    invalid={!!getFieldArrayError(form.errors, 'parlatoInterazioneOrale', i)}
                    infoText={getFieldArrayError(form.errors, 'parlatoInterazioneOrale', i)}
                  />
                  <CustomSelect
                    label="Parlato - Produzione orale"
                    name="parlatoProduzioneOrale"
                    placeholder="Indica livello parlato/produzione orale"
                    wrapperClass="col-md-6 required"
                    options={comprensioneOptions}
                    value={
                      (form.values.elencoCompetenzeLingue &&
                        form.values.elencoCompetenzeLingue[i].parlatoProduzioneOrale) ||
                      ''
                    }
                    onChange={(e) => setCompetenzaLingua(e, i, 'parlatoProduzioneOrale')}
                    disabled={
                      form.values.elencoCompetenzeLingue &&
                      !form.values.elencoCompetenzeLingue[i].idLingua
                    }
                    invalid={!!getFieldArrayError(form.errors, 'parlatoProduzioneOrale', i)}
                    infoText={getFieldArrayError(form.errors, 'parlatoProduzioneOrale', i)}
                  />
                  <CustomSelect
                    label="Scritto"
                    name="scritto"
                    placeholder="Indica livello scritto"
                    wrapperClass="col-md-6 required"
                    options={comprensioneOptions}
                    value={
                      (form.values.elencoCompetenzeLingue &&
                        form.values.elencoCompetenzeLingue[i].scritto) ||
                      ''
                    }
                    onChange={(e) => setCompetenzaLingua(e, i, 'scritto')}
                    disabled={
                      form.values.elencoCompetenzeLingue &&
                      !form.values.elencoCompetenzeLingue[i].idLingua
                    }
                    invalid={!!getFieldArrayError(form.errors, 'scritto', i)}
                    infoText={getFieldArrayError(form.errors, 'scritto', i)}
                  />
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Button color="primary" outline onClick={() => rimuoviLingua(i)}>
                      {t('Rimuovi lingua')}
                    </Button>
                  </div>
                </div>
              </div>
            </Callout>
          ))}

        <div className="form-custom form-inserimento">
          <div className="form-row">
            <TextareaInfoChars
              className=""
              label="Capacità e competenze sociali"
              placeholder="Indica capacità e competenze sociali"
              id="capacitaCompetenzeSociali"
              wrapperClassName={'col-md-12 mb-4'}
              name="capacitaCompetenzeSociali"
              maxLength={300}
              value={form.values.capacitaCompetenzeSociali}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'capacitaCompetenzeSociali')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />

            <div className={'col-auto'}>
              <span style={{ fontSize: '.777rem', paddingLeft: '0.5rem', color: '#435a70' }}>
                <b>Capacità e competenze organizzative</b>
              </span>
            </div>
            <div className={'col-md-12 form-group'}>
              <CreatableSelect
                key={'input-ccp-organizzative'}
                id="input-ccp-organizzative"
                name="capacitaCompetenzeOrganizzative"
                openMenuOnFocus={true}
                openMenuOnClick={true}
                options={capacitaCompetenzeOrganizzativeOptions}
                value={capacitaCompetenzeOrganizzative}
                placeholder="Indica capacità e competenze organizzative"
                isMulti={true}
                onInputChange={() => ''}
                onChange={handleChange}
              />
            </div>
            <TextareaInfoChars
              className=""
              label="Capacità e competenze tecniche"
              placeholder="Indica capacità e competenze tecniche"
              id="capacitaCompetenzeTecniche"
              wrapperClassName={'col-md-12'}
              name="capacitaCompetenzeTecniche"
              maxLength={300}
              value={form.values.capacitaCompetenzeTecniche}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'capacitaCompetenzeTecniche')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
            <TextareaInfoChars
              className=""
              label="Capacità e competenze informatiche"
              placeholder="Indica capacità e competenze informatiche"
              id="capacitaCompetenzeInformatiche"
              wrapperClassName={'col-md-12'}
              name="capacitaCompetenzeInformatiche"
              maxLength={300}
              value={form.values.capacitaCompetenzeInformatiche}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'capacitaCompetenzeInformatiche')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
            <TextareaInfoChars
              className=""
              label="Altre capacità e competenze"
              placeholder="Indica altre capacità e competenze"
              id="altreCapacitaCompetenze"
              wrapperClassName={'col-md-12'}
              name="altreCapacitaCompetenze"
              maxLength={300}
              value={form.values.altreCapacitaCompetenze}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'altreCapacitaCompetenze')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
            <TextareaInfoChars
              className=""
              label="Certificazioni"
              placeholder="Indica certificazioni conseguite"
              id="certificazioni"
              wrapperClassName={'col-md-12'}
              name="certificazioni"
              maxLength={300}
              value={form.values.certificazioni}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'certificazioni')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
            <TextareaInfoChars
              className=""
              label="Patente"
              placeholder="Indica patenti conseguite"
              id="patente"
              wrapperClassName={'col-md-12'}
              name="patente"
              maxLength={300}
              value={form.values.patente}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'patente')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
            <TextareaInfoChars
              className=""
              label="Ulteriori informazioni"
              placeholder="Indica ulteriori informazioni"
              id="ulterioriInformazioni"
              wrapperClassName={'col-md-12'}
              name="ulterioriInformazioni"
              maxLength={300}
              value={form.values.ulterioriInformazioni}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, 'ulterioriInformazioni')}
              infoText={
                t(
                  'Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali'
                ) || ''
              }
            />
          </div>
        </div>
      </div>
    );
  }
);

export default CapacitaCompetenzaTab;
