import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/common/page-header/PageHeader';
import { Alert, Button } from 'design-react-kit';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getNullaOstaRichiedibile, insertRichiestaNullaOsta } from '../../../store/bandoSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HREF_CANDIDATURA_ELENCO_BANDI } from '../../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../../store/store-constants';
import { NullaOstaRichiedibileDtoMotivoEnum } from '../../../services/ms-anagrafica-unica/model/nulla-osta-richiedibile-dto';

const NullaOsta = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { nullaOstaRichiedibileStatus, nullaOstaRichiedibileResult } = useAppSelector(
    (state) => state.bando
  );

  useEffect(() => {
    getNullaOsta();
  }, []);

  const getNullaOsta = () =>
    dispatch(getNullaOstaRichiedibile())
      .unwrap()
      .then((resp) => !!resp && resp.flag);

  const inviaRichiestaNullaOsta = () => {
    dispatch(insertRichiestaNullaOsta())
      .unwrap()
      .then((resp) => resp && navigate(HREF_CANDIDATURA_ELENCO_BANDI));
  };

  return (
    <div>
      <PageHeader showIcon={false} title={t('Inserimento richiesta nulla osta')!} buttons={[]} />
      {nullaOstaRichiedibileStatus === STATUS_FULLFILLED && !!nullaOstaRichiedibileResult && (
        <div>
          {nullaOstaRichiedibileResult.flag ? (
            <div>
              <p>{t('richiestaNullaOsta')}</p>
              <div className="d-flex flex-row-reverse">
                <Button
                  color="primary"
                  className="reverse mt-2 mr-2"
                  onClick={() => inviaRichiestaNullaOsta()}
                >
                  {t('Invia richiesta')}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {nullaOstaRichiedibileResult?.motivo ===
                NullaOstaRichiedibileDtoMotivoEnum.Conflitto && (
                <Alert color="warning">
                  {t('nullaOstaNonRichiedibile') +
                    ` - Stato richiesta: ${nullaOstaRichiedibileResult.statoRichiestaNullaOsta!}`}
                </Alert>
              )}
              {nullaOstaRichiedibileResult?.motivo ===
                NullaOstaRichiedibileDtoMotivoEnum.ZeroBandi && (
                <Alert color="warning">{t('nullaOstaNonRichiedibileZeroBandi')}</Alert>
              )}
              <div className="d-flex flex-row-reverse">
                <Button
                  color="primary"
                  className="reverse mt-2 mr-2"
                  onClick={() => navigate(HREF_CANDIDATURA_ELENCO_BANDI)}
                >
                  Indietro
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NullaOsta;
