import { useEffect, useState } from 'react';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import { Button, Input } from 'design-react-kit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  detailsPosizioneOrganizzativa,
  disassociaPO,
} from '../../store/posizioneOrganizzativaSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { DENOMINAZIONE_PO, DIPENDENTE_REGIONALE } from './posizioniOrganizzativeConstants';
import { HREF_ELENCO_POSIZIONI_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import { PosizioneOrganizzativaDisassociaRequestDto } from '../../services/ms-anagrafica-unica';
import { useFormik } from 'formik';
import PageHeader from '../../components/common/page-header/PageHeader';

const DisassociazionePosizioneOrganizzativa = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { idCodiceIncarico } = useParams();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const posizioneOrganizzativa = useAppSelector(
    (state) => state.posizioneOrganizzativa.detailsResult
  );
  const posizioneOrganizzativaResult = useAppSelector(
    (state) => state.posizioneOrganizzativa.detailsStatus
  );

  useEffect(() => {
    setOpenAlert(false);
    getDetailsFromApi(idCodiceIncarico!);
  }, []);

  const form = useFormik({
    initialValues: {
      estremoAttoConferimento: '',
    },
    onSubmit: (values) => {
      const param: PosizioneOrganizzativaDisassociaRequestDto = {
        codiceUnivoco: idCodiceIncarico,
        estremiAttoDisassociazione: values.estremoAttoConferimento,
        requestType: 'Disassocia',
      };
      setOpenAlert(true);
      dispatch(
        disassociaPO({
          patchPosizioneOrganizzativaRequest: param,
          idCodiceIncarico: idCodiceIncarico!,
        })
      );
    },
  });

  const getDetailsFromApi = (idCodiceIncarico: string) => {
    dispatch(detailsPosizioneOrganizzativa({ idCodiceIncarico }));
  };

  return (
    <>
      <PageHeader
        showIcon={true}
        urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE}
        state={{ form: true }}
        title={t('Disassocia posizione organizzativa')!}
      ></PageHeader>
      {posizioneOrganizzativaResult === STATUS_FULLFILLED && (
        <>
          <p>
            <b>{DENOMINAZIONE_PO}:</b> {posizioneOrganizzativa?.denominazione}
          </p>
          <p>
            <b>{DIPENDENTE_REGIONALE}:</b> {posizioneOrganizzativa?.dipendenteRegionale}
          </p>
          <div className="row mt-5">
            <Input
              label="Estremi atto di conferimento"
              type="text"
              placeholder="Inserisci l'estremo atto di conferimento"
              id="input-estremoAttoConferimento"
              wrapperClassName="form-group col-md-12"
              name="estremoAttoConferimento"
              value={form.values.estremoAttoConferimento}
              onChange={form.handleChange}
            ></Input>
          </div>
        </>
      )}
      <div className="py-5">
        <div className="d-flex flex-row-reverse">
          <Button color="primary" className="mt-2" onClick={form.submitForm}>
            Disassocia
          </Button>
          <Button
            outline
            color="primary"
            className="mt-2 mr-2"
            onClick={() => navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE, { state: { form: true } })}
          >
            Annulla
          </Button>
        </div>
      </div>
    </>
  );
};

export default DisassociazionePosizioneOrganizzativa;
