/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InsertUpdateIncarichiDirigenzialiRequestDto
 */
export interface InsertUpdateIncarichiDirigenzialiRequestDto {
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  codiceIncaricoSo?: string;
  /**
   *
   * @type {number}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  idDipendente?: number;
  /**
   *
   * @type {boolean}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  candidatoInterno?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  inComando?: boolean;
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  tipoIncarico?: InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum;
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  codiceFiscaleEsterno?: string;
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  nomeCognomeEsterno?: string;
  /**
   *
   * @type {number}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  idDocumento?: number;
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  requestType?: InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof InsertUpdateIncarichiDirigenzialiRequestDto
   */
  nprotocolloUscita?: string;
}

export const InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum = {
  Principale: 'PRINCIPALE',
  Interim: 'INTERIM',
  Vicario: 'VICARIO',
} as const;

export type InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum =
  (typeof InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum)[keyof typeof InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum];
export const InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum = {
  Salva: 'SALVA',
  Conferma: 'CONFERMA',
} as const;

export type InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum =
  (typeof InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum)[keyof typeof InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum];
