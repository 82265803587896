import PageHeader from 'components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from 'components/sidemenu/sidemenuConstants';
import CreaConcorsoForm, {
  type IFormCreaConcorsoForm,
} from 'components/crea-concorso-form/CreaConcorsoForm';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NOTIFICA_STATO } from 'utility/Notifica';
import { useAppDispatch } from 'hooks';
import { showNotifica } from 'store/loaderSlice';

export interface IConcorso {
  id: string;
  nome: string;
  areaProfessionale: string;
  livelli: string;
  dataInserimento: string;
  dataModifica: string;
  posizioneGraduatoria?: string;
  classe?: string;
  vincitore?: boolean;
  idoneo?: boolean;
}

export const creaConcorso = async (values: IFormCreaConcorsoForm): Promise<IConcorso | string> => {
  const valuesAdjusted = {
    nome: values.nome,
    areaProfessionale: values.areaProfessionale,
    posizioneEconomica: values.livelli,
    classe: 'A',
    ...(values.posizioneGraduatoria && { posizioneGraduatoria: values.posizioneGraduatoria }),
    ...(values.vincitore && { vincitore: values.vincitore }),
    idoneo: values.vincitore ? null : (values.idoneo ?? null),
  };

  try {
    const concorsoResponse = await axios.post(
      `${process.env.REACT_APP_MS_AU_BASE_URL}/v1/concorsi/onboarding/concorso/nuovo`,
      valuesAdjusted
    );

    return concorsoResponse.data as IConcorso;
  } catch (error) {
    return error as string;
  }
};

const CreaConcorso = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCreaConcorsoSubmit = async (values: IFormCreaConcorsoForm) => {
    const concorso = await creaConcorso(values);
    console.log('concorso', concorso);

    if (typeof concorso === 'string') {
      dispatch(
        showNotifica({
          titolo: 'Concorso non salvato',
          stato: 'error',
          messaggio: 'Il concorso non è stato salvato',
          tipo: NOTIFICA_STATO.error,
        })
      );
    } else {
      dispatch(
        showNotifica({
          titolo: 'Concorso salvato',
          stato: 'success',
          messaggio: 'Il concorso è stato salvato',
          tipo: NOTIFICA_STATO.success,
        })
      );
    }
  };
  const handleBackButtonClick = () => navigate(HREF_ELENCO_CANDIDATI);

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={'Crea Concorso'} />
      <CreaConcorsoForm
        onSubmit={handleCreaConcorsoSubmit}
        onBackButtonClick={handleBackButtonClick}
      />
    </div>
  );
};

export default CreaConcorso;