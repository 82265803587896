import { useFormik } from 'formik';
import { type ReactNode } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useParams } from 'react-router-dom';
import useGetOnboardingById from 'hooks/services/useGetOnboardingById';
import {
  initialValues,
  type IFormCreaCandidatoForm,
} from 'components/crea-candidato-form/CreaCandidatoForm';
import useGetCandidatoByCodiceFiscale from 'hooks/services/useGetCandidatoByCodiceFiscale';
import useAsyncInitialValues from 'hooks/useAsyncInitialValues';
import validationSchemaWithAddedFields from 'components/forms/validationSchemaWithAddedFields';
import { FormContext } from 'hooks/useCompositeForm';
export interface IFormCandidatoWithAddedFields extends IFormCreaCandidatoForm {
  fileCv?: File;
  note?: string;
}

export const initialValuesWithAddedFields = {
  ...initialValues,
};

export const FormReadingOnly = ({ children }: { children: ReactNode }) => {
  const { codiceFiscale, onboardingId } = useParams();
  const onboarding = useGetOnboardingById(onboardingId);
  const candidato = useGetCandidatoByCodiceFiscale(codiceFiscale);

  const formik = useFormik({
    initialValues: initialValuesWithAddedFields,
    validationSchema: toFormikValidationSchema(validationSchemaWithAddedFields),
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const isAsyncValuesLoading = useAsyncInitialValues(formik, candidato, onboarding);

  if (isAsyncValuesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormContext.Provider value={formik}>
      <div className="mb-4 mt-2">{children}</div>
    </FormContext.Provider>
  );
};
