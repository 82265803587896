import {
  Button,
  FormGroup,
  Icon,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'design-react-kit';
import { useCompositeForm } from 'hooks/useCompositeForm';
import { useState } from 'react';

const DichiarazioneRegime = () => {
  const { values, handleChange, setFieldValue, errors, touched } = useCompositeForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <Button
        className="w-100 d-flex justify-content-between mb-3"
        color="primary"
        onClick={toggleModal}
      >
        Dichiarazione regime di TFR/TFS
        <Icon icon="it-arrow-right" color="white" />
      </Button>
      {/* <Button onClick={toggleModal}>Dichiarazione regime di TFR/TFS</Button> */}
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => toggleModal()}
        centered
        labelledBy={'modal-privacy'}
      >
        <ModalHeader className="w-100 custom-width-modal-header" tag="div" id={'modal-privacy'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Icon size="lg" color="primary" onClick={toggleModal} icon="it-close" />
              <h2>Dichiarazione regime di TFR/TFS</h2>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <FormGroup check className="">
              <Input
                id="radio1"
                name="dichiarazioneAttivitaLavorativa"
                type="radio"
                value="TFR"
                onChange={handleChange}
              />
              <Label check htmlFor="radio1">
                Di essere in regime di TFR
              </Label>
            </FormGroup>

            <FormGroup check className="">
              <Input
                id="radio2"
                name="dichiarazioneAttivitaLavorativa"
                type="radio"
                value="TFS"
                onChange={handleChange}
              />
              <Label check htmlFor="radio2">
                Di essere in regime di TFS
              </Label>
            </FormGroup>

            <FormGroup check className="">
              <Input
                id="radio3"
                name="dichiarazioneAttivitaLavorativa"
                type="radio"
                value="CE5"
                onChange={handleChange}
              />
              <Label check htmlFor="radio3">
                Di avere in corso, alla data odierna, un contratto di cessione del quinto dello
                stipendio
              </Label>
            </FormGroup>

            <FormGroup check className="">
              <Input
                id="radio4"
                name="dichiarazioneAttivitaLavorativa"
                type="radio"
                value="CEN"
                onChange={handleChange}
              />
              <Label check htmlFor="radio4">
                Di <strong>NON</strong> avere in corso, alla data odierna, un contratto di cessione
                del quinto dello stipendio
              </Label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color="primary"
            outline
            className="link-underline-primary"
            onClick={() => toggleModal()}
          >
            Annulla
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleModal();
              console.log('Form values: ', values);
            }}
          >
            Ho preso visione
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DichiarazioneRegime;
