// @ts-nocheck
import { TextArea } from 'design-react-kit';
import { useEffect, useRef } from 'react';
import './TextareaInfoChars.scss';

interface TextareaInfoCharsProps {
  label?: string | ReactNode;
  placeholder?: string;
  infoText?: string;
  value?: string | number;
  readOnly?: boolean;
  normalized?: boolean;
  valid?: boolean;
  invalid?: boolean;
  cssModule?: CSSModule;
  wrapperClassName?: string;
  className?: string;
  name?: string;
  onChange?: Func;
  style?: Object;
  maxLength?: number;
  id?: string;
  disabled?: boolean;
  rows?: number;
  defaultValue?: string;
}

const TextareaInfoChars = (props: TextareaInfoCharsProps) => {
  const refTextarea = useRef<HTMLTextAreaElement>(null);

  const remainingChars = () => {
    if (!!refTextarea.current && props.maxLength) {
      const infoTextElement = refTextarea.current.parentNode?.querySelector('small');
      if (!!infoTextElement) {
        let postNode = infoTextElement.querySelector('span');
        if (!postNode) {
          postNode = document.createElement('span');
        }
        postNode.innerText = refTextarea.current?.value.length + '/' + props.maxLength;
        postNode.classList.add('ml-3');
        infoTextElement.appendChild(postNode);
        infoTextElement.classList.add('textarea-infochars');
      }
    }
  };

  useEffect(() => {
    if (!!refTextarea.current) {
      remainingChars();
    }
  }, []);

  useEffect(() => {
    if (!!props.value) {
      remainingChars();
    }
  }, [props.value]);

  const count = (e) => {
    props.onChange(e);
    remainingChars();
  };

  return (
    <TextArea
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      infoText={props.infoText}
      value={props.value}
      readOnly={props.readOnly}
      normalized={props.normalized}
      valid={props.valid}
      invalid={props.invalid}
      cssModule={props.cssModule}
      wrapperClassName={props.wrapperClassName}
      className={props.className}
      name={props.name}
      onChange={(e) => count(e)}
      style={props.style}
      maxLength={props.maxLength}
      innerRef={refTextarea}
      disabled={props.disabled || false}
      rows={props.rows}
      defaultValue={props.defaultValue}
    ></TextArea>
  );
};

export default TextareaInfoChars;
