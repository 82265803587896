import axios from 'axios';
import { useEffect, useState } from 'react';

export interface Options {
  value: string;
  label: string;
  valore: string;
}

const useConcorsiSelectOptions = () => {
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MS_AU_BASE_URL}/v1/forms/concorso/onboarding`)
      .then((response) => {
        setConcorsiOptions(response.data);
      })
      .catch((error) => {
        console.error('Error fetching select options:', error);
      });
  }, []);

  const [concorsiOptions, setConcorsiOptions] = useState<{
    tipoAtto: Array<Options>;
    concorsi: Array<Options>;
    modalitaSelezione: Array<Options>;
    categorie: Array<Options>;
    livelli: Array<Options>;
    areeProfessionali: Array<Options>;
    tipologieContratto: Array<Options>;
    tipoPartTime: Array<Options>;
    tipiDocumento: Array<Options>;
  }>({
    tipoAtto: [],
    concorsi: [],
    modalitaSelezione: [],
    categorie: [],
    livelli: [],
    areeProfessionali: [],
    tipologieContratto: [],
    tipoPartTime: [],
    tipiDocumento: [],
  });

  return concorsiOptions;
};

export default useConcorsiSelectOptions;
