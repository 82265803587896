import { Button, Col, Input, Table, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import CustomSelect, { CustomSelectItem } from '../../components/common/custom-select/CustomSelect';
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  detailsCandidato,
  formSrachPopulate,
  getListaDocumenti,
  patchValidazioneIntegrazioneDati,
  resetUpdateForm,
  validazioneIntegrazioneDati,
} from '../../store/candidatoSlice';
import {
  ElencoNuoveAssunzioniDtoStatoCandidatoEnum,
  NuoveAssunzioniPatchRequestDto,
} from '../../services/ms-anagrafica-unica';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import PageHeader from '../../components/common/page-header/PageHeader';
import { useNavigate } from 'react-router-dom';
import { HREF_SCRIVANIA_VIRTUALE } from '../../components/layout/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../store/store-constants';

const initialValuesUtenteRecuperato: NuoveAssunzioniPatchRequestDto = {
  cognome: '',
  nome: '',
  genere: undefined,
  dataNascita: '',
  cittaNascita: '',
  codiceFiscale: '',
  cittadinanza: '',
  cittaResidenza: '',
  provinciaResidenza: '',
  capResidenza: '',
  indirizzoResidenza: '',
  statoCivile: '',
  numeroCartaIdentita: '',
  indirizzoPec: '',
  telefono: '',
  cellulare: '',
  email: '',
  note: '',
  titoloDiStudio: '',
  notePersonale: '',
  codiceClasseDiConcorso: undefined,
  requestType: 'CONFERMA',
  documenti: {},
};

const schemaConfirm = z.object({
  codiceClasseDiConcorso: z.string({ required_error: 'required' }),
  titoloDiStudio: z.string({ required_error: 'required' }),
  note: z.string().optional(),
  telefono: z
    .string()
    .regex(new RegExp(/^\d+$/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
  cellulare: z
    .string()
    .regex(new RegExp(/^\d+$/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
});

const schemaSave = z.object({
  codiceClasseDiConcorso: z.string({ required_error: 'required' }).optional(),
  titoloDiStudio: z.string({ required_error: 'required' }).optional(),
  note: z.string().optional(),
  telefono: z
    .string()
    .regex(new RegExp(/^\d+$/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
  cellulare: z
    .string()
    .regex(new RegExp(/^\d+$/), 'numberTel')
    .trim()
    .min(1)
    .max(15, 'lenghtTelefonoCandidato')
    .optional(),
});

const ValidazioneIntegrazioneDatiCandidato = () => {
  const dispatch = useAppDispatch();
  const {
    formSearchResult,
    detailsResult,
    detailsStatus,
    elencoDocumentiResult,
    elencoDocumentiStatus,
    updateResult,
  } = useAppSelector((state) => state.candidato);
  const { userInfoResult } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const [button, setButtonSubmit] = useState('');
  const validationSchema =
    button === 'CONFERMA'
      ? toFormikValidationSchema(schemaConfirm)
      : toFormikValidationSchema(schemaSave);
  const navigate = useNavigate();

  let candidatoValidato =
    detailsResult?.statoCandidato === ElencoNuoveAssunzioniDtoStatoCandidatoEnum.Val;

  useEffect(() => {
    if (userInfoResult && userInfoResult.entityId) {
      dispatch(resetUpdateForm());
      dispatch(formSrachPopulate());
      dispatch(getListaDocumenti());
      //TODO: utilizzare l'id del candidato recuperato dal login
      dispatch(detailsCandidato(userInfoResult.entityId));
    }
  }, [userInfoResult]);

  useEffect(() => {
    insertForm.setValues({
      cognome: detailsResult?.anagraficaUnica?.cognome || '',
      nome: detailsResult?.anagraficaUnica?.nome || '',
      // genere: detailsResult?.anagraficaUnica?.genere || undefined,
      dataNascita: detailsResult?.anagraficaUnica?.dataNascita || '',
      cittaNascita: detailsResult?.anagraficaUnica?.cittaNascita || '',
      codiceFiscale: detailsResult?.anagraficaUnica?.codiceFiscale || '',
      cittadinanza: detailsResult?.anagraficaUnica?.cittadinanza || '',
      cittaResidenza: detailsResult?.anagraficaUnica?.cittaResidenza || '',
      provinciaResidenza: detailsResult?.anagraficaUnica?.provinciaResidenza || '',
      capResidenza: detailsResult?.anagraficaUnica?.capResidenza || '',
      indirizzoResidenza: detailsResult?.anagraficaUnica?.indirizzoResidenza || '',
      statoCivile: detailsResult?.anagraficaUnica?.statoCivile || '',
      numeroCartaIdentita: detailsResult?.anagraficaUnica?.numeroCartaIdentita || '',
      indirizzoPec: detailsResult?.anagraficaUnica?.indirizzoPec || '',
      telefono: detailsResult?.telefono || '',
      cellulare: detailsResult?.cellulare || '',
      email: detailsResult?.email || '',
      note: detailsResult?.note || '',
      titoloDiStudio: detailsResult?.titoloDiStudio || '',
      notePersonale: detailsResult?.note || '',
      codiceClasseDiConcorso: detailsResult?.codiceClasseConcorso || undefined,
      requestType: 'CONFERMA',
      documenti: {},
    });
  }, [detailsResult]);

  let classiDiConcorso: CustomSelectItem[] = [];
  formSearchResult?.classiDiConcorso?.forEach((s) => {
    classiDiConcorso.push({ label: s.label, value: s.valore });
  });

  let genereOptions: CustomSelectItem[] = [];
  formSearchResult?.generi?.forEach((s) => {
    genereOptions.push({ label: s.label, value: s.valore });
  });

  const insertForm: FormikProps<NuoveAssunzioniPatchRequestDto> = useFormik({
    initialValues: initialValuesUtenteRecuperato,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      doInsert();
    },
  });

  const handleChange = (name: string, selectedOption: any) => {
    insertForm.setFieldValue(name, selectedOption);
  };

  const doInsert = () => {
    if (userInfoResult && userInfoResult.entityId) {
      let body: patchValidazioneIntegrazioneDati = {
        id: userInfoResult.entityId,
        bodyRequest: insertForm.values,
      };
      dispatch(validazioneIntegrazioneDati(body));
    }
  };

  useEffect(() => {
    if (userInfoResult && userInfoResult.entityId)
      dispatch(detailsCandidato(userInfoResult.entityId));
  }, [updateResult]);

  const getFieldError = (
    form: FormikProps<NuoveAssunzioniPatchRequestDto>,
    fieldName: keyof NuoveAssunzioniPatchRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || '';
    } else return '';
  };

  const submitConfirm = () => {
    insertForm.setFieldValue('requestType', 'CONFERMA');
    setTimeout(() => {
      insertForm.submitForm();
    }, 500);
  };

  const submitSave = () => {
    insertForm.setFieldValue('requestType', 'SALVA');
    setTimeout(() => {
      insertForm.submitForm();
    }, 500);
  };

  const handleIdChange = (id: number | undefined, name: string | undefined) => {
    if (id && id !== -1) {
      console.log(id, name);
      const documento = {
        ...insertForm.values.documenti,
        [name!]: id,
      };
      console.log(documento);
      insertForm.setFieldValue('documenti', documento);
    } else {
      const documento = { ...insertForm.values.documenti };
      delete documento[id!];
      insertForm.setFieldValue('documento', documento);
    }
  };
  return (
    <div>
      <PageHeader showIcon={false} title={'Validazione - Integrazione dati candidato'} />
      {detailsStatus === STATUS_FULLFILLED && (
        <div>
          <PageHeader showIcon={false} title={'Validazione - Integrazione dati candidato'} />
          {detailsStatus === STATUS_FULLFILLED && (
            <div className="form-custom">
              <h5 className="border-bottom mt-3">Riepilogo</h5>
              <div className="position-relative py-5">
                <div className="row">
                  <Input
                    label="Cognome"
                    type="text"
                    placeholder="Inserisci il cognome"
                    id="input-cognome"
                    wrapperClassName="col-md-6"
                    name="cognome"
                    value={insertForm.values.cognome}
                    disabled={
                      (detailsResult?.anagraficaUnica?.cognome != null &&
                        detailsResult?.anagraficaUnica?.cognome !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'cognome')}
                    infoText={t(getFieldError(insertForm, 'cognome')) || ''}
                  />
                  <Input
                    label="Nome"
                    type="text"
                    placeholder="Inserisci il nome"
                    id="input-nome"
                    wrapperClassName="col-md-6"
                    name="nome"
                    value={insertForm.values.nome}
                    disabled={
                      (detailsResult?.anagraficaUnica?.nome != null &&
                        detailsResult?.anagraficaUnica?.nome !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'nome')}
                    infoText={t(getFieldError(insertForm, 'nome')) || ''}
                  />
                  <Col md="6">
                    <CustomSelect
                      label="Genere"
                      placeholder="Seleziona il genere"
                      options={genereOptions}
                      name="genere"
                      disabled={
                        (detailsResult?.anagraficaUnica?.genere != null &&
                          detailsResult?.anagraficaUnica.genere !== undefined) ||
                        candidatoValidato
                      }
                      invalid={!!getFieldError(insertForm, 'genere')}
                      infoText={t(getFieldError(insertForm, 'genere')) || ''}
                      value={insertForm.values.genere}
                      onChange={(e) => {
                        handleChange('genere', e);
                      }}
                    />
                  </Col>
                  <Input
                    label="Data di nascita"
                    type="date"
                    placeholder="Inserisci la data di nascita"
                    id="input-dataNascita"
                    wrapperClassName="col-md-6"
                    name="dataNascita"
                    value={insertForm.values.dataNascita}
                    disabled={
                      (detailsResult?.anagraficaUnica?.dataNascita != null &&
                        detailsResult?.anagraficaUnica?.dataNascita !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'dataNascita')}
                    infoText={t(getFieldError(insertForm, 'dataNascita')) || ''}
                  />
                  <Input
                    label="Città di nascita"
                    type="text"
                    placeholder="Inserisci la città di nascita"
                    id="input-cittaNascita"
                    wrapperClassName="col-md-6"
                    name="cittaNascita"
                    value={insertForm.values.cittaNascita}
                    disabled={
                      (detailsResult?.anagraficaUnica?.cittaNascita != null &&
                        detailsResult?.anagraficaUnica?.cittaNascita !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'cittaNascita')}
                    infoText={t(getFieldError(insertForm, 'cittaNascita')) || ''}
                  />
                  <Input
                    label="Codice fiscale"
                    type="text"
                    placeholder="Inserisci il codice fiscale"
                    id="input-codiceFiscale"
                    wrapperClassName="col-md-6"
                    name="codiceFiscale"
                    value={insertForm.values.codiceFiscale}
                    disabled={
                      (detailsResult?.anagraficaUnica?.codiceFiscale != null &&
                        detailsResult?.anagraficaUnica?.codiceFiscale !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'codiceFiscale')}
                    infoText={t(getFieldError(insertForm, 'codiceFiscale')) || ''}
                  />
                  <Input
                    label="Cittadinanza"
                    type="text"
                    placeholder="Inserisci la cittadinanza"
                    id="input-cittadinanza"
                    wrapperClassName="col-md-6"
                    name="cittadinanza"
                    value={insertForm.values.cittadinanza}
                    disabled={
                      (detailsResult?.anagraficaUnica?.cittadinanza != null &&
                        detailsResult?.anagraficaUnica?.cittadinanza !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'cittadinanza')}
                    infoText={t(getFieldError(insertForm, 'cittadinanza')) || ''}
                  />
                  <Input
                    label="Città di residenza"
                    type="text"
                    placeholder="Inserisci la città di residenza"
                    id="input-cittaResidenza"
                    wrapperClassName="col-md-6"
                    name="cittaResidenza"
                    value={insertForm.values.cittaResidenza}
                    disabled={
                      (detailsResult?.anagraficaUnica?.cittaResidenza != null &&
                        detailsResult?.anagraficaUnica?.cittaResidenza !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'cittaResidenza')}
                    infoText={t(getFieldError(insertForm, 'cittaResidenza')) || ''}
                  />
                  <Input
                    label="Provincia"
                    type="text"
                    placeholder="Inserisci la provincia"
                    id="input-provinciaResidenza"
                    wrapperClassName="col-md-6"
                    name="provinciaResidenza"
                    value={insertForm.values.provinciaResidenza}
                    disabled={
                      (detailsResult?.anagraficaUnica?.provinciaResidenza != null &&
                        detailsResult?.anagraficaUnica?.provinciaResidenza !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'provinciaResidenza')}
                    infoText={t(getFieldError(insertForm, 'provinciaResidenza')) || ''}
                  />
                  <Input
                    label="CAP"
                    type="text"
                    placeholder="Inserisci il cap"
                    id="input-capResidenza"
                    wrapperClassName="col-md-6"
                    name="capResidenza"
                    value={insertForm.values.capResidenza}
                    disabled={
                      (detailsResult?.anagraficaUnica?.capResidenza != null &&
                        detailsResult?.anagraficaUnica?.capResidenza !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'capResidenza')}
                    infoText={t(getFieldError(insertForm, 'capResidenza')) || ''}
                  />
                  <Input
                    label="Indirizzo di residenza"
                    type="text"
                    placeholder="Inserisci l'indirizzo di residenza"
                    id="input-indirizzoResidenza"
                    wrapperClassName="col-md-6"
                    name="indirizzoResidenza"
                    value={insertForm.values.indirizzoResidenza}
                    disabled={
                      (detailsResult?.anagraficaUnica?.indirizzoResidenza != null &&
                        detailsResult?.anagraficaUnica?.indirizzoResidenza !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'indirizzoResidenza')}
                    infoText={t(getFieldError(insertForm, 'indirizzoResidenza')) || ''}
                  />
                  <Input
                    label="Stato civile"
                    type="text"
                    placeholder="Inserisci lo stato civile"
                    id="input-statoCivile"
                    wrapperClassName="col-md-6"
                    name="statoCivile"
                    value={insertForm.values.statoCivile}
                    disabled={
                      (detailsResult?.anagraficaUnica?.statoCivile != null &&
                        detailsResult?.anagraficaUnica?.statoCivile !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'statoCivile')}
                    infoText={t(getFieldError(insertForm, 'statoCivile')) || ''}
                  />
                  <Input
                    label="N° Carta di identità"
                    type="text"
                    placeholder="Inserisci il numero della carta d'identita"
                    id="input-numeroCartaIdentita"
                    wrapperClassName="col-md-6"
                    name="numeroCartaIdentita"
                    disabled={
                      (detailsResult?.anagraficaUnica?.numeroCartaIdentita != null &&
                        detailsResult?.anagraficaUnica?.numeroCartaIdentita !== '') ||
                      candidatoValidato
                    }
                    value={insertForm.values.numeroCartaIdentita}
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'numeroCartaIdentita')}
                    infoText={t(getFieldError(insertForm, 'numeroCartaIdentita')) || ''}
                  />
                  <Input
                    label="Telefono"
                    type="tel"
                    placeholder="Inserisci il numero di telefono"
                    id="input-telefono"
                    wrapperClassName="col-md-6 "
                    name="telefono"
                    disabled={
                      (detailsResult?.telefono != null && detailsResult?.telefono !== '') ||
                      candidatoValidato
                    }
                    value={insertForm.values.telefono}
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'telefono')}
                    infoText={t(getFieldError(insertForm, 'telefono')) || ''}
                  />
                  <Input
                    label="Cellulare"
                    type="tel"
                    placeholder="Inserisci il numero di cellulare"
                    id="input-cellulare"
                    wrapperClassName="col-md-6 "
                    name="cellulare"
                    disabled={
                      (detailsResult?.cellulare != null && detailsResult?.cellulare !== '') ||
                      candidatoValidato
                    }
                    value={insertForm.values.cellulare}
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'cellulare')}
                    infoText={t(getFieldError(insertForm, 'cellulare')) || ''}
                  />
                  <Input
                    label="Pec"
                    type="email"
                    placeholder="Inserisci la pec"
                    id="input-indirizzoPec"
                    wrapperClassName="required col-md-6"
                    name="indirizzoPec"
                    value={insertForm.values.indirizzoPec}
                    disabled={
                      (detailsResult?.anagraficaUnica?.indirizzoPec != null &&
                        detailsResult?.anagraficaUnica?.indirizzoPec !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'indirizzoPec')}
                    infoText={t(getFieldError(insertForm, 'indirizzoPec')) || ''}
                  />
                  <Input
                    label="Email"
                    type="email"
                    placeholder="Inserisci l' email"
                    id="input-email"
                    wrapperClassName="col-md-6"
                    name="email"
                    value={insertForm.values.email}
                    disabled={
                      (detailsResult?.email != null && detailsResult?.email !== '') ||
                      candidatoValidato
                    }
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'email')}
                    infoText={t(getFieldError(insertForm, 'email')) || ''}
                  />
                  <TextArea
                    label="Note personale"
                    placeholder="Inserisci le note personali "
                    id="input-notePersonale"
                    wrapperClassName=" col-md-12"
                    name="notePersonale"
                    value={insertForm.values.notePersonale}
                    disabled={true}
                    onChange={insertForm.handleChange}
                    invalid={!!getFieldError(insertForm, 'notePersonale')}
                    infoText={t(getFieldError(insertForm, 'notePersonale')) || ''}
                  />
                </div>
              </div>

              <h5 className="border-bottom">Dettagli aggiuntivi</h5>
              <div className="position-relative py-5">
                <div className="row">
                  <Col md="6">
                    <CustomSelect
                      label="Classe di concorso"
                      placeholder="Seleziona la classe di concorso"
                      options={classiDiConcorso}
                      name="codiceClasseDiConcorso"
                      invalid={!!getFieldError(insertForm, 'codiceClasseDiConcorso')}
                      infoText={t(getFieldError(insertForm, 'codiceClasseDiConcorso')) || ''}
                      value={insertForm.values.codiceClasseDiConcorso}
                      onChange={(e) => {
                        handleChange('codiceClasseDiConcorso', e);
                      }}
                      wrapperClass="required"
                      disabled={candidatoValidato}
                    />
                  </Col>
                  <Input
                    label="Titolo di studi"
                    type="text"
                    placeholder="Inserisci il titolo di studio"
                    id="input-titoloDiStudio"
                    wrapperClassName="required col-md-6"
                    name="titoloDiStudio"
                    value={insertForm.values.titoloDiStudio}
                    onChange={insertForm.handleChange}
                    disabled={candidatoValidato}
                    invalid={!!getFieldError(insertForm, 'titoloDiStudio')}
                    infoText={t(getFieldError(insertForm, 'titoloDiStudio')) || ''}
                  />
                  <TextArea
                    label="Note"
                    placeholder="Inserisci le note  "
                    id="input-note"
                    wrapperClassName=" col-md-12"
                    name="note"
                    value={insertForm.values.note}
                    onChange={insertForm.handleChange}
                    disabled={candidatoValidato}
                    invalid={!!getFieldError(insertForm, 'note')}
                    infoText={t(getFieldError(insertForm, 'note')) || ''}
                  />
                </div>
              </div>
              <h5 className="border-bottom">Lista documenti obbligatori</h5>
              <div className="position-relative py-3">
                {elencoDocumentiStatus === STATUS_FULLFILLED && (
                  <Table striped size="xs">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="table-order">Nome documento</div>
                        </th>
                        <th scope="col">
                          <div className="table-order">File</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(elencoDocumentiResult || []).map((x, i) => {
                        // Verifica se l'utente ha documenti caricati con il nome specificato
                        const hasDocumentUploaded = detailsResult?.elencoDocumenti!.some(
                          (item) => item.tipo === x.nomeDocumento
                        );

                        return (
                          <tr key={i}>
                            <td>{x.descrizioneDocumento}</td>
                            <td style={{ width: '55%' }}>
                              {hasDocumentUploaded ? (
                                detailsResult?.elencoDocumenti!.map((item) => {
                                  if (item.tipo === x.nomeDocumento) {
                                    return (
                                      <DocumentUpload
                                        key={item.documentoDto?.id}
                                        hiddenTitle={true}
                                        documento={item.documentoDto}
                                        documentName=""
                                        hiddenDeleteButton={candidatoValidato}
                                        showOnlyIcon
                                      />
                                    );
                                  }
                                  return null;
                                })
                              ) : !candidatoValidato ? (
                                <DocumentUpload
                                  hiddenTitle={true}
                                  setDocumentIdNameFile={handleIdChange}
                                  returnDocumentName={true}
                                  idDocumento={insertForm?.values?.documenti![x.nomeDocumento!]}
                                  documentName={x.nomeDocumento!}
                                  showOnlyIcon
                                />
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <div className="d-flex flex-row-reverse mt-3">
                  <Button
                    color="primary"
                    className="mt-2"
                    disabled={candidatoValidato}
                    onClick={() => {
                      submitConfirm();
                      setButtonSubmit('CONFERMA');
                    }}
                  >
                    Conferma
                  </Button>
                  <Button
                    outline
                    color="primary"
                    className="mt-2 mr-2"
                    disabled={candidatoValidato}
                    onClick={() => {
                      submitSave();
                      setButtonSubmit('SAVE');
                    }}
                  >
                    Salva
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    className="mt-2 mr-2"
                    onClick={() => {
                      navigate(HREF_SCRIVANIA_VIRTUALE);
                    }}
                  >
                    Indietro
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ValidazioneIntegrazioneDatiCandidato;